import React, { ReactNode } from 'react';
import cn from 'classnames';
import { Loading } from 'ui';

import './TabContent.scss';

type Props = {
  title: string;
  children: ReactNode;
  className?: string;
  path?: string;
  dataTestId?: string;
  full?: boolean;
  isLoading?: boolean;
};

const TabContent = ({
  children,
  className,
  dataTestId = 'tab-content',
  full,
  isLoading = false
}: Props) => {
  const classNames = cn(className, { '-full': full });
  return (
    <div data-testid={dataTestId} className={classNames}>
      {isLoading ? <Loading /> : children}
    </div>
  );
};

export default TabContent;
