import React from 'react';
import range from 'lodash/range';
import TableRow from '../TableRow/TableRow';
import TableCell from '../TableCell/TableCell';
import Loader from '../../Loader/Loader';

type Props = {
  columns: number;
};

const TableLoader = ({ columns }: Props) => {
  return (
    <>
      {range(5).map(row => (
        <TableRow clickable={false} dataTestid="table-row-loading" key={row}>
          {range(columns).map(column => (
            <TableCell key={column}>
              <Loader />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default TableLoader;
