const ACCESS_TOKEN_KEY = '_yield_access_token';

export function getToken(): string | null {
  return window.localStorage.getItem(ACCESS_TOKEN_KEY);
}

export function setToken(token: string): void {
  window.localStorage.setItem(ACCESS_TOKEN_KEY, token);
}

export function logoutUrl(errorMessage?: string): string {
  return (
    '/logout?return_to=' +
    encodeURIComponent(window.location.pathname + window.location.search) +
    (errorMessage ? `&error_message=${encodeURIComponent(errorMessage)}` : '')
  );
}

export function removeToken() {
  window.localStorage.removeItem(ACCESS_TOKEN_KEY);
}
