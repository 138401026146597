import { involvedPartyName } from 'domain/lawsuits';
import React from 'react';
import { InvolvedParty } from 'types';
import { Stack, StackMargin } from 'ui';

import style from './Lawsuits.module.scss';

type Props = {
  parties: InvolvedParty[];
};

const InvolvedParties = ({ parties }: Props) => {
  return (
    <Stack marginTop={StackMargin.MEDIUM}>
      <h4 className={style.involvedParties}>Partes envolvidas:</h4>
      {parties.map(party => (
        <Stack
          marginTop={StackMargin.SMALL}
          className={style.party}
          key={party.id}
        >
          {involvedPartyName(party)}
        </Stack>
      ))}
    </Stack>
  );
};

export default InvolvedParties;
