import {
  AddContentToCase,
  FetchReportTableProps,
  ReportElectoralDonation,
  Report,
  CaseEntity,
  FetchReportTotalValueResult
} from 'types';
import Client from './client';

export function fetchReportElectoralDonations({
  caseId,
  reportId,
  pagination
}: FetchReportTableProps) {
  return Client.get<ReportElectoralDonation[]>(
    `cases/${caseId}/reports/${reportId}/contents/electoral_donations`,
    {
      params: {
        page: pagination?.page.toString(),
        pageSize: pagination?.pageSize.toString()
      }
    }
  );
}

export function fetchReportElectoralDonationsSummary({
  caseId,
  reportId
}: FetchReportTableProps) {
  return Client.get<ReportElectoralDonation[]>(
    `cases/${caseId}/reports/${reportId}/contents/electoral_donations/summary`
  );
}

export function addElectoralDonationToCase({
  caseId,
  contentId,
  reportId
}: AddContentToCase) {
  return Client.post<unknown, CaseEntity>(
    `cases/${caseId}/reports/${reportId}/contents/electoral_donations/${contentId}`
  );
}

export function createReportFromElectoralDonations({
  caseId,
  contentId,
  reportId
}: AddContentToCase) {
  return Client.post<unknown, Report>(
    `cases/${caseId}/reports/${reportId}/contents/electoral_donations/${contentId}/reports`
  );
}

export function fetchReportElectoralDonationsTotalValue({
  caseId,
  reportId
}: {
  caseId: string;
  reportId: string;
}) {
  return Client.get<FetchReportTotalValueResult>(
    `cases/${caseId}/reports/${reportId}/contents/electoral_donations/total_value`
  );
}
