import { assetOriginOptions } from 'domain/assets';

import React from 'react';
import { FormikProps, getIn } from 'formik';
import { AssetAttributes, AssetRealEstate } from 'types';
import { FormContainer, InputField, SelectField } from 'ui';

import styles from '../../Form.module.scss';

type Props = {
  assetRealEstate: AssetRealEstate | null;
  isLoading: boolean;
  formik: FormikProps<AssetAttributes>;
  disabled?: boolean;
};

const RealEstateFields = ({
  assetRealEstate,
  isLoading,
  formik,
  disabled
}: Props) => {
  return (
    <>
      <h3 className={styles.title}>Informações do Grupo</h3>
      <FormContainer className={'col-2'}>
        <InputField
          type="text"
          id="name"
          name="name"
          title="Nome do grupo"
          isLoading={isLoading}
          value={formik.values.name}
          error={getIn(formik.touched, 'name') && getIn(formik.errors, 'name')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={disabled}
        />

        <SelectField
          id="assetRealEstate.origin"
          name="assetRealEstate.origin"
          value={formik.values?.assetRealEstate?.origin ?? ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isLoading={isLoading}
          options={assetOriginOptions()}
          title="Origem (opcional)"
          showMode={
            assetRealEstate ? !assetRealEstate.origin.isEditable : false
          }
          optional
          error={
            getIn(formik.touched, 'assetRealEstate.origin') &&
            getIn(formik.errors, 'assetRealEstate.origin')
          }
          disabled={disabled}
        />

        <InputField
          type="area"
          id="assetRealEstate.landArea"
          name="assetRealEstate.landArea"
          title="Área do terreno m² (opcional)"
          isLoading={isLoading}
          value={formik.values.assetRealEstate?.landArea?.toString()}
          error={
            getIn(formik.touched, 'assetRealEstate.landArea') &&
            getIn(formik.errors, 'assetRealEstate.landArea')
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          showMode={
            assetRealEstate ? !assetRealEstate.landArea.isEditable : false
          }
          disabled={disabled}
        />

        <InputField
          type="area"
          id="assetRealEstate.constructedArea"
          name="assetRealEstate.constructedArea"
          title="Área construída m² (opcional)"
          isLoading={isLoading}
          value={formik.values.assetRealEstate?.constructedArea?.toString()}
          error={
            getIn(formik.touched, 'assetRealEstate.constructedArea') &&
            getIn(formik.errors, 'assetRealEstate.constructedArea')
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          showMode={
            assetRealEstate
              ? !assetRealEstate.constructedArea.isEditable
              : false
          }
          disabled={disabled}
        />
      </FormContainer>
    </>
  );
};

export default RealEstateFields;
