import React from 'react';

import { CreditContract } from 'types';

import { Card, Accordion, Link } from 'ui';
import { FileList } from 'components';

import style from './ContractFiles.module.scss';

type Props = {
  contracts: CreditContract[];
  showDelete?: boolean;
  showLink?: boolean;
};

const ContractFiles = ({
  contracts,
  showDelete = true,
  showLink = true
}: Props) => {
  const isContractEmpty = contracts.length === 0;

  return (
    <Card>
      <h2 className={style.cardTitle}>Contratos</h2>

      {isContractEmpty && (
        <p className={style.emptyList}>Não há contratos neste caso</p>
      )}

      {!isContractEmpty && (
        <>
          {contracts.map((contract: CreditContract) => {
            return (
              <Accordion
                isClosed
                key={`contract-${contract.id}`}
                title={`Contrato nº ${contract.number}`}
                titleClassName={style.accordionHeader}
                headerActions={
                  showLink && (
                    <Link
                      target="_blank"
                      highlight
                      href={`/cases/${contract.caseId}/debtors/contract/${contract.id}`}
                    >
                      Ver contrato
                    </Link>
                  )
                }
              >
                <div className={style.caseFilesList}>
                  <FileList
                    className={style.filesList}
                    files={contract.fileUploads}
                    title="Arquivos do contrato"
                    caseId={contract.caseId}
                    placeholder="Não há arquivos neste contrato"
                    showDelete={showDelete}
                  />
                  <FileList
                    className={style.filesList}
                    files={contract.guaranteeFiles}
                    title="Arquivos de garantias"
                    caseId={contract.caseId}
                    placeholder="Não há arquivos de garantias neste contrato"
                    showDelete={showDelete}
                  />
                  <FileList
                    className={style.filesList}
                    files={contract.reclaimingFiles}
                    title="Arquivos de recuperações"
                    caseId={contract.caseId}
                    placeholder="Não há arquivos de recuperações neste contrato"
                    showDelete={showDelete}
                  />
                </div>
              </Accordion>
            );
          })}
        </>
      )}
    </Card>
  );
};

export default ContractFiles;
