import React, { ReactNode } from 'react';
import cn from 'classnames';
import style from './Cell.module.scss';

export enum TablePositionModifier {
  CENTER = 'center',
  RIGHT = 'right'
}

type Props = {
  children: ReactNode;
  colSpan?: number;
  collapsible?: {
    isExpanded: boolean;
    onCollapsibleCallback: () => void;
  };
  isHeader?: boolean;
  positionModifier?: TablePositionModifier;
  className?: string;
  dataTestId?: string;
  onClick?: () => void;
};

const positionModifierClass = (
  modifier?: TablePositionModifier
): string | undefined => {
  if (!modifier) {
    return;
  }
  return modifier === TablePositionModifier.RIGHT
    ? style.cellRight
    : style.cellCenter;
};

const Cell = ({
  colSpan = 1,
  children,
  collapsible,
  isHeader,
  positionModifier,
  className,
  dataTestId,
  onClick
}: Props) => {
  const isExpanded = collapsible?.isExpanded;
  const onCollapsibleCallback = collapsible?.onCollapsibleCallback;

  const collapsibleClass = isExpanded
    ? style.cellExpanded
    : style.cellCollapsible;

  const contentClassName = cn([
    style.cell,
    className,
    isHeader && style.cellSmall,
    isHeader && style.cellHeading,
    positionModifierClass(positionModifier),
    collapsible && collapsibleClass
  ]);

  const content = collapsible ? (
    <div
      className={style.expandButton}
      role={collapsible ? 'button' : undefined}
      onClick={collapsible ? onCollapsibleCallback : undefined}
    >
      <div className={style.clipContent}>{children}</div>
    </div>
  ) : (
    children
  );

  return isHeader ? (
    <th colSpan={colSpan} className={contentClassName} data-testid={dataTestId}>
      {content}
    </th>
  ) : (
    <td
      colSpan={colSpan}
      className={contentClassName}
      data-testid={dataTestId}
      onClick={onClick}
    >
      {content}
    </td>
  );
};

export default Cell;
