import { fetchProfiles } from 'api';
import { can, Action } from 'api/permissions';
import { useQuery } from 'hooks';
import React, { useEffect } from 'react';
import { Loading, openErrorToast, Stack, StackMargin } from 'ui';
import DeleteProfileModalButton from './DeleteProfileModalButton';
import NewProfileModalButton from './NewProfileModalButton';
import ProfileItemWrapper from './ProfileItemWrapper';

import style from './PermissionsManagement.module.scss';

const PermissionsManagement = () => {
  const { data: profiles, isLoading, error, refetch } = useQuery(
    fetchProfiles,
    {}
  );

  const refetchProfiles = () => refetch({});

  useEffect(() => {
    error &&
      openErrorToast(
        'Erro ao carregar, atualize a página para exibir os perfis'
      );
  }, [error]);

  if (isLoading) return <Loading />;

  return (
    <div className={style.container}>
      <Stack
        marginBottom={StackMargin.XLARGE}
        className={style.buttonsContainer}
      >
        {can(Action.PERMISSOES_GERENCIAMENTO_DE_PERMISSOES_EXCLUIR) && (
          <DeleteProfileModalButton
            profiles={profiles}
            refetchProfiles={refetchProfiles}
          />
        )}

        {can(Action.PERMISSOES_GERENCIAMENTO_DE_PERMISSOES_INCLUIR) && (
          <NewProfileModalButton refetchProfiles={refetchProfiles} />
        )}
      </Stack>
      {profiles && profiles.length > 0 ? (
        <ul>
          {profiles?.map(profile => (
            <ProfileItemWrapper key={profile.id} profile={profile} />
          ))}
        </ul>
      ) : (
        <p className={style.emptyListMessage}>Não há perfis cadastrados</p>
      )}
    </div>
  );
};

export default PermissionsManagement;
