import {
  EntityType,
  CreditContract,
  Reclaiming,
  QueryStatus,
  Movement,
  FileUpload,
  Court,
  CourtFormAttributes,
  ApiPagination,
  ApiError
} from 'types';

export enum StatusType {
  FILED = 'filed',
  IN_DISTRIBUTION = 'in_distribution',
  CLOSED = 'closed'
}

export enum CauseType {
  AGAINST = 'against',
  IN_FAVOR = 'in_favor'
}

export enum LawyerCauseType {
  AGAINST = 'against',
  IN_FAVOR = 'in_favor',
  NEUTRAL = 'neutral'
}

export enum InvolvedPartyType {
  AUTHOR = 'autor',
  DEFENDANT = 'reu',
  NEUTRAL = 'neutral'
}

export type IndividualInvolvedParty = {
  name: string;
  cpf: string | null;
};

export type CompanyInvolvedParty = {
  businessName: string;
  cnpj: string | null;
};

export type InvolvedPartyAttributes = {
  entityType: EntityType | null;
  type: InvolvedPartyType | null;
  individual?: IndividualInvolvedParty;
  company?: CompanyInvolvedParty;
  isInCase?: boolean;
};

export type InvolvedParty = InvolvedPartyAttributes & {
  id: string | null;
};

export type LawyerAttributes = {
  name: string;
  cause?: string;
};

export type Lawyer = LawyerAttributes & {
  id: string | null;
};

export type LawsuitBase = {
  action?: string;
  cause?: CauseType;
  causeInitialDate?: string;
  causeInitialValue?: number;
  description?: string;
  externalNumber?: string;
  lawyers: Array<Lawyer>;
  number: string;
  parties: Array<InvolvedParty>;
  executionComments?: string;
  status: string;
  type?: string;
};

export type InternalLawsuitAttributes = LawsuitBase & {
  fileUploadIds: Array<string>;
  contractIds: Array<string>;
  reclaimingIds: Array<string>;
  courtId: string;
  court?: CourtFormAttributes;
};

export type InternalLawsuit = LawsuitBase & {
  id: string;
  contracts: Array<CreditContract>;
  reclaimings: Array<Reclaiming>;
  court: Court;
  isAutoUpdated: boolean;
  isParent: boolean;
  movementFiles: Array<FileUpload>;
  fileUploads: Array<FileUpload>;
  children?: Array<ChildLawsuit>;
  paginatedChildren: {
    data: Array<ChildLawsuit>;
    error: ApiError;
    pagination: ApiPagination;
  };
};

export type ChildLawsuitAttributes = LawsuitBase & {
  courtId: string;
  court?: CourtFormAttributes;
};

export type ChildLawsuit = LawsuitBase & {
  id: string;
  court: Court;
  isAutoUpdated: boolean;
  isParent: boolean;
  movements: Array<Movement>;
  movementFiles: Array<FileUpload>;
  fileUploads: Array<FileUpload>;
  parent: InternalLawsuit;
};

export type LawsuitSearch = {
  id: string;
  searchTerm: string;
  provider: string;
  status: QueryStatus;
  content: LawsuitAutocompleteContent;
};

export type LawsuitAutocomplete = {
  id: string;
  content: null | LawsuitAutocompleteContent;
  status: QueryStatus;
};

export type LawsuitAutocompleteContent = {
  lawyers: LawyersAutocomplete[];
  parties: PartiesAutocomplete[];
  status: string;
  action: string;
  causeInitialDate: string;
  court: CourtAutocomplete;
};

export type LawyersAutocomplete = {
  cause: string;
  name: string;
};

export type PartiesAutocomplete = {
  entityType: EntityType;
  name: string;
  type: InvolvedPartyType | null;
  isInCase: boolean;
  document: string | null;
};

export type AddPartyToCase = {
  caseId: string;
  party: InvolvedParty;
};

export type CourtAutocomplete = {
  name: string | null;
  judgingBody: string | null;
  instance: string | null;
  city: {
    id: string | null;
    name: string | null;
    state: string | null;
  };
  id: string | null;
};
