import React from 'react';
import { Loader } from 'ui';

export const Loading = () => {
  return (
    <>
      <Loader height={1.5} width={4} />
      <Loader height={1.5} width={4} />
      <Loader height={1.5} width={4} />
    </>
  );
};
