import {
  LocationAttributes,
  Location,
  RequestQueryParams,
  LocationFormAttributes
} from 'types';
import Client from './client';

function createLocation(caseId: string, locationData: LocationFormAttributes) {
  return Client.post<LocationAttributes, Location>(
    `cases/${caseId}/locations`,
    locationData
  );
}

export type FetchLocationsParams = { caseId: string } & RequestQueryParams;

function fetchLocations({ caseId, pagination, orderBy }: FetchLocationsParams) {
  return Client.get<Location[]>(`cases/${caseId}/locations`, {
    params: {
      page: pagination?.page.toString(),
      pageSize: pagination?.pageSize.toString(),
      field: orderBy?.field,
      value: orderBy?.value
    }
  });
}

function deleteLocation(caseId: string, locationId: string) {
  return Client.delete(`cases/${caseId}/locations/${locationId}`);
}

function updateLocation(
  caseId: string,
  locationId: string,
  locationData: LocationFormAttributes
) {
  return Client.put<LocationAttributes, Location>(
    `cases/${caseId}/locations/${locationId}`,
    locationData
  );
}

function submitLocation({
  location,
  values,
  caseId
}: {
  location: Location | undefined;
  values: LocationFormAttributes;
  caseId: string;
}) {
  if (location) return updateLocation(caseId, location.id, values);

  return createLocation(caseId, values);
}

export {
  createLocation,
  fetchLocations,
  deleteLocation,
  updateLocation,
  submitLocation
};
