import React from 'react';

import { CreditContract } from 'types';

import { Card } from 'ui';
import Form from './Form';

import './ContractCard.scss';

type Props = {
  caseId: string;
  contractNumberHandler: (contractNumber: string) => void;
  creditContractHandler: (creditContract: CreditContract) => void;
  creditContract?: CreditContract;
  isLoading?: boolean;
};

const ContractCard = ({
  caseId,
  contractNumberHandler,
  creditContractHandler,
  creditContract,
  isLoading
}: Props) => {
  return (
    <Card className="contract-card" dataTestId="contract-card">
      <Form
        caseId={caseId}
        contractNumberHandler={contractNumberHandler}
        creditContractHandler={creditContractHandler}
        creditContract={creditContract}
        isLoading={isLoading}
      />
    </Card>
  );
};

export default ContractCard;
