import React, { ReactElement, ReactNode } from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

import { Icon } from 'ui';

import style from './NavigationItem.module.scss';

type Props = {
  children?: ReactNode;
  content: string | ReactElement;
  active?: boolean;
  iconName?: string;
  fill?: string;
  onClick?: () => void;
  pathname?: string;
  target?: string;
  position?: string;
  showChild?: boolean;
};

const NavigationItem = ({
  children,
  active,
  iconName,
  fill,
  position,
  pathname,
  target,
  onClick = () => {},
  content,
  showChild
}: Props) => {
  const navigationItemClass = cn(style.navigationItem, {
    [style.active]: active,
    [style.bottom]: position === 'bottom',
    [style.top]: position === 'top'
  });

  const textClass = cn(style.text, {
    [style.textActive]: active
  });

  return (
    <>
      <NavLink
        onClick={onClick}
        to={{ pathname }}
        className={navigationItemClass}
        data-testid="navigation-item"
        target={target}
      >
        {iconName && (
          <span className={style.icon}>
            <Icon small name={iconName} fill={active ? '#3949ab' : fill} />
          </span>
        )}
        <span className={textClass}>{content}</span>
      </NavLink>
      <AnimatePresence>
        {showChild && (
          <motion.div
            transition={{ duration: 0.2 }}
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default NavigationItem;
