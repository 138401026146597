import * as Yup from 'yup';
import { isValidCPF, isValidCNPJ } from 'utils';

export function cpf(): Yup.StringSchema<string> {
  return Yup.string()
    .length(11, 'Precisa ser um CPF completo')
    .test({
      name: 'is-cpf',
      exclusive: true,
      message: 'Precisa ser um CPF válido',
      test: value => !value || isValidCPF(value)
    });
}

export function cnpj(): Yup.StringSchema<string> {
  return Yup.string()
    .length(14, 'Precisa ser um CNPJ completo')
    .test({
      name: 'is-cnpj',
      exclusive: true,
      message: 'Precisa ser um CNPJ válido',
      test: value => !value || isValidCNPJ(value)
    });
}
