export enum CnpjSituation {
  NULA = 'nula',
  ATIVA = 'ativa',
  SUSPENSA = 'suspensa',
  INAPTA = 'inapta',
  ATIVA_NAO_REGULAR = 'ativa_nao_regular',
  BAIXADA = 'baixada'
}

const cnpjSituationTitleDictionary = {
  [CnpjSituation.NULA]: 'Nula',
  [CnpjSituation.ATIVA]: 'Ativa',
  [CnpjSituation.SUSPENSA]: 'Suspensa',
  [CnpjSituation.INAPTA]: 'Inapta',
  [CnpjSituation.ATIVA_NAO_REGULAR]: 'Ativa não regular',
  [CnpjSituation.BAIXADA]: 'Baixada'
};

export function cnpjSituationTitle(situation: CnpjSituation): string {
  return cnpjSituationTitleDictionary[situation] || situation;
}

export enum CnpjSpecialSituation {
  FALIDO = 'falido',
  INICIO_DE_CONCORDATA = 'inicio_de_concordata',
  TERMINO_DE_CONCORDATA = 'termino_de_concordata',
  EM_LIQUIDACAO = 'em_liquidacao',
  EXTRA_JUDICIAL = 'em_liquidacao_extra_judicial',
  INTERVENCAO = 'intervencao',
  FINANCEIRO_E_DE_CAPITAIS = 'financeiro_e_de_capitais',
  LIQUIDACAO_JUDICIAL = 'liquidacao_judicial',
  LIQUIDACAO_EXTRA_JUDICIAL = 'liquidacao_extra_judicial',
  RECUPERACAO_JUDICIAL = 'recuperacao_judicial'
}

const cnpjSpecialSituationTitleDictionary = {
  [CnpjSpecialSituation.FALIDO]: 'Falido',
  [CnpjSpecialSituation.INICIO_DE_CONCORDATA]: 'Início de concordata',
  [CnpjSpecialSituation.TERMINO_DE_CONCORDATA]: 'Término de concordata',
  [CnpjSpecialSituation.EM_LIQUIDACAO]: 'Em liquidação',
  [CnpjSpecialSituation.EXTRA_JUDICIAL]: 'Em liquidação extra-judicial',
  [CnpjSpecialSituation.INTERVENCAO]: 'Intervenção',
  [CnpjSpecialSituation.FINANCEIRO_E_DE_CAPITAIS]: 'Financeiro e de capitais',
  [CnpjSpecialSituation.LIQUIDACAO_JUDICIAL]: 'Liquidação judicial',
  [CnpjSpecialSituation.LIQUIDACAO_EXTRA_JUDICIAL]: 'Liquidação extra-judicial',
  [CnpjSpecialSituation.RECUPERACAO_JUDICIAL]: 'Recuperação judicial'
};

export function cnpjSpecialSituationTitle(
  situation: CnpjSpecialSituation
): string {
  return cnpjSpecialSituationTitleDictionary[situation] || situation;
}

export enum CompanySize {
  MICROEMPRESA = 'microempresa',
  EMPRESA_PEQUENO_PORTE = 'empresa_de_pequeno_porte',
  DEMAIS_EMPRESAS = 'demais_empresas'
}

const companySizeTitleDictionary = {
  [CompanySize.MICROEMPRESA]: 'Microempresa',
  [CompanySize.EMPRESA_PEQUENO_PORTE]: 'Empresa de pequeno porte',
  [CompanySize.DEMAIS_EMPRESAS]: 'Demais empresas'
};

export function CompanySizeTitle(companySize: CompanySize): string {
  return companySizeTitleDictionary[companySize] || companySize;
}
