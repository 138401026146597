import React, {
  createContext,
  useContext,
  ReactElement,
  useState,
  useEffect,
  Component
} from 'react';
import { Socket, Channel } from 'phoenix';
import { hasValidToken } from 'api';
import { reconnectAfterMs } from 'utils';
import Client from 'api/client';
import { AugmentedWindow } from 'types';

const SocketContext = createContext<State>({
  socket: null,
  isConnected: false
});

type Props = {
  children: ReactElement | ReactElement[];
};

type State = {
  socket: null | Socket;
  isConnected: boolean;
};

class SocketProvider extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const socket = new Socket(
      (window as AugmentedWindow).ENV.REACT_APP_WEB_SOCKET_URL,
      {
        params: () => ({ access_token: Client.getSessionToken() }),
        reconnectAfterMs
      }
    );

    this.state = {
      socket: socket,
      isConnected: false
    };
  }

  componentDidMount() {
    const socket = this.state.socket;

    if (!socket) return;

    socket.onError(this.setConnectionState);
    socket.onOpen(this.setConnectionState);
    socket.onClose(this.setConnectionState);
    if (hasValidToken()) {
      socket.connect();
    }
  }

  componentWillUnmount() {
    if (this.state.socket) {
      this.state.socket!.disconnect();
    }
  }

  setConnectionState = () => {
    this.setState({ isConnected: this.state.socket!.isConnected() });
  };

  render() {
    if (!this.state.socket) return null;

    return (
      <SocketContext.Provider value={this.state}>
        {this.props.children}
      </SocketContext.Provider>
    );
  }
}

const useSocket = () => {
  return useContext(SocketContext);
};

const useChannel = (
  topic: string
): { channel: Channel | null; isConnected: boolean } => {
  const [currentChannel, setChannel] = useState<Channel | null>(null);
  const { socket, isConnected } = useSocket();

  useEffect(() => {
    if (!socket || !isConnected) return;

    const channel = socket!.channel(topic);

    channel
      .join()
      .receive('ok', () => {
        setChannel(channel);
      })
      .receive('error', resp => {
        console.error(resp);
      });

    return () => {
      channel.leave();
    };
  }, [socket, isConnected, topic]);

  return { channel: currentChannel, isConnected };
};

export { SocketProvider, useChannel, useSocket };
