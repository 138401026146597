import { ClientOptions, ApiSuccessfulResponse, AugmentedWindow } from 'types';
import request from './request';
import { setToken, getToken, logoutUrl, removeToken } from './session';

const BASE_URL: string = (window as AugmentedWindow).ENV.REACT_APP_SERVER_URL;

export default class Client {
  static baseUrl(): string {
    return BASE_URL;
  }

  static post<T, R>(
    path: string,
    body = {},
    opts: Partial<ClientOptions> = {}
  ): Promise<ApiSuccessfulResponse<R>> {
    return request<T, R>(
      this.baseUrl(),
      { method: 'POST', url: path, data: body },
      opts
    );
  }

  static put<T, R>(
    path: string,
    body = {},
    opts: Partial<ClientOptions> = {}
  ): Promise<ApiSuccessfulResponse<R>> {
    return request<T, R>(
      this.baseUrl(),
      { method: 'PUT', url: path, data: body },
      opts
    );
  }

  static patch<T, R>(
    path: string,
    body = {},
    opts: Partial<ClientOptions> = {}
  ): Promise<ApiSuccessfulResponse<R>> {
    return request<T, R>(
      this.baseUrl(),
      { method: 'PATCH', url: path, data: body },
      opts
    );
  }

  static delete<T>(
    path: string,
    opts: Partial<ClientOptions> = {}
  ): Promise<ApiSuccessfulResponse<T>> {
    return request<void, T>(
      this.baseUrl(),
      { method: 'DELETE', url: path },
      opts
    );
  }

  static get<T>(
    path: string,
    opts: Partial<ClientOptions> = {}
  ): Promise<ApiSuccessfulResponse<T>> {
    return request<void, T>(this.baseUrl(), { method: 'GET', url: path }, opts);
  }

  static setSessionToken(token: string): void {
    setToken(token);
  }
  static getSessionToken(): string | null {
    return getToken();
  }
  static removeSessionToken() {
    removeToken();
  }
  static logoutUrl(errorMessage?: string): string {
    return logoutUrl(errorMessage);
  }
}
