import { renderTooltipText, ReportAssetName } from 'domain/reports';
import React from 'react';
import { PaginationProps } from 'types';
import { DynamicTable, TablePositionModifier } from 'ui';
import cn from 'classnames';

import style from './ReportDynamicTable.module.scss';

type Column<T> = {
  positionModifier?: TablePositionModifier;
  name: string;
  columnKey: string;
  render: (data: T) => JSX.Element | string | number | null | undefined;
  hasActions?: boolean;
  shouldSkipCollapse?: boolean;
  className?: (data: T) => string;
  dataTestId?: string;
  onClick?: (data: T) => void;
};

type Props<T> = {
  columns: Column<T>[];
  data: T[];
  isCollapsible?: boolean;
  isLoading?: boolean;
  pagination?: PaginationProps;
  noAutoPaginate?: boolean;
  rowKey: (item: T) => string;
  emptyText?: string;
  reportAssetName: ReportAssetName;
  reportAssetChildrenName?: ReportAssetName;
  childrenField?: string;
};

const ReportDynamicTable = <T extends unknown>({
  columns,
  data,
  rowKey,
  isLoading,
  pagination,
  emptyText,
  reportAssetName,
  reportAssetChildrenName,
  childrenField = '',
  isCollapsible,
  noAutoPaginate = true
}: Props<T>): JSX.Element => {
  return (
    <DynamicTable
      columns={columns}
      data={data}
      rowKey={rowKey}
      isLoading={isLoading}
      pagination={pagination}
      noAutoPaginate={noAutoPaginate}
      emptyText={emptyText}
      isCollapsible={isCollapsible}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getRowClassName={(item: any) =>
        cn(style.defaultRow, [
          {
            [style.isNew]:
              item.field.history?.isNew && !item.field.history?.isReportNew,
            [style.lastSeenAt]: item.field.history?.lastSeenAt,
            [style.defaultChildrenRow]: item.isChildrenRowOpen
          }
        ])
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      rowTooltipText={(item: any) =>
        item.isChildrenRowOpen && reportAssetChildrenName
          ? renderTooltipText(
              item[childrenField]?.history,
              reportAssetChildrenName
            )
          : renderTooltipText(item.field.history, reportAssetName)
      }
    />
  );
};

export default ReportDynamicTable;
