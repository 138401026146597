import React, { ReactElement } from 'react';
import cn from 'classnames';

import iconPaths from './iconPaths';

type Props = {
  name: string;
  fill?: string;
  small?: boolean;
  dataTestId?: string;
  loadMode?: boolean;
  className?: string;
};

const Icon = ({
  name,
  fill = '#000',
  small,
  dataTestId = 'icon',
  loadMode = false,
  className
}: Props): ReactElement => {
  const iconSize = small ? 16 : 24;
  const svgClassName = cn('icon', className, { '-loading': loadMode });

  return (
    <svg
      className={svgClassName}
      width={iconSize}
      height={iconSize}
      viewBox="0 0 24 24"
      data-testid={dataTestId}
    >
      {iconPaths[name]({ fill, loadMode })}
    </svg>
  );
};

export default Icon;
