import React, {
  useReducer,
  createContext,
  useContext,
  useEffect,
  ReactElement,
  useCallback
} from 'react';
import uniqBy from 'lodash/uniqBy';
import some from 'lodash/some';
import filter from 'lodash/filter';
import { Prompt } from 'react-router-dom';
import style from './FormDirtyContext.module.scss';

const CONTENT_AFTER_LAST_COMMA = new RegExp(/, ([^,]*)$/);

const showAlert = (event: BeforeUnloadEvent) => {
  event.preventDefault();
  event.returnValue = true;
  return true;
};

type State = {
  FormChangesMessage: () => JSX.Element;
  setDirtiness: ({ formKey, isDirty, formName }: FormDirty) => void;
  clearAll: () => void;
};

type Props = {
  children: ReactElement | ReactElement[];
};

type FormDirty = {
  formKey: string;
  isDirty: boolean;
  formName: string;
};

type ReducerState = {
  [key: string]: Pick<FormDirty, 'isDirty' | 'formName'>;
};

type ReducerAction = (FormDirty & { type: 'toggle' }) | { type: 'clearAll' };

const FormDirtyContext = createContext<State>({} as State);

const formReducer = (state: ReducerState, action: ReducerAction) => {
  switch (action.type) {
    case 'toggle':
      return {
        ...state,
        [action.formKey]: { isDirty: action.isDirty, formName: action.formName }
      };
    case 'clearAll': {
      return {};
    }
  }
};

const FormDirtyProvider = ({ children }: Props) => {
  const [forms, dispatch] = useReducer(formReducer, {});

  const setDirtiness = useCallback(
    ({ formName, formKey, isDirty }: FormDirty) => {
      dispatch({ type: 'toggle', formKey, formName, isDirty });
    },
    [dispatch]
  );

  const clearAll = useCallback(() => {
    dispatch({ type: 'clearAll' });
  }, [dispatch]);

  const isAnyFormDirty = some(forms, 'isDirty');

  const formChangesMessage = () => {
    if (!isAnyFormDirty) return <div />;

    const dirtyForms = filter(forms, 'isDirty');

    const formNames = uniqBy(dirtyForms, 'formName')
      .map(formEntry => formEntry.formName)
      .join(', ')
      .replace(CONTENT_AFTER_LAST_COMMA, ' e $1');

    return (
      <div className={style.info}>
        Existem alterações não salvas em {formNames}
      </div>
    );
  };

  useEffect(() => {
    if (isAnyFormDirty) {
      window.addEventListener('beforeunload', showAlert);
    }

    return function cleanup() {
      window.removeEventListener('beforeunload', showAlert);
    };
  }, [isAnyFormDirty]);

  return (
    <FormDirtyContext.Provider
      value={{
        FormChangesMessage: formChangesMessage,
        setDirtiness,
        clearAll
      }}
    >
      <Prompt
        when={isAnyFormDirty}
        message="Existem alterações não salvas. Deseja realmente sair da página?"
      />

      {children}
    </FormDirtyContext.Provider>
  );
};

const useFormDirty = () => {
  return useContext(FormDirtyContext);
};

export { useFormDirty, FormDirtyProvider, FormDirtyContext };
