export enum SummaryInfoType {
  DEBTORS_AND_CONTRACTS = 'debtorsContracts',
  DEBTORS_IN_JUDICIAL_RECOVERY = 'debtorsInJudicialRecovery',
  GUARANTEES = 'guarantees',
  EVENTS = 'events',
  SUSPECTS = 'suspects',
  ASSETS = 'assets',
  CITIES = 'cities',
  LAWSUITS = 'lawsuits'
}

export enum IndividualSummaryInfoType {
  REPORT_CITIES = 'reportCities',
  SAVED_POSTS = 'savedPosts',
  FAMILY = 'family',
  PARTNER_COMPANY = 'partnerCompany',
  REAL_ESTATE = 'realEstate',
  VEHICLE = 'vehicle',
  AIRPLANE = 'airplane',
  RURAL_PROPERTY = 'ruralProperty',
  ELECTORAL_DONATIONS = 'electoralDonations',
  LAWSUITS = 'lawsuits'
}

export enum CompanySummaryInfoType {
  REPORT_CITIES = 'reportCities',
  SOCIETAL_STRUCTURE = 'societalStructure',
  RELATED_COMPANIES = 'relatedCompanies',
  REAL_ESTATE = 'realEstate',
  VEHICLE = 'vehicle',
  AIRPLANE = 'airplane',
  RURAL_PROPERTY = 'ruralProperty',
  LOCATIONS = 'locations',
  LAWSUITS = 'lawsuits'
}
