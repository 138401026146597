import { useState, useCallback } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { ApiSuccessfulResponse, ApiPagination } from 'types';

export function useQuery<T, Params>(
  query: (params: Params) => Promise<ApiSuccessfulResponse<T>>,
  params: Params
) {
  const { isLoading, data, setData, error, request, pagination } = useQueryCall<
    T,
    Params
  >(query);

  useDeepCompareEffect(() => {
    request(params);
  }, [query, params]);

  return {
    isLoading,
    data,
    setData,
    error,
    refetch: request,
    pagination
  };
}

export function useQueryCall<T, Params>(
  query: (params: Params) => Promise<ApiSuccessfulResponse<T>>,
  defaultData?: { data?: T; pagination?: ApiPagination }
) {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<T | undefined>(defaultData?.data);
  const [error, setError] = useState();
  const [pagination, setPagination] = useState<ApiPagination | undefined>(
    defaultData?.pagination
  );

  const request = useCallback(
    async function request(params: Params) {
      try {
        setLoading(true);

        const response = await query(params);
        setData(response?.data);
        setPagination(response?.pagination);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    },
    [query]
  );

  return {
    isLoading,
    data,
    setData,
    error,
    request,
    pagination
  };
}
