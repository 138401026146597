import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { createCase } from 'api';
import { openErrorToast, openSuccessToast } from 'ui';

import { CaseFormAttributes, OnSubmit, CaseType } from 'types';

import Layout from 'Layouts/Layout';
import CaseTabs from '../CaseTabs';

const New = ({ history, match }: RouteComponentProps<{ type: CaseType }>) => {
  const [isSubmiting, setIsSubmiting] = useState(false);

  const onSubmit: OnSubmit<CaseFormAttributes> = async (
    values,
    formikHelpers
  ) => {
    setIsSubmiting(true);
    try {
      const response = await createCase(values);
      openSuccessToast('Caso cadastrado com sucesso!');
      history.push(`/cases/${response.data.id}`);
    } catch (error) {
      openErrorToast('Houve um erro ao cadastrar o caso');
      formikHelpers.setErrors(error.errors);
    } finally {
      setIsSubmiting(false);
    }
  };

  return (
    <Layout>
      <CaseTabs
        onSubmit={onSubmit}
        isSubmiting={isSubmiting}
        caseType={match.params.type}
      />
    </Layout>
  );
};

export default New;
