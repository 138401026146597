import { PermissionProfile, ProfileAttributes, ProfileItem } from 'types';
import Client from './client';

export function createProfile(profileAttributes: ProfileAttributes) {
  return Client.post<ProfileAttributes, ProfileItem>(
    `/permissions/profiles`,
    profileAttributes
  );
}

export function fetchProfiles() {
  return Client.get<ProfileItem[]>('/permissions/profiles');
}

export function fetchProfile(profileId: string) {
  return Client.get<PermissionProfile>(`/permissions/profiles/${profileId}`);
}

export function deleteProfile(profileId: string) {
  return Client.delete<void>(`/permissions/profiles/${profileId}`, {
    redirectOnNotFoundError: false
  });
}

export function copyPermissions(
  sourceProfileId: string,
  targetProfileId: string
) {
  return Client.post<{ source: string; target: string }, PermissionProfile>(
    `/permissions/profiles/copy_permissions`,
    { sourceId: sourceProfileId, targetId: targetProfileId }
  );
}

export function enablePermission(permissionId: string, profileId: string) {
  return Client.post<void, void>(
    `/permissions/${permissionId}/profiles/${profileId}`,
    {
      redirectOnNotFoundError: false
    }
  );
}

export function disablePermission(permissionId: string, profileId: string) {
  return Client.delete<void>(
    `/permissions/${permissionId}/profiles/${profileId}`,
    {
      redirectOnNotFoundError: false
    }
  );
}
