import { Report } from './report';
import { Actions } from './action';
import { Asset } from './assets';

export enum EntityRole {
  DEBTOR = 'debtor',
  SUSPECT = 'suspect',
  PAYOR = 'payor',
  GUARANTOR = 'guarantor'
}

export enum EntityType {
  COMPANY = 'company',
  INDIVIDUAL = 'individual'
}

export enum EntityRoleCompany {
  ABROAD_PARTNER = 'abroad_partner',
  ADMINISTRATOR = 'administrator',
  DIRECTOR = 'director',
  EMPLOYEER = 'employeer',
  PARTNER = 'partner',
  PRESIDENT = 'president'
}

export enum EntityRoleIndividual {
  AUNT_OR_UNCLE = 'aunt_or_uncle',
  CHILD = 'child',
  COUSIN = 'cousin',
  DONOR = 'donor',
  FATHER = 'father',
  GRANDPARENT = 'grandparent',
  GRANDCHILD = 'grandchild',
  MOTHER = 'mother',
  RELATIVE = 'relative',
  SIBLING = 'sibling',
  SPOUSE = 'spouse',
  PARTNER = 'partner'
}

export type Entity = EntityFormAttributes & Pick<EntityAttributes, 'id'>;

export type SearchEntity = {
  entitiesSearch: Array<Entity>;
  suggestions: Array<Entity>;
};

export type Individual = {
  name: string;
  cpf: string | null;
  isAbroad: boolean;
};

export type Company = {
  cnpj: string | null;
  businessName: string;
  isAbroad: boolean;
};

export type EntityAttributes = {
  assets: Asset[];
  id: string;
  type: EntityType;
  roles: Array<string>;
  individual: Individual | null;
  company: Company | null;
  currentReport: Report | null;
  description: string | null;
};

type CaseIdAndActions = {
  caseId: string;
  actions: Actions;
};

export type CaseEntity = EntityAttributes & CaseIdAndActions;

export type EntityFormAttributes = {
  type: EntityType;
  individual: Individual;
  company: Company;
  description: string;
};

export type EntityWithRole = {
  entity: EntityWithRelations | null;
  id: string;
  name: string | null;
  parentEntity: EntityWithRelations | null;
  parentReport: Report | null;
  report: Report | null;
  role: EntityRoleCompany | EntityRoleIndividual;
};

export type EntityWithRelations = EntityAttributes &
  Pick<CaseEntity, 'caseId'> & {
    relationships: EntityWithRole[];
  };

export type EntityWithReports = EntityAttributes &
  Pick<CaseEntity, 'caseId'> &
  Pick<EntityWithRelations, 'relationships'> &
  Pick<Report, 'selectedInstagramProfile'>;
