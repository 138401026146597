import {
  ApiSuccessfulResponse,
  ReportAttributes,
  Report,
  CaseEntity,
  LegalReportQuotation,
  LegalReportOrder,
  RequestQueryParams
} from 'types';

import Client from './client';

function createReport(reportData: ReportAttributes) {
  return Client.post<ReportAttributes, Report>(
    `cases/${reportData.caseId}/reports`,
    reportData
  );
}

function deleteReport(caseId: string, reportId: string) {
  return Client.delete(`cases/${caseId}/reports/${reportId}`);
}

function createEntityFromReport(caseId: string, reportId: string) {
  return Client.post<void, CaseEntity>(
    `cases/${caseId}/reports/${reportId}/entities`
  );
}

function createEntityReport(caseId: string, entityId: string) {
  return Client.post<void, CaseEntity>(
    `cases/${caseId}/entities/${entityId}/report`
  );
}

function refreshReport(
  caseId: string,
  reportId: string
): Promise<ApiSuccessfulResponse<Report>> {
  return Client.put<void, Report>(`cases/${caseId}/reports/${reportId}`);
}

function retryReportFailedQueries(
  caseId: string,
  reportId: string
): Promise<ApiSuccessfulResponse<Report>> {
  return Client.post<void, Report>(`cases/${caseId}/reports/${reportId}/retry`);
}

type FetchReportsParams = RequestQueryParams & Pick<Report, 'caseId'>;

function fetchReports({ caseId, pagination, orderBy }: FetchReportsParams) {
  return Client.get<Report[]>(`cases/${caseId}/reports`, {
    params: {
      page: pagination?.page.toString(),
      pageSize: pagination?.pageSize.toString(),
      field: orderBy?.field,
      value: orderBy?.value
    }
  });
}

function fetchReport({
  caseId,
  reportId
}: {
  caseId: string;
  reportId: string;
}) {
  return Client.get<Report>(`cases/${caseId}/reports/${reportId}`);
}

function createReportExternalLawsuitQueries({
  caseId,
  reportId
}: {
  caseId: string;
  reportId: string;
}) {
  return Client.post<void, Report>(
    `cases/${caseId}/reports/${reportId}/external_lawsuits_queries`
  );
}

function createLegalReportQuotation({
  caseId,
  reportId
}: {
  caseId: string;
  reportId: string;
}) {
  return Client.post<void, LegalReportQuotation>(
    `cases/${caseId}/reports/${reportId}/legal_report_quotations`
  );
}

function createLegalReportClone({
  caseId,
  reportId
}: {
  caseId: string;
  reportId: string;
}) {
  return Client.post<void, void>(
    `cases/${caseId}/reports/${reportId}/legal_report_clone`
  );
}

function fetchLegalReportQuotation({
  caseId,
  reportId,
  quotationId
}: {
  caseId: string;
  reportId: string;
  quotationId: string;
}) {
  return Client.get<LegalReportQuotation>(
    `cases/${caseId}/reports/${reportId}/legal_report_quotations/${quotationId}`
  );
}

function createLegalReportOrder({
  caseId,
  reportId,
  quotationId
}: {
  caseId: string;
  reportId: string;
  quotationId: string;
}) {
  return Client.post<void, LegalReportOrder>(
    `cases/${caseId}/reports/${reportId}/legal_report_quotations/${quotationId}/legal_report_orders`
  );
}

function updateReportSelectedInstagram({
  caseId,
  reportId,
  selectedInstagramProfileId
}: {
  caseId: string;
  reportId: string;
  selectedInstagramProfileId: string;
}) {
  return Client.patch<{ selectedInstagramProfileId: string }, void>(
    `cases/${caseId}/reports/${reportId}/selected_instagram`,
    { selectedInstagramProfileId }
  );
}

function bookmarkInstagramPublication({
  caseId,
  reportId,
  instagramPublicationId
}: {
  caseId: string;
  reportId: string;
  instagramPublicationId: string;
}) {
  return Client.post<{ instagramPublicationId: string }, void>(
    `cases/${caseId}/reports/${reportId}/bookmarks`,
    { instagramPublicationId }
  );
}

function unbookmarkInstagramPublication({
  caseId,
  reportId,
  instagramPublicationId
}: {
  caseId: string;
  reportId: string;
  instagramPublicationId: string;
}) {
  return Client.delete(
    `cases/${caseId}/reports/${reportId}/bookmarks/${instagramPublicationId}`
  );
}

export {
  createEntityFromReport,
  createEntityReport,
  createReport,
  deleteReport,
  fetchReports,
  fetchReport,
  refreshReport,
  retryReportFailedQueries,
  createReportExternalLawsuitQueries,
  createLegalReportQuotation,
  createLegalReportClone,
  fetchLegalReportQuotation,
  createLegalReportOrder,
  updateReportSelectedInstagram,
  bookmarkInstagramPublication,
  unbookmarkInstagramPublication
};
