import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from 'components';

import Create from './Create';
import Edit from './Edit';
import List from './List/InternalLawsuits';

const LawsuitsRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path={path} component={List} />

      <PrivateRoute exact path={`${path}/create`} component={Create} />

      <PrivateRoute path={`${path}/:lawsuitId/:lawsuitTab?`} component={Edit} />
    </Switch>
  );
};

export default LawsuitsRoutes;
