import React, { useContext } from 'react';

import { useFeatureFlag } from 'hooks';

import { Stack, StackMargin } from 'ui';
import { Feature } from 'types';
import { can, Action } from 'api';
import { CaseContext } from 'components';
import Assets from './Assets';
import ExternalLawsuits from './ExternalLawsuits';
import Locations from './Locations';
import People from './People';
import BookmarkedInstagramPublications from './BookmarkedInstagramPublications';
import Cities from './Cities';

type Props = {
  caseId: string;
};

const Resources = ({ caseId }: Props) => {
  const [isExternalLawsuitsEnabled] = useFeatureFlag(Feature.EXTERNAL_LAWSUITS);
  const [isBookmarkInstagramPublicationEnabled] = useFeatureFlag(
    Feature.BOOKMARK_INSTAGRAM_PUBLICATION
  );

  const { caseData } = useContext(CaseContext);

  const negotiatorId = caseData?.negotiator?.id;

  const canUser = {
    showSuspects: can(
      Action.CASOS_RASTREAMENTO_SUSPEITOS_CONSULTAR,
      negotiatorId
    ),
    showReports: can(
      Action.CASOS_RASTREAMENTO_CONSULTAS_CONSULTAR,
      negotiatorId
    ),
    showAssets: can(Action.CASOS_RASTREAMENTO_ATIVOS_CONSULTAR, negotiatorId),
    showLocation: can(
      Action.CASOS_RASTREAMENTO_LOCALIZACOES_CONSULTAR,
      negotiatorId
    ),
    showCities: can(Action.CASOS_RASTREAMENTO_PRACAS_CONSULTAR, negotiatorId),
    showExternalLawsuits: can(
      Action.CASOS_RASTREAMENTO_PROCESSOS_CONSULTAR,
      negotiatorId
    ),
    showBookmarkedInstagramPublications: can(
      Action.CASOS_RASTREAMENTO_POSTAGENS_SALVAS_CONSULTAR,
      negotiatorId
    )
  };

  return (
    <div>
      {(canUser.showSuspects || canUser.showReports) && (
        <Stack>
          <People caseId={caseId} />
        </Stack>
      )}
      {canUser.showAssets && (
        <Stack marginTop={StackMargin.XLARGE}>
          <Assets caseId={caseId} />
        </Stack>
      )}
      {canUser.showLocation && (
        <Stack marginTop={StackMargin.XLARGE}>
          <Locations caseId={caseId} />
        </Stack>
      )}
      {canUser.showCities && (
        <Stack marginTop={StackMargin.XLARGE}>
          <Cities caseId={caseId} />
        </Stack>
      )}

      {canUser.showExternalLawsuits && isExternalLawsuitsEnabled && (
        <Stack marginTop={StackMargin.XLARGE}>
          <ExternalLawsuits caseId={caseId} />
        </Stack>
      )}
      {canUser.showBookmarkedInstagramPublications &&
        isBookmarkInstagramPublicationEnabled && (
          <Stack marginTop={StackMargin.XLARGE}>
            <BookmarkedInstagramPublications caseId={caseId} />
          </Stack>
        )}
    </div>
  );
};

export default Resources;
