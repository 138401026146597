import React from 'react';
import { FormikProps } from 'formik';

import { fetchCreditContracts, fetchTrackedExternalLawsuitsSummary } from 'api';
import { useQuery } from 'hooks';

import { ExecutionRelatedAttributes, ExecutionType } from 'types';

import GuaranteeFields from './ExecutedGuaranteeIdField';
import AssetFields from './ExecutedAssetIdField';
import ExternalLawsuitFields from './ExecutedExternalLawsuitField';

type Props = {
  caseId: string;
  selectedType: ExecutionType;
  formik: FormikProps<ExecutionRelatedAttributes>;
};

const ExecutedFields = ({ selectedType, caseId, formik }: Props) => {
  const {
    data: contracts = [],
    isLoading: isContratsLoading
  } = useQuery(fetchCreditContracts, { caseId });

  const {
    data: externalLawsuits = [],
    isLoading: isExternalLawsuitsLoading
  } = useQuery(fetchTrackedExternalLawsuitsSummary, { caseId });

  const fields = {
    [ExecutionType.ASSET]: <AssetFields caseId={caseId} formik={formik} />,
    [ExecutionType.GUARANTEE]: (
      <GuaranteeFields
        formik={formik}
        contracts={contracts}
        isLoading={isContratsLoading}
      />
    ),
    [ExecutionType.EXTERNAL_LAWSUIT]: (
      <ExternalLawsuitFields
        formik={formik}
        externalLawsuits={externalLawsuits}
        isLoading={isExternalLawsuitsLoading}
      />
    )
  };

  return fields[selectedType];
};

export default ExecutedFields;
