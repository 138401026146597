import { handleDateFieldChange } from 'domain/forms';
import React, { useContext } from 'react';
import { MovementFormAttributes, OnSubmit, Movement } from 'types';
import * as Yup from 'yup';
import { useFormik, getIn } from 'formik';
import { can, Action } from 'api';
import {
  FormContainer,
  InputField,
  TextArea,
  Button,
  DatePickerField,
  StackMargin,
  Stack
} from 'ui';
import { FileField, CaseContext } from 'components';

type Props = {
  caseId: string;
  movementData?: Movement;
  onSubmit: OnSubmit<MovementFormAttributes>;
};

const initialValues: MovementFormAttributes = {
  content: '',
  date: '',
  description: '',
  fileUploadIds: [],
  title: ''
};

const validationSchema = Yup.object().shape({
  date: Yup.string().required('Campo obrigatório'),
  description: Yup.string().required('Campo obrigatório'),
  title: Yup.string().required('Campo obrigatório')
});

const formatInitialData = (movementData: Movement | undefined) => {
  if (!movementData) return initialValues;

  return {
    ...movementData,
    title: movementData.title || '',
    description: movementData.description || '',
    content: movementData.content || '',
    fileUploadIds: movementData.fileUploads.map(file => file.id)
  };
};

const Form = ({ caseId, movementData, onSubmit }: Props) => {
  const formik = useFormik({
    initialValues: formatInitialData(movementData),
    validationSchema,
    onSubmit
  });

  const { caseData } = useContext(CaseContext);
  const negotiatorId = caseData?.negotiator?.id;
  const canUser = {
    accessMovementFiles: can(
      Action.CASOS_PROCESSO_ARQUIVOS_DO_MOVIMENTO_CONSULTAR,
      negotiatorId
    ),
    addMovementFile: can(
      Action.CASOS_PROCESSO_ARQUIVOS_DO_MOVIMENTO_INCLUIR,
      negotiatorId
    ),
    deleteMovementFile: can(
      Action.CASOS_PROCESSO_ARQUIVOS_DO_MOVIMENTO_EXCLUIR,
      negotiatorId
    )
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormContainer className="col-2">
        <InputField
          id="movement-title"
          name="title"
          title="Título"
          type="text"
          value={formik.values.title}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={formik.touched.title && getIn(formik.errors, 'title')}
        />
        <DatePickerField
          id="movement-date"
          name="date"
          title="Data"
          placeholder="Selecione..."
          value={formik.values.date}
          onChange={handleDateFieldChange(
            formik.setFieldValue,
            formik.setFieldTouched,
            'date'
          )}
          onBlur={formik.handleBlur}
          error={formik.touched.date && getIn(formik.errors, 'date')}
        />
      </FormContainer>
      <FormContainer>
        <TextArea
          id="movement-description"
          name="description"
          title="Descrição"
          value={formik.values.description}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={
            formik.touched.description && getIn(formik.errors, 'description')
          }
        />
        <TextArea
          id="movement-content"
          name="content"
          title="Conteúdo"
          value={formik.values.content}
          rows={2}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={formik.touched.content && getIn(formik.errors, 'content')}
          dataTestId="movement-content"
        />
        {canUser.accessMovementFiles && (
          <FileField
            caseId={caseId}
            title="Arquivos"
            name="fileUploadIds"
            id="fileUploadIds"
            initialFiles={movementData?.fileUploads}
            onUploadSuccess={ids => {
              formik.setFieldValue('fileUploadIds', ids);
            }}
            dataTestId="movement-file-upload"
            showAdd={canUser.addMovementFile}
            showDelete={canUser.deleteMovementFile}
          />
        )}
      </FormContainer>
      <Stack marginTop={StackMargin.MEDIUM}>
        <Button highlight centered onClick={formik.handleSubmit}>
          {movementData ? 'Editar movimentação' : 'Criar movimentação'}
        </Button>
      </Stack>
    </form>
  );
};

export default Form;
