import { lawsuitNumber, STATUS_TYPE_DICTIONARY } from 'domain/lawsuits';
import React from 'react';
import { LawsuitCard } from 'components';
import { applyDateMask, applyMask, handleNullValue } from 'utils';
import { StatusType, InternalLawsuit } from 'types';
import { Stack, StackMargin, Loader, Toast, ToastType } from 'ui';

import style from './Lawsuits.module.scss';

import InvolvedParties from './InvolvedParties';

type Props = {
  internalLawsuits: InternalLawsuit[];
  isLoading: boolean;
  error?: boolean;
};

const InternalLawsuitsList = ({
  internalLawsuits,
  isLoading,
  error
}: Props) => {
  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return (
      <Toast
        type={ToastType.ERROR}
        message={'Erro ao listar processos, tente novamente.'}
      />
    );
  }

  return (
    <div className={style.cardsWrapper}>
      {internalLawsuits.map(internalLawsuit => (
        <LawsuitCard
          key={internalLawsuit.id}
          lawsuit={internalLawsuit}
          className={style.card}
          title={
            <h3 className={style.cardTitle}>
              {lawsuitNumber(internalLawsuit.number)}
            </h3>
          }
        >
          <div className={style.causeWrapper}>
            <span className={style.causeDate} data-testid="cause-date">
              {`
                ${STATUS_TYPE_DICTIONARY[internalLawsuit.status as StatusType]}:
              `}
              <Stack marginLeft={StackMargin.SMALL} className={style.bold}>
                {handleNullValue(
                  internalLawsuit.causeInitialDate,
                  'string',
                  value => applyDateMask(value)
                )}
              </Stack>
            </span>

            <span className={style.causeValue} data-testid="cause-value">
              Valor da Causa:
              <Stack marginLeft={StackMargin.SMALL} className={style.bold}>
                {handleNullValue(
                  internalLawsuit.causeInitialValue,
                  'string',
                  value => applyMask('currency', value)
                )}
              </Stack>
            </span>
          </div>

          <InvolvedParties parties={internalLawsuit.parties} />
        </LawsuitCard>
      ))}
    </div>
  );
};

export default InternalLawsuitsList;
