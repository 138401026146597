import { City, CaseCity, ReportCaseCity } from 'types';
import Client from './client';

export function fetchCities({ state }: { state: string }) {
  return Client.get<City[]>('cities/search', { params: { state: state } });
}

export function fetchCaseCities({ caseId }: { caseId: string }) {
  return Client.get<CaseCity[]>(`cases/${caseId}/cities`);
}

export function fetchReportCaseCities({
  caseId,
  reportId
}: {
  caseId: string;
  reportId: string;
}) {
  return Client.get<ReportCaseCity[]>(
    `cases/${caseId}/reports/${reportId}/cities`
  );
}
