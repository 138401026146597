import {
  formatInitialValues as formatAssetInitialValues,
  assetRealEstateIdentificationLabel,
  assetTypeOptions,
  assetValidationSchema,
  assetRuralPropertyTypeOptions,
  assetRealEstateTypeOptions,
  assetOriginOptions,
  occupancyOptions
} from 'domain/assets';
import { executionDegreeOptions } from 'domain/executions';
import React from 'react';
import * as Yup from 'yup';
import { useFormik, getIn } from 'formik';
import {
  InputField,
  SelectField,
  Toggle,
  FormContainer,
  Button,
  openErrorToast,
  openSuccessToast,
  StackMargin,
  Stack
} from 'ui';
import {
  ExecutionAttributes,
  Execution,
  OnSubmit,
  ApiError,
  EntityType,
  ExecutionType,
  AssetType
} from 'types';
import { createExecution } from 'api';
import { AutoCompleteField } from 'components';

const validationSchema = Yup.object().shape({
  degree: Yup.string()
    .required('Campo obrigatório')
    .nullable(),
  executedAsset: assetValidationSchema
});

type Props = {
  onCreate: (execution: Execution) => void;
  caseId: string;
  internalLawsuitId: string;
};

const formatError = (errors?: ApiError) => {
  if (!errors) return {};

  return {
    ...errors,
    entity: { individual: errors?.individual, company: errors?.company }
  };
};

const initialValues = {
  degree: null,
  type: ExecutionType.ASSET,
  executedAsset: formatAssetInitialValues()
};

const formatInitialValues = (
  executionData?: Execution
): ExecutionAttributes => {
  if (!executionData) return initialValues;

  return {
    degree: executionData.degree,
    type: executionData.type,
    executedAsset: formatAssetInitialValues(executionData.executedAsset)
  };
};

const Form = ({ onCreate, caseId, internalLawsuitId }: Props) => {
  const onSubmit: OnSubmit<ExecutionAttributes> = async (
    values,
    formikHelpers
  ) => {
    try {
      const response = await createExecution(caseId, internalLawsuitId, values);

      onCreate(response.data);

      openSuccessToast('Penhora salva com sucesso!');
    } catch (error) {
      formikHelpers.setErrors(formatError(error.errors));
      openErrorToast('Houve um erro ao salvar a Penhora');
    }
  };

  const formik = useFormik({
    initialValues: formatInitialValues(),
    validationSchema,
    onSubmit: onSubmit
  });

  return (
    <form>
      <FormContainer className="col-3">
        <SelectField
          id="degree"
          name="degree"
          value={formik.values.degree?.toString()}
          title="Grau da penhora"
          error={
            getIn(formik.touched, 'degree') && getIn(formik.errors, 'degree')
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          options={executionDegreeOptions()}
          optional
        />

        <SelectField
          id="type"
          name="executedAsset.type"
          value={formik.values.executedAsset.type}
          title="Tipo"
          error={
            getIn(formik.touched, 'executedAsset.type') &&
            getIn(formik.errors, 'executedAsset.type')
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          options={assetTypeOptions()}
        />

        <InputField
          id="name"
          name="executedAsset.name"
          type="text"
          value={formik.values.executedAsset.name}
          error={
            getIn(formik.touched, 'executedAsset.name') &&
            getIn(formik.errors, 'executedAsset.name')
          }
          title="Nome do ativo"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        <InputField
          id="identification"
          name="executedAsset.identification"
          type="text"
          value={formik.values.executedAsset.identification}
          error={
            getIn(formik.touched, 'executedAsset.identification') &&
            getIn(formik.errors, 'executedAsset.identification')
          }
          title={`${assetRealEstateIdentificationLabel(
            formik.values.executedAsset.type
          )} (opcional)`}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        {formik.values.executedAsset.type === AssetType.REAL_ESTATE && (
          <>
            <SelectField
              id="assetRealEstate.type"
              name="assetRealEstate.type"
              value={formik.values?.executedAsset.assetRealEstate?.type ?? ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              options={assetRealEstateTypeOptions()}
              title="Subtipo (opcional)"
              optional
            />

            <Toggle
              checked={formik.values?.executedAsset.assetRealEstate?.occupied?.toString()}
              name="assetRealEstate.occupied"
              onChange={event =>
                formik.setFieldValue(
                  'assetRealEstate.occupied',
                  event.target.value === 'true'
                )
              }
              options={occupancyOptions}
              title="Ocupação (opcional)"
            />

            <SelectField
              id="assetRealEstate.origin"
              name="assetRealEstate.origin"
              value={formik.values?.executedAsset.assetRealEstate?.origin ?? ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              options={assetOriginOptions()}
              title="Origem (opcional)"
              optional
            />
          </>
        )}

        {formik.values.executedAsset.type === AssetType.RURAL_PROPERTY && (
          <>
            <SelectField
              id="assetRuralProperty.type"
              name="assetRuralProperty.type"
              value={
                formik.values?.executedAsset.assetRuralProperty?.type ?? ''
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              options={assetRuralPropertyTypeOptions()}
              title="Subtipo (opcional)"
              optional
            />

            <Toggle
              checked={formik.values?.executedAsset.assetRuralProperty?.occupied?.toString()}
              name="assetRuralProperty.occupied"
              onChange={event =>
                formik.setFieldValue(
                  'assetRuralProperty.occupied',
                  event.target.value === 'true'
                )
              }
              options={occupancyOptions}
              title="Ocupação (opcional)"
            />

            <SelectField
              id="assetRuralProperty.origin"
              name="assetRuralProperty.origin"
              value={
                formik.values?.executedAsset.assetRuralProperty?.origin ?? ''
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              options={assetOriginOptions()}
              title="Origem (opcional)"
              optional
            />
          </>
        )}

        <Toggle
          title="Tipo de pessoa proprietária"
          options={[
            { value: 'individual', label: 'Física' },
            { value: 'company', label: 'Jurídica' }
          ]}
          onChange={event =>
            formik.setFieldValue(
              'executedAsset.entity.type',
              event.target.value
            )
          }
          checked={formik.values.executedAsset.entity.type}
        />

        {formik.values.executedAsset.entity.type === 'individual' ? (
          <React.Fragment>
            <Toggle
              name="entity.individual.isAbroad"
              title="País"
              options={[
                { value: 'false', label: 'Brasil' },
                { value: 'true', label: 'Exterior' }
              ]}
              checked={formik.values.executedAsset.entity.individual.isAbroad.toString()}
              onChange={event =>
                formik.setFieldValue(
                  'executedAsset.entity.individual.isAbroad',
                  event.target.value
                )
              }
            />

            <AutoCompleteField
              caseId={caseId}
              isAbroad={formik.values.executedAsset.entity.individual.isAbroad}
              entityType={EntityType.INDIVIDUAL}
              onSelect={selection => {
                formik.setFieldValue(
                  'executedAsset.entity.individual.cpf',
                  selection!.value
                );
                formik.setFieldValue(
                  'executedAsset.entity.individual.name',
                  selection!.complement
                );
              }}
              dataToItem={data => ({
                value: data.individual.cpf,
                complement: data.individual.name
              })}
              id="executedAsset-entity-cpf"
              name="executedAsset.entity.individual.cpf"
              type="cpf"
              value={formik.values.executedAsset.entity.individual.cpf}
              error={
                getIn(formik.touched, 'executedAsset.entity.individual.cpf') &&
                getIn(formik.errors, 'executedAsset.entity.individual.cpf')
              }
              title="CPF do proprietário"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            <AutoCompleteField
              caseId={caseId}
              isAbroad={formik.values.executedAsset.entity.individual.isAbroad}
              entityType={EntityType.INDIVIDUAL}
              onSelect={selection => {
                formik.setFieldValue(
                  'executedAsset.entity.individual.name',
                  selection!.value
                );
                formik.setFieldValue(
                  'executedAsset.entity.individual.cpf',
                  selection!.complement
                );
              }}
              dataToItem={data => ({
                value: data.individual.name,
                complement: data.individual.cpf
              })}
              id="executedAsset-entity-name"
              name="executedAsset.entity.individual.name"
              type="text"
              value={formik.values.executedAsset.entity.individual.name}
              error={
                getIn(formik.touched, 'executedAsset.entity.individual.name') &&
                getIn(formik.errors, 'executedAsset.entity.individual.name')
              }
              title="Nome completo do proprietário"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Toggle
              name="executedAsset.entity.company.isAbroad"
              title="País"
              options={[
                { value: 'false', label: 'Brasil' },
                { value: 'true', label: 'Exterior' }
              ]}
              checked={formik.values.executedAsset.entity.company.isAbroad.toString()}
              onChange={event =>
                formik.setFieldValue(
                  'executedAsset.entity.company.isAbroad',
                  event.target.value
                )
              }
            />

            <AutoCompleteField
              caseId={caseId}
              isAbroad={formik.values.executedAsset.entity.company.isAbroad}
              entityType={EntityType.COMPANY}
              onSelect={selection => {
                formik.setFieldValue(
                  'executedAsset.entity.company.cnpj',
                  selection!.value
                );
                formik.setFieldValue(
                  'executedAsset.entity.company.businessName',
                  selection!.complement
                );
              }}
              dataToItem={data => ({
                value: data.company.cnpj,
                complement: data.company.businessName
              })}
              id="executedAsset-entity-cnpj"
              name="executedAsset.entity.company.cnpj"
              type="cnpj"
              value={formik.values.executedAsset.entity.company.cnpj}
              error={
                getIn(formik.touched, 'executedAsset.entity.company.cnpj') &&
                getIn(formik.errors, 'executedAsset.entity.company.cnpj')
              }
              title="CNPJ do proprietário"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            <AutoCompleteField
              caseId={caseId}
              isAbroad={formik.values.executedAsset.entity.company.isAbroad}
              entityType={EntityType.COMPANY}
              onSelect={selection => {
                formik.setFieldValue(
                  'executedAsset.entity.company.businessName',
                  selection!.value
                );
                formik.setFieldValue(
                  'executedAsset.entity.company.cnpj',
                  selection!.complement
                );
              }}
              dataToItem={data => ({
                value: data.company.businessName,
                complement: data.company.cnpj
              })}
              id="executedAsset-entity-businessName"
              name="executedAsset.entity.company.businessName"
              type="text"
              value={formik.values.executedAsset.entity.company.businessName}
              error={
                getIn(
                  formik.touched,
                  'executedAsset.entity.company.businessName'
                ) &&
                getIn(
                  formik.errors,
                  'executedAsset.entity.company.businessName'
                )
              }
              title="Razão social do proprietário"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </React.Fragment>
        )}
      </FormContainer>
      <Stack marginTop={StackMargin.MEDIUM}>
        <Button onClick={formik.handleSubmit} highlight centered type="submit">
          Adicionar
        </Button>
      </Stack>
    </form>
  );
};

export default Form;
