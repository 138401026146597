import {
  AddContentToCase,
  CaseEntity,
  FetchReportTableProps,
  ReportPartner,
  Report
} from 'types';
import Client from './client';

export function fetchReportPartners({
  caseId,
  pagination,
  reportId
}: FetchReportTableProps) {
  return Client.get<ReportPartner[]>(
    `cases/${caseId}/reports/${reportId}/contents/partners`,
    {
      params: {
        page: pagination?.page.toString(),
        pageSize: pagination?.pageSize.toString()
      }
    }
  );
}

export function fetchReportPartnersSummary({
  caseId,
  reportId
}: FetchReportTableProps) {
  return Client.get<ReportPartner[]>(
    `cases/${caseId}/reports/${reportId}/contents/partners/summary`
  );
}

export function addPartnersToCase({
  caseId,
  contentId,
  reportId
}: AddContentToCase) {
  return Client.post<unknown, CaseEntity>(
    `cases/${caseId}/reports/${reportId}/contents/partners/${contentId}`
  );
}

export function createReportFromPartners({
  caseId,
  contentId,
  reportId
}: AddContentToCase) {
  return Client.post<unknown, Report>(
    `cases/${caseId}/reports/${reportId}/contents/partners/${contentId}/reports`
  );
}
