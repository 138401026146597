import { BadgeColor } from 'components/ColoredBadge/ColoredBadge';
import { CPFSituation } from 'types';

interface CPFSituationInterface {
  [key: string]: BadgeColor;
}

export const CPFSituationColor: CPFSituationInterface = {
  [CPFSituation.FALECIDO]: BadgeColor.RED,
  [CPFSituation.REGULAR]: BadgeColor.GREEN,
  [CPFSituation.SUSPENSA]: BadgeColor.DEFAULT,
  [CPFSituation.PENDENTE]: BadgeColor.DEFAULT,
  [CPFSituation.CANCELADA_MULTIPLICIDADE]: BadgeColor.DEFAULT,
  [CPFSituation.CENCELADA_OFICIO]: BadgeColor.DEFAULT,
  [CPFSituation.NULA]: BadgeColor.DEFAULT
};
