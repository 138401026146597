import React, { useEffect, useState } from 'react';
import {
  generatePath,
  useRouteMatch,
  withRouter,
  useLocation
} from 'react-router-dom';

import {
  ExternalLawsuit as ExternalLawsuitType,
  Feature,
  TabContent as TabContentProps,
  LocationState
} from 'types';
import {
  fetchExternalLawsuit,
  toggleExternalLawsuitTracking,
  toggleExternalLawsuitMonitoring
} from 'api/externalLawsuits';
import { Action, can } from 'api/permissions';
import { useFeatureFlag, useLiveLawsuit, useQuery } from 'hooks';

import Layout from 'Layouts/Layout';
import {
  Header,
  Link,
  openErrorToast,
  TabContent,
  Tabs,
  Title,
  Button,
  openSuccessToast,
  ToolTip
} from 'ui';
import BasicInformation from './BasicInformation';
import Movements from './Movements';

import style from './ExternalLawsuit.module.scss';

const ExternalLawsuit = () => {
  const match: {
    params: { caseId: string; externalLawsuitId: string; tab: string };
    isExact: boolean;
    path: string;
    url: string;
  } = useRouteMatch();
  const location = useLocation<LocationState>();

  const { caseId, externalLawsuitId } = match.params;

  const {
    data: externalLawsuit,
    error,
    isLoading,
    setData,
    refetch
  } = useQuery<
    ExternalLawsuitType,
    { caseId: string; externalLawsuitId: string }
  >(fetchExternalLawsuit, { caseId, externalLawsuitId });

  useLiveLawsuit(`cases:${caseId}:external_lawsuits:${externalLawsuitId}`, () =>
    refetch({ caseId, externalLawsuitId })
  );

  const [returnTo, setReturnTo] = useState<string>();
  const [isLoadingLawsuitTracking, setIsLoadingLawsuitTracking] = useState<
    boolean
  >(false);
  const [isLoadingLawsuitMonitoring, setIsLoadingLawsuitMonitoring] = useState<
    boolean
  >(false);

  const [isExternalLawsuitsMonitoringEnabled] = useFeatureFlag(
    Feature.EXTERNAL_LAWSUITS_MONITORING
  );

  useEffect(() => {
    if (location.state?.returnTo) {
      setReturnTo(location.state.returnTo);
    }
  }, [location.state]);

  const linkTo = (newTab: TabContentProps) => {
    if (!newTab.path) return;

    const pathname = generatePath(`${match.path}`, {
      caseId,
      externalLawsuitId,
      tab: newTab.path
    });

    return {
      pathname: pathname,
      state: { returnTo: returnTo }
    };
  };

  const buildTitle = () => {
    const returnToElements = returnTo?.split('/');
    const isAssetTracking =
      returnToElements?.includes('reports') ||
      returnToElements?.includes('resources');

    return isAssetTracking ? 'Rastreamento de Ativos' : 'Processos Judiciais';
  };

  const isTracked = externalLawsuit?.isTracked ?? false;
  const isMonitored = externalLawsuit?.isMonitored ?? false;
  const number = externalLawsuit?.number;
  const hasToDisableTrackButton =
    isTracked && !!externalLawsuit?.executions.length;

  const trackLawsuitText = isTracked
    ? 'Remover do rastreamento de ativos'
    : 'Adicionar ao rastreamento de ativos';

  const monitorLawsuitText = isMonitored
    ? 'Deixar de acompanhar processo'
    : 'Acompanhar processo';

  const toggleTracking = async () => {
    try {
      setIsLoadingLawsuitTracking(true);
      const response = await toggleExternalLawsuitTracking({
        caseId,
        externalLawsuitId
      });

      setData(response.data);
      setIsLoadingLawsuitTracking(false);
      openSuccessToast(
        `Processo ${
          response.data.isTracked ? 'adicionado ao' : 'removido do'
        } rastreamento de ativos.`
      );
    } catch (error) {
      openErrorToast(`Não foi possível ${trackLawsuitText.toLowerCase()}.`);
    }
  };
  const toggleMonitoring = async () => {
    try {
      setIsLoadingLawsuitMonitoring(true);
      const response = await toggleExternalLawsuitMonitoring({
        caseId,
        externalLawsuitId
      });

      setData(response.data);
      setIsLoadingLawsuitMonitoring(false);
      openSuccessToast(
        `Processo ${
          response.data.isMonitored ? 'em' : 'não está em'
        } acompanhamento.`
      );
    } catch (error) {
      openErrorToast(`Não foi possível ${monitorLawsuitText.toLowerCase()}.`);
    }
  };

  if (error) {
    openErrorToast('Ocorreu um erro ao listar as informações do processo.');
  }

  let trackLawsuitButton = (
    <Button
      highlight
      outline
      small
      onClick={toggleTracking}
      disabled={isLoadingLawsuitTracking}
      danger={isTracked}
      loading={isLoadingLawsuitTracking}
    >
      {trackLawsuitText}
    </Button>
  );

  if (hasToDisableTrackButton) {
    trackLawsuitButton = (
      <ToolTip
        content="Não é possível remover do rastreamento, pois o processo está em penhora"
        placement="top"
      >
        <div data-testid="tracking-tooltip">
          <Button
            highlight
            outline
            small
            onClick={toggleTracking}
            disabled
            danger
            loading={isLoadingLawsuitTracking}
          >
            {trackLawsuitText}
          </Button>
        </div>
      </ToolTip>
    );
  }

  const negotiatorId = externalLawsuit?.caseNegotiatorId;

  const canUser = {
    addToResources: can(
      Action.CASOS_PROCESSO_EXTERNO_ADICIONAR_A_RASTREAMENTO,
      negotiatorId
    ),
    removeFromResources: can(
      Action.CASOS_PROCESSO_EXTERNO_REMOVER_DO_RASTREAMENTO,
      negotiatorId
    ),
    monitorLawsuit: can(
      Action.CASOS_PROCESSO_EXTERNO_ACOMPANHAR_PROCESSO,
      negotiatorId
    ),
    unmonitorLawsuit: can(
      Action.CASOS_PROCESSO_EXTERNO_DEIXAR_DE_ACOMPANHAR,
      negotiatorId
    ),
    showLawsuit: can(Action.CASOS_PROCESSO_EXTERNO_VER_PROCESSO, negotiatorId)
  };

  const canShowTrackingButton = isTracked
    ? canUser.removeFromResources
    : canUser.addToResources;

  const canShowMonitoringButton = isMonitored
    ? canUser.unmonitorLawsuit
    : canUser.monitorLawsuit;

  return (
    <Layout>
      <Header isLoading={isLoading}>
        <Link iconName="arrow-left" to={returnTo ?? `/cases/${caseId}`}>
          Voltar
        </Link>
        <Title>{buildTitle()}</Title>
      </Header>
      <Header
        className={style.header}
        containerClassName={style.container}
        isLoading={isLoading}
        sticky
        actionsComponent={
          <>
            {canShowTrackingButton && trackLawsuitButton}
            {isExternalLawsuitsMonitoringEnabled && canShowMonitoringButton && (
              <Button
                highlight
                outline
                small
                onClick={toggleMonitoring}
                disabled={isLoadingLawsuitMonitoring}
                danger={isMonitored}
              >
                {monitorLawsuitText}
              </Button>
            )}
          </>
        }
      >
        <div className={style.headerContent}>
          <Title className={style.title}>Processo nº {number}</Title>
        </div>
      </Header>
      <Tabs linkTab={linkTo} defaultPath={match.params.tab}>
        <TabContent
          title="Informações básicas"
          path="basic-information"
          dataTestId="basic-information-tab"
        >
          <BasicInformation
            externalLawsuit={externalLawsuit}
            isLoading={isLoading}
            disableStatusLinks={!canUser.showLawsuit}
          />
        </TabContent>

        <TabContent
          title="Movimentações"
          path="movements"
          dataTestId="movements-tab"
        >
          <Movements caseId={caseId} externalLawsuitId={externalLawsuitId} />
        </TabContent>
      </Tabs>
    </Layout>
  );
};

export default withRouter(ExternalLawsuit);
