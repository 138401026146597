import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  ReactElement
} from 'react';
import { Prompt } from 'react-router-dom';

type State = {
  isAnyFormDirty: boolean;
  setIsContractFormDirty: (dirty: boolean) => void;
  setIsGuaranteeFormDirty: (dirty: boolean, id: string) => void;
  cleanGuaranteeFormDirty: (id: string) => void;
  setIsReclaimingFormDirty: (dirty: boolean, id: string) => void;
  cleanReclaimingFormDirty: (id: string) => void;
  formsWithChange: () => JSX.Element[];
};

const FormDirtyContext = createContext<State>({
  isAnyFormDirty: false,
  setIsContractFormDirty: (dirty: boolean) => {},
  setIsGuaranteeFormDirty: (dirty: boolean, id: string) => {},
  cleanGuaranteeFormDirty: (id: string) => {},
  setIsReclaimingFormDirty: (dirty: boolean, id: string) => {},
  cleanReclaimingFormDirty: (id: string) => {},
  formsWithChange: () => []
});

type Props = {
  children: ReactElement | ReactElement[];
};

function FormDirtyProvider({ children }: Props) {
  const [isContractFormDirty, setIsContractFormDirty] = useState<boolean>(
    false
  );
  const [GuaranteeFormDirty, setGuaranteeFormDirty] = useState<{
    [key: string]: boolean;
  }>({});
  const [ReclaimingFormDirty, setReclaimingFormDirty] = useState<{
    [key: string]: boolean;
  }>({});

  const addGuaranteeFormDirty = (dirty: boolean, id: string) => {
    setGuaranteeFormDirty({
      ...GuaranteeFormDirty,
      [id]: dirty
    });
  };

  const removeGuaranteeFormDirty = (id: string) => {
    setGuaranteeFormDirty({
      ...GuaranteeFormDirty,
      [id]: false
    });
  };

  const addReclaimingFormDirty = (dirty: boolean, id: string) => {
    setReclaimingFormDirty({
      ...ReclaimingFormDirty,
      [id]: dirty
    });
  };

  const removeReclaimingFormDirty = (id: string) => {
    setReclaimingFormDirty({
      ...ReclaimingFormDirty,
      [id]: false
    });
  };

  const showAlert = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = true;
    return true;
  };

  const isGuaranteeFormDirty = Object.values(GuaranteeFormDirty).some(
    elem => elem === true
  );
  const isReclaimingFormDirty = Object.values(ReclaimingFormDirty).some(
    elem => elem === true
  );

  const formsWithChange = (): JSX.Element[] => {
    const dictionary = [
      {
        hasChange: isContractFormDirty,
        translation: 'Informações do Contrato'
      },
      {
        hasChange: isGuaranteeFormDirty,
        translation: 'Garantias'
      },
      {
        hasChange: isReclaimingFormDirty,
        translation: 'Recuperações'
      }
    ];

    return dictionary
      .filter(dictEntry => dictEntry.hasChange)
      .map(dictEntry => <b>{dictEntry.translation}</b>)
      .map((el, index, arr) => (
        <React.Fragment key={index}>
          {index > 0 && index !== arr.length - 1 && ', '}
          {index !== 0 && index === arr.length - 1 && ' e '}
          {el}
        </React.Fragment>
      ));
  };

  const isAnyFormDirty =
    isContractFormDirty || isGuaranteeFormDirty || isReclaimingFormDirty;

  useEffect(() => {
    if (isAnyFormDirty) {
      window.addEventListener('beforeunload', showAlert);
    }

    return function cleanup() {
      window.removeEventListener('beforeunload', showAlert);
    };
  }, [isAnyFormDirty]);

  return (
    <FormDirtyContext.Provider
      value={{
        isAnyFormDirty: isAnyFormDirty,
        setIsContractFormDirty,
        setIsGuaranteeFormDirty: addGuaranteeFormDirty,
        cleanGuaranteeFormDirty: removeGuaranteeFormDirty,
        setIsReclaimingFormDirty: addReclaimingFormDirty,
        cleanReclaimingFormDirty: removeReclaimingFormDirty,
        formsWithChange
      }}
    >
      <Prompt
        when={isAnyFormDirty}
        message="Existem alterações não salvas. Deseja realmente sair da página?"
      />

      {children}
    </FormDirtyContext.Provider>
  );
}

const useFormDirty = () => {
  return useContext(FormDirtyContext);
};

export { useFormDirty, FormDirtyProvider };
