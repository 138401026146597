import { Guarantee } from './guarantee';
import { Reclaiming } from './reclaiming';
import { FileUpload } from './file';
import { Debtor } from './debtor';
import { TaxIndexer } from './taxIndexer';
import { Actions } from './action';
import { Activity } from './activity';

export enum CreditContractStatus {
  EM_DIA = 'em_dia',
  INADIMPLENTE = 'inadimplente',
  PREJUIZO = 'prejuizo',
  LIQUIDADO = 'liquidado',
  NOVADO_POR_PRJ = 'novado_por_prj',
  CEDIDO = 'cedido'
}

export enum CreditContractModality {
  ACC = 'acc',
  ACE = 'ace',
  BNDES_AUTOMATICO_FINEM = 'bndes_automatico_finem',
  ACORDO_JUDICIAL = 'acordo_judicial',
  BONUS_DE_ADIMPLENCIA = 'bonus_de_adimplencia',
  CAMBIO = 'cambio',
  CAPITAL_DE_GIRO_CCB = 'capital_de_giro_ccb',
  CARTA_DE_CREDITO = 'carta_de_credito',
  CDCA = 'cdca',
  CONFISSAO_DE_DIVIDA = 'confissao_de_divida',
  CONTA_GARANTIDA = 'conta_garantida',
  COTAS_DE_FIP = 'cotas_de_fip',
  CPR = 'cpr',
  DEBENTURES = 'debentures',
  DERIVATIVOS = 'derivativos',
  DIP_FINANCE = 'dip_finance',
  FIANCA = 'fianca',
  FIANCA_HONRADA = 'fianca_honrada',
  FINAME = 'finame',
  FINIMP = 'finimp',
  KG_RENEG = 'kg_reneg',
  LEASING = 'leasing',
  LOAN = 'loan',
  LOAN_OFFSHORE = 'loan_offshore',
  NCE = 'nce',
  NOTAS_PROMISSORIAS = 'notas_promissorias',
  PLANO_DE_RJ = 'plano_de_rj',
  PRE_PAGAMENTO_DE_EXPORTACAO_PPE = 'pre_pagamento_exportacao_ppe',
  REPASSE_BNDES = 'repasse_bndes',
  CCB_PEAC_PRE_FIXADA = 'ccb_peac_pre_fixada',
  PEAC = 'peac'
}

export enum CreditContractType {
  BILATERAL = 'bilateral',
  UNIONIZED = 'unionized'
}
export enum CreditContractCurrency {
  BRL = 'brl',
  USD = 'usd',
  EUR = 'eur',
  OTHER = 'other'
}

export type CreditContractOrigin = {
  id: string;
  name: string;
};

export type CreditContractAttributes = {
  activities: Activity[];
  accountingBalance: number;
  accountingDate: string;
  actions: Actions;

  comments?: string;
  creditOrigin: CreditContractOrigin;
  currency: CreditContractCurrency;
  cuttingDate: string;

  debtorId: string;

  endDate: string;

  firstOverduePaymentDate: string | null;
  fileUploadIds: Array<string>;

  indexer: TaxIndexer;
  initialAmount: number;
  inCourt: boolean;
  isClosed: boolean | null;

  modality: CreditContractModality;

  number: string;

  overdueAmountAtCuttingDate: number;

  pddImpairmentPercentage: number;
  pddImpairmentAmount: number;

  status: CreditContractStatus;

  startDate: string;

  taxPercentage: number;
  taxDeductible: boolean;
  type: CreditContractType;
};

export type CreditContract = CreditContractAttributes & {
  caseId: string;
  id: string;
  changedAt: string;
  debtor: Debtor;
  guarantees: Array<Guarantee>;
  reclaimings: Array<Reclaiming>;
  fileUploads: Array<FileUpload>;
  reclaimingFiles: Array<FileUpload>;
  guaranteeFiles: Array<FileUpload>;
};

export type CreditContractFormAttributes = {
  number: string;
  status: CreditContractStatus | '';
  modality: CreditContractModality | '';
  type: CreditContractType | '';
  indexer: TaxIndexer | '';
  taxPercentage: number | string;
  currency: CreditContractCurrency | '';
  initialAmount: number | string;
  startDate: string;
  endDate: string;
  firstOverduePaymentDate: string;
  overdueAmountAtCuttingDate: number | string;
  cuttingDate: string;
  accountingBalance: number | string;
  accountingDate: string;
  pddImpairmentPercentage: number | string;
  pddImpairmentAmount: number | string;
  taxDeductible: boolean;
  inCourt: boolean;
  comments?: string | null;
  fileUploadIds: Array<string>;
  debtorId: string;
  creditOriginId?: string;
};

export type CreditContractParams = Partial<CreditContractFormAttributes>;

export type CreditContractByModality = {
  modality: CreditContractModality;
  contracts: CreditContract[];
};
