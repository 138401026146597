import React, { ReactNode } from 'react';
import { DropDown, Button, Link, Icon } from 'ui';

type DropDownOption = {
  text: string;
  callback: () => void;
  dataTestId?: string;
  isNegative?: boolean;
};

type Props = {
  handleSubmit: () => void;
  handleCancelClick: () => void;
  dropDownOptions: Array<DropDownOption>;
  title: string;
  titleComponent?: ReactNode;
  showMode: boolean;
  htmlId?: string;
};

const CardHeader = ({
  handleSubmit,
  dropDownOptions,
  title,
  titleComponent,
  showMode,
  handleCancelClick,
  htmlId
}: Props) => {
  return (
    <div className="header" id={htmlId}>
      <div className="title-container">
        <h2 data-testid="card-header-title">{title}</h2>
        {titleComponent ? (
          <div className="title-component">{titleComponent}</div>
        ) : null}
      </div>

      {showMode && (
        <DropDown dataTestId="dropdown-trigger" options={dropDownOptions} />
      )}
      {!showMode && (
        <div className="actions">
          <Link
            className="cancel"
            dataTestId="edit-mode-cancel"
            onClick={handleCancelClick}
          >
            <Icon name="close" fill="#62667a" small />
          </Link>
          <Button
            dataTestId="card-submit"
            onClick={handleSubmit}
            highlight
            small
            type="submit"
          >
            Salvar
          </Button>
        </div>
      )}
    </div>
  );
};

export default CardHeader;
