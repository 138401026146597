import React, { useEffect, useState } from 'react';
import { validateInvitationToken } from 'api';
import LayoutAuthentication from 'Layouts/LayoutAuthentication';
import * as queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { openErrorToast } from 'ui';
import CreatePassword from './CreatePassword';
import InvalidInvitationToken from './InvalidInvitationToken';

const NewPassword = () => {
  const history = useHistory<{ token: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isInvalid, setIsInvalid] = useState<boolean | null>(null);
  const urlParams = queryString.parse(history.location.search);

  const invitationToken: string | undefined = urlParams?.token
    ? urlParams?.token.toString()
    : undefined;

  async function request(invitationToken: string | undefined) {
    if (!invitationToken) {
      setIsInvalid(true);
      setIsLoading(false);
      return;
    }

    try {
      const response = await validateInvitationToken(invitationToken);
      setIsInvalid(!response.data.isValid);
      setIsLoading(false);
    } catch (error) {
      openErrorToast('Não foi possível fazer o login.');
    }
  }

  useEffect(() => {
    request(invitationToken);
  }, [invitationToken]);

  return (
    <LayoutAuthentication>
      {isLoading && <p className="paragraph">Carregando...</p>}
      {isInvalid && (
        <InvalidInvitationToken data-testid="component-invalid-invitation-token" />
      )}

      {!isLoading && !isInvalid && invitationToken && (
        <CreatePassword
          data-testid="component-new-password"
          invitationToken={invitationToken}
          setIsInvalid={setIsInvalid}
        />
      )}
    </LayoutAuthentication>
  );
};

export default NewPassword;
