import {
  CaseCityEntriesType,
  City,
  Option,
  ReportCaseCityEntriesType
} from 'types';

export function buildCitiesOptions(cities?: City[]): Option[] {
  if (!cities) return [];

  return cities.map(
    (city: City): Option => ({
      label: city.name,
      value: city.id
    })
  );
}

const CASE_CITY_DICTIONARY = {
  [CaseCityEntriesType.ASSET]: 'Imóvel',
  [CaseCityEntriesType.INTERNAL_LAWSUITS]: 'Processo',
  [CaseCityEntriesType.LOCATION]: 'Localização',
  [CaseCityEntriesType.REPORT]: 'Suspeito (a)'
};

const REPORT_CASE_CITY_DICTIONARY = {
  [ReportCaseCityEntriesType.REAL_ESTATE]: 'Imóvel',
  [ReportCaseCityEntriesType.RURAL_PROPERTY]: 'Imóvel Rural',
  [ReportCaseCityEntriesType.PARTNERSHIP_COMPANY]: 'Empresas que é sócia',
  [ReportCaseCityEntriesType.COMPANY]: 'Endereço',
  [ReportCaseCityEntriesType.INDIVIDUAL]: 'Endereço',
  [ReportCaseCityEntriesType.VEHICLE]: 'Veículo',
  [ReportCaseCityEntriesType.LOCATION]: 'Localização'
};

export function caseCityLabel(type: CaseCityEntriesType): string {
  return CASE_CITY_DICTIONARY[type] || type;
}

export function reportCaseCityLabel(type: ReportCaseCityEntriesType): string {
  return REPORT_CASE_CITY_DICTIONARY[type] || type;
}
