import React from 'react';
import { Icon, ToolTip } from 'ui';

import { DivergentDataValue } from 'types';

const MIN_DIVERGENT_DATA = 1;

type Props = {
  divergentData: DivergentDataValue;
  openDivergentDataModal: () => void;
};

const AlertDivergentData = ({
  divergentData,
  openDivergentDataModal
}: Props) => {
  return divergentData && divergentData.length > MIN_DIVERGENT_DATA ? (
    <ToolTip
      content="Há dados divergentes entre os provedores. Clique para vê-los."
      placement="top"
    >
      <button
        style={{ padding: 0 }}
        onClick={openDivergentDataModal}
        data-testid="open-divergent-data-modal-button"
        aria-label="Abrir modal de informações divergentes"
      >
        <Icon fill={'#e53935'} name="warning" small />
      </button>
    </ToolTip>
  ) : null;
};

export default AlertDivergentData;
