import React, { useContext, useEffect } from 'react';
import * as Yup from 'yup';
import { FormikHelpers, useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

import {
  CreditContractFormAttributes,
  CreditContractType,
  OnSubmit,
  CreditContract,
  Feature,
  CaseType
} from 'types';
import { Action, can, submitCreditContract } from 'api';
import { useFeatureFlag } from 'hooks';

import { Button, openSuccessToast, openErrorToast } from 'ui';
import { CaseContext, FileField } from 'components';
import { useFormDirty } from '../../FormDirtyContext';
import DebtorInfo from '../../../DebtorInfo';
import ContractInfo from './ContractInfo';
import style from './Form.module.scss';

type Props = {
  caseId: string;
  contractNumberHandler: (contractNumber: string) => void;
  creditContractHandler: (creditContract: CreditContract) => void;
  creditContract?: CreditContract;
  isLoading?: boolean;
};

const initialValues: CreditContractFormAttributes = {
  number: '',
  status: '',
  modality: '',
  type: CreditContractType.BILATERAL,
  indexer: '',
  taxPercentage: '',
  currency: '',
  initialAmount: '',
  startDate: '',
  endDate: '',
  firstOverduePaymentDate: '',
  overdueAmountAtCuttingDate: '',
  cuttingDate: '',
  accountingBalance: '',
  accountingDate: '',
  pddImpairmentPercentage: '',
  pddImpairmentAmount: '',
  taxDeductible: true,
  inCourt: true,
  comments: '',
  fileUploadIds: [],
  debtorId: ''
};

const validationSchema = Yup.object().shape({
  number: Yup.string()
    .matches(/^[\w-/\s]+$/, 'Deve conter somente números ou letras')
    .required('Campo obrigatório'),
  status: Yup.string().required('Campo obrigatório'),
  modality: Yup.string().required('Campo obrigatório'),
  type: Yup.string().required('Campo obrigatório'),
  indexer: Yup.string().required('Campo obrigatório'),
  taxPercentage: Yup.number()
    .typeError('Deve ser um número')
    .min(0, 'Deve ser maior que 0%')
    .required('Campo obrigatório'),
  currency: Yup.string().required('Campo obrigatório'),
  initialAmount: Yup.number()
    .min(0, 'Dever ser maior que 0')
    .required('Campo obrigatório'),
  startDate: Yup.date()
    .max(new Date(), 'A data deve ser igual ou anterior a hoje')
    .required('Campo obrigatório'),
  endDate: Yup.date()
    .when(
      'startDate',
      (startDate: string, schema: Yup.DateSchema<Date>) =>
        startDate &&
        schema.min(startDate, 'A data deve ser posterior a data inicial')
    )
    .required('Campo obrigatório'),
  firstOverduePaymentDate: Yup.date().max(
    new Date(),
    'A data deve ser igual ou anterior a hoje'
  ),
  overdueAmountAtCuttingDate: Yup.number().required('Campo obrigatório'),
  cuttingDate: Yup.date()
    .max(new Date(), 'A data deve ser igual ou anterior a hoje')
    .required('Campo obrigatório'),
  accountingBalance: Yup.number()
    .notRequired()
    .nullable(),
  accountingDate: Yup.date()
    .notRequired()
    .nullable(),
  pddImpairmentPercentage: Yup.number()
    .typeError('Deve ser um número')
    .max(10000, 'deve ser menor que 100%')
    .min(0, 'Deve ser maior que 0%')
    .required('Campo obrigatório'),
  pddImpairmentAmount: Yup.number().required('Campo obrigatório'),
  taxDeductible: Yup.boolean().required('Campo obrigatório'),
  inCourt: Yup.boolean().required('Campo obrigatório'),
  comments: Yup.string()
    .notRequired()
    .nullable(),
  debtorId: Yup.string().required('Campo obrigatório'),
  fileUploadIds: Yup.array()
    .of(Yup.string())
    .notRequired()
});

const Form = ({
  caseId,
  contractNumberHandler,
  creditContractHandler,
  creditContract,
  isLoading
}: Props) => {
  const [isUploadEnabled] = useFeatureFlag(Feature.FILE_UPLOADS);
  const history = useHistory();

  const { caseData } = useContext(CaseContext);

  const negotiatorId = caseData?.negotiator?.id;

  const buildInitialValues = (
    creditContract: CreditContract | undefined
  ): CreditContractFormAttributes => {
    if (!creditContract) return initialValues;

    return {
      number: creditContract.number,
      status: creditContract.status,
      modality: creditContract.modality,
      type: creditContract.type,
      indexer: creditContract.indexer,
      taxPercentage: creditContract.taxPercentage.toString(),
      currency: creditContract.currency,
      initialAmount: creditContract.initialAmount.toString(),
      startDate: creditContract.startDate,
      endDate: creditContract.endDate,
      firstOverduePaymentDate: creditContract.firstOverduePaymentDate || '',
      overdueAmountAtCuttingDate: creditContract.overdueAmountAtCuttingDate.toString(),
      cuttingDate: creditContract.cuttingDate,
      accountingBalance: creditContract.accountingBalance?.toString() ?? '',
      accountingDate: creditContract.accountingDate,
      pddImpairmentPercentage: creditContract.pddImpairmentPercentage.toString(),
      pddImpairmentAmount: creditContract.pddImpairmentAmount.toString(),
      taxDeductible: creditContract.taxDeductible,
      inCourt: creditContract.inCourt,
      comments: creditContract.comments,
      fileUploadIds: creditContract.fileUploads.map(file => file.id),
      debtorId: creditContract.debtor.id,
      creditOriginId: creditContract.creditOrigin?.id
    };
  };

  const { setIsContractFormDirty } = useFormDirty();

  const handleSubmit: OnSubmit<CreditContractFormAttributes> = async (
    values: CreditContractFormAttributes,
    formikHelpers: FormikHelpers<CreditContractFormAttributes>
  ) => {
    try {
      const response = await submitCreditContract(
        values,
        caseId,
        creditContract
      );

      formikHelpers.setValues(buildInitialValues(response.data));

      if (response.status === 201) {
        setIsContractFormDirty(false);
        history.push(`/cases/${caseId}/debtors/contract/${response.data.id}`);
      }

      if (response.status === 200) {
        creditContractHandler(response.data);
      }

      openSuccessToast('Contrato salvo com sucesso!');
    } catch (error) {
      openErrorToast('Houve um erro ao salvar o contrato');

      if (error.errors) {
        formikHelpers.setErrors(error.errors);
      }
    }
  };

  const formik = useFormik({
    initialValues: buildInitialValues(creditContract),
    onSubmit: handleSubmit,
    validationSchema: validationSchema,
    enableReinitialize: true
  });

  useEffect(() => {
    setIsContractFormDirty(formik.dirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.dirty]);

  const canUser = {
    editContract: can(Action.CASOS_CONTRATO_EDITAR, negotiatorId),
    addContract: can(Action.CASOS_CONTRATO_INCLUIR, negotiatorId),
    showFiles: can(Action.CASOS_ARQUIVOS_DO_CONTRATO_CONSULTAR, negotiatorId),
    addFile: can(Action.CASOS_ARQUIVOS_DO_CONTRATO_INCLUIR, negotiatorId),
    removeFile: can(Action.CASOS_ARQUIVOS_DO_CONTRATO_EXCLUIR, negotiatorId)
  };

  const disableField = creditContract
    ? !canUser.editContract
    : !canUser.addContract;

  const canShowSaveButton = creditContract
    ? canUser.editContract || canUser.addFile || canUser.removeFile
    : canUser.addContract;

  return (
    <div className={style.form}>
      {!isLoading && canShowSaveButton && (
        <Button
          className={style.button}
          highlight
          small
          onClick={formik.handleSubmit}
          disabled={!formik.dirty}
        >
          {creditContract ? 'Salvar' : 'Criar'}
        </Button>
      )}
      <DebtorInfo
        formik={formik}
        caseId={caseId}
        isLoading={isLoading}
        isDisabled={disableField}
      />
      <ContractInfo
        contractNumberHandler={contractNumberHandler}
        formik={formik}
        isLoading={isLoading}
        isDisabled={disableField}
        showCreditOrigin={caseData?.type === CaseType.PEAC}
      />
      {isUploadEnabled && canUser.showFiles && (
        <div>
          <h2 className={style.filesLabel}>Arquivos</h2>
          <FileField
            showMode={!formik.values.debtorId}
            caseId={caseId}
            name="fileUploadIds"
            id="fileUploadIds"
            onUploadSuccess={ids => {
              formik.setFieldValue('fileUploadIds', ids);
            }}
            initialFiles={creditContract?.fileUploads}
            dataTestId="contract-file-upload"
            showDelete={canUser.removeFile}
            showAdd={canUser.addFile}
          />
        </div>
      )}
    </div>
  );
};

export default Form;
