import {
  fetchInstagramProfileAggregatedProfiles,
  FetchInstagramProfileAggregatedProfilesParams
} from 'api';
import { useQueryCall } from 'hooks';
import React, { useCallback } from 'react';
import { AggregatedMentionedInstagramProfile, InstagramProfile } from 'types';
import { openErrorToast } from 'ui';
import { useInstagramProfile } from 'components';

import RelatedInstagramProfilesTable from './Table/RelatedInstagramProfilesTable';

type Props = {
  caseId: string;
  reportId: string;
  selectedInstagramProfile?: InstagramProfile | null;
  canBookmarkPublication?: boolean;
};

const ReportRelatedInstagramProfiles = ({
  caseId,
  reportId,
  selectedInstagramProfile,
  canBookmarkPublication = true
}: Props) => {
  const { instagramProfile: contextInstagramProfile } = useInstagramProfile();
  const instagramProfile = selectedInstagramProfile || contextInstagramProfile;
  const instagramProfileId = instagramProfile?.id;

  const {
    data: relatedInstagramProfiles,
    pagination: paginationParams,
    request: fetchRelatedProfiles,
    isLoading,
    error,
    setData: setRelatedProfilesData
  } = useQueryCall<
    AggregatedMentionedInstagramProfile[],
    FetchInstagramProfileAggregatedProfilesParams
  >(fetchInstagramProfileAggregatedProfiles, {
    data: instagramProfile?.paginatedAggregatedMentionedProfiles?.data,
    pagination:
      instagramProfile?.paginatedAggregatedMentionedProfiles?.pagination
  });

  const onCurrentPageChange = (page: number) =>
    fetchRelatedProfiles({
      caseId: caseId,
      instagramProfileId: instagramProfileId!,
      pagination: {
        page,
        pageSize: paginationParams!.size
      }
    });

  const onItemsPerPageChange = (pageSize: number) =>
    fetchRelatedProfiles({
      caseId: caseId,
      instagramProfileId: instagramProfileId!,
      pagination: {
        page: paginationParams!.number,
        pageSize: pageSize
      }
    });

  const refetch = useCallback(
    (pageNumber: number, pageSize: number) =>
      fetchRelatedProfiles({
        caseId: caseId,
        instagramProfileId: instagramProfileId!,
        pagination: {
          page: pageNumber,
          pageSize
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [caseId, instagramProfileId]
  );

  if (error)
    openErrorToast(
      'Ocorreu um erro ao listar os perfis de instagram relacionados.'
    );

  return (
    <RelatedInstagramProfilesTable
      caseId={caseId}
      instagramProfileId={instagramProfileId}
      relatedInstagramProfiles={relatedInstagramProfiles}
      paginationParams={paginationParams}
      onCurrentPageChange={onCurrentPageChange}
      onItemsPerPageChange={onItemsPerPageChange}
      isLoading={isLoading}
      canBookmarkPublication={canBookmarkPublication}
      refetch={refetch}
      setRelatedProfilesData={setRelatedProfilesData}
      reportId={reportId}
    />
  );
};

export default ReportRelatedInstagramProfiles;
