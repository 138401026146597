import { ExternalLawsuitCard, ExternalLawsuitContentCard } from 'components';
import React from 'react';
import {
  ExternalLawsuit,
  ExternalLawsuitContent,
  ExternalLawsuitParty
} from 'types';
import { Card, DynamicTable, Stack, StackMargin, SubTitle } from 'ui';
import { applyMask, handleNullValue } from 'utils';

import style from './ExternalLawsuitInfo.module.scss';

type Props = {
  externalLawsuit?: ExternalLawsuit | null;
  externalLawsuitContent?: ExternalLawsuitContent | null;
  isLoading?: boolean;
  dataTestId?: string;
};

const ExternalLawsuitInfo = ({
  externalLawsuit,
  externalLawsuitContent,
  isLoading = false,
  dataTestId = 'external-lawsuit-info'
}: Props) => {
  const content = externalLawsuitContent ?? externalLawsuit?.content;

  const tableColumns = [
    {
      name: 'CPF/CNPJ',
      columnKey: 'document',
      render: (item: ExternalLawsuitParty) =>
        handleNullValue(item.document.value, 'string', value =>
          applyMask(item.document.type!, value)
        )
    },
    {
      name: 'Nome/Razão social',
      columnKey: 'name',
      render: (item: ExternalLawsuitParty) => handleNullValue(item.name)
    },
    {
      name: 'Tipo da parte',
      columnKey: 'type',
      render: (item: ExternalLawsuitParty) => handleNullValue(item.type)
    },
    {
      name: 'Advogado',
      columnKey: 'lawyer',
      render: (item: ExternalLawsuitParty) =>
        handleNullValue(item.lawyerNames.join(', '))
    }
  ];

  const rowKey = (item: ExternalLawsuitParty) => item.name!;

  return (
    <>
      <Stack marginTop={StackMargin.MEDIUM} dataTestId={dataTestId}>
        <SubTitle
          badgeText={content?.basicInformation.length}
          badgeTestId="information-group-badge"
          className={style.subtitle}
        >
          Grupos de informações encontradas
        </SubTitle>
      </Stack>
      {content?.basicInformation.map((basicInformation, index) => (
        <Stack key={index} marginTop={StackMargin.MEDIUM}>
          <Stack marginBottom={StackMargin.SMALL}>
            <span className={style.cardTitle}>Informações do processo</span>
          </Stack>
          {externalLawsuit ? (
            <ExternalLawsuitCard
              className={style.card}
              isLoading={isLoading}
              externalLawsuit={{
                ...externalLawsuit,
                content: {
                  ...content,
                  basicInformation: [basicInformation]
                }
              }}
              withoutTitle
              hideMonitoringIcon
              hideTrackingIcon
              showFullInfo
            />
          ) : (
            <ExternalLawsuitContentCard
              className={style.card}
              isLoading={isLoading}
              externalLawsuitContent={{
                ...content,
                basicInformation: [basicInformation]
              }}
              showFullInfo
            />
          )}
        </Stack>
      ))}
      <Stack marginTop={StackMargin.LARGE}>
        <SubTitle
          badgeText={content?.parties?.length}
          badgeTestId="parties-badge"
        >
          Partes envolvidas
        </SubTitle>
        <Card medium className={style.card}>
          <DynamicTable
            columns={tableColumns}
            data={content?.parties ?? []}
            dataTestId="externalLawsuitParties"
            isLoading={isLoading}
            rowKey={rowKey}
            emptyText="Não há partes envolvidas"
          />
        </Card>
      </Stack>
    </>
  );
};

export default ExternalLawsuitInfo;
