import { getFormikError } from 'domain/forms';
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { AssetGroupParams, createAssetGroup } from 'api';
import { AssetSelectableList } from 'components';
import { Asset, AssetType } from 'types';
import {
  InputField,
  openErrorToast,
  openSuccessToast,
  Button,
  Stack,
  StackMargin
} from 'ui';

import style from './CreateAssetGroupModal.module.scss';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Campo obrigatório'),
  assetIds: Yup.array()
    .of(Yup.string())
    .min(1)
    .required('Campo obrigatório')
});

type Props = {
  caseId: string;
  onCreateAssetGroup: (params: Asset) => void;
};

const CreateAssetGroupModal = ({ caseId, onCreateAssetGroup }: Props) => {
  const onSubmit = async (values: AssetGroupParams) => {
    try {
      const response = await createAssetGroup(caseId, values);

      onCreateAssetGroup(response.data);

      openSuccessToast('Grupo criado com sucesso!');
    } catch (error) {
      openErrorToast('Houve um erro ao criar grupo!');
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      assetIds: []
    },
    validationSchema,
    onSubmit
  });

  const getError = getFormikError(formik);

  const handleSelectAsset = (assets: Asset[]) => {
    formik.setFieldValue(
      'assetIds',
      assets.map(asset => asset.id)
    );
  };

  return (
    <div data-testid="asset-group-form">
      <div className={style.input}>
        <InputField
          id="name"
          name="name"
          title="Nome do grupo"
          type="text"
          value={formik.values.name}
          error={getError('name')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </div>

      <AssetSelectableList
        caseId={caseId}
        onSelect={handleSelectAsset}
        searchDefaultParams={{
          types: [AssetType.REAL_ESTATE, AssetType.RURAL_PROPERTY],
          isGroup: false
        }}
        restrictOnlyOneType
      />

      <Stack marginTop={StackMargin.LARGE}>
        <Button
          centered
          highlight
          disabled={!formik.isValid || !formik.dirty}
          onClick={formik.handleSubmit}
        >
          Criar grupo
        </Button>
      </Stack>
    </div>
  );
};

export default CreateAssetGroupModal;
