import { ASSET_IDENTIFICATION_DICTIONARY } from 'domain/assets';
import React from 'react';
import { FormikProps, getIn } from 'formik';
import { AssetAttributes, AssetType, AssetOther } from 'types';
import { InputField, FormContainer } from 'ui';

import styles from '../Form.module.scss';

type Props = {
  assetOther: AssetOther | null;
  isLoading: boolean;
  formik: FormikProps<AssetAttributes>;
  identificationLocked: boolean;
  disabled?: boolean;
};

const OtherFields = ({
  assetOther,
  isLoading,
  formik,
  identificationLocked,
  disabled
}: Props) => {
  return (
    <>
      <h3 className={styles.title}>Informações do ativo</h3>

      <InputField
        type="text"
        id="name"
        name="name"
        title="Nome do ativo"
        isLoading={isLoading}
        value={formik.values.name}
        error={getIn(formik.touched, 'name') && getIn(formik.errors, 'name')}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        disabled={disabled}
      />

      <FormContainer className="col-2">
        <InputField
          type="text"
          id="assetOther.quantity"
          name="assetOther.quantity"
          title="Quantidade (opcional)"
          isLoading={isLoading}
          value={formik.values.assetOther?.quantity?.toString()}
          error={
            getIn(formik.touched, 'assetOther.quantity') &&
            getIn(formik.errors, 'assetOther.quantity')
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          showMode={assetOther ? !assetOther.quantity.isEditable : false}
          disabled={disabled}
        />

        <InputField
          type="text"
          id="identification"
          name="identification"
          title={`${
            ASSET_IDENTIFICATION_DICTIONARY[AssetType.OTHER]
          } (opcional)`}
          isLoading={isLoading}
          value={formik.values.identification}
          error={
            getIn(formik.touched, 'identification') &&
            getIn(formik.errors, 'identification')
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          showMode={identificationLocked}
          disabled={disabled}
        />
      </FormContainer>
    </>
  );
};

export default OtherFields;
