import * as queryString from 'query-string';
import jwtDecode from 'jwt-decode';
import { DateTime } from 'luxon';
import { History } from 'history';
import {
  saveAllowedActionsOnLocalStorage,
  deleteAllowedActionsFromLocalStorage
} from 'api/permissions';
import {
  createAccessToken,
  createAccessTokenWithOauth,
  deleteAccessToken,
  OAuthCredentials,
  UserCredentials
} from './accessTokens';
import Client from './client';

type Claims = {
  exp: number;
  allowed_actions: string[];
  sub: string;
  profile_name: string;
  profile_has_special_permissions: boolean;
  user_email: string;
  user_name: string;
};

export const RETURN_URL_KEY = '_yield_return_url';

export async function login(history: History, credentials: UserCredentials) {
  const result = await createAccessToken(credentials);

  Client.setSessionToken(result.data.accessToken);
  saveAllowedActionsOnLocalStorage(result.data.allowedActions);
  history.push(returnUrl());
}

export async function oauthLogin(
  history: History,
  credentials: OAuthCredentials
) {
  const result = await createAccessTokenWithOauth(credentials);

  Client.setSessionToken(result.data.accessToken);
  saveAllowedActionsOnLocalStorage(result.data.allowedActions);
  history.push(returnUrl());

  return result.data.message;
}

export function hasValidToken(): boolean {
  const token = Client.getSessionToken();

  if (!token) {
    return false;
  }

  return !isExpired(token);
}

export async function logout(history: History) {
  if (Client.getSessionToken()) {
    try {
      await deleteAccessToken();
    } catch {
    } finally {
      Client.removeSessionToken();
      deleteAllowedActionsFromLocalStorage();
    }
  }

  history.push('/login' + history.location.search);
}

export function saveReturnUrl(): void {
  const urlParams: { return_to?: string } = queryString.parse(
    window.location.search
  );

  const returnTo = urlParams.return_to;
  if (returnTo) {
    window.localStorage.setItem(RETURN_URL_KEY, returnTo);
  }
}

export function returnUrl(): string {
  const urlFromStorage = window.localStorage.getItem(RETURN_URL_KEY);
  window.localStorage.removeItem(RETURN_URL_KEY);

  const urlParams: { return_to?: string } = queryString.parse(
    window.location.search
  );

  if (urlParams.return_to) {
    return urlParams.return_to;
  }

  if (urlFromStorage) {
    return urlFromStorage;
  }

  return '/';
}

export function getAllowedActions(): string[] | undefined {
  const claims = getClaims();

  return claims?.allowed_actions;
}

export function getUserInfo():
  | { profile: string; email: string; name: string }
  | undefined {
  const claims = getClaims();

  return (
    claims && {
      profile: claims.profile_name ?? '',
      email: claims.user_email ?? '',
      name: claims.user_name ?? ''
    }
  );
}

export function getProfileHasSpecialPermissions(): boolean | undefined {
  const claims = getClaims();

  return claims?.profile_has_special_permissions;
}

export function getUserId(): string | undefined {
  const claims = getClaims();

  return claims?.sub;
}

function getClaims(): Claims | undefined {
  const token = Client.getSessionToken();
  if (!token) {
    window.location.assign(Client.logoutUrl());
    return undefined;
  }

  const decoded = decodeToken(token!);
  if (!decoded) {
    window.location.assign(Client.logoutUrl());
    return undefined;
  }

  return decoded;
}

function isExpired(token: string): boolean {
  const decoded = decodeToken(token);
  if (!decoded) {
    return true;
  }

  const now = DateTime.utc().toSeconds();

  return decoded.exp <= now;
}

function decodeToken(token: string): Claims | null {
  try {
    return jwtDecode(token);
  } catch {
    return null;
  }
}

export function startSessionDaemon() {
  const intervalId = setInterval(() => {
    if (!hasValidToken()) {
      window.location.assign(
        Client.logoutUrl('Sua sessão expirou, entre novamente.')
      );
    }
  }, 60 * 1000);

  return {
    stopDaemon: () => {
      clearInterval(intervalId);
    }
  };
}
