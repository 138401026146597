import { AdverseParty } from 'types';
import Client from './client';

function searchAdverseParties(name: string) {
  return Client.get<AdverseParty[]>('adverse_parties/search', {
    params: { name: name }
  });
}

export { searchAdverseParties };
