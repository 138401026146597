import React from 'react';
import { Guarantee } from 'types';

import { pluralize } from 'utils';
import HeaderStatus from '../HeaderStatus';

type Props = {
  caseId: string;
  guarantee: Guarantee;
  disabled?: boolean;
};

const GuaranteeStatus = ({ caseId, guarantee, disabled }: Props) => {
  const contractsText = pluralize(
    guarantee.contracts.length,
    'no contrato',
    'nos contratos'
  ).replace(/\d/g, '');

  return (
    <HeaderStatus
      links={guarantee.contracts.map(contract => ({
        label: `nº ${contract.number}`,
        linkId: contract.id,
        linkTo: `/cases/${caseId}/debtors/contract/${contract.id}`
      }))}
      strongText={`Na garantia nº ${guarantee.instrumentNumber}`}
      middleText={contractsText}
      dataTestId="guarantee-status"
      disabled={disabled}
    />
  );
};

export default GuaranteeStatus;
