export function handleEmptyArray<T = string>(
  value: T[] | null = null,
  formatter?: (value: T[]) => string
): string | T[] {
  if (!value || (Array.isArray(value) && value.length === 0)) {
    return '-';
  }

  if (formatter) return formatter(value);
  return value;
}
