import * as Reports from 'domain/reports';
import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Layout from 'Layouts/Layout';
import {
  Header,
  Link,
  Title,
  Button,
  ToolTip,
  openErrorToast,
  openSuccessToast,
  Toast,
  ToastType,
  Stack,
  StackMargin
} from 'ui';

import { useLiveInstagramProfile, useQuery, useFeatureFlag } from 'hooks';
import {
  fetchInstagramProfile,
  retryInstagramProfile,
  updateInstagramProfile
} from 'api';
import {
  Status,
  InstagramProfile,
  Report,
  InstagramSubjectStatus,
  Feature,
  LocationState
} from 'types';

import {
  ReportStatus,
  ReportRelatedInstagramProfilesTable,
  LocationMap,
  BookmarkedInstagramPublicationsTable,
  InstagramProfileProvider
} from 'components';

import style from './Show.module.scss';

const Show = () => {
  const [isBookmarkInstagramPublicationEnabled] = useFeatureFlag(
    Feature.BOOKMARK_INSTAGRAM_PUBLICATION
  );

  const history = useHistory<LocationState>();
  const returnTo = history.location.state?.returnTo;

  const { caseId, reportId, instagramProfileId } = useParams<{
    caseId: string;
    instagramProfileId: string;
    reportId: string;
  }>();

  const { data: instagramProfile, isLoading, error, refetch } = useQuery<
    InstagramProfile,
    {
      caseId: string;
      reportId: string;
      instagramProfileId: string;
    }
  >(fetchInstagramProfile, {
    caseId,
    reportId,
    instagramProfileId
  });

  const onMessage = useCallback(async () => {
    try {
      await refetch({ caseId, reportId, instagramProfileId });
    } catch {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseId, instagramProfileId]);

  useLiveInstagramProfile({ caseId, instagramProfileId, onMessage });

  if (error) {
    openErrorToast('Erro ao carregar o Perfil.');
  }

  const handleRetryFailedQueries = async () => {
    try {
      await retryInstagramProfile({ caseId, instagramProfileId });
      openSuccessToast(
        'Refazendo consultas que falharam! Aguarde o término para visualizá-las.'
      );
    } catch {
      openErrorToast('Não foi possível refazer as consultas.');
    }
  };

  const notFound =
    !isLoading &&
    instagramProfile?.instagramSubject?.status ===
      InstagramSubjectStatus.NOT_FOUND;

  const handleUpdateInstagramProfile = async () => {
    try {
      const { data: instagramProfile } = await updateInstagramProfile({
        caseId,
        instagramProfileId
      });
      openSuccessToast('Perfil atualizado com sucesso!');
      history.push(
        `/cases/${caseId}/reports/${reportId}/instagram_profiles/${instagramProfile.id}`
      );
    } catch (error) {
      openErrorToast('Não foi possivel atualizar o perfil.');
    }
  };

  const privateProfile = !notFound && !isLoading && !instagramProfile?.isPublic;

  const reportStatus = {
    completedQueries: instagramProfile?.completedQueries,
    failedQueries: instagramProfile?.failedQueries,
    totalQueries: instagramProfile?.totalQueries,
    finishedAt: instagramProfile?.finishedAt
  };

  return (
    <Layout>
      <div className={style.header}>
        <Header isLoading={isLoading}>
          <Link
            to={returnTo ?? `/cases/${caseId}/resources`}
            iconName="arrow-left"
          >
            Voltar
          </Link>
          <Title>
            {instagramProfile?.name ?? 'Perfil sem nome'}
            <Link
              external
              underline
              className={style.headerLink}
              href={instagramProfile?.url}
            >
              @{instagramProfile?.socialName}
            </Link>
          </Title>
          <div className={style.bottomheader}>
            <ReportStatus report={reportStatus as Report} />

            {Reports.reportStatus(reportStatus as Report) ===
              Status.PARTIAL && (
              <ToolTip
                content="Refazer apenas as consultas que falharam"
                placement="top"
              >
                <span
                  className={style.headerButton}
                  data-testid="report-retry-button"
                >
                  <Button
                    outline
                    highlight
                    small
                    onClick={handleRetryFailedQueries}
                    dataTestId={`report-retry-${instagramProfileId}`}
                  >
                    Tentar novamente
                  </Button>
                </span>
              </ToolTip>
            )}

            <ToolTip content="Refazer todas as consultas" placement="top">
              <span
                className={style.headerButton}
                data-testid="report-update-button"
              >
                <Button
                  outline
                  highlight
                  small
                  onClick={handleUpdateInstagramProfile}
                  dataTestId="update-social-report"
                >
                  Atualizar
                </Button>
              </span>
            </ToolTip>
          </div>
        </Header>
      </div>

      {privateProfile && (
        <div className={style.body}>
          <Toast
            message={
              'Este perfil não é público, suas informações não puderam ser consultadas'
            }
            type={ToastType.DEFAULT}
          />
        </div>
      )}

      {notFound && (
        <div className={style.body}>
          <Toast
            message={
              'Perfil do Instagram não encontrado, provavelmente esta conta foi desativada ou está temporariamente desabilitada'
            }
            type={ToastType.DEFAULT}
          />
        </div>
      )}

      {!isLoading && !notFound && !privateProfile && (
        <InstagramProfileProvider
          caseId={caseId}
          reportId={reportId}
          instagramProfile={instagramProfile ?? null}
        >
          <>
            <div className={style.mapWrapper}>
              <LocationMap
                title="Localizações"
                initialAnimation={false}
                caseId={caseId}
                reportId={reportId}
              />
            </div>

            {instagramProfile && (
              <Stack marginTop={StackMargin.XLARGE}>
                <ReportRelatedInstagramProfilesTable
                  caseId={caseId}
                  key={instagramProfileId}
                  reportId={reportId}
                />
              </Stack>
            )}

            {isBookmarkInstagramPublicationEnabled && instagramProfile && (
              <Stack marginTop={StackMargin.XLARGE}>
                <BookmarkedInstagramPublicationsTable
                  caseId={caseId}
                  reportId={reportId}
                />
              </Stack>
            )}
          </>
        </InstagramProfileProvider>
      )}
    </Layout>
  );
};

export default Show;
