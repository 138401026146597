import { FormikProps, getIn } from 'formik';

import { Option } from 'types';

export function booleanOptions(): Option[] {
  return [
    { value: 'true', label: 'Sim' },
    { value: 'false', label: 'Não' }
  ];
}

export const handleDateFieldChange = (
  setFieldValue: (field: string, value: string | null) => void,
  setFieldTouched: (field: string, touched: boolean) => void,
  fieldName: string
) => async (value: string | null) => {
  await setFieldValue(fieldName, value);
  setFieldTouched(fieldName, true);
};

export const getFormikError = <T>(formik: FormikProps<T>) => (
  prop: string | (string & keyof T)
) => {
  return getIn(formik.touched, prop) && getIn(formik.errors, prop);
};
