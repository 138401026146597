import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { CaseCity, CaseCityEntriesType, GroupedState } from 'types';
import { Table } from 'ui';

import StateRow from './StateRow/StateRow';
import styles from './CaseCitiesTable.module.scss';

type Props = {
  caseCities: CaseCity[];
  isLoading?: boolean;
  isSummary?: boolean;
  showOrigin?: boolean;
  showOriginHeader?: boolean;
  hiddenEntriesLinks?: CaseCityEntriesType[];
};

const CaseCitiesTable = ({
  caseCities,
  isLoading = false,
  isSummary,
  showOrigin,
  showOriginHeader = true,
  hiddenEntriesLinks = []
}: Props) => {
  const states = Object.entries(groupBy(caseCities, 'state')).map<GroupedState>(
    ([state, cities]) => ({
      name: state,
      count: cities.reduce((acc, city) => acc + (city.origin?.count ?? 0), 0),
      cities
    })
  );

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.Cell className={styles.tableHeaderCell}>
            {showOriginHeader ? 'Praça/CPF ou CNPJ' : 'Praça'}
          </Table.Cell>
          <Table.Cell className={styles.tableHeaderCell}>
            {showOriginHeader ? 'Nome ou razão social' : ''}
          </Table.Cell>
          <Table.Cell className={styles.tableHeaderCell}>
            {showOriginHeader ? 'Tipo' : ''}
          </Table.Cell>
          <Table.Cell className={styles.tableHeaderCell}>
            Qta. de localizações
          </Table.Cell>
        </Table.Row>
      </Table.Header>
      <Table.Body
        isLoading={isLoading}
        isEmpty={!caseCities.length}
        columns={4}
        emptyText="Nenhuma praça identificada"
      >
        {orderBy(states, ['name'], ['asc']).map(state => (
          <StateRow
            key={state.name}
            state={state}
            isSummary={isSummary}
            showOrigin={showOrigin}
            hiddenEntriesLinks={hiddenEntriesLinks}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

export default CaseCitiesTable;
