import { User, NewPasswordCredentials, ApiSuccessfulResponse } from 'types';

import Client from './client';

type TokenValidationResponse = {
  isValid: boolean;
};

export function validateInvitationToken(
  invitationToken: string
): Promise<ApiSuccessfulResponse<TokenValidationResponse>> {
  return Client.post<{ token: string }, TokenValidationResponse>(
    'invitations/validate_token',
    { token: invitationToken },
    { authenticated: false }
  );
}

export function acceptInvitation(credentials: NewPasswordCredentials) {
  return Client.post<NewPasswordCredentials, User>(
    'invitations/accept',
    credentials,
    { authenticated: false }
  );
}
