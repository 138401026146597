import { useEffect } from 'react';
import { useChannel } from 'components';

import { useLegalReport } from 'components';

function useLiveReport(
  caseId: string,
  reportId: string,
  refetchCallback: () => Promise<void>
) {
  const { channel, isConnected } = useChannel(
    `cases:${caseId}:reports:${reportId}`
  );

  const {
    legalReportQuotationId,
    setLegalReportQuotationData,
    setLegalReportQuotationId
  } = useLegalReport();

  useEffect(() => {
    if (!channel || !isConnected) return;

    const queue: string[] = [];
    let isRefetching = false;

    const clearQueue = () => queue.splice(0, queue.length);

    const refetchData = async () => {
      if (!isRefetching && queue.length > 0) {
        isRefetching = true;
        clearQueue();

        await refetchCallback().finally(() => {
          isRefetching = false;

          refetchData();
        });
      }
    };

    const addToQueue = () => {
      queue.push('report_updated');

      refetchData();
    };

    channel.on('report_updated', addToQueue);

    channel.on('quotation_finished', response => {
      if (!legalReportQuotationId) {
        setLegalReportQuotationId(response.id);
      }

      setLegalReportQuotationData(response);
    });

    return () => {
      if (channel) {
        channel.off('report_updated');
        channel.off('quotation_finished');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseId, reportId, isConnected, channel, legalReportQuotationId]);
}

export { useLiveReport };
