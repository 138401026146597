import React, { ReactNode } from 'react';
import { DateTime } from 'luxon';

import { ApiPagination, Feature, InstagramPublication } from 'types';
import { useFeatureFlag } from 'hooks';

import { Badge, Link, Modal, Pagination } from 'ui';
import PublicationBookmark from './PublicationBookmarkModal';

import style from './PublicationsModal.module.scss';

const DEFAULT_PAGE_SIZE = 6;

type Props = {
  caseId: string;
  instagramPublications: InstagramPublication[] | undefined;
  isOpen: boolean;
  isLoading: boolean;
  canBookmarkPublication?: boolean;
  onCurrentPageChange: (newPage: number) => void;
  onClose: () => void;
  pagination: ApiPagination | undefined;
  reportId: string;
  title: ReactNode;
};

const PublicationsModal = ({
  caseId,
  instagramPublications,
  isOpen,
  isLoading,
  canBookmarkPublication = true,
  onCurrentPageChange,
  onClose,
  pagination,
  reportId,
  title
}: Props) => {
  const [isBookmarkInstagramPublicationEnabled] = useFeatureFlag(
    Feature.BOOKMARK_INSTAGRAM_PUBLICATION
  );

  const ModalTitle = () => (
    <div className={style.titleContainer}>
      <span>
        {title}
        {pagination && (
          <Badge className={style.badge}>{pagination?.totalEntries}</Badge>
        )}
      </span>
    </div>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={<ModalTitle />}
      className={style.modal}
      dataTestId="instagram-publications-modal"
    >
      <div className={style.content}>
        <div className={style.body}>
          {isLoading ? (
            'Carregando...'
          ) : (
            <ul>
              {instagramPublications?.map((publication, index) => (
                <li key={`instagram-post-item-${index}`} className={style.item}>
                  {canBookmarkPublication &&
                    isBookmarkInstagramPublicationEnabled && (
                      <PublicationBookmark
                        caseId={caseId}
                        instagramPublication={publication}
                        reportId={reportId}
                      />
                    )}
                  <Link
                    external
                    highlight
                    underline
                    href={publication.url}
                    className={style.link}
                  >
                    {DateTime.fromISO(publication.createdAt)
                      .setLocale('pt-BR')
                      .toFormat("EEEE, dd/MM/yy 'às' T")}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>

        {pagination && pagination.totalEntries > DEFAULT_PAGE_SIZE && (
          <div className={style.footer} data-testid="pagination">
            <Pagination
              hideSelect
              currentPage={pagination.number}
              itemsPerPage={6}
              onCurrentPageChange={onCurrentPageChange}
              totalEntries={pagination.totalEntries}
              totalPages={pagination.totalPages}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default PublicationsModal;
