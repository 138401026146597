import React, { ReactElement, useState, useCallback, useEffect } from 'react';
import Downshift from 'downshift';
import cn from 'classnames';
import debounce from 'lodash/debounce';

import { AdverseParty } from 'types';
import { searchAdverseParties } from 'api';
import { InputField, openErrorToast } from 'ui';
import { applyMask } from 'utils';
import { InputFieldProps } from '../../ui/Form/InputField/InputField';

import './AdversePartyAutoCompleteField.scss';

type Item = {
  value: string | null;
  type: string | null;
  role: string | null;
};

type Props = InputFieldProps & {
  onSelect: (selection: Item | null) => void;
  dataToItem: (data: AdverseParty) => Item;
};

type FetchItemsParams = {
  dataToItem: Props['dataToItem'];
  value: Props['value'];
  setItems: (items: Item[]) => void;
  inputValue: string;
  type: Props['type'];
};

const DEBOUNCE_TIME = 500;

const itemToString = (item: Item | null): string => item?.value || '';

const fetchItemsDebounced = debounce(
  async ({ dataToItem, value, setItems, inputValue }: FetchItemsParams) => {
    if (!inputValue) return;
    if (inputValue === value) return;

    if (inputValue && inputValue.length < 3) return setItems([]);

    try {
      const response = await searchAdverseParties(inputValue);

      const items = response.data.map(adverseParty => dataToItem(adverseParty));
      setItems(items);
    } catch (error) {
      openErrorToast('Erro ao listar auto complete.');
    }
  },
  DEBOUNCE_TIME
);

const AdversePartyAutoCompleteField = ({
  id,
  name,
  type,
  value,
  onChange,
  onBlur,
  title,
  error,
  showMode,
  dataTestId,
  onSelect,
  dataToItem,
  disabled
}: Props): ReactElement => {
  const [items, setItems] = useState<Array<Item>>([]);

  const fetchItems = useCallback(
    (inputValue: string) => {
      fetchItemsDebounced({
        dataToItem,
        value,
        setItems,
        type,
        inputValue
      });
    },
    [dataToItem, type, value, setItems]
  );

  useEffect(() => {
    return fetchItemsDebounced.cancel();
  }, []);

  return (
    <Downshift
      onSelect={onSelect}
      itemToString={itemToString}
      onInputValueChange={fetchItems}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        selectedItem
      }) => (
        <div className="input-field auto-complete-default">
          <InputField
            {...getInputProps({
              onChange: onChange,
              onBlur: onBlur
            })}
            dataTestId={dataTestId}
            id={id}
            name={name}
            type={type}
            value={value}
            title={title}
            error={error}
            showMode={showMode}
            disabled={disabled}
          />

          {isOpen && items.length ? (
            <ul
              {...getMenuProps()}
              data-testid="auto-complete-dropdown"
              className="dropdown"
            >
              {items.map((item, index) => (
                <li
                  className={cn('dropdown-item', {
                    '-selected': Boolean(selectedItem === item)
                  })}
                  {...getItemProps({
                    key: `auto-complete-item-${index}`,
                    index,
                    item
                  })}
                >
                  {applyMask(type, item.value || '')}
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      )}
    </Downshift>
  );
};

export default AdversePartyAutoCompleteField;
