import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';

import { fetchMonitoredExternalLawsuits } from 'api';
import { ApiPagination, ExternalLawsuit } from 'types';

import { Icon, openErrorToast } from 'ui';
import { ExternalLawsuitList } from 'components';

import style from '../LawsuitsList.module.scss';

type Props = {
  caseId: string;
  hideTitleLink?: boolean;
};

type ExternalLawsuitsPaginated = {
  data: ExternalLawsuit[];
  pagination: ApiPagination;
};

const MonitoredExternalLawsuits = ({
  caseId,
  hideTitleLink = false
}: Props) => {
  const [externalLawsuits, setExternalLawsuits] = useState<
    ExternalLawsuitsPaginated
  >();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getMonitoredExternalLawsuits = useCallback(
    async (page = 1, pageSize = 6) => {
      setIsLoading(true);

      try {
        const { data, pagination } = await fetchMonitoredExternalLawsuits({
          caseId,
          pagination: {
            page,
            pageSize
          }
        });

        setExternalLawsuits({ data, pagination: pagination! });
      } catch (error) {
        openErrorToast('Não foi possível listar os processos externos.');
      } finally {
        setIsLoading(false);
      }
    },
    [caseId]
  );

  useEffect(() => {
    getMonitoredExternalLawsuits();
  }, [getMonitoredExternalLawsuits]);

  const isExternalLawsuitsEmpty = !externalLawsuits?.data.length;

  return (
    <div
      className={style.lawsuitsList}
      data-testid={`list-monitered-external-lawsuits`}
    >
      <div className={style.header} data-testid={`icon-eye`}>
        <Icon name="eye" fill="#62667A" small />
        <h3>Em acompanhamento</h3>
      </div>

      <div
        className={cn([
          style.masonryListWrapper,
          (isExternalLawsuitsEmpty || isLoading) && style.empty
        ])}
      >
        {!isLoading && isExternalLawsuitsEmpty ? (
          <>Não há processos em acompanhamento</>
        ) : (
          <ExternalLawsuitList
            data={externalLawsuits}
            fetchExternalLawsuits={getMonitoredExternalLawsuits}
            isLoading={isLoading}
            hideMonitoringIcon
            hideTitleLink={hideTitleLink}
          />
        )}
      </div>
    </div>
  );
};

export default MonitoredExternalLawsuits;
