import {
  createLegalReportClone,
  createLegalReportOrder,
  fetchLegalReportQuotation
} from 'api';
import { useLegalReport } from 'components';
import React, { useCallback, useEffect, useState } from 'react';
import { LegalReportOrder, QueryStatus } from 'types';
import { Button, Loader, Modal } from 'ui';
import { applyMask, handleNullValue } from 'utils';

import styles from './LegalReportCloneModal.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  caseId: string;
  reportId: string;
  onCreateOrder: (data: LegalReportOrder) => void;
};

const LegalReportCloneModal = ({
  caseId,
  isOpen,
  onClose,
  reportId,
  onCreateOrder
}: Props) => {
  const [hasRequestError, setHasRequestError] = useState<boolean>(false);

  const {
    legalReportQuotationData,
    setLegalReportQuotationData,
    setLegalReportQuotationId,
    legalReportQuotationId
  } = useLegalReport();

  const createLegalReport = useCallback(async () => {
    setHasRequestError(false);

    try {
      await createLegalReportClone({ caseId, reportId });
    } catch (e) {
      setHasRequestError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseId, reportId]);

  useEffect(() => {
    createLegalReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchLegalReport = async (id: string) => {
    setHasRequestError(false);

    try {
      const response = await fetchLegalReportQuotation({
        caseId,
        reportId,
        quotationId: id
      });

      setLegalReportQuotationData(response.data);
    } catch (e) {
      setHasRequestError(true);
    }
  };

  useEffect(() => {
    if (legalReportQuotationId) {
      fetchLegalReport(legalReportQuotationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legalReportQuotationId]);

  const handleRetry = () => {
    setLegalReportQuotationData(undefined);
    setLegalReportQuotationId(undefined);
    createLegalReport();
  };

  const handleCreateOrder = async () => {
    if (!legalReportQuotationId) return;

    setHasRequestError(false);

    try {
      const response = await createLegalReportOrder({
        caseId,
        reportId,
        quotationId: legalReportQuotationId
      });

      onCreateOrder(response.data);
      onClose();
    } catch (e) {
      setHasRequestError(true);
    }
  };

  let actionButton;
  let content = (
    <div className={styles.contentLoading}>
      <Loader height={5} width={1} />
      <p className={styles.text}>Carregando informações de orçamento...</p>
    </div>
  );

  if (legalReportQuotationData?.status === QueryStatus.SUCCEEDED) {
    const legalReportContent = legalReportQuotationData.content;

    const isLegalReportQuotationEmpty = legalReportContent?.totalLawsuits === 0;

    content = isLegalReportQuotationEmpty ? (
      <p>Não há novos processos para realizar a busca.</p>
    ) : (
      <>
        <p>
          A consulta trará{' '}
          <strong className={styles.bold}>
            {handleNullValue(legalReportContent?.totalLawsuits)} processos novos
          </strong>{' '}
          de{' '}
          <strong className={styles.bold}>
            {handleNullValue(legalReportContent?.minYear)} a{' '}
            {handleNullValue(legalReportContent?.maxYear)}
          </strong>{' '}
          por{' '}
          <strong className={styles.bold}>
            {handleNullValue(legalReportContent?.totalCost, 'string', value =>
              applyMask('currency', value.toString())
            )}
          </strong>
          .
          <br />
          Confirme o valor antes de realizar a busca.
        </p>
      </>
    );

    actionButton = (
      <Button
        small
        highlight
        onClick={handleCreateOrder}
        disabled={isLegalReportQuotationEmpty}
      >
        Realizar busca
      </Button>
    );
  }

  if (
    legalReportQuotationData?.status === QueryStatus.FAILED ||
    legalReportQuotationData?.status === QueryStatus.NOT_FOUND ||
    hasRequestError
  ) {
    content = (
      <>
        <p className={styles.text}>
          Não foi possível consultar o custo da busca de processos.
        </p>
        <p className={styles.text}>Tente novamente mais tarde.</p>
      </>
    );
    actionButton = (
      <Button small highlight onClick={handleRetry}>
        Tentar novamente
      </Button>
    );
  }

  return (
    <Modal
      title="Buscar processos"
      isOpen={isOpen}
      onClose={onClose}
      className={styles.modal}
      contentClassName={styles.content}
    >
      {content}
      <div className={styles.actions}>
        <Button small highlight outline onClick={onClose}>
          Cancelar
        </Button>

        {actionButton}
      </div>
    </Modal>
  );
};

export default LegalReportCloneModal;
