import React, { ReactElement, useState, useContext } from 'react';
import _ from 'lodash';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Button,
  Card,
  Link,
  openErrorToast,
  openSuccessToast,
  SplitDropdownButton,
  Stack,
  StackMargin,
  FormContainer
} from 'ui';
import {
  Guarantee,
  GuaranteeFormAttributes,
  GuaranteeModality,
  GuaranteeType,
  OnSubmit,
  EntityType,
  ApiError,
  Feature
} from 'types';
import {
  Action,
  can,
  deleteGuarantee,
  removeGuaranteeFromContract,
  submitGuarantee
} from 'api';
import * as ValidationSchema from 'utils/validationSchema';

import { ExecutionStatus, FileField, CaseContext } from 'components';
import { useFeatureFlag } from 'hooks';

import { useFormDirty } from '../../FormDirtyContext';

import AssetList from '../AssetsList/AssetsList';
import Form from './Form';

import style from './Card.module.scss';

type Props = {
  caseId: string;
  contractId: string;
  guaranteeData?: Guarantee;
  htmlId: string;
  increaseTotalCurrentValue: (amount: number) => void;
  onDelete: (message: string) => void;
};

const initialValues: GuaranteeFormAttributes = {
  modality: '',
  object: '',
  instrumentNumber: '',
  requiredCoverage: '',
  sharedGuaranteeParticipation: '',
  type: GuaranteeType.INDIVIDUAL,
  fileUploadIds: [],
  guarantor: {
    type: EntityType.INDIVIDUAL,
    individual: {
      cpf: '',
      name: '',
      isAbroad: false
    },
    company: {
      cnpj: '',
      businessName: '',
      isAbroad: false
    },
    description: ''
  },
  comments: ''
};

const validationSchema = Yup.object().shape({
  modality: Yup.string().required('Campo obrigatório'),
  object: Yup.string().when('modality', {
    is: GuaranteeModality.CLEAN,
    then: Yup.string()
      .notRequired()
      .nullable(),
    otherwise: Yup.string().required('Campo obrigatório')
  }),
  type: Yup.string().required('Campo obrigatório'),
  instrumentNumber: Yup.string()
    .matches(/^[\w-/\s]+$/, 'Formato inválido')
    .required('Campo obrigatório'),
  requiredCoverage: Yup.number()
    .typeError('Deve ser um número')
    .min(0, 'Deve ser maior que 0%')
    .notRequired()
    .nullable(),
  sharedGuaranteeParticipation: Yup.number()
    .typeError('Deve ser um número')
    .max(10000, 'deve ser menor que 100%')
    .min(0, 'Deve ser maior que 0%')
    .when('type', {
      is: GuaranteeType.COMPARTILHADA,
      then: Yup.number().required('Campo obrigatório'),
      otherwise: Yup.number()
        .notRequired()
        .nullable()
    }),
  guarantor: Yup.object().shape({
    type: Yup.string().required('Campo obrigatório'),
    individual: Yup.object().when('type', {
      is: 'individual',
      then: Yup.object().shape({
        isAbroad: Yup.boolean(),
        cpf: Yup.string().when('isAbroad', {
          is: false,
          then: ValidationSchema.cpf()
            .required('Campo obrigatório')
            .nullable(),
          otherwise: ValidationSchema.cpf()
            .notRequired()
            .nullable()
        }),
        name: Yup.string()
          .min(3, 'Precisa ter ao menos 3 caracteres')
          .max(255, 'Precisa ter menos de 256 caracteres')
          .required('Campo obrigatório')
      }),
      otherwise: Yup.object().shape({
        isAbroad: Yup.boolean(),
        cpf: Yup.string()
          .notRequired()
          .nullable(),
        name: Yup.string()
          .notRequired()
          .nullable()
      })
    }),
    company: Yup.object().when('type', {
      is: 'company',
      then: Yup.object().shape({
        isAbroad: Yup.boolean(),
        cnpj: Yup.string().when('isAbroad', {
          is: false,
          then: ValidationSchema.cnpj()
            .required('Campo obrigatório')
            .nullable(),
          otherwise: Yup.string()
            .notRequired()
            .nullable()
        }),
        businessName: Yup.string()
          .min(3, 'Precisa ter ao menos 3 caracteres')
          .max(255, 'Precisa ter menos de 256 caracteres')
          .required('Campo obrigatório')
      }),
      otherwise: Yup.object().shape({
        isAbroad: Yup.boolean(),
        cnpj: Yup.string()
          .notRequired()
          .nullable(),
        businessName: Yup.string()
          .notRequired()
          .nullable()
      })
    }),
    description: Yup.string()
      .max(255, 'Precisa ter menos de 256 caracteres')
      .nullable()
      .notRequired()
  }),
  comments: Yup.string()
    .notRequired()
    .nullable()
});

const formatInitialValue = (
  guaranteeData: Guarantee | undefined
): GuaranteeFormAttributes => {
  if (!guaranteeData) return initialValues;

  return {
    modality: guaranteeData.modality,
    object: guaranteeData.object ? guaranteeData.object : '',
    type: guaranteeData.type,
    comments: guaranteeData.comments,
    instrumentNumber: guaranteeData.instrumentNumber,
    sharedGuaranteeParticipation:
      guaranteeData.sharedGuaranteeParticipation?.toString() ?? '',
    requiredCoverage: guaranteeData.requiredCoverage?.toString() ?? '',
    fileUploadIds: guaranteeData.fileUploads.map(file => file.id),
    guarantor: {
      type: guaranteeData.guarantor.type,
      individual: {
        cpf: '',
        name: '',
        isAbroad: false,
        ...guaranteeData.guarantor.individual
      },
      company: {
        cnpj: '',
        businessName: '',
        isAbroad: false,
        ...guaranteeData.guarantor.company
      },
      description: guaranteeData.guarantor.description ?? ''
    }
  };
};

const formatError = (errors?: ApiError) => {
  if (!errors) return {};

  return {
    ...errors,
    guarantor: { individual: errors?.individual, company: errors?.company }
  };
};

const GuaranteeCard = ({
  caseId,
  contractId,
  guaranteeData,
  htmlId,
  increaseTotalCurrentValue,
  onDelete
}: Props): ReactElement => {
  const [isUploadEnabled] = useFeatureFlag(Feature.FILE_UPLOADS);

  const [guarantee, setGuarantee] = useState<Guarantee | undefined>(
    guaranteeData
  );

  const { cleanGuaranteeFormDirty } = useFormDirty();

  const { caseData } = useContext(CaseContext);

  const onSubmit: OnSubmit<GuaranteeFormAttributes> = async (
    values,
    formikHelpers
  ) => {
    try {
      const response = await submitGuarantee({
        guarantee,
        values,
        caseId,
        contractId
      });

      setGuarantee(response.data);

      formikHelpers.setValues(formatInitialValue(response.data));

      openSuccessToast('Garantia salva com sucesso!');
    } catch (error) {
      openErrorToast('Houve um erro ao salvar a garantia');
      formikHelpers.setErrors(formatError(error.errors));
    }
  };

  const handleDelete = (formikDirty: boolean) => async (): Promise<void> => {
    const message = 'Garantia excluida com sucesso!';
    if (!formikDirty && !guarantee) {
      return onDelete(message);
    }

    if (
      window.confirm(
        'Você deseja excluir essa garantia deste e de todos os contratos? Essa ação não poderá ser desfeita'
      )
    ) {
      try {
        if (guarantee) {
          await deleteGuarantee(caseId, guarantee.id);

          const guaranteeTotalValue = _.sumBy(guarantee.assets, 'valueCents');
          increaseTotalCurrentValue(-guaranteeTotalValue);
        }

        cleanGuaranteeFormDirty(htmlId);
        onDelete(message);
      } catch (error) {
        openErrorToast('Houve um erro ao excluir a garantia');
      }
    }
  };

  const handleRemoveGuaranteeFromContract = () => async (): Promise<void> => {
    if (window.confirm('Você deseja remover a garantia do contrato?')) {
      try {
        if (guarantee) {
          await removeGuaranteeFromContract({
            caseId,
            contractId,
            guaranteeId: guarantee.id
          });

          const guaranteeTotalValue = _.sumBy(guarantee.assets, 'valueCents');
          increaseTotalCurrentValue(-guaranteeTotalValue);
        }

        cleanGuaranteeFormDirty(htmlId);

        onDelete('Garantia removida do contrato com sucesso!');
      } catch (error) {
        openErrorToast('Houve um erro ao remover a garantia do contato');
      }
    }
  };

  const guaranteeOptions = (formikDirty: boolean) => {
    const excludeButton = {
      text: 'Excluir garantia',
      callback: handleDelete(formikDirty)
    };

    if (!guarantee || guarantee.contracts.length < 2) {
      return [excludeButton];
    }

    const removeFromContractButton = {
      text: 'Remover do contrato',
      callback: handleRemoveGuaranteeFromContract()
    };

    return [removeFromContractButton, excludeButton];
  };

  const negotiatorId = caseData?.negotiator?.id;

  const canUser = {
    addGuarantee: can(Action.CASOS_GARANTIAS_INCLUIR, negotiatorId),
    editGuarantee: can(Action.CASOS_GARANTIAS_EDITAR, negotiatorId),
    removeGuarantee: can(Action.CASOS_GARANTIAS_EXCLUIR, negotiatorId),
    addGuaranteeFile: can(
      Action.CASOS_ARQUIVOS_DA_GARANTIA_INCLUIR,
      negotiatorId
    ),
    showFiles: can(Action.CASOS_ARQUIVOS_DA_GARANTIA_CONSULTAR, negotiatorId),
    removeGuaranteeFile: can(
      Action.CASOS_ARQUIVOS_DA_GARANTIA_EXCLUIR,
      negotiatorId
    )
  };

  const canEditGuarantee =
    canUser.editGuarantee ||
    canUser.addGuaranteeFile ||
    canUser.removeGuaranteeFile;

  const canAddGuarantee =
    canUser.addGuarantee ||
    canUser.addGuaranteeFile ||
    canUser.removeGuaranteeFile;

  const canShowSaveButton = guarantee ? canEditGuarantee : canAddGuarantee;

  return (
    <Formik<GuaranteeFormAttributes>
      initialValues={formatInitialValue(guarantee)}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {formik => (
        <Card
          dataTestId="guarantee-card"
          className="guarantee-card"
          id={htmlId}
        >
          <div className="header">
            <div className="title-container">
              <h2 data-testid="card-header-title">{`Garantia ${formik.values.instrumentNumber}`}</h2>
            </div>
            <div className="actions">
              {canUser.removeGuarantee && (
                <SplitDropdownButton
                  small
                  options={guaranteeOptions(formik.dirty)}
                  danger
                />
              )}
              {canShowSaveButton && (
                <Button
                  dataTestId="card-submit"
                  onClick={formik.handleSubmit}
                  highlight
                  small
                  type="submit"
                  disabled={!formik.dirty}
                >
                  Salvar
                </Button>
              )}
            </div>
          </div>

          <Stack
            marginTop={StackMargin.MEDIUM}
            marginBottom={StackMargin.MEDIUM}
          >
            {guaranteeData?.executions.map(execution => (
              <ExecutionStatus
                key={execution.id}
                caseId={caseId}
                execution={execution}
              />
            ))}
          </Stack>

          {guarantee && guarantee.contracts.length > 1 && (
            <>
              <p className={style.linkedContractsLabel}>
                Essa garantia está presente nos seguintes contratos:
              </p>
              <div className={style.linkedContracts}>
                <ul>
                  {guarantee?.contracts.map(contract => (
                    <li
                      className={style.linkedContractLink}
                      key={`linked-contract-link-${contract.id}`}
                    >
                      <Link
                        highlight
                        underline
                        to={`/cases/${caseId}/debtors/contract/${contract.id}`}
                      >
                        Contrato {contract.number}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}

          <Form
            dirtyId={htmlId}
            id={`guarantee-form-${htmlId}`}
            caseId={caseId}
            formik={formik}
          />

          <AssetList
            caseId={caseId}
            guaranteeId={guarantee?.id}
            guaranteeAssets={guarantee?.assets ?? []}
            increaseGuaranteeTotalValue={increaseTotalCurrentValue}
          />

          {canUser.showFiles && isUploadEnabled && (
            <FormContainer>
              <FileField
                dataTestId="guarantee-file-upload"
                id={`${htmlId}-uploads`}
                name="guarantee-uploads"
                title="Arquivos da garantia"
                caseId={caseId}
                initialFiles={guarantee?.fileUploads}
                onUploadSuccess={ids => {
                  formik.setFieldValue('fileUploadIds', ids);
                }}
                showAdd={canUser.addGuaranteeFile}
                showDelete={canUser.removeGuaranteeFile}
              />
            </FormContainer>
          )}
        </Card>
      )}
    </Formik>
  );
};

export default GuaranteeCard;
