import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';

import { fetchCreditContract, deleteCreditContract, Action, can } from 'api';
import { ActionName, CreditContract } from 'types';
import * as Actions from 'utils/actions';

import {
  Title,
  Header,
  Button,
  openErrorToast,
  openSuccessToast,
  StackMargin,
  Stack
} from 'ui';
import { CaseContext } from 'components';
import ContractCard from './ContractCard';
import { Guarantees } from './Guarantees';

import './Contract.scss';
import Reclaimings from './Reclaimings';

import { useFormDirty } from './FormDirtyContext';

type Props = {
  caseId: string;
  contractId?: string;
};

const Contract = ({ caseId, contractId }: Props) => {
  const history = useHistory();
  const [contractNumber, setContractNumber] = useState<string>('');
  const [creditContract, setCreditContract] = useState<
    CreditContract | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { isAnyFormDirty, formsWithChange } = useFormDirty();

  async function request(caseId: string, contractId: string) {
    try {
      setIsLoading(true);

      const response = await fetchCreditContract({ caseId, contractId });

      setContractNumber(response.data.number);
      setCreditContract(response.data);
    } catch (error) {
      openErrorToast('Não foi possível carregar o contrato!');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (contractId) {
      request(caseId, contractId);
    }
  }, [contractId, caseId]);

  const handleDelete = async () => {
    if (
      !window.confirm('Você tem certeza de que deseja remover esse contrato?')
    )
      return;

    try {
      await deleteCreditContract(caseId, creditContract!.id);
      openSuccessToast('Contrato excluído com sucesso!');
      history.push(`/cases/${caseId}/debtors`);
    } catch {
      openErrorToast('Houve um erro ao excluir o contrato');
    }
  };

  const { caseData } = useContext(CaseContext);

  const negotiatorId = caseData?.negotiator?.id;

  const canUser = {
    deleteContract: can(Action.CASOS_CONTRATO_EXCLUIR, negotiatorId),
    showGuarantees: can(Action.CASOS_GARANTIAS_CONSULTAR, negotiatorId)
  };

  return (
    <div className="contract-page">
      <Header
        sticky
        isLoading={isLoading}
        actionsComponent={
          creditContract &&
          canUser.deleteContract && (
            <Button small outline danger type="button" onClick={handleDelete}>
              Excluir contrato
            </Button>
          )
        }
      >
        <Title>Contrato {contractNumber}</Title>
        {creditContract && (
          <span className="changed">
            {`Editado pela última vez em ${DateTime.fromISO(
              creditContract.changedAt
            )
              .setLocale('pt-BR')
              .toLocaleString(DateTime.DATE_FULL)}`}
          </span>
        )}
        {isAnyFormDirty && (
          <span className="info">
            Existem alterações não salvas em {formsWithChange()}
          </span>
        )}
      </Header>

      <div className="content">
        <ContractCard
          caseId={caseId}
          contractNumberHandler={setContractNumber}
          creditContractHandler={setCreditContract}
          creditContract={creditContract}
          isLoading={isLoading}
        />

        {creditContract && !isLoading && (
          <>
            {canUser.showGuarantees && (
              <Stack marginTop={StackMargin.XLARGE}>
                <Guarantees
                  caseId={caseId}
                  contractId={creditContract.id}
                  fetchedGuarantees={creditContract.guarantees}
                  canCreateRelationship={Actions.isAvailable(
                    creditContract.actions,
                    ActionName.CREATE_CONTRACT_GUARANTEE_RELATIONSHIP
                  )}
                />
              </Stack>
            )}

            <Stack marginTop={StackMargin.XLARGE}>
              <Reclaimings
                fetchedReclaimings={creditContract.reclaimings}
                contractId={creditContract.id}
                caseId={caseId}
              />
            </Stack>
          </>
        )}
      </div>
    </div>
  );
};

export default Contract;
