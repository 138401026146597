import { handleDateFieldChange } from 'domain/forms';
import React, { useState, ChangeEvent, useContext } from 'react';
import { getIn, FormikTouched, FormikErrors, FormikHandlers } from 'formik';
import { DateTime } from 'luxon';

import {
  ReclaimingFormAttributes,
  AutoCompleteItem,
  Payment,
  EntityType,
  FrequencyType,
  ReclamingType
} from 'types';
import { applyMask } from 'utils';
import {
  FormContainer,
  SelectField,
  Toggle,
  InputField,
  InfoArea,
  DatePickerField,
  TextArea
} from 'ui';
import { Action, can } from 'api';
import { AutoCompleteField, CaseContext } from 'components';
import RecurrentPayments from './RecurrentPayments';
import './Form.scss';

type Options = {
  value: string;
  label: string;
}[];

type Props = {
  errors: FormikErrors<ReclaimingFormAttributes>;
  onBlur: (event: React.ChangeEvent<HTMLElement>) => void;
  onChange: FormikHandlers['handleChange'];
  onSubmit: () => void;
  setFieldTouched: (field: string, value: boolean) => void;
  setFieldValue: (field: string, value: string | null | Payment[]) => void;
  touched: FormikTouched<ReclaimingFormAttributes>;
  values: ReclaimingFormAttributes;
  caseId: string;
  reclaimingId?: string;
};

const OPTIONS_TYPE: Options = [
  { value: ReclamingType.STOCK_OR_QUOTAS, label: 'Ações ou quotas' },
  { value: ReclamingType.CREDIT_ASSIGNMENT, label: 'Cessão de crédito' },
  {
    value: ReclamingType.GUARANTEE_LETTER_RETURN,
    label: 'Devolução de carta de fiança'
  },
  { value: ReclamingType.MONEY, label: 'Dinheiro' },
  { value: ReclamingType.STOCK, label: 'Estoque' },
  {
    value: ReclamingType.COMMERCIAL_RESIDENTIAL_PROPERTY,
    label: 'Imóvel comercial residencial'
  },
  { value: ReclamingType.RURAL_PROPERTY, label: 'Imóvel rural' },
  { value: ReclamingType.URBAN_LAND_PROPERTY, label: 'Imóvel terreno urbano' },
  {
    value: ReclamingType.MACHINES_AND_EQUIPMENTS,
    label: 'Maquinas e equipamentos'
  },
  { value: ReclamingType.VEHICLES, label: 'Veículos' }
];

const OPTIONS_PAYOR_TYPE: Options = [
  { value: EntityType.INDIVIDUAL, label: 'Física' },
  { value: EntityType.COMPANY, label: 'Jurídica' }
];

const OPTIONS_FREQUENCY: Options = [
  { value: FrequencyType.SINGLE, label: 'Única' },
  { value: FrequencyType.RECURRENT, label: 'Recorrente' }
];

const OPTIONS_ABROAD: Options = [
  { value: 'false', label: 'Brasil' },
  { value: 'true', label: 'Exterior' }
];

const Form = ({
  values,
  onChange,
  errors,
  touched,
  onBlur,
  onSubmit,
  setFieldValue,
  setFieldTouched,
  caseId,
  reclaimingId
}: Props) => {
  const [singleTotalAmount, setSingleTotalAmount] = useState<number>(
    Number(values.totalAmount)
  );
  const { payor } = values;
  const { caseData } = useContext(CaseContext);

  function handleToggle(name: string) {
    return function(event: ChangeEvent<HTMLInputElement>) {
      setFieldValue(name, event.target.value);
    };
  }

  function handleSelectDropdownDocument(
    selection: AutoCompleteItem | null,
    valueName: string,
    complementName: string
  ): void {
    if (!selection) return;

    setFieldValue(valueName, selection.value);
    setFieldValue(complementName, selection.complement);
  }

  const negotiatorId = caseData?.negotiator?.id;

  const canEditReclaiming = reclaimingId
    ? can(Action.CASOS_RECUPERACOES_EDITAR, negotiatorId)
    : can(Action.CASOS_RECUPERACOES_INCLUIR, negotiatorId);

  return (
    <form
      className="form-reclaiming"
      onSubmit={onSubmit}
      data-testid="reclaiming-form"
    >
      <FormContainer className="col-2">
        <SelectField
          id="reclaiming-type"
          name="type"
          value={values.type}
          title="Tipo de recuperação"
          error={getIn(touched, 'type') && getIn(errors, 'type')}
          onChange={onChange}
          onBlur={onBlur}
          options={OPTIONS_TYPE}
          dataTestId="reclaiming-reclaiming-type"
          disabled={!canEditReclaiming}
        />

        <Toggle
          title="Frequência"
          options={OPTIONS_FREQUENCY}
          checked={values.frequency}
          onChange={handleToggle('frequency')}
          disabled={!canEditReclaiming}
        />

        {values.frequency === FrequencyType.SINGLE && (
          <>
            <InputField
              name="totalAmount"
              type="currency"
              id="reclaiming-total-amount"
              value={values.totalAmount.toString()}
              title="Valor recuperado"
              error={
                getIn(touched, 'totalAmount') && getIn(errors, 'totalAmount')
              }
              onChange={onChange}
              onBlur={onBlur}
              dataTestId="reclaiming-total-amount"
              disabled={!canEditReclaiming}
            />

            <DatePickerField
              id="reclaiming-date"
              name="date"
              title="Data"
              placeholder="Selecione..."
              value={values.date}
              onChange={handleDateFieldChange(
                setFieldValue,
                setFieldTouched,
                'date'
              )}
              onBlur={onBlur}
              error={getIn(touched, 'date') && getIn(errors, 'date')}
              max={DateTime.local().toISO()}
              disabled={!canEditReclaiming}
            />
          </>
        )}
      </FormContainer>

      {values.frequency === FrequencyType.RECURRENT && (
        <div data-testid="recurrent-payments">
          <InfoArea
            label="Valor total recuperado"
            value={applyMask('currency', singleTotalAmount.toString())}
          />

          <RecurrentPayments
            onChange={onChange}
            onBlur={onBlur}
            values={values}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            errors={errors}
            touched={touched}
            setSingleTotalAmount={setSingleTotalAmount}
            singleTotalAmount={singleTotalAmount}
          />
        </div>
      )}

      <FormContainer
        className="col-4 -last-double"
        well
        noPadding
        title="Pagante"
      >
        <Toggle
          title="Tipo de pessoa"
          options={OPTIONS_PAYOR_TYPE}
          checked={values.payor.type}
          onChange={handleToggle('payor.type')}
          dataTestId="reclaiming-type-document"
          name="payor.type"
          disabled={!canEditReclaiming}
        />

        {payor.type === EntityType.INDIVIDUAL ? (
          <>
            <Toggle
              name="payor.individual.isAbroad"
              title="País"
              options={OPTIONS_ABROAD}
              checked={values.payor.individual.isAbroad.toString()}
              onChange={event =>
                setFieldValue('payor.individual.isAbroad', event.target.value)
              }
              disabled={!canEditReclaiming}
            />
            <AutoCompleteField
              caseId={caseId}
              isAbroad={values.payor.individual.isAbroad}
              id="reclaiming-individual-cpf"
              name="payor.individual.cpf"
              type="cpf"
              entityType={EntityType.INDIVIDUAL}
              dataTestId="reclaiming-document-individual-cpf"
              onSelect={selection => {
                handleSelectDropdownDocument(
                  selection,
                  'payor.individual.cpf',
                  'payor.individual.name'
                );
              }}
              dataToItem={data => ({
                complement: data.individual.name,
                value: data.individual.cpf
              })}
              value={values.payor.individual.cpf}
              title="CPF"
              error={
                getIn(touched, 'payor.individual.cpf') &&
                getIn(errors, 'payor.individual.cpf')
              }
              onChange={onChange}
              onBlur={onBlur}
              disabled={!canEditReclaiming}
            />
            <AutoCompleteField
              caseId={caseId}
              isAbroad={values.payor.individual.isAbroad}
              id="reclaiming-individual-name"
              name="payor.individual.name"
              type="text"
              value={values.payor.individual.name}
              entityType={EntityType.INDIVIDUAL}
              onChange={onChange}
              onBlur={onBlur}
              dataTestId="reclaiming-document-individual-name"
              title="Nome completo"
              onSelect={selection => {
                handleSelectDropdownDocument(
                  selection,
                  'payor.individual.name',
                  'payor.individual.cpf'
                );
              }}
              dataToItem={data => ({
                complement: data.individual.cpf,
                value: data.individual.name
              })}
              error={
                getIn(touched, 'payor.individual.name') &&
                getIn(errors, 'payor.individual.name')
              }
              disabled={!canEditReclaiming}
            />
          </>
        ) : (
          <>
            <Toggle
              name="payor.company.isAbroad"
              title="País"
              options={OPTIONS_ABROAD}
              checked={values.payor.company.isAbroad.toString()}
              onChange={event =>
                setFieldValue('payor.company.isAbroad', event.target.value)
              }
              disabled={!canEditReclaiming}
            />
            <AutoCompleteField
              caseId={caseId}
              isAbroad={values.payor.company.isAbroad}
              entityType={EntityType.COMPANY}
              onSelect={selection => {
                handleSelectDropdownDocument(
                  selection,
                  'payor.company.cnpj',
                  'payor.company.businessName'
                );
              }}
              dataToItem={data => ({
                complement: data.company.businessName,
                value: data.company.cnpj
              })}
              type="cnpj"
              dataTestId="reclaiming-document-company"
              id="reclaiming-company-cnpj"
              name="payor.company.cnpj"
              value={values.payor.company.cnpj}
              title="CNPJ"
              error={
                getIn(touched, 'payor.company.cnpj') &&
                getIn(errors, 'payor.company.cnpj')
              }
              onChange={onChange}
              onBlur={onBlur}
              disabled={!canEditReclaiming}
            />
            <AutoCompleteField
              caseId={caseId}
              isAbroad={values.payor.company.isAbroad}
              entityType={EntityType.COMPANY}
              onSelect={selection => {
                handleSelectDropdownDocument(
                  selection,
                  'payor.company.businessName',
                  'payor.company.cnpj'
                );
              }}
              dataToItem={data => ({
                value: data.company.businessName,
                complement: data.company.cnpj
              })}
              dataTestId="input-guarantor-company-businessname"
              id="reclaiming-company-businessName"
              name="payor.company.businessName"
              type="text"
              value={values.payor.company.businessName}
              title="Razão social"
              error={
                getIn(touched, 'payor.company.businessName') &&
                getIn(errors, 'payor.company.businessName')
              }
              onChange={onChange}
              onBlur={onBlur}
              disabled={!canEditReclaiming}
            />
          </>
        )}
      </FormContainer>

      <FormContainer>
        <TextArea
          id="reclaiming-comments"
          name="comments"
          title="Comentários"
          value={values.comments}
          onChange={onChange}
          onBlur={onBlur}
          error={getIn(touched, 'comments') && getIn(errors, 'comments')}
          disabled={!canEditReclaiming}
        />
      </FormContainer>
    </form>
  );
};

export default Form;
