import { entityDocument, entityName } from 'domain/entity';
import { judicialSituationLabel, countContracts } from 'domain/debtors';
import { modalityLabel, statusLabel } from 'domain/contracts';
import { guaranteeTotalValue } from 'domain/guarantees';

import React from 'react';
import sumBy from 'lodash/sumBy';
import sum from 'lodash/sum';
import cn from 'classnames';

import { fetchGroupedWithContractAndInvoices } from 'api';
import { useQuery } from 'hooks';
import {
  pluralize,
  valueToMillions,
  applyDateMask,
  applyPercentMask
} from 'utils';

import { Stack, StackMargin } from 'ui';

import { DebtorGroupedWithContractAndInvoice } from 'types';

import { SummaryError } from 'components';
import SummaryLoader from '../SummaryLoader';

import style from './Debtors.module.scss';

type Props = {
  caseId: string;
};

const Debtors = ({ caseId }: Props) => {
  const { data: debtorsWithContracts = [], error, isLoading } = useQuery<
    DebtorGroupedWithContractAndInvoice[],
    { caseId: string }
  >(fetchGroupedWithContractAndInvoices, {
    caseId
  });

  function fetchStatus() {
    if (debtorsWithContracts && debtorsWithContracts.length > 0) {
      return `${pluralize(
        debtorsWithContracts.length,
        'devedor',
        'devedores'
      )}, ${pluralize(
        countContracts(debtorsWithContracts),
        'contrato',
        'contratos'
      )}`;
    }

    return '0 devedores, 0 contratos';
  }

  if (isLoading) {
    return <SummaryLoader title="Devedores e contratos" />;
  }

  if (error) {
    return (
      <SummaryError
        title="Devedores e contratos"
        message="Erro ao listar devedores, tente novamente."
      />
    );
  }

  return (
    <Stack marginTop={StackMargin.XLARGE}>
      <h3 className={style.title}>{fetchStatus()}</h3>
      <Stack marginTop={StackMargin.LARGE}>
        <table className={style.accordionTable}>
          <thead>
            <tr>
              <th
                className={cn([style.tableHeader, style.tableHeaderFirst])}
                colSpan={3}
              >
                Devedor / Contrato
              </th>
              <th className={cn([style.tableHeader, style.tableHeaderSecond])}>
                Situação judicial
              </th>
              <th className={cn([style.tableHeader, style.tableHeaderOverdue])}>
                Data e saldo devedor
              </th>
              <th className={style.tableHeader}>Garantias</th>
              <th className={style.tableHeader}>Recuperações</th>
              <th className={cn([style.tableHeader, style.tableHeaderLast])}>
                PPD (%)
              </th>
            </tr>
          </thead>

          {debtorsWithContracts &&
            debtorsWithContracts.map(
              (
                {
                  debtor,
                  totalDebtAmount,
                  totalGuaranteeAmount,
                  totalReclaimingAmount,
                  contractsByModality,
                  weightedPddAverage
                },
                index
              ) => {
                return (
                  <tbody className={style.tableBody} key={index}>
                    <tr
                      data-testid="debtor-row"
                      className={cn([style.debtorRow])}
                    >
                      <td
                        colSpan={3}
                        className={cn([style.tableData, style.firstCol])}
                      >
                        <div>
                          <span className={style.debtorName}>
                            {entityName(debtor.entity)}
                          </span>
                          <span className={style.debtorDoc}>
                            {entityDocument(debtor.entity)}
                          </span>
                        </div>
                      </td>
                      <td
                        className={cn([
                          style.tableData,
                          style.judicialSituation
                        ])}
                      >
                        {judicialSituationLabel(debtor.judicialSituation)}
                      </td>
                      <td
                        className={cn([style.tableData, style.debtorSumValue])}
                      >
                        {valueToMillions(totalDebtAmount)}
                      </td>
                      <td
                        className={cn([style.tableData, style.debtorSumValue])}
                      >
                        {valueToMillions(totalGuaranteeAmount)}
                      </td>
                      <td
                        className={cn([style.tableData, style.debtorSumValue])}
                      >
                        {valueToMillions(totalReclaimingAmount)}
                      </td>
                      <td
                        className={cn([
                          style.tableData,
                          style.debtorSumValue,
                          style.tableDataLast
                        ])}
                      >
                        {applyPercentMask(weightedPddAverage.toString())}%
                      </td>
                    </tr>
                    {contractsByModality.map((contractByModality, index) => {
                      return (
                        <React.Fragment key={`contract-${index}`}>
                          <tr
                            data-testid="contract-row"
                            className={style.contractRow}
                          >
                            <td className={style.indentCol}></td>
                            <td
                              colSpan={3}
                              className={cn([
                                style.tableData,
                                style.firstCol,
                                style.tdNotClickable
                              ])}
                            >
                              {modalityLabel(contractByModality.modality)}
                            </td>
                            <td
                              className={cn([
                                style.tableData,
                                style.contractSumValue,
                                style.tdNotClickable
                              ])}
                            >
                              {valueToMillions(
                                contractByModality.totalDebtAmount
                              )}
                            </td>
                            <td
                              className={cn([
                                style.tableData,
                                style.contractSumValue,
                                style.tdNotClickable
                              ])}
                            >
                              {valueToMillions(
                                contractByModality.totalGuaranteeAmount
                              )}
                            </td>
                            <td
                              className={cn([
                                style.tableData,
                                style.contractSumValue,
                                style.tdNotClickable
                              ])}
                            >
                              {valueToMillions(
                                contractByModality.totalReclaimingAmount
                              )}
                            </td>
                            <td
                              className={cn([
                                style.tableData,
                                style.contractSumValue,
                                style.tableDataLast,
                                style.tdNotClickable
                              ])}
                            >
                              {applyPercentMask(
                                contractByModality.weightedPddAverage.toString()
                              )}
                              %
                            </td>
                          </tr>

                          {contractByModality.contracts.map(contract => {
                            return (
                              <tr
                                data-testid="contract-row-info"
                                className={cn([style.contractRow])}
                                key={`${contract.id}-content`}
                              >
                                <td className={style.indentCol}></td>
                                <td className={style.indentCol}></td>
                                <td
                                  colSpan={2}
                                  className={cn([
                                    style.tableData,
                                    style.firstCol
                                  ])}
                                >
                                  {contract.isClosed
                                    ? `${contract.number} (${statusLabel(
                                        contract.status
                                      )})`
                                    : contract.number}
                                </td>
                                <td
                                  className={cn([
                                    style.tableData,
                                    style.contractValue
                                  ])}
                                >
                                  <div className={style.contractOverdueAmount}>
                                    <span>
                                      {valueToMillions(
                                        contract.overdueAmountAtCuttingDate
                                      )}
                                    </span>
                                    <span
                                      className={
                                        style.contractOverdueAmountDate
                                      }
                                    >
                                      {applyDateMask(contract.cuttingDate)}
                                    </span>
                                  </div>
                                </td>
                                <td
                                  className={cn([
                                    style.tableData,
                                    style.contractValue
                                  ])}
                                  data-testid="sum-appraisal-value-cents"
                                >
                                  {valueToMillions(
                                    sum(
                                      contract.guarantees.flatMap(guarantee =>
                                        guaranteeTotalValue(guarantee)
                                      )
                                    )
                                  )}
                                </td>
                                <td
                                  className={cn([
                                    style.tableData,
                                    style.contractValue
                                  ])}
                                >
                                  {valueToMillions(
                                    sumBy(contract.reclaimings, 'totalAmount')
                                  )}
                                </td>
                                <td
                                  className={cn([
                                    style.tableData,
                                    style.contractValue,
                                    style.tableDataLast
                                  ])}
                                >
                                  {applyPercentMask(
                                    contract.pddImpairmentPercentage.toString()
                                  )}
                                  %
                                </td>
                              </tr>
                            );
                          })}
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                );
              }
            )}
        </table>
      </Stack>
    </Stack>
  );
};

export default Debtors;
