import React, { ReactElement } from 'react';

import { Link } from 'ui';

type Props = {
  fallback?: ReactElement | string;
  renderCondition?: boolean;
  onClick: () => void;
  isLoading?: boolean;
  text?: string;
};

const Toggle = ({
  isLoading = false,
  text = 'Ver origem',
  renderCondition = true,
  onClick,
  fallback = ''
}: Props) => {
  if (!renderCondition) return <>{fallback}</>;

  return (
    <Link
      highlight={!isLoading}
      underline={!isLoading}
      disabled={isLoading}
      onClick={onClick}
    >
      {text}
    </Link>
  );
};

export default Toggle;
