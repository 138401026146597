import React from 'react';

import { Card, Loader } from 'ui';
import { FileUpload } from 'types';

import style from './ImageGalleryField.module.scss';
import ImageGalleryField from './ImageGalleryField';

type Props = {
  images: FileUpload[];
  caseId: string;
  onUploadSuccess?: (imageIds: string[]) => void;
  isLoading?: boolean;
  dataTestId?: string;
  showAdd?: boolean;
  showDelete?: boolean;
};

const GalleryLoader = ({ isLoading = false, ...props }: Props) => {
  if (isLoading) {
    return (
      <Card className={style.card}>
        <h3 className={style.title}>Fotos</h3>
        <div className={style.images}>
          <Loader width={1.48} height={9} />
          <Loader width={1.48} height={9} />
          <Loader width={1.48} height={9} />
        </div>
      </Card>
    );
  }

  return <ImageGalleryField {...props} />;
};

export default GalleryLoader;
