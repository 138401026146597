import { useCallback, useMemo, useState } from 'react';
import { omit } from 'lodash';

export type DuplicateEntryInput = {
  duplicateAmount: number;
  currentKey: string;
  keysToReset?: {
    [key in string]: unknown;
  };
};

export type ContainerEntry = {
  isDuplicated?: boolean;
  scrollToThis?: boolean;
};

export function useContainer<Type>(initialEntries: Type[]) {
  const [entries, setEntries] = useState<
    Record<string, (Type | undefined) & ContainerEntry>
  >(() =>
    initialEntries.reduce(
      (items, item, index) => ({
        ...items,
        [index]: item
      }),
      {}
    )
  );

  const [lastIndex, setLastIndex] = useState(initialEntries.length);

  const newEntry = useCallback(() => {
    setEntries(items => ({
      ...items,
      [lastIndex]: undefined
    }));
    setLastIndex(i => i + 1);
  }, [lastIndex, setEntries, setLastIndex]);

  const setEntry = useCallback(
    (key: string) => (entry: Type) => {
      setEntries(entries => ({
        ...entries,
        [key]: entry
      }));
    },
    [setEntries]
  );

  const mapEntries = useCallback(
    function mapListEntries<T>(
      mapperFn: (
        item: (Type | undefined) & ContainerEntry,
        key: string,
        index: number
      ) => T
    ): T[] {
      return Object.entries(entries).map(([key, item], index) =>
        mapperFn(item, key, index)
      );
    },
    [entries]
  );

  const reduceEntries = useCallback(
    function<A>(
      reducer: (
        acc: A,
        item: (Type | undefined) & ContainerEntry,
        index: number
      ) => A,
      initial: A
    ) {
      const items = Object.values(entries);
      return items.reduce(reducer, initial);
    },
    [entries]
  );

  const deleteEntry = useCallback(
    (key: string) => () => {
      setEntries(entries => omit(entries, key));
    },
    [setEntries]
  );

  const duplicateEntry = useCallback(
    function({
      duplicateAmount,
      currentKey,
      keysToReset
    }: DuplicateEntryInput) {
      let duplicateEntries = {};

      for (let index = 1; index <= duplicateAmount; index++) {
        duplicateEntries = {
          ...duplicateEntries,
          [index + lastIndex]: {
            ...entries[currentKey],
            ...keysToReset,
            isDuplicated: true,
            scrollToThis: index === 1
          }
        };
      }

      setEntries(entries => ({
        ...entries,
        ...duplicateEntries
      }));
      setLastIndex(i => i + duplicateAmount + 1);
    },
    [entries, setEntries, setLastIndex, lastIndex]
  );

  const entriesCount = useMemo(() => Object.values(entries).length, [entries]);
  return {
    setEntry,
    setEntries,
    newEntry,
    mapEntries,
    reduceEntries,
    entriesCount,
    deleteEntry,
    duplicateEntry
  };
}
