import {
  Asset,
  AssetAttributes,
  AssetTotalValue,
  AssetType,
  RequestQueryParams
} from 'types';
import Client from './client';

export type AssetSearchParams = {
  name?: string;
  entityId?: string;
  types?: AssetType[];
  guaranteeId?: string;
  excludeFrom?: string[];
  excludeGuaranteeAsset?: boolean;
  isGroup?: boolean;
};

export type FetchAssetsParams = RequestQueryParams &
  Pick<Asset, 'caseId'> & { params?: AssetSearchParams };

function createAsset(caseId: string, assetData: AssetAttributes) {
  return Client.post<AssetAttributes, Asset>(
    `cases/${caseId}/assets`,
    assetData
  );
}

function fetchAssets({
  caseId,
  pagination,
  orderBy,
  params
}: FetchAssetsParams) {
  return Client.get<Asset[]>(`cases/${caseId}/assets`, {
    params: {
      ...params,
      page: pagination?.page.toString(),
      pageSize: pagination?.pageSize.toString(),
      field: orderBy?.field,
      value: orderBy?.value
    }
  });
}

function fetchAsset({ caseId, assetId }: { caseId: string; assetId: string }) {
  return Client.get<Asset>(`cases/${caseId}/assets/${assetId}`);
}

function deleteAsset(caseId: string, id: string) {
  return Client.delete<void>(`cases/${caseId}/assets/${id}`);
}

function updateAsset(caseId: string, id: string, assetData: AssetAttributes) {
  return Client.put<AssetAttributes, Asset>(
    `cases/${caseId}/assets/${id}`,
    assetData
  );
}

function fetchAssetsTotalValue({ caseId }: Pick<Asset, 'caseId'>) {
  return Client.get<AssetTotalValue>(`cases/${caseId}/assets_total`);
}

function fetchAssetsSummary({ caseId }: Pick<Asset, 'caseId'>) {
  return Client.get<Asset[]>(`cases/${caseId}/assets_summary`);
}

export {
  createAsset,
  deleteAsset,
  fetchAssets,
  fetchAsset,
  updateAsset,
  fetchAssetsTotalValue,
  fetchAssetsSummary
};
