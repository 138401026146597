import React from 'react';
import { motion, AnimateSharedLayout, AnimatePresence } from 'framer-motion';
import { PointFeature } from 'supercluster';

import { useInstagramProfile } from 'components';
import { IconTooltip, RadioSwitch, Spinner } from 'ui';
import { LocationMapPoints, CityMapPoints, ReportCaseCity } from 'types';

import GoogleMaps from 'components/GoogleMaps/GoogleMaps';

import { useToggles } from 'hooks';
import style from './LocationMap.module.scss';

type Props = {
  caseId: string;
  cities?: ReportCaseCity[];
  initialAnimation?: boolean;
  isLoading?: boolean;
  reportId: string;
  title: string;
  showMap?: boolean;
  hideEmptyMessage?: boolean;
  showInstagramPostOnPin?: boolean;
  showInstagramLocations?: boolean;
  canBookmarkPublication?: boolean;
};

const LocationMap = ({
  caseId,
  initialAnimation,
  isLoading = false,
  reportId,
  cities = [],
  title,
  showMap = true,
  hideEmptyMessage = false,
  showInstagramPostOnPin = true,
  showInstagramLocations = true,
  canBookmarkPublication = true
}: Props) => {
  const { instagramProfile } = useInstagramProfile();
  const { toggle, isOpen } = useToggles<'showMap'>({ showMap: false });

  const locations = instagramProfile?.aggregatedLocations ?? [];

  const points: PointFeature<LocationMapPoints>[] = showInstagramLocations
    ? locations.map(loc => ({
        type: 'Feature',
        properties: {
          cluster: false,
          caseId: caseId,
          reportId: reportId,
          displayName: loc.location.displayName,
          googleMapsUrl: loc.location.googleMapsUrl,
          id: loc.location.id,
          publicationsCount: loc.publicationsCount,
          locationId: loc.location.id
        },
        geometry: {
          type: 'Point',
          coordinates: [
            parseFloat(loc.location.lon),
            parseFloat(loc.location.lat)
          ]
        }
      }))
    : [];

  const cityPoints: PointFeature<CityMapPoints>[] = cities.map(city => ({
    type: 'Feature',
    properties: {
      cluster: false,
      displayName: `${city.name} - ${city.state}`,
      locationCount: city.origin?.count,
      id: city.id,
      caseId: caseId
    },
    geometry: {
      type: 'Point',
      coordinates: [city.longitude!, city.latitude!]
    }
  }));

  const shouldShowMap =
    showMap && (Boolean(locations.length) || Boolean(cities.length));

  return (
    <div className={style.mapsWrapper}>
      <div className={style.mapsTitleWrapper}>
        <div className={style.providerTooltipWrapper}>
          <h3 className={style.mapsTitle}>{title}</h3>
          <IconTooltip
            text="As coordenadas apresentadas são as com maior índice de relevância, mas podem não estar corretas"
            dataTestId="location-map-tooltip"
            className={style.providerTooltip}
          />
        </div>
        <div>
          {shouldShowMap && (
            <RadioSwitch
              label="Mostrar mapa de praças"
              onChange={toggle('showMap')}
              active={isOpen.showMap}
              inputId="report-case-cities-map"
            />
          )}
        </div>
      </div>
      {isOpen.showMap && (
        <AnimateSharedLayout type="crossfade">
          <AnimatePresence initial={initialAnimation}>
            <motion.div
              layout
              className={style.maps}
              data-testid="report-maps"
              key="map"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: '450px' },
                collapsed: { opacity: 0, height: '0px' }
              }}
              transition={{ duration: 1, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              <GoogleMaps
                points={points}
                cityPoints={cityPoints}
                showInstagramPostOnPin={showInstagramPostOnPin}
                canBookmarkPublication={canBookmarkPublication}
              />

              {isLoading && (
                <div className={style.mapOverlay}>
                  <Spinner big color="white" />
                </div>
              )}
            </motion.div>
          </AnimatePresence>
        </AnimateSharedLayout>
      )}

      {!shouldShowMap && !hideEmptyMessage && (
        <div className={style.mapsEmpty}>Nenhuma localização encontrada</div>
      )}
    </div>
  );
};

export default LocationMap;
