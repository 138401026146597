import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { logout } from 'api';
import { useSocket } from 'components';

const Logout = () => {
  const history = useHistory();
  const { socket } = useSocket();

  useEffect(() => {
    logout(history);
    socket?.disconnect();
  }, [history, socket]);

  return null;
};

export default Logout;
