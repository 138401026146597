import React from 'react';
import { useFormik, getIn } from 'formik';
import * as Yup from 'yup';

import {
  openErrorToast,
  openSuccessToast,
  Button,
  FormContainer,
  InputField,
  StackMargin,
  Stack
} from 'ui';

import { User, OnSubmit, ExternalLawyerFormAttributes } from 'types';

import { createExternalLawyer, fetchProfiles, updateUser } from 'api';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(255, 'Deve ter no máximo 255 caractere(s)')
    .matches(/.+\s.+/, { message: 'Precisa ter no mínimo duas palavras' })
    .matches(/^\D+$/, { message: 'Não deve conter número' })
    .required('Campo obrigatório'),
  email: Yup.string()
    .required('Campo obrigatório')
    .max(255, 'Deve ter no máximo 255 caractere(s)')
    .email('Digite um e-mail válido')
});

type Props = {
  lawFirmId: string;
  handleSave: (user: User) => void;
  formData?: User;
};

const Form = ({ handleSave, lawFirmId, formData }: Props) => {
  const handleCreate: OnSubmit<ExternalLawyerFormAttributes> = async (
    externalLawyerData,
    formikHelpers
  ): Promise<void> => {
    try {
      const fetchProfilesResponse = await fetchProfiles();
      const externalLawyerProfile = fetchProfilesResponse.data.find(
        profile => profile.name === 'YIELD_ADV_EXTERNO'
      );

      const response = await createExternalLawyer(lawFirmId, {
        ...externalLawyerData,
        profileId: externalLawyerProfile?.id || ''
      });

      openSuccessToast('Convite enviado com sucesso');
      handleSave(response.data);
    } catch (error) {
      openErrorToast('Houve um erro ao enviar o convite');
      formikHelpers.setErrors(error.errors);
    }
  };

  const handleUpdate: OnSubmit<ExternalLawyerFormAttributes> = async (
    externalLawyerData,
    formikHelpers
  ): Promise<void> => {
    if (!formData) return;

    try {
      const response = await updateUser(formData.id, externalLawyerData);
      openSuccessToast('Usuário atualizado com sucesso');
      handleSave(response.data);
    } catch (error) {
      openErrorToast('Houve um erro ao atualizar usuário');
      formikHelpers.setErrors(error.errors);
    }
  };

  const onSubmit: OnSubmit<ExternalLawyerFormAttributes> = async (
    externalLawyerData,
    formikHelpers
  ) => {
    if (formData) {
      handleUpdate(externalLawyerData, formikHelpers);
    } else {
      handleCreate(externalLawyerData, formikHelpers);
    }
  };

  const initialValues: ExternalLawyerFormAttributes = {
    name: formData?.name ?? '',
    email: formData?.email ?? '',
    profileId: formData?.profile.id ?? ''
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit
  });

  return (
    <form onSubmit={formik.handleSubmit} data-testid="external-lawyer-form">
      <FormContainer>
        <InputField
          dataTestId="input-name"
          error={formik.touched.name && getIn(formik.errors, 'name')}
          id="user-name"
          name="name"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          title="Nome completo"
          type="text"
          value={formik.values.name}
        />

        <InputField
          dataTestId="input-email"
          error={formik.touched.email && getIn(formik.errors, 'email')}
          id="user-email"
          name="email"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          title="E-mail"
          type="text"
          value={formik.values.email}
        />
      </FormContainer>
      <Stack marginTop={StackMargin.MEDIUM}>
        <Button
          dataTestId="submit-lawyer"
          disabled={!formik.isValid || !formik.dirty}
          highlight
          centered
          onClick={formik.handleSubmit}
          type="submit"
        >
          {formData ? 'Salvar' : 'Convidar'}
        </Button>
      </Stack>
    </form>
  );
};

export default Form;
