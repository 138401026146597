import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory, Redirect } from 'react-router-dom';
import * as queryString from 'query-string';
import { openErrorToast, Link, Title } from 'ui';
import { useFeatureFlag } from 'hooks';
import { login, hasValidToken, returnUrl, saveReturnUrl } from 'api';
import { useSocket } from 'components';
import { AugmentedWindow, Feature } from 'types';
import LayoutAuthentication from 'Layouts/LayoutAuthentication';
import ResetPassword from './ResetPassword';
import Form from './Form';

const Login = () => {
  const history = useHistory();
  const locationSearch = history.location.search;
  const [shouldShowResetPassword, setResetPassword] = useState(false);
  const [isCaptchaEnabled] = useFeatureFlag(Feature.LOGIN_CAPTCHA);
  const [isADLoginEnabled] = useFeatureFlag(Feature.LOGIN_OAUTH);

  const { socket } = useSocket();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Digite um e-mail válido')
      .required('Campo obrigatório'),
    password: Yup.string().required('Campo obrigatório'),
    captcha: isCaptchaEnabled
      ? Yup.string().required('Campo obrigatório')
      : Yup.string()
          .notRequired()
          .nullable()
  });

  useEffect(() => {
    saveReturnUrl();
  });

  useEffect(() => {
    const urlParams = queryString.parse(locationSearch);

    const errorMessage: string | undefined = urlParams?.error_message
      ? urlParams?.error_message.toString()
      : undefined;

    if (errorMessage) {
      openErrorToast(errorMessage, false);
    }
  }, [locationSearch]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      captcha: ''
    },
    validationSchema,
    onSubmit: async values => {
      try {
        await login(history, values);

        socket?.connect();
      } catch (error) {
        if (error.status === 422) {
          formik.setErrors(error.errors);
          if (error.errors?.profile) {
            error.errors.profile.forEach((profileErrorMsg: string) => {
              openErrorToast(profileErrorMsg, 12000);
            });
          }
        } else {
          openErrorToast('Não foi possível fazer o login.');
        }
      }
    }
  });

  function showResetPassword() {
    setResetPassword(true);
  }

  function showLogin() {
    formik.resetForm();
    setResetPassword(false);
  }

  if (hasValidToken()) {
    return <Redirect to={returnUrl()} />;
  }

  if (isADLoginEnabled) {
    const OAUTH_LOGIN_URL: string = (window as AugmentedWindow).ENV
      .REACT_APP_OAUTH_LOGIN_URL;
    const decodedLoginUrl = atob(OAUTH_LOGIN_URL);

    return (
      <LayoutAuthentication>
        <>
          <Title>Login</Title>
          <Link asButton highlight href={decodedLoginUrl} dataTestId="ad-login">
            Entrar com AD
          </Link>
        </>
      </LayoutAuthentication>
    );
  }

  return (
    <LayoutAuthentication>
      {shouldShowResetPassword ? (
        <ResetPassword
          initialEmail={formik.values.email}
          showLogin={showLogin}
        />
      ) : (
        <Form
          handleBlur={formik.handleBlur}
          handleSubmit={formik.handleSubmit}
          errors={formik.errors}
          touched={formik.touched}
          isSubmitting={formik.isSubmitting}
          handleOnChange={formik.handleChange}
          values={formik.values}
          showResetPassword={showResetPassword}
          setFieldValue={formik.setFieldValue}
        />
      )}
    </LayoutAuthentication>
  );
};

export default Login;
