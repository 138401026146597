import { FileUpload } from './file';
import { User } from './user';

export enum CaseEventType {
  REUNIAO_CREDORES = 'reuniao_credores',
  REUNIAO_EMPRESA_OU_ADVISOR = 'reuniao_empresa_advisor',
  REUNIAO_ESCRITORIO = 'reuniao_escritorio',
  REUNIAO_INTERNA = 'reuniao_interna',
  AGC = 'agc',
  VISITA = 'visita',
  UPDATE = 'update',
  APROVAÇÃO_OU_COMITE = 'aprovacao_comite',
  PROPOSTA_ACORDO = 'proposta_acordo',
  PROPOSTA_CESSAO = 'proposta_cessao',
  ALTERACOES_CONTRATUAIS = 'alteracoes_contratuais',
  CLIPPING = 'clipping',
  OUTROS = 'outros'
}

export type CaseEventAttributes = {
  content: string;
  date: string;
  description: string;
  type: CaseEventType | null;
  title: string;
};

export type CaseEvent = CaseEventAttributes & {
  id: string;
  fileUploads: Array<FileUpload>;
  user: User;
};

export type CaseEventFormAttributes = Omit<CaseEventAttributes, 'user'> & {
  fileUploadIds: string[];
  user?: User;
};
