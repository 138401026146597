import {
  LawyersAutocomplete,
  PartiesAutocomplete,
  InvolvedParty,
  Lawyer,
  CourtAutocomplete,
  CourtFormAttributes,
  EntityType
} from 'types';

export function normalizeParties(
  parties: PartiesAutocomplete[]
): InvolvedParty[] {
  return parties.map((party: PartiesAutocomplete) => {
    const individual = party.entityType === EntityType.INDIVIDUAL && {
      name: party.name || '',
      cpf: party.document || ''
    };

    const company = party.entityType === EntityType.COMPANY && {
      businessName: party.name || '',
      cnpj: party.document || ''
    };

    return {
      id: null,
      entityType: party.entityType,
      type: party.type,
      isInCase: party.isInCase || false,
      [party.entityType]: company || individual
    };
  });
}

export function normalizeLawyers(lawyers: LawyersAutocomplete[]): Lawyer[] {
  return lawyers.map(lawyer => ({
    id: null,
    name: lawyer.name,
    cause: lawyer.cause || ''
  }));
}

export function normalizeCourts(court: CourtAutocomplete): CourtFormAttributes {
  return {
    id: court.id || '',
    name: court.name || '',
    judgingBody: court.judgingBody || '',
    instance: court.instance || '',
    city: {
      id: court.city.id || '',
      name: court.city.name || '',
      state: court.city.state || ''
    }
  };
}
