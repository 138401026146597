import { ERROR_MESSAGE } from 'domain/reports';
import React from 'react';

import cn from 'classnames';

import { ProviderTooltip, SummaryError } from 'components';
import { CardInfo } from 'ui';

import { applyCodeMask, handleNullValue } from 'utils';
import { ReportCnae } from 'types';
import { fetchReportCnae, fetchReportCnaeSummary } from 'api';
import { useQuery } from 'hooks';

import style from './Cnaes.module.scss';

const INITIAL_PAGE = 1;
const DEFAULT_PAGE_SIZE = 6;

type Props = {
  caseId: string;
  reportId: string;
  isSummary?: boolean;
  providerStatus?: string;
};

const Cnaes = ({ caseId, reportId, isSummary, providerStatus }: Props) => {
  const fetchFunction = isSummary ? fetchReportCnaeSummary : fetchReportCnae;

  const { data: cnaes = [], isLoading, pagination, refetch, error } = useQuery(
    fetchFunction,
    {
      caseId,
      reportId,
      pagination: {
        page: INITIAL_PAGE,
        pageSize: DEFAULT_PAGE_SIZE
      }
    }
  );

  const handlePageChange = (newPage: number) =>
    refetch({
      caseId,
      reportId,
      pagination: {
        page: newPage,
        pageSize: DEFAULT_PAGE_SIZE
      }
    });

  const className = cn({
    [style.summary]: isSummary
  });

  if (error) {
    return <SummaryError title="CNAE" message={ERROR_MESSAGE.cnae} />;
  }

  const cardItems = cnaes.map((cnae: ReportCnae) => {
    return {
      label: handleNullValue(cnae.code, 'string', code => applyCodeMask(code)),
      value: cnae.description,
      tooltip: isSummary ? null : (
        <ProviderTooltip
          providers={cnae.field.providers}
          searchedByHomonym={cnae.field.searchedByHomonym}
        />
      )
    };
  });

  return (
    <CardInfo
      className={className}
      title="CNAE"
      tooltipText={providerStatus}
      isLoading={isLoading}
      items={cardItems}
      pagination={pagination}
      onCurrentPageChange={handlePageChange}
    />
  );
};

export default Cnaes;
