import React from 'react';

import { Stack, StackMargin, Badge } from 'ui';

import { Guarantee } from 'types';

import style from './ContractBadges.module.scss';

type Props = Pick<Guarantee, 'contracts'>;

const ContractBadges = ({ contracts }: Props) => (
  <div className={style.container}>
    {contracts.map(contract => (
      <Stack key={contract.number} marginTop={StackMargin.SMALL}>
        <Badge
          className={style.badge}
          key={contract.number}
          dataTestId={`badge-${contract.number}`}
        >
          {`CONTRATO ${contract.number}`}
        </Badge>
      </Stack>
    ))}
  </div>
);

export default ContractBadges;
