import { buildCitiesOptions } from 'domain/cities';
import React, { ReactElement, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik, getIn } from 'formik';
import { fetchCities, submitLocation } from 'api';
import {
  FormContainer,
  SelectField,
  InputField,
  Toggle,
  Button,
  openSuccessToast,
  openErrorToast
} from 'ui';
import { AutoCompleteField } from 'components';
import {
  ApiError,
  Location,
  OnSubmit,
  EntityType,
  LocationFormAttributes,
  LocationEntity,
  City
} from 'types';
import { stateOptions } from 'utils';
import * as ValidationSchema from 'utils/validationSchema';
import { useQueryCall } from 'hooks';

const initialValues: LocationFormAttributes = {
  status: '',
  state: '',
  cityId: '',
  entity: {
    type: 'individual',
    individual: {
      cpf: '',
      name: '',
      isAbroad: false
    },
    company: {
      cnpj: '',
      businessName: '',
      isAbroad: false
    }
  },
  comment: '',
  abroadCity: '',
  isAbroad: false,
  isFromReport: false
};

const validationSchema = Yup.object().shape({
  status: Yup.string().required('Campo obrigatório'),
  abroadCity: Yup.string().when('isAbroad', {
    is: true,
    then: Yup.string().required('Campo obrigatório'),
    otherwise: Yup.string()
      .notRequired()
      .nullable()
  }),
  cityId: Yup.string().when('isAbroad', {
    is: false,
    then: Yup.string().required('Campo obrigatório'),
    otherwise: Yup.string()
      .notRequired()
      .nullable()
  }),
  state: Yup.string().when('isAbroad', {
    is: false,
    then: Yup.string().required('Campo obrigatório'),
    otherwise: Yup.string()
      .notRequired()
      .nullable()
  }),
  entity: Yup.object().shape({
    type: Yup.string()
      .notRequired()
      .nullable(),
    individual: Yup.object().shape({
      cpf: ValidationSchema.cpf()
        .notRequired()
        .nullable(),
      name: Yup.string()
        .notRequired()
        .min(3, 'Precisa ter ao menos 3 caracteres')
        .max(255, 'Precisa ter menos de 256 caracteres')
        .nullable()
    }),
    company: Yup.object().shape({
      cnpj: ValidationSchema.cnpj()
        .notRequired()
        .nullable(),
      businessName: Yup.string()
        .notRequired()
        .min(3, 'Precisa ter ao menos 3 caracteres')
        .max(255, 'Precisa ter menos de 256 caracteres')
        .nullable()
    })
  }),
  comment: Yup.string()
    .notRequired()
    .nullable()
});

const status: Array<{ value: string; label: string }> = [
  { value: 'suspected', label: 'Suspeita' },
  { value: 'confirmed', label: 'Confirmada' }
];

const formatError = (errors?: ApiError) => {
  if (!errors) return {};

  return {
    entity: { individual: errors?.individual, company: errors?.company },
    ...errors
  };
};

const checkEmptyParams = (entity: LocationEntity): boolean => {
  if (entity?.type === EntityType.INDIVIDUAL) {
    return (
      !Boolean(entity?.individual.name) && !Boolean(entity?.individual.cpf)
    );
  }

  return (
    !Boolean(entity?.company.businessName) && !Boolean(entity?.company.cnpj)
  );
};

const sanitizeParams = (
  location: LocationFormAttributes
): LocationFormAttributes => {
  if (location.isAbroad) {
    return { ...location, cityId: '' };
  }

  return { ...location, abroadCity: null };
};

const formatInitialValues = (
  locationData: Location | undefined
): LocationFormAttributes => {
  if (!locationData) return initialValues;

  return {
    ...locationData,
    cityId: locationData.city?.id || '',
    state: locationData.city?.state || '',
    isAbroad: locationData.abroadCity ? true : false,
    comment: locationData.comment === null ? '' : locationData.comment,
    entity: {
      type: EntityType.INDIVIDUAL,
      ...locationData.entity,
      individual: {
        cpf: '',
        name: '',
        isAbroad: false,
        ...locationData.entity?.individual
      },
      company: {
        cnpj: '',
        businessName: '',
        isAbroad: false,
        ...locationData.entity?.company
      }
    }
  };
};

const isAbroadOptions: Array<{ value: string; label: string }> = [
  { value: 'false', label: 'Brasil' },
  { value: 'true', label: 'Exterior' }
];

type Props = {
  onCreate: (location: Location) => void;
  onUpdate: (location: Location) => void;
  locationData?: Location | undefined;
  caseId: string;
};

const Form = ({
  onCreate,
  onUpdate,
  locationData,
  caseId
}: Props): ReactElement => {
  const { data: cities, request: callCities } = useQueryCall<
    City[],
    { state: string }
  >(fetchCities);

  const onHandleSubmit: OnSubmit<LocationFormAttributes> = async (
    values,
    formikHelpers
  ) => {
    const emptyEntity = checkEmptyParams(values.entity);
    if (emptyEntity) {
      values = { ...values, entity: null };
    }

    try {
      const sanitizeValues = sanitizeParams(values);

      const response = await submitLocation({
        location: locationData,
        values: sanitizeValues,
        caseId
      });

      locationData ? onUpdate(response.data) : onCreate(response.data);

      openSuccessToast('Localização salva com sucesso!');
    } catch (error) {
      if (error.errors.caseCityEntity) {
        openErrorToast(
          'Localização já adicionada ao caso. Altere um dos campos: CPF/CNPJ, estado ou município'
        );
      } else {
        formikHelpers.setErrors(formatError(error.errors));
        openErrorToast('Houve um erro ao salvar a localização');
      }
    }
  };

  const formik = useFormik({
    initialValues: formatInitialValues(locationData)!,
    validationSchema,
    onSubmit: onHandleSubmit
  });

  const selectedState = formik?.values.state;

  useEffect(() => {
    if (selectedState) {
      callCities({ state: selectedState });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedState]);

  const handleBooleanToggle = (value: string, fieldName: string): void =>
    value === 'true'
      ? formik.setFieldValue(fieldName, true)
      : formik.setFieldValue(fieldName, false);

  return (
    <form className="location-form" data-testid="location-form">
      <FormContainer className="location-form-container col-2">
        <SelectField
          dataTestId="location-select-status"
          id="location-status"
          name="status"
          value={formik.values.status}
          error={formik.touched.status && getIn(formik.errors, 'status')}
          title="Status"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          options={status}
          disabled={locationData?.isFromReport}
        />

        <Toggle
          dataTestId="toggle-is-abroad"
          title="País da localização"
          options={isAbroadOptions}
          checked={formik.values.isAbroad.toString()}
          onChange={event =>
            handleBooleanToggle(event.target.value, 'isAbroad')
          }
          disabled={locationData?.isFromReport}
        />

        <SelectField
          dataTestId="location-select-state"
          id="location-state"
          name="state"
          value={formik.values.state}
          error={formik.touched.state && getIn(formik.errors, 'state')}
          title="Estado"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          options={stateOptions}
          disabled={locationData?.isFromReport || formik.values.isAbroad}
        />

        {formik.values.isAbroad ? (
          <InputField
            id="location-abroad-city"
            name="abroadCity"
            type="text"
            value={formik.values.abroadCity}
            error={
              formik.touched.abroadCity && getIn(formik.errors, 'abroadCity')
            }
            title="Município"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={locationData?.isFromReport}
          />
        ) : (
          <SelectField
            dataTestId="location-select-city"
            name="cityId"
            id="location-city"
            title="Município"
            value={formik.values.cityId}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={!selectedState || locationData?.isFromReport}
            options={buildCitiesOptions(cities)}
            error={
              getIn(formik.touched, 'cityId') && getIn(formik.errors, 'cityId')
            }
          />
        )}

        <Toggle
          title="Tipo de pessoa"
          options={[
            { value: EntityType.INDIVIDUAL, label: 'Física' },
            { value: EntityType.COMPANY, label: 'Jurídica' }
          ]}
          onChange={event =>
            formik.setFieldValue('entity.type', event.target.value)
          }
          checked={formik.values.entity?.type}
          disabled={locationData?.isFromReport}
        />
        {formik.values.entity?.type === EntityType.INDIVIDUAL ? (
          <React.Fragment>
            <Toggle
              name={'entity.individual.isAbroad'}
              title="País da pessoa"
              options={isAbroadOptions}
              checked={formik.values.entity.individual.isAbroad.toString()}
              onChange={event =>
                handleBooleanToggle(
                  event.target.value,
                  'entity.individual.isAbroad'
                )
              }
              disabled={locationData?.isFromReport}
            />
            <AutoCompleteField
              caseId={caseId}
              isAbroad={formik.values.entity.individual.isAbroad}
              entityType={EntityType.INDIVIDUAL}
              onSelect={selection => {
                formik.setFieldValue('entity.individual.cpf', selection!.value);
                formik.setFieldValue(
                  'entity.individual.name',
                  selection!.complement
                );
              }}
              dataToItem={data => ({
                value: data.individual.cpf,
                complement: data.individual.name
              })}
              dataTestId="location-input-entity-cpf"
              id="location-entity-cpf"
              name="entity.individual.cpf"
              type="cpf"
              value={formik.values.entity.individual.cpf}
              error={getIn(formik.errors, 'entity.individual.cpf')}
              title="CPF"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={locationData?.isFromReport}
            />

            <AutoCompleteField
              caseId={caseId}
              isAbroad={formik.values.entity.individual.isAbroad}
              entityType={EntityType.INDIVIDUAL}
              onSelect={selection => {
                formik.setFieldValue(
                  'entity.individual.name',
                  selection!.value
                );
                formik.setFieldValue(
                  'entity.individual.cpf',
                  selection!.complement
                );
              }}
              dataToItem={data => ({
                value: data.individual.name,
                complement: data.individual.cpf
              })}
              dataTestId="location-input-entity-name"
              id="location-entity-name"
              name="entity.individual.name"
              type="text"
              value={formik.values.entity.individual.name}
              error={getIn(formik.errors, 'entity.individual.name')}
              title="Nome completo"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={locationData?.isFromReport}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Toggle
              name={'entity.company.isAbroad'}
              title="País da pessoa"
              options={isAbroadOptions}
              checked={formik.values.entity?.company.isAbroad.toString()}
              onChange={event =>
                handleBooleanToggle(
                  event.target.value,
                  'entity.company.isAbroad'
                )
              }
              disabled={locationData?.isFromReport}
            />
            <AutoCompleteField
              caseId={caseId}
              isAbroad={formik.values.entity?.company.isAbroad}
              entityType={EntityType.COMPANY}
              onSelect={selection => {
                formik.setFieldValue('entity.company.cnpj', selection!.value);
                formik.setFieldValue(
                  'entity.company.businessName',
                  selection!.complement
                );
              }}
              dataToItem={data => ({
                value: data.company.cnpj,
                complement: data.company.businessName
              })}
              dataTestId="location-input-entity-cnpj"
              id="location-entity-cnpj"
              name="entity.company.cnpj"
              type="cnpj"
              value={formik.values.entity?.company.cnpj}
              error={getIn(formik.errors, 'entity.company.cnpj')}
              title="CNPJ"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={locationData?.isFromReport}
            />

            <AutoCompleteField
              caseId={caseId}
              isAbroad={formik.values.entity?.company.isAbroad}
              entityType={EntityType.COMPANY}
              onSelect={selection => {
                formik.setFieldValue(
                  'entity.company.businessName',
                  selection!.value
                );
                formik.setFieldValue(
                  'entity.company.cnpj',
                  selection!.complement
                );
              }}
              dataToItem={data => ({
                value: data.company.businessName,
                complement: data.company.cnpj
              })}
              dataTestId="location-input-entity-businessName"
              id="location-entity-businessName"
              name="entity.company.businessName"
              type="text"
              value={formik.values.entity?.company.businessName}
              error={getIn(formik.errors, 'entity.company.businessName')}
              title="Razão social"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={locationData?.isFromReport}
            />
          </React.Fragment>
        )}
      </FormContainer>
      <FormContainer>
        <InputField
          dataTestId="location-input-comment"
          id="location-comment"
          name="comment"
          type="text"
          limit={100}
          showCounter
          value={formik.values.comment}
          error={getIn(formik.errors, 'comment')}
          title="Comentário"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Button
          onClick={formik.handleSubmit}
          highlight
          centered
          dataTestId="location-submit-button"
          type="submit"
        >
          {locationData ? 'Salvar' : 'Adicionar'}
        </Button>
      </FormContainer>
    </form>
  );
};

export default Form;
