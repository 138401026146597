import * as Guarantees from 'domain/guarantees';
import React, { Fragment, useEffect, useContext } from 'react';
import { getIn, FormikProps } from 'formik';
import { AutoCompleteField, CaseContext } from 'components';
import { can, Action } from 'api';
import { FormContainer, InputField, SelectField, Toggle, TextArea } from 'ui';
import {
  GuaranteeFormAttributes,
  EntityType,
  GuaranteeModality,
  GuaranteeType
} from 'types';

import './Form.scss';

import { useFormDirty } from '../../FormDirtyContext';

type Props = {
  id: string;

  caseId: string;
  formik: FormikProps<GuaranteeFormAttributes>;
  dirtyId: string;
};

const Form = ({ id, caseId, formik, dirtyId }: Props) => {
  const { setIsGuaranteeFormDirty } = useFormDirty();

  const { caseData } = useContext(CaseContext);

  useEffect(() => {
    setIsGuaranteeFormDirty(formik.dirty, dirtyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.dirty]);

  function idFor(fieldName: string): string {
    return `${id}-${fieldName}`;
  }

  const negotiatorId = caseData?.negotiator?.id;

  const canUser = {
    addGuarantee: can(Action.CASOS_GARANTIAS_INCLUIR, negotiatorId),
    editGuarantee: can(Action.CASOS_GARANTIAS_EDITAR, negotiatorId)
  };

  const isDisabled = formik.initialValues?.instrumentNumber
    ? !canUser.editGuarantee
    : !canUser.addGuarantee;

  return (
    <form id={id} data-testid="guarantee-card-form" className="guarantee-form">
      <FormContainer
        className="col-4 -last-double"
        well
        noPadding
        title="Garantidor"
      >
        <Toggle
          title="Tipo de pessoa"
          options={[
            { value: 'individual', label: 'Física' },
            { value: 'company', label: 'Jurídica' }
          ]}
          onChange={event =>
            formik.setFieldValue('guarantor.type', event.target.value)
          }
          checked={formik.values.guarantor.type}
          disabled={isDisabled}
        />
        {formik.values.guarantor.type === 'individual' ? (
          <Fragment>
            <Toggle
              name="guarantor.individual.isAbroad"
              title="País"
              options={[
                { value: 'false', label: 'Brasil' },
                { value: 'true', label: 'Exterior' }
              ]}
              checked={formik.values.guarantor.individual.isAbroad.toString()}
              onChange={event =>
                formik.setFieldValue(
                  'guarantor.individual.isAbroad',
                  event.target.value
                )
              }
              disabled={isDisabled}
            />
            <AutoCompleteField
              caseId={caseId}
              isAbroad={formik.values.guarantor.individual.isAbroad}
              entityType={EntityType.INDIVIDUAL}
              onSelect={selection => {
                formik.setFieldValue(
                  'guarantor.individual.cpf',
                  selection!.value
                );
                formik.setFieldValue(
                  'guarantor.individual.name',
                  selection!.complement
                );
              }}
              dataToItem={data => ({
                value: data.individual.cpf,
                complement: data.individual.name
              })}
              dataTestId="input-guarantor-individual-cpf"
              id={idFor('guarantor-individual-cpf')}
              name="guarantor.individual.cpf"
              type={'cpf'}
              value={formik.values.guarantor.individual.cpf}
              title="CPF"
              error={
                formik.touched?.guarantor?.individual?.cpf &&
                getIn(formik.errors, 'guarantor.individual.cpf')
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={isDisabled}
            />
            <AutoCompleteField
              caseId={caseId}
              isAbroad={formik.values.guarantor.individual.isAbroad}
              entityType={EntityType.INDIVIDUAL}
              onSelect={selection => {
                formik.setFieldValue(
                  'guarantor.individual.name',
                  selection!.value
                );
                formik.setFieldValue(
                  'guarantor.individual.cpf',
                  selection!.complement
                );
              }}
              dataToItem={data => ({
                value: data.individual.name,
                complement: data.individual.cpf
              })}
              dataTestId="input-guarantor-individual-name"
              id={idFor('guarantor-individual-name')}
              name="guarantor.individual.name"
              type="text"
              value={formik.values.guarantor.individual.name}
              title={'Nome completo'}
              error={
                formik.touched?.guarantor?.individual?.name &&
                getIn(formik.errors, 'guarantor.individual.name')
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={isDisabled}
            />
          </Fragment>
        ) : (
          <Fragment>
            <Toggle
              name="guarantor.company.isAbroad"
              title="País"
              options={[
                { value: 'false', label: 'Brasil' },
                { value: 'true', label: 'Exterior' }
              ]}
              checked={formik.values.guarantor.company.isAbroad.toString()}
              onChange={event => {
                formik.setFieldValue(
                  'guarantor.company.isAbroad',
                  event.target.value
                );
              }}
              disabled={isDisabled}
            />
            <AutoCompleteField
              caseId={caseId}
              isAbroad={formik.values.guarantor.company.isAbroad}
              entityType={EntityType.COMPANY}
              onSelect={selection => {
                formik.setFieldValue(
                  'guarantor.company.cnpj',
                  selection!.value
                );
                formik.setFieldValue(
                  'guarantor.company.businessName',
                  selection!.complement
                );
              }}
              dataToItem={data => ({
                value: data.company.cnpj,
                complement: data.company.businessName
              })}
              dataTestId="input-guarantor-company-cnpj"
              id={idFor('guarantor-company-cnpj')}
              name="guarantor.company.cnpj"
              type={'cnpj'}
              value={formik.values.guarantor.company.cnpj}
              title="CNPJ"
              error={
                formik.touched?.guarantor?.company?.cnpj &&
                getIn(formik.errors, 'guarantor.company.cnpj')
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={isDisabled}
            />
            <AutoCompleteField
              caseId={caseId}
              isAbroad={formik.values.guarantor.company.isAbroad}
              entityType={EntityType.COMPANY}
              onSelect={selection => {
                formik.setFieldValue(
                  'guarantor.company.businessName',
                  selection!.value
                );
                formik.setFieldValue(
                  'guarantor.company.cnpj',
                  selection!.complement
                );
              }}
              dataToItem={data => ({
                value: data.company.businessName,
                complement: data.company.cnpj
              })}
              dataTestId="input-guarantor-company-businessname"
              id={idFor('guarantor-company-businessname')}
              name="guarantor.company.businessName"
              type="text"
              value={formik.values.guarantor.company.businessName}
              title="Razão social"
              error={
                formik.touched?.guarantor?.company?.businessName &&
                getIn(formik.errors, 'guarantor.company.businessName')
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={isDisabled}
            />
          </Fragment>
        )}
      </FormContainer>
      <FormContainer className="col-3">
        <InputField
          dataTestId="input-instrumentNumber"
          id={idFor('instrumentNumber')}
          name="instrumentNumber"
          type="text"
          value={formik.values.instrumentNumber}
          title="Nº instrumento de garantia"
          error={
            formik.touched.instrumentNumber &&
            getIn(formik.errors, 'instrumentNumber')
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={isDisabled}
        />

        <SelectField
          dataTestId="select-guarantee-modality"
          id={idFor('modality')}
          name="modality"
          value={formik.values.modality}
          title="Forma de garantia"
          error={formik.touched.modality && getIn(formik.errors, 'modality')}
          onChange={event => {
            formik.handleChange(event);
            if (event.target.value === GuaranteeModality.CLEAN) {
              formik.setFieldValue('object', '');
              formik.setFieldTouched('object', false);
            }
          }}
          onBlur={formik.handleBlur}
          options={Guarantees.modalityOptions()}
          disabled={isDisabled}
        />
        <SelectField
          dataTestId="select-guarantee-object"
          id={idFor('object')}
          name="object"
          value={formik.values.object}
          title="Objeto de garantia"
          error={formik.touched.object && getIn(formik.errors, 'object')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          options={Guarantees.objectOptions()}
          disabled={
            isDisabled || formik.values.modality === GuaranteeModality.CLEAN
          }
        />

        <InputField
          dataTestId="input-requiredCoverage"
          id={idFor('requiredCoverage')}
          name="requiredCoverage"
          type="percent"
          value={formik.values.requiredCoverage.toString()}
          title="Cobertura de garantia exigida (%)"
          error={
            formik.touched.requiredCoverage &&
            getIn(formik.errors, 'requiredCoverage')
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={isDisabled}
        />
        <Toggle
          title="Compartilhamento com sindicato"
          options={[
            { value: GuaranteeType.INDIVIDUAL, label: 'Individual' },
            { value: GuaranteeType.COMPARTILHADA, label: 'Compartilhada' }
          ]}
          onChange={event => formik.setFieldValue('type', event.target.value)}
          checked={formik.values.type}
          disabled={isDisabled}
        />

        <InputField
          dataTestId="input-sharedGuaranteeParticipation"
          id={idFor('sharedGuaranteeParticipation')}
          name="sharedGuaranteeParticipation"
          type="percent"
          value={formik.values.sharedGuaranteeParticipation}
          title="Participação na garantia compartilhada (%)"
          error={
            formik.touched.sharedGuaranteeParticipation &&
            getIn(formik.errors, 'sharedGuaranteeParticipation')
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={isDisabled}
        />
      </FormContainer>
      <FormContainer>
        <TextArea
          dataTestId="input-comments"
          id={idFor('comments')}
          name="comments"
          value={formik.values.comments}
          title="Comentários"
          error={formik.touched.comments && getIn(formik.errors, 'comments')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={isDisabled}
        />
      </FormContainer>
    </form>
  );
};

export default Form;
