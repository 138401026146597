import React, { ChangeEvent, ReactElement, ReactNode } from 'react';

import { Checkbox, Link } from 'ui';
import cn from 'classnames';

import style from './ListItem.module.scss';

type Props = {
  children?: ReactNode;
  title: string;
  text?: string;
  actions?: ReactElement;
  onCheckboxClick?: (event: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  isCheckBoxDisabled?: boolean;
  id?: string;
  className?: string;
  titleUrl?: string;
};

const ListItem = ({
  title,
  text,
  actions,
  onCheckboxClick,
  checked,
  id,
  isCheckBoxDisabled = false,
  children,
  className,
  titleUrl
}: Props) => {
  return (
    <li className={cn(style.row, className)}>
      <div className={style.listWrapper}>
        {onCheckboxClick && (
          <Checkbox
            name={`checkbox[${id}].id`}
            id={id!}
            onChange={onCheckboxClick}
            checked={checked}
            disabled={isCheckBoxDisabled}
          />
        )}

        {titleUrl ? (
          <Link href={titleUrl} external>
            <h3 className={style.title} data-testid="list-item-title">
              {title}
            </h3>
          </Link>
        ) : (
          <h3 className={style.title} data-testid="list-item-title">
            {title}
          </h3>
        )}
        <span className={style.text}>{text}</span>
        {children}
      </div>

      {actions && <div className={style.actions}>{actions}</div>}
    </li>
  );
};

export default ListItem;
