import objectLabel, {
  guaranteeTotalValue,
  modalityLabel
} from 'domain/guarantees';
import { entityDocument, entityName } from 'domain/entity';
import React from 'react';

import { fetchCaseGuarantees } from 'api';

import { useQuery, useToggles } from 'hooks';
import { applyMask, handleNullValue } from 'utils';

import { ExecutionStatus, SummaryError } from 'components';
import { Stack, StackMargin, Card, RadioSwitch } from 'ui';

import { Guarantee } from 'types';

import AssetsSummaryTable from '../AssetsSummaryTable';
import SummaryLoader from '../SummaryLoader';

import ContractBadges from './ContractBadges/ContractBadges';
import style from './Guarantees.module.scss';

type Props = {
  caseId: string;
};

const Guarantees = ({ caseId }: Props) => {
  const { data: guarantees = [], error, isLoading } = useQuery<
    Guarantee[],
    Pick<Props, 'caseId'>
  >(fetchCaseGuarantees, {
    caseId
  });

  const { toggle, isOpen } = useToggles<'guarenteesFullView'>({
    guarenteesFullView: false
  });

  if (isLoading) {
    return <SummaryLoader title="Garantias" />;
  }

  if (error) {
    return (
      <SummaryError
        title="Garantias"
        message="Erro ao listar garantias, tente novamente."
      />
    );
  }

  return (
    <Stack className={style.guaranteeContainer} marginTop={StackMargin.XLARGE}>
      <div className={style.titleWrapper}>
        <h3 className={style.title}>{`Garantias (${guarantees.length})`}</h3>

        <div className={style.fullView}>
          <RadioSwitch
            label="Detalhamento dos ativos em garantia"
            onChange={toggle('guarenteesFullView')}
            active={isOpen.guarenteesFullView}
            inputId="guarantee-full-view"
          />
        </div>
      </div>

      <Stack marginTop={StackMargin.LARGE}>
        {guarantees.map(guarantee => (
          <Stack key={guarantee.id} marginTop={StackMargin.MEDIUM}>
            <Card
              className={style.card}
              medium
              dataTestId={`card-${guarantee.id}`}
            >
              <div className={style.header}>
                <div className={style.instrumentNumberContent}>
                  <span className={style.number}>
                    {guarantee.instrumentNumber}
                  </span>
                  <span>{`(${modalityLabel(guarantee.modality)}, ${objectLabel(
                    guarantee.object
                  )})`}</span>
                </div>
                <span className={style.amountContent}>
                  {`Valor da garantia: ${handleNullValue(
                    guaranteeTotalValue(guarantee).toString(),
                    'number',
                    amount => applyMask('currency', amount)
                  )}`}
                </span>

                <div className={style.percentContent}>
                  {guarantee.requiredCoverage && (
                    <span>
                      Cobertura de garantia{' '}
                      {applyMask(
                        'percent',
                        guarantee.requiredCoverage.toString()
                      )}
                      %
                    </span>
                  )}

                  {guarantee.sharedGuaranteeParticipation && (
                    <span className={style.sharedGuarantee}>
                      / Compartilhada{' '}
                      {applyMask(
                        'percent',
                        guarantee.sharedGuaranteeParticipation.toString()
                      )}
                      %
                    </span>
                  )}
                </div>
              </div>

              {!!guarantee.executions.length && (
                <Stack marginTop={StackMargin.MEDIUM}>
                  {guarantee.executions.map(execution => (
                    <ExecutionStatus
                      caseId={caseId}
                      execution={execution}
                      key={`${guarantee.id}-${execution.id}`}
                    />
                  ))}
                </Stack>
              )}

              <Stack marginTop={StackMargin.MEDIUM}>
                <p className={style.description}>
                  Garantidor: {entityName(guarantee.guarantor)} (
                  {entityDocument(guarantee.guarantor)})
                </p>
              </Stack>

              <Stack marginTop={StackMargin.MEDIUM}>
                <p className={style.description}>
                  Essa garantia está presente nos seguintes contratos:
                </p>
                <ContractBadges contracts={guarantee.contracts} />
              </Stack>

              {isOpen.guarenteesFullView && (
                <>
                  <Stack marginTop={StackMargin.MEDIUM}>
                    <p className={style.description}>Ativos na garantia:</p>
                  </Stack>
                  <Card
                    small
                    className={style.card}
                    dataTestId={`card-guarantee-${guarantee.id}-assets`}
                  >
                    <AssetsSummaryTable
                      assets={guarantee.assets}
                      isLoading={isLoading}
                      shouldNotRenderGroupChildrenStyle
                    />
                  </Card>
                </>
              )}
            </Card>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default Guarantees;
