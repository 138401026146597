import { FieldHistory } from 'types';

export const FORMER_VALUE_CONTENT_PREFIX =
  'Esse campo foi atualizado,\no valor antigo é:';

type FormerValues<T extends string> = {
  [key in T]: string | number;
};

export const getFormerValues = <T extends string>(
  fieldNames: T[],
  history: FieldHistory | null
) => {
  if (!history) return null;

  return fieldNames.reduce(
    (formerValues, fieldName) =>
      Object.assign(formerValues, {
        [fieldName]: history.updatedFields[fieldName]?.formerValue
      }),
    {} as FormerValues<T>
  );
};

export const getFormerValueContent = (
  fieldName: string,
  history: FieldHistory | null,
  formatter?: (value: string) => string
) => {
  if (!history) return null;

  const formerValue = history.updatedFields[fieldName]?.formerValue;

  if (!formerValue) return null;

  if (formatter) {
    return `${FORMER_VALUE_CONTENT_PREFIX} ${formatter(
      formerValue.toString()
    )}`;
  }

  return `${FORMER_VALUE_CONTENT_PREFIX} ${formerValue}`;
};
