import React from 'react';

import { fetchActivitiesGroupedByContract } from 'api';
import { useQuery } from 'hooks';

import { EmptyState, Loader, Toast } from 'ui';
import { ToastType } from 'ui/Toast/Toast';

import { CreditContract } from 'types';

import ActivitiesPerContract from './PerContract/ActivitiesPerContract';

type Props = { caseId: string; contract?: null | CreditContract };

const Activities = ({ caseId }: Props) => {
  const { data: contracts = [], isLoading, error } = useQuery<
    CreditContract[],
    Pick<Props, 'caseId'>
  >(fetchActivitiesGroupedByContract, { caseId });

  if (error) {
    return (
      <Toast
        type={ToastType.ERROR}
        message="Houve um erro ao listar as atividades, tente novamente."
      />
    );
  }

  if (isLoading) return <Loader width={5} />;

  if (!contracts.length && !isLoading) {
    return <EmptyState text="Não há atividades neste caso" />;
  }

  return (
    <>
      {contracts.map(contract => (
        <ActivitiesPerContract
          key={contract.id}
          contract={contract}
          caseId={caseId}
        />
      ))}
    </>
  );
};

export default Activities;
