import {
  CaseEntity,
  EntityAttributes,
  EntityFormAttributes,
  EntityWithRelations,
  EntityWithReports
} from 'types';

import Client from './client';

function createEntity(caseId: string, entityData: EntityFormAttributes) {
  return Client.post<EntityFormAttributes, CaseEntity>(
    `cases/${caseId}/entities`,
    entityData
  );
}

function deleteEntity(caseId: string, id: string) {
  return Client.delete(`cases/${caseId}/entities/${id}`);
}

function fetchEntitiesReports({ caseId }: { caseId: string }) {
  return Client.get<EntityWithReports[]>(
    `cases/${caseId}/entities/with_reports`
  );
}

function updateEntityDescription({
  caseId,
  entityId,
  data
}: {
  caseId: string;
  entityId: string;
  data: { description: string };
}) {
  return Client.patch<{ description: string }, EntityAttributes>(
    `/cases/${caseId}/entities/${entityId}`,
    data
  );
}

function fetchEntityRelationships({
  caseId,
  entityId
}: {
  caseId: string;
  entityId: string;
}) {
  return Client.get<EntityWithRelations>(
    `cases/${caseId}/entities/${entityId}`
  );
}

function fetchAllCaseEntities({ caseId }: { caseId: string }) {
  return Client.get<CaseEntity[]>(`cases/${caseId}/entities/all`);
}

export {
  createEntity,
  deleteEntity,
  updateEntityDescription,
  fetchEntityRelationships,
  fetchEntitiesReports,
  fetchAllCaseEntities
};
