import { createProfile } from 'api';
import { Formik, getIn } from 'formik';
import { useToggles } from 'hooks';
import * as Yup from 'yup';
import React from 'react';
import { Toggle } from 'ui';
import { OnSubmit, ProfileAttributes, ProfileRolesType } from 'types';
import {
  Button,
  Checkbox,
  FormContainer,
  InputField,
  Modal,
  openErrorToast,
  openSuccessToast,
  Stack,
  StackMargin
} from 'ui';

import style from './NewProfileModalButton.module.scss';

type Props = {
  refetchProfiles: () => void;
};

const NewProfileModalButton = ({ refetchProfiles }: Props) => {
  const { isOpen, toggle } = useToggles<'newProfileModal'>({
    newProfileModal: false
  });

  const handleSubmit: OnSubmit<ProfileAttributes> = async (
    profileAttributes,
    formikHelpers
  ) => {
    try {
      await createProfile({
        ...profileAttributes,
        name: `YIELD_${profileAttributes.name.toUpperCase()}`
      });

      refetchProfiles();
      openSuccessToast('Perfil adicionado com sucesso!');
    } catch (error) {
      formikHelpers.setErrors(error.errors);
      openErrorToast('Não foi possível salvar o perfil.');
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Campo obrigatório')
      .test(
        'YIELD_',
        'Não deve começar com YIELD_',
        (value: string) => !!value && !value.startsWith('YIELD_')
      )
  });

  return (
    <>
      <Button small highlight onClick={toggle('newProfileModal')}>
        Novo perfil
      </Button>

      {isOpen.newProfileModal && (
        <Modal
          title="Novo perfil"
          isOpen={isOpen.newProfileModal}
          onClose={toggle('newProfileModal')}
          className={style.modal}
          small
        >
          <Formik<ProfileAttributes>
            initialValues={{
              name: '',
              hasSpecialPermissions: false,
              role: ProfileRolesType.DEFAULT
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {formik => (
              <form onSubmit={formik.handleSubmit}>
                <FormContainer>
                  <Stack>
                    <label htmlFor="name" className={style.nameInputLabel}>
                      Nome do perfil
                    </label>
                    <Stack
                      marginTop={StackMargin.SMALL}
                      className={style.nameInputContainer}
                    >
                      <span className={style.nameSpan}>YIELD_</span>
                      <InputField
                        id="name"
                        name="name"
                        type="text"
                        value={formik.values.name.toUpperCase()}
                        error={
                          formik.touched.name && getIn(formik.errors, 'name')
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="NOME"
                        className={style.nameInput}
                      />
                    </Stack>
                  </Stack>

                  <Toggle
                    title="Tipo de perfil"
                    options={[
                      { value: ProfileRolesType.DEFAULT, label: 'PADRÃO' },
                      {
                        value: ProfileRolesType.INTERNAL_LAWYER,
                        label: 'ADV. INTERNO'
                      },
                      {
                        value: ProfileRolesType.NEGOTIATOR,
                        label: 'NEGOCIADOR'
                      }
                    ]}
                    onChange={event => {
                      formik.setFieldValue('role', event.target.value);
                      formik.setFieldValue('hasSpecialPermissions', false);
                    }}
                    checked={formik.values.role}
                    tooltipText={
                      'Padrão: Perfil não relacionado ao caso\nAdv. Interno: Perfil relacionado ao caso\nNegociador: Perfil relacionado ao caso, tem opção de incluir o menu "Casos do negociador"'
                    }
                  />
                  {formik.values.role === ProfileRolesType.NEGOTIATOR && (
                    <Checkbox
                      id="hasSpecialPermissions"
                      name="hasSpecialPermissions"
                      label='Incluir "Casos do negociador"'
                      onChange={() => {
                        formik.setFieldValue(
                          'hasSpecialPermissions',
                          !formik.values.hasSpecialPermissions
                        );
                      }}
                      checked={
                        formik.values.hasSpecialPermissions &&
                        formik.values.role === ProfileRolesType.NEGOTIATOR
                      }
                    />
                  )}

                  <Button
                    highlight
                    outline
                    centered
                    disabled={!formik.isValid || !formik.dirty}
                    type="submit"
                  >
                    Adicionar
                  </Button>
                </FormContainer>
              </form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};

export default NewProfileModalButton;
