import { contractBadgeColor } from 'domain/contracts';
import * as Cases from 'domain/cases';
import cn from 'classnames';
import React from 'react';
import { DateTime } from 'luxon';

import { ColoredBadge } from 'components';
import { BadgeColor } from 'components/ColoredBadge/ColoredBadge';
import { Table, Checkbox } from 'ui';

import { daysSince, pluralizeByDays, handleNullValue } from 'utils';

import { Case, CaseType } from 'types';

import style from './CaseItem.module.scss';

type Props = {
  currentCase: Case;
  onClick: (clickedCase: Case) => void;
  className?: string;
  selected: boolean;
  onSelectCase: (caseId: string) => void;
  secondaryRow?: (_: Case) => React.ReactElement;
};

const badgeMetadata = (givenCase: Case) => {
  const { type } = givenCase;

  const overdueDateByType = {
    [CaseType.ATIVOS_ESPECIAIS]: givenCase.firstOverdueDate,
    [CaseType.PEAC]: givenCase.contracts?.[0]?.firstOverduePaymentDate,
    [CaseType.SALES_FINANCE]: givenCase.firstOverdueDate
  };

  const biggestOverdueDate = overdueDateByType[type] ?? '';

  if (!biggestOverdueDate.length) {
    return { color: BadgeColor.DEFAULT, days: '0 dias' };
  }

  const color =
    CaseType.ATIVOS_ESPECIAIS === type
      ? BadgeColor.DEFAULT
      : contractBadgeColor(biggestOverdueDate);

  const now = DateTime.local().toISO();

  return {
    color,
    days: pluralizeByDays(daysSince(biggestOverdueDate, now))
  };
};

const CaseItem = ({
  currentCase,
  onClick,
  className,
  selected,
  onSelectCase,
  secondaryRow
}: Props) => {
  const handleClick = () => {
    onClick(currentCase);
  };

  const lastChangedAt = DateTime.fromISO(currentCase.lastChangedAt)
    .setLocale('pt-BR')
    .toRelative();

  const { days, color } = badgeMetadata(currentCase);

  return (
    <>
      <Table.Row clickable>
        <Table.Cell className={cn([className, style.checkbox])}>
          <Checkbox
            name={`case[${currentCase.id}].id`}
            id={currentCase.id}
            onChange={() => onSelectCase(currentCase.id)}
            checked={selected}
          />
        </Table.Cell>
        <Table.Cell onClick={handleClick} className={className}>
          {currentCase.name}
        </Table.Cell>
        <Table.Cell onClick={handleClick} className={className}>
          <span>
            {handleNullValue(currentCase.internalLawyer?.name, 'string')}
          </span>
        </Table.Cell>
        <Table.Cell onClick={handleClick} className={className}>
          <span>{currentCase.negotiator.name}</span>
        </Table.Cell>
        <Table.Cell onClick={handleClick} className={className}>
          {Cases.statusLabel(currentCase.status)}
        </Table.Cell>
        <Table.Cell onClick={handleClick} className={className}>
          {lastChangedAt || 'Muito tempo atrás'}
        </Table.Cell>
        <Table.Cell onClick={handleClick} className={className}>
          <ColoredBadge type={color} title={`${days} de atraso`} />
        </Table.Cell>
      </Table.Row>
      {secondaryRow && secondaryRow(currentCase)}
    </>
  );
};

export default CaseItem;
