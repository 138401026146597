import React, { ReactNode, useState, useCallback } from 'react';

import { RelatedInstagramProfilePublicationsModal } from 'components';
import { useLiveInstagramProfile } from 'hooks';
import {
  ApiPagination,
  AggregatedMentionedInstagramProfile,
  InstagramProfile
} from 'types';
import { Accordion, Card, DynamicTable, Link, TablePositionModifier } from 'ui';

import PublicationsLink from '../PublicationsLink';
// import MoreInfo from '../MoreInfo';
import Actions from '../Actions';

import style from './RelatedInstagramProfilesTable.module.scss';

type Props = {
  caseId: string;
  instagramProfileId?: string;
  relatedInstagramProfiles?: AggregatedMentionedInstagramProfile[];
  paginationParams?: ApiPagination;
  onCurrentPageChange: (page: number) => Promise<void>;
  onItemsPerPageChange: (pageSize: number) => Promise<void>;
  isLoading?: boolean;
  canBookmarkPublication?: boolean;
  refetch: (pageNumber: number, pageSize: number) => Promise<void>;
  setRelatedProfilesData: React.Dispatch<
    React.SetStateAction<AggregatedMentionedInstagramProfile[] | undefined>
  >;
  reportId: string;
};

const DEFAULT_PAGE_SIZE = 6;
const FIRST_PAGE = 1;

const RelatedInstagramProfilesTable = ({
  caseId,
  instagramProfileId,
  relatedInstagramProfiles,
  paginationParams,
  onCurrentPageChange,
  onItemsPerPageChange,
  isLoading,
  canBookmarkPublication = true,
  refetch,
  setRelatedProfilesData,
  reportId
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState<{
    title: ReactNode;
    aggregatedProfileId: string;
  }>();

  const pageNumber = paginationParams?.number ?? FIRST_PAGE;
  const pageSize = paginationParams?.size ?? DEFAULT_PAGE_SIZE;

  const onMessage = useCallback(
    () => refetch(pageNumber, pageSize),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageNumber, pageSize, instagramProfileId, reportId]
  );

  useLiveInstagramProfile({
    caseId,
    onMessage
  });

  const openModal = (instagramProfile: InstagramProfile) => {
    const title = (
      <>
        Postagens com{' '}
        <span className={style.titleBold}>@{instagramProfile.socialName}</span>
      </>
    );
    setModalData({
      title,
      aggregatedProfileId: instagramProfile.id
    });
    setIsModalOpen(true);
  };

  const setInstagramProfile = (instagramProfile: InstagramProfile) => {
    const relatedProfiles = relatedInstagramProfiles?.map(profile => {
      if (profile.instagramProfile.id === instagramProfile.id) {
        return {
          ...profile,
          instagramProfile: instagramProfile
        };
      }

      return profile;
    });

    setRelatedProfilesData(relatedProfiles);
  };

  const dynamicTablePagination = paginationParams && {
    currentPage: paginationParams.number,
    itemsPerPage: paginationParams.size,
    hidePagination: paginationParams.totalEntries <= DEFAULT_PAGE_SIZE,
    totalEntries: paginationParams.totalEntries,
    totalPages: paginationParams.totalPages,
    onCurrentPageChange,
    onItemsPerPageChange
  };

  const columns = [
    {
      name: 'Usuário',
      columnKey: 'socialName',
      render: (item: AggregatedMentionedInstagramProfile) => (
        <Link highlight underline external href={item.instagramProfile.url}>
          @{item.instagramProfile.socialName}
        </Link>
      )
    },
    {
      name: 'Postagem(s) em que aparece',
      columnKey: 'publications',
      render: (item: AggregatedMentionedInstagramProfile) => (
        <PublicationsLink
          caseId={caseId}
          reportId={reportId}
          publicationsCount={item.mentionsCount}
          lastMention={item.lastMention.instagramPublication}
          onClick={() => openModal(item.instagramProfile)}
        />
      )
    },
    // {
    //   name: 'Mais informações do usuário',
    //   columnKey: 'more-info',
    //   render: (profile: AggregatedMentionedInstagramProfile) => (
    //     <MoreInfo
    //       caseId={caseId}
    //       profile={profile.instagramProfile}
    //       setInstagramProfile={setInstagramProfile}
    //     />
    //   )
    // },
    {
      positionModifier: TablePositionModifier.RIGHT,
      hasActions: true,
      name: '',
      columnKey: 'actions',
      render: (profile: AggregatedMentionedInstagramProfile) => (
        <Actions
          caseId={caseId}
          profile={profile.instagramProfile}
          setInstagramProfile={setInstagramProfile}
          reportId={reportId}
        />
      )
    }
  ];

  const rowKey = (item: AggregatedMentionedInstagramProfile) =>
    item.instagramProfile.id;

  return (
    <>
      <Accordion
        title="Perfis de Instagram relacionados"
        badgeContent={paginationParams?.totalEntries}
      >
        <Card small>
          {relatedInstagramProfiles && dynamicTablePagination && (
            <DynamicTable
              columns={columns}
              data={relatedInstagramProfiles}
              rowKey={rowKey}
              pagination={dynamicTablePagination}
              noAutoPaginate
              isLoading={isLoading}
            />
          )}
        </Card>
      </Accordion>

      {modalData && isModalOpen && instagramProfileId && (
        <RelatedInstagramProfilePublicationsModal
          caseId={caseId}
          instagramProfileId={instagramProfileId}
          isOpen={isModalOpen}
          canBookmarkPublication={canBookmarkPublication}
          onClose={() => setIsModalOpen(false)}
          reportId={reportId}
          {...modalData}
        />
      )}
    </>
  );
};

export default RelatedInstagramProfilesTable;
