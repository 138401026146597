import {
  ReportAirplane,
  FetchReportTableProps,
  AddContentToCase,
  AssetAttributes
} from 'types';

import Client from './client';

export function fetchReportAirplanes({
  caseId,
  reportId,
  pagination
}: FetchReportTableProps) {
  return Client.get<ReportAirplane[]>(
    `cases/${caseId}/reports/${reportId}/contents/airplanes`,
    {
      params: {
        page: pagination?.page.toString(),
        pageSize: pagination?.pageSize.toString()
      }
    }
  );
}

export function fetchReportAirplanesSummary({
  caseId,
  reportId
}: FetchReportTableProps) {
  return Client.get<ReportAirplane[]>(
    `cases/${caseId}/reports/${reportId}/contents/airplanes/summary`
  );
}

export function addAirplanesToCase({
  caseId,
  reportId,
  contentId: airplaneId
}: AddContentToCase) {
  return Client.post<unknown, AssetAttributes>(
    `cases/${caseId}/reports/${reportId}/contents/airplanes/${airplaneId}`
  );
}
