import React, { useContext } from 'react';

import { Action, can, fetchFiles } from 'api';
import { useQuery } from 'hooks';

import { Card, openErrorToast, Stack, StackMargin } from 'ui';
import { CaseContext, FileList } from 'components';
import ContractFiles from './ContractFiles';
import InternalLawsuitFiles from './InternalLawsuitFiles';
import AssetFiles from './AssetFiles';
import InvoiceFiles from './InvoiceFiles';

import style from './Files.module.scss';

type Props = {
  caseId: string;
};

const Files = ({ caseId }: Props) => {
  const {
    data: files,
    error: errorFiles,
    isLoading: isLoadingFiles
  } = useQuery(fetchFiles, caseId);

  const { caseData } = useContext(CaseContext);
  const negotiatorId = caseData?.negotiator?.id;

  if (errorFiles) {
    openErrorToast('Erro ao listar arquivos do caso');
  }

  const isEventFilesEmpty = files?.eventFiles.length === 0;
  const isInvoiceFilesEmpty = files?.invoices.length === 0;

  const canUser = {
    deleteFiles: can(Action.CASOS_ARQUIVOS_EXCLUIR, negotiatorId),
    visitContracts: can(Action.CASOS_ARQUIVOS_VER_CONTRATO, negotiatorId),
    visitLawsuits: can(Action.CASOS_ARQUIVOS_VER_PROCESSO, negotiatorId),
    visitAssets: can(Action.CASOS_ARQUIVOS_VER_ATIVO, negotiatorId),
    visitInvoices: can(Action.CASOS_ARQUIVOS_VER_NOTA_FISCAL, negotiatorId)
  };

  return (
    <div className="case-files">
      {isLoadingFiles ? (
        <p>Carregando...</p>
      ) : (
        <>
          {!isInvoiceFilesEmpty && (
            <InvoiceFiles
              invoices={files?.invoices ?? []}
              showDelete={canUser.deleteFiles}
              showLink={canUser.visitInvoices}
            />
          )}

          {files?.contracts && (
            <Stack marginTop={StackMargin.MEDIUM}>
              <ContractFiles
                contracts={files?.contracts}
                showDelete={canUser.deleteFiles}
                showLink={canUser.visitContracts}
              />
            </Stack>
          )}

          {files?.lawsuits && (
            <Stack marginTop={StackMargin.MEDIUM}>
              <InternalLawsuitFiles
                internalLawsuits={files?.lawsuits}
                caseId={caseId}
                showDelete={canUser.deleteFiles}
                showLink={canUser.visitLawsuits}
              />
            </Stack>
          )}

          <Stack marginTop={StackMargin.MEDIUM}>
            <Card>
              <h2 className={style.cardTitle}>Arquivos do caso</h2>

              {isEventFilesEmpty && (
                <p className={style.emptyList}>
                  Não há arquivos na timeline deste caso
                </p>
              )}

              {!isLoadingFiles && !isEventFilesEmpty && (
                <div className={style.caseFilesList}>
                  <FileList
                    files={files?.eventFiles}
                    caseId={caseId}
                    className={style.filesList}
                    showDelete={canUser.deleteFiles}
                  />
                </div>
              )}
            </Card>
          </Stack>

          {files?.assets && (
            <Stack marginTop={StackMargin.MEDIUM}>
              <AssetFiles
                caseId={caseId}
                assets={files.assets}
                showDelete={canUser.deleteFiles}
                showLink={canUser.visitAssets}
              />
            </Stack>
          )}
        </>
      )}
    </div>
  );
};

export default Files;
