import { QueryStatus } from './queryStatus';

export enum SocialQueryType {
  SUBJECT = 'subject',
  LOCATIONS = 'locations',
  RELATED_PROFILES = 'related_profiles'
}

export type SocialQuery = {
  id: string;
  status: QueryStatus;
  type: SocialQueryType;
};
