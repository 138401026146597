import { ProviderName, ProviderType } from 'types/provider';
import { QueryStatus } from './queryStatus';
import { EntityType } from './entity';

export type QueryType = EntityType;

export type QueryAttributes = {
  provider: ProviderName;
  status: QueryStatus;
  type: ProviderType;
  expectedTables: ReportExpectedTable[];
};

export type Query = QueryAttributes & {
  id: string;
};

export enum ReportExpectedTable {
  AIRPLANE = 'airplane',
  CNAE = 'cnae',
  COMPANY = 'company',
  ELECTORAL_DONATION = 'electoral_donation',
  EXTERNAL_LAWSUIT = 'external_lawsuit',
  FAMILY_MEMBER = 'family_member',
  INDIVIDUAL = 'individual',
  INSTAGRAM = 'instagram',
  LOCATION = 'location',
  PARTNER = 'partner',
  PARTNERSHIP_COMPANY = 'partnership_company',
  REAL_ESTATE = 'real_estate',
  RELATED_COMPANY = 'related_company',
  RURAL_PROPERTY = 'rural_property',
  VEHICLE = 'vehicle'
}
