import { getFormikError } from 'domain/forms';
import { ColoredBadge } from 'components';
import { BadgeColor } from 'components/ColoredBadge/ColoredBadge';
import { FormikProps } from 'formik';
import React from 'react';
import { AssetAttributes, AssetAutoStatus, AssetManualStatus } from 'types';
import { MultiSelect } from 'ui';

type Props = {
  isLoading: boolean;
  formik: FormikProps<AssetAttributes>;
  isDisabled: boolean;
};

const isAssetManualStatus = (status: string): status is AssetManualStatus =>
  Object.values(AssetManualStatus).includes(status as AssetManualStatus);

const StatusSelector = ({ formik, isLoading, isDisabled }: Props) => {
  const hasError = getFormikError(formik);

  const allStatus = [
    ...formik.values.autoStatus,
    ...formik.values.manualStatus
  ];

  const isSelected = (assetStatus: AssetAutoStatus | AssetManualStatus) =>
    allStatus.includes(assetStatus);
  const isEmpty = (xs: unknown[]) => xs.length === 0;

  const shouldAllowAvailable = isEmpty(
    allStatus.filter(s => s !== AssetManualStatus.AVAILABLE)
  );

  const genericEnabledCase = !isSelected(AssetManualStatus.AVAILABLE);

  const enabled = {
    [AssetAutoStatus.EXECUTED]: false,
    [AssetAutoStatus.GUARANTEED]: false,
    [AssetAutoStatus.RECLAIMED]: false,
    [AssetManualStatus.AVAILABLE]: shouldAllowAvailable,
    [AssetManualStatus.EXECUTED_BY_THIRD_PARTY]: genericEnabledCase,
    [AssetManualStatus.GUARANTEED_BY_THIRD_PARTY]: genericEnabledCase,
    [AssetManualStatus.UNAVAILABLE]: genericEnabledCase
  };

  const handleSelection = (
    selected: Array<AssetAutoStatus | AssetManualStatus>
  ) =>
    formik.setFieldValue('manualStatus', selected.filter(isAssetManualStatus));

  const Option = (
    value: AssetAutoStatus | AssetManualStatus,
    title: string,
    color: BadgeColor
  ) => ({ value, title, color });

  return (
    <MultiSelect
      disabled={isDisabled}
      selected={allStatus}
      name="manualStatus"
      id="manualStatus"
      onChange={handleSelection}
      isLoading={isLoading}
      onBlur={formik.handleBlur}
      error={hasError('autoStatus') || hasError('manualStatus')}
      title="Status do ativo"
    >
      {[
        Option(AssetManualStatus.AVAILABLE, 'Livre', BadgeColor.GREEN),
        Option(
          AssetAutoStatus.GUARANTEED,
          'Em garantia',
          BadgeColor.AQUAMARINE
        ),
        Option(AssetAutoStatus.EXECUTED, 'Penhorado', BadgeColor.PURPLE),
        Option(
          AssetManualStatus.GUARANTEED_BY_THIRD_PARTY,
          'Garantia de terceiros',
          BadgeColor.ORANGE
        ),
        Option(
          AssetManualStatus.EXECUTED_BY_THIRD_PARTY,
          'Penhorado por terceiros',
          BadgeColor.PINK
        ),
        Option(
          AssetManualStatus.UNAVAILABLE,
          'Indisponibilidade fiscal',
          BadgeColor.ROUGE
        ),
        Option(AssetAutoStatus.RECLAIMED, 'Recuperado', BadgeColor.BLUE)
      ].map(({ color, value, title }, index) => (
        <MultiSelect.Option
          value={value}
          disabled={!enabled[value]}
          key={index}
        >
          <ColoredBadge title={title} type={color} />
        </MultiSelect.Option>
      ))}
    </MultiSelect>
  );
};

export default StatusSelector;
