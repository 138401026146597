import React from 'react';
import * as Yup from 'yup';
import { useFormik, getIn, FormikErrors } from 'formik';

import {
  Toggle,
  InputField,
  FormContainer,
  Button,
  StackMargin,
  Stack
} from 'ui';
import { getWithDefault } from 'utils';
import {
  OnSubmit,
  EntityFormAttributes,
  CaseEntity,
  EntityType,
  ApiSuccessfulResponse,
  EntityAttributes,
  ApiFailedResponse
} from 'types';
import * as ValidationSchema from 'utils/validationSchema';

const initialValues: EntityFormAttributes = {
  type: EntityType.INDIVIDUAL,
  individual: {
    name: '',
    cpf: '',
    isAbroad: false
  },
  company: {
    businessName: '',
    cnpj: '',
    isAbroad: false
  },
  description: ''
};

const formatInitialValues = (entity?: EntityAttributes) =>
  Object.keys(initialValues).reduce(
    (values, key) => ({
      ...values,
      ...{
        [key]: getWithDefault<EntityAttributes, EntityFormAttributes>(
          key as keyof EntityFormAttributes,
          initialValues,
          entity
        )
      }
    }),
    {} as EntityFormAttributes
  );

const validationSchema = Yup.object().shape({
  type: Yup.string().required('Campo obrigatório'),
  individual: Yup.object().when('type', {
    is: 'individual',
    then: Yup.object().shape({
      isAbroad: Yup.boolean(),
      cpf: Yup.string().when('isAbroad', {
        is: false,
        then: ValidationSchema.cpf()
          .required('Campo obrigatório')
          .nullable(),
        otherwise: ValidationSchema.cpf()
          .notRequired()
          .nullable()
      }),
      name: Yup.string()
        .min(3, 'Precisa ter ao menos 3 caracteres')
        .max(255, 'Precisa ter menos de 256 caracteres')
        .required('Campo obrigatório')
    }),
    otherwise: Yup.object()
      .notRequired()
      .nullable(),
    name: Yup.string()
      .notRequired()
      .nullable()
  }),
  company: Yup.object().when('type', {
    is: 'company',
    then: Yup.object().shape({
      isAbroad: Yup.boolean(),
      cnpj: Yup.string().when('isAbroad', {
        is: false,
        then: ValidationSchema.cnpj()
          .required('Campo obrigatório')
          .nullable(),
        otherwise: ValidationSchema.cnpj()
          .notRequired()
          .nullable()
      }),
      businessName: Yup.string()
        .min(3, 'Precisa ter ao menos 3 caracteres')
        .max(255, 'Precisa ter menos de 256 caracteres')
        .required('Campo obrigatório')
    }),
    otherwise: Yup.object().shape({
      cnpj: Yup.string()
        .notRequired()
        .nullable(),
      businessName: Yup.string()
        .notRequired()
        .nullable()
    })
  }),
  description: Yup.string()
    .max(255, 'Precisa ter menos de 256 caracteres')
    .notRequired()
    .nullable()
});

export enum FormType {
  ADD = 'add',
  CREATE = 'create'
}

type DefaultProps = {
  onSuccess: (entity: CaseEntity) => void;
  onError: (error: ApiFailedResponse) => void;
};

type AddArgs = {
  reportId: string;
  caseId: string;
  description: string;
};

type AddProps = {
  formMode: FormType.ADD;
  entity: EntityAttributes;
  addFunction: ({
    reportId,
    caseId,
    description
  }: AddArgs) => Promise<ApiSuccessfulResponse<CaseEntity>>;
  args: Pick<AddArgs, 'reportId' | 'caseId'>;
} & DefaultProps;

type CreateProps = {
  formMode: FormType.CREATE;
  createFunction: (
    caseId: string,
    values: EntityFormAttributes
  ) => Promise<ApiSuccessfulResponse<CaseEntity>>;
  args: { caseId: string };
} & DefaultProps;

type FormProps = AddProps | CreateProps;

const Form = (props: FormProps) => {
  const { onSuccess, onError } = props;
  const { addFunction, entity, args: addArgs, formMode } = props as AddProps;
  const { args: createArgs, createFunction } = props as CreateProps;

  const handleErrors = (
    setErrors: (errors: FormikErrors<EntityFormAttributes>) => void,
    error: ApiFailedResponse
  ) => {
    const { errors } = error;
    if (errors) {
      setErrors(errors as FormikErrors<EntityFormAttributes>);
    }

    return onError(error);
  };

  const onSubmit: OnSubmit<EntityFormAttributes> = async (
    values,
    formikHelpers
  ) => {
    const submitFn = {
      [FormType.ADD]: () =>
        addFunction({
          caseId: addArgs.caseId,
          reportId: addArgs.reportId,
          description: values.description
        }),
      [FormType.CREATE]: () => createFunction(createArgs.caseId, values)
    }[formMode];

    try {
      const response = await submitFn();
      return onSuccess(response.data);
    } catch (error) {
      return handleErrors(formikHelpers.setErrors, error);
    }
  };

  const formik = useFormik({
    initialValues: formatInitialValues(entity),
    validationSchema,
    onSubmit: onSubmit
  });

  const toggleOptions = [
    { value: 'individual', label: 'Física' },
    { value: 'company', label: 'Jurídica' }
  ];

  const showMode = formMode === FormType.ADD;

  return (
    <form className="suspect-form" data-testid="suspect-form">
      <FormContainer>
        <Toggle
          dataTestId="toggle-type"
          title="Tipo de pessoa"
          options={toggleOptions}
          checked={formik.values.type}
          onChange={event => formik.setFieldValue('type', event.target.value)}
          showMode={showMode}
        />

        {formik.values.type === 'individual' ? (
          <>
            <Toggle
              name="individual.isAbroad"
              title="País"
              options={[
                { value: 'false', label: 'Brasil' },
                { value: 'true', label: 'Exterior' }
              ]}
              checked={formik.values.individual.isAbroad.toString()}
              onChange={event =>
                formik.setFieldValue('individual.isAbroad', event.target.value)
              }
              showMode={showMode}
            />
            <InputField
              id="suspect-cpf"
              dataTestId="suspect-cpf"
              name="individual.cpf"
              title="CPF"
              type="cpf"
              value={formik.values.individual.cpf}
              error={
                formik.touched.individual?.cpf &&
                getIn(formik.errors, 'individual.cpf')
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              showMode={showMode}
            ></InputField>
            <InputField
              id="suspect-name"
              dataTestId="suspect-name"
              name="individual.name"
              title="Nome completo"
              type="text"
              value={formik.values.individual.name}
              error={
                formik.touched.individual?.name &&
                getIn(formik.errors, 'individual.name')
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              showMode={showMode}
            ></InputField>
          </>
        ) : (
          <>
            <Toggle
              name="company.isAbroad"
              title="País"
              options={[
                { value: 'false', label: 'Brasil' },
                { value: 'true', label: 'Exterior' }
              ]}
              checked={formik.values.company.isAbroad.toString()}
              onChange={event =>
                formik.setFieldValue('company.isAbroad', event.target.value)
              }
              showMode={showMode}
            />
            <InputField
              id="suspect-cnpj"
              dataTestId="suspect-cnpj"
              name="company.cnpj"
              title="CNPJ"
              type="cnpj"
              value={formik.values.company.cnpj}
              error={
                formik.touched.company?.cnpj &&
                getIn(formik.errors, 'company.cnpj')
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              showMode={showMode}
            ></InputField>
            <InputField
              id="suspect-business-name"
              dataTestId="suspect-business-name"
              name="company.businessName"
              title="Razão social"
              type="text"
              value={formik.values.company.businessName}
              error={
                formik.touched.company?.businessName &&
                getIn(formik.errors, 'company.businessName')
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              showMode={showMode}
            ></InputField>
          </>
        )}
        <InputField
          id="description"
          dataTestId="description"
          name="description"
          title="Descrição (opcional)"
          type="text"
          value={formik.values.description}
          error={
            getIn(formik.touched, 'description') &&
            getIn(formik.errors, 'description')
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </FormContainer>
      <Stack marginTop={StackMargin.MEDIUM}>
        <Button
          highlight
          centered
          dataTestId="submit-suspect-form"
          onClick={formik.handleSubmit}
          type="submit"
        >
          Adicionar
        </Button>
      </Stack>
    </form>
  );
};

export default Form;
