import { guaranteeTotalValue } from 'domain/guarantees';

import React, { ReactElement, Fragment, useState, useContext } from 'react';
import size from 'lodash/size';
import omit from 'lodash/omit';
import sumBy from 'lodash/sumBy';

import { Action, can } from 'api';
import { Button, openSuccessToast, Accordion, StackMargin, Stack } from 'ui';
import { useHashAnchor } from 'hooks/useAnchor';
import { applyMask } from 'utils';
import { Guarantee, Guarantee as GuaranteeData } from 'types';
import { CaseContext } from 'components';
import GuaranteeCard from './Card/Card';
import ContractGuaranteeRelationshipModal from './ContractGuaranteeRelationshipModal';

import style from './Guarantees.module.scss';

type GuaranteeCardState = {
  [index: string]: {
    data?: GuaranteeData;
  };
};

type Props = {
  caseId: string;
  contractId: string;
  fetchedGuarantees?: Array<GuaranteeData>;
  canCreateRelationship?: boolean;
};

const Guarantees = ({
  caseId,
  contractId,
  fetchedGuarantees = [],
  canCreateRelationship = false
}: Props): ReactElement => {
  const [
    isContractGuaranteeRelationshipModalOpen,
    setIsContractGuaranteeRelationshipModalOpen
  ] = useState<boolean>(false);
  const [guarantees, setGuarantees] = useState<GuaranteeCardState>(
    fetchedGuarantees.reduce(
      (agg: GuaranteeCardState, guarantee: GuaranteeData, key: number) => {
        agg[key] = { data: guarantee };
        return agg;
      },
      {}
    )
  );

  const [totalCurrentValue, setTotalCurrentValue] = useState<number>(
    sumBy(fetchedGuarantees, guarantee => guaranteeTotalValue(guarantee)) || 0
  );

  const { caseData } = useContext(CaseContext);

  const addGuaranteeCard = (guaranteeData: { data?: Guarantee }) => {
    const guaranteeKeys: string | undefined = Object.keys(guarantees).pop();
    const newKey: number = guaranteeKeys ? parseInt(guaranteeKeys, 10) + 1 : 0;

    const newGuarantee: GuaranteeCardState = {
      [newKey]: guaranteeData
    };

    setGuarantees({ ...guarantees, ...newGuarantee });
  };

  const handleDeleteGuarantee = (index: string) => async (
    message: string
  ): Promise<void> => {
    setGuarantees(omit(guarantees, index));
    openSuccessToast(message);
  };

  const handleNewGuarantee = (): void => {
    addGuaranteeCard({});
  };

  const handleAddExistentGuarantee = (existentGuarantee: Guarantee) => {
    addGuaranteeCard({ data: existentGuarantee });
  };

  const toggleContractGuaranteeRelationshipModal = (): void => {
    setIsContractGuaranteeRelationshipModalOpen(
      !isContractGuaranteeRelationshipModalOpen
    );
  };

  const increaseTotalCurrentValue = (amount: number): void => {
    setTotalCurrentValue(totalCurrentValue + amount);
  };

  useHashAnchor();

  const negotiatorId = caseData?.negotiator?.id;

  const canUser = {
    addGuarantee: can(Action.CASOS_GARANTIAS_INCLUIR, negotiatorId)
  };

  return (
    <Accordion
      title="Garantias"
      badgeContent={size(guarantees)}
      headerInfo={{
        label: 'Total de garantias:',
        value: applyMask('currency', totalCurrentValue.toString())
      }}
    >
      <Fragment>
        {Object.entries(guarantees).map(([key, guarantee], index) => (
          <Stack
            marginTop={index === 0 ? StackMargin.ZERO : StackMargin.MEDIUM}
            key={`stack-${key}`}
          >
            <GuaranteeCard
              contractId={contractId!}
              htmlId={guarantee.data?.id ?? key}
              key={`guarantee-card-${key}`}
              guaranteeData={guarantee.data}
              onDelete={handleDeleteGuarantee(key)}
              caseId={caseId}
              increaseTotalCurrentValue={increaseTotalCurrentValue}
            />
          </Stack>
        ))}

        <Stack marginTop={StackMargin.MEDIUM}>
          <div className={style.guaranteesActions}>
            {canUser.addGuarantee && (
              <Button
                onClick={handleNewGuarantee}
                highlight
                outline
                dataTestId="add-new-guarantee"
              >
                Adicionar garantia
              </Button>
            )}

            {canUser.addGuarantee && canCreateRelationship && (
              <Stack marginLeft={StackMargin.MEDIUM}>
                <Button
                  onClick={toggleContractGuaranteeRelationshipModal}
                  highlight
                  outline
                  dataTestId="add-existent-guarantee"
                >
                  Adicionar garantia existente
                </Button>
              </Stack>
            )}
          </div>
        </Stack>

        <ContractGuaranteeRelationshipModal
          caseId={caseId}
          contractId={contractId}
          isOpen={isContractGuaranteeRelationshipModalOpen}
          onAddGuarantee={handleAddExistentGuarantee}
          onClose={toggleContractGuaranteeRelationshipModal}
        />
      </Fragment>
    </Accordion>
  );
};

export default Guarantees;
