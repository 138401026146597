import React from 'react';

import { InvoiceWithDuplicateFiles } from 'types';

import { Card, Accordion, Link } from 'ui';
import { FileList } from 'components';

import style from './InvoiceFiles.module.scss';

type Props = {
  invoices: InvoiceWithDuplicateFiles[];
  showDelete?: boolean;
  showLink?: boolean;
};

const InvoiceFiles = ({
  invoices,
  showDelete = true,
  showLink = true
}: Props) => {
  return (
    <Card>
      <h2 className={style.cardTitle}>Notas Fiscais</h2>

      {invoices.map(invoice => {
        return (
          <Accordion
            isClosed
            key={`contract-${invoice.id}`}
            title={`Nota fiscal nº ${invoice.number}`}
            titleClassName={style.accordionHeader}
            headerActions={
              showLink && (
                <Link
                  target="_blank"
                  highlight
                  href={`/cases/${invoice.caseId}/debtors/invoice/${invoice.id}`}
                >
                  Ver nota fiscal
                </Link>
              )
            }
          >
            <div className={style.caseFilesList}>
              <FileList
                className={style.filesList}
                files={invoice.fileUploads}
                title="Arquivos da nota fiscal"
                caseId={invoice.caseId}
                placeholder="Não há arquivos nesta nota fiscal"
                showDelete={showDelete}
              />
              <FileList
                className={style.filesList}
                files={invoice.duplicateFiles}
                title="Arquivos das duplicatas"
                caseId={invoice.caseId}
                placeholder="Não há arquivos das duplicatas nesta nota fiscal"
                showDelete={showDelete}
              />
            </div>
          </Accordion>
        );
      })}
    </Card>
  );
};

export default InvoiceFiles;
