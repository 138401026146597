import React from 'react';
import cn from 'classnames';

import './Checkbox.scss';

type Props = {
  id: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  label?: string;
  checked?: boolean;
  disabled?: boolean;
  error?: Array<string> | string;
  indeterminate?: boolean;
};

const Checkbox = ({
  label,
  id,
  name,
  value,
  disabled,
  checked = false,
  onChange,
  error,
  indeterminate = false
}: Props) => {
  const inputClass = cn('checkbox-default', {
    '-error': Boolean(error),
    '-indeterminate': Boolean(indeterminate)
  });

  return (
    <div className={inputClass}>
      <input
        data-testid={id}
        className="input"
        type="checkbox"
        id={id}
        name={name}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        value={value}
      />
      <label className="label" htmlFor={id}>
        {label}
      </label>

      {error && (
        <p className="message">
          {Array.isArray(error) ? error.join(', ') : error}
        </p>
      )}
    </div>
  );
};

export default Checkbox;
