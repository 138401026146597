import React from 'react';
import { FileField } from 'components';

import { Stack, StackMargin } from 'ui';

import { ExecutionRequestAttributes, FileUpload } from 'types';

type Props = Pick<ExecutionRequestAttributes, 'caseId'> & {
  initialFiles: FileUpload[];
  onUpdate: (ids: string[]) => void;
  showAdd?: boolean;
  showDelete?: boolean;
};

const Files = ({
  caseId,
  initialFiles,
  onUpdate,
  showAdd,
  showDelete
}: Props) => {
  return (
    <Stack marginTop={StackMargin.MEDIUM}>
      <FileField
        title="Arquivos da penhora"
        placeholder="Não há arquivos nesta penhora"
        initialFiles={initialFiles}
        caseId={caseId}
        id="filesUploadId"
        name="filesUpload"
        dataTestId="filesUploadInput"
        onUploadSuccess={onUpdate}
        showAdd={showAdd}
        showDelete={showDelete}
      />
    </Stack>
  );
};

export default Files;
