import { openSuccessToast } from 'ui';

export const NOSELECT = 'noselect';

export const handleCopy = async (id: string, tableName: string) => {
  const head = document.querySelector(`[data-js="head-${tableName}"]`);
  const headClone = head!.cloneNode(true) as HTMLElement;
  const headChild = headClone.querySelectorAll(`th.noselect`);

  for (let i = 0; i < headChild.length; i++) {
    headClone.removeChild(headChild[i]);
  }

  headClone.setAttribute('data-js', `copy-${tableName}`);
  head!.parentNode!.insertBefore(headClone, head!.parentNode!.firstChild);

  const row = document.querySelector(`[data-js="row-${id}"]`);
  const rowClone = row!.cloneNode(true) as HTMLElement;
  const rowChild = rowClone.querySelectorAll(`td.${NOSELECT}`);

  for (let i = 0; i < rowChild.length; i++) {
    rowClone.removeChild(rowChild[i]);
  }

  rowClone.setAttribute('data-js', `copy-${id}`);
  row!.parentNode!.insertBefore(rowClone, row!.parentNode!.firstChild);

  const range = document.createRange();
  const selection = document.getSelection();

  const headCopy = document.querySelector(
    `[data-js="copy-${tableName}"]`
  ) as HTMLElement;
  const rowCopy = document.querySelector(
    `[data-js="copy-${id}"]`
  ) as HTMLElement;

  range.selectNode(rowCopy!);
  range.insertNode(headCopy!);
  selection!.removeAllRanges();
  selection!.addRange(range);

  document.execCommand('copy');

  headCopy.remove();
  rowCopy.remove();

  openSuccessToast('Linha copiada com sucesso');
};
