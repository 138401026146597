import { assetTypeLabel, assetValueCents } from 'domain/assets';
import {
  objectLabel as guaranteeObjectLabel,
  guaranteeTotalValue
} from 'domain/guarantees';
import {
  getExecutionLink,
  getExecutionTitle,
  getExecutionCounter
} from 'domain/executions';
import { externalLawsuitTypeLabel } from 'domain/externalLawsuits';

import uniqBy from 'lodash/uniqBy';
import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { Card, Link, Modal, openErrorToast, openSuccessToast } from 'ui';
import { useToggles } from 'hooks';
import { toggleExternalLawsuitTracking, can, Action } from 'api';
import { applyDateMask, applyMask, handleNullValue } from 'utils';

import { CaseContext } from 'components';

import {
  Execution,
  ExecutionType,
  ExecutionEvent,
  ExecutionRequestAttributes
} from 'types';

import ExecutionEventTimeline from './EventTimeline';
import Form from './Form';

import style from './ExecutionCard.module.scss';

const getExecutionMetadata = (execution: Execution) => {
  const { type } = execution;

  if (type === ExecutionType.ASSET) {
    const asset = execution.executedAsset!;

    return {
      type: assetTypeLabel(asset),
      value: handleNullValue(assetValueCents(asset), 'string', value =>
        applyMask('currency', value)
      ),
      link: {
        text: asset.isGroup ? 'Ver grupo' : 'Ver ativo',
        ...getExecutionLink(execution)
      },
      title: getExecutionTitle(execution),
      counter: getExecutionCounter(execution)
    };
  }

  if (type === ExecutionType.GUARANTEE) {
    const guarantee = execution.executedGuarantee!;

    return {
      type: guaranteeObjectLabel(guarantee.object),
      value: handleNullValue(guaranteeTotalValue(guarantee), 'string', value =>
        applyMask('currency', value)
      ),
      link: {
        text: 'Ver contrato',
        ...getExecutionLink(execution)
      },
      title: getExecutionTitle(execution)
    };
  }

  const externalLawsuitBasicInfo = execution.executedExternalLawsuit!.content
    .basicInformation;

  return {
    type: externalLawsuitTypeLabel(externalLawsuitBasicInfo),
    basicInfos: uniqBy(externalLawsuitBasicInfo, 'value').map(basicInfo => ({
      distributionDate: applyDateMask(basicInfo.distributionDate!),
      value: handleNullValue(basicInfo.value, 'string', value =>
        applyMask('currency', value)
      )
    })),
    link: {
      text: 'Ver processo',
      ...getExecutionLink(execution)
    },
    title: getExecutionTitle(execution)
  };
};

type Props = Omit<ExecutionRequestAttributes, 'executionId'> & {
  execution: Execution;
  onUpdate: (execution: Execution) => void;
  onRemove: (id: string) => void;
};

const ExecutionCard = ({
  caseId,
  lawsuitId,
  execution,
  onUpdate,
  onRemove
}: Props) => {
  const { isOpen, toggle, toggleTrigger } = useToggles<
    'editModal' | 'timeline'
  >({
    editModal: false,
    timeline: false
  });

  const location = useLocation();

  const handleUpdate = (updatedExecution: Execution) => {
    toggleTrigger('editModal');
    return onUpdate(updatedExecution);
  };

  const handleRemoveTracking = async (executionId: string) => {
    if (!window.confirm('Deseja remover de rastreamento também?'))
      return onRemove(executionId);

    try {
      await toggleExternalLawsuitTracking({
        caseId,
        externalLawsuitId: execution.executedExternalLawsuit!.id
      });

      openSuccessToast('Processo removido do rastreamento de ativos.');
    } catch (error) {
      openErrorToast('Não foi possível remover do rastreamento de ativos.');
    } finally {
      onRemove(executionId);
    }
  };

  const handleRemove = (id: string) => {
    toggleTrigger('editModal');

    if (execution.type === ExecutionType.EXTERNAL_LAWSUIT) {
      return handleRemoveTracking(id);
    }

    return onRemove(id);
  };

  const handleEvents = (events: ExecutionEvent[]) =>
    onUpdate({ ...execution, events });

  const executionMetadata = getExecutionMetadata(execution);

  const { caseData } = useContext(CaseContext);
  const negotiatorId = caseData?.negotiator?.id;
  const canUser = {
    editExecution: can(Action.CASOS_PROCESSO_PENHORA_EDITAR, negotiatorId)
  };

  return (
    <>
      <Card medium className={style.executionCard}>
        <div
          className={style.executionTitleContainer}
          data-testid="execution-title-container"
        >
          <div
            data-testid="execution-accordion-title"
            className={style.accordionTitle}
          >
            <ExecutionEventTimeline.Toggle
              isClosed={!isOpen.timeline}
              onClick={toggle('timeline')}
            />
            <span data-testid="card-title" className={style.executionTitle}>
              {executionMetadata.title}
              {executionMetadata.counter && (
                <span className={style.executionCounter}>
                  {executionMetadata.counter}
                </span>
              )}
            </span>
          </div>
          <div
            data-testid="execution-actions"
            className={style.executionButtons}
          >
            <Link
              highlight
              to={{
                ...executionMetadata.link,
                state: { returnTo: location.pathname }
              }}
            >
              {executionMetadata.link.text}
            </Link>
            {canUser.editExecution && (
              <Link
                highlight
                onClick={toggle('editModal')}
                data-testid="edit-execution-link"
              >
                Editar penhora
              </Link>
            )}
          </div>
        </div>
        <div className={style.executionMetadata}>
          <p data-testid="execution-type" className={style.paragraph}>
            Tipo:
            <b className={style.bold}>{executionMetadata.type}</b>
          </p>

          {execution.type === ExecutionType.EXTERNAL_LAWSUIT ? (
            executionMetadata.basicInfos?.map(
              ({ distributionDate, value }, index) => (
                <p
                  key={`${value}-${distributionDate}-${index}`}
                  data-testid="execution-value"
                  className={style.paragraph}
                >
                  Valor da causa:
                  <b className={style.bold}>{value}</b>
                  <span> (distribuição {distributionDate})</span>
                </p>
              )
            )
          ) : (
            <p data-testid="execution-value" className={style.paragraph}>
              Valor da avaliação:
              <b className={style.bold}>{executionMetadata.value}</b>
            </p>
          )}

          <p data-testid="execution-degree" className={style.paragraph}>
            Grau de penhora:
            <b className={style.bold}>
              {handleNullValue(execution.degree, 'string')}º grau
            </b>
          </p>
          <ExecutionEventTimeline
            caseId={caseId}
            lawsuitId={lawsuitId}
            executionId={execution.id}
            handleEvents={handleEvents}
            events={execution.events}
            isClosed={!isOpen.timeline}
          />
        </div>
      </Card>

      <Modal
        isOpen={isOpen.editModal}
        title="Editar penhora"
        onClose={toggle('editModal')}
      >
        <Form
          title={executionMetadata.title}
          degree={execution.degree}
          lawsuitId={lawsuitId}
          executionId={execution.id}
          caseId={caseId}
          onSave={handleUpdate}
          onRemove={handleRemove}
        />
      </Modal>
    </>
  );
};

export default ExecutionCard;
