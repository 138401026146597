import { ERROR_MESSAGE, ReportAssetName } from 'domain/reports';
import { buildPaginationParams } from 'domain/pagination';
import React from 'react';

import {
  Accordion,
  Card,
  DropDown,
  openSuccessToast,
  openErrorToast,
  DynamicTable,
  TablePositionModifier,
  Stack,
  StackMargin
} from 'ui';
import { applyMask, handleNullValue, addPercentage } from 'utils';
import { openReportTab } from 'pages/reports/utils';
import {
  createReportFromPartners,
  addPartnersToCase,
  fetchReportPartners,
  fetchReportPartnersSummary
} from 'api';
import { useQuery } from 'hooks';

import { ReportPartner, ActionName, ActionEntity } from 'types';
import { FormerValueTooltip, ProviderTooltip, SummaryError } from 'components';
import * as Actions from 'utils/actions';
import compact from 'lodash/compact';

import SummaryTitle from 'pages/reports/SharedComponents/SummaryTitle';
import ReportDynamicTable from 'pages/reports/SharedComponents/ReportDynamicTable/ReportDynamicTable';

import style from './PartnersTable.module.scss';

type Props = {
  caseId: string;
  reportId: string;
  addToCase?: boolean;
  research?: boolean;
  isSummary?: boolean;
  providerStatus?: string;
  tableMessage?: string;
};

const PartnersTable = ({
  isSummary,
  caseId,
  reportId,
  addToCase = true,
  research = true,
  providerStatus,
  tableMessage
}: Props) => {
  const fetchFunction = isSummary
    ? fetchReportPartnersSummary
    : fetchReportPartners;

  const {
    data: partners = [],
    isLoading,
    error,
    pagination,
    refetch
  } = useQuery(fetchFunction, {
    caseId,
    reportId
  });

  if (error) {
    return (
      <SummaryError
        title="Estrutura societária"
        message={ERROR_MESSAGE.partners}
      />
    );
  }

  const handleSearch = (partnerId: ReportPartner['id']) => async () => {
    try {
      const response = await createReportFromPartners({
        caseId,
        reportId,
        contentId: partnerId
      });

      openSuccessToast('Consulta salva com sucesso!');
      openReportTab(response.data);
    } catch (err) {
      openErrorToast('Houve um erro ao criar a consulta.');
    }
  };

  const handleAddToCase = (partner: ReportPartner) => async () => {
    try {
      await addPartnersToCase({ caseId, reportId, contentId: partner.id });
      openSuccessToast(`${partner.role} adicionado ao caso com sucesso`);
    } catch (err) {
      openErrorToast(`Não foi possivel adicionar o ${partner.role} ao caso`);
    }
  };

  const isEmpty = partners.length < 1;

  const buildActions = (entry: ReportPartner) => {
    const createReportAction = {
      text: 'Buscar',
      callback: handleSearch(entry.id),
      isDisabled: Actions.isDisabled(
        entry.field.actions,
        ActionName.CREATE_REPORT
      ),
      description: Actions.unavailableReasons(
        entry.field.actions,
        ActionName.CREATE_REPORT,
        ActionEntity.PARTNER
      )
    };

    const addToCaseAction = {
      text: 'Adicionar ao caso',
      callback: handleAddToCase(entry),
      isDisabled: Actions.isDisabled(
        entry.field.actions,
        ActionName.ADD_TO_CASE
      ),
      description: Actions.unavailableReasons(
        entry.field.actions,
        ActionName.ADD_TO_CASE,
        ActionEntity.PARTNER
      )
    };

    const allActionsAllowed = compact([
      addToCase && addToCaseAction,
      research && createReportAction
    ]);

    return allActionsAllowed;
  };

  const columns = [
    {
      name: 'CPF ou CNPJ',
      columnKey: 'identification',
      render: (item: ReportPartner) => (
        <FormerValueTooltip
          fieldName="identification"
          fieldValue={item.identification}
          history={item.field.history}
          formatter={value =>
            applyMask(item.type === 'company' ? 'cnpj' : 'cpf', value)
          }
        />
      )
    },
    {
      name: 'Nome ou Razão Social',
      columnKey: 'name',
      render: (item: ReportPartner) => (
        <FormerValueTooltip
          fieldName="name"
          fieldValue={item.name}
          history={item.field.history}
        />
      )
    },
    {
      name: 'Participação societária',
      columnKey: 'share',
      render: (item: ReportPartner) => (
        <FormerValueTooltip
          fieldName="share"
          fieldValue={item.share}
          history={item.field.history}
          formatter={value => addPercentage(value)}
        >
          {addPercentage(handleNullValue(item.share, 'number'))}
        </FormerValueTooltip>
      )
    },
    {
      name: 'Qualificação',
      columnKey: 'role',
      render: (item: ReportPartner) => (
        <FormerValueTooltip
          fieldName="role"
          fieldValue={item.role}
          history={item.field.history}
        />
      )
    }
  ];

  const actionColumn = [
    {
      positionModifier: TablePositionModifier.RIGHT,
      hasActions: true,
      name: '',
      columnKey: 'actions',
      render: (item: ReportPartner) => (
        <>
          <ProviderTooltip
            providers={item.field.providers}
            searchedByHomonym={item.field.searchedByHomonym}
            dataTestId="provider-tooltip"
          />
          {buildActions(item).length > 0 && (
            <DropDown options={buildActions(item)} />
          )}
        </>
      )
    }
  ];

  const rowKey = (item: ReportPartner) => item.id;

  if (isSummary) {
    return (
      <>
        <Stack marginBottom={StackMargin.SMALL}>
          <SummaryTitle>{`Estrutura societária (${partners.length})`}</SummaryTitle>
        </Stack>
        <DynamicTable
          columns={columns}
          data={partners}
          rowKey={rowKey}
          getRowClassName={() => style.summaryTableRow}
          noAutoPaginate
          isLoading={isLoading}
        />
      </>
    );
  }

  const refetchWithPagination = (pagination?: {
    page: number;
    pageSize: number;
  }) => {
    refetch({
      caseId,
      reportId,
      pagination
    });
  };

  const paginationProps = buildPaginationParams(
    pagination,
    refetchWithPagination
  );

  return (
    <Accordion
      title="Estrutura societária"
      badgeContent={pagination?.totalEntries}
      tooltipText={providerStatus}
      isClosed={isEmpty && !tableMessage}
    >
      <Card small>
        <ReportDynamicTable
          columns={[...columns, ...actionColumn]}
          data={partners}
          rowKey={rowKey}
          isLoading={isLoading}
          pagination={paginationProps}
          emptyText={tableMessage}
          reportAssetName={ReportAssetName.PARTNERS}
        />
      </Card>
    </Accordion>
  );
};

export default PartnersTable;
