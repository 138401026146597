import React from 'react';
import { ItemsPerPageOptions, PaginationProps } from 'types';
import Select from 'ui/Select/Select';
import style from './Pagination.module.scss';

import {
  createPaginationArray,
  calculateBaseIndex,
  calculateEndIndex,
  Marker,
  SELECT_OPTIONS
} from './utils';

import PageButton from './PageButton/PageButton';

const isDifferentThanNumber = (keyCode: number): boolean =>
  keyCode < 48 || keyCode > 58;

const shouldPreventPropagation = (key: string): boolean =>
  key !== 'Backspace' && key !== 'ArrowDown' && key !== 'ArrowUp';

const Pagination = ({
  currentPage,
  hidePagination,
  itemsPerPage,
  onCurrentPageChange,
  onItemsPerPageChange,
  totalEntries,
  totalPages,
  hideSelect
}: PaginationProps) => {
  const pages = createPaginationArray(currentPage, totalPages).map(
    ({ label, incrementValue }) => ({
      label,
      onClick: () => {
        onCurrentPageChange(currentPage + incrementValue);
      },
      isCurrent: label === currentPage.toString()
    })
  );

  const elementsBaseIndex = calculateBaseIndex(currentPage, itemsPerPage);
  const elementsEndIndex = calculateEndIndex(
    currentPage,
    itemsPerPage,
    totalEntries
  );

  const handleItemsPerPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void =>
    onItemsPerPageChange &&
    onItemsPerPageChange(parseInt(e.target.value, 10) as ItemsPerPageOptions);

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (
      isDifferentThanNumber(event.keyCode) &&
      shouldPreventPropagation(event.key)
    ) {
      event.preventDefault();
    }

    if (event.key !== 'Enter') {
      return;
    }

    handleInputEvent(event);
  };

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>): void =>
    handleInputEvent(event);

  const handleInputEvent = ({
    currentTarget
  }: React.SyntheticEvent<HTMLInputElement>): void => {
    const match = currentTarget.value.match(/-?\d+/);
    currentTarget.value = '';

    if (!match) {
      return;
    }

    const pageNumber = parseInt(match[0], 10);
    const pageToUpdate = validatePageNumber(pageNumber);

    onCurrentPageChange(pageToUpdate);
  };

  const validatePageNumber = (pageNumber: number): number => {
    if (pageNumber > totalPages) {
      return currentPage;
    }
    if (pageNumber < 1) {
      return 1;
    }
    return pageNumber;
  };

  return hidePagination ? (
    <></>
  ) : (
    <div className={style.pagination}>
      <p
        className={style.itemsCounter}
      >{`${elementsBaseIndex}-${elementsEndIndex} de ${totalEntries} itens`}</p>

      <PageButton
        onClick={() => onCurrentPageChange(currentPage - 1)}
        isDisabled={currentPage === 1}
        label={Marker.LEFT}
      />

      {pages.map(({ label, onClick, isCurrent }, i) => (
        <PageButton
          onClick={onClick}
          isCurrent={isCurrent}
          key={`pagination-link-${i + label}`}
          label={label}
          borderless={
            label === Marker.DOUBLE_LEFT || label === Marker.DOUBLE_RIGHT
          }
        ></PageButton>
      ))}

      <PageButton
        onClick={() => onCurrentPageChange(currentPage + 1)}
        isDisabled={currentPage === totalPages}
        label={Marker.RIGHT}
      />

      {!hideSelect && (
        <Select
          id="items-per-page"
          isSmall
          dataTestId="items-per-page"
          name="items-per-page"
          onBlur={() => {}}
          onChange={handleItemsPerPageChange}
          options={SELECT_OPTIONS}
          value={itemsPerPage.toString()}
        />
      )}

      <label className={style.inputLabel} htmlFor="go-to-input">
        Ir para:
      </label>

      <input
        max={totalPages}
        disabled={totalPages <= 1}
        min={1}
        className={style.input}
        onKeyDown={handleKeyDown}
        onBlur={handleOnBlur}
        id="go-to-input"
        type="number"
      />
    </div>
  );
};

export default Pagination;
