import React, {
  useState,
  createContext,
  useContext,
  ReactElement
} from 'react';

import { LegalReportQuotation } from 'types';

type State = {
  legalReportQuotationId?: string;
  setLegalReportQuotationId: (id?: string) => void;
  legalReportQuotationData?: LegalReportQuotation;
  setLegalReportQuotationData: (data?: LegalReportQuotation) => void;
};

const LegalReportContext = createContext<State>({
  legalReportQuotationId: undefined,
  setLegalReportQuotationId: (id?: string) => {},
  legalReportQuotationData: undefined,
  setLegalReportQuotationData: (data?: LegalReportQuotation) => {}
});

type Props = {
  defaultData?: LegalReportQuotation;
  children: ReactElement | ReactElement[];
};

const LegalReportProvider = ({ children, defaultData }: Props) => {
  const [legalReportQuotationId, setLegalReportQuotationId] = useState<
    string
  >();
  const [data, setData] = useState<LegalReportQuotation | undefined>(
    defaultData
  );

  return (
    <LegalReportContext.Provider
      value={{
        legalReportQuotationId,
        setLegalReportQuotationId,
        legalReportQuotationData: data,
        setLegalReportQuotationData: setData
      }}
    >
      {children}
    </LegalReportContext.Provider>
  );
};

const useLegalReport = () => {
  return useContext(LegalReportContext);
};

export { useLegalReport, LegalReportProvider };
