import React, { ReactChild, MouseEvent } from 'react';
import cn from 'classnames';

import { Icon } from 'ui';

import style from './AccordionTable.module.scss';

type Props = {
  column: ReactChild;
  isFirstColumn: boolean;
  hasChild: boolean;
  expanded: boolean;
  parentExpanded: boolean;
  handleExpanded: (event: MouseEvent) => void;
};

const FirstCell = ({
  column,
  expanded,
  isFirstColumn,
  hasChild,
  parentExpanded,
  handleExpanded
}: Props) => {
  if (isFirstColumn && parentExpanded && hasChild) {
    return (
      <div className={style.iconWrapper}>
        <div
          data-testid="expand-trigger"
          className={style.expandTrigger}
          onClick={handleExpanded}
        >
          <Icon
            className={cn(style.icon, expanded && style.iconExpanded)}
            name={'arrow-right'}
            fill="#62667A"
            small
          />
        </div>
        {column}
      </div>
    );
  }

  return <>{column}</>;
};

export default FirstCell;
