import { entityRelationshipLabel } from 'domain/entity';
import { buildPaginationParams } from 'domain/pagination';
import { ERROR_MESSAGE, ReportAssetName } from 'domain/reports';
import React from 'react';

import {
  Accordion,
  DropDown,
  Card,
  openSuccessToast,
  openErrorToast,
  DynamicTable,
  TablePositionModifier,
  Stack,
  StackMargin
} from 'ui';
import { applyMask } from 'utils';
import { openReportTab } from 'pages/reports/utils';
import {
  addFamilyMemberToCase,
  createReportFromFamilyMembers,
  fetchReportFamilyMembers,
  fetchReportFamilyMembersSummary
} from 'api';

import {
  ReportFamilyMember,
  ActionName,
  ActionEntity,
  DEFAULT_PAGE_SIZE,
  FIRST_PAGE
} from 'types';
import { FormerValueTooltip, ProviderTooltip, SummaryError } from 'components';
import * as Actions from 'utils/actions';
import compact from 'lodash/compact';

import SummaryTitle from 'pages/reports/SharedComponents/SummaryTitle';

import { useQuery } from 'hooks';
import ReportDynamicTable from '../../ReportDynamicTable/ReportDynamicTable';
import style from './FamilyMembersTable.module.scss';

type Props = {
  caseId: string;
  reportId: string;
  addToCase?: boolean;
  research?: boolean;
  isSummary?: boolean;
  providerStatus?: string;
  tableMessage?: string;
};

const FamilyMembersTable = ({
  isSummary,
  caseId,
  reportId,
  providerStatus,
  addToCase = true,
  research = true,
  tableMessage
}: Props) => {
  const query = isSummary
    ? fetchReportFamilyMembersSummary
    : fetchReportFamilyMembers;
  const {
    data: familyMembers = [],
    isLoading,
    pagination,
    error,
    refetch
  } = useQuery(query, {
    caseId,
    reportId
  });

  if (error) {
    return (
      <SummaryError title="Família" message={ERROR_MESSAGE.familyMember} />
    );
  }

  const handleSearch = (
    familyMemberId: ReportFamilyMember['id']
  ) => async () => {
    try {
      const response = await createReportFromFamilyMembers({
        caseId,
        reportId,
        contentId: familyMemberId
      });
      openSuccessToast('Consulta salva com sucesso!');
      openReportTab(response.data);
    } catch (error) {
      openErrorToast('Houve um erro ao criar a consulta.');
    }
  };

  const handleAddToCase = (
    familyMemberId: ReportFamilyMember['id']
  ) => async () => {
    try {
      await addFamilyMemberToCase({
        caseId,
        reportId,
        contentId: familyMemberId
      });
      openSuccessToast('Indivíduo salvo com sucesso!');
    } catch (error) {
      openErrorToast('Não foi possível salvar o indivíduo ao caso.');
    }
  };

  const isEmpty = familyMembers.length < 1;

  const buildActions = ({
    field: { actions },
    id: yieldId
  }: ReportFamilyMember) => {
    const createReportAction = {
      text: 'Buscar',
      callback: handleSearch(yieldId),
      isDisabled: Actions.isDisabled(actions, ActionName.CREATE_REPORT),
      description: Actions.unavailableReasons(
        actions,
        ActionName.CREATE_REPORT,
        ActionEntity.INDIVIDUAL
      )
    };

    const addToCaseAction = {
      text: 'Adicionar a suspeitos',
      callback: handleAddToCase(yieldId),
      isDisabled: Actions.isDisabled(actions, ActionName.ADD_TO_CASE),
      description: Actions.unavailableReasons(
        actions,
        ActionName.ADD_TO_CASE,
        ActionEntity.INDIVIDUAL
      )
    };

    const allActionsAllowed = compact([
      addToCase && addToCaseAction,
      research && createReportAction
    ]);

    return allActionsAllowed;
  };

  const columns = [
    {
      name: 'Vínculo familiar',
      columnKey: 'relationship',
      render: (item: ReportFamilyMember) => (
        <FormerValueTooltip
          fieldName="relationship"
          fieldValue={entityRelationshipLabel(item.relationship)}
          history={item.field.history}
        />
      )
    },
    {
      name: 'CPF',
      columnKey: 'cpf',
      render: (item: ReportFamilyMember) => (
        <FormerValueTooltip
          fieldName="cpf"
          fieldValue={item.cpf}
          history={item.field.history}
          formatter={value => applyMask('cpf', value)}
        />
      )
    },
    {
      name: 'Nome',
      columnKey: 'name',
      render: (item: ReportFamilyMember) => (
        <FormerValueTooltip
          fieldName="name"
          fieldValue={item.name}
          history={item.field.history}
        />
      )
    }
  ];

  const actionColumn = [
    {
      positionModifier: TablePositionModifier.RIGHT,
      hasActions: true,
      name: '',
      columnKey: 'actions',
      render: (item: ReportFamilyMember) => (
        <>
          <ProviderTooltip
            searchedByHomonym={item.field.searchedByHomonym}
            providers={item.field.providers}
            dataTestId="provider-tooltip"
          />

          {buildActions(item).length > 0 && (
            <DropDown options={buildActions(item)} />
          )}
        </>
      )
    }
  ];

  const rowKey = (item: ReportFamilyMember) => item.id;

  if (isSummary) {
    return (
      <>
        <Stack marginBottom={StackMargin.SMALL}>
          <SummaryTitle>{`Família (${familyMembers.length})`}</SummaryTitle>
        </Stack>
        <DynamicTable
          columns={columns}
          data={familyMembers}
          rowKey={rowKey}
          getRowClassName={() => style.summaryTableRow}
          noAutoPaginate
          isLoading={isLoading}
        />
      </>
    );
  }

  const refetchWithPagination = (pagination?: {
    page: number;
    pageSize: number;
  }) => {
    refetch({
      caseId,
      reportId,
      pagination: {
        page: pagination?.page ?? FIRST_PAGE,
        pageSize: pagination?.pageSize ?? DEFAULT_PAGE_SIZE
      }
    });
  };

  const paginationProps = buildPaginationParams(
    pagination,
    refetchWithPagination
  );

  return (
    <Accordion
      title="Família"
      badgeContent={pagination?.totalEntries}
      tooltipText={providerStatus}
      isClosed={isEmpty && !tableMessage}
    >
      <Card small>
        <ReportDynamicTable
          columns={[...columns, ...actionColumn]}
          data={familyMembers}
          rowKey={rowKey}
          isLoading={isLoading}
          pagination={paginationProps}
          emptyText={tableMessage}
          reportAssetName={ReportAssetName.FAMILY_MEMBERS}
        />
      </Card>
    </Accordion>
  );
};

export default FamilyMembersTable;
