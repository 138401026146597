import { User, UserFormAttributes } from 'types';

import Client from './client';
type ResetPasswordAttributes = {
  email: string;
};

export function inviteUser(invitationData: UserFormAttributes) {
  return Client.post<UserFormAttributes, User>('users', invitationData);
}

export function resetPassword(attrs: ResetPasswordAttributes) {
  return Client.post<ResetPasswordAttributes, ResetPasswordAttributes>(
    'users/reset_password',
    attrs,
    { authenticated: false }
  );
}

export function fetchUsers({ roles = [] }: { roles?: string[] }) {
  return Client.get<User[]>('users', { params: { roles: roles } });
}

export function deleteUser(id: string) {
  return Client.delete(`users/${id}`);
}

export function resendInviteUser(id: string) {
  return Client.post<void, User>(`users/${id}/invitations`);
}

export function updateUser(id: string, data: UserFormAttributes) {
  return Client.patch<UserFormAttributes, User>(`users/${id}`, data);
}
