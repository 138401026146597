import React from 'react';
import cn from 'classnames';

import styles from './Spinner.module.scss';

type Props = {
  color?: 'white' | 'blue';
  dataTestId?: string;
  big?: boolean;
};

const Spinner = ({ color = 'white', big, dataTestId = 'spinner' }: Props) => {
  const classes = cn([
    styles['spinner'],
    color === 'white' && styles.spinnerWhite,
    color === 'blue' && styles.spinnerBlue,
    big && styles['big']
  ]);

  return (
    <div
      className={styles.spinnerWrapper}
      aria-label="loading"
      data-testid={dataTestId}
    >
      <div className={classes} aria-hidden={true}></div>
    </div>
  );
};

export default Spinner;
