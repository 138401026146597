import { Execution, QueryStatus } from 'types';
import { Movement } from './movement';

export enum DocumentType {
  CPF = 'cpf',
  CNPJ = 'cnpj'
}

export enum ExternalLawsuitIndication {
  ACOES_FEDERAIS_ATIVAS = 'acoes_federais_ativas',
  BENS_PASSIVAS = 'bens_passivas',
  BENS_ATIVAS = 'bens_ativas',
  BENS_EXECUCAO = 'bens_execucao'
}

export type ExternalLawsuitMovement = Omit<Movement, 'date'> & {
  date: string | null;
};

export type ExternalLawsuitDocument = {
  type: DocumentType | null;
  value: string | null;
};

export type ExternalLawsuitParty = {
  document: ExternalLawsuitDocument;
  lawyerNames: string[];
  name: string | null;
  type: string | null;
};

export type ExternalLawsuitBasicInformation = {
  class: string | null;
  court: string | null;
  degree: string | null;
  distributionDate: string | null;
  judgeName: string | null;
  judgingBody: string | null;
  lawSegment: string | null;
  originUnit: string | null;
  status: string | null;
  value: number | null;
};

export type RelatedLawsuit = {
  action: string | null;
  court: string | null;
  degree: string | null;
  distributionDate: string | null;
  number: string | null;
};

export type ExternalLawsuitContent = {
  basicInformation: ExternalLawsuitBasicInformation[];
  movements?: ExternalLawsuitMovement[];
  parties: ExternalLawsuitParty[];
  subjects: string[];
  relatedLawsuits: RelatedLawsuit[];
};

export type ExternalLawsuit = {
  id: string;
  caseId: string;
  caseNegotiatorId?: string;
  content: ExternalLawsuitContent;
  isTracked: boolean;
  isMonitored: boolean;
  isAutoUpdated: boolean;
  number: string;
  distributionDate: string | null;
  children: Array<ExternalLawsuit>;
  indications: null | ExternalLawsuitIndication[];
  executions: Array<Execution>;
};

export type ExternalLawsuitSearch = {
  id: string;
  searchTerm: string;
  provider: string;
  status: QueryStatus;
  content: ExternalLawsuitContent;
};

export type ExternalLawsuitSocketResponse = {
  id: string;
  content: null | ExternalLawsuitContent;
  status: QueryStatus;
};
