import React from 'react';

import { IndividualSummaryInfoType, Report } from 'types';

import { Action, can } from 'api';

import { Stack, StackMargin } from 'ui';

import BasicInfo from '../SharedComponents/BasicInfo/BasicInfo';

import OtherInfosSummary from '../SharedComponents/OtherInfosSummary/OtherInfosSummary';
import InstagramBookmarkSummaryTable from '../SharedComponents/IndividualTables/InstagramBookmarkSummaryTable';

import FamilyMembersTable from '../SharedComponents/IndividualTables/FamilyMembersTable';
import PartnershipCompaniesTable from '../SharedComponents/IndividualTables/PartnershipCompaniesTable';

import VehiclesTable from '../SharedComponents/SharedTables/VehiclesTable';
import ElectoralDonationsTable from '../SharedComponents/IndividualTables/ElectoralDonationsTable';
import {
  RealEstateTable,
  AirplanesTable,
  RuralPropertyTable
} from '../SharedComponents/SharedTables';
import CitiesAndLocations from '../SharedComponents/CitiesAndLocations/CitiesAndLocations';
import ExternalLawsuitsSummary from './ExternalLawsuitsSummary';

import style from './Summary.module.scss';

type InfosObj = { [key in IndividualSummaryInfoType]: string };

type Props = {
  caseId: string;
  reportId: string;
  data: Report;
  checkedInfos: InfosObj;
};

const Individual = ({ data, caseId, reportId, checkedInfos }: Props) => {
  const isInfoChecked = (infoKey: IndividualSummaryInfoType) => {
    return checkedInfos[infoKey];
  };

  const negotiatorId = data?.caseNegotiatorId;

  const canUser = {
    showInstagramReport: can(
      Action.CASOS_RELATORIO_PFPJ_VER_PERFIL_INSTAGRAM,
      negotiatorId
    )
  };

  return (
    <>
      <div className={style.information}>
        <div className={style.col}>
          <BasicInfo report={data} isSummary />
        </div>
        <div className={style.col}>
          <OtherInfosSummary
            report={data}
            showPossibleInstagramProfiles={canUser.showInstagramReport}
          />
        </div>
      </div>

      {isInfoChecked(IndividualSummaryInfoType.REPORT_CITIES) && (
        <Stack marginTop={StackMargin.LARGE}>
          <CitiesAndLocations
            reportId={reportId}
            caseId={caseId}
            title="Praças"
            isSummary
          />
        </Stack>
      )}

      {isInfoChecked(IndividualSummaryInfoType.SAVED_POSTS) &&
        canUser.showInstagramReport && (
          <Stack marginTop={StackMargin.LARGE}>
            <InstagramBookmarkSummaryTable
              caseId={caseId}
              reportId={reportId}
              instagramProfileId={data.selectedInstagramProfile?.id}
            />
          </Stack>
        )}

      {isInfoChecked(IndividualSummaryInfoType.FAMILY) && (
        <Stack marginTop={StackMargin.XLARGE}>
          <FamilyMembersTable
            caseId={data.caseId}
            reportId={data.id}
            isSummary
          />
        </Stack>
      )}

      {isInfoChecked(IndividualSummaryInfoType.PARTNER_COMPANY) && (
        <Stack marginTop={StackMargin.XLARGE}>
          <PartnershipCompaniesTable
            caseId={data.caseId}
            reportId={data.id}
            isSummary
          />
        </Stack>
      )}

      {isInfoChecked(IndividualSummaryInfoType.REAL_ESTATE) && (
        <Stack marginTop={StackMargin.XLARGE}>
          <RealEstateTable caseId={data.caseId} reportId={data.id} isSummary />
        </Stack>
      )}

      {isInfoChecked(IndividualSummaryInfoType.VEHICLE) && (
        <Stack marginTop={StackMargin.XLARGE}>
          <VehiclesTable caseId={data.caseId} reportId={data.id} isSummary />
        </Stack>
      )}

      {isInfoChecked(IndividualSummaryInfoType.AIRPLANE) && (
        <Stack marginTop={StackMargin.XLARGE}>
          <AirplanesTable caseId={caseId} reportId={reportId} isSummary />
        </Stack>
      )}

      {isInfoChecked(IndividualSummaryInfoType.RURAL_PROPERTY) && (
        <Stack marginTop={StackMargin.XLARGE}>
          <RuralPropertyTable
            caseId={data.caseId}
            reportId={data.id}
            isSummary
          />
        </Stack>
      )}

      {isInfoChecked(IndividualSummaryInfoType.ELECTORAL_DONATIONS) && (
        <Stack marginTop={StackMargin.XLARGE}>
          <ElectoralDonationsTable
            caseId={caseId}
            reportId={reportId}
            isSummary
          />
        </Stack>
      )}

      {isInfoChecked(IndividualSummaryInfoType.LAWSUITS) && (
        <Stack marginTop={StackMargin.XLARGE}>
          <ExternalLawsuitsSummary caseId={caseId} reportId={reportId} />
        </Stack>
      )}
    </>
  );
};

export default Individual;
