import { FileUpload, FileResponse } from 'types';
import Client from './client';

function uploadFile(
  caseId: string,
  fileData: FormData,
  onUploadProgress: (ev: ProgressEvent) => void
) {
  return Client.post<FormData, FileUpload>(`cases/${caseId}/files`, fileData, {
    onUploadProgress,
    resource: false,
    customHeaders: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

function deleteFile(caseId: string, fileId: string) {
  return Client.delete(`cases/${caseId}/files/${fileId}`);
}

function fetchFiles(caseId: string) {
  return Client.get<FileResponse>(`cases/${caseId}/files`);
}

export { uploadFile, deleteFile, fetchFiles };
