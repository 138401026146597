import React from 'react';
import { pluralize, valueToMillions } from 'utils';
import { useQuery } from 'hooks';
import { fetchSalesFinanceCasesStats } from 'api';
import { Toast, ToastType } from 'ui';
import { Loading } from './LoadingCases';

const regularPlural = (count: number, singular: string) =>
  pluralize(count, singular, `${singular}s`);

const Stats = () => {
  const { data: casesStats, isLoading, error } = useQuery(
    fetchSalesFinanceCasesStats,
    {}
  );

  if (error) {
    return (
      <Toast
        type={ToastType.ERROR}
        message="Ocorreu um erro na obtenção das estatísticas sobre casos."
      />
    );
  }

  if (isLoading || !casesStats) return <Loading />;

  return (
    <>
      <span>{`R$ ${valueToMillions(
        casesStats.totalActiveContractAmount +
          casesStats.totalActiveInvoiceAmount ?? 0
      )} em saldo devedor de ${regularPlural(
        casesStats.activeCasesCount ?? 0,
        'caso'
      )}`}</span>
      <span>{`R$ ${valueToMillions(
        casesStats.totalActiveInvoiceAmount ?? 0
      )} saldo devedor de ${regularPlural(
        casesStats.totalActiveInvoices ?? 0,
        'NF'
      )}`}</span>
      <span>{`R$ ${valueToMillions(
        casesStats.totalActiveContractAmount ?? 0
      )} saldo devedor de ${regularPlural(
        casesStats.totalActiveContracts ?? 0,
        'contrato'
      )}`}</span>
      <span>{`R$ ${valueToMillions(
        casesStats.totalInactiveReclaimingAmount +
          casesStats.inactiveClosedDuplicateAmount ?? 0
      )} recuperados em ${pluralize(
        casesStats.inactiveCasesCount ?? 0,
        'caso encerrado',
        'casos encerrados'
      )}`}</span>
      <span>{`R$ ${valueToMillions(
        casesStats.totalActiveReclaimingAmount +
          casesStats.activeClosedDuplicateAmount ?? 0
      )} recuperados em ${regularPlural(
        casesStats.activeCasesCount ?? 0,
        'caso'
      )} em andamento`}</span>
    </>
  );
};

export default Stats;
