import { InstagramPublication } from 'types';
import Client from './client';

function fetchInstagramLocationPublications({
  caseId,
  reportId,
  instagramLocationId,
  pagination
}: {
  caseId: string;
  reportId: string;
  instagramLocationId: string;
  pagination?: {
    page: number;
    pageSize: number;
  };
}) {
  return Client.get<InstagramPublication[]>(
    `cases/${caseId}/reports/${reportId}/instagram_locations/${instagramLocationId}/publications`,
    {
      params: {
        page: pagination?.page.toString(),
        pageSize: pagination?.pageSize.toString()
      }
    }
  );
}

export { fetchInstagramLocationPublications };
