import { assetTypeLabel, assetValueCents } from 'domain/assets';
import { entityName } from 'domain/entity';
import React from 'react';
import cn from 'classnames';

import { Asset } from 'types';
import { Table } from 'ui';
import { applyMask, handleNullValue } from 'utils';
import { AssetStatusBadges } from 'components';
import style from './AssetsSummaryTable.module.scss';

type Props = {
  assets: Asset[];
  shouldNotRenderGroupChildrenStyle?: boolean;
  isLoading?: boolean;
};

const AssetsSummaryTable = ({
  assets,
  isLoading = false,
  shouldNotRenderGroupChildrenStyle = false
}: Props) => {
  return (
    <Table>
      <Table.Header>
        <Table.Row small className={style.header}>
          <Table.Cell key="name-type" colSpan={2}>
            Nome e tipo do ativo
          </Table.Cell>
          <Table.Cell key="identification">Identificação</Table.Cell>
          <Table.Cell key="value">Valor</Table.Cell>
          <Table.Cell key="entity">Nome ou razão social</Table.Cell>
          <Table.Cell key="description">Descrição</Table.Cell>
          <Table.Cell key="status">Status do ativo</Table.Cell>
        </Table.Row>
      </Table.Header>
      <Table.Body
        isLoading={isLoading}
        isEmpty={assets.length < 1}
        emptyText="Nenhum ativo encontrado."
        columns={7}
      >
        {assets.map((asset, index) => {
          const shouldRenderGroupStyle =
            asset.assetGroupId && !shouldNotRenderGroupChildrenStyle;

          return (
            <Table.Row key={index}>
              {shouldRenderGroupStyle && (
                <Table.Cell
                  className={cn(style.indentCol)}
                  dataTestId="table-cell-identation-col"
                />
              )}
              <Table.Cell
                className={cn(style.column, {
                  [style.assetGroup]: shouldRenderGroupStyle
                })}
                colSpan={shouldRenderGroupStyle ? 1 : 2}
              >
                <div className={style.nameType}>
                  <span>{asset.name}</span>
                  <span className={style.assetType}>
                    {assetTypeLabel(asset)}
                    {asset.isGroup && ` (${asset.groupedAssets?.length ?? 0})`}
                  </span>
                </div>
              </Table.Cell>
              <Table.Cell
                className={cn(style.column, {
                  [style.assetGroup]: shouldRenderGroupStyle
                })}
              >
                {handleNullValue(asset.identification)}
              </Table.Cell>
              <Table.Cell
                className={cn(style.column, {
                  [style.assetGroup]: shouldRenderGroupStyle
                })}
              >
                {handleNullValue(assetValueCents(asset), 'string', value =>
                  applyMask('currency', value)
                )}
              </Table.Cell>
              <Table.Cell
                className={cn(style.column, {
                  [style.assetGroup]: shouldRenderGroupStyle
                })}
              >
                {entityName(asset.entity)}
              </Table.Cell>
              <Table.Cell
                className={cn([
                  style.descriptionCol,
                  style.column,
                  { [style.assetGroup]: shouldRenderGroupStyle }
                ])}
              >
                {handleNullValue(asset.entity.description, 'string')}
              </Table.Cell>
              <Table.Cell
                className={cn(style.column, {
                  [style.assetGroup]: shouldRenderGroupStyle
                })}
              >
                {shouldRenderGroupStyle ? (
                  '-'
                ) : (
                  <AssetStatusBadges asset={asset} />
                )}
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

export default AssetsSummaryTable;
