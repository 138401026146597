import { handleDateFieldChange } from 'domain/forms';
import React, { useEffect, useContext } from 'react';
import { FieldArray, getIn, FormikProps } from 'formik';
import { motion } from 'framer-motion';
import { DateTime } from 'luxon';
import {
  Card,
  FormContainer,
  InputField,
  SelectField,
  DatePickerField,
  TextArea
} from 'ui';
import { City, DebtorFormAttributes, DebtorJudicialRecoveryVote } from 'types';
import { useQueryCall } from 'hooks';
import { fetchCities, can, Action } from 'api';
import { CaseContext } from 'components';
import { stateOptions } from 'utils';
import CreditClasses from './CreditClasses';
import './JudicialRecovery.scss';

type Props = {
  formik: FormikProps<DebtorFormAttributes>;
};

type Option = { value: string; label: string };

function buildCitiesOptions(cities?: City[]): Option[] {
  if (!cities) return [];

  return cities.map(
    (city: City): Option => ({
      label: city.name,
      value: city.id
    })
  );
}

const JudicialRecovery = ({ formik }: Props) => {
  const { data: cities, request: callCities, isLoading } = useQueryCall<
    City[],
    { state: string }
  >(fetchCities);

  const { caseData } = useContext(CaseContext);

  useEffect(() => {
    if (formik.values.judicialRecoveryState) {
      callCities({ state: formik.values.judicialRecoveryState });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.judicialRecoveryState]);

  const negotiatorId = caseData?.negotiator?.id;

  const canUser = {
    addJudicialRecovery: can(
      Action.CASOS_DEVEDOR_RECUPERACAO_JUDICIAL_INCLUIR,
      negotiatorId
    ),
    editJudicialRecovery: can(
      Action.CASOS_DEVEDOR_RECUPERACAO_JUDICIAL_EDITAR,
      negotiatorId
    ),
    showCreditClass: can(
      Action.CASOS_DEVEDOR_CLASSE_DE_CREDITO_CONSULTAR,
      negotiatorId
    )
  };

  const isDisabled = formik.initialValues.orderDate
    ? !canUser.editJudicialRecovery
    : !canUser.addJudicialRecovery;

  return (
    <motion.div
      key="content"
      initial="hide"
      animate="slideDown"
      exit="slideUp"
      variants={{
        hide: { opacity: 0, y: -100 },
        slideDown: { opacity: 1, y: 0 },
        slideUp: { opacity: 0, y: -100 }
      }}
      transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
    >
      <Card
        className="judicial-recovery-card"
        data-testid="judicial-recovery-card"
      >
        <h2 className="title">Recuperação Judicial</h2>

        <FormContainer className="col-4">
          <DatePickerField
            id="order-date"
            name="orderDate"
            title="Data do pedido"
            placeholder="Selecione..."
            value={formik.values.orderDate}
            onChange={handleDateFieldChange(
              formik.setFieldValue,
              formik.setFieldTouched,
              'orderDate'
            )}
            onBlur={formik.handleBlur}
            error={
              getIn(formik.touched, 'orderDate') &&
              getIn(formik.errors, 'orderDate')
            }
            max={DateTime.local().toISO()}
            disabled={isDisabled}
          />

          <DatePickerField
            id="deferral-date"
            name="deferralDate"
            title="Data do deferimento"
            placeholder="Selecione..."
            value={formik.values.deferralDate}
            onChange={handleDateFieldChange(
              formik.setFieldValue,
              formik.setFieldTouched,
              'deferralDate'
            )}
            onBlur={formik.handleBlur}
            error={
              getIn(formik.touched, 'deferralDate') &&
              getIn(formik.errors, 'deferralDate')
            }
            max={DateTime.local().toISO()}
            disabled={isDisabled}
          />

          <SelectField
            name="judicialRecoveryState"
            id="judicial-recovery-state"
            title="Estado"
            value={formik.values.judicialRecoveryState}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            options={stateOptions}
            error={
              getIn(formik.touched, 'judicialRecoveryState') &&
              getIn(formik.errors, 'judicialRecoveryState')
            }
            disabled={isDisabled}
          />

          <SelectField
            name="cityId"
            id="judicial-recovery-city"
            title="Cidade"
            value={formik.values.cityId}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={
              isDisabled || !formik.values.judicialRecoveryState || isLoading
            }
            options={buildCitiesOptions(cities)}
            error={
              getIn(formik.touched, 'cityId') && getIn(formik.errors, 'cityId')
            }
          />

          <InputField
            id="court"
            name="court"
            type="text"
            title="Vara"
            value={formik.values.court}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={
              getIn(formik.touched, 'court') && getIn(formik.errors, 'court')
            }
            disabled={isDisabled}
          />

          <InputField
            id="judge"
            name="judge"
            type="text"
            title="Juiz"
            value={formik.values.judge}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={
              getIn(formik.touched, 'judge') && getIn(formik.errors, 'judge')
            }
            disabled={isDisabled}
          />

          <InputField
            id="judicial-assistant"
            name="judicialAssistant"
            type="text"
            title="Administrador judicial"
            value={formik.values.judicialAssistant}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={
              getIn(formik.touched, 'judicialAssistant') &&
              getIn(formik.errors, 'judicialAssistant')
            }
            disabled={isDisabled}
          />

          <InputField
            id="debtor-lawyer"
            name="debtorLawyer"
            type="text"
            title="Advogado devedor"
            value={formik.values.debtorLawyer}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={
              getIn(formik.touched, 'debtorLawyer') &&
              getIn(formik.errors, 'debtorLawyer')
            }
            disabled={isDisabled}
          />

          <DatePickerField
            id="prj-approval-date"
            name="prjApprovalDate"
            title="Data da aprovação do PRJ"
            placeholder="Selecione..."
            value={formik.values.prjApprovalDate}
            onChange={handleDateFieldChange(
              formik.setFieldValue,
              formik.setFieldTouched,
              'prjApprovalDate'
            )}
            onBlur={formik.handleBlur}
            error={
              getIn(formik.touched, 'prjApprovalDate') &&
              getIn(formik.errors, 'prjApprovalDate')
            }
            max={DateTime.local().toISO()}
            disabled={isDisabled}
          />

          <DatePickerField
            id="homologation-date"
            name="homologationDate"
            title="Data da homologação"
            placeholder="Selecione..."
            value={formik.values.homologationDate}
            onChange={handleDateFieldChange(
              formik.setFieldValue,
              formik.setFieldTouched,
              'homologationDate'
            )}
            onBlur={formik.handleBlur}
            error={
              getIn(formik.touched, 'homologationDate') &&
              getIn(formik.errors, 'homologationDate')
            }
            max={DateTime.local().toISO()}
            disabled={isDisabled}
          />

          <SelectField
            name="vote"
            id={'vote'}
            title="Voto"
            value={formik?.values?.vote ? formik.values.vote : ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              getIn(formik.touched, 'vote') && getIn(formik.errors, 'vote')
            }
            options={[
              { value: DebtorJudicialRecoveryVote.YES, label: 'Sim' },
              {
                value: DebtorJudicialRecoveryVote.NO,
                label: 'Não'
              },
              {
                value: DebtorJudicialRecoveryVote.ABSTENTION,
                label: 'Abstenção'
              },
              {
                value: DebtorJudicialRecoveryVote.DOES_NOT_VOTE,
                label: 'Não vota'
              }
            ]}
            disabled={isDisabled}
          />

          <DatePickerField
            id="final-date"
            name="exitDate"
            title="Data de saída (opcional)"
            placeholder="Selecione..."
            value={formik.values.exitDate}
            onChange={handleDateFieldChange(
              formik.setFieldValue,
              formik.setFieldTouched,
              'exitDate'
            )}
            onBlur={formik.handleBlur}
            error={
              getIn(formik.touched, 'exitDate') &&
              getIn(formik.errors, 'exitDate')
            }
            max={DateTime.local().toISO()}
            disabled={isDisabled}
          />
        </FormContainer>

        {canUser.showCreditClass && (
          <FieldArray name="creditClasses">
            {formikArrayHelpers => (
              <CreditClasses
                formikArrayHelpers={formikArrayHelpers}
                formik={formik}
              />
            )}
          </FieldArray>
        )}

        <FormContainer>
          <TextArea
            id="comments"
            name="comments"
            title="Comentários"
            value={formik.values.comments}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={
              getIn(formik.touched, 'comments') &&
              getIn(formik.errors, 'comments')
            }
            disabled={isDisabled}
          />
        </FormContainer>
      </Card>
    </motion.div>
  );
};

export default JudicialRecovery;
