import {
  AddContentToCase,
  CaseEntity,
  FetchReportTableProps,
  ReportFamilyMember,
  Report
} from 'types';
import Client from './client';

export function fetchReportFamilyMembers({
  caseId,
  reportId,
  pagination
}: FetchReportTableProps) {
  return Client.get<ReportFamilyMember[]>(
    `cases/${caseId}/reports/${reportId}/contents/family_members`,
    {
      params: {
        page: pagination?.page.toString(),
        pageSize: pagination?.pageSize.toString()
      }
    }
  );
}

export function fetchReportFamilyMembersSummary({
  caseId,
  reportId
}: FetchReportTableProps) {
  return Client.get<ReportFamilyMember[]>(
    `cases/${caseId}/reports/${reportId}/contents/family_members/summary`
  );
}

export function addFamilyMemberToCase({
  caseId,
  contentId,
  reportId
}: AddContentToCase) {
  return Client.post<unknown, CaseEntity>(
    `cases/${caseId}/reports/${reportId}/contents/family_members/${contentId}`
  );
}

export function createReportFromFamilyMembers({
  caseId,
  contentId,
  reportId
}: AddContentToCase) {
  return Client.post<unknown, Report>(
    `cases/${caseId}/reports/${reportId}/contents/family_members/${contentId}/reports`
  );
}
