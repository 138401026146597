import { eventTypeLabel } from 'domain/executions';

import React from 'react';

import {
  Accordion,
  TimeLine,
  Card,
  SubTitle,
  Button,
  openErrorToast,
  openSuccessToast,
  Modal,
  Trigger as Toggle
} from 'ui';
import { TimelineHeight } from 'ui/TimeLine/TimeLine';
import { useToggles } from 'hooks';
import orderBy from 'lodash/orderBy';

import { deleteExecutionEvent } from 'api';
import { ExecutionEvent, ExecutionRequestAttributes } from 'types';

import Form from './Form';

import style from './EventTimeline.module.scss';

type Props = ExecutionRequestAttributes & {
  events: ExecutionEvent[];
  isClosed: boolean;
  handleEvents: (events: ExecutionEvent[]) => void;
};

const EventTimeline = ({
  isClosed,
  events,
  handleEvents,
  lawsuitId,
  caseId,
  executionId
}: Props) => {
  const { isOpen, toggle, closeAll } = useToggles<'createEvent'>({
    createEvent: false
  });
  const onRemove = async (eventId: string) => {
    if (!window.confirm('Deseja remover este evento?')) return;

    try {
      await deleteExecutionEvent({
        caseId,
        lawsuitId,
        executionId,
        eventId
      });

      handleEvents(events.filter(event => event.id !== eventId));

      openSuccessToast('Evento removido com sucesso!');
    } catch (e) {
      openErrorToast('Houve um erro ao remover o evento.');
    }
  };

  const onCreate = (event: ExecutionEvent) => {
    closeAll();
    return handleEvents(orderBy([...events, event], 'date'));
  };

  return (
    <>
      <Accordion
        title="execution-events"
        isClosed={isClosed}
        renderHeader={false}
        className={style.accordion}
      >
        <Card dataTestId="execution-events-card" className={style.card}>
          <SubTitle>Timeline da penhora</SubTitle>
          <div className={style.timelineContainer}>
            {events.map(event => (
              <TimeLine
                timelineHeight={TimelineHeight.MEDIUM}
                id={event.id}
                title={eventTypeLabel(event.type)}
                date={event.date}
                key={event.id}
                dataTestId={`event-${event.id}`}
                actions={
                  <Button
                    onClick={() => onRemove(event.id)}
                    className={style.deleteButton}
                    borderless
                    danger
                    dataTestId={`remove-${event.id}`}
                    icon="trash"
                  />
                }
              />
            ))}
          </div>
          <Button
            highlight
            outline
            small
            centered
            onClick={toggle('createEvent')}
          >
            Adicionar evento
          </Button>
        </Card>
      </Accordion>

      <Modal
        title="Adicionar evento"
        isOpen={isOpen.createEvent}
        onClose={toggle('createEvent')}
      >
        <Form
          onCreate={onCreate}
          caseId={caseId}
          lawsuitId={lawsuitId}
          executionId={executionId}
        />
      </Modal>
    </>
  );
};

export default Object.assign(EventTimeline, { Toggle });
