import { ReactElement, useEffect } from 'react';
import { startSessionDaemon } from 'api';

type Props = {
  children: ReactElement;
};

const SessionDaemon = ({ children }: Props) => {
  useEffect(() => {
    const { stopDaemon } = startSessionDaemon();

    return () => {
      stopDaemon();
    };
  });

  return children;
};

export default SessionDaemon;
