import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import * as queryString from 'query-string';

const DEFAULT_PADDING = 120;

type AnchorArguments = {
  isLoading?: boolean;
  anchorId?: string;
  padding?: number;
};

function useAnchor({
  isLoading = false,
  anchorId,
  padding = DEFAULT_PADDING
}: AnchorArguments) {
  useEffect(() => {
    if (!isLoading && !!anchorId) {
      const element = document.getElementById(anchorId);

      element && window.scrollTo(0, element.offsetTop - padding);
    }
  }, [isLoading, anchorId, padding]);
}

type SearchAnchorArguments = {
  isLoading: boolean;
  field: string;
  padding?: number;
};

export function useSearchAnchor({
  isLoading,
  field,
  padding = DEFAULT_PADDING
}: SearchAnchorArguments) {
  const location = useLocation();
  const locationSearch = location.search;

  const anchorId = useMemo(() => {
    const urlParams = queryString.parse(locationSearch);

    return urlParams[field] ? urlParams[field]?.toString() : undefined;
  }, [field, locationSearch]);

  useAnchor({ isLoading, anchorId, padding });
}

type HashAnchorArguments = {
  isLoading?: boolean;
  padding?: number;
};

export function useHashAnchor({
  isLoading,
  padding = DEFAULT_PADDING
}: HashAnchorArguments = {}) {
  const location = useLocation();
  const locationHash = location.hash;

  const anchorId = useMemo(() => {
    return locationHash ? locationHash.replace('#', '') : undefined;
  }, [locationHash]);

  useAnchor({ isLoading, anchorId, padding });
}
