import React, { ReactElement } from 'react';
import { Icon } from 'ui';
import cn from 'classnames';
import style from './Toast.module.scss';

export enum ToastType {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  DEFAULT = 'default'
}

enum IconName {
  'success' = 'checked-rounded',
  'error' = 'close-rounded',
  'info' = 'bookmark',
  'default' = 'warning'
}

enum IconColor {
  'success' = '#388E3C',
  'error' = '#D32F2F',
  'info' = '#303F9F',
  'default' = '#4B4E5D'
}

type Props = {
  type: ToastType;
  closeToast?: () => void;
  message: string | ReactElement;
  icon?: string;
  fill?: string;
};

const Toast = ({ type, closeToast, message, icon, fill }: Props) => {
  const className = cn(style.toast, {
    [style.success]: type === ToastType.SUCCESS,
    [style.error]: type === ToastType.ERROR,
    [style.info]: type === ToastType.INFO,
    [style.toastDefault]: type === ToastType.DEFAULT
  });

  return (
    <div onClick={closeToast} className={className} data-testid="toast">
      <div className={style.icon}>
        <Icon name={icon ?? IconName[type]} fill={fill ?? IconColor[type]} />
      </div>

      <p className={style.message}>{message}</p>
    </div>
  );
};

export default Toast;
