import React from 'react';
import { Stack, StackMargin, Loader } from 'ui';

import style from './Summary.module.scss';

type Props = {
  title: string;
};

const SummaryLoader = ({ title }: Props) => {
  return (
    <Stack marginTop={StackMargin.XLARGE}>
      <h3 className={style.errorTitle}>{title}</h3>
      <Stack marginTop={StackMargin.MEDIUM}>
        <Loader />
      </Stack>
    </Stack>
  );
};

export default SummaryLoader;
