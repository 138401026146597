import React from 'react';

import { Icon } from 'ui';

import style from './TableOrderBy.module.scss';

type Props = {
  children: string;
  onClick: (sortedField: string) => void;
  direction?: 'asc' | 'desc';
  asc?: boolean;
  desc?: boolean;
  dataTestId?: string;
};

const TableOrderBy = ({
  children,
  asc,
  desc,
  onClick,
  direction,
  dataTestId
}: Props) => {
  const getIconName = () => {
    if (asc) {
      return 'sort-asc';
    } else if (desc) {
      return 'sort-desc';
    }

    if (direction) return `sort-${direction}`;

    return 'sort';
  };

  const setIconFill = () => {
    if (asc || desc || direction) {
      return undefined;
    }

    return '#969AAB';
  };

  return (
    <span
      onClick={() => onClick(children)}
      className={style.orderBy}
      data-testid={dataTestId || `orderBy-${getIconName()}`}
    >
      {children}
      {
        <Icon
          name={getIconName()}
          fill={setIconFill()}
          className={style.icon}
        />
      }
    </span>
  );
};

export default TableOrderBy;
