import React, { useContext, useEffect, useRef } from 'react';
import {
  useRouteMatch,
  generatePath,
  useHistory,
  useLocation
} from 'react-router-dom';
import { FormikProps } from 'formik';
import dropRight from 'lodash/dropRight';
import last from 'lodash/last';
import compact from 'lodash/compact';

import { OnSubmit, CaseFormAttributes, Feature, CaseType } from 'types';
import { CaseContext, Notifications, usePaginationContext } from 'components';

import { Header, Title, Button, Link, Tabs, TabContent, Modal } from 'ui';
import { TabContent as TabContentProps } from 'types/tabContent';
import { useFeatureFlag } from 'hooks/features';
import { useToggles } from 'hooks';
import { LocationState } from 'types';
import Form from './Form';
import Resources from './Resources/Resources';
import Files from './Files/Files';
import LawsuitsRoutes from './InternalLawsuits';
import DebtorsRoutes from './Debtors';
import Events from './Events/EventsRoute';
import {
  canShowSaveButton,
  buildCanShowTab,
  buildCanUser
} from './caseTabsPermissions';

import { default as SummaryForm } from './Summary/Form/Form';

import style from './CaseTab.module.scss';

type Props = {
  title?: string;
  caseId?: string;
  onSubmit: OnSubmit<CaseFormAttributes>;
  onDelete?: (caseId: string) => void;
  isSubmiting: boolean;
  caseType: CaseType;
};

const CaseTabs = ({
  title = 'Novo caso',
  caseId,
  onSubmit,
  onDelete,
  isSubmiting,
  caseType
}: Props) => {
  const history = useHistory();
  const location = useLocation();
  const { changeCaseId } = usePaginationContext();
  const { caseData } = useContext(CaseContext);

  useEffect(() => {
    changeCaseId(caseId ?? '');
  }, [caseId, changeCaseId]);

  const { current: listCasesByType } = useRef(`/cases/${caseType}`);

  const returnTo = (location?.state as LocationState | undefined)?.returnTo;

  const match: {
    params: { caseId: string; tab: string; action: string };
    isExact: boolean;
    path: string;
    url: string;
  } = useRouteMatch();
  const [isLawsuitsEnabled] = useFeatureFlag(Feature.CASE_LAWSUITS);
  const [isUploadEnabled] = useFeatureFlag(Feature.FILE_UPLOADS);
  const [isEventsEnabled] = useFeatureFlag(Feature.CASE_EVENTS);
  const [isDebtorEnabled] = useFeatureFlag(Feature.CASE_DEBTORS);
  const [isSummaryEnabled] = useFeatureFlag(Feature.CASE_SUMMARY);
  const [isNotificationsEnabled] = useFeatureFlag(Feature.NOTIFICATIONS);
  const { isOpen, toggle, closeAll } = useToggles<'summary'>({
    summary: false
  });

  const formRef = useRef<FormikProps<CaseFormAttributes>>(null);

  const handleSubmit = () => {
    if (formRef.current) return formRef.current.handleSubmit();
  };

  const handleRedirectSummary = (path: string) => {
    closeAll();
    return history.push(path);
  };

  const saveButton = (
    <Button
      highlight
      onClick={handleSubmit}
      type="submit"
      disabled={isSubmiting}
    >
      Salvar
    </Button>
  );

  const removeButton = (
    <Button
      outline
      danger
      onClick={() => {
        onDelete && caseId && onDelete(caseId);
      }}
      type="button"
    >
      Excluir caso
    </Button>
  );

  const summaryButton = (
    <Button outline highlight onClick={toggle('summary')} type="button">
      Ver resumo
    </Button>
  );

  const canUser = buildCanUser(caseData?.negotiator?.id);
  const canShowTab = buildCanShowTab(canUser, caseType, caseId);

  if (compact(Object.values(canShowTab)).length === 0) {
    window.location.replace('/permission_denied');
    return <></>;
  }

  const actions = (
    <>
      {caseId && canUser.removeCase && removeButton}
      {isSummaryEnabled && canUser.showSummary && summaryButton}
      {canShowSaveButton(canUser, caseId) && saveButton}
    </>
  );

  const linkTo = (newTab: TabContentProps) => {
    if (!newTab.path) return;

    return generatePath(`${match.path}`, {
      caseId: match.params.caseId,
      type: caseType,
      tab: newTab.path
    });
  };

  const tabContents = [
    canShowTab.basicInformation && (
      <TabContent
        title="Informações básicas"
        path="basic-information"
        dataTestId="basic-information-tab"
        key="basic-information-tab"
      >
        <Form
          id="case-creation-form"
          caseType={caseType}
          formRef={formRef}
          onSubmit={onSubmit}
        />
      </TabContent>
    ),

    caseId && isDebtorEnabled && canShowTab.debtors && (
      <TabContent
        title="Devedores e contratos"
        path="debtors"
        dataTestId="debtors-tab"
        key="debtors-tab"
        full
      >
        <DebtorsRoutes />
      </TabContent>
    ),

    caseId && canShowTab.resources && (
      <TabContent
        title="Rastreamento"
        className="case-resources"
        path="resources"
        dataTestId="resources-tab"
        key="resources-tab"
      >
        <Resources caseId={caseId} />
      </TabContent>
    ),

    caseId && isEventsEnabled && canShowTab.events && (
      <TabContent
        title="Acompanhamento"
        className="case-events"
        path="events"
        dataTestId="events-tab"
        key="events-tab"
        full
      >
        <Events />
      </TabContent>
    ),

    caseId && isLawsuitsEnabled && canShowTab.lawsuits && (
      <TabContent
        title="Processos judiciais "
        className="case-lawsuits"
        path="internal_lawsuits"
        dataTestId="lawsuits-tab"
        key="lawsuits-tab"
        full
      >
        <LawsuitsRoutes />
      </TabContent>
    ),

    caseId && isUploadEnabled && canShowTab.files && (
      <TabContent
        title="Arquivos"
        className="case-files"
        path="files"
        dataTestId="files-tab"
        key="files-tab"
      >
        <Files caseId={caseId} />
      </TabContent>
    )
  ];

  const allowedTabContents = compact(tabContents);

  return (
    <React.Fragment>
      <Header
        actionsComponent={
          (!match.params.tab || match.params.tab === 'basic-information') &&
          actions
        }
      >
        <Link
          to={{
            pathname: last(returnTo) ?? listCasesByType,
            state: { returnTo: dropRight(returnTo, 1) }
          }}
          iconName="arrow-left"
        >
          Voltar
        </Link>
        <div className={style.headerWrapper}>
          <Title className={style.title}>{title}</Title>
          {isNotificationsEnabled && caseId && (
            <Notifications caseId={caseId} />
          )}
        </div>
      </Header>
      <Tabs linkTab={linkTo} defaultPath={match.params.tab}>
        {allowedTabContents.length === 0 ? <div /> : allowedTabContents}
      </Tabs>
      {caseId && (
        <Modal isOpen={isOpen.summary} onClose={toggle('summary')}>
          <SummaryForm caseId={caseId} onConfirm={handleRedirectSummary} />
        </Modal>
      )}
    </React.Fragment>
  );
};

export default CaseTabs;
