import {
  ReportRuralProperty,
  FetchReportTableProps,
  AddContentToCase,
  AssetAttributes
} from 'types';

import Client from './client';

export function fetchReportRuralProperties({
  caseId,
  reportId,
  pagination
}: FetchReportTableProps) {
  return Client.get<ReportRuralProperty[]>(
    `cases/${caseId}/reports/${reportId}/contents/rural_properties`,
    {
      params: {
        page: pagination?.page.toString(),
        pageSize: pagination?.pageSize.toString()
      }
    }
  );
}

export function fetchReportRuralPropertiesSummary({
  caseId,
  reportId
}: FetchReportTableProps) {
  return Client.get<ReportRuralProperty[]>(
    `cases/${caseId}/reports/${reportId}/contents/rural_properties/summary`
  );
}

export function addRuralPropertiesToCase({
  caseId,
  reportId,
  contentId
}: AddContentToCase) {
  return Client.post<unknown, AssetAttributes>(
    `cases/${caseId}/reports/${reportId}/contents/rural_properties/${contentId}`
  );
}
