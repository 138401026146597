import { getFormikError } from 'domain/forms';
import { situationOptions, covenantOptions } from 'domain/invoice';
import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import * as Yup from 'yup';
import { FormikHelpers, useFormik } from 'formik';

import { FileField, useFormDirty, CaseContext } from 'components';

import {
  Card,
  FormContainer,
  InputField,
  SelectField,
  Button,
  openSuccessToast,
  openErrorToast,
  TextArea
} from 'ui';
import { Invoice, OnSubmit, InvoiceFormAttributes } from 'types';
import { Action, can, submitInvoice, fetchCovenants } from 'api';
import { useQuery } from 'hooks';

import DebtorInfo from '../../DebtorInfo';

import style from './InvoiceCard.module.scss';

const validationSchema = Yup.object().shape({
  number: Yup.string()
    .matches(/^[\w-/\s]+$/, 'Deve conter somente números ou letras')
    .required('Campo obrigatório'),
  valueCents: Yup.number()
    .min(0, 'Dever ser maior que 0')
    .required('Campo obrigatório'),
  situation: Yup.string().required('Campo obrigatório'),
  debtorId: Yup.string().required('Campo obrigatório'),
  covenantId: Yup.string().required('Campo obrigatório'),
  comment: Yup.string()
    .notRequired()
    .nullable(),
  fileUploadIds: Yup.array()
    .of(Yup.string())
    .notRequired()
});

const initialValues: InvoiceFormAttributes = {
  number: '',
  valueCents: '',
  situation: '',
  debtorId: '',
  covenantId: '',
  comment: '',
  fileUploadIds: []
};

type Props = {
  caseId: string;
  invoice?: Invoice;
  setInvoice: React.Dispatch<React.SetStateAction<Invoice | undefined>>;
  isLoading: boolean;
};

const InvoiceCard = ({ caseId, invoice, setInvoice, isLoading }: Props) => {
  const {
    data: covenants = [],
    error: covenantError,
    isLoading: isLoadingCovenants
  } = useQuery(fetchCovenants, undefined);

  const history = useHistory();
  const { setDirtiness } = useFormDirty();
  const { caseData } = useContext(CaseContext);

  const handleSubmit: OnSubmit<InvoiceFormAttributes> = async (
    values: InvoiceFormAttributes,
    formikHelpers: FormikHelpers<InvoiceFormAttributes>
  ) => {
    try {
      const response = await submitInvoice({ caseId, values, invoice });

      setInvoice(response?.data);

      openSuccessToast('Nota fiscal salva com sucesso!');

      setDirtiness({
        formKey: 'invoice',
        isDirty: false,
        formName: 'Nota fiscal'
      });

      history.push(`/cases/${caseId}/debtors/invoice/${response?.data.id}`);
    } catch (error) {
      formikHelpers.setErrors(error.errors);
      openErrorToast('Houve um erro ao salvar a nota fiscal');
    }
  };

  const buildInitialValues = (invoice?: Invoice) => {
    if (!invoice) return initialValues;

    return {
      ...invoice,
      valueCents: invoice.valueCents.toString(),
      debtorId: invoice.debtor.id,
      covenantId: invoice.covenant.id,
      fileUploadIds: invoice.fileUploads.map(file => file.id)
    };
  };

  const formik = useFormik({
    initialValues: buildInitialValues(invoice),
    onSubmit: handleSubmit,
    validationSchema: validationSchema,
    enableReinitialize: true
  });

  const getError = getFormikError(formik);

  if (covenantError) {
    openErrorToast('Houve um erro ao listar convênios!');
  }

  useEffect(() => {
    setDirtiness({
      formKey: 'invoice',
      isDirty: formik.dirty,
      formName: 'Nota fiscal'
    });
  }, [formik.dirty, setDirtiness]);

  const negotiatorId = caseData?.negotiator?.id;

  const canUser = {
    addInvoice: can(Action.CASOS_NOTA_FISCAL_INCLUIR, negotiatorId),
    editInvoice: can(Action.CASOS_NOTA_FISCAL_EDITAR, negotiatorId),
    showFiles: can(
      Action.CASOS_ARQUIVOS_DA_NOTA_FISCAL_CONSULTAR,
      negotiatorId
    ),
    addFiles: can(Action.CASOS_ARQUIVOS_DA_NOTA_FISCAL_INCLUIR, negotiatorId),
    deleteFiles: can(Action.CASOS_ARQUIVOS_DA_NOTA_FISCAL_EXCLUIR, negotiatorId)
  };

  const isDisabled = invoice ? !canUser.editInvoice : !canUser.addInvoice;

  const canShowSaveButton = invoice
    ? canUser.editInvoice || canUser.addFiles || canUser.deleteFiles
    : canUser.addInvoice;

  return (
    <Card className={style.card}>
      <div>
        {canShowSaveButton && (
          <Button
            className={style.submitButton}
            highlight
            small
            onClick={formik.handleSubmit}
            disabled={isDisabled || !formik.dirty}
          >
            {invoice ? 'Salvar' : 'Criar'}
          </Button>
        )}

        <DebtorInfo
          formik={formik}
          caseId={caseId}
          isLoading={isLoading}
          isDisabled={isDisabled}
        />

        <div>
          <h2 className={style.invoiceLabel}>Informações da Nota fiscal</h2>
          <FormContainer className="col-4">
            <SelectField
              disabled={isDisabled || !formik.values.debtorId}
              error={getError('covenantId')}
              id="covenantId"
              isLoading={isLoadingCovenants}
              name="covenantId"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              options={covenantOptions(covenants)}
              optional
              title="Convênio"
              value={formik.values.covenantId}
            />

            <InputField
              disabled={isDisabled || !formik.values.debtorId}
              error={getError('number')}
              id="number"
              isLoading={isLoading}
              name="number"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              title="Número da nota fiscal"
              type="text"
              value={formik.values.number}
            />

            <InputField
              disabled={isDisabled || !formik.values.debtorId}
              error={getError('valueCents')}
              id="valueCents"
              isLoading={isLoading}
              name="valueCents"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              title="Valor da nota fiscal"
              type="currency"
              value={formik.values.valueCents.toString()}
            />

            <SelectField
              disabled={isDisabled || !formik.values.debtorId}
              error={getError('situation')}
              id="situation"
              isLoading={isLoading}
              name="situation"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              options={situationOptions()}
              optional
              title="Situação"
              value={formik.values.situation}
            />
          </FormContainer>
          <FormContainer>
            <TextArea
              id="comment"
              isLoading={isLoading}
              name="comment"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              title="Comentários"
              value={formik.values.comment ?? ''}
              disabled={isDisabled}
            />
          </FormContainer>
        </div>
        {canUser.showFiles && (
          <div>
            <h2 className={style.label}>Arquivos</h2>
            <FileField
              showMode={!formik.values.debtorId}
              caseId={caseId}
              name="fileUploadIds"
              id="fileUploadIds"
              onUploadSuccess={ids => {
                formik.setFieldValue('fileUploadIds', ids);
              }}
              initialFiles={invoice?.fileUploads}
              dataTestId="contract-file-upload"
              showAdd={canUser.addFiles}
              showDelete={canUser.deleteFiles}
            />
          </div>
        )}
      </div>
    </Card>
  );
};

export default InvoiceCard;
