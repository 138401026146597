import React, { ReactElement, useEffect, useState } from 'react';
import cn from 'classnames';
import { Icon, Table } from 'ui';

import style from './ExpandableRowItem.module.scss';

type Props = {
  children: ReactElement | ReactElement[];
  itemName: string;
  itemAmount: string;
  columnsSizeStyle?: string;
  firstColumnStyle?: string;
  shouldExpandAll?: boolean;
};

const ExpandableRowItem = ({
  children,
  itemName,
  itemAmount,
  columnsSizeStyle,
  firstColumnStyle,
  shouldExpandAll
}: Props) => {
  const [isOpen, setIsOpen] = useState(shouldExpandAll || false);

  useEffect(() => {
    shouldExpandAll && setIsOpen(true);
  }, [shouldExpandAll]);

  return (
    <>
      <Table.Row
        className={cn(style.row, columnsSizeStyle)}
        onClick={() => setIsOpen(prev => !prev)}
      >
        <Table.Cell>
          <div className={cn(style.firstColumn, firstColumnStyle)}>
            <Icon
              className={cn(style.accordionIcon, {
                [style.accordionIconOpen]: isOpen
              })}
              name="arrow-right"
              fill="#62667A"
              small
            />
            {itemName}
          </div>
        </Table.Cell>
        <Table.Cell>{itemAmount}</Table.Cell>
      </Table.Row>
      {isOpen && children}
    </>
  );
};

export default ExpandableRowItem;
