import { providerName } from 'domain/providers';
import React from 'react';

import cn from 'classnames';
import { Modal, Table, Link, Stack, StackMargin } from 'ui';

import { applyMask } from 'utils';

import { DivergentDataProvider } from 'types';

import style from './ModalDivergentDataProvider.module.scss';

const MAIN_PROVIDER = 'serpro';

interface MaskInterface {
  [key: string]: string;
}

const mask: MaskInterface = {
  CNPJ: 'cnpj',
  CPF: 'cpf',
  'Data da situação cadastral': 'isoDate',
  'Data que entrou em situação especial': 'isoDate',
  'Data de abertura': 'isoDate',
  'Data de nascimento': 'isoDate',
  Telefones: 'phone'
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  divergentDataProvider?: DivergentDataProvider;
};

const renderList = (list: string[], label: string, isLink = true) => (
  <ul>
    {list.map((item: string, index: number) => (
      <li key={index}>
        <Stack marginBottom={StackMargin.SMALL}>
          <Link
            underline={isLink}
            disabled={!isLink}
            external
            highlight
            href={item}
          >
            {applyMask(mask[label], item)}
          </Link>
        </Stack>
      </li>
    ))}
  </ul>
);

const ModalDivergentDataProvider = ({
  isOpen,
  onClose,
  divergentDataProvider
}: Props) => {
  const rowClass = (provider: string) =>
    cn({ [style.activeProviderRow]: provider === MAIN_PROVIDER });

  return (
    <Modal
      medium
      title="Dados divergentes dos provedores"
      isOpen={isOpen}
      onClose={onClose}
    >
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.Cell>Provedor</Table.Cell>
            <Table.Cell>{divergentDataProvider?.label}</Table.Cell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {divergentDataProvider?.divergentData?.map((data, index) => {
            const provider = data.providers[0];
            const fieldValue = data.fieldValue.toString();
            const isArray = Array.isArray(data.fieldValue);
            const list = fieldValue.split(',');
            const label = divergentDataProvider?.label;

            return (
              <Table.Row key={index} className={rowClass(provider)}>
                <Table.Cell>{providerName(provider)}</Table.Cell>
                <Table.Cell>
                  {isArray
                    ? renderList(list, label, divergentDataProvider?.isLink)
                    : applyMask(mask[label], fieldValue)}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </Modal>
  );
};

export default ModalDivergentDataProvider;
