import { useState, useEffect, useCallback } from 'react';
import { featureIsEnabled } from 'api';
import { Feature } from 'types';

export function useFeatureFlag(featureName: Feature) {
  const [isEnabled, setEnabled] = useState(false);

  const checkFeatureEnabled = useCallback(async () => {
    try {
      const response = await featureIsEnabled(featureName);

      setEnabled(response);
    } catch (error) {
      console.error(error);
    }
  }, [featureName]);

  useEffect(() => {
    checkFeatureEnabled();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [isEnabled];
}
