import {
  assetOriginOptions,
  assetRealEstateTypeOptions,
  ASSET_IDENTIFICATION_DICTIONARY,
  occupancyOptions
} from 'domain/assets';
import { buildCitiesOptions } from 'domain/cities';
import React, { useEffect } from 'react';
import { FormikProps, getIn } from 'formik';
import { City, AssetAttributes, AssetType, AssetRealEstate } from 'types';
import { FormContainer, InputField, SelectField, Toggle } from 'ui';
import { stateOptions } from 'utils';
import { useQueryCall } from 'hooks';
import { fetchCities } from 'api';

import styles from '../Form.module.scss';

type Props = {
  assetRealEstate: AssetRealEstate | null;
  isLoading: boolean;
  formik: FormikProps<AssetAttributes>;
  identificationLocked: boolean;
  disabled?: boolean;
};

const RealEstateFields = ({
  assetRealEstate,
  isLoading,
  formik,
  identificationLocked,
  disabled
}: Props) => {
  const { data: cities, request: callCities } = useQueryCall<
    City[],
    { state: string }
  >(fetchCities);

  const selectedState = formik?.values.assetRealEstate?.state;

  useEffect(() => {
    if (selectedState) {
      callCities({ state: selectedState });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedState]);

  return (
    <>
      <h3 className={styles.title}>Informações do Imóvel</h3>

      <InputField
        type="text"
        id="name"
        name="name"
        title="Nome do ativo"
        isLoading={isLoading}
        value={formik.values.name}
        error={getIn(formik.touched, 'name') && getIn(formik.errors, 'name')}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        disabled={disabled}
      />

      <InputField
        type="text"
        id="assetRealEstate.address"
        name="assetRealEstate.address"
        title="Endereço (opcional)"
        isLoading={isLoading}
        value={formik.values.assetRealEstate?.address}
        error={
          getIn(formik.touched, 'assetRealEstate.address') &&
          getIn(formik.errors, 'assetRealEstate.address')
        }
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        showMode={assetRealEstate ? !assetRealEstate.address.isEditable : false}
        disabled={disabled}
      />

      <FormContainer className={'col-2'}>
        <InputField
          type="text"
          id="assetRealEstate.number"
          name="assetRealEstate.number"
          title="Número (opcional)"
          isLoading={isLoading}
          value={formik.values.assetRealEstate?.number?.toString()}
          error={
            getIn(formik.touched, 'assetRealEstate.number') &&
            getIn(formik.errors, 'assetRealEstate.number')
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          showMode={
            assetRealEstate ? !assetRealEstate.number.isEditable : false
          }
          disabled={disabled}
        />

        <InputField
          type="cep"
          id="assetRealEstate.postalCode"
          name="assetRealEstate.postalCode"
          title="CEP (opcional)"
          isLoading={isLoading}
          value={formik.values.assetRealEstate?.postalCode?.toString()}
          error={
            getIn(formik.touched, 'assetRealEstate.postalCode') &&
            getIn(formik.errors, 'assetRealEstate.postalCode')
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          showMode={
            assetRealEstate ? !assetRealEstate.postalCode.isEditable : false
          }
          disabled={disabled}
        />

        <SelectField
          name="assetRealEstate.state"
          id={'assetRealEstate.state'}
          title="Estado (opcional)"
          value={formik.values.assetRealEstate?.state ?? ''}
          onChange={(event: React.ChangeEvent<{ value: string }>) => {
            event.preventDefault();
            formik.handleChange(event);
            callCities({ state: event.currentTarget.value });
          }}
          onBlur={formik.handleBlur}
          options={stateOptions}
          error={
            getIn(formik.touched, 'assetRealEstate.state') &&
            getIn(formik.errors, 'assetRealEstate.state')
          }
          showMode={assetRealEstate ? !assetRealEstate.state.isEditable : false}
          disabled={disabled}
        />

        <SelectField
          name="assetRealEstate.cityId"
          id={'assetRealEstate.cityId'}
          title="Município (opcional)"
          value={formik.values.assetRealEstate?.cityId ?? ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={!selectedState || isLoading || disabled}
          options={buildCitiesOptions(cities)}
          error={
            getIn(formik.touched, 'assetRealEstate.cityId') &&
            getIn(formik.errors, 'assetRealEstate.cityId')
          }
          showMode={assetRealEstate ? !assetRealEstate.city.isEditable : false}
        />

        <InputField
          type="text"
          id="assetRealEstate.landArea"
          name="assetRealEstate.landArea"
          title="Área do terreno m² (opcional)"
          isLoading={isLoading}
          value={formik.values.assetRealEstate?.landArea?.toString()}
          error={
            getIn(formik.touched, 'assetRealEstate.landArea') &&
            getIn(formik.errors, 'assetRealEstate.landArea')
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          showMode={
            assetRealEstate ? !assetRealEstate.landArea.isEditable : false
          }
          disabled={disabled}
        />

        <InputField
          type="text"
          id="assetRealEstate.constructedArea"
          name="assetRealEstate.constructedArea"
          title="Área construída m² (opcional)"
          isLoading={isLoading}
          value={formik.values.assetRealEstate?.constructedArea?.toString()}
          error={
            getIn(formik.touched, 'assetRealEstate.constructedArea') &&
            getIn(formik.errors, 'assetRealEstate.constructedArea')
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          showMode={
            assetRealEstate
              ? !assetRealEstate.constructedArea.isEditable
              : false
          }
          disabled={disabled}
        />

        <Toggle
          checked={formik.values?.assetRealEstate?.occupied?.toString()}
          name="assetRealEstate.occupied"
          onChange={event =>
            formik.setFieldValue(
              'assetRealEstate.occupied',
              event.target.value === 'true'
            )
          }
          isLoading={isLoading}
          options={occupancyOptions}
          title="Ocupação (opcional)"
          showMode={
            assetRealEstate ? !assetRealEstate.occupied.isEditable : false
          }
          disabled={disabled}
        />

        <SelectField
          id="assetRealEstate.origin"
          name="assetRealEstate.origin"
          value={formik.values?.assetRealEstate?.origin ?? ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isLoading={isLoading}
          options={assetOriginOptions()}
          title="Origem (opcional)"
          showMode={
            assetRealEstate ? !assetRealEstate.origin.isEditable : false
          }
          optional
          disabled={disabled}
        />

        <SelectField
          id="assetRealEstate.type"
          name="assetRealEstate.type"
          value={formik.values?.assetRealEstate?.type ?? ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isLoading={isLoading}
          options={assetRealEstateTypeOptions()}
          title="Subtipo (opcional)"
          showMode={assetRealEstate ? !assetRealEstate.type.isEditable : false}
          optional
          disabled={disabled}
        />

        <InputField
          type="text"
          id="identification"
          name="identification"
          title={`${
            ASSET_IDENTIFICATION_DICTIONARY[AssetType.REAL_ESTATE]
          } (opcional)`}
          isLoading={isLoading}
          value={formik.values?.identification}
          error={
            getIn(formik.touched, 'identification') &&
            getIn(formik.errors, 'identification')
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          showMode={identificationLocked}
          disabled={disabled}
        />
      </FormContainer>
    </>
  );
};

export default RealEstateFields;
