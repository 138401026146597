import { tableColapseOptions } from 'domain/transitions';
import { useToggles } from 'hooks';
import React, { useState } from 'react';
import { ReportCaseCity } from 'types';
import { Icon, Link, Table } from 'ui';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import styles from './CityRow.module.scss';
import OriginRow from './OriginRow/OriginRow';

type Props = {
  city: ReportCaseCity;
  isSummary?: boolean;
  shouldShowDetails?: boolean;
};

const DEFAULT_PAGE_SIZE = 10;

const CityRow = ({
  city,
  isSummary = false,
  shouldShowDetails = false
}: Props) => {
  const { isOpen, toggle } = useToggles<'originsRow'>({ originsRow: false });

  const cityOrigins = city.origin?.entries ?? [];
  const useStateOrigins = isSummary
    ? cityOrigins
    : cityOrigins.slice(0, DEFAULT_PAGE_SIZE) ?? [];
  const [origins, setOrigins] = useState(useStateOrigins);
  const [totalLoaded, setTotalLoaded] = useState(DEFAULT_PAGE_SIZE);
  const haveToShowLoadMoreLink =
    cityOrigins.length > 10 &&
    origins.length !== cityOrigins.length &&
    !isSummary;

  const handleShowMoreOrigins = () => {
    const newPageSize = totalLoaded + DEFAULT_PAGE_SIZE;
    const newOrigins = cityOrigins.slice(0, newPageSize);

    setOrigins(newOrigins);
    setTotalLoaded(newPageSize);
  };

  const getTableCellProps = (isSummary?: boolean) => {
    const defaultProps = {
      colSpan: 2,
      dataTestId: 'city-cell-summary'
    };

    if (isSummary) {
      return defaultProps;
    }

    return {
      ...defaultProps,
      className: styles.cityCell,
      dataTestId: 'city-cell',
      onClick: toggle('originsRow')
    };
  };

  return (
    <>
      <motion.tr className={styles.cityRow} {...tableColapseOptions}>
        <Table.Cell {...getTableCellProps(isSummary)}>
          <div className={styles.cityCellIcon}>
            {!isSummary && (
              <Icon
                name="arrow-right"
                fill="#62667A"
                small
                className={cn({
                  [styles.cityCellIconClose]: !isOpen.originsRow,
                  [styles.cityCellIconOpen]: isOpen.originsRow
                })}
                dataTestId="collapse-city-icon"
              />
            )}
            {`${city.name} (${city.origin?.count.toString()})`}
          </div>
        </Table.Cell>
      </motion.tr>
      <AnimatePresence initial={false}>
        {(isOpen.originsRow || shouldShowDetails) && (
          <>
            {origins.map((origin, index) => (
              <OriginRow key={`${index}-${city.id}`} origin={origin} />
            ))}
            {haveToShowLoadMoreLink && (
              <motion.tr {...tableColapseOptions}>
                <Table.Cell
                  colSpan={4}
                  textAlign="center"
                  dataTestId="show-load-more-link"
                >
                  <Link
                    highlight
                    className={styles.loadMoreOrigins}
                    onClick={handleShowMoreOrigins}
                  >
                    Carregar mais ({totalLoaded} de {cityOrigins.length})
                  </Link>
                </Table.Cell>
              </motion.tr>
            )}
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default CityRow;
