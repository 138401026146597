import { Action, can } from 'api/permissions';
import { CaseContext, LawsuitCard } from 'components';
import React, { useContext } from 'react';
import { InternalLawsuit } from 'types';
import { Link } from 'ui';
import styles from './InternalLawsuitCard.module.scss';

type Props = {
  dataTestId?: string;
  caseId: string;
  lawsuit: InternalLawsuit;
};

function shouldSlice<T>(items: T[]): boolean {
  return items.length > 5;
}

function sliceLinked<T>(items: T[]): T[] {
  if (shouldSlice(items)) {
    return items.slice(0, 4);
  }

  return items;
}

const InternalLawsuitCard = ({
  lawsuit,
  dataTestId = 'card-item',
  caseId
}: Props) => {
  const { caseData } = useContext(CaseContext);

  const canShowInternalLawsuit = can(
    Action.CASOS_PROCESSOS_JUDICIAIS_VER_PROCESSO,
    caseData?.negotiator?.id
  );

  const canShowContract = can(
    Action.CASOS_PROCESSOS_JUDICIAIS_VER_CONTRATO,
    caseData?.negotiator?.id
  );

  return (
    <div>
      <LawsuitCard
        lawsuit={lawsuit}
        dataTestId={dataTestId}
        title={
          <LawsuitCard.TriggerTitle
            lawsuit={lawsuit}
            url={`/cases/${caseId}/internal_lawsuits/${lawsuit.id}`}
            disabled={!canShowInternalLawsuit}
          />
        }
      />
      <div className={styles.relatedCard}>
        <div className={styles.relatedColumn}>
          <span className={styles.listHeader}>Contratos Vinculados:</span>
          <ul>
            {sliceLinked(lawsuit.contracts).map(contract => (
              <li
                className={styles.listItem}
                key={contract.id}
                data-testid="linked-contract-item"
              >
                <Link
                  href={`/cases/${contract.caseId}/debtors/contract/${contract.id}`}
                  target="_blank"
                  highlight
                  underline
                  disabled={!canShowContract}
                >
                  {`Contrato ${contract.number}`}
                </Link>
              </li>
            ))}

            {shouldSlice(lawsuit.contracts) && (
              <li
                className={styles.listItem}
                key="see-all"
                data-testid="see-all-linked-contracts"
              >
                <Link
                  highlight
                  underline
                  target="_blank"
                  href={`/cases/${caseId}/internal_lawsuits/${lawsuit.id}#contracts`}
                >
                  {`Ver todos (${lawsuit.contracts.length})`}
                </Link>
              </li>
            )}
          </ul>
        </div>
        <div className={styles.relatedColumn}>
          <span className={styles.listHeader}>Processos Vinculados:</span>
          <ul>
            {sliceLinked(lawsuit.children || []).map(child => (
              <li
                className={styles.listItem}
                key={child.id}
                data-testid="linked-child-lawsuit-item"
              >
                <Link
                  href={`/cases/${caseId}/internal_lawsuits/${lawsuit.id}/children/${child.id}`}
                  target="_blank"
                  highlight
                  underline
                  disabled={!canShowInternalLawsuit}
                >
                  {child.number ?? 'Processo sem número'}
                </Link>
              </li>
            ))}

            {shouldSlice(lawsuit.children || []) && (
              <li
                className={styles.listItem}
                key="see-all"
                data-testid="see-all-linked-child-lawsuits"
              >
                <Link
                  highlight
                  underline
                  target="_blank"
                  href={`/cases/${caseId}/internal_lawsuits/${lawsuit.id}#children`}
                >
                  Ver todos ({lawsuit.children?.length})
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default InternalLawsuitCard;
