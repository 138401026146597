import React, { useContext } from 'react';
import { DateTime } from 'luxon';
import compact from 'lodash/compact';
import {
  Accordion,
  Card,
  DropDown,
  DynamicTable,
  Link,
  Stack,
  StackMargin,
  TablePositionModifier,
  Toast,
  ToastType,
  openErrorToast,
  openInfoToast
} from 'ui';
import { InstagramPublication, PaginatedResource } from 'types';
import { useQuery } from 'hooks';
import {
  Action,
  can,
  fetchBookmarkedInstagramPublicationsForCase,
  unbookmarkInstagramPublicationForCase
} from 'api';
import { handleNullValue } from 'utils';
import { CaseContext, usePaginationContext } from 'components';

const FIRST_PAGE = 1;
const DEFAULT_PAGE_SIZE = 6;

type Props = {
  caseId: string;
};

const BookmarkedInstagramPublications = ({ caseId }: Props) => {
  const { getPagination, changePagination } = usePaginationContext();
  const { caseData } = useContext(CaseContext);

  const canRemoveBookmarkedPublication = can(
    Action.CASOS_RASTREAMENTO_POSTAGENS_SALVAS_EXCLUIR,
    caseData?.negotiator.id
  );

  const defaultPagination = {
    pagination: {
      page: FIRST_PAGE,
      pageSize: DEFAULT_PAGE_SIZE
    }
  };

  const contextPagination = getPagination(
    PaginatedResource.BOOKMARKED_INSTAGRAM_PUBLICATIONS
  );

  const fetchPagination = contextPagination ?? defaultPagination;

  const {
    data: publications,
    isLoading,
    error,
    pagination,
    refetch: fetchPublications
  } = useQuery(fetchBookmarkedInstagramPublicationsForCase, {
    caseId,
    ...fetchPagination
  });

  if (error) openErrorToast('Ocorreu um erro ao carregar as publicações.');

  const handleUnbookmarkInstagramPublication = (
    instagramPublicationId: string
  ) => async () => {
    try {
      await unbookmarkInstagramPublicationForCase({
        caseId,
        instagramPublicationId
      });

      await fetchPublications({
        caseId,
        pagination: {
          page: pagination!.number,
          pageSize: pagination!.size
        }
      });

      openInfoToast('Post removido de postagens salvas.');
    } catch (e) {
      openErrorToast('Ocorreu um erro ao remover postagem.');
    }
  };

  const onCurrentPageChange = (newPage: number) => {
    const newPagination = {
      page: newPage,
      pageSize: pagination!.size
    };

    fetchPublications({
      caseId,
      pagination: newPagination
    });

    changePagination(
      PaginatedResource.BOOKMARKED_INSTAGRAM_PUBLICATIONS,
      newPagination
    );
  };

  const onItemsPerPageChange = (newPageSize: number) => {
    const newPagination = {
      page: pagination!.number,
      pageSize: newPageSize
    };

    fetchPublications({
      caseId,
      pagination: newPagination
    });

    changePagination(
      PaginatedResource.BOOKMARKED_INSTAGRAM_PUBLICATIONS,
      newPagination
    );
  };

  const columns = compact([
    {
      name: 'Post',
      columnKey: 'publication',
      render: (item: InstagramPublication) => (
        <Link
          asButton
          social
          small
          external
          iconName="instagram"
          href={item.url}
        >
          {`Post ${DateTime.fromISO(item.createdAt).toFormat('dd/MM/yy T')}`}
        </Link>
      )
    },
    {
      name: 'Localização',
      columnKey: 'location',
      render: (item: InstagramPublication) =>
        handleNullValue(item.instagramLocation?.displayName)
    },
    {
      name: 'Usuário do Instagram',
      columnKey: 'socialName',
      render: (item: InstagramPublication) => (
        <Link highlight underline external href={item.instagramProfile?.url}>
          @{item.instagramProfile?.socialName}
        </Link>
      )
    },
    canRemoveBookmarkedPublication && {
      positionModifier: TablePositionModifier.RIGHT,
      hasActions: true,
      name: '',
      columnKey: 'actions',
      render: (item: InstagramPublication) => (
        <DropDown
          dataTestId="actions-dropdown"
          options={[
            {
              text: 'Remover de postagens salvas',
              callback: handleUnbookmarkInstagramPublication(item.id)
            }
          ]}
        />
      )
    }
  ]);

  const rowKey = (item: InstagramPublication) => item.id;

  const paginationParams = pagination && {
    currentPage: pagination.number,
    itemsPerPage: pagination.size,
    hidePagination: pagination.totalEntries <= DEFAULT_PAGE_SIZE,
    totalEntries: pagination.totalEntries,
    totalPages: pagination.totalPages,
    onCurrentPageChange,
    onItemsPerPageChange
  };

  return (
    <Accordion
      title="Postagens do Instagram salvas"
      iconName="bookmark"
      badgeContent={pagination?.totalEntries}
    >
      <Toast
        type={ToastType.DEFAULT}
        message="As postagens no Instagram estão sujeitas a edições e deleções do usuário"
      />

      <Stack marginTop={StackMargin.MEDIUM}>
        <Card small>
          <DynamicTable
            columns={columns}
            data={publications ?? []}
            dataTestId="bookmarkInstagramPublicationtable"
            isLoading={isLoading}
            pagination={paginationParams}
            noAutoPaginate
            rowKey={rowKey}
          />
        </Card>
      </Stack>
    </Accordion>
  );
};

export default BookmarkedInstagramPublications;
