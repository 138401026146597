import React, { ReactElement } from 'react';

import { ToolTip, Icon } from 'ui';

import style from './IconTooltip.module.scss';

type Props = {
  text: string | ReactElement;
  dataTestId?: string;
  icon?: string;
  color?: string;
  className?: string;
};

const IconTooltip = ({
  text,
  dataTestId = 'icon-tooltip',
  icon = 'question-mark-rounded',
  color = '#B2B5C2',
  className
}: Props) => {
  return (
    <ToolTip content={text} placement="top" className={className}>
      <span data-testid={dataTestId} className={style.child}>
        <Icon name={icon} fill={color} small />
      </span>
    </ToolTip>
  );
};

export default IconTooltip;
