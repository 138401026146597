import React, { createRef, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import cn from 'classnames';
import { AugmentedWindow } from 'types';

const reCaptchaToken = (window as AugmentedWindow).ENV
  .REACT_APP_PUBLIC_RECAPTCHA_TOKEN;

type Props = {
  errors?: Array<string> | string;
  onChange: (value: string | null) => void;
};

const reCaptchaReference = createRef<ReCAPTCHA>();

const CaptchaField = ({ errors, onChange }: Props) => {
  const handleChange = () => {
    onChange(reCaptchaReference.current!.getValue());
  };

  const inputClass = cn('input-field', {
    '-error': Boolean(errors)
  });

  useEffect(() => {
    if (Boolean(errors)) {
      reCaptchaReference.current!.reset();
    }
  }, [errors]);

  return (
    <div className={inputClass} data-testid="captcha-field">
      <div className="container">
        <ReCAPTCHA
          ref={reCaptchaReference}
          sitekey={reCaptchaToken}
          onChange={handleChange}
          hl="pt-BR"
        />
      </div>
      {errors && (
        <p className="message">
          {Array.isArray(errors) ? errors.join(', ') : errors}
        </p>
      )}
    </div>
  );
};

export default CaptchaField;
