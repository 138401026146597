import React from 'react';
import range from 'lodash/range';
import { Loader } from 'ui';

import style from './NotificationLoader.module.scss';

const NotificationLoader = () => {
  return (
    <div className={style.loaderWrapper} data-testid="notification-loader">
      {range(3).map(row => (
        <div className={style.loaderContent} key={row}>
          <Loader height={1.5} width={4} />
          <Loader height={1} width={2} />
        </div>
      ))}
    </div>
  );
};

export default NotificationLoader;
