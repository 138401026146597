import { mountReportAddress } from 'domain/reportLocation';
import React from 'react';
import get from 'lodash/get';
import cn from 'classnames';

import { Stack, Badge } from 'ui';
import { applyDocumentMask } from 'utils';

import {
  ReportCaseSummary,
  ReportFamilyMember,
  ReportLocation,
  ReportPartner,
  ReportPartnershipCompanies,
  ReportRelatedCompany
} from 'types';

import style from './ContentBadges.module.scss';

type Props = {
  report: ReportCaseSummary | null;
  advancedInfoActive: boolean;
};

type AdvancedInfoValue =
  | ReportFamilyMember
  | ReportLocation
  | ReportPartner
  | ReportPartnershipCompanies
  | ReportRelatedCompany;

const amountByKeys = (report: ReportCaseSummary, keys: string[]) =>
  Object.entries(report)
    .filter(([prop, value]) => Array.isArray(value) && keys.includes(prop))
    .reduce((amount, [, value]) => (amount += Array(value).flat().length), 0);

const advancedInfoByKey = (
  report: ReportCaseSummary,
  keys: string[],
  infoRender: (value: AdvancedInfoValue) => string,
  subInfoRender: (value: AdvancedInfoValue) => string
) =>
  Object.entries(report)
    .filter(([prop, value]) => Array.isArray(value) && keys.includes(prop))
    .flatMap(([, value]) => value as AdvancedInfoValue[])
    .map(value => {
      return {
        info: infoRender(value),
        subInfo: subInfoRender(value)
      };
    });

const totalEntries = (report: ReportCaseSummary, keys: string[]) =>
  get(report, [...keys, 'pagination', 'totalEntries'], 0);

const totalCount = (report: ReportCaseSummary, keys: string[]) =>
  get(report, ['content', ...keys], 0);

const ContentBadges = ({ report, advancedInfoActive = false }: Props) => {
  if (!report) return null;

  const metadata = [
    {
      value: amountByKeys(report, ['familyMembers']),
      label: 'Família',
      advancedInfos: advancedInfoByKey(
        report,
        ['familyMembers'],
        value => get(value, ['name']),
        value =>
          `${applyDocumentMask(get(value, ['cpf']))}, ${get(value, [
            'relationship'
          ])}`
      )
    },
    {
      company: {
        value: amountByKeys(report, ['relatedCompanies']),
        label: 'Empresas relacionadas',
        advancedInfos: advancedInfoByKey(
          report,
          ['relatedCompanies'],
          value => get(value, ['businessName']),
          value => applyDocumentMask(get(value, ['cnpj']))
        )
      },
      individual: {
        value: amountByKeys(report, ['partnershipCompanies']),
        label: 'Empresas em que é sócia',
        advancedInfos: advancedInfoByKey(
          report,
          ['partnershipCompanies'],
          value => get(value, ['businessName']),
          value =>
            `${applyDocumentMask(get(value, ['cnpj']))}, ${get(value, [
              'role'
            ])}`
        )
      }
    }[report.type],
    {
      value: amountByKeys(report, ['locations']),
      label: 'Localizações',
      advancedInfos: advancedInfoByKey(
        report,
        ['locations'],
        value => get(value, ['businessName']),
        value => mountReportAddress(get(value, ['address']), 'state')
      )
    },
    {
      value: amountByKeys(report, ['partners']),
      label: 'Estrutura societária',
      advancedInfos: advancedInfoByKey(
        report,
        ['partners'],
        value => get(value, ['name']),
        value =>
          `${applyDocumentMask(get(value, ['identification']))}, ${get(value, [
            'role'
          ])}`
      )
    },
    {
      value: totalCount(report, ['airplanes']),
      label: 'Aeronaves'
    },
    {
      value: totalCount(report, ['electoralDonations']),
      label: 'Doações eleitorais'
    },
    {
      value: totalCount(report, ['realEstates']),
      label: 'Imóveis'
    },
    {
      value: totalCount(report, ['ruralProperties']),
      label: 'Imóveis rurais'
    },
    {
      value: totalCount(report, ['vehicles']),
      label: 'Veículos'
    },
    {
      value: totalEntries(report, ['paginatedLawsuits']),
      label: 'Processos judiciais'
    },
    {
      value: totalEntries(report, [
        'selectedInstagramProfile',
        'paginatedPublications'
      ]),
      label: 'Postagens no Instagram'
    },
    {
      value: totalEntries(report, [
        'selectedInstagramProfile',
        'paginatedFavoritedPublications'
      ]),
      label: 'Postagens salvas'
    },
    {
      value: totalEntries(report, [
        'selectedInstagramProfile',
        'paginatedAggregatedMentionedProfiles'
      ]),
      label: 'Perfis relacionados'
    }
  ].filter(item => !!item?.value) as Array<{
    value: number;
    label: string;
    advancedInfos?: Array<{ info: string; subInfo: string | null }>;
  }>;

  return (
    <div className={style.badgesWrapper}>
      {metadata.map(({ value, label, advancedInfos }, idx) => {
        const className = cn(style.badgeContainer, {
          [style.active]: Boolean(advancedInfoActive) && advancedInfos
        });

        return (
          <Stack key={`${report.id}-${idx}`} className={className}>
            <div className={style.badges}>
              <Badge dataTestId={`badge-${label.split(' ').join('')}`}>
                {value}
              </Badge>
              <span className={style.label}>{label}</span>
            </div>
            {advancedInfoActive && advancedInfos && (
              <div className={style.advancedInfoWrapper}>
                {advancedInfos?.map((advanced, index) => {
                  return (
                    <div key={index} className={style.advancedInfo}>
                      <span className={style.info}>{advanced.info}</span>
                      {advanced.subInfo && (
                        <span className={style.subInfo}>
                          ({advanced.subInfo})
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </Stack>
        );
      })}
    </div>
  );
};

export default ContentBadges;
