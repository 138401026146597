import * as TaxIndexers from 'domain/taxIndexers';
import React, { useContext } from 'react';
import { getIn, FormikProps, FieldArrayRenderProps } from 'formik';
import { motion } from 'framer-motion';
import {
  Card,
  FormContainer,
  InputField,
  SelectField,
  Toggle,
  Button,
  Link,
  ToolTip,
  StackMargin,
  Stack
} from 'ui';

import {
  DebtorFormAttributes,
  CreditClassFormAttributes,
  CreditClassType
} from 'types';

import { can, Action } from 'api';

import { CaseContext } from 'components';
import style from './CreditClasses.module.scss';

type Props = {
  formikArrayHelpers: FieldArrayRenderProps;
  formik: FormikProps<DebtorFormAttributes>;
};

const CreditClasses = ({ formikArrayHelpers, formik }: Props) => {
  const selectedClasses = formik.values.creditClasses.flatMap(
    creditClass => creditClass.class
  );
  const { caseData } = useContext(CaseContext);

  const addCreditClass = () => {
    const avaliableClasses = Object.values(CreditClassType).filter(
      classType => !selectedClasses.includes(classType)
    );

    return formikArrayHelpers.push({
      amortizationMonths: '',
      classPercentage: '',
      class: avaliableClasses[0],
      complianceBonusPercentage: '',
      haircutPercentage: '',
      indexer: '',
      interestGraceMonths: '',
      mainGraceMonths: '',
      creditValueCents: '',
      prjFeePercentage: ''
    });
  };

  const removeCreditClass = (index: number) => () => {
    formikArrayHelpers.remove(index);
  };

  const canAdd: boolean = formik.values.creditClasses.length < 5;

  const negotiatorId = caseData?.negotiator?.id;

  const canUser = {
    addCreditClass: can(
      Action.CASOS_DEVEDOR_CLASSE_DE_CREDITO_INCLUIR,
      negotiatorId
    ),
    editCreditClass: can(
      Action.CASOS_DEVEDOR_CLASSE_DE_CREDITO_EDITAR,
      negotiatorId
    ),
    removeCreditClass: can(
      Action.CASOS_DEVEDOR_CLASSE_DE_CREDITO_EXCLUIR,
      negotiatorId
    )
  };

  return (
    <div
      className="credit-class-container"
      data-testid="credit-class-container"
    >
      <h2 className={style.title}>Classes de crédito</h2>
      {formik.values.creditClasses.map(
        (creditClass: CreditClassFormAttributes, index: number) => {
          const isDisabled = formik.initialValues?.creditClasses[index]
            ?.creditValueCents
            ? !canUser.editCreditClass
            : !canUser.addCreditClass;
          return (
            <motion.div
              key={`credit-class-row-${index}`}
              initial="hide"
              animate="slideDown"
              exit="slideUp"
              variants={{
                hide: { opacity: 0, y: -100 },
                slideDown: { opacity: 1, y: 0 },
                slideUp: { opacity: 0, y: -50 }
              }}
              transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              <Stack marginTop={StackMargin.SMALL}>
                <Card
                  small
                  dataTestId={`credit-class-row-${index}`}
                  className={style.card}
                >
                  <FormContainer className="toogle-container">
                    <Toggle
                      title="Classe de crédito"
                      options={[
                        {
                          value: CreditClassType.EXTRACONCURSAL,
                          label: 'Extraconcursal',
                          disabled: selectedClasses.includes(
                            CreditClassType.EXTRACONCURSAL
                          )
                        },
                        {
                          value: CreditClassType.DIP,
                          label: 'DIP',
                          disabled: selectedClasses.includes(
                            CreditClassType.DIP
                          )
                        },
                        {
                          value: CreditClassType.REAL,
                          label: 'Classe 2: Real',
                          disabled: selectedClasses.includes(
                            CreditClassType.REAL
                          )
                        },
                        {
                          value: CreditClassType.QUIROGRAFARIO,
                          label: 'Classe 3: Quirográfico',
                          disabled: selectedClasses.includes(
                            CreditClassType.QUIROGRAFARIO
                          )
                        },
                        {
                          value: CreditClassType.MPE,
                          label: 'Classe 4: MPE',
                          disabled: selectedClasses.includes(
                            CreditClassType.MPE
                          )
                        }
                      ]}
                      onChange={event =>
                        formik.setFieldValue(
                          `creditClasses[${index}].class`,
                          event.target.value
                        )
                      }
                      checked={creditClass.class}
                      disabled={isDisabled}
                    />

                    {canUser.removeCreditClass && (
                      <div className={style.remove}>
                        <ToolTip content="Remover classe de crédito">
                          <span>
                            <Link
                              onClick={removeCreditClass(index)}
                              iconName="close"
                              dataTestId="remove-credit-class"
                            />
                          </span>
                        </ToolTip>
                      </div>
                    )}
                  </FormContainer>
                  <FormContainer className="col-3">
                    <InputField
                      id={`creditClasses-${index}-creditValueCents`}
                      name={`creditClasses[${index}].creditValueCents`}
                      type="currency"
                      title="Valor do crédito"
                      value={creditClass.creditValueCents.toString()}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={
                        getIn(
                          formik.touched,
                          `creditClasses[${index}].creditValueCents`
                        ) &&
                        getIn(
                          formik.errors,
                          `creditClasses[${index}].creditValueCents`
                        )
                      }
                      disabled={isDisabled}
                    />

                    <InputField
                      id={`creditClasses-${index}-classPercentage`}
                      name={`creditClasses[${index}].classPercentage`}
                      type="percent"
                      title="% do crédito na classe"
                      value={creditClass.classPercentage.toString()}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={
                        getIn(
                          formik.touched,
                          `creditClasses[${index}].classPercentage`
                        ) &&
                        getIn(
                          formik.errors,
                          `creditClasses[${index}].classPercentage`
                        )
                      }
                      disabled={isDisabled}
                    />

                    <InputField
                      id={`creditClasses-${index}-haircutPercentage`}
                      name={`creditClasses[${index}].haircutPercentage`}
                      type="percent"
                      title="Haircut (%)"
                      value={creditClass.haircutPercentage.toString()}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={
                        getIn(
                          formik.touched,
                          `creditClasses[${index}].haircutPercentage`
                        ) &&
                        getIn(
                          formik.errors,
                          `creditClasses[${index}].haircutPercentage`
                        )
                      }
                      disabled={isDisabled}
                    />

                    <InputField
                      id={`creditClasses-${index}-complianceBonusPercentage`}
                      name={`creditClasses[${index}].complianceBonusPercentage`}
                      type="percent"
                      title="Bônus de adimplência (%)"
                      value={creditClass.complianceBonusPercentage.toString()}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={
                        getIn(
                          formik.touched,
                          `creditClasses[${index}].complianceBonusPercentage`
                        ) &&
                        getIn(
                          formik.errors,
                          `creditClasses[${index}].complianceBonusPercentage`
                        )
                      }
                      disabled={isDisabled}
                    />

                    <InputField
                      id={`creditClasses-${index}-interestGraceMonths`}
                      name={`creditClasses[${index}].interestGraceMonths`}
                      type="text"
                      title="Carência do juros (meses)"
                      value={creditClass.interestGraceMonths.toString()}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={
                        getIn(
                          formik.touched,
                          `creditClasses[${index}].interestGraceMonths`
                        ) &&
                        getIn(
                          formik.errors,
                          `creditClasses[${index}].interestGraceMonths`
                        )
                      }
                      disabled={isDisabled}
                    />

                    <InputField
                      id={`creditClasses-${index}-mainGraceMonths`}
                      name={`creditClasses[${index}].mainGraceMonths`}
                      type="text"
                      title="Carência principal (meses)"
                      value={creditClass.mainGraceMonths.toString()}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={
                        getIn(
                          formik.touched,
                          `creditClasses[${index}].mainGraceMonths`
                        ) &&
                        getIn(
                          formik.errors,
                          `creditClasses[${index}].mainGraceMonths`
                        )
                      }
                      disabled={isDisabled}
                    />

                    <InputField
                      id={`creditClasses-${index}-amortizationMonths`}
                      name={`creditClasses[${index}].amortizationMonths`}
                      type="text"
                      title="Amortização (meses)"
                      value={creditClass.amortizationMonths.toString()}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={
                        getIn(
                          formik.touched,
                          `creditClasses[${index}].amortizationMonths`
                        ) &&
                        getIn(
                          formik.errors,
                          `creditClasses[${index}].amortizationMonths`
                        )
                      }
                      disabled={isDisabled}
                    />

                    <SelectField
                      id={`creditClasses-${index}-indexer`}
                      name={`creditClasses[${index}].indexer`}
                      title="Indexador"
                      options={TaxIndexers.indexerOptions()}
                      value={creditClass.indexer}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        getIn(
                          formik.touched,
                          `creditClasses[${index}].indexer`
                        ) &&
                        getIn(formik.errors, `creditClasses[${index}].indexer`)
                      }
                      disabled={isDisabled}
                    />

                    <InputField
                      id={`creditClasses-${index}-prjFeePercentage`}
                      name={`creditClasses[${index}].prjFeePercentage`}
                      type="percent"
                      title="Taxa PRJ (%)"
                      value={creditClass.prjFeePercentage.toString()}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={
                        getIn(
                          formik.touched,
                          `creditClasses[${index}].prjFeePercentage`
                        ) &&
                        getIn(
                          formik.errors,
                          `creditClasses[${index}].prjFeePercentage`
                        )
                      }
                      disabled={isDisabled}
                    />
                  </FormContainer>
                </Card>
              </Stack>
            </motion.div>
          );
        }
      )}

      {canUser.addCreditClass && (
        <Stack marginTop={StackMargin.SMALL}>
          <Button
            highlight
            outline
            centered
            onClick={addCreditClass}
            disabled={!canAdd}
          >
            Adicionar classe de crédito
          </Button>
        </Stack>
      )}
    </div>
  );
};

export default CreditClasses;
