import {
  ApiPagination,
  DEFAULT_PAGE_SIZE,
  FIRST_PAGE,
  MIN_PAGE_SIZE
} from 'types';

export const buildPaginationParams = (
  pagination: ApiPagination | undefined,
  refetch: (args?: { page: number; pageSize: number }) => void
) => {
  const currentPage = pagination?.number ?? FIRST_PAGE;
  const itemsPerPage = pagination?.size ?? DEFAULT_PAGE_SIZE;

  return {
    currentPage,
    itemsPerPage,
    hidePagination: pagination && pagination.totalEntries <= MIN_PAGE_SIZE,
    totalEntries: pagination?.totalEntries ?? 0,
    totalPages: pagination?.totalPages ?? 0,
    onCurrentPageChange: (newPage: number) =>
      refetch({ page: newPage, pageSize: itemsPerPage }),
    onItemsPerPageChange: (newPageSize: number) =>
      refetch({ pageSize: newPageSize, page: currentPage })
  };
};
