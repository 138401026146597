import { entityLink, entityName, entityRoleDescription } from 'domain/entity';
import { reportLink } from 'domain/reports';
import React, { Fragment } from 'react';
import cn from 'classnames';

import { Modal, ToolTip, Badge, Link, Stack, StackMargin } from 'ui';

import { EntityWithRelations, EntityWithRole } from 'types';

import Toggle from './Toggle';

import style from './OriginModal.module.scss';

type Props = {
  entity?: EntityWithRelations | null;
  isOpen: boolean;
  onClose: () => void;
};

const withCenteredText = (args: string[]) => cn(args, style.textCentered);

const relationshipLink = (relationship: EntityWithRole) => {
  if (relationship.parentEntity) return entityLink(relationship.parentEntity);
  if (relationship.parentReport) return reportLink(relationship.parentReport);
  return '';
};

const generateEntityMetadata = (currentEntity: EntityWithRelations) => ({
  name: entityName(currentEntity),
  link: entityLink(currentEntity),
  entities: currentEntity.relationships.map(relationship => ({
    id: relationship.id,
    disabled: !(relationship.entity?.currentReport || relationship.report),
    name: relationship.name,
    link: relationshipLink(relationship),
    role: entityRoleDescription(relationship.role)
  }))
});

const OriginModal = ({ entity: currentEntity, isOpen, onClose }: Props) => {
  if (!currentEntity) return null;

  const entityMetadata = generateEntityMetadata(currentEntity);

  return (
    <>
      <Modal className={style.originModal} isOpen={isOpen} onClose={onClose}>
        <Stack marginBottom={StackMargin.XLARGE}>
          <p className={withCenteredText([style.title])}>Origem do suspeito</p>
          <p className={withCenteredText([style.subtitle])}>
            {entityMetadata.name}
          </p>
        </Stack>
        <div>
          <Badge
            className={style.badge}
            dataTestId={`badge-${currentEntity.id}`}
          >
            <Link highlight href={entityMetadata.link}>
              {entityMetadata.name}
            </Link>
          </Badge>
          {entityMetadata.entities.map(entity => (
            <Fragment key={entity.id}>
              <Stack
                marginBottom={StackMargin.MEDIUM}
                marginTop={StackMargin.MEDIUM}
              >
                <p className={withCenteredText([style.textRole])}>
                  {entity.role}
                </p>
              </Stack>
              <ToolTip
                content={entity.disabled ? 'Este relatório foi deletado' : null}
                placement="top"
              >
                <div>
                  <Badge
                    className={style.badge}
                    dataTestId={`badge-${entity.id}`}
                  >
                    <Link
                      dataTestId={`link-${entity.id}`}
                      highlight={!entity.disabled}
                      disabled={entity.disabled}
                      href={entity.link}
                    >
                      {entity.name}
                    </Link>
                  </Badge>
                </div>
              </ToolTip>
            </Fragment>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default Object.assign(OriginModal, {
  Toggle
});
