import { getFormikError } from 'domain/forms';
import {
  assetOriginOptions,
  assetRealEstateIdentificationLabel,
  assetRealEstateTypeOptions,
  assetRuralPropertyTypeOptions,
  assetTypeOptions,
  assetValidationSchema,
  formatInitialValues,
  occupancyOptions,
  sanitizeInitalValues
} from 'domain/assets';

import React from 'react';
import { useFormik } from 'formik';

import { createAsset } from 'api';

import { AutoCompleteField } from 'components';
import {
  InputField,
  SelectField,
  Toggle,
  FormContainer,
  Button,
  openErrorToast,
  openSuccessToast,
  StackMargin,
  Stack
} from 'ui';

import {
  AssetAttributes,
  OnSubmit,
  ApiError,
  EntityType,
  Asset,
  AssetType
} from 'types';

type Props = {
  onCreateAsset: (asset: Asset) => void;
  caseId: string;
  formDefaultData?: Partial<AssetAttributes>;
  isAssetTypeBlocked?: boolean;
};

const formatError = (errors?: ApiError) => {
  if (!errors) return {};

  return {
    ...errors,
    entity: { individual: errors?.individual, company: errors?.company }
  };
};

const Form = ({
  onCreateAsset,
  caseId,
  formDefaultData,
  isAssetTypeBlocked
}: Props) => {
  const onSubmit: OnSubmit<AssetAttributes> = async (values, formikHelpers) => {
    try {
      const sanitizedValues = sanitizeInitalValues(values);

      const response = await createAsset(caseId, sanitizedValues);

      onCreateAsset(response.data);

      openSuccessToast('Ativo salvo com sucesso!');
    } catch (error) {
      formikHelpers.setErrors(formatError(error.errors));
      openErrorToast('Houve um erro ao salvar o ativo');
    }
  };

  const reclaimingFormData = {
    ...formDefaultData,
    assetRealEstate: {
      id: null,
      address: null,
      constructedArea: null,
      landArea: null,
      number: null,
      postalCode: null,
      registration: null,
      cityId: '',
      state: '',
      origin: null,
      occupied: null,
      type: null
    },
    assetRuralProperty: {
      id: null,
      address: null,
      detentionPercentage: null,
      incraCode: null,
      landArea: null,
      landAreaUnit: null,
      constructedArea: null,
      constructedAreaUnit: null,
      nirfCodes: null,
      registration: null,
      number: null,
      postalCode: null,
      cityId: '',
      state: '',
      origin: null,
      occupied: null,
      type: null
    }
  };

  const formik = useFormik({
    initialValues: formatInitialValues(undefined, reclaimingFormData),
    validationSchema: assetValidationSchema,
    onSubmit
  });

  const getError = getFormikError(formik);

  const formatOptionalTitle = (title: string) =>
    formDefaultData?.reclaimingIds ? title : `${title} (opcional)`;

  return (
    <form className="asset-form" data-testid="asset-form">
      <FormContainer className="col-3">
        <SelectField
          dataTestId="select-asset-type"
          id="type"
          name="type"
          value={formik.values.type}
          title="Tipo"
          error={getError('type')}
          disabled={isAssetTypeBlocked}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          options={assetTypeOptions()}
        />

        <InputField
          dataTestId="asset-name"
          id="name"
          name="name"
          type="text"
          value={formik.values.name!}
          error={getError('name')}
          title="Nome do ativo"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        <InputField
          dataTestId="asset-identification"
          id="identification"
          name="identification"
          type="text"
          value={formik.values.identification!}
          error={getError('identification')}
          title={`${assetRealEstateIdentificationLabel(
            formik.values.type
          )} (opcional)`}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        {formik.values.type === AssetType.REAL_ESTATE && (
          <>
            <SelectField
              dataTestId="asset-subtype"
              id="assetRealEstate.type"
              name="assetRealEstate.type"
              value={formik.values?.assetRealEstate?.type ?? ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              options={assetRealEstateTypeOptions()}
              title={formatOptionalTitle('Subtipo')}
              error={getError('assetRealEstate.type')}
              optional
            />

            <Toggle
              dataTestId="asset-occupied"
              checked={formik.values?.assetRealEstate?.occupied?.toString()}
              name="assetRealEstate.occupied"
              onChange={event =>
                formik.setFieldValue(
                  'assetRealEstate.occupied',
                  event.target.value === 'true'
                )
              }
              options={occupancyOptions}
              title={formatOptionalTitle('Ocupação')}
              error={getError('assetRealEstate.occupied')}
            />

            <SelectField
              dataTestId="asset-origin"
              id="assetRealEstate.origin"
              name="assetRealEstate.origin"
              value={formik.values?.assetRealEstate?.origin ?? ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              options={assetOriginOptions()}
              title={formatOptionalTitle('Origem')}
              error={getError('assetRealEstate.origin')}
              optional
            />
          </>
        )}

        {formik.values.type === AssetType.RURAL_PROPERTY && (
          <>
            <SelectField
              dataTestId="asset-subtype"
              id="assetRuralProperty.type"
              name="assetRuralProperty.type"
              value={formik.values?.assetRuralProperty?.type ?? ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              options={assetRuralPropertyTypeOptions()}
              title={formatOptionalTitle('Subtipo')}
              error={getError('assetRuralProperty.origin')}
              optional
            />

            <Toggle
              dataTestId="asset-occupied"
              checked={formik.values?.assetRuralProperty?.occupied?.toString()}
              name="assetRuralProperty.occupied"
              onChange={event =>
                formik.setFieldValue(
                  'assetRuralProperty.occupied',
                  event.target.value === 'true'
                )
              }
              options={occupancyOptions}
              title={formatOptionalTitle('Ocupação')}
              error={getError('assetRuralProperty.origin')}
            />

            <SelectField
              dataTestId="asset-origin"
              id="assetRuralProperty.origin"
              name="assetRuralProperty.origin"
              value={formik.values?.assetRuralProperty?.origin ?? ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              options={assetOriginOptions()}
              title={formatOptionalTitle('Origem')}
              error={getError('assetRuralProperty.origin')}
              optional
            />
          </>
        )}

        <Toggle
          title="Tipo de pessoa proprietária"
          options={[
            { value: 'individual', label: 'Física' },
            { value: 'company', label: 'Jurídica' }
          ]}
          onChange={event =>
            formik.setFieldValue('entity.type', event.target.value)
          }
          checked={formik.values.entity.type}
        />

        {formik.values.entity.type === EntityType.INDIVIDUAL ? (
          <React.Fragment>
            <Toggle
              name="entity.individual.isAbroad"
              title="País"
              options={[
                { value: 'false', label: 'Brasil' },
                { value: 'true', label: 'Exterior' }
              ]}
              checked={formik.values.entity.individual.isAbroad.toString()}
              onChange={event =>
                formik.setFieldValue(
                  'entity.individual.isAbroad',
                  event.target.value
                )
              }
            />

            <AutoCompleteField
              caseId={caseId}
              isAbroad={formik.values.entity.individual.isAbroad}
              entityType={EntityType.INDIVIDUAL}
              onSelect={selection => {
                formik.setFieldValue('entity.individual.cpf', selection!.value);
                formik.setFieldValue(
                  'entity.individual.name',
                  selection!.complement
                );
              }}
              dataToItem={data => ({
                value: data.individual.cpf,
                complement: data.individual.name
              })}
              dataTestId="asset-entity-cpf"
              id="asset-entity-cpf"
              name="entity.individual.cpf"
              type="cpf"
              value={formik.values.entity.individual.cpf}
              error={getError('entity.individual.cpf')}
              title="CPF do proprietário"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            <AutoCompleteField
              caseId={caseId}
              isAbroad={formik.values.entity.individual.isAbroad}
              entityType={EntityType.INDIVIDUAL}
              onSelect={selection => {
                formik.setFieldValue(
                  'entity.individual.name',
                  selection!.value
                );
                formik.setFieldValue(
                  'entity.individual.cpf',
                  selection!.complement
                );
              }}
              dataToItem={data => ({
                value: data.individual.name,
                complement: data.individual.cpf
              })}
              dataTestId="asset-entity-name"
              id="asset-entity-name"
              name="entity.individual.name"
              type="text"
              value={formik.values.entity.individual.name}
              error={getError('entity.individual.name')}
              title="Nome completo do proprietário"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Toggle
              name="entity.company.isAbroad"
              title="País"
              options={[
                { value: 'false', label: 'Brasil' },
                { value: 'true', label: 'Exterior' }
              ]}
              checked={formik.values.entity.company.isAbroad.toString()}
              onChange={event =>
                formik.setFieldValue(
                  'entity.company.isAbroad',
                  event.target.value
                )
              }
            />

            <AutoCompleteField
              caseId={caseId}
              isAbroad={formik.values.entity.company.isAbroad}
              entityType={EntityType.COMPANY}
              onSelect={selection => {
                formik.setFieldValue('entity.company.cnpj', selection!.value);
                formik.setFieldValue(
                  'entity.company.businessName',
                  selection!.complement
                );
              }}
              dataToItem={data => ({
                value: data.company.cnpj,
                complement: data.company.businessName
              })}
              dataTestId="asset-entity-cnpj"
              id="asset-entity-cnpj"
              name="entity.company.cnpj"
              type="cnpj"
              value={formik.values.entity.company.cnpj}
              error={getError('entity.company.cnpj')}
              title="CNPJ do proprietário"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            <AutoCompleteField
              caseId={caseId}
              isAbroad={formik.values.entity.company.isAbroad}
              entityType={EntityType.COMPANY}
              onSelect={selection => {
                formik.setFieldValue(
                  'entity.company.businessName',
                  selection!.value
                );
                formik.setFieldValue(
                  'entity.company.cnpj',
                  selection!.complement
                );
              }}
              dataToItem={data => ({
                value: data.company.businessName,
                complement: data.company.cnpj
              })}
              dataTestId="asset-entity-businessName"
              id="asset-entity-businessName"
              name="entity.company.businessName"
              type="text"
              value={formik.values.entity.company.businessName}
              error={getError('entity.company.businessName')}
              title="Razão social do proprietário"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </React.Fragment>
        )}
      </FormContainer>
      <Stack marginTop={StackMargin.MEDIUM}>
        <Button
          onClick={formik.handleSubmit}
          highlight
          centered
          dataTestId="submit-asset"
          type="submit"
        >
          Adicionar
        </Button>
      </Stack>
    </form>
  );
};

export default Form;
