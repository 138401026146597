import { DuplicateStatus, DuplicateProtestStatus, Option } from 'types';

const DuplicateStatusDictionary = {
  [DuplicateStatus.PAID_ON_TIME]: 'Pago em dia',
  [DuplicateStatus.EXPIRING]: 'A vencer',
  [DuplicateStatus.OVERDUE]: 'Pago em atraso',
  [DuplicateStatus.DEFAULTING]: 'Inadimplente'
};

const DuplicateProtestStatusDictionary = {
  [DuplicateProtestStatus.PAID_OFF]: 'Liquidado em cartório',
  [DuplicateProtestStatus.PROTESTED]: 'Protestado',
  [DuplicateProtestStatus.PROTESTING]: 'Em protesto',
  [DuplicateProtestStatus.SENT_TO_NOTARY]: 'Enviado a cartório'
};

export function duplicateStatusOptions(): Option[] {
  return Object.values(DuplicateStatus).map(situation => ({
    value: situation,
    label: DuplicateStatusDictionary[situation]
  }));
}

export function duplicateProtestStatusOptions(): Option[] {
  return Object.values(DuplicateProtestStatus).map(situation => ({
    value: situation,
    label: DuplicateProtestStatusDictionary[situation]
  }));
}
