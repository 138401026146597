import React, { ReactElement } from 'react';
import cn from 'classnames';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-away.css';
import './ToolTip.scss';

type Props = {
  children: ReactElement;
  content: string | ReactElement | null;
  placement?:
    | 'auto'
    | 'auto-start'
    | 'auto-end'
    | 'top'
    | 'bottom'
    | 'right'
    | 'left'
    | 'top-start'
    | 'top-end'
    | 'bottom-start'
    | 'bottom-end'
    | 'right-start'
    | 'right-end'
    | 'left-start'
    | 'left-end';
  className?: string;
};

const ToolTip = ({
  children,
  content,
  placement = 'bottom-start',
  className
}: Props) => {
  if (!content) return children;

  return (
    <Tippy
      className="tooltip"
      placement={placement}
      content={
        <div className={cn('tooltip-content', className)}>{content}</div>
      }
      arrow={true}
      animation="shift-away"
      duration={[300, 300]}
    >
      {children}
    </Tippy>
  );
};

export default ToolTip;
