import { ASSET_IDENTIFICATION_DICTIONARY } from 'domain/assets';
import React from 'react';
import { FormikProps, getIn } from 'formik';
import { AssetAttributes, AssetVehicle, AssetType } from 'types';
import { FormContainer, InputField } from 'ui';

import styles from '../Form.module.scss';

type Props = {
  assetVehicle: AssetVehicle | null;
  isLoading: boolean;
  formik: FormikProps<AssetAttributes>;
  identificationLocked: boolean;
  disabled?: boolean;
};

const VehicleFields = ({
  assetVehicle,
  isLoading,
  formik,
  identificationLocked,
  disabled
}: Props) => {
  return (
    <>
      <h3 className={styles.title}>Informações do veículo</h3>

      <InputField
        type="text"
        id="name"
        name="name"
        title="Nome do ativo"
        isLoading={isLoading}
        value={formik.values.name}
        error={getIn(formik.touched, 'name') && getIn(formik.errors, 'name')}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        disabled={disabled}
      />

      <InputField
        type="text"
        id="assetVehicle.brandAndModel"
        name="assetVehicle.brandAndModel"
        title="Modelo/Marca (opcional)"
        isLoading={isLoading}
        value={formik.values.assetVehicle?.brandAndModel}
        error={
          getIn(formik.touched, 'assetVehicle.brandAndModel') &&
          getIn(formik.errors, 'assetVehicle.brandAndModel')
        }
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        showMode={
          assetVehicle ? !assetVehicle?.brandAndModel.isEditable : false
        }
        disabled={disabled}
      />

      <FormContainer className={'col-2'}>
        <InputField
          type="text"
          id="assetVehicle.renavam"
          name="assetVehicle.renavam"
          title="Renavam (opcional)"
          isLoading={isLoading}
          value={formik.values.assetVehicle?.renavam}
          error={
            getIn(formik.touched, 'assetVehicle.renavam') &&
            getIn(formik.errors, 'assetVehicle.renavam')
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          showMode={assetVehicle ? !assetVehicle?.renavam.isEditable : false}
          disabled={disabled}
        />

        <InputField
          type="text"
          id="assetVehicle.serialNumber"
          name="assetVehicle.serialNumber"
          title="Chassis (opcional)"
          isLoading={isLoading}
          value={formik.values.assetVehicle?.serialNumber}
          error={
            getIn(formik.touched, 'assetVehicle.serialNumber') &&
            getIn(formik.errors, 'assetVehicle.serialNumber')
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          showMode={
            assetVehicle ? !assetVehicle?.serialNumber.isEditable : false
          }
          disabled={disabled}
        />

        <InputField
          type="text"
          id="assetVehicle.yearOfManufacture"
          name="assetVehicle.yearOfManufacture"
          title="Ano de fabricação (opcional)"
          isLoading={isLoading}
          value={formik.values.assetVehicle?.yearOfManufacture?.toString()}
          error={
            getIn(formik.touched, 'assetVehicle.yearOfManufacture') &&
            getIn(formik.errors, 'assetVehicle.yearOfManufacture')
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          max={4}
          showMode={
            assetVehicle ? !assetVehicle?.yearOfManufacture.isEditable : false
          }
          disabled={disabled}
        />

        <InputField
          type="text"
          id="assetVehicle.modelYear"
          name="assetVehicle.modelYear"
          title="Ano do modelo (opcional)"
          isLoading={isLoading}
          value={formik.values.assetVehicle?.modelYear?.toString()}
          error={
            getIn(formik.touched, 'assetVehicle.modelYear') &&
            getIn(formik.errors, 'assetVehicle.modelYear')
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          max={4}
          showMode={assetVehicle ? !assetVehicle?.modelYear.isEditable : false}
          disabled={disabled}
        />

        <InputField
          type="text"
          id="identification"
          name="identification"
          title={`${
            ASSET_IDENTIFICATION_DICTIONARY[AssetType.VEHICLE]
          } (opcional)`}
          isLoading={isLoading}
          value={formik.values.identification?.toString()}
          error={
            getIn(formik.touched, 'identification') &&
            getIn(formik.errors, 'identification')
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          showMode={identificationLocked}
          disabled={disabled}
        />
      </FormContainer>
    </>
  );
};

export default VehicleFields;
