import { TYPE_DICTIONARY } from 'domain/cases';
import React, { useEffect, useState, useCallback } from 'react';
import { useParams, generatePath } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { History } from 'history';

import { Action, fetchCases, fetchCasesStats, can } from 'api';
import { useFeatureFlag, useQuery } from 'hooks';

import { DataImportField, Notifications } from 'components';
import { Header, openErrorToast, Link, InputField, Accordion } from 'ui';
import Layout from 'Layouts/Layout';

import { ClientParams, Case, Feature, CaseType } from 'types';

import CaseList from './CaseList';
import ExportButton from './ExportButton';
import Stats from './Stats';
import style from './index.module.scss';

type CaseHeader = {
  label: string;
  field: string;
  direction: string | null;
};

type OrderByConfig = {
  field: string;
  direction: string;
};

type Props = {
  history: History;
};

const buildOrderCasesParams = (
  orderByConfig: OrderByConfig
): ClientParams | undefined => {
  if (orderByConfig.field) {
    return {
      field: orderByConfig.field,
      value: orderByConfig.direction === 'asc' ? 'asc' : 'desc'
    };
  }
};

const buildFetchCasesParams = (
  searchTerm: string | undefined,
  orderByConfig: OrderByConfig
): ClientParams => {
  return {
    filter: searchTerm,
    order: buildOrderCasesParams(orderByConfig)
  };
};

const DEBOUNCE_TIME = 500;

const fetchCasesDebounced = debounce(
  async ({ searchTerm, orderByConfig, setCases, setIsLoading, type }) => {
    try {
      setIsLoading(true);
      const response = await fetchCases(
        buildFetchCasesParams(searchTerm, orderByConfig),
        type
      );
      setCases(response.data);
    } catch (error) {
      openErrorToast('Ocorreu um erro na listagem de casos.');
    } finally {
      setIsLoading(false);
    }
  },
  DEBOUNCE_TIME
);

const List = ({ history }: Props) => {
  const [cases, setCases] = useState<Case[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [orderByConfig, setOrderByConfig] = useState<OrderByConfig>({
    field: '',
    direction: ''
  });
  const [headers, setHeaders] = useState<CaseHeader[]>([
    { label: 'Caso', field: 'name', direction: null },
    { label: 'Advogado Responsável', field: 'internalLawyer', direction: null },
    { label: 'Negociador Responsável', field: 'negotiator', direction: null },
    { label: 'Estágio de Recuperação', field: 'status', direction: null },
    { label: 'Atualizado', field: 'lastChangedAt', direction: null },
    { label: 'Maior atraso', field: 'overdueDate', direction: null }
  ]);
  const [searchTerm, setSearchTerm] = useState<string | undefined>();
  const [selectedCaseIds, setSelectedCaseIds] = useState<string[]>([]);
  const [isNotificationsEnable] = useFeatureFlag(Feature.NOTIFICATIONS);
  const [isExportEnable] = useFeatureFlag(Feature.CASES_EXPORT);

  const { type } = useParams<{ type: CaseType }>();

  const { data: casesStats, error } = useQuery(fetchCasesStats, {
    caseType: type as CaseType
  });

  if (error) {
    openErrorToast('Ocorreu um erro na obtenção das estatísticas sobre casos.');
  }

  const fetchCasesHandler = useCallback(
    ({ searchTerm, orderByConfig }) => {
      fetchCasesDebounced({
        searchTerm,
        orderByConfig,
        setCases,
        setIsLoading,
        type
      });

      return () => {
        fetchCasesDebounced.cancel();
      };
    },
    [setCases, setIsLoading, type]
  );

  useEffect(() => {
    fetchCasesHandler({ orderByConfig, searchTerm });
  }, [orderByConfig, searchTerm, fetchCasesHandler]);

  const caseClickedHandler = (clickedCase: Case) =>
    history.push(`/cases/${clickedCase.id}`);

  const orderByClickedHandler = (field: string) => {
    let direction = 'asc';

    if (orderByConfig.field === field && orderByConfig.direction === 'asc') {
      direction = 'desc';
    }

    const headersUpdated = headers.map((header: CaseHeader) => {
      if (header.field === field) {
        return { ...header, direction };
      }

      return { ...header, direction: null };
    });

    setHeaders(headersUpdated);
    setOrderByConfig({ field, direction });
  };

  const setSearchQueryHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Layout>
      <Header
        actionsComponent={
          <div className={style.actionsContainer} data-testid="actions">
            {can(Action.CASOS_LISTAGEM_DE_CASOS_CONSULTAR) && (
              <InputField
                id="search"
                name="search"
                type="text"
                className={style.searchField}
                placeholder="Busque por nome do caso ou devedor…"
                value={searchTerm}
                icon="zoom"
                onChange={setSearchQueryHandler}
                disabled={isLoading}
              />
            )}
            <div className={style.actionsContent}>
              {isExportEnable &&
                can(Action.CASOS_LISTAGEM_DE_CASOS_EXPORTAR) && (
                  <ExportButton
                    isDisabled={isLoading || !cases.length}
                    caseIds={selectedCaseIds}
                  />
                )}

              {can(Action.CASOS_LISTAGEM_DE_CASOS_IMPORTAR) && (
                <DataImportField />
              )}

              {can(Action.CASOS_LISTAGEM_DE_CASOS_INCLUIR) && (
                <Link
                  asButton
                  small
                  highlight
                  to={generatePath(`/cases/new/:type`, { type })}
                >
                  Novo Caso
                </Link>
              )}
            </div>
          </div>
        }
        contentClassName={style.headerContent}
      >
        <Accordion
          title={`Casos de ${TYPE_DICTIONARY[type as CaseType]}`}
          titleExtra={
            <>
              {casesStats && (
                <span className={style.counter}>
                  ({casesStats.activeCasesCount + casesStats.inactiveCasesCount}
                  )
                </span>
              )}
              {isNotificationsEnable && <Notifications />}
            </>
          }
        >
          <div className={style.stats}>
            <Stats type={type as CaseType} />
          </div>
        </Accordion>
      </Header>

      <CaseList
        isLoading={isLoading}
        cases={cases || []}
        onCaseClick={caseClickedHandler}
        onOrderByClick={orderByClickedHandler}
        headers={headers}
        onSelectCase={setSelectedCaseIds}
        selectedCaseIds={selectedCaseIds}
        totalCases={casesStats?.totalCasesCount}
      />
    </Layout>
  );
};

export default List;
