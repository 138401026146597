import React, { ReactElement, useState } from 'react';
import cn from 'classnames';
import { InputLoader, IconTooltip } from 'ui';

import './Toggle.scss';

type Options = {
  value: string;
  label: string;
  disabled?: boolean;
}[];

type Props = {
  options: Options;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: string | undefined;
  title: string;
  showMode?: boolean;
  name?: string;
  dataTestId?: string;
  disabled?: boolean;
  isLoading?: boolean;
  error?: string;
  tooltipText?: string;
};

const Toggle = ({
  options,
  onChange,
  checked,
  title,
  showMode,
  dataTestId = 'toggle-input-wrapper',
  disabled,
  isLoading,
  error,
  tooltipText
}: Props): ReactElement => {
  const [name] = useState<string>(
    ((Math.random() * 0xffffff) << 0).toString(16)
  );

  const toggleClass = cn('toggle-field', {
    '-error': Boolean(error)
  });

  if (showMode) {
    const selectedOption:
      | { value: string; label: string }
      | undefined = options.find(option => option.value === checked);

    return (
      <div className={toggleClass} data-testid="toggle-show-mode">
        <span className="label">{title}</span>
        <span className="value">{selectedOption && selectedOption.label}</span>
      </div>
    );
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    !disabled && onChange && onChange(event);
  }

  if (isLoading) {
    return (
      <InputLoader
        id={`toggle-${name}-loading`}
        name={name}
        title={title}
        dataTestId={dataTestId}
      />
    );
  }

  return (
    <div className={toggleClass} data-testid={dataTestId}>
      <label className="label">
        {title}
        {tooltipText && (
          <span className="tooltipIcon">
            <IconTooltip text={tooltipText} className="tooltipText" />
          </span>
        )}
      </label>
      <div className="container">
        {options.map(({ value, label, disabled: optDisabled }) => {
          return (
            <div className="item" key={`toggle-key-${name}-${value}`}>
              <input
                data-testid={`toggle-${value}`}
                id={`toggle-${name}-${value}`}
                name={name}
                value={value}
                type="radio"
                onChange={handleChange}
                checked={checked === value}
                disabled={optDisabled || disabled}
              />
              <label htmlFor={`toggle-${name}-${value}`}>{label}</label>
            </div>
          );
        })}
      </div>
      {error && <p className="message">{error}</p>}
    </div>
  );
};

export default Toggle;
