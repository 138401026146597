import React, { ReactElement } from 'react';
import cn from 'classnames';

import style from './InfoArea.module.scss';

type Props = {
  value: string;
  label: string;
  dataTestId?: string;
  isLoading?: boolean;
  className?: string;
};

const InfoArea = ({
  dataTestId = 'info-field',
  isLoading,
  label,
  value,
  className = ''
}: Props): ReactElement => {
  const content = isLoading ? 'Carregando...' : value;
  const mainClassName = cn(style.infoArea, className);

  return (
    <div className={mainClassName} data-testid={dataTestId}>
      <span className={style.label}>{label}</span>
      <span className={style.value}>{content}</span>
    </div>
  );
};

export default InfoArea;
