import {
  ReclaimingParams,
  Reclaiming,
  Asset,
  AssetMissingDataAttribute
} from 'types';
import Client from './client';

function createReclaiming(
  reclaimingData: ReclaimingParams,
  caseId: string,
  contractId: string
) {
  return Client.post<ReclaimingParams, Reclaiming>(
    `cases/${caseId}/contracts/${contractId}/reclaimings`,
    reclaimingData
  );
}

function deleteReclaiming(caseId: string, id: string) {
  return Client.delete(`cases/${caseId}/reclaimings/${id}`);
}

function deleteReclaimingAsset(caseId: string, id: string, assetId: string) {
  return Client.delete<void>(
    `cases/${caseId}/reclaimings/${id}/assets/${assetId}`
  );
}

function updateReclaiming(
  caseId: string,
  id: string,
  reclaimingData: ReclaimingParams
) {
  return Client.put<ReclaimingParams, Reclaiming>(
    `cases/${caseId}/reclaimings/${id}`,
    reclaimingData
  );
}

function submitReclaiming({
  reclaiming,
  caseId,
  contractId,
  values
}: {
  reclaiming: Reclaiming | undefined;
  caseId: string;
  contractId: string;
  values: ReclaimingParams;
}) {
  if (reclaiming) return updateReclaiming(caseId, reclaiming.id, values);

  return createReclaiming(values, caseId, contractId);
}

type AddReclaimingAssetData = {
  assetIds: string[];
  assets: AssetMissingDataAttribute[];
};

function addReclaimingAsset(
  caseId: string,
  reclaimingId: string,
  data: AddReclaimingAssetData
) {
  return Client.put<AddReclaimingAssetData, Asset[]>(
    `cases/${caseId}/reclaimings/${reclaimingId}/assets`,
    data
  );
}

export {
  createReclaiming,
  deleteReclaiming,
  deleteReclaimingAsset,
  updateReclaiming,
  submitReclaiming,
  addReclaimingAsset
};
