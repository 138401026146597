import { Guarantee, GuaranteeFormAttributes, CaseEntity } from 'types';
import Client from './client';

function createGuarantee(
  guaranteeData: GuaranteeFormAttributes,
  caseId: string,
  contractId: string
) {
  return Client.post<GuaranteeFormAttributes, Guarantee>(
    `cases/${caseId}/contracts/${contractId}/guarantees`,
    guaranteeData
  );
}

function deleteGuarantee(caseId: string, id: string) {
  return Client.delete<void>(`cases/${caseId}/guarantees/${id}`);
}

function createContractGuaranteeRelationship({
  caseId,
  contractId,
  guaranteeId
}: {
  caseId: string;
  contractId: string;
  guaranteeId: string;
}) {
  return Client.post<void, Guarantee>(
    `cases/${caseId}/contracts/${contractId}/guarantees/${guaranteeId}/relationships`
  );
}

function removeGuaranteeFromContract({
  caseId,
  contractId,
  guaranteeId
}: {
  caseId: string;
  contractId: string;
  guaranteeId: string;
}) {
  return Client.delete<void>(
    `cases/${caseId}/contracts/${contractId}/guarantees/${guaranteeId}`
  );
}

function updateGuarantee(
  caseId: string,
  guaranteeData: GuaranteeFormAttributes,
  id: string
) {
  return Client.put<GuaranteeFormAttributes, Guarantee>(
    `cases/${caseId}/guarantees/${id}`,
    guaranteeData
  );
}

function submitGuarantee({
  guarantee,
  values,
  caseId,
  contractId
}: {
  guarantee: Guarantee | undefined;
  values: GuaranteeFormAttributes;
  caseId: string;
  contractId: string;
}) {
  if (guarantee) return updateGuarantee(caseId, values, guarantee.id);

  return createGuarantee(values, caseId, contractId);
}

function fetchCaseGuarantees({ caseId }: Pick<CaseEntity, 'caseId'>) {
  return Client.get<Guarantee[]>(`cases/${caseId}/guarantees`);
}

function deleteGuaranteeAsset(caseId: string, guaranteeId: string, id: string) {
  return Client.delete<void>(
    `cases/${caseId}/guarantees/${guaranteeId}/assets/${id}`
  );
}

type AddAssetData = {
  assetIds: string[];
};

function addGuaranteeAsset(
  caseId: string,
  guaranteeId: string,
  data: AddAssetData
) {
  return Client.post<AddAssetData, Guarantee>(
    `cases/${caseId}/guarantees/${guaranteeId}/assets`,
    data
  );
}

export {
  createGuarantee,
  deleteGuarantee,
  updateGuarantee,
  createContractGuaranteeRelationship,
  removeGuaranteeFromContract,
  submitGuarantee,
  fetchCaseGuarantees,
  deleteGuaranteeAsset,
  addGuaranteeAsset
};
