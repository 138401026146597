import React, { useContext } from 'react';
import { FieldArrayRenderProps, FormikProps, getIn } from 'formik';
import { CaseFormAttributes, AdverseParty } from 'types';
import { Card, FormContainer, Toggle, SelectField, Link, Button } from 'ui';
import { Action, can } from 'api/permissions';
import { AdversePartyAutoCompleteField, CaseContext } from 'components';

import './AdverseParties.scss';

type Item = {
  value: string | null;
  type: string | null;
  role: string | null;
};

type Props = {
  formikArrayHelpers: FieldArrayRenderProps;
  formik: FormikProps<CaseFormAttributes>;
};

const AdverseParties = ({ formikArrayHelpers, formik }: Props) => {
  const getNameFieldTitle = (index: number): string => {
    if (formik.values.adverseParties[index].type === 'individual')
      return 'Nome completo';
    return 'Razão social';
  };

  const addParty = () =>
    formikArrayHelpers.insert(formik.values.adverseParties.length, {
      name: '',
      type: 'individual',
      role: ''
    });

  const removeParty = (index: number) => () => {
    formikArrayHelpers.remove(index);
  };

  const handleAutoCompleteSelection = (
    selection: Item | null,
    index: number
  ) => {
    formik.setFieldValue(`adverseParties[${index}].name`, selection!.value);
    formik.setFieldValue(`adverseParties[${index}].type`, selection!.type);
    formik.setFieldValue(`adverseParties[${index}].role`, selection!.role);
  };

  const { caseData } = useContext(CaseContext);
  const negotiatorId = caseData?.negotiator?.id;

  const canUser = {
    addAdverseParties: can(
      Action.CASOS_INFORMACOES_BASICAS_PARTE_ADVERSA_INCLUIR,
      negotiatorId
    ),
    editAdverseParties: can(
      Action.CASOS_INFORMACOES_BASICAS_PARTE_ADVERSA_EDITAR,
      negotiatorId
    ),
    removeAdverseParties: can(
      Action.CASOS_INFORMACOES_BASICAS_PARTE_ADVERSA_EXCLUIR,
      negotiatorId
    )
  };

  const isDisabled = (adversePartyIndex: number) =>
    !(formik.initialValues.adverseParties[adversePartyIndex]?.name
      ? canUser.editAdverseParties
      : canUser.addAdverseParties);

  return (
    <div className="adverse-parties-container">
      <p className="label">Partes adversas</p>
      <Card>
        <FormContainer>
          {formik.values.adverseParties.map(
            (adverseParty: AdverseParty, index: number) => (
              <div className="row" key={`adverse-party-row-${index}`}>
                <Toggle
                  title="Tipo de pessoa"
                  options={[
                    { value: 'individual', label: 'Física' },
                    { value: 'company', label: 'Jurídica' }
                  ]}
                  onChange={event =>
                    formik.setFieldValue(
                      `adverseParties[${index}].type`,
                      event.target.value
                    )
                  }
                  checked={adverseParty.type}
                  disabled={isDisabled(index)}
                />

                <AdversePartyAutoCompleteField
                  id={`adverse-party-${index}-name`}
                  name={`adverseParties[${index}].name`}
                  type="text"
                  title={getNameFieldTitle(index)}
                  value={adverseParty.name}
                  onSelect={selection =>
                    handleAutoCompleteSelection(selection, index)
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={isDisabled(index)}
                  error={
                    getIn(formik.touched, `adverseParties[${index}].name`) &&
                    getIn(formik.errors, `adverseParties[${index}].name`)
                  }
                  dataToItem={data => ({
                    value: data.name,
                    type: data.type,
                    role: data.role
                  })}
                />

                <SelectField
                  id={`adverse-party-${index}-role`}
                  name={`adverseParties[${index}].role`}
                  title="Tipo de parte"
                  options={[
                    { value: 'office', label: 'Escritório' },
                    { value: 'advisor', label: 'Advisor' }
                  ]}
                  value={adverseParty.role}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    getIn(formik.touched, `adverseParties[${index}].role`) &&
                    getIn(formik.errors, `adverseParties[${index}].role`)
                  }
                  disabled={isDisabled(index)}
                />

                <div className="remove">
                  {canUser.removeAdverseParties && (
                    <Link
                      onClick={removeParty(index)}
                      iconName="close"
                      dataTestId="remove-adverse-party"
                    />
                  )}
                </div>
              </div>
            )
          )}
        </FormContainer>
        {canUser.addAdverseParties && (
          <Button highlight outline centered onClick={addParty}>
            Adicionar parte adversa
          </Button>
        )}
      </Card>
    </div>
  );
};

export default AdverseParties;
