import React, { useContext } from 'react';
import { CaseContext, LawsuitCard } from 'components';
import { ChildLawsuit, ApiPagination } from 'types';
import { Button, EmptyState, Link, Pagination, TwoColumnsMasonry } from 'ui';
import { useQueryCall } from 'hooks';
import { Action, can, fetchChildLawsuits, FetchChildLawsuitsParams } from 'api';

import style from './ChildLawsuitList.module.scss';

type Props = {
  childLawsuits: Array<ChildLawsuit>;
  parentLawsuitId: string;
  caseId: string;
  paginationParams: ApiPagination;
  parentLawsuitNumber: string;
};

const ChildLawsuitList = ({
  childLawsuits,
  parentLawsuitId,
  caseId,
  paginationParams,
  parentLawsuitNumber
}: Props) => {
  const {
    data: paginatedChildLawsuits,
    pagination,
    request: fetchLawsuit
  } = useQueryCall<ChildLawsuit[], FetchChildLawsuitsParams>(
    fetchChildLawsuits,
    { data: childLawsuits, pagination: paginationParams }
  );

  const showPagination: boolean = paginationParams.totalPages > 1;
  const { caseData } = useContext(CaseContext);
  const negotiatorId = caseData?.negotiator?.id;

  const canUser = {
    showChildLawsuit: can(
      Action.CASOS_PROCESSO_INFORMACOES_BASICAS_VER_PROCESSO,
      negotiatorId
    ),
    addChildLawsuit: can(
      Action.CASOS_PROCESSO_INFORMACOES_BASICAS_ADICIONAR_PROCESSO_VINCULADO,
      negotiatorId
    )
  };

  const columnRender = (data: ChildLawsuit[]) =>
    data.map(childLawsuit => {
      const goTo = {
        pathname: `/cases/${caseId}/internal_lawsuits/${parentLawsuitId}/children/${childLawsuit.id}`,
        state: { parentLawsuitNumber: parentLawsuitNumber }
      };

      return (
        <LawsuitCard
          className={style.card}
          key={childLawsuit.id}
          lawsuit={childLawsuit}
          dataTestId="child-card"
          title={
            <LawsuitCard.TriggerTitle
              lawsuit={childLawsuit}
              url={goTo}
              disabled={!canUser.showChildLawsuit}
            />
          }
        />
      );
    });

  const goToCreateChildLawsuit = {
    pathname: `/cases/${caseId}/internal_lawsuits/${parentLawsuitId}/children`,
    state: { parentLawsuitNumber: parentLawsuitNumber }
  };

  return (
    <div className={style.wrapper} id="children">
      <h3 className={style.title}>Processos vinculados</h3>

      {childLawsuits.length === 0 && (
        <EmptyState text="Não tem nenhum processo vinculado" />
      )}

      {paginatedChildLawsuits && (
        <TwoColumnsMasonry
          data={paginatedChildLawsuits!}
          render={columnRender}
        />
      )}

      {showPagination && pagination && (
        <Pagination
          currentPage={pagination!.number}
          itemsPerPage={pagination!.size}
          onCurrentPageChange={page => {
            fetchLawsuit({
              caseId,
              internalLawsuitId: parentLawsuitId,
              pagination: { page, pageSize: pagination!.size }
            });
          }}
          onItemsPerPageChange={pageSize => {
            fetchLawsuit({
              caseId,
              internalLawsuitId: parentLawsuitId,
              pagination: { page: pagination!.number, pageSize: pageSize }
            });
          }}
          totalEntries={pagination!.totalEntries}
          totalPages={pagination!.totalPages}
        />
      )}

      {canUser.addChildLawsuit && (
        <Link className={style.addLink} to={goToCreateChildLawsuit}>
          <Button centered small highlight outline>
            Adicionar processo vinculado
          </Button>
        </Link>
      )}
    </div>
  );
};

export default ChildLawsuitList;
