import React, { ReactNode } from 'react';
import cn from 'classnames';
import { Badge, Icon, IconTooltip } from 'ui';

import './SubTitle.scss';

type Props = {
  children: ReactNode;
  badgeText?: string | number | ReactNode;
  badgeTestId?: string;
  tooltipText?: string;
  iconName?: string;
  additionalInfo?: {
    label: string | ReactNode;
    value: string | ReactNode;
    dataTestId?: string;
  };
  actions?: ReactNode;
  className?: string;
};

const SubTitle = ({
  children,
  badgeText,
  badgeTestId = 'badge-id',
  tooltipText,
  iconName,
  additionalInfo,
  actions,
  className
}: Props) => {
  const containerClassName = cn(className, 'subtitle-default');

  return (
    <div className={containerClassName} data-testid="subTitle">
      <div className="header-item">
        {iconName && <Icon name={iconName} fill="#7A7E94" small />}
        <h3 className="title">{children}</h3>
        {badgeText !== undefined && (
          <Badge dataTestId={badgeTestId}>{badgeText}</Badge>
        )}
        {tooltipText && (
          <div className="status">
            <IconTooltip
              text={tooltipText}
              dataTestId="status-tooltip"
              icon="warning"
              color="#e53935"
            />
          </div>
        )}
      </div>

      <div className="header-info">
        {additionalInfo && (
          <h3 className="header-item" data-testid={additionalInfo.dataTestId}>
            {additionalInfo.label}
            <span className="value">{additionalInfo.value}</span>
          </h3>
        )}

        <div className="actions">{actions && actions}</div>
      </div>
    </div>
  );
};

export default SubTitle;
