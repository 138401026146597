import React, { useState, useRef, RefObject, ReactNode } from 'react';
import TetherComponent from 'react-tether';
import useOnclickOutside from 'react-cool-onclickoutside';
import cn from 'classnames';

import { LocationDescriptorObject } from 'history';
import { LocationState } from 'types';
import { Icon, ToolTip } from 'ui';
import { Link } from 'react-router-dom';
import styles from './DropDown.module.scss';

export type DropDownOption = {
  callback?: () => void;
  dataTestId?: string;
  description?: string | null;
  isDisabled?: boolean;
  isHidden?: boolean;
  isLink?: boolean;
  isNegative?: boolean;
  link?: string | LocationDescriptorObject<LocationState>;
  text: string;
};

type Props = {
  danger?: boolean;
  dataTestId?: string;
  dropDownTrigger?: ReactNode;
  highlight?: boolean;
  offset?: string;
  options: Array<DropDownOption>;
  smallerFont?: boolean;
  iconName?: string;
  iconColor?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
};

const DropDown = ({
  dataTestId = 'dropdown-trigger',
  dropDownTrigger,
  iconName = 'three-dots',
  iconColor = '#62667a',
  isDisabled = false,
  isLoading = false,
  offset,
  options,
  smallerFont
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleIsOpen = (): void => setIsOpen(!isOpen);
  const outsideRef = useRef<HTMLDivElement>(null);

  const trigger = dropDownTrigger ?? (
    <Icon name={iconName} fill={iconColor} small loadMode={isLoading} />
  );

  useOnclickOutside(outsideRef, () => {
    setIsOpen(false);
  });

  const handleOptionClick = (callback: () => void) => () => {
    callback();
    setIsOpen(false);
  };

  return (
    <TetherComponent
      offset={offset ?? '0px 0px'}
      className={styles.dropdownTether}
      attachment="top right"
      targetAttachment="bottom right"
      constraints={[
        {
          to: 'scrollParent',
          attachment: 'together'
        }
      ]}
      renderTarget={(ref: RefObject<Element>): ReactNode => (
        <button
          className={cn({ [styles.eventsNone]: isDisabled })}
          disabled={isDisabled}
          data-testid={dataTestId}
          type="button"
          ref={ref as RefObject<HTMLButtonElement>}
          onClick={toggleIsOpen}
        >
          {trigger}
        </button>
      )}
      renderElement={(ref: RefObject<Element>) =>
        isOpen && (
          <div ref={outsideRef}>
            <div
              ref={ref as RefObject<HTMLDivElement>}
              className={styles.dropdown}
              data-testid="dropdown-body"
            >
              <ul>
                {options.map(
                  (option: DropDownOption, index: number) =>
                    !option.isHidden && (
                      <li key={index}>
                        <ToolTip
                          content={option.description || ''}
                          placement="top"
                        >
                          <div data-testid={`tooltip-${option.dataTestId}`}>
                            {option.isLink ? (
                              <Link
                                data-testid={option.dataTestId}
                                className={cn([
                                  styles.option,
                                  option.isNegative && styles.optionNegative,
                                  option.isDisabled && styles.optionDisabled,
                                  smallerFont && styles.optionSmall
                                ])}
                                to={option.link || ''}
                                onClick={
                                  option.callback &&
                                  handleOptionClick(option.callback)
                                }
                              >
                                {option.text}
                              </Link>
                            ) : (
                              <button
                                className={cn([
                                  styles.option,
                                  option.isNegative && styles.optionNegative,
                                  option.isDisabled && styles.optionDisabled,
                                  smallerFont && styles.optionSmall
                                ])}
                                data-testid={option.dataTestId}
                                type="button"
                                title={option.text}
                                disabled={option.isDisabled}
                                onClick={
                                  option.callback &&
                                  handleOptionClick(option.callback)
                                }
                              >
                                {option.text}
                              </button>
                            )}
                          </div>
                        </ToolTip>
                      </li>
                    )
                )}
              </ul>
            </div>
          </div>
        )
      }
    />
  );
};

export default DropDown;
