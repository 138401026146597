import { InstagramPublication } from 'types';
import Client from './client';

function unbookmarkInstagramPublicationForCase({
  caseId,
  instagramPublicationId
}: {
  caseId: string;
  instagramPublicationId: string;
}) {
  return Client.delete(`cases/${caseId}/bookmarks/${instagramPublicationId}`);
}

function fetchBookmarkedInstagramPublicationsForCase({
  caseId,
  pagination
}: {
  caseId: string;
  pagination?: {
    page: number;
    pageSize: number;
  };
}) {
  return Client.get<InstagramPublication[]>(`cases/${caseId}/bookmarks`, {
    params: {
      page: pagination?.page.toString(),
      pageSize: pagination?.pageSize.toString()
    }
  });
}

export {
  unbookmarkInstagramPublicationForCase,
  fetchBookmarkedInstagramPublicationsForCase
};
