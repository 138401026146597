import React from 'react';

import { fetchEvents } from 'api';

import { useQuery, useToggles } from 'hooks';

import { CaseEvent } from 'types';

import {
  Stack,
  StackMargin,
  Card,
  TimeLine,
  TimelineColor,
  RadioSwitch
} from 'ui';

import { SummaryError } from 'components';
import SummaryLoader from '../SummaryLoader';

import style from './Events.module.scss';

import TimelineContent from './TimelineContent';

type Props = {
  caseId: string;
};

const Events = ({ caseId }: Props) => {
  const { data = [], isLoading, error } = useQuery<
    CaseEvent[],
    { caseId: string }
  >(fetchEvents, {
    caseId
  });
  const { toggle, isOpen } = useToggles<'fullView'>({ fullView: false });

  if (isLoading) {
    return <SummaryLoader title="Timeline negocial" />;
  }

  if (error) {
    return (
      <SummaryError
        title="Timeline negocial"
        message="Erro ao listar timeline negocial, tente novamente."
      />
    );
  }

  return (
    <Stack marginTop={StackMargin.XLARGE}>
      <div className={style.titleWrapper}>
        <h3 className={style.title}>Timeline negocial</h3>

        <div className={style.fullView}>
          <RadioSwitch
            label="Versão completa"
            onChange={toggle('fullView')}
            active={isOpen.fullView}
          />
        </div>
      </div>
      <Stack marginTop={StackMargin.LARGE}>
        <Card className={style.card}>
          {data.map(event => (
            <TimeLine
              id={event.id}
              title={event.title}
              date={event.date}
              key={event.id}
              dataTestId="event-item"
              color={TimelineColor.GRAY}
            >
              <TimelineContent event={event} expanded={isOpen.fullView} />
            </TimeLine>
          ))}
        </Card>
      </Stack>
    </Stack>
  );
};

export default Events;
