const setupFavicon = () => {
  window
    .matchMedia?.('(prefers-color-scheme: dark)')
    .addEventListener?.('change', setFavicon);

  setFavicon();
};

const setFavicon = () => {
  const isDarkMode = window.matchMedia?.('(prefers-color-scheme: dark)')
    .matches;

  const publicPath = '/';
  const icoType: string = isDarkMode ? 'favicon-darkmode.png' : 'favicon.png';
  const faviconElement: HTMLLinkElement | null = document.querySelector(
    '#favicon'
  );

  if (faviconElement) {
    faviconElement.href = publicPath + icoType;
  }
};

export default setupFavicon;
