import React from 'react';
import cn from 'classnames';
import './Select.scss';

type Options = {
  value: string;
  label: string;
  disabled?: boolean;
}[];

export type Props = {
  dataTestId?: string;
  disabled?: boolean;
  id: string;
  name: string;
  onBlur?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  optional?: boolean;
  options: Options;
  value?: string;
  isSmall?: boolean;
  optionalLabel?: string;
  title?: string;
};

const Select = ({
  dataTestId,
  disabled,
  id,
  isSmall,
  name,
  onBlur,
  onChange,
  optional,
  options,
  value,
  optionalLabel = 'Selecione...',
  title
}: Props) => {
  return (
    <select
      aria-label={title}
      className={cn('select', { '-small': isSmall })}
      data-testid={dataTestId}
      disabled={disabled}
      id={id}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
    >
      <option value="" disabled={!optional}>
        {disabled ? 'Indisponível' : optionalLabel}
      </option>
      {options.map(({ value, label, disabled }, index) => (
        <option key={`option-key-${index}`} value={value} disabled={disabled}>
          {label}
        </option>
      ))}
    </select>
  );
};

export default Select;
