import React from 'react';
import * as Yup from 'yup';
import { getIn, useFormik } from 'formik';

import { updateEntityDescription } from 'api';

import {
  Stack,
  StackMargin,
  SubTitle,
  Card,
  Button,
  openErrorToast,
  openSuccessToast,
  TextArea
} from 'ui';

import { EntityAttributes, OnSubmit } from 'types';

import style from './Form.module.scss';

type Props = {
  className?: string;
  entity: EntityAttributes;
  caseId: string;
  isDisabled: boolean;
  onUpdate: (_: EntityAttributes) => void;
};

const validationSchema = Yup.object().shape({
  description: Yup.string()
    .max(512, 'Precisa ter menos de 512 caracteres')
    .nullable()
    .notRequired()
});

const Form = ({ className, entity, caseId, isDisabled, onUpdate }: Props) => {
  const onSubmit: OnSubmit<{ description: string }> = async data => {
    try {
      const response = await updateEntityDescription({
        caseId,
        entityId: entity.id,
        data
      });

      onUpdate(response.data);

      openSuccessToast('Descrição atualizada com sucesso.');
    } catch (error) {
      openErrorToast('Houve um erro ao atualizar a descrição');
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { description: entity.description ?? '' },
    validationSchema: validationSchema,
    onSubmit
  });

  return (
    <Stack
      marginTop={StackMargin.XLARGE}
      marginBottom={StackMargin.LARGE}
      className={className}
    >
      <Card>
        <div className={style.cardHeader}>
          <SubTitle className={style.subtitle}>Campos editáveis</SubTitle>
          <Button
            type="submit"
            highlight
            small
            disabled={!formik.dirty || isDisabled}
            onClick={formik.handleSubmit}
          >
            Salvar
          </Button>
        </div>
        <Stack marginTop={StackMargin.SMALL} marginBottom={StackMargin.SMALL}>
          <div className={style.textAreaWithBottom}>
            <TextArea
              id="description"
              name="description"
              title="Descrição(máximo 512 caracteres)"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                getIn(formik.touched, 'description') &&
                getIn(formik.errors, 'description')
              }
              value={formik.values.description}
              disabled={isDisabled}
            />
          </div>
        </Stack>
      </Card>
    </Stack>
  );
};

export default Form;
