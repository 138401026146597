import { handleDateFieldChange, getFormikError } from 'domain/forms';
import { eventTypeOptions } from 'domain/caseEvents';

import React, { useContext } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { FileField, CaseContext } from 'components';
import { Action, can } from 'api';
import {
  FormContainer,
  InputField,
  TextArea,
  Button,
  DatePickerField,
  SelectField,
  Stack,
  StackMargin
} from 'ui';

import { CaseEventFormAttributes, OnSubmit, CaseEvent, User } from 'types';

import style from './EventForm.module.scss';

const initialValues: CaseEventFormAttributes = {
  content: '',
  date: '',
  description: '',
  fileUploadIds: [],
  title: '',
  type: null
};

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Campo obrigatório'),
  date: Yup.string().required('Campo obrigatório'),
  content: Yup.string().notRequired(),
  description: Yup.string().required('Campo obrigatório'),
  type: Yup.string()
    .notRequired()
    .nullable()
});

type Props = {
  onDelete?: (id: string) => void;
  caseId: string;
  eventData?: Partial<CaseEvent>;
  onSubmit: OnSubmit<CaseEventFormAttributes>;
};

const formatInitialData = (
  eventData?: Partial<CaseEvent>
): CaseEventFormAttributes => {
  if (!eventData) return initialValues;

  return {
    content: eventData.content ?? '',
    date: eventData.date ?? '',
    description: eventData.description ?? '',
    type: eventData.type ?? null,
    title: eventData.title ?? '',
    fileUploadIds: eventData.fileUploads?.map(file => file.id) ?? []
  };
};

const createdBy = (user?: User) =>
  user?.name && (
    <Stack marginTop={StackMargin.ZERO}>
      <span className={style.createdBy}>Evento criado por {user.name}</span>
    </Stack>
  );

const EventForm = ({ caseId, eventData, onSubmit, onDelete }: Props) => {
  const formik = useFormik({
    initialValues: formatInitialData(eventData),
    validationSchema,
    onSubmit
  });

  const { caseData } = useContext(CaseContext);

  const isDeleteVisible = eventData && eventData.id && onDelete;

  const handleDelete = () => {
    eventData?.id && onDelete && onDelete(eventData.id);
  };

  const hasError = getFormikError(formik);

  const negotiatorId = caseData?.negotiator?.id;

  const canUser = {
    createEvent: can(Action.CASOS_ACOMPANHAMENTO_EVENTO_INCLUIR, negotiatorId),
    editEvent: can(Action.CASOS_ACOMPANHAMENTO_EVENTO_EDITAR, negotiatorId),
    deleteEvent: can(Action.CASOS_ACOMPANHAMENTO_EVENTO_EXCLUIR, negotiatorId),
    addFiles: can(
      Action.CASOS_ACOMPANHAMENTO_ARQUIVOS_EVENTO_INCLUIR,
      negotiatorId
    ),
    deleteFiles: can(
      Action.CASOS_ACOMPANHAMENTO_ARQUIVOS_EVENTO_EXCLUIR,
      negotiatorId
    ),
    showFiles: can(
      Action.CASOS_ACOMPANHAMENTO_ARQUIVOS_EVENTO_CONSULTAR,
      negotiatorId
    )
  };

  const canEditOrCreateEvent = eventData
    ? canUser.editEvent
    : canUser.createEvent;

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormContainer className="col-2">
        <InputField
          id="event-title"
          name="title"
          title="Título"
          type="text"
          value={formik.values.title}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={hasError('title')}
          disabled={!canEditOrCreateEvent}
        />
        <DatePickerField
          id="event-date"
          name="date"
          title="Data"
          placeholder="Selecione..."
          value={formik.values.date}
          onChange={handleDateFieldChange(
            formik.setFieldValue,
            formik.setFieldTouched,
            'date'
          )}
          onBlur={formik.handleBlur}
          error={hasError('date')}
          disabled={!canEditOrCreateEvent}
        />
      </FormContainer>
      <FormContainer>
        <SelectField
          id="event-type"
          name="type"
          value={formik.values.type ?? ''}
          title="Tipo (opcional)"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          optional
          options={eventTypeOptions()}
          error={hasError('type')}
          disabled={!canEditOrCreateEvent}
        />
        <InputField
          id="event-description"
          name="description"
          title="Descrição"
          type="text"
          value={formik.values.description}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          dataTestId="event-description"
          error={hasError('description')}
          disabled={!canEditOrCreateEvent}
        />

        <TextArea
          id="event-content"
          name="content"
          title="Conteúdo"
          rows={2}
          value={formik.values.content}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={hasError('content')}
          disabled={!canEditOrCreateEvent}
        />

        {createdBy(eventData?.user)}

        {canUser.showFiles && (
          <FileField
            caseId={caseId}
            title="Arquivos"
            name="fileUploadIds"
            id="fileUploadIds"
            onUploadSuccess={ids => {
              formik.setFieldValue('fileUploadIds', ids);
            }}
            dataTestId="event-file-upload"
            initialFiles={eventData?.fileUploads}
            showAdd={canUser.addFiles}
            showDelete={canUser.deleteFiles}
          />
        )}

        <div className={style.actions}>
          {canUser.deleteEvent && isDeleteVisible && (
            <Button danger outline onClick={handleDelete}>
              Excluir evento
            </Button>
          )}
          {(canEditOrCreateEvent ||
            canUser.addFiles ||
            canUser.deleteFiles) && (
            <Button
              disabled={eventData && !formik.dirty}
              highlight
              onClick={formik.handleSubmit}
            >
              {eventData ? 'Salvar' : 'Criar evento'}
            </Button>
          )}
        </div>
      </FormContainer>
    </form>
  );
};

export default EventForm;
