import { getExpectedTableQueries } from 'domain/reports';
import uniq from 'lodash/uniq';
import {
  ProviderName,
  ProviderType,
  Query,
  QueryStatus,
  Report,
  ReportExpectedTable
} from 'types';
import { joinArray } from 'utils';

const NAME_DICTIONARY = {
  [ProviderName.ASSERTIVA]: 'Assertiva',
  [ProviderName.DIGESTO]: 'Digesto',
  [ProviderName.NEOWAY]: 'Neoway',
  [ProviderName.REFINARIA_INCRA]: 'Refinaria Incra',
  [ProviderName.REFINARIA_SOCIAL]: 'Refinaria Social',
  [ProviderName.INSTAGRAM]: 'Instagram',
  [ProviderName.REFINARIA]: 'Refinaria',
  [ProviderName.GOLIVE]: 'Go Live',
  [ProviderName.SERPRO]: 'Serpro'
};

const TYPE_DICTIONARY = {
  [ProviderType.ALL]: 'informações básicas',
  [ProviderType.EXTERNAL_LAWSUIT]: 'processos judiciais',
  [ProviderType.EXTERNAL_LAWSUIT_MORE_INFO]: 'informações de cada processo',
  [ProviderType.FAMILY]: 'família',
  [ProviderType.FIPE_PRICE]: 'preço da tabela Fipe de veículos',
  [ProviderType.INSTAGRAM_PROFILES]: 'informações do Instagram',
  [ProviderType.LEGAL_REPORT]: 'definição de parâmetros na busca de processos',
  [ProviderType.OWNERS_BY_RURAL_PROPERTY]:
    'informações sobre os donos de um imóvel rural',
  [ProviderType.RURAL_PROPERTY]: 'imóveis rurais',
  [ProviderType.RURAL_PROPERTIES]: 'imóveis rurais',
  [ProviderType.VEHICLES]: 'veículos'
};

export function providerName(name: ProviderName): string {
  return NAME_DICTIONARY[name] || name;
}

export function providerNames(names: ProviderName[]): string[] {
  return names.map(name => providerName(name));
}

export function providerType(type: ProviderType): string {
  return TYPE_DICTIONARY[type] || type;
}

const neowayDependent = [
  ProviderName.DIGESTO,
  ProviderName.REFINARIA_INCRA,
  ProviderName.REFINARIA_SOCIAL,
  ProviderName.GOLIVE
];

const serproDependent = [ProviderName.GOLIVE];

export function providerStatus(
  query: Query,
  hasNeowayFailed?: boolean,
  hasSerproFailed?: boolean
): string {
  if (
    hasNeowayFailed &&
    hasSerproFailed &&
    (neowayDependent.includes(query.provider) ||
      serproDependent.includes(query.provider))
  ) {
    return `não pôde executar, pois os provedores de PF/PJ falharam`;
  }

  if (hasNeowayFailed && neowayDependent.includes(query.provider)) {
    return `não pôde executar, pois o provedor ${providerName(
      ProviderName.NEOWAY
    )} falhou`;
  }

  if (query.status === QueryStatus.PENDING) {
    return ' executando...';
  }

  return 'falhou';
}

type CheckProviderStatusArgs = {
  report: Report;
  status: QueryStatus;
  providerName: ProviderName;
};

export const checkProviderStatus = ({
  report,
  providerName,
  status
}: CheckProviderStatusArgs): boolean => {
  return report.queries.some(
    (query: Query) => query.provider === providerName && query.status === status
  );
};

export const checkProviderFailed = (
  providerName: ProviderName,
  tableName: ReportExpectedTable,
  data?: Report | null
) =>
  data?.queries.some((query: Query) => {
    return (
      query.provider === providerName &&
      query.status === QueryStatus.FAILED &&
      query.expectedTables.includes(tableName)
    );
  }) ?? false;

export function providerTooltipText(
  data: Report,
  tableName: ReportExpectedTable
): string {
  const errors = getExpectedTableQueries(data, tableName, 'error');

  const hasNeowayFailed = checkProviderFailed(
    ProviderName.NEOWAY,
    tableName,
    data
  );
  const hasSerproFailed = checkProviderFailed(
    ProviderName.SERPRO,
    tableName,
    data
  );

  if (tableName === ReportExpectedTable.EXTERNAL_LAWSUIT && hasNeowayFailed) {
    return `Provedor ${providerName(
      ProviderName.DIGESTO
    )} não pôde executar, pois o provedor ${providerName(
      ProviderName.NEOWAY
    )} falhou`;
  }

  if (
    tableName === ReportExpectedTable.INDIVIDUAL ||
    tableName === ReportExpectedTable.COMPANY
  ) {
    const failedProviders: Array<string> = [];
    if (hasNeowayFailed)
      failedProviders.push(providerName(ProviderName.NEOWAY));

    if (hasSerproFailed)
      failedProviders.push(providerName(ProviderName.SERPRO));

    if (failedProviders.length > 0) {
      const isPlural = failedProviders.length > 1;
      const providers = failedProviders.sort().join(', ');

      return isPlural
        ? `Provedores: ${providers} falharam`
        : `Provedor: ${providers} falhou`;
    }

    return '';
  }

  if (errors && errors.length > 0) {
    return errors
      .map(query => {
        const provider = query.provider;

        return `Provedor ${providerName(provider)} ${providerStatus(
          query,
          hasNeowayFailed,
          hasSerproFailed
        )}`;
      })
      .join(';\n');
  }

  return '';
}

export function providerTableText(
  data: Report,
  tableName: ReportExpectedTable
): string {
  const errors = getExpectedTableQueries(data, tableName, 'error');
  const success = getExpectedTableQueries(data, tableName, 'success');

  const hasNeowayFailed = checkProviderFailed(
    ProviderName.NEOWAY,
    tableName,
    data
  );
  const hasSerproFailed = checkProviderFailed(
    ProviderName.SERPRO,
    tableName,
    data
  );

  if (hasNeowayFailed && tableName === ReportExpectedTable.EXTERNAL_LAWSUIT) {
    return `A consulta do provedor ${providerName(
      ProviderName.DIGESTO
    )} não pode ser executada, pois o provedor ${providerName(
      ProviderName.NEOWAY
    )} falhou`;
  }

  if (errors && errors.length > 0) {
    return errors
      .map(query => {
        return `A consulta do provedor ${providerName(
          query.provider
        )} ${providerStatus(query, hasNeowayFailed, hasSerproFailed)}`;
      })
      .join('; ');
  }

  if (success && success.length > 0) {
    return `Nenhum dado encontrado pelo(s) provedor(es) ${joinArray(
      providerNames(uniq(success.map(query => query.provider)))
    )}`;
  }

  return 'Nenhum dado encontrado';
}
