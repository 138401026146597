import React, { ReactElement } from 'react';
import cn from 'classnames';
import './RadioSwitch.scss';

type Props = {
  active: boolean;
  onChange: () => void;
  dataTestId?: string;
  inputId?: string;
  label?: string | ReactElement;
  isLoading?: boolean;
  disabled?: boolean;
};

const RadioSwitch = ({
  active,
  onChange,
  dataTestId = 'radio-switch',
  inputId = 'radio-switch-new',
  isLoading = false,
  disabled = false,
  label
}: Props) => {
  return (
    <div
      className={cn('radio-switch', {
        disabled: Boolean(disabled) || Boolean(isLoading)
      })}
    >
      {label && (
        <label className="inputLabel" htmlFor={inputId}>
          {label}
        </label>
      )}
      <input
        data-testid={dataTestId}
        checked={active}
        onChange={onChange}
        className="checkbox"
        id={inputId}
        type="checkbox"
        disabled={disabled || isLoading}
      />
      <label
        className={cn('label', {
          loading: Boolean(isLoading),
          disabled: Boolean(disabled)
        })}
        htmlFor={inputId}
      >
        <span className="button" />
      </label>
    </div>
  );
};

export default RadioSwitch;
