import { usePaginationContext } from 'components';
import React from 'react';
import { PaginatedResource } from 'types';
import TableOrderBy from 'ui/Table/TableOrderBy/TableOrderBy';

type Props = {
  children: string;
  resource: PaginatedResource;
  field: string;
};

const OrderByWithContext = ({ children, resource, field }: Props) => {
  const { changeOrderBy, getOrderBy } = usePaginationContext();

  const currentOrderBy = getOrderBy(resource)?.orderBy;

  let direction: 'asc' | 'desc' | undefined;

  if (currentOrderBy) {
    const { field: currentField, value: currentValue } = currentOrderBy;

    if (currentField === field) direction = currentValue;
  }

  const handleClick = () => {
    changeOrderBy(resource, {
      field,
      value: direction === 'asc' ? 'desc' : 'asc'
    });
  };

  return (
    <TableOrderBy onClick={handleClick} direction={direction}>
      {children}
    </TableOrderBy>
  );
};

export default OrderByWithContext;
