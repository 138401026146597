import {
  CnpjSituationColor,
  CnpjSpecialSituationColor
} from 'domain/cnpjSituation';
import { CPFSituationColor } from 'domain/cpfSituation';
import { providerName } from 'domain/providers';
import React, { useState } from 'react';

import cn from 'classnames';

import { ProviderTooltip } from 'components';

import {
  ReportFields,
  DivergentDataProvider,
  DivergentDataValue,
  Report,
  ReportCompany,
  ReportIndividual,
  cnpjSituationTitle,
  cnpjSpecialSituationTitle,
  cpfSituationName,
  CPFSituation,
  CnpjSituation,
  CnpjSpecialSituation,
  CompanySizeTitle,
  CompanySize
} from 'types';

import ColoredBadge from 'components/ColoredBadge/ColoredBadge';

import { CardInfo, ToolTip, Icon } from 'ui';
import {
  applyMask,
  applyDateMask,
  handleNullValue,
  reportFieldFirstValue,
  handleMultipleProviders
} from 'utils';
import { useToggles } from 'hooks';

import AlertDivergentData from '../AlertDivergentData';
import ModalDivergentDataProvider from './ModalDivergentDataProvider';

import style from './BasicInfo.module.scss';

type Props = {
  report?: Report;
  isSummary?: boolean;
  providerStatus?: string;
};

type PoliticallyExposedPerson = {
  level: string | null;
  position: string | null;
};

const BasicInfo = ({ report, isSummary = false, providerStatus }: Props) => {
  const [divergentDataProvider, setDivergentDataProvider] = useState<
    DivergentDataProvider
  >();

  const { isOpen, toggle, toggleTrigger } = useToggles<
    'divergentDataProviderModal'
  >({
    divergentDataProviderModal: false
  });

  const mountPoliticallyExposedPerson = ({
    level,
    position
  }: PoliticallyExposedPerson) => {
    if (level && position) {
      return `Sim (${level}, ${position})`;
    }

    return 'Não';
  };

  const mountObitYearMessage = (
    obitYearReport: ReportFields<number>[]
  ): string => {
    const provider = handleMultipleProviders(obitYearReport)?.[0];
    const obitYear = handleNullValue(reportFieldFirstValue(obitYearReport));

    if (!provider) return '-';

    return `${providerName(provider)}: Falecimento em ${obitYear}`;
  };

  function openDivergentDataModal(
    label: string,
    divergentData: DivergentDataValue
  ) {
    setDivergentDataProvider({ label: label, divergentData: divergentData });
    toggleTrigger('divergentDataProviderModal');
  }

  const makeCardInfoPersonalInformation = (
    individual: ReportIndividual | null
  ) => {
    return [
      {
        label: 'Nome completo',
        value: handleNullValue(reportFieldFirstValue(individual?.name)),
        alert: (
          <AlertDivergentData
            divergentData={individual?.name}
            openDivergentDataModal={() =>
              openDivergentDataModal('Nome completo', individual?.name)
            }
          />
        ),
        tooltip:
          !isSummary && individual?.name ? (
            <ProviderTooltip
              providers={handleMultipleProviders(individual.name)}
            />
          ) : null
      },
      {
        label: 'Nome da mãe',
        value: handleNullValue(reportFieldFirstValue(individual?.motherName)),
        alert: (
          <AlertDivergentData
            divergentData={individual?.motherName}
            openDivergentDataModal={() =>
              openDivergentDataModal('Nome da mãe', individual?.motherName)
            }
          />
        ),
        tooltip:
          !isSummary && individual?.motherName ? (
            <ProviderTooltip
              providers={handleMultipleProviders(individual.motherName)}
            />
          ) : null
      },
      {
        label: 'Data de nascimento',
        value: handleNullValue(
          reportFieldFirstValue(individual?.dateOfBirth),
          'string',
          applyDateMask
        ),
        alert: (
          <AlertDivergentData
            divergentData={individual?.dateOfBirth}
            openDivergentDataModal={() =>
              openDivergentDataModal(
                'Data de nascimento',
                individual?.dateOfBirth
              )
            }
          />
        ),
        tooltip:
          !isSummary && individual?.dateOfBirth ? (
            <ProviderTooltip
              providers={handleMultipleProviders(individual.dateOfBirth)}
            />
          ) : null
      },
      {
        label: 'CPF',
        value: handleNullValue(
          reportFieldFirstValue(individual?.cpf),
          'string',
          value => applyMask('cpf', value)
        ),
        alert: (
          <AlertDivergentData
            divergentData={individual?.cpf}
            openDivergentDataModal={() =>
              openDivergentDataModal('CPF', individual?.cpf)
            }
          />
        ),
        tooltip:
          !isSummary && individual?.cpf ? (
            <ProviderTooltip
              providers={handleMultipleProviders(individual.cpf)}
            />
          ) : null
      },
      {
        label: 'Situação cadastral',
        value: reportFieldFirstValue(individual?.cpfSituation) ? (
          <ColoredBadge
            type={
              CPFSituationColor[
                handleNullValue(reportFieldFirstValue(individual?.cpfSituation))
              ]
            }
            title={handleNullValue(
              cpfSituationName(
                reportFieldFirstValue(individual?.cpfSituation) as CPFSituation
              )
            )}
          />
        ) : (
          handleNullValue(
            cpfSituationName(
              reportFieldFirstValue(individual?.cpfSituation) as CPFSituation
            )
          )
        ),
        alert: (
          <AlertDivergentData
            divergentData={individual?.cpfSituation}
            openDivergentDataModal={() =>
              openDivergentDataModal(
                'Situação cadastral',
                individual?.cpfSituation
              )
            }
          />
        ),
        tooltip:
          !isSummary && individual?.cpfSituation ? (
            <ProviderTooltip
              providers={handleMultipleProviders(individual.cpfSituation)}
            />
          ) : null
      },
      {
        label: 'Idade',
        value: `${
          reportFieldFirstValue(individual?.age)
            ? `${reportFieldFirstValue(individual?.age)} anos`
            : handleNullValue(reportFieldFirstValue(individual?.age))
        }`,
        alert: (
          <AlertDivergentData
            divergentData={individual?.age}
            openDivergentDataModal={() =>
              openDivergentDataModal('Idade', individual?.age)
            }
          />
        ),
        faded: Boolean(individual?.obitYear?.length),
        tooltip:
          !isSummary && individual?.age ? (
            <div style={{ display: 'flex' }}>
              {individual.obitYear?.length ? (
                <ToolTip
                  content={mountObitYearMessage(individual.obitYear)}
                  placement="top"
                >
                  <span style={{ marginRight: 8 }}>
                    <Icon fill={'#B2B5C2'} name="add-rounded" small />
                  </span>
                </ToolTip>
              ) : null}
              <ProviderTooltip
                providers={handleMultipleProviders(individual.age)}
              />
            </div>
          ) : null
      },
      {
        label: 'Sexo',
        value: handleNullValue(reportFieldFirstValue(individual?.sex)),
        alert: (
          <AlertDivergentData
            divergentData={individual?.sex}
            openDivergentDataModal={() =>
              openDivergentDataModal('Sexo', individual?.sex)
            }
          />
        ),
        tooltip:
          !isSummary && individual?.sex ? (
            <ProviderTooltip
              providers={handleMultipleProviders(individual.sex)}
            />
          ) : null
      },
      {
        label: 'Renda estimada',
        value: handleNullValue(
          reportFieldFirstValue(individual?.incomePrediction)
        ),
        alert: (
          <AlertDivergentData
            divergentData={individual?.incomePrediction}
            openDivergentDataModal={() =>
              openDivergentDataModal(
                'Renda estimada',
                individual?.incomePrediction
              )
            }
          />
        ),
        tooltip:
          !isSummary && individual?.incomePrediction ? (
            <ProviderTooltip
              providers={handleMultipleProviders(individual.incomePrediction)}
            />
          ) : null
      },
      {
        label: 'Pessoa politicamente exposta',
        value: mountPoliticallyExposedPerson({
          level:
            reportFieldFirstValue(individual?.politicalLevel)?.toString() ??
            null,
          position:
            reportFieldFirstValue(individual?.politicalPosition)?.toString() ??
            null
        }),
        tooltip:
          !isSummary &&
          individual?.politicalLevel &&
          individual?.politicalPosition ? (
            <ProviderTooltip
              providers={handleMultipleProviders(individual?.politicalLevel)}
            />
          ) : null
      }
    ];
  };

  const makeCardInfoBasicInformation = (company: ReportCompany | null) => {
    return [
      {
        label: 'Razão social',
        value: handleNullValue(reportFieldFirstValue(company?.businessName)),
        alert: (
          <AlertDivergentData
            divergentData={company?.businessName}
            openDivergentDataModal={() =>
              openDivergentDataModal('Razão social', company?.businessName)
            }
          />
        ),
        tooltip:
          !isSummary && company?.businessName ? (
            <ProviderTooltip
              providers={handleMultipleProviders(company.businessName)}
            />
          ) : null
      },
      {
        label: 'Nome fantasia',
        value: handleNullValue(reportFieldFirstValue(company?.tradeName)),
        alert: (
          <AlertDivergentData
            divergentData={company?.tradeName}
            openDivergentDataModal={() =>
              openDivergentDataModal('Nome fantasia', company?.tradeName)
            }
          />
        ),
        tooltip:
          !isSummary && company?.tradeName ? (
            <ProviderTooltip
              providers={handleMultipleProviders(company.tradeName)}
            />
          ) : null
      },
      {
        label: 'CNPJ',
        value: handleNullValue(
          reportFieldFirstValue(company?.cnpj),
          'string',
          value => applyMask('cnpj', value)
        ),
        alert: (
          <AlertDivergentData
            divergentData={company?.cnpj}
            openDivergentDataModal={() =>
              openDivergentDataModal('CNPJ', company?.cnpj)
            }
          />
        ),
        tooltip:
          !isSummary && company?.cnpj ? (
            <ProviderTooltip
              providers={handleMultipleProviders(company.cnpj)}
            />
          ) : null
      },
      {
        label: 'Situação cadastral',
        value: reportFieldFirstValue(company?.cnpjSituation) ? (
          <ColoredBadge
            type={
              CnpjSituationColor[
                handleNullValue(reportFieldFirstValue(company?.cnpjSituation))
              ]
            }
            title={handleNullValue(
              cnpjSituationTitle(
                reportFieldFirstValue(company?.cnpjSituation) as CnpjSituation
              )
            )}
          />
        ) : (
          handleNullValue(reportFieldFirstValue(company?.cnpjSituation))
        ),
        alert: (
          <AlertDivergentData
            divergentData={company?.cnpjSituation}
            openDivergentDataModal={() =>
              openDivergentDataModal(
                'Situação cadastral',
                company?.cnpjSituation
              )
            }
          />
        ),
        tooltip:
          !isSummary && company?.cnpjSituation ? (
            <ProviderTooltip
              providers={handleMultipleProviders(company.cnpjSituation)}
            />
          ) : null
      },
      {
        label: 'Data da situação cadastral',
        value: handleNullValue(
          reportFieldFirstValue(company?.cnpjSituationDate),
          'string',
          applyDateMask
        ),
        alert: (
          <AlertDivergentData
            divergentData={company?.cnpjSituationDate}
            openDivergentDataModal={() =>
              openDivergentDataModal(
                'Data da situação cadastral',
                company?.cnpjSituationDate
              )
            }
          />
        ),
        tooltip:
          !isSummary && company?.cnpjSituationDate ? (
            <ProviderTooltip
              providers={handleMultipleProviders(company.cnpjSituationDate)}
            />
          ) : null
      },
      {
        label: 'Situação especial',
        value: reportFieldFirstValue(company?.cnpjSpecialSituation) ? (
          <ColoredBadge
            type={
              CnpjSpecialSituationColor[
                handleNullValue(
                  reportFieldFirstValue(company?.cnpjSpecialSituation)
                )
              ]
            }
            title={handleNullValue(
              cnpjSpecialSituationTitle(
                reportFieldFirstValue(
                  company?.cnpjSpecialSituation
                ) as CnpjSpecialSituation
              )
            )}
          />
        ) : (
          handleNullValue(reportFieldFirstValue(company?.cnpjSpecialSituation))
        ),
        alert: (
          <AlertDivergentData
            divergentData={company?.cnpjSpecialSituation}
            openDivergentDataModal={() =>
              openDivergentDataModal(
                'Situação especial',
                company?.cnpjSpecialSituation
              )
            }
          />
        ),
        tooltip:
          !isSummary && company?.cnpjSpecialSituation ? (
            <ProviderTooltip
              providers={handleMultipleProviders(company.cnpjSpecialSituation)}
            />
          ) : null
      },
      {
        label: 'Data que entrou em situação especial',
        value: handleNullValue(
          reportFieldFirstValue(company?.cnpjSpecialSituationDate),
          'string',
          applyDateMask
        ),
        alert: (
          <AlertDivergentData
            divergentData={company?.cnpjSpecialSituationDate}
            openDivergentDataModal={() =>
              openDivergentDataModal(
                'Data que entrou em situação especial',
                company?.cnpjSpecialSituationDate
              )
            }
          />
        ),
        tooltip:
          !isSummary && company?.cnpjSpecialSituationDate ? (
            <ProviderTooltip
              providers={handleMultipleProviders(
                company.cnpjSpecialSituationDate
              )}
            />
          ) : null
      },
      {
        label: 'Natureza jurídica',
        value: handleNullValue(reportFieldFirstValue(company?.legalNature)),
        alert: (
          <AlertDivergentData
            divergentData={company?.legalNature}
            openDivergentDataModal={() =>
              openDivergentDataModal('Natureza jurídica', company?.legalNature)
            }
          />
        ),
        tooltip:
          !isSummary && company?.legalNature ? (
            <ProviderTooltip
              providers={handleMultipleProviders(company.legalNature)}
            />
          ) : null
      },
      {
        label: 'Data de abertura',
        value: handleNullValue(
          reportFieldFirstValue(company?.openingDate),
          'string',
          applyDateMask
        ),
        alert: (
          <AlertDivergentData
            divergentData={company?.openingDate}
            openDivergentDataModal={() =>
              openDivergentDataModal('Data de abertura', company?.openingDate)
            }
          />
        ),
        tooltip:
          !isSummary && company?.openingDate ? (
            <ProviderTooltip
              providers={handleMultipleProviders(company.openingDate)}
            />
          ) : null
      },
      {
        label: 'Porte da empresa',
        value: handleNullValue(
          CompanySizeTitle(
            reportFieldFirstValue(company?.companySize) as CompanySize
          )
        ),
        alert: (
          <AlertDivergentData
            divergentData={company?.companySize}
            openDivergentDataModal={() =>
              openDivergentDataModal('Porte da empresa', company?.companySize)
            }
          />
        ),
        tooltip:
          !isSummary && company?.companySize ? (
            <ProviderTooltip
              providers={handleMultipleProviders(company.companySize)}
            />
          ) : null
      },
      {
        label: 'Nº de funcionários',
        value: handleNullValue(reportFieldFirstValue(company?.totalEmployees)),
        alert: (
          <AlertDivergentData
            divergentData={company?.totalEmployees}
            openDivergentDataModal={() =>
              openDivergentDataModal(
                'Nº de funcionários',
                company?.totalEmployees
              )
            }
          />
        ),
        tooltip:
          !isSummary && company?.totalEmployees ? (
            <ProviderTooltip
              providers={handleMultipleProviders(company.totalEmployees)}
            />
          ) : null
      },
      {
        label: 'Faturamento estimado',
        value: handleNullValue(
          reportFieldFirstValue(company?.estimatedRevenue)
        ),
        alert: (
          <AlertDivergentData
            divergentData={company?.estimatedRevenue}
            openDivergentDataModal={() =>
              openDivergentDataModal(
                'Faturamento estimado',
                company?.estimatedRevenue
              )
            }
          />
        ),
        tooltip:
          !isSummary && company?.estimatedRevenue ? (
            <ProviderTooltip
              providers={handleMultipleProviders(company.estimatedRevenue)}
            />
          ) : null
      }
    ];
  };

  if (!report) {
    return null;
  }

  const className = cn({
    [style.summary]: isSummary
  });

  return (
    <>
      <ModalDivergentDataProvider
        isOpen={isOpen.divergentDataProviderModal}
        divergentDataProvider={divergentDataProvider}
        onClose={toggle('divergentDataProviderModal')}
      />

      {report.type === 'company' ? (
        <CardInfo
          className={className}
          title="Informações básicas"
          tooltipText={providerStatus}
          items={makeCardInfoBasicInformation(report.company)}
        />
      ) : (
        <CardInfo
          className={className}
          title="Informações pessoais"
          tooltipText={providerStatus}
          items={makeCardInfoPersonalInformation(report.individual)}
          dataTestId="card-info-personal-informations"
        />
      )}
    </>
  );
};

export default BasicInfo;
