import React from 'react';
import { updateInstagramProfile } from 'api';
import { InstagramProfile } from 'types';
import { DropDown, openErrorToast, openSuccessToast } from 'ui';
import { useLocation } from 'react-router-dom';

type Props = {
  caseId: string;
  profile: InstagramProfile;
  setInstagramProfile: (profile: InstagramProfile) => void;
  reportId: string;
};

const Actions = ({ caseId, profile, setInstagramProfile, reportId }: Props) => {
  const location = useLocation();

  const handleUpdate = async () => {
    try {
      const response = await updateInstagramProfile({
        caseId,
        instagramProfileId: profile.id
      });
      setInstagramProfile(response.data);
      openSuccessToast('Relatório social atualizado com sucesso!');
    } catch {
      openErrorToast('Não foi possivel atualizar o relatório.');
    }
  };

  if (profile.moreInfoRequestedAt === null || profile.totalQueries === 0)
    return null;

  return (
    <DropDown
      dataTestId="actions-dropdown"
      options={[
        {
          text: 'Ver relatório',
          isLink: true,
          link: {
            pathname: `/cases/${caseId}/reports/${reportId}/instagram_profiles/${profile.id}`,
            state: { returnTo: location.pathname }
          }
        },
        {
          text: 'Atualizar relatório',
          callback: handleUpdate
        }
      ]}
    />
  );
};

export default Actions;
