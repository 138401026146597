import {
  AddPartyToCase,
  CaseEntity,
  InternalLawsuit,
  InternalLawsuitAttributes,
  InvolvedPartyAttributes,
  LawsuitSearch
} from 'types';

import Client from './client';

function createInternalLawsuit(
  caseId: string,
  lawsuitData: InternalLawsuitAttributes
) {
  return Client.post<InternalLawsuitAttributes, InternalLawsuit>(
    `cases/${caseId}/internal_lawsuits`,
    lawsuitData
  );
}

function deleteInternalLawsuit(caseId: string, lawsuitId: string) {
  return Client.delete(`cases/${caseId}/internal_lawsuits/${lawsuitId}`);
}

function fetchInternalLawsuit({
  caseId,
  lawsuitId
}: {
  caseId: string;
  lawsuitId: string;
}) {
  return Client.get<InternalLawsuit>(
    `cases/${caseId}/internal_lawsuits/${lawsuitId}`
  );
}

export type FetchLawsuitsParams = {
  caseId: string;
};

function fetchInternalLawsuits({ caseId }: FetchLawsuitsParams) {
  return Client.get<InternalLawsuit[]>(`cases/${caseId}/internal_lawsuits`);
}

function updateInternalLawsuit(
  caseId: string,
  id: string,
  lawSuitData: InternalLawsuitAttributes
) {
  return Client.put<InternalLawsuitAttributes, InternalLawsuit>(
    `cases/${caseId}/internal_lawsuits/${id}`,
    lawSuitData
  );
}

function createLawsuitSearch(caseId: string, searchTerm: string) {
  return Client.post<{ searchTerm: string }, LawsuitSearch>(
    `cases/${caseId}/internal_lawsuits/searches`,
    { searchTerm }
  );
}

function fetchLawsuitSearch({
  caseId,
  searchId
}: {
  caseId: string;
  searchId: string;
}) {
  return Client.get<LawsuitSearch>(
    `cases/${caseId}/internal_lawsuits/searches/${searchId}`
  );
}

function autoUpdateLawsuit(caseId: string, lawsuitId: string) {
  return Client.patch<void, InternalLawsuit>(
    `cases/${caseId}/internal_lawsuits/${lawsuitId}/auto_update`
  );
}

function addInvolvedPartyToCase({ caseId, party }: AddPartyToCase) {
  return Client.post<InvolvedPartyAttributes, CaseEntity>(
    `cases/${caseId}/internal_lawsuits/entities`,
    party
  );
}

export {
  addInvolvedPartyToCase,
  autoUpdateLawsuit,
  createLawsuitSearch,
  createInternalLawsuit,
  deleteInternalLawsuit,
  fetchLawsuitSearch,
  fetchInternalLawsuit,
  fetchInternalLawsuits,
  updateInternalLawsuit
};
