export const FIRST_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 6;
export const MIN_PAGE_SIZE = 6;

export enum PaginatedResource {
  PEOPLE_SUSPECTS = 'people_suspects',
  PEOPLE_REPORTS = 'people_reports',
  ASSETS = 'assets',
  LOCATIONS = 'locations',
  EXTERNAL_LAWSUITS = 'external_lawsuits',
  BOOKMARKED_INSTAGRAM_PUBLICATIONS = 'bookmarked_instagram_publications'
}

export type ItemsPerPageOptions = 6 | 10 | 12 | 20 | 100;

export type ApiPagination = {
  size: ItemsPerPageOptions;
  number: number;
  totalEntries: number;
  totalPages: number;
};

export type Pagination = {
  page: number;
  pageSize: number;
};

export type PaginationRequestParams = {
  pagination?: Pagination;
};

export type RequestQueryParams = PaginationRequestParams & OrderByRequestParams;

export type OrderBy = {
  field?: string;
  value?: 'asc' | 'desc';
};

export type OrderByRequestParams = {
  orderBy?: OrderBy;
};

export type PaginationProps = {
  currentPage: number;
  hidePagination?: boolean;
  itemsPerPage: ItemsPerPageOptions;
  onCurrentPageChange: (newCurrentPage: number) => void;
  onItemsPerPageChange?: (newItemsPerPage: ItemsPerPageOptions) => void;
  totalEntries: number;
  totalPages: number;
  hideSelect?: boolean;
};
