import React, { ReactElement } from 'react';

import cn from 'classnames';

import style from './RadioButton.module.scss';

type Props = {
  id: string;
  name: string;
  value: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string | ReactElement;
  error?: Array<string> | string;
  disabled?: boolean;
  className?: string;
  checked?: boolean;
  showWithRadioButton?: boolean;
};

const RadioButton = ({
  id,
  name,
  label,
  value,
  error,
  className,
  disabled,
  checked,
  showWithRadioButton = true,
  onChange
}: Props) => {
  return (
    <div className={cn(style.container, className)}>
      <input
        id={`${id}-${value}`}
        className={style.input}
        type="radio"
        name={name}
        value={value}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
      <label
        className={cn({
          [style.label]: showWithRadioButton,
          [style.labelError]: error,
          [style.labelDisabled]: disabled,
          [style.labelChecked]: checked
        })}
        htmlFor={`${id}-${value}`}
      >
        {label}
      </label>
      {error && (
        <p className={cn(style.message, error && style.messageError)}>
          {Array.isArray(error) ? error.join(', ') : error}
        </p>
      )}
    </div>
  );
};

export default RadioButton;
