import React, { useState } from 'react';
import {
  Modal,
  Button,
  DropDown,
  openSuccessToast,
  openErrorToast,
  Icon,
  Card
} from 'ui';
import {
  ActionName,
  ActionEntity,
  ReportRuralProperty,
  ProviderName
} from 'types';
import { addRuralPropertiesToCase } from 'api/reportRuralProperties';
import * as AvailableActions from 'utils/actions';
import { DropDownOption } from 'ui/DropDown/DropDown';
import Table from '../Table/Table';
import SerproExtraInformation from './SerproExtraInformation/SerproExtraInformation';

type Props = {
  ruralProperty: ReportRuralProperty;
  caseId: string;
  reportId: string;
  addToCase?: boolean;
};

const Actions = ({
  ruralProperty,
  caseId,
  reportId,
  addToCase = true
}: Props) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isSeproModalOpen, setSerproModalOpen] = useState(false);
  const {
    field: { actions }
  } = ruralProperty;

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const openSeproModal = () => {
    setSerproModalOpen(true);
  };

  const closeSerproModal = () => {
    setSerproModalOpen(false);
  };

  const buildAddToCaseHandler = (
    ruralPropertyID: string | null
  ) => async () => {
    if (ruralPropertyID === null) return;

    try {
      await addRuralPropertiesToCase({
        caseId,
        contentId: ruralPropertyID,
        reportId
      });

      openSuccessToast('Imóvel rural adicionado ao caso com sucesso!');
    } catch (error) {
      openErrorToast('Falha ao adicionar imóvel rural ao caso.');
    }
  };

  const addToCaseAction = {
    text: 'Adicionar ao caso',
    callback: buildAddToCaseHandler(ruralProperty.id),
    isDisabled: AvailableActions.isDisabled(actions, ActionName.ADD_TO_CASE),
    description: AvailableActions.unavailableReasons(
      actions,
      ActionName.ADD_TO_CASE,
      ActionEntity.RURAL_PROPERTY
    ),
    isHidden: !addToCase
  };

  const moreInformationAction = {
    text: 'Informações adicionais',
    callback: openSeproModal
  };

  const relatedPropertiesAction = {
    text: 'Imóveis relacionados',
    callback: openModal
  };

  const actionOptions: DropDownOption[] = [];
  let dropdownTrigger = undefined;

  if (addToCase) actionOptions.push(addToCaseAction);

  if (ruralProperty.field.providers.includes(ProviderName.SERPRO))
    actionOptions.push(moreInformationAction);

  if (ruralProperty.relatedProperties.length > 0) {
    actionOptions.push(relatedPropertiesAction);
    dropdownTrigger = <Icon name="add-rounded" small fill="#3949AB" />;
  }

  return actionOptions.length > 0 ? (
    <>
      <DropDown dropDownTrigger={dropdownTrigger} options={actionOptions} />

      <Modal
        large
        isOpen={isSeproModalOpen}
        onClose={closeSerproModal}
        title="Informações Serpro"
      >
        <SerproExtraInformation extraInformation={ruralProperty} />
        <Button onClick={closeSerproModal} highlight centered>
          Voltar
        </Button>
      </Modal>

      <Modal
        large
        isOpen={isModalOpen}
        onClose={closeModal}
        title={`Imóveis relacionados ao ${ruralProperty.propertyName}`}
      >
        <Card>
          <Table
            ruralProperties={ruralProperty.relatedProperties}
            caseId={caseId}
            reportId={reportId}
          />
        </Card>
        <Button onClick={closeModal} highlight centered>
          Voltar
        </Button>
      </Modal>
    </>
  ) : (
    <></>
  );
};

export default Actions;
