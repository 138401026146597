import React from 'react';
import { ExternalLawsuit } from 'types';
import { Pagination, TwoColumnsMasonry } from 'ui';
import { usePagination } from 'hooks';
import { ExternalLawsuitCard } from 'components';
import style from './RelatedLawsuitList.module.scss';

type Props = {
  relatedLawsuits: ExternalLawsuit[];
};

const RelatedLawsuitList = ({ relatedLawsuits }: Props) => {
  const { data, paginationProps } = usePagination({
    dataToPaginate: relatedLawsuits
  });

  const columnRender = (data: ExternalLawsuit[]) =>
    data.map(externalLawsuit => (
      <ExternalLawsuitCard
        key={externalLawsuit.id}
        externalLawsuit={externalLawsuit}
        hideMonitoringIcon
        hideTrackingIcon
        title={<ExternalLawsuitCard.TriggerTitle lawsuit={externalLawsuit} />}
      />
    ));

  if (!data.length) {
    return <p className={style.emptyText}>Não há processos relacionados</p>;
  }

  return (
    <>
      <TwoColumnsMasonry data={data} render={columnRender} />

      {!paginationProps.hidePagination && <Pagination {...paginationProps} />}
    </>
  );
};

export default RelatedLawsuitList;
