import React, { useEffect, useState } from 'react';
import { FormikErrors } from 'formik';
import {
  createUsefulLink,
  fetchUsefulLinks,
  updateUsefulLink,
  deleteUsefulLink,
  can,
  Action
} from 'api';
import { UsefulLink } from 'types';
import { useQuery } from 'hooks';
import {
  Button,
  DropDown,
  Icon,
  ListItem,
  Loading,
  Modal,
  openErrorToast,
  openSuccessToast,
  Stack,
  StackMargin
} from 'ui';
import Form from './Form';

import style from './UsefulLinksList.module.scss';

const UsefulLinksList = () => {
  const {
    data: usefulLinks = [],
    isLoading = true,
    error,
    setData: setUsefulLinks
  } = useQuery(fetchUsefulLinks, {});
  const [linkModalOpen, setLinkModalOpen] = useState(false);
  const [linkIndex, setLinkIndex] = useState<number>();
  const selectedLink =
    linkIndex === undefined ? undefined : usefulLinks[linkIndex];

  useEffect(() => {
    error && openErrorToast('Ocorreu um erro na listagem de links.');
  }, [error]);

  function sortLinks(links: UsefulLink[]): UsefulLink[] {
    return links.sort((link1, link2) =>
      link1.name.toLowerCase() > link2.name.toLowerCase() ? 1 : -1
    );
  }

  function closeLinkModal(): void {
    setLinkIndex(undefined);
    setLinkModalOpen(false);
  }

  function openLinkModal(currentIndex?: number): void {
    if (currentIndex !== undefined) setLinkIndex(currentIndex);
    setLinkModalOpen(true);
  }

  async function handleSave(
    linkData: UsefulLink,
    setErrors: (errors: FormikErrors<UsefulLink>) => void
  ) {
    if (linkData.id) {
      try {
        const { data: updatedLink } = await updateUsefulLink(
          linkData.id,
          linkData
        );
        const newLinkList = usefulLinks.map(link => {
          if (link.id === updatedLink.id) {
            return updatedLink;
          }
          return link;
        });
        setUsefulLinks(sortLinks(newLinkList));

        openSuccessToast('Link atualizado com sucesso!');
        closeLinkModal();
      } catch (error) {
        setErrors(error.errors);
        openErrorToast('Não foi possível atualizar o link.');
      }
    } else {
      try {
        const { data: newLink } = await createUsefulLink(linkData);
        setUsefulLinks(sortLinks([...usefulLinks, newLink]));

        openSuccessToast('Link adicionado com sucesso!');
        closeLinkModal();
      } catch (error) {
        setErrors(error.errors);
        openErrorToast('Não foi possível salvar o link.');
      }
    }
  }
  const handleDelete = async (id: string) => {
    if (!window.confirm('Deseja remover esse link?')) return;

    try {
      await deleteUsefulLink(id);

      const linksWithoutRemoved = usefulLinks.filter(link => link.id !== id);

      setUsefulLinks(linksWithoutRemoved);
      openSuccessToast('Link removido com sucesso!');
    } catch (error) {
      openErrorToast('Erro ao excluir link.');
    }
  };

  const canUser = {
    addUserfulLinks: can(Action.LINKS_UTEIS_LINKS_INCLUIR),
    editUsefulLinks: can(Action.LINKS_UTEIS_LINKS_EDITAR),
    deleteUsefulLinks: can(Action.LINKS_UTEIS_LINKS_EXCLUIR)
  };

  const canAccessActions = canUser.editUsefulLinks || canUser.deleteUsefulLinks;

  let content;

  if (isLoading) {
    content = <Loading />;
  } else if (error) {
    content = (
      <p className={style.feedback}>
        <Icon name="warning" fill="#7a7e94" />
        Não foi possível carregar os links. Atualize a página ou tente novamente
        mais tarde.
      </p>
    );
  } else {
    if (usefulLinks.length > 0) {
      const actions = (id: string, index: number) =>
        canAccessActions ? (
          <DropDown
            dataTestId={`dropdown-link-${index}`}
            options={[
              {
                text: 'Editar',
                callback: () => openLinkModal(index),
                isHidden: !canUser.editUsefulLinks
              },
              {
                text: 'Excluir',
                callback: () => handleDelete(id),
                isNegative: true,
                isHidden: !canUser.deleteUsefulLinks
              }
            ]}
          />
        ) : (
          <></>
        );

      content = (
        <ul>
          {usefulLinks.map((usefulLink, index) => (
            <ListItem
              id={usefulLink.id}
              key={usefulLink.id}
              className={style.item}
              title={usefulLink.name}
              text={usefulLink.description}
              actions={actions(usefulLink.id, index)}
              titleUrl={usefulLink.url}
            />
          ))}
        </ul>
      );
    } else {
      content = <p className={style.feedback}>Não há links cadastrados</p>;
    }
  }

  return (
    <>
      <Stack>
        {canUser.addUserfulLinks && (
          <Button
            icon="add"
            onClick={openLinkModal}
            dataTestId="add-useful-link-button"
            outline
            highlight
            block
          >
            Adicionar Site
          </Button>
        )}

        <Stack marginTop={StackMargin.LARGE}>
          <h2 className={style.title}>Lista de Sites</h2>
          {content}
        </Stack>
      </Stack>
      <Modal
        isOpen={linkModalOpen}
        onClose={closeLinkModal}
        dataTestId="link-modal"
        title={selectedLink ? 'Editar site' : 'Novo site'}
      >
        <Form linkData={selectedLink} onSave={handleSave} />
      </Modal>
    </>
  );
};

export default UsefulLinksList;
