import { numericDateFormat } from 'utils';

type Mask = {
  apply: (value: string) => string;
  revert: (value: string) => string;
};

type Masks = {
  [type: string]: Mask;
};

export function applyDateMask(value: string): string {
  return value && numericDateFormat(value);
}

export function applyFieldDateMask(value: string): string {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{8})(\d)/, '$1')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})/, '$1');
}

export function applyCodeMask(value: string): string {
  return value
    .replace(/(\d{7})(\d)(.*)/, '$1')
    .replace(/(\d{4})(\d{1})(\d{2})/, '$1-$2/$3');
}

function applyCPFMask(value: string): string {
  return value
    .toString()
    .replace(/\D/g, '')
    .replace(/^(\d{11})(.*)/, '$1')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
}

function cleanUpPercentMask(value: string): string {
  return parseInt(value.toString().replace(/\D/g, ''), 10).toString();
}

function cleanUpMask(value: string): string {
  return value.toString().replace(/\D/g, '');
}

function cleanFieldDateMask(value: string): string {
  return value;
}

function applyCNPJMask(value: string): string {
  return value
    .toString()
    .replace(/\D/g, '')
    .replace(/^(\d{14})(.*)/, '$1')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    .replace(/\.(\d{3})(\d)/, '.$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2');
}

export function applyCurrencyMask(value: string): string {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(parseFloat(value) / 100);
}

function applyAreaMask(value: string): string {
  return new Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    minimumFractionDigits: 2
  }).format(parseFloat(value) / 100);
}

export function applyPostalCodeMask(value: string): string {
  return value.replace(/\D/g, '').replace(/^(\d{5})(\d)/g, '$1-$2');
}

export function applyPhoneMask(value: string): string {
  return value
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d)/g, '($1) $2')
    .replace(/(\d)(\d{4})$/, '$1-$2');
}

export function applyPercentMask(value: string): string {
  return parseInt(value, 10)
    .toString()
    .replace(/\D+/, '')
    .padStart(3, '0')
    .replace(/^(\d{1,3})(\d{2})/, '$1,$2');
}

export function applyDocumentMask(value: string | null): string {
  if (value === null) {
    return '';
  }

  if (value.length === 11) {
    return applyCPFMask(value);
  } else {
    return applyCNPJMask(value);
  }
}

const MASKS: Masks = {
  cpf: { apply: applyCPFMask, revert: cleanUpMask },
  cnpj: { apply: applyCNPJMask, revert: cleanUpMask },
  currency: { apply: applyCurrencyMask, revert: cleanUpMask },
  cep: { apply: applyPostalCodeMask, revert: cleanUpMask },
  phone: { apply: applyPhoneMask, revert: cleanUpMask },
  percent: { apply: applyPercentMask, revert: cleanUpPercentMask },
  date: { apply: applyFieldDateMask, revert: cleanFieldDateMask },
  isoDate: { apply: applyDateMask, revert: cleanFieldDateMask },
  area: { apply: applyAreaMask, revert: cleanUpMask }
};

export function applyMask(type: string, value: string | undefined): string {
  return runMask('apply', type, value);
}

export function revertMask(type: string, value: string | undefined): string {
  return runMask('revert', type, value);
}

function runMask(
  operation: keyof Mask,
  type: string,
  value: string | undefined
): string {
  if (!value) return '';
  if (!MASKS[type]) return value;

  return MASKS[type][operation](value);
}
