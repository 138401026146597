import React, { useState, useRef, RefObject, ReactNode } from 'react';
import TetherComponent from 'react-tether';
import useOnclickOutside from 'react-cool-onclickoutside';
import cn from 'classnames';
import { Icon, Modal, openErrorToast } from 'ui';
import { useQuery, useLiveNotificationsCount } from 'hooks';
import { unreadNotificationsCount } from 'api';

import ListWindow from './ListWindow';

import style from './Notifications.module.scss';

import PaginatedItems from './PaginatedItems';

type Props = {
  caseId?: string;
};

const Notifications = ({ caseId }: Props) => {
  const [windowOpen, setWindowOpen] = useState<boolean>(false);
  const toggleIsOpen = (): void => setWindowOpen(!windowOpen);
  const outsideRef = useRef<HTMLDivElement>(null);

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const openModal = () => {
    setWindowOpen(false);
    setModalOpen(true);
  };

  const closeNotificaton = () => {
    setWindowOpen(false);
    setModalOpen(false);
  };

  useOnclickOutside(outsideRef, () => {
    setWindowOpen(false);
  });

  const {
    data: unreadData,
    error: unreadFetchError,
    setData
  } = useQuery(unreadNotificationsCount, { caseId });
  useLiveNotificationsCount({ caseId, setData });

  if (unreadFetchError) {
    openErrorToast('Não foi possivel buscar a contagem de notificações.');
  }

  const counter = unreadData?.unreadCount || 0;
  const hasNotification = counter > 0;

  return (
    <>
      <TetherComponent
        offset={hasNotification ? '30px -50px' : '30px -36px'}
        className={style.dropdownTether}
        attachment="top left"
        targetAttachment="bottom left"
        constraints={[
          {
            to: 'scrollParent',
            attachment: 'together'
          }
        ]}
        renderTarget={(ref: RefObject<Element>): ReactNode => (
          <button
            className={cn([
              style.trigger,
              hasNotification && style.triggerActive
            ])}
            type="button"
            ref={ref as RefObject<HTMLButtonElement>}
            onClick={toggleIsOpen}
          >
            <div className={style.triggerWrapper}>
              <Icon
                name="bell"
                fill={hasNotification ? '#fff' : '#B2B5C2'}
                small
              />
              {hasNotification && (
                <span
                  className={style.counter}
                  data-testid="notifications-counter"
                >
                  {counter < 100 ? counter : '99+'}
                </span>
              )}
            </div>
          </button>
        )}
        renderElement={(ref: RefObject<Element>) =>
          windowOpen && (
            <div ref={outsideRef}>
              <div
                ref={ref as RefObject<HTMLDivElement>}
                data-testid="dropdown-body"
              >
                <ListWindow
                  caseId={caseId}
                  openModal={openModal}
                  closeNotificaton={closeNotificaton}
                />
              </div>
            </div>
          )
        }
      />

      <Modal
        medium
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        dataTestId="notifications-modal"
        title="Notificações"
      >
        <PaginatedItems caseId={caseId} closeNotificaton={closeNotificaton} />
      </Modal>
    </>
  );
};

export default Notifications;
