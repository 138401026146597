import React, { useState } from 'react';

import styles from './CollapsibleParagraph.module.scss';

const MAX_SIZE = 255;

type Props = {
  className?: string;
  maxSize?: number;
  text: string;
  dataTestId?: string;
};

const CollapsibleParagraph = ({
  className,
  maxSize = MAX_SIZE,
  text,
  dataTestId = 'collapsible-paragraph'
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const content = isOpen ? text : text.slice(0, maxSize);
  const buttonText = isOpen ? 'Ver menos' : 'Ver mais';
  const isButtonVisible = text.length >= maxSize;

  return (
    <p className={className} data-testid={dataTestId}>
      {content}
      {isButtonVisible && (
        <button className={styles.button} onClick={() => setIsOpen(!isOpen)}>
          {buttonText}
        </button>
      )}
    </p>
  );
};

export default CollapsibleParagraph;
