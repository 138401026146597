import React from 'react';

import { InstagramPublication } from 'types';
import {
  bookmarkInstagramPublication,
  unbookmarkInstagramPublication
} from 'api';

import { Bookmark, openErrorToast, openInfoToast } from 'ui';
import { useInstagramProfile } from 'components';

import style from './PublicationBookmark.module.scss';

enum BookmarkPosition {
  left = 'left',
  right = 'right'
}

type Props = {
  reportId: string;
  caseId: string;
  instagramPublication: InstagramPublication;
  position: BookmarkPosition;
  color?: string;
  onBookmarkCallBack?: () => void;
};

const PublicationBookmark = ({
  reportId,
  caseId,
  instagramPublication,
  position,
  color = '#3F51B5',
  onBookmarkCallBack
}: Props) => {
  const {
    setUnBookmarkPublication,
    setBookmarkPublication
  } = useInstagramProfile();

  const handleBookmark = async () => {
    try {
      if (instagramPublication.isFavorite) {
        await unbookmarkInstagramPublication({
          caseId,
          reportId,
          instagramPublicationId: instagramPublication.id
        });

        setUnBookmarkPublication(instagramPublication.id);

        openInfoToast('Post removido de postagens salvas');
      } else {
        await bookmarkInstagramPublication({
          caseId,
          reportId,
          instagramPublicationId: instagramPublication.id
        });

        setBookmarkPublication(instagramPublication);

        openInfoToast('Post adicionado a postagens salvas');
      }

      onBookmarkCallBack && onBookmarkCallBack();
    } catch (e) {
      openErrorToast('Erro ao favoritar Post!');
    }
  };

  return (
    <Bookmark
      small
      bookmarked={instagramPublication.isFavorite}
      className={
        position === BookmarkPosition.left
          ? style.bookmarkLeft
          : style.bookmarkRight
      }
      fill={color}
      onClick={handleBookmark}
    />
  );
};

export { PublicationBookmark, BookmarkPosition };
