import React, { ReactNode } from 'react';
import cn from 'classnames';

import style from './GroupField.module.scss';

type Props = {
  children: ReactNode;
  id: string;
  title?: string;
  error?: Array<string> | string;
};

const GroupField = ({ children, title, error, id }: Props) => {
  const labelClass = cn([style.label, Boolean(error) && style.labelError]);

  return (
    <div>
      {title && (
        <label htmlFor={id} className={labelClass}>
          {title}
        </label>
      )}
      <div className={style.groupField}>{children}</div>
      {error && (
        <p className={style.message}>
          {Array.isArray(error) ? error.join(', ') : error}
        </p>
      )}
    </div>
  );
};

export default GroupField;
