import { Case, Invoice, Duplicate, DuplicateFormAttributes } from 'types';
import Client from './client';

type RequestParams = {
  caseId: Case['id'];
  invoiceId: Invoice['id'];
  duplicateId: Duplicate['id'];
};

export function createDuplicate(
  { caseId, invoiceId }: Omit<RequestParams, 'duplicateId'>,
  duplicate: DuplicateFormAttributes
) {
  return Client.post<DuplicateFormAttributes, Duplicate>(
    `cases/${caseId}/invoices/${invoiceId}/duplicates`,
    duplicate
  );
}

export function updateDuplicate(
  { caseId, duplicateId }: Omit<RequestParams, 'invoiceId'>,
  duplicate: DuplicateFormAttributes
) {
  return Client.put<DuplicateFormAttributes, Duplicate>(
    `cases/${caseId}/duplicates/${duplicateId}`,
    duplicate
  );
}

export function saveDuplicate(
  params: Omit<RequestParams, 'duplicateId'> & {
    duplicateId?: Duplicate['id'];
  },
  duplicate: DuplicateFormAttributes
) {
  if (params.duplicateId)
    return updateDuplicate(
      { caseId: params.caseId, duplicateId: params.duplicateId },
      duplicate
    );
  return createDuplicate(params, duplicate);
}

export function deleteDuplicate({
  caseId,
  duplicateId
}: Omit<RequestParams, 'invoiceId'>) {
  return Client.delete<void>(`cases/${caseId}/duplicates/${duplicateId}`);
}
