import { isEqual } from 'lodash';
import React from 'react';
import { PermissionFunctionality } from 'types';
import ExpandableRowItem from '../../../ExpandableRowItem';
import ActionRow from './ActionRow';

import style from './FunctionalityRow.module.scss';

type Props = {
  columnsSizeStyle?: string;
  functionality: PermissionFunctionality;
  onPermissionChange: (permissionId: string, value: boolean) => void;
  profileId: string;
  shouldExpandAll: boolean;
};

const FunctionalityRow = ({
  columnsSizeStyle,
  functionality,
  onPermissionChange,
  profileId,
  shouldExpandAll
}: Props) => {
  return (
    <ExpandableRowItem
      itemName={functionality.name}
      itemAmount={functionality.enabledAmount.toString()}
      columnsSizeStyle={columnsSizeStyle}
      firstColumnStyle={style.firstColumn}
      shouldExpandAll={shouldExpandAll}
    >
      {functionality.actions.map(action => (
        <ActionRow
          key={action.id}
          action={action}
          profileId={profileId}
          columnsSizeStyle={columnsSizeStyle}
          onPermissionChange={onPermissionChange}
        />
      ))}
    </ExpandableRowItem>
  );
};

const MemoizedFunctionalityRow = React.memo(
  FunctionalityRow,
  (prevProps, newProps) =>
    isEqual(prevProps.functionality, newProps.functionality) &&
    prevProps.shouldExpandAll === newProps.shouldExpandAll
);

export default MemoizedFunctionalityRow;
