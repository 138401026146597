import React, { ReactElement } from 'react';

type Props = {
  children: ReactElement[] | ReactElement;
};

const TableHeader = ({ children }: Props) => {
  return (
    <thead className="header" data-testid="tableHeader">
      {children}
    </thead>
  );
};

export default TableHeader;
