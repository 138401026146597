import React from 'react';
import { toast } from 'react-toastify';
import Toast, { ToastType } from './Toast';
import 'react-toastify/dist/ReactToastify.css';

import style from './Toast.module.scss';

toast.configure({
  toastClassName: style.reset,
  position: toast.POSITION.BOTTOM_RIGHT,
  hideProgressBar: true,
  autoClose: 5000,
  closeOnClick: true,
  closeButton: false,
  draggable: false
});

type ToastProps = {
  closeToast: () => void;
};

type ToastParams = {
  type: ToastType;
  message: string;
  autoClose: number | false;
};

function openToast({ type, message, autoClose }: ToastParams) {
  toast(
    ({ closeToast }: ToastProps) => (
      <Toast type={type} message={message} closeToast={closeToast} />
    ),
    { autoClose }
  );
}

function openErrorToast(message: string, autoClose: number | false = 5000) {
  openToast({ type: ToastType.ERROR, message, autoClose });
}

function openSuccessToast(message: string, autoClose: number | false = 5000) {
  openToast({ type: ToastType.SUCCESS, message, autoClose });
}

function openInfoToast(message: string, autoClose: number | false = 5000) {
  openToast({ type: ToastType.INFO, message, autoClose });
}

export { openErrorToast, openSuccessToast, openInfoToast };
