import { useEffect, useState } from 'react';

import { useChannel } from 'components';

import { ExternalLawsuitSocketResponse } from 'types';

function useLiveSearchExternalLawsuits(
  onSearchResponseFromSocket: (response: ExternalLawsuitSocketResponse) => void,
  { caseId, searchId }: { caseId: string; searchId: { current: string } }
) {
  const { channel, isConnected } = useChannel('cases:' + caseId);
  const [isAlreadyOn, setIsAlreadyOn] = useState(false);

  useEffect(() => {
    return () => {
      if (channel && isConnected) {
        channel.off('external_lawsuit_search_finished');
      }
    };
  }, [channel, isConnected]);

  useEffect(() => {
    if (!channel || isAlreadyOn || !isConnected) return;

    channel!.on('external_lawsuit_search_finished', response => {
      setIsAlreadyOn(true);

      if (searchId.current === response.id) {
        onSearchResponseFromSocket(response);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchId.current, isConnected, channel]);

  return [isConnected];
}

export { useLiveSearchExternalLawsuits };
