import { formatInitialValues, assetHasMissingFields } from 'domain/assets';
import React, { useState } from 'react';
import cn from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Controller, Swiper as SwiperType } from 'swiper';

import { AssetSelectableList } from 'components';
import { useToggles } from 'hooks';
import { Asset, AssetMissingDataAttribute } from 'types';
import {
  Button,
  Modal,
  openErrorToast,
  openSuccessToast,
  Stack,
  StackMargin
} from 'ui';
import { addReclaimingAsset, AssetSearchParams } from 'api';

import AssetsMissingFields from './AssetsMissingFields';

import style from './RelateAssetModal.module.scss';

type Props = {
  caseId: string;
  reclaimingId: string;
  onAdd: (newAssets: Asset[]) => void;
  searchDefaultParams?: AssetSearchParams;
  hideAddButton?: boolean;
};

SwiperCore.use([Controller]);

const RelateAssetModal = ({
  caseId,
  reclaimingId,
  onAdd,
  searchDefaultParams,
  hideAddButton = false
}: Props) => {
  const { isOpen, toggle, closeAll } = useToggles<'modal'>({
    modal: false
  });

  const [controlledSwiper, setControlledSwiper] = useState<SwiperType>();

  const [selectedAssets, setSelectedAssets] = useState<Asset[]>([]);

  const handleRelateAsset = async (assetsData: AssetMissingDataAttribute[]) => {
    try {
      const response = await addReclaimingAsset(caseId, reclaimingId, {
        assetIds: selectedAssets.map(asset => asset.id),
        assets: assetsData
      });

      openSuccessToast('Ativo(s) adicionado(s) com sucesso!');
      closeAll();

      onAdd(response.data);
    } catch (error) {
      openErrorToast('Houve um erro ao adicionar o(s) ativo(s)');
    }
  };

  const handleAddAsset = () => {
    if (selectedAssets.filter(assetHasMissingFields).length > 0) {
      controlledSwiper?.slideNext();

      return;
    }

    handleRelateAsset(selectedAssets.map(asset => formatInitialValues(asset)));
  };

  const handleBack = () => {
    controlledSwiper?.slidePrev();
  };

  return (
    <>
      {hideAddButton || (
        <Button highlight outline small onClick={toggle('modal')}>
          Adicionar ativo existente
        </Button>
      )}

      {isOpen.modal && (
        <Modal
          large
          isOpen={isOpen.modal}
          onClose={toggle('modal')}
          title="Adicionar ativo existente"
        >
          <Swiper
            onSwiper={setControlledSwiper}
            centerInsufficientSlides
            spaceBetween={32}
            slidesPerView={1}
            allowTouchMove={false}
          >
            <SwiperSlide key="selectable-list" data-testid="selectable-list">
              <AssetSelectableList
                caseId={caseId}
                onSelect={setSelectedAssets}
                searchDefaultParams={searchDefaultParams}
              />
              <Stack marginTop={StackMargin.SMALL}>
                <Button
                  centered
                  disabled={selectedAssets.length === 0}
                  highlight
                  onClick={handleAddAsset}
                >
                  Adicionar
                </Button>
              </Stack>
            </SwiperSlide>
            <SwiperSlide key="missing-info" data-testid="missing-info">
              {({ isActive }: { isActive: boolean }) => (
                <div
                  className={cn(style.missingInfo, {
                    [style.activeSlide]: isActive
                  })}
                >
                  <AssetsMissingFields
                    onAddAssets={handleRelateAsset}
                    onBack={handleBack}
                    assets={selectedAssets}
                  />
                </div>
              )}
            </SwiperSlide>
          </Swiper>
        </Modal>
      )}
    </>
  );
};

export default RelateAssetModal;
