import { DateTime } from 'luxon';
import React from 'react';
import { InstagramPublication } from 'types';
import { Button, Link } from 'ui';
import style from './PublicationsLink.module.scss';

type Props = {
  publicationsCount: number;
  lastMention: InstagramPublication;
  onClick: () => void;
  caseId: string;
  reportId: string;
};

const PublicationsLink = ({
  publicationsCount,
  lastMention,
  onClick,
  caseId,
  reportId
}: Props) => {
  return publicationsCount > 1 ? (
    <Button social small icon="instagram" onClick={onClick}>
      {`Postagens (${publicationsCount})`}
    </Button>
  ) : (
    <div className={style.publicationLinkContainer}>
      <Link
        asButton
        social
        small
        external
        iconName="instagram"
        href={lastMention.url}
      >
        {`Post ${DateTime.fromISO(lastMention.createdAt).toFormat(
          'dd/MM/yy T'
        )}`}
      </Link>
    </div>
  );
};

export default PublicationsLink;
