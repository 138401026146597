import React from 'react';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';

import { Card, Badge } from 'ui';

import { applyMask } from 'utils';
import { ExternalLawsuitContent } from 'types';

import ExternalLawsuitContentCardLoader from './ExternalLawsuitContentCardLoader';

import styles from './ExternalLawsuitContentCard.module.scss';

type Props = {
  externalLawsuitContent?: ExternalLawsuitContent;
  isLoading?: boolean;
  omitCauseAmount?: boolean;
  className?: string;
  showFullInfo?: boolean;
};

const filterNullUndefined = (items: (null | string | undefined)[]): string[] =>
  items.filter((item): item is string => typeof item === 'string');

const ExternalLawsuitContentCard = ({
  externalLawsuitContent,
  isLoading,
  omitCauseAmount,
  className,
  showFullInfo = false
}: Props) => {
  const basicInformation = externalLawsuitContent?.basicInformation;

  const tags = uniq(
    basicInformation?.flatMap(basicInformation => {
      return filterNullUndefined([
        basicInformation.lawSegment,
        basicInformation.degree,
        basicInformation.status,
        basicInformation.class,
        basicInformation.judgingBody
      ]);
    })
  );

  const court = basicInformation?.find(({ court }) => court)?.court;
  const originUnit = basicInformation?.find(({ originUnit }) => originUnit)
    ?.originUnit;
  const judgeName = basicInformation?.find(({ judgeName }) => judgeName)
    ?.judgeName;
  const lawSegment = basicInformation?.find(({ lawSegment }) => lawSegment)
    ?.lawSegment;
  const segment = lawSegment ? `Vara ${lawSegment}` : null;
  const subtitle = filterNullUndefined([
    court,
    segment,
    originUnit,
    judgeName
  ]).join(', ');

  if (isLoading) {
    return <ExternalLawsuitContentCardLoader />;
  }

  return (
    <Card medium aria-label="lawsuit" className={className}>
      {subtitle && (
        <div className={styles.info}>
          <h4 className={styles.text}>{subtitle}</h4>
        </div>
      )}

      {showFullInfo &&
        externalLawsuitContent?.subjects.map((subject, index) => (
          <div
            className={styles.text}
            key={`${subject.split(' ').join('-')}-${index}`}
          >
            {subject}
          </div>
        ))}

      {tags && (
        <div className={styles.tags}>
          {tags.map((tag, i) => (
            <Badge rounded key={i}>
              {tag}
            </Badge>
          ))}
        </div>
      )}

      {uniqBy(basicInformation, 'value').map((basicInformation, index) => (
        <div className={styles.distributionDateWrapper} key={index}>
          {!omitCauseAmount && (
            <h5 className={styles.value}>
              Valor da Causa:
              <strong className={styles.bold}>
                {basicInformation.value
                  ? applyMask('currency', basicInformation.value.toString())
                  : '-'}
              </strong>
            </h5>
          )}
        </div>
      ))}
    </Card>
  );
};

export default ExternalLawsuitContentCard;
