import { ExternalLawsuitMovement } from 'types';
import Client from './client';

function fetchExternalMovements({
  caseId,
  externalLawsuitId
}: {
  caseId: string;
  externalLawsuitId: string;
}) {
  return Client.get<{
    movements: ExternalLawsuitMovement[];
    lastUpdatedAt: string;
  }>(`cases/${caseId}/external_lawsuits/${externalLawsuitId}/movements`);
}

export { fetchExternalMovements };
