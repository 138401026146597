import React, { ReactElement } from 'react';
import cn from 'classnames';

import TableBody from './TableBody/TableBody';
import TableCell from './TableCell/TableCell';
import TableHeader from './TableHeader/TableHeader';
import TableRow from './TableRow/TableRow';
import TableOrderBy from './TableOrderBy/TableOrderBy';

import './Table.scss';

type Props = {
  children: ReactElement[];
  className?: string;
};

const Table = ({ children, className }: Props) => {
  const classNames = cn('table-default', className);

  return (
    <table className={classNames} data-testid="table" cellSpacing="0">
      {children}
    </table>
  );
};

export default Table;

Table.Body = TableBody;
Table.Cell = TableCell;
Table.Header = TableHeader;
Table.Row = TableRow;
Table.OrderBy = TableOrderBy;
