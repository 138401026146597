import { causeOptions, lawyerValidationSchema } from 'domain/lawsuits';
import React, { useContext } from 'react';

import { LawyerCauseType, Lawyer } from 'types';
import { Button, FormContainer, InputField, Toggle } from 'ui';
import { Formik } from 'formik';

import { CaseContext } from 'components';
import { Action, can } from 'api';
import style from './Form.module.scss';

type Props = {
  lawyer?: Lawyer;
  lawyerIndex?: number;
  onSave: (lawyer: Lawyer, lawyerIndex?: number) => void;
  onDelete: (lawyerIndex: number) => void;
  onCancel: () => void;
};

const initialValues: Lawyer = {
  id: null,
  name: '',
  cause: LawyerCauseType.NEUTRAL
};

const Lawyers = ({
  lawyer,
  lawyerIndex,
  onSave,
  onDelete,
  onCancel
}: Props) => {
  const formatInitialValues = (lawyer?: Lawyer) => {
    if (lawyer) return lawyer;

    return initialValues;
  };

  const { caseData } = useContext(CaseContext);

  const negotiatorId = caseData?.negotiator?.id;

  const canUser = {
    deleteLawyers: can(Action.CASOS_PROCESSO_ADVOGADOS_EXCLUIR, negotiatorId)
  };

  return (
    <Formik<Lawyer>
      initialValues={formatInitialValues(lawyer)}
      validationSchema={lawyerValidationSchema}
      onSubmit={values => onSave(values, lawyerIndex)}
    >
      {form => (
        <FormContainer>
          <InputField
            id="lawyer-name"
            name="name"
            type="text"
            title="Nome completo"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.name}
            error={form.errors.name}
          />

          <Toggle
            title="Parte"
            options={causeOptions()}
            onChange={event => form.setFieldValue('cause', event.target.value)}
            checked={form.values.cause}
            name="cause"
          />

          <div className={style.actions}>
            <Button
              onClick={form.handleSubmit}
              highlight
              dataTestId="lawyer-submit-button"
              type="submit"
            >
              {lawyer ? 'Salvar' : 'Adicionar'}
            </Button>

            {lawyerIndex !== undefined && canUser.deleteLawyers ? (
              <Button
                onClick={() => onDelete(lawyerIndex)}
                highlight
                outline
                danger
                dataTestId="lawyer-remove-button"
                type="button"
              >
                Excluir
              </Button>
            ) : (
              <Button
                onClick={onCancel}
                highlight
                outline
                dataTestId="lawyer-cancel-button"
                type="button"
              >
                Cancelar
              </Button>
            )}
          </div>
        </FormContainer>
      )}
    </Formik>
  );
};

export default Lawyers;
