import { lawsuitNumber } from 'domain/lawsuits';
import { executionEventLabel } from 'domain/executions';

import React from 'react';
import last from 'lodash/last';

import { Execution } from 'types';
import { Badge, Link } from 'ui';

import style from './ExecutionStatus.module.scss';

type Props = {
  caseId: string;
  execution: Execution;
  disabled?: boolean;
};

const ExecutionStatus = ({ execution, caseId, disabled }: Props) => {
  const latestEvent = last(execution.events)?.type;

  return (
    <div className={style.executionStatus} data-testid="executionStatus">
      <span className={style.bold}>Em penhora </span>
      {latestEvent && (
        <Badge className={style.eventBadge} rounded dataTestId="badge-event">
          {executionEventLabel(latestEvent)}
        </Badge>
      )}
      <Badge className={style.degreeBadge} rounded dataTestId="badge-degree">
        {execution.degree}º GRAU
      </Badge>
      <span> no </span>
      <Link
        className={style.lawsuitLink}
        to={`/cases/${caseId}/internal_lawsuits/${execution.internalLawsuit.id}`}
        disabled={disabled}
        underline
        highlight
      >
        {lawsuitNumber(execution.internalLawsuit.number)}
      </Link>
    </div>
  );
};

export default ExecutionStatus;
