import {
  Execution,
  ExecutionAttributes,
  ExecutionEvent,
  ExecutionRelatedAttributes,
  ExecutionRequestAttributes,
  ExternalLawsuitExecutionAttributes
} from 'types';
import Client from './client';

function createExecution(
  caseId: string,
  lawsuitId: string,
  data:
    | ExecutionAttributes
    | ExecutionRelatedAttributes
    | ExternalLawsuitExecutionAttributes
) {
  return Client.post<
    ExecutionAttributes | ExecutionRelatedAttributes,
    Execution
  >(`cases/${caseId}/internal_lawsuits/${lawsuitId}/executions`, data);
}

function createExecutionEvent({
  caseId,
  lawsuitId,
  executionId,
  data
}: ExecutionRequestAttributes & { data: Omit<ExecutionEvent, 'id'> }) {
  return Client.post<Omit<ExecutionEvent, 'id'>, ExecutionEvent>(
    `cases/${caseId}/internal_lawsuits/${lawsuitId}/executions/${executionId}/events`,
    data
  );
}

function fetchExecutions({
  caseId,
  lawsuitId
}: Pick<ExecutionRequestAttributes, 'caseId' | 'lawsuitId'>) {
  return Client.get<Execution[]>(
    `cases/${caseId}/internal_lawsuits/${lawsuitId}/executions`
  );
}

function deleteExecution({
  caseId,
  lawsuitId,
  executionId
}: ExecutionRequestAttributes) {
  return Client.delete(
    `cases/${caseId}/internal_lawsuits/${lawsuitId}/executions/${executionId}`
  );
}

function deleteExecutionEvent({
  caseId,
  lawsuitId,
  executionId,
  eventId
}: ExecutionRequestAttributes & { eventId: string }) {
  return Client.delete(
    `cases/${caseId}/internal_lawsuits/${lawsuitId}/executions/${executionId}/events/${eventId}`
  );
}

function updateExecution({
  caseId,
  lawsuitId,
  executionId,
  degree
}: ExecutionRequestAttributes & { degree: number }) {
  return Client.patch<void, Execution>(
    `cases/${caseId}/internal_lawsuits/${lawsuitId}/executions/${executionId}`,
    { degree }
  );
}

export {
  fetchExecutions,
  createExecution,
  createExecutionEvent,
  deleteExecution,
  deleteExecutionEvent,
  updateExecution
};
