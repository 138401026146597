import { assetTypeOptions } from 'domain/assets';
import { entityName } from 'domain/entity';
import React, { useState, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import debounce from 'lodash/debounce';
import head from 'lodash/head';

import { useToggles } from 'hooks';

import { AssetSearchParams } from 'api';
import {
  InputField,
  SelectField,
  FormContainer,
  Button,
  Stack,
  StackMargin,
  Icon
} from 'ui';

import { AssetType, CaseEntity, Guarantee } from 'types';

import style from './Filters.module.scss';

const buildFilterValue = (filter: Filters, value: string) => {
  if (value === '') return undefined;

  if (filter === 'types') return [value];

  return value;
};

type Filters = 'entityId' | 'types' | 'guaranteeId' | 'name';

type Props = {
  activeFilters: AssetSearchParams;
  onApplyFilter: (params: AssetSearchParams) => void;
  entities: CaseEntity[];
  guarantees: Guarantee[];
  isGuaranteeOptionLoading: boolean;
  isGuaranteeOptionError?: string;
  isEntityOptionLoading: boolean;
  isEntityOptionError?: string;
  isFilterActive: boolean;
  defaultTypes?: AssetType[];
};

const FilterInputs = ({
  activeFilters,
  entities,
  guarantees,
  onApplyFilter,
  isGuaranteeOptionLoading,
  isGuaranteeOptionError,
  isEntityOptionLoading,
  isEntityOptionError,
  isFilterActive,
  defaultTypes
}: Props) => {
  const [filters, setFilters] = useState<AssetSearchParams>(activeFilters);

  const { isOpen, toggle, closeAll } = useToggles<'filters'>({
    filters: false
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const applyFilterDebounced = useCallback(debounce(onApplyFilter, 500), []);

  const handleChangeFilter = (filter: Filters) => (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newFilter = {
      ...filters,
      [filter]: buildFilterValue(filter, event.target.value)
    };

    setFilters(newFilter);

    filter === 'name' && applyFilterDebounced(newFilter);
  };

  const handleApplyFilter = () => {
    onApplyFilter(filters);
  };

  const handleClearFilters = () => {
    const cleanFilters = {
      name: undefined,
      entityId: undefined,
      types: undefined,
      guaranteeId: undefined
    };

    setFilters(cleanFilters);
    onApplyFilter(cleanFilters);

    closeAll();
  };

  const entityOptions = entities.map(entity => {
    return { label: entityName(entity), value: entity.id };
  });

  const guaranteeOptions = guarantees?.map(guarantee => {
    return { label: guarantee.instrumentNumber, value: guarantee.id };
  });

  const typeOptions = assetTypeOptions(defaultTypes);

  const filterIcon = () => {
    if (isOpen.filters) return 'arrow-up';

    return isFilterActive ? 'filter-active' : 'filter';
  };

  return (
    <>
      <div className={style.search}>
        <InputField
          className={style.searchInput}
          id="name"
          name="name"
          title="Busque por nome do ativo ou identificador"
          type="text"
          onChange={handleChangeFilter('name')}
          value={filters?.name}
        />
        <Button
          outline
          highlight
          borderless
          className={style.searchButton}
          onClick={toggle('filters')}
          dataTestId="asset-advanced-filters"
        >
          <Icon name={filterIcon()} small fill="#3F51B5" />
        </Button>
      </div>

      <AnimatePresence>
        {isOpen.filters && (
          <motion.div
            initial="collapsed"
            animate={'open'}
            exit="collapsed"
            variants={{
              open: { opacity: 1, y: 0 },
              collapsed: { opacity: 0, y: -5 }
            }}
            transition={{ duration: 0.2, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <Stack marginTop={StackMargin.SMALL} className={style.filters}>
              <FormContainer className="col-3" title="Filtros">
                <SelectField
                  title="Proprietário"
                  id="entityId"
                  name="entityId"
                  onChange={handleChangeFilter('entityId')}
                  options={entityOptions}
                  optional
                  value={filters?.entityId}
                  isLoading={isEntityOptionLoading}
                  disabled={Boolean(isEntityOptionError)}
                />

                <SelectField
                  title="Tipo"
                  id="types"
                  name="types"
                  onChange={handleChangeFilter('types')}
                  options={typeOptions}
                  optional
                  value={head(filters?.types)}
                />

                <SelectField
                  title="Garantia"
                  id="guaranteeId"
                  name="guaranteeId"
                  onChange={handleChangeFilter('guaranteeId')}
                  options={guaranteeOptions}
                  optional
                  value={filters?.guaranteeId}
                  isLoading={isGuaranteeOptionLoading}
                  disabled={Boolean(isGuaranteeOptionError)}
                />
              </FormContainer>
              <div className={style.filtersActions}>
                {isFilterActive && (
                  <Button outline highlight small onClick={handleClearFilters}>
                    Remover Filtros
                  </Button>
                )}
                {!isFilterActive && (
                  <Button outline highlight small onClick={toggle('filters')}>
                    Cancelar
                  </Button>
                )}
                <Button highlight small onClick={handleApplyFilter}>
                  Aplicar
                </Button>
              </div>
            </Stack>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default FilterInputs;
