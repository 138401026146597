export function pluralize(
  count: number | null | undefined,
  singular: string,
  plural: string
): string {
  if (count === 1) return `${count} ${singular}`;

  return `${count || 0} ${plural}`;
}

export function regularPlural(count: number, singular: string) {
  return pluralize(count, singular, `${singular}s`);
}

export const pluralizeByDays = (days: number | null) =>
  pluralize(days, 'dia', 'dias');
