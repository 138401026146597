import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from 'components';

import Events from './Events';

const EventsRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path={`${path}/:eventTab?`} component={Events} />
    </Switch>
  );
};

export default EventsRoutes;
