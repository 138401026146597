import { fetchInvoice } from 'api';
import { useInitNavigation, useQueryCall } from 'hooks';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { openErrorToast, Stack, StackMargin } from 'ui';
import { FormDirtyProvider } from 'components';
import Duplicates from './Duplicates';
import style from './Invoice.module.scss';
import InvoiceCard from './InvoiceCard';
import InvoiceHeader from './InvoiceHeader';

const Invoice = () => {
  const { caseId, invoiceId } = useParams<{
    caseId: string;
    invoiceId?: string;
  }>();

  const {
    data: invoice,
    error,
    isLoading,
    request,
    setData: setInvoice
  } = useQueryCall(fetchInvoice);

  useEffect(() => {
    invoiceId && request({ caseId, id: invoiceId });
  }, [caseId, invoiceId, request]);

  if (error) {
    openErrorToast('Houve um erro ao exibir nota fical!');
  }

  useInitNavigation(pathname => {
    const isCreateRoute = pathname.endsWith('/invoice/create');
    if (isCreateRoute) return [pathname.replace('/invoice/create', '')];

    return [pathname.replace(`/invoice/${invoiceId}`, '')];
  });

  return (
    <FormDirtyProvider>
      <div className={style.header}>
        <InvoiceHeader
          caseId={caseId}
          invoice={invoice}
          isLoading={isLoading}
        />

        <div className={style.content}>
          <InvoiceCard
            caseId={caseId}
            invoice={invoice}
            isLoading={isLoading}
            setInvoice={setInvoice}
          />

          {invoice && !isLoading && (
            <Stack marginTop={StackMargin.XLARGE}>
              <Duplicates
                initialDuplicates={invoice.duplicates}
                invoiceId={invoice.id}
                caseId={caseId}
              />
            </Stack>
          )}
        </div>
      </div>
    </FormDirtyProvider>
  );
};

export default Invoice;
