import { executionDegreeOptions } from 'domain/executions';

import React, { useContext } from 'react';
import { useFormik, getIn } from 'formik';
import {
  InfoArea,
  SelectField,
  FormContainer,
  Button,
  openErrorToast,
  openSuccessToast
} from 'ui';
import { Execution, OnSubmit } from 'types';
import { can, Action, deleteExecution, updateExecution } from 'api';
import { CaseContext } from 'components';

import style from './Form.module.scss';

type Props = {
  executionId: string;
  lawsuitId: string;
  caseId: string;
  title: string;
  degree: number | null;
  onSave: (execution: Execution) => void;
  onRemove: (id: string) => void;
};

const Form = ({
  onSave,
  onRemove,
  executionId,
  lawsuitId,
  caseId,
  title,
  degree
}: Props) => {
  const onSubmit: OnSubmit<{ degree: number }> = async values => {
    try {
      const response = await updateExecution({
        caseId,
        lawsuitId,
        executionId,
        degree: values.degree!
      });

      onSave(response.data);

      openSuccessToast('Penhora atualizada com sucesso!');
    } catch (error) {
      openErrorToast('Houve um erro ao atualizar a Penhora');
    }
  };

  const onDelete = async () => {
    if (!window.confirm('Deseja remover esta penhora?')) return;

    try {
      await deleteExecution({
        caseId,
        lawsuitId,
        executionId
      });

      onRemove(executionId);

      openSuccessToast('Penhora removida com sucesso!');
    } catch (error) {
      openErrorToast('Erro ao remover a penhora');
    }
  };

  const formik = useFormik({
    initialValues: { degree: degree ?? 0 },
    onSubmit: onSubmit
  });

  const { caseData } = useContext(CaseContext);
  const negotiatorId = caseData?.negotiator?.id;
  const canUser = {
    deleteExecution: can(Action.CASOS_PROCESSO_PENHORA_EXCLUIR, negotiatorId)
  };

  return (
    <form>
      <FormContainer>
        <InfoArea label="Ativo" value={title} />

        <SelectField
          id="degree"
          name="degree"
          value={formik.values.degree?.toString()}
          title="Grau da penhora"
          error={
            getIn(formik.touched, 'degree') && getIn(formik.errors, 'degree')
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          options={executionDegreeOptions()}
          optional
        />
      </FormContainer>
      <div className={style.actions}>
        <Button
          disabled={!formik.dirty}
          onClick={formik.handleSubmit}
          highlight
          type="submit"
        >
          Salvar
        </Button>
        {canUser.deleteExecution && (
          <Button onClick={onDelete} outline danger>
            Remover da penhora
          </Button>
        )}
      </div>
    </form>
  );
};

export default Form;
