import React, { useState, ReactNode } from 'react';

import { LocationPublicationsModal } from 'components';

import { LocationMapPoints } from 'types';

import PinTippy from './PinTippy';

import style from './Pin.module.scss';

const Pin = ({
  publicationsCount,
  caseId,
  reportId,
  googleMapsUrl,
  locationId,
  displayName,
  showInstagramPostOnPin = true,
  canBookmarkPublication = true
}: LocationMapPoints) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalProps, setModalProps] = useState<{
    title: ReactNode;
    caseId: string;
    instagramLocationId: string;
    reportId: string;
  }>();

  const openModal = () => {
    const title = (
      <>
        Postagens em <span className={style.titleBold}>{displayName}</span>
      </>
    );

    setIsOpen(true);
    setModalProps({ title, caseId, instagramLocationId: locationId, reportId });
  };

  const onClickPostsButton = () => openModal();

  return (
    <>
      <PinTippy
        count={publicationsCount}
        caseId={caseId}
        reportId={reportId}
        googleMapsUrl={googleMapsUrl}
        locationId={locationId}
        showInstagramPostOnPin={showInstagramPostOnPin}
        canBookmarkPublication={canBookmarkPublication}
        onClickPostsButton={onClickPostsButton}
      >
        <div className={style.pin} data-testid="pin" />
      </PinTippy>

      {modalProps && isOpen && (
        <LocationPublicationsModal
          isOpen={isOpen}
          canBookmarkPublication={canBookmarkPublication}
          onClose={() => setIsOpen(false)}
          {...modalProps}
        />
      )}
    </>
  );
};

export default Pin;
