import { DateTime } from 'luxon';

export function numericDateFormat(value: string): string {
  if (!value) return 'Sem data';
  if (!DateTime.fromISO(value).isValid) return 'Data inválida';

  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    year: 'numeric',
    month: 'numeric'
  };

  return DateTime.fromISO(value)
    .setLocale('pt-BR')
    .toUTC()
    .toLocaleString(options);
}

export function formatMaxDate(maxDate: string) {
  if (!maxDate) return '';
  return DateTime.fromISO(maxDate).toFormat('yyyy-MM-dd');
}
