import React from 'react';

import ContentLoader from 'react-content-loader';

type Props = {
  width?: number;
  height?: number;
};

const Loader = ({ width = 1, height = 1 }: Props) => {
  const h = 16 * height;
  const w = 100 * width;

  return (
    <ContentLoader
      speed={2}
      width={w}
      height={h}
      viewBox={`0 0 ${w} ${h}`}
      backgroundColor="#E2E3E9"
      foregroundColor="#CBCDD7"
    >
      <rect
        x="0"
        y="0"
        rx="3"
        ry="3"
        width={w}
        height={h}
        data-testid="content-loader-rect"
      />
    </ContentLoader>
  );
};

export default Loader;
