import {
  ReportRelatedCompany,
  FetchReportTableProps,
  AddContentToCase,
  CaseEntity,
  Report
} from 'types';

import Client from './client';

export function fetchReportRelatedCompanies({
  caseId,
  reportId,
  pagination
}: FetchReportTableProps) {
  return Client.get<ReportRelatedCompany[]>(
    `cases/${caseId}/reports/${reportId}/contents/related_companies`,
    {
      params: {
        page: pagination?.page.toString(),
        pageSize: pagination?.pageSize.toString()
      }
    }
  );
}

export function fetchReportRelatedCompaniesSummary({
  caseId,
  reportId
}: FetchReportTableProps) {
  return Client.get<ReportRelatedCompany[]>(
    `cases/${caseId}/reports/${reportId}/contents/related_companies/summary`
  );
}

export function addReportRelatedCompanyToCase({
  caseId,
  reportId,
  contentId
}: AddContentToCase) {
  return Client.post<unknown, CaseEntity>(
    `cases/${caseId}/reports/${reportId}/contents/related_companies/${contentId}`
  );
}

export function createReportFromRelatedCompany({
  caseId,
  reportId,
  contentId
}: AddContentToCase) {
  return Client.post<unknown, Report>(
    `cases/${caseId}/reports/${reportId}/contents/related_companies/${contentId}/reports`
  );
}
