import React, { ReactElement } from 'react';
import cn from 'classnames';

import { Card, IconTooltip, Link, Loader, Pagination } from 'ui';
import { CardInfoLinkValue, CardInfoItem, ApiPagination } from 'types';

import style from './CardInfo.module.scss';

const DEFAULT_PAGE_SIZE = 6;

type Props = {
  items: CardInfoItem[];
  title?: string;
  subtitle?: string | ReactElement;
  dataTestId?: string;
  className?: string;
  isLoading?: boolean;
  tooltipText?: string;
  pagination?: ApiPagination;
  onCurrentPageChange?: (newPage: number) => void;
};

function convertToLink({
  href,
  value,
  external = false,
  underline = false,
  onClick
}: CardInfoItem | CardInfoLinkValue) {
  return (
    <Link
      highlight
      data-testid="item-social-link"
      href={href}
      external={external}
      underline={underline}
      onClick={onClick}
    >
      {value}
    </Link>
  );
}

const convertArrayToList = (item: CardInfoItem) => (
  <ul>
    {(item.value as (string | CardInfoLinkValue)[]).map(
      (value: string | CardInfoLinkValue, key: number) => (
        <li
          key={`card-info-list-item-${key}`}
          data-testid="card-info-list-item"
          className={style.listValue}
        >
          {typeof value === 'string' || value instanceof String
            ? value
            : convertToLink(value)}
        </li>
      )
    )}
  </ul>
);

function handleValue(item: CardInfoItem) {
  if (Array.isArray(item.value)) {
    return convertArrayToList(item);
  }

  if (item.href) {
    return convertToLink(item);
  }

  return item.value;
}

const CardInfo = ({
  title,
  subtitle,
  dataTestId = 'card-info',
  items = [],
  className,
  isLoading,
  tooltipText,
  pagination,
  onCurrentPageChange
}: Props) => (
  <Card className={cn(className)} dataTestId={dataTestId} medium>
    <div className={style.header}>
      <div className={style.titleContainer}>
        {title && <h3 className={style.title}>{title}</h3>}
        {tooltipText && (
          <IconTooltip
            text={tooltipText}
            dataTestId="status-tooltip"
            icon="warning"
            color="#e53935"
          />
        )}
      </div>
      {subtitle && <div className={style.subtitle}>{subtitle}</div>}
    </div>

    {isLoading && <Loader />}

    <ul className={style.list}>
      {items.map((item, index) => (
        <li className={style.row} key={`card-row-${index}`}>
          <span className={cn(style.rowLabel, style.faded)}>{item.label}</span>
          <span className={cn(style.rowValue, { [style.faded]: item.faded })}>
            {handleValue(item)}
            <span className={style.itemAlert}>{item.alert}</span>
          </span>
          <span>{item.tooltip}</span>
        </li>
      ))}
    </ul>

    {pagination &&
      onCurrentPageChange &&
      pagination.totalEntries > DEFAULT_PAGE_SIZE && (
        <div data-testid="pagination">
          <Pagination
            hideSelect
            currentPage={pagination.number}
            itemsPerPage={DEFAULT_PAGE_SIZE}
            onCurrentPageChange={onCurrentPageChange}
            totalEntries={pagination.totalEntries}
            totalPages={pagination.totalPages}
          />
        </div>
      )}
  </Card>
);

export default CardInfo;
