import { useState } from 'react';
import mapValues from 'lodash/mapValues';

type ToggleProps<T extends string> = {
  [key in T]: boolean;
};

export function useToggles<T extends string>(modals: ToggleProps<T>) {
  const [isOpen, setIsOpen] = useState(modals);

  const toggleTrigger = (modalKey: T) =>
    setIsOpen(prevState => ({
      ...prevState,
      [modalKey]: !isOpen[modalKey]
    }));

  const toggle = (modalKey: T) => () => toggleTrigger(modalKey);

  const closeAll = () => setIsOpen(mapValues(isOpen, () => false));
  const openAll = () => setIsOpen(mapValues(isOpen, () => true));

  return {
    isOpen,
    toggle,
    toggleTrigger,
    closeAll,
    openAll
  };
}
