import React from 'react';
import { Title } from 'ui';

const InvalidInvitationToken = () => {
  return (
    <React.Fragment>
      <Title>Token inválido</Title>
      <p className="paragraph" data-testid="invalid-token-message">
        Entre em contato com o administrador da plataforma para receber um novo
        link.
      </p>
    </React.Fragment>
  );
};

export default InvalidInvitationToken;
