import { CaseEntity, EntityFormAttributes, Asset } from 'types';
import { CreditContract } from './creditContract';
import { Execution } from './execution';
import { FileUpload } from './file';

export enum GuaranteeModality {
  CLEAN = 'clean',
  ALIENACAO_FIDUCIARIA = 'alienacao_fiduciaria',
  ALIENACAO_FIDUCIARIA_SOBEJO = 'alienacao_fiduciaria_sobejo',
  AVAL = 'aval',
  CAUCAO = 'caucao',
  CESSAO_FIDUCIARIA = 'cessao_fiduciaria',
  CESSAO_FIDUCIARIA_SOBEJO = 'cessao_fiduciaria_sobejo',
  COMFORT_LETTER = 'comfort_letter',
  CPR = 'cpr',
  FIANCA = 'fianca',
  FIANCA_BANCARIA = 'fianca_bancaria',
  GUARANTEE_LETTER = 'guarantee_letter',
  HIPOTECA_1_GRAU = 'hipoteca_1_grau',
  HIPOTECA_2_GRAU_OU_MAIOR = 'hipoteca_2_grau_ou_maior',
  PENHOR_1_GRAU = 'penhor_1_grau',
  PENHOR_2_GRAU_OU_MAIOR = 'penhor_2_grau_ou_maior',
  SEGURO = 'seguro',
  SBLC = 'sblc',
  WARRANTS_CDAWA = 'warrants_cdawa'
}

export enum GuaranteeObject {
  ACOES_QUOTAS = 'acoes_quotas',
  AERONAVES_EMBARCACOES = 'aeronaves_embarcacoes',
  APLICACOES_FINANCEIRAS = 'aplicacoes_financeiras',
  ATIVO_BIOLOGICO = 'ativo_biologico',
  DUPLICATAS = 'duplicatas',
  EDIFICIO = 'edificio',
  ESTABELECIMENTO_COMERCIAL = 'estabelecimento_comercial',
  FAZENDA_SITIO_CHACARA = 'fazenda_sitio_chacara',
  FGI = 'fgi',
  INDUSTRIA = 'industria',
  LOTE_RURAL = 'lote_rural',
  LOTE_URBANO = 'lote_urbano',
  MAQUINAS_E_EQUIPAMENTOS = 'maquinas_e_equipamentos',
  MARCAS = 'marcas',
  MERCADORIAS = 'mercadorias',
  PESSOA_FISICA = 'pessoa_fisica',
  PESSOA_JURIDICA = 'pessoa_juridica',
  QUOTAS_DE_FIP_FIDCS = 'quotas_de_fip_fidcs',
  RECEBIVEIS_A_PERFORMAR = 'recebiveis_a_performar',
  RECEBIVEIS_PERFORMADOS = 'recebiveis_performados',
  RESIDENCIAL_APTO = 'residencial_apto',
  RESIDENCIAL_CASA = 'residencial_casa',
  SALAS_COMERCIAIS = 'salas_comerciais',
  VEICULOS = 'veiculos'
}

export enum GuaranteeType {
  INDIVIDUAL = 'individual',
  COMPARTILHADA = 'compartilhada'
}

export type GuaranteeAttributes = {
  modality: GuaranteeModality;
  type: GuaranteeType;
  instrumentNumber: string;
  requiredCoverage: number | null;
  sharedGuaranteeParticipation: string | null;
  object: GuaranteeObject;
  comments: string;
  fileUploads: Array<FileUpload>;
  guarantor: CaseEntity;
  contractId: string;
  contracts: Array<CreditContract>;
};

export type Guarantee = GuaranteeAttributes & {
  id: string;
  executions: Execution[];
  assets: Asset[];
};

export type GuaranteeParams = Partial<Guarantee>;

export type GuaranteeFormAttributes = {
  modality: GuaranteeModality | string;
  object: GuaranteeObject | string;
  type: GuaranteeType | string;
  fileUploadIds: Array<string>;
  guarantor: EntityFormAttributes;
  comments: string;
  sharedGuaranteeParticipation: string;
  instrumentNumber: string;
  requiredCoverage: number | string;
};
