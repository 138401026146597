import React, { useContext } from 'react';
import compact from 'lodash/compact';

import { Action, can, fetchCaseCities } from 'api';
import { useQuery, useToggles } from 'hooks';

import {
  Accordion,
  Card,
  openErrorToast,
  RadioSwitch,
  Stack,
  StackMargin
} from 'ui';
import { CaseCitiesTable, CaseContext } from 'components';

import CityMap from 'components/CityMap/CityMap';
import { CaseCityEntriesType } from 'types';

type Props = {
  caseId: string;
};

const Cities = ({ caseId }: Props) => {
  const { toggle, isOpen } = useToggles<'showMap'>({ showMap: false });
  const { caseData } = useContext(CaseContext);
  const negotiatorId = caseData?.negotiator.id;

  const canUser = {
    showMapToggle: can(
      Action.CASOS_RASTREAMENTO_PRACAS_MOSTRAR_MAPA,
      negotiatorId
    ),
    showSuspectReport: can(
      Action.CASOS_RASTREAMENTO_PRACAS_VER_SUSPEITO,
      negotiatorId
    ),
    showPropertyReport: can(
      Action.CASOS_RASTREAMENTO_PRACAS_VER_IMOVEL,
      negotiatorId
    ),
    showLawsuitReport: can(
      Action.CASOS_RASTREAMENTO_PRACAS_VER_PROCESSO,
      negotiatorId
    )
  };

  const { data: caseCities = [], error, isLoading } = useQuery(
    fetchCaseCities,
    { caseId }
  );

  if (error) {
    openErrorToast('Houve um erro ao listar praças');
  }

  const existSomeCaseCities = Boolean(caseCities.length);

  const hiddenEntriesLinks = compact([
    !canUser.showSuspectReport && CaseCityEntriesType.REPORT,
    !canUser.showPropertyReport && CaseCityEntriesType.ASSET,
    !canUser.showLawsuitReport && CaseCityEntriesType.INTERNAL_LAWSUITS
  ]);

  return (
    <Accordion
      title="Praças"
      badgeContent={caseCities.length}
      headerActions={
        canUser.showMapToggle &&
        existSomeCaseCities && (
          <RadioSwitch
            label="Mostrar mapa de praças"
            onChange={toggle('showMap')}
            active={isOpen.showMap}
            inputId="case-cities-map"
          />
        )
      }
    >
      {isOpen.showMap && existSomeCaseCities && (
        <CityMap caseId={caseId} cities={caseCities} />
      )}
      <Stack marginTop={StackMargin.MEDIUM}>
        <Card small>
          <CaseCitiesTable
            caseCities={caseCities}
            isLoading={isLoading}
            hiddenEntriesLinks={hiddenEntriesLinks}
          />
        </Card>
      </Stack>
    </Accordion>
  );
};

export default Cities;
