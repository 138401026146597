import { lawsuitNumber } from 'domain/lawsuits';
import React, { ReactNode } from 'react';
import cn from 'classnames';

import { Card, Link, Icon } from 'ui';
import { InternalLawsuit, ChildLawsuit } from 'types';
import { LinkLocation } from 'ui/Link/Link';

import styles from './LawsuitCard.module.scss';

type Props = {
  title: React.ReactElement;
  dataTestId?: string;
  lawsuit: InternalLawsuit | ChildLawsuit;
  className?: string;
  children?: ReactNode;
};

type TitleProps = {
  url: LinkLocation | string;
  lawsuit: InternalLawsuit | ChildLawsuit;
  disabled?: boolean;
};

const TriggerTitle = ({ lawsuit, url, disabled = false }: TitleProps) => (
  <h3 className={styles.title}>
    <Link
      to={url}
      className={styles.link}
      highlight
      underline
      disabled={disabled}
    >
      {lawsuitNumber(lawsuit.number)}
      {disabled || <Icon name="arrow-right" small />}
    </Link>
  </h3>
);

const LawsuitCard = ({
  lawsuit,
  dataTestId = 'card-item',
  title,
  children,
  className
}: Props) => {
  return (
    <Card
      className={cn(styles.lawsuitCard, className)}
      dataTestId={dataTestId}
      medium
    >
      {title}

      {lawsuit.type && <div className={styles.subtitle}>{lawsuit.type}</div>}

      {lawsuit.description && (
        <div className={styles.description}>{lawsuit.description}</div>
      )}
      {children}
    </Card>
  );
};

export default Object.assign(LawsuitCard, { TriggerTitle });
