import React, { ReactElement } from 'react';
import cn from 'classnames';

type Props = {
  children?: ReactElement[] | ReactElement | string;
  className?: string;
  colSpan?: number;
  dataTestId?: string;
  headerSticky?: boolean;
  isExpanded?: boolean;
  centeredContent?: boolean;
  onClick?: () => void;
  textAlign?: 'center' | 'right';
};

const TableCell = ({
  children,
  className,
  colSpan,
  dataTestId = 'tableCell',
  headerSticky,
  isExpanded = false,
  onClick,
  textAlign,
  centeredContent = false
}: Props) => {
  const classNames = cn('cell', className, {
    '-sticky': headerSticky,
    '-expanded': isExpanded,
    '-center': textAlign === 'center',
    '-right': textAlign === 'right',
    '-centeredContent': centeredContent
  });

  return (
    <td
      className={classNames}
      data-testid={dataTestId}
      colSpan={colSpan}
      onClick={onClick}
    >
      {children}
    </td>
  );
};

export default TableCell;
