import React, { ReactNode } from 'react';

import {
  fetchAggregatedProfilePublications,
  FetchAggregatedProfilePublicationsParams
} from 'api';
import { useQuery } from 'hooks';
import { InstagramPublication } from 'types';
import { openErrorToast } from 'ui';

import PublicationsModal from './Modal';

const INITIAL_PAGE = 1;
const DEFAULT_PAGE_SIZE = 6;

type Props = {
  aggregatedProfileId: string;
  caseId: string;
  instagramProfileId: string;
  isOpen: boolean;
  canBookmarkPublication?: boolean;
  onClose: () => void;
  reportId: string;
  title: ReactNode;
};

const RelatedInstagramProfilePublicationsModal = ({
  aggregatedProfileId,
  caseId,
  instagramProfileId,
  isOpen,
  canBookmarkPublication = true,
  onClose,
  reportId,
  title
}: Props) => {
  const {
    data: instagramPublications,
    isLoading,
    error,
    pagination,
    refetch
  } = useQuery<
    InstagramPublication[],
    FetchAggregatedProfilePublicationsParams
  >(fetchAggregatedProfilePublications, {
    caseId,
    aggregatedProfileId,
    instagramProfileId,
    reportId,
    pagination: {
      page: INITIAL_PAGE,
      pageSize: DEFAULT_PAGE_SIZE
    }
  });

  if (error) openErrorToast('Ocorreu um erro ao carregar as publicações');

  const handlePageChange = (newPage: number) =>
    refetch({
      caseId,
      aggregatedProfileId,
      instagramProfileId,
      reportId,
      pagination: {
        page: newPage,
        pageSize: DEFAULT_PAGE_SIZE
      }
    });

  return (
    <PublicationsModal
      caseId={caseId}
      instagramPublications={instagramPublications}
      isOpen={isOpen}
      isLoading={isLoading}
      canBookmarkPublication={canBookmarkPublication}
      onCurrentPageChange={handlePageChange}
      onClose={onClose}
      pagination={pagination}
      reportId={reportId}
      title={title}
    />
  );
};

export default RelatedInstagramProfilePublicationsModal;
