import React, { useContext } from 'react';
import { Case, Duplicate, Invoice } from 'types';
import { Accordion, Button, Stack, StackMargin } from 'ui';
import { Action, can } from 'api';
import { CaseContext } from 'components';
import { applyMask } from 'utils';
import { useDuplicatesList } from './useDuplicatesList';
import DuplicateCard from './DuplicateCard';
import style from './Duplicates.module.scss';

type Props = {
  caseId: Case['id'];
  invoiceId: Invoice['id'];
  initialDuplicates: Duplicate[];
};
const Duplicates = ({ initialDuplicates, ...params }: Props) => {
  const {
    duplicatesCount,
    mapDuplicates,
    newDuplicate,
    setDuplicate,
    totals,
    deleteDuplicate,
    duplicateEntry
  } = useDuplicatesList(initialDuplicates);

  const { caseData } = useContext(CaseContext);

  const negotiatorId = caseData?.negotiator?.id;

  const canShowDuplicates = can(
    Action.CASOS_NOTA_FISCAL_DUPLICATAS_CONSULTAR,
    negotiatorId
  );
  const canAddDuplicate = can(
    Action.CASOS_NOTA_FISCAL_DUPLICATAS_INCLUIR,
    negotiatorId
  );

  return (
    <>
      {canShowDuplicates && (
        <Accordion
          title="Duplicatas"
          badgeContent={duplicatesCount}
          headerInfo={{
            label: (
              <div className={style.labelContainer}>
                <span className={style.label}>Valor total:</span>
                <span className={style.label}>Saldo devedor total:</span>
              </div>
            ),
            value: (
              <div
                className={`${style.labelContainer} ${style.labelContainerRigth}`}
              >
                <div className={`${style.label} ${style.labelRigth}`}>
                  {applyMask('currency', totals.value.toString())}
                </div>
                <div className={`${style.label} ${style.labelRigth}`}>
                  {applyMask('currency', totals.overdue.toString())}
                </div>
              </div>
            ),
            dataTestId: 'duplicates-summary'
          }}
        >
          {mapDuplicates((duplicate, key, index) => {
            const isFirst = index === 0;
            const marginTop = isFirst ? StackMargin.ZERO : StackMargin.MEDIUM;

            return (
              <Stack marginTop={marginTop} key={`stack-${key}`}>
                <DuplicateCard
                  {...params}
                  key={`duplicate-item-${key}`}
                  duplicate={duplicate}
                  setDuplicate={setDuplicate(key)}
                  htmlId={`duplicate-item-${key}`}
                  deleteDuplicate={deleteDuplicate(key)}
                  duplicateEntry={duplicateEntry}
                  currentKey={key}
                />
              </Stack>
            );
          })}

          {canAddDuplicate && (
            <Stack marginTop={StackMargin.MEDIUM}>
              <Button onClick={newDuplicate} highlight outline centered>
                Adicionar duplicata
              </Button>
            </Stack>
          )}
        </Accordion>
      )}
    </>
  );
};

export default Duplicates;
