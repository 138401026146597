import React from 'react';
import { Reclaiming } from 'types';

import HeaderStatus from '../HeaderStatus';

type Props = {
  caseId: string;
  reclaiming: Reclaiming;
  disabled?: boolean;
};

const ReclaimingStatus = ({ caseId, reclaiming, disabled }: Props) => {
  return (
    <HeaderStatus
      links={[
        {
          label: `nº ${reclaiming.contract.number}`,
          linkId: reclaiming.id,
          linkTo: `/cases/${caseId}/debtors/contract/${reclaiming.contract.id}`
        }
      ]}
      strongText="Em recuperação"
      middleText="no contrato"
      dataTestId="reclaiming-status"
      disabled={disabled}
    />
  );
};

export default ReclaimingStatus;
