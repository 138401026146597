import { buildPaginationParams } from 'domain/pagination';
import React, { useContext, useState } from 'react';

import {
  deleteReport,
  createEntityFromReport,
  retryReportFailedQueries,
  refreshReport,
  can,
  Action
} from 'api';

import { CaseContext, usePaginationContext } from 'components';
import { Report, ApiPagination, PaginatedResource } from 'types';

import {
  SubTitle,
  Button,
  Card,
  Modal,
  openErrorToast,
  openSuccessToast
} from 'ui';

import Form from './Form';
import ReportTable from './ReportTable';

type Props = {
  caseId: string;
  reports: Report[];
  refetchSuspects: () => void;
  isLoading: boolean;
  refetchReports: () => void;
  pagination?: ApiPagination;
  setReports: React.Dispatch<React.SetStateAction<Report[] | undefined>>;
};

const Reports = ({
  caseId,
  reports,
  refetchSuspects,
  isLoading,
  refetchReports,
  pagination,
  setReports
}: Props) => {
  const { changePagination } = usePaginationContext();
  const [isModalOpen, toggleModal] = useState<boolean>(false);
  const { caseData } = useContext(CaseContext);

  const handleOnCreateReport = async (report: Report) => {
    refetchReports();
    toggleModal(false);

    window.open(`/cases/${report.caseId}/reports/${report.id}`);
  };

  const handleOnDeleteReport = async (reportId: string) => {
    if (
      !window.confirm('Você tem certeza de que deseja remover esta consulta?')
    )
      return;

    try {
      await deleteReport(caseId, reportId);
      refetchReports();
      openSuccessToast('Consulta removida com sucesso!');
    } catch (error) {
      openErrorToast('Não foi possível remover a consulta.');
    }
  };

  const handleOnCreateEntity = async (reportId: string) => {
    try {
      await createEntityFromReport(caseId, reportId);
      refetchReports();
      refetchSuspects();
      openSuccessToast('Suspeito adicionado com sucesso!');
    } catch (error) {
      openErrorToast('Não foi possível adicionar a lista de suspeitos.');
    }
  };

  const updateReportInListing = (
    reGeneratedReport: Report,
    updatedReportId: string
  ) => {
    const updatedEntities = reports.map(report =>
      report.id === updatedReportId ? reGeneratedReport : report
    );
    setReports(updatedEntities);
  };

  const handleRetryReport = async (reportId: string) => {
    try {
      const { data: report } = await retryReportFailedQueries(caseId, reportId);
      updateReportInListing(report, reportId);
      openSuccessToast(
        'Refazendo consultas que falharam! Aguarde o término para visualizá-las.'
      );
    } catch (error) {
      openErrorToast('Não foi possível atualizar o relatório.');
    }
  };

  const handleGenerateErrorReport = async (reportId: string) => {
    try {
      const { data: report } = await refreshReport(caseId, reportId);
      openSuccessToast(
        'Gerando relatório! Aguarde o término para visualizá-lo.'
      );
      updateReportInListing(report, reportId);
    } catch (error) {
      openErrorToast('Não foi possível gerar o relatório.');
    }
  };

  const handleChangePagination = (pagination?: {
    page: number;
    pageSize: number;
  }) => {
    const { page, pageSize } = pagination!;
    changePagination(PaginatedResource.PEOPLE_REPORTS, { page, pageSize });
  };

  const paginationProps = buildPaginationParams(
    pagination,
    handleChangePagination
  );

  const canAddReports = can(
    Action.CASOS_RASTREAMENTO_CONSULTAS_INCLUIR,
    caseData?.negotiator.id
  );

  return (
    <div className="report-container" data-testid="reports-list">
      <SubTitle badgeTestId="report-badge" badgeText={pagination?.totalEntries}>
        Consultas
      </SubTitle>
      <Card small>
        <ReportTable
          reports={reports}
          onDelete={handleOnDeleteReport}
          onCreateEntity={handleOnCreateEntity}
          handleRetryReport={handleRetryReport}
          handleGenerateErrorReport={handleGenerateErrorReport}
          pagination={paginationProps}
        />

        {canAddReports && (
          <div className="actions">
            <Button
              dataTestId="new-report-trigger"
              outline
              highlight
              centered
              small
              onClick={() => toggleModal(true)}
              disabled={isLoading}
            >
              Nova consulta
            </Button>
          </div>
        )}
      </Card>

      <Modal
        isOpen={isModalOpen}
        onClose={() => toggleModal(false)}
        title="Nova consulta"
      >
        <Form caseId={caseId} onCreateReport={handleOnCreateReport} />
      </Modal>
    </div>
  );
};

export default Reports;
