import { entityDocument, entityRolesToString } from 'domain/entity';
import * as Reports from 'domain/reports';
import { handleCopy, NOSELECT } from 'domain/copyTableRow';
import React, { useContext } from 'react';
import { DropDown, DynamicTable, TablePositionModifier } from 'ui';
import { DropDownOption } from 'ui/DropDown/DropDown';
import {
  CaseEntity,
  ActionName,
  ActionEntity,
  cpfSituationName,
  cnpjSituationTitle,
  CnpjSituation,
  Status,
  PaginationProps,
  PaginatedResource,
  CPFSituation
} from 'types';
import { CaseContext, ReportStatusWithModal } from 'components';
import * as Actions from 'utils/actions';
import { handleNullValue, reportFieldFirstValue } from 'utils';
import { Action, can } from 'api';
import { compact } from 'lodash';
import style from './SuspectTable.module.scss';

type GetOptionsProps = {
  handleDelete: (entityId: string) => Promise<void>;
  handleCreateEntityReport: (entityId: string) => Promise<void>;
  handleUpdateReport: (
    entity: CaseEntity,
    isErrorReport: boolean
  ) => Promise<void>;
  entity: CaseEntity;
  reportIsEnabled: boolean;
  canUser: {
    deleteSuspect: boolean;
    createReport: boolean;
    showReport: boolean;
    updateReport: boolean;
    copyLine: boolean;
  };
};

const getOptions = ({
  handleDelete,
  handleCreateEntityReport,
  handleUpdateReport,
  entity,
  reportIsEnabled,
  canUser
}: GetOptionsProps): DropDownOption[] => {
  const defaultOptions = [
    {
      text: 'Copiar linha',
      callback: () => handleCopy(entity.id, 'suspects'),
      dataTestId: `copy-${entity.id}`,
      isHidden: !canUser.copyLine
    },
    {
      text: 'Excluir',
      callback: () => handleDelete(entity.id),
      dataTestId: `delete-${entity.id}`,
      isNegative: true,
      isDisabled: Actions.isDisabled(entity.actions, ActionName.DELETE),
      description: Actions.unavailableReasons(
        entity.actions,
        ActionName.DELETE,
        ActionEntity.SUSPECT
      ),
      isHidden: !canUser.deleteSuspect
    }
  ];
  const reportStatus = Reports.reportStatus(entity.currentReport);

  const createReportDisabled = !entity.company?.cnpj && !entity.individual?.cpf;
  const isErrorStatus = reportStatus === Status.ERROR;

  if (reportIsEnabled) {
    if (reportStatus === Status.NOSTATUS || isErrorStatus) {
      return [
        {
          text: 'Gerar relatório',
          callback: () =>
            isErrorStatus
              ? handleUpdateReport(entity, true)
              : handleCreateEntityReport(entity.id),
          dataTestId: `create-report-${entity.id}`,
          isDisabled: createReportDisabled,
          description: createReportDisabled
            ? 'Não é possivel gerar relatório pois o devedor não tem CPF ou CNPJ'
            : null,
          isHidden: !canUser.createReport
        },
        ...defaultOptions
      ];
    }

    return [
      {
        text: 'Ver relatório',
        isLink: true,
        dataTestId: `show-report-${entity.id}`,
        link: `/cases/${entity.currentReport?.caseId}/reports/${entity.currentReport?.id}`,
        isHidden: !canUser.showReport
      },
      {
        text: 'Atualizar relatório',
        callback: () => handleUpdateReport(entity, false),
        dataTestId: `update-report-${entity.id}`,
        isHidden: !canUser.updateReport
      },
      ...defaultOptions
    ];
  }

  return defaultOptions;
};

type Props = {
  suspects: CaseEntity[];
  handleDelete: (entityId: string) => Promise<void>;
  handleCreateEntityReport: (entityId: string) => Promise<void>;
  handleUpdateReport: (
    entity: CaseEntity,
    isErrorReport: boolean
  ) => Promise<void>;
  handleRetryFailedQueries: (entity: CaseEntity) => Promise<void>;
  reportIsEnabled: boolean;
  pagination: PaginationProps;
};

const SuspectTable = ({
  suspects,
  handleDelete,
  handleCreateEntityReport,
  handleUpdateReport,
  handleRetryFailedQueries,
  reportIsEnabled,
  pagination
}: Props) => {
  const { caseData } = useContext(CaseContext);
  const negotiatorId = caseData?.negotiator.id;
  const docsFormated = (suspect: CaseEntity) =>
    cpfSituationName(
      reportFieldFirstValue(
        suspect.currentReport?.individual?.cpfSituation
      ) as CPFSituation
    ) ||
    cnpjSituationTitle(
      reportFieldFirstValue(
        suspect.currentReport?.company?.cnpjSituation
      ) as CnpjSituation
    );

  const canUser = {
    createReport: can(
      Action.CASOS_RASTREAMENTO_SUSPEITOS_GERAR_RELATORIO,
      negotiatorId
    ),
    deleteSuspect: can(
      Action.CASOS_RASTREAMENTO_SUSPEITOS_EXCLUIR,
      negotiatorId
    ),
    showSuspectOrigin: can(
      Action.CASOS_RASTREAMENTO_SUSPEITOS_VER_ORIGEM,
      negotiatorId
    ),
    showReport: can(
      Action.CASOS_RASTREAMENTO_SUSPEITOS_VER_RELATORIO,
      negotiatorId
    ),
    showPartialReport: can(
      Action.CASOS_RASTREAMENTO_SUSPEITOS_VER_RELATORIO_PARCIAL,
      negotiatorId
    ),
    seePartial: can(
      Action.CASOS_RELATORIO_PFPJ_VER_RELATORIO_PARCIAL,
      negotiatorId
    ),
    updateReport: can(
      Action.CASOS_RASTREAMENTO_SUSPEITOS_ATUALIZAR_RELATORIO,
      negotiatorId
    ),
    copyLine: can(
      Action.CASOS_RASTREAMENTO_SUSPEITOS_COPIAR_LINHA,
      negotiatorId
    ),
    retry: can(Action.CASOS_RELATORIO_PFPJ_TENTAR_NOVAMENTE, negotiatorId)
  };

  const columns = compact([
    {
      columnKey: 'entityDocument',
      name: 'CPF ou CNPJ',
      render: (suspect: CaseEntity) => entityDocument(suspect),
      hasOrderBy: true
    },
    {
      columnKey: 'entityName',
      name: 'Nome ou razão social',
      render: (suspect: CaseEntity) =>
        suspect.company?.businessName || suspect.individual?.name,
      hasOrderBy: true
    },
    {
      columnKey: 'description',
      name: 'Descrição',
      render: (suspect: CaseEntity) =>
        handleNullValue(suspect.description, 'string'),
      hasOrderBy: true,
      className: () => style.largeColumn
    },
    {
      columnKey: 'situation',
      name: 'Situação',
      render: (suspect: CaseEntity) =>
        handleNullValue(docsFormated(suspect), 'string'),
      hasOrderBy: true
    },
    {
      columnKey: 'type',
      name: 'Tipo',
      render: (suspect: CaseEntity) => entityRolesToString(suspect.roles),
      hasOrderBy: true
    },
    {
      columnKey: 'report',
      name: 'Status do relatório',
      className: () => NOSELECT,
      render: (suspect: CaseEntity) => {
        const status = Reports.reportStatus(suspect.currentReport);
        const isError = status === Status.ERROR;

        return (
          <ReportStatusWithModal
            report={suspect.currentReport}
            handleTryAgain={() => handleRetryFailedQueries(suspect)}
            handleGenerateErrorReport={() => handleUpdateReport(suspect, true)}
            disabled={!canUser.showPartialReport}
            showLink={canUser.seePartial}
            showAction={isError ? canUser.createReport : canUser.retry}
          />
        );
      }
    },
    {
      columnKey: 'dropdown',
      positionModifier: TablePositionModifier.RIGHT,
      name: '',
      className: () => NOSELECT,
      render: (suspect: CaseEntity) => {
        const options = getOptions({
          handleDelete,
          handleCreateEntityReport,
          handleUpdateReport,
          entity: suspect,
          reportIsEnabled,
          canUser
        });

        if (options.every(option => option.isHidden)) return <></>;

        return (
          <DropDown dataTestId={`options-${suspect.id}`} options={options} />
        );
      }
    }
  ]);

  const rowKey = (suspect: CaseEntity) => suspect.id;

  return (
    <>
      <DynamicTable
        data={suspects}
        columns={columns}
        rowKey={rowKey}
        pagination={pagination}
        noAutoPaginate
        tableName="suspects"
        paginatedResource={PaginatedResource.PEOPLE_SUSPECTS}
      />
    </>
  );
};

export default SuspectTable;
