import React, { useState } from 'react';
import { Button, SelectField, Stack, StackMargin } from 'ui';
import { createArrayFrom } from 'utils';

type Props = {
  handleDuplicate: (amount: number) => void;
};

const DuplicateForm = ({ handleDuplicate }: Props) => {
  const [duplicateAmount, setDuplicateAmount] = useState<number>(1);

  const handleSelectChange = (event: React.ChangeEvent<{ value: string }>) => {
    setDuplicateAmount(Number(event.target.value));
  };

  const handleCopy = () => {
    handleDuplicate(duplicateAmount);
  };

  return (
    <>
      <SelectField
        name="duplicateAmount"
        id="duplicateAmount"
        value={duplicateAmount.toString()}
        onChange={handleSelectChange}
        options={createArrayFrom({
          size: 10,
          fillFunction: index => {
            const amount = index + 1;

            return {
              value: amount.toString(),
              label: `${amount}x`
            };
          }
        })}
      />

      <Stack marginTop={StackMargin.XLARGE}>
        <Button highlight centered onClick={handleCopy}>
          Copiar
        </Button>
      </Stack>
    </>
  );
};

export default DuplicateForm;
