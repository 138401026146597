import {
  duplicateProtestStatusOptions,
  duplicateStatusOptions
} from 'domain/duplicate';
import {
  booleanOptions,
  getFormikError,
  handleDateFieldChange
} from 'domain/forms';
import { FileField, useFormDirty, CaseContext } from 'components';
import {
  Action,
  can,
  saveDuplicate,
  deleteDuplicate as deleteDuplicateAPI
} from 'api';
import { useFormik } from 'formik';
import { DateTime } from 'luxon';
import React, { useEffect, useContext } from 'react';
import {
  Case,
  Duplicate,
  DuplicateFormAttributes,
  Invoice,
  OnSubmit
} from 'types';
import {
  Button,
  Card,
  DatePickerField,
  FormContainer,
  InputField,
  Modal,
  openErrorToast,
  openSuccessToast,
  SelectField,
  TextArea,
  Toggle
} from 'ui';
import * as Yup from 'yup';
import mapValues from 'lodash/mapValues';
import { DuplicateEntryInput, useToggles, ContainerEntry } from 'hooks';
import style from './DuplicateCard.module.scss';
import { DuplicateForm } from './DuplicateForm';

type Props = {
  caseId: Case['id'];
  invoiceId: Invoice['id'];
  setDuplicate: React.Dispatch<Duplicate>;
  duplicate?: Duplicate & ContainerEntry;
  isLoading?: boolean;
  htmlId: string;
  currentKey: string;
  deleteDuplicate: () => void;
  duplicateEntry: React.Dispatch<DuplicateEntryInput>;
};

const boolOptions = booleanOptions();

const DuplicatesFormSchema = Yup.object<
  Record<keyof DuplicateFormAttributes, unknown>
>({
  comment: Yup.string(),
  fileUploadIds: Yup.array(Yup.string()),
  dueDate: Yup.string().required('Campo obrigatório'),
  externalNumber: Yup.string().required('Campo obrigatório'),
  hasReceipt: Yup.boolean().required('Campo obrigatório'),
  internalNumber: Yup.string().required('Campo obrigatório'),
  issueDate: Yup.string().required('Campo obrigatório'),
  protestStatus: Yup.string().required('Campo obrigatório'),
  status: Yup.string().required('Campo obrigatório'),
  taxPercentage: Yup.number()
    .min(0, 'Dever ser maior que 0')
    .required('Campo obrigatório'),
  valueCents: Yup.number()
    .min(0, 'Dever ser maior que 0')
    .required('Campo obrigatório'),
  fineAmount: Yup.number()
    .min(0, 'Dever ser maior que 0')
    .required('Campo obrigatório'),
  finePercentage: Yup.number()
    .min(0, 'Dever ser maior que 0')
    .required('Campo obrigatório'),
  moraAmount: Yup.number()
    .min(0, 'Dever ser maior que 0')
    .required('Campo obrigatório'),
  moraPercentage: Yup.number()
    .min(0, 'Dever ser maior que 0')
    .required('Campo obrigatório'),
  overdueAmount: Yup.number()
    .min(0, 'Dever ser maior que 0')
    .required('Campo obrigatório'),
  deliveryNumber: Yup.string().required('Campo obrigatório')
});

const initialValues: DuplicateFormAttributes = {
  dueDate: '',
  externalNumber: '',
  fineAmount: '',
  finePercentage: '',
  internalNumber: '',
  issueDate: '',
  moraAmount: '',
  moraPercentage: '',
  overdueAmount: '',
  protestStatus: '',
  status: '',
  taxPercentage: '',
  comment: '',
  valueCents: '',
  hasReceipt: true,
  fileUploadIds: [],
  deliveryNumber: ''
};

const buildInitialValues = (duplicate?: Duplicate): DuplicateFormAttributes => {
  if (!duplicate) return initialValues;

  const { fileUploads, hasReceipt, comment, deliveryNumber } = duplicate;
  return {
    ...mapValues(duplicate, String),
    deliveryNumber: deliveryNumber ?? '',
    comment: comment ?? '',
    hasReceipt: hasReceipt,
    fileUploadIds: fileUploads.map(fileUp => fileUp.id)
  };
};

const DuplicateCard = ({
  caseId,
  invoiceId,
  setDuplicate,
  duplicate,
  isLoading = false,
  htmlId,
  currentKey,
  deleteDuplicate,
  duplicateEntry
}: Props) => {
  const { setDirtiness } = useFormDirty();

  const { isOpen, toggle, closeAll } = useToggles<'duplicate'>({
    duplicate: false
  });

  const handleDuplicate = (duplicateAmount: number) => {
    duplicateEntry({
      duplicateAmount,
      currentKey,
      keysToReset: {
        dueDate: '',
        id: null,
        internalNumber: '',
        externalNumber: '',
        fileUploads: []
      }
    });

    closeAll();
  };

  const handleSubmit: OnSubmit<DuplicateFormAttributes> = async (
    values,
    formikHelpers
  ) => {
    try {
      const response = await saveDuplicate(
        { caseId, invoiceId, duplicateId: duplicate?.id },
        values
      );
      setDuplicate(response?.data);
      setDirtiness({
        formKey: htmlId,
        isDirty: false,
        formName: 'Duplicatas'
      });

      openSuccessToast('Duplicata salva com sucesso!');
    } catch (error) {
      formikHelpers.setErrors(error.errors);
      openErrorToast('Houve uma falha ao salvar a duplicata.');
    }
  };

  const formik = useFormik<DuplicateFormAttributes>({
    initialValues: buildInitialValues(duplicate),
    onSubmit: handleSubmit,
    validationSchema: DuplicatesFormSchema,
    enableReinitialize: true
  });

  const { caseData } = useContext(CaseContext);

  useEffect(() => {
    setDirtiness({
      formKey: htmlId,
      isDirty: formik.dirty,
      formName: 'Duplicatas'
    });
  }, [formik.dirty, htmlId, setDirtiness]);

  useEffect(() => {
    if (duplicate?.scrollToThis) {
      const element = document.getElementById(htmlId);

      element?.scrollIntoView({ behavior: 'smooth' });
    }

    if (duplicate?.isDuplicated) {
      formik.setFieldValue('isDuplicated', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = async () => {
    if (!formik.dirty && !duplicate?.id) {
      return deleteDuplicate();
    }

    if (window.confirm('Você deseja excluir essa duplicata?')) {
      try {
        if (duplicate?.id) {
          await deleteDuplicateAPI({
            caseId,
            duplicateId: duplicate.id
          });

          openSuccessToast('Duplicata excluida com sucesso!');
        }

        deleteDuplicate();
        setDirtiness({
          formKey: htmlId,
          isDirty: false,
          formName: 'Duplicatas'
        });
      } catch (error) {
        openErrorToast('Houve uma falha ao excluir a duplicata.');
      }
    }
  };

  const getError = getFormikError(formik);

  const negotiatorId = caseData?.negotiator?.id;

  const canUser = {
    showFiles: can(
      Action.CASOS_NOTA_FISCAL_ARQUIVOS_DA_DUPLICATA_CONSULTAR,
      negotiatorId
    ),
    addFiles: can(
      Action.CASOS_NOTA_FISCAL_ARQUIVOS_DA_DUPLICATA_INCLUIR,
      negotiatorId
    ),
    deleteFiles: can(
      Action.CASOS_NOTA_FISCAL_ARQUIVOS_DA_DUPLICATA_EXCLUIR,
      negotiatorId
    ),
    deleteDuplicate: can(
      Action.CASOS_NOTA_FISCAL_DUPLICATAS_EXCLUIR,
      negotiatorId
    ),
    editDuplicate: can(
      Action.CASOS_NOTA_FISCAL_DUPLICATAS_EDITAR,
      negotiatorId
    ),
    copyDuplicate: can(Action.CASOS_NOTA_FISCAL_DUPLICATAS_COPIAR, negotiatorId)
  };

  const isNewDuplicate = !duplicate;
  const isCopiedDuplicate = duplicate && !duplicate.id;

  const isDisabled =
    isCopiedDuplicate || isNewDuplicate ? false : !canUser.editDuplicate;

  const canShowSaveButton =
    isCopiedDuplicate ||
    isNewDuplicate ||
    canUser.editDuplicate ||
    canUser.addFiles ||
    canUser.deleteFiles;

  const canShowDeleteButton =
    isCopiedDuplicate || isNewDuplicate || canUser.deleteDuplicate;

  return (
    <>
      <Card
        className={style.card}
        customHeader={
          <h2 className={style.duplicateLabel}>
            Duplicata {formik.values.externalNumber}
          </h2>
        }
        dataTestId="duplicate-card"
        id={htmlId}
      >
        <div>
          <div className={style.actions}>
            {canShowDeleteButton && (
              <Button outline small danger onClick={handleDelete}>
                Excluir
              </Button>
            )}

            {canUser.copyDuplicate && (
              <Button
                outline
                highlight={!!duplicate?.id && !formik.dirty}
                small
                disabled={!duplicate?.id || formik.dirty}
                onClick={toggle('duplicate')}
              >
                Copiar duplicata
              </Button>
            )}

            {canShowSaveButton && (
              <Button
                highlight
                small
                onClick={formik.handleSubmit}
                disabled={!formik.dirty}
              >
                Salvar
              </Button>
            )}
          </div>

          <div>
            <FormContainer className="col-3">
              <InputField
                error={getError('internalNumber')}
                id="internalNumber"
                isLoading={isLoading}
                name="internalNumber"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                title="Nosso número"
                type="text"
                value={formik.values.internalNumber}
                disabled={isDisabled}
              />

              <InputField
                error={getError('externalNumber')}
                id="externalNumber"
                isLoading={isLoading}
                name="externalNumber"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                title="Número da duplicata"
                type="text"
                value={formik.values.externalNumber}
                disabled={isDisabled}
              />

              <InputField
                error={getError('valueCents')}
                id="valueCents"
                isLoading={isLoading}
                name="valueCents"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                title="Valor da duplicata"
                type="currency"
                value={formik.values.valueCents}
                disabled={isDisabled}
              />
            </FormContainer>
            <FormContainer className="col-4">
              <InputField
                error={getError('deliveryNumber')}
                id="deliveryNumber"
                isLoading={isLoading}
                name="deliveryNumber"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                title="Número NF entrega de mercadoria"
                type="text"
                value={formik.values.deliveryNumber}
                disabled={isDisabled}
              />

              <Toggle
                isLoading={isLoading}
                name="hasReceipt"
                onChange={formik.handleChange('hasReceipt')}
                options={boolOptions}
                checked={formik.values.hasReceipt.toString()}
                title="Comprovante de entrega mercadoria"
                disabled={isDisabled}
              />

              <SelectField
                error={getError('status')}
                id="status"
                isLoading={isLoading}
                name="status"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                options={duplicateStatusOptions()}
                optional
                title="Situação"
                value={formik.values.status}
                disabled={isDisabled}
              />

              <SelectField
                error={getError('protestStatus')}
                id="protestStatus"
                isLoading={isLoading}
                name="protestStatus"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                options={duplicateProtestStatusOptions()}
                optional
                title="Situação do protesto"
                value={formik.values.protestStatus}
                disabled={isDisabled}
              />
            </FormContainer>
            <FormContainer className="col-4">
              <DatePickerField
                error={getError('issueDate')}
                id="issueDate"
                name="issueDate"
                onChange={handleDateFieldChange(
                  formik.setFieldValue,
                  formik.setFieldTouched,
                  'issueDate'
                )}
                title="Data de emissão"
                value={formik.values.issueDate}
                max={DateTime.local().toISO()}
                disabled={isDisabled}
              />

              <DatePickerField
                error={getError('dueDate')}
                id="dueDate"
                name="dueDate"
                onChange={handleDateFieldChange(
                  formik.setFieldValue,
                  formik.setFieldTouched,
                  'dueDate'
                )}
                title="Vencimento"
                value={formik.values.dueDate}
                disabled={isDisabled}
              />

              <InputField
                error={getError('overdueAmount')}
                id="overdueAmount"
                isLoading={isLoading}
                name="overdueAmount"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                title="Saldo devedor"
                type="currency"
                value={formik.values.overdueAmount}
                disabled={isDisabled}
              />

              <InputField
                error={getError('taxPercentage')}
                id="taxPercentage"
                isLoading={isLoading}
                name="taxPercentage"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                title="Taxa (%)"
                type="percent"
                value={formik.values.taxPercentage}
                disabled={isDisabled}
              />
            </FormContainer>
            <FormContainer className="col-4">
              <InputField
                error={getError('finePercentage')}
                id="finePercentage"
                isLoading={isLoading}
                name="finePercentage"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                title="Multa (%)"
                type="percent"
                value={formik.values.finePercentage}
                disabled={isDisabled}
              />

              <InputField
                error={getError('fineAmount')}
                id="fineAmount"
                isLoading={isLoading}
                name="fineAmount"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                title="Multa (valor)"
                type="currency"
                value={formik.values.fineAmount}
                disabled={isDisabled}
              />

              <InputField
                error={getError('moraPercentage')}
                id="moraPercentage"
                isLoading={isLoading}
                name="moraPercentage"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                title="Mora (%)"
                type="percent"
                value={formik.values.moraPercentage}
                disabled={isDisabled}
              />

              <InputField
                error={getError('moraAmount')}
                id="moraAmount"
                isLoading={isLoading}
                name="moraAmount"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                title="Mora (valor)"
                type="currency"
                value={formik.values.moraAmount}
                disabled={isDisabled}
              />
            </FormContainer>
            <FormContainer>
              <TextArea
                error={getError('comment')}
                id="comment"
                isLoading={isLoading}
                name="comment"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                title="Comentários"
                value={formik.values.comment ?? ''}
                rows={1}
                disabled={isDisabled}
              />
            </FormContainer>
          </div>
          {canUser.showFiles && (
            <div>
              <FileField
                caseId={caseId}
                name="fileUploadIds"
                id={`duplicates-form-${htmlId}`}
                title="Arquivos da duplicata"
                onUploadSuccess={ids => {
                  formik.setFieldValue('fileUploadIds', ids);
                }}
                initialFiles={duplicate?.fileUploads}
                showAdd={canUser.addFiles}
                showDelete={canUser.deleteFiles}
              />
            </div>
          )}
        </div>
      </Card>

      <Modal
        title="Copiar duplicata"
        isOpen={isOpen.duplicate}
        onClose={toggle('duplicate')}
      >
        <DuplicateForm handleDuplicate={handleDuplicate} />
      </Modal>
    </>
  );
};

export default DuplicateCard;
