import React, { ReactElement, useState, useRef } from 'react';
import xor from 'lodash/xor';
import { useDropzone } from 'react-dropzone';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { Button, FileItem, openErrorToast, openSuccessToast } from 'ui';
import { FileUpload, GenericFile } from 'types';
import { deleteFile } from 'api';

import styles from './FileField.module.scss';
import FileUploader from './FileUploader';

const fileUploadsToIds = (files: FileUpload[]): string[] =>
  files.map(file => file.id);

type Props = {
  caseId: string;
  id: string;
  name: string;
  title?: string;
  onUploadSuccess: (ids: string[]) => void;
  placeholder?: string;
  showMode?: boolean;
  dataTestId?: string;
  initialFiles?: Array<FileUpload>;
  showAdd?: boolean;
  showDelete?: boolean;
};

const FileField = ({
  id,
  name,
  title,
  placeholder = 'Não há arquivos',
  showMode,
  dataTestId,
  initialFiles = [],
  caseId,
  onUploadSuccess,
  showAdd = true,
  showDelete = true
}: Props): ReactElement => {
  const initialFilesIds = initialFiles.map(file => file.id);
  const idsRef = useRef<string[]>(initialFilesIds);
  const [filesUpload, setFilesUpload] = useState<Array<File>>([]);
  const [files, setFiles] = useState(initialFiles);
  const [ids, setIds] = useState<string[]>(initialFilesIds);

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    noDrag: showMode,
    onDrop: acceptedFiles => {
      setFilesUpload([...filesUpload, ...(acceptedFiles ?? [])]);
    },
    multiple: true,
    accept: '.png,.jpg,.jpeg,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.pdf,.eml,.msg'
  });

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length === 0) return;

    setFilesUpload([...filesUpload, ...(event.target.files ?? [])]);
  };

  const onSuccess = (file: GenericFile) => {
    if (file.id) {
      const fileUpload = file as FileUpload;
      setIds(ids => [...ids, fileUpload.id]);

      idsRef.current.push(fileUpload.id);
      onUploadSuccess(idsRef.current);
    }
  };

  const deleteFileRequest = async (fileId: string) => {
    if (!window.confirm('Deseja remover esse arquivo?')) return;

    try {
      const newFiles = files.filter(item => item.id !== fileId);

      await deleteFile(caseId, fileId);

      setFiles(newFiles);

      openSuccessToast('Arquivo excluído com sucesso.');
    } catch (error) {
      openErrorToast('Erro ao excluir.');
    }
  };

  const onDelete = async (fileId: string) => {
    const filteredIds = xor(ids, [fileId]);
    setIds(filteredIds);
    idsRef.current = filteredIds;

    if (showMode) {
      deleteFileRequest(fileId);
    }

    onUploadSuccess(idsRef.current);
  };

  useDeepCompareEffect(() => {
    const filesIds = fileUploadsToIds(initialFiles);
    setFilesUpload([]);
    setFiles(initialFiles);
    setIds(filesIds);
    idsRef.current = filesIds;
  }, [initialFiles]);

  const isFilesEmpty = filesUpload.length === 0 && files.length === 0;

  return (
    <div
      {...getRootProps({
        className: styles.container,
        onChange: handleOnChange
      })}
      data-testid="file-field"
    >
      {title && (
        <label
          className={styles.title}
          htmlFor={id}
          data-testid="file-field-label"
        >
          {title}
        </label>
      )}
      <div className={styles.fileList}>
        {isFilesEmpty && <div className={styles.emptyList}>{placeholder}</div>}

        {files.map((file: FileUpload) => {
          return (
            <FileItem
              showMode={showMode}
              key={`file-${file.id}`}
              file={file}
              onDelete={onDelete}
              onUndoDelete={onSuccess}
              showDelete={showDelete}
            />
          );
        })}

        {filesUpload.map((file: File, index: number) => {
          return (
            <FileUploader
              key={`upload-file-${index}`}
              file={file}
              caseId={caseId}
              onSuccess={onSuccess}
              onDelete={onDelete}
            />
          );
        })}
      </div>
      {showAdd && !showMode && (
        <div>
          <input data-testid={dataTestId} {...getInputProps({ name, id })} />

          <label htmlFor={id} data-testid="file-field-trigger">
            <Button highlight outline small centered onClick={open}>
              Adicionar arquivo
            </Button>
          </label>
        </div>
      )}
    </div>
  );
};

export default FileField;
