import React from 'react';
import { pluralize, valueToMillions } from 'utils';
import { useQuery } from 'hooks';
import { fetchPeacCasesStats } from 'api';
import { Toast, ToastType } from 'ui';
import style from './Stats.module.scss';
import { Loading } from './LoadingCases';

const regularPlural = (count: number, singular: string) =>
  pluralize(count, singular, `${singular}s`);

const Stats = () => {
  const { data: casesStats, isLoading, error } = useQuery(
    fetchPeacCasesStats,
    {}
  );

  if (error) {
    return (
      <Toast
        type={ToastType.ERROR}
        message="Ocorreu um erro na obtenção das estatísticas sobre casos."
      />
    );
  }
  if (isLoading || !casesStats) return <Loading />;

  return (
    <>
      <span>{`R$ ${valueToMillions(
        casesStats.totalActiveContractAmount ?? 0
      )} em saldo devedor de ${regularPlural(
        casesStats.totalActiveContracts ?? 0,
        'contrato'
      )}`}</span>
      <span>{`R$ ${valueToMillions(
        casesStats.totalActiveReclaimingAmount ?? 0
      )} recuperados em ${regularPlural(
        casesStats.activeCasesCount ?? 0,
        'caso'
      )} em andamento`}</span>
      <span>{`R$ ${valueToMillions(
        casesStats.totalInactiveReclaimingAmount ?? 0
      )} recuperados em ${pluralize(
        casesStats.inactiveCasesCount ?? 0,
        'caso encerrado',
        'casos encerrados'
      )}`}</span>
      {casesStats.creditOrigin?.map((creditOrigin, index) => (
        <span key={index}>
          <span className={style.bold}>{creditOrigin.name}:</span>
          {` R$ ${valueToMillions(
            creditOrigin.data.totalContractAmount ?? 0
          )} em saldo devedor; R$ ${valueToMillions(
            creditOrigin.data.totalReclaimingAmount ?? 0
          )} recuperados`}
        </span>
      ))}
    </>
  );
};

export default Stats;
