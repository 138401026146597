import React from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';

import style from './NavigationSubItem.module.scss';

type Props = {
  content: string;
  active?: boolean;
  onClick?: () => void;
  pathname?: string;
};

const NavigationSubItem = ({
  active,
  pathname,
  onClick = () => {},
  content
}: Props) => {
  const navigationItemClass = cn(style.navigationItem, {
    [style.active]: active
  });

  const contentClass = cn(style.content, {
    [style.active]: active
  });

  return (
    <NavLink
      onClick={onClick}
      to={{ pathname }}
      className={navigationItemClass}
      data-testid="navigation-item"
    >
      <span className={contentClass}>{content}</span>
    </NavLink>
  );
};

export default NavigationSubItem;
