import { usefulLinkSchema as validationSchema } from 'domain/usefulLinks';
import React from 'react';
import { FormikErrors, getIn, useFormik } from 'formik';

import { OnSubmit, UsefulLink } from 'types';
import { Button, FormContainer, InputField, TextArea } from 'ui';

import style from './Form.module.scss';

type Props = {
  linkData?: UsefulLink;
  onSave: (
    link: UsefulLink,
    setErrors: (errors: FormikErrors<UsefulLink>) => void
  ) => void;
};

const initialValues: UsefulLink = {
  id: '',
  name: '',
  description: '',
  url: ''
};

const Form = ({ linkData, onSave }: Props) => {
  const onSubmit: OnSubmit<UsefulLink> = (linkData, { setErrors }) => {
    onSave(linkData, setErrors);
  };

  const form = useFormik({
    validationSchema,
    initialValues: linkData ?? initialValues,
    onSubmit
  });

  return (
    <form onSubmit={form.handleSubmit}>
      <FormContainer>
        <InputField
          id="link-name"
          name="name"
          type="text"
          title="Nome do site"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.name}
          error={getIn(form.touched, 'name') && getIn(form.errors, 'name')}
        />
        <InputField
          id="link-url"
          name="url"
          type="text"
          placeholder="http(s)://website.com.br"
          title="Endereço do site (URL)"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.url}
          error={getIn(form.touched, 'url') && getIn(form.errors, 'url')}
        />
        <TextArea
          id="link-description"
          name="description"
          title="Descrição (opcional)"
          limit={100}
          showCounter
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.description}
          error={
            getIn(form.touched, 'description') &&
            getIn(form.errors, 'description')
          }
        />
        <div className={style.actions}>
          <Button
            highlight
            dataTestId="party-submit-button"
            type="submit"
            disabled={!form.isValid || !form.dirty}
          >
            {linkData ? 'Salvar' : 'Adicionar'}
          </Button>
        </div>
      </FormContainer>
    </form>
  );
};

export default Form;
