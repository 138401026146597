import { ERROR_MESSAGE, ReportAssetName } from 'domain/reports';
import { buildPaginationParams } from 'domain/pagination';
import React from 'react';

import {
  Accordion,
  Card,
  DropDown,
  openSuccessToast,
  openErrorToast,
  DynamicTable,
  TablePositionModifier,
  Stack,
  StackMargin
} from 'ui';

import {
  addReportRelatedCompanyToCase,
  createReportFromRelatedCompany,
  fetchReportRelatedCompanies,
  fetchReportRelatedCompaniesSummary
} from 'api';
import { applyMask, applyDateMask } from 'utils';
import { openReportTab } from 'pages/reports/utils';
import * as Actions from 'utils/actions';
import compact from 'lodash/compact';

import { ActionEntity, ActionName, ReportRelatedCompany } from 'types';
import { useQuery } from 'hooks';

import { FormerValueTooltip, ProviderTooltip, SummaryError } from 'components';
import SummaryTitle from 'pages/reports/SharedComponents/SummaryTitle';
import ReportDynamicTable from 'pages/reports/SharedComponents/ReportDynamicTable/ReportDynamicTable';

import style from './RelatedCompaniesTable.module.scss';

type Props = {
  caseId: string;
  reportId: string;
  addToCase?: boolean;
  research?: boolean;
  isSummary?: boolean;
  providerStatus?: string;
  tableMessage?: string;
};

const RelatedCompaniesTable = ({
  caseId,
  reportId,
  addToCase = true,
  research = true,
  isSummary,
  providerStatus,
  tableMessage
}: Props) => {
  const query = isSummary
    ? fetchReportRelatedCompaniesSummary
    : fetchReportRelatedCompanies;

  const {
    data: relatedCompanies = [],
    isLoading,
    pagination,
    error,
    refetch
  } = useQuery(query, {
    caseId,
    reportId
  });

  if (error) {
    return (
      <SummaryError
        title="Empresas relacionadas"
        message={ERROR_MESSAGE.relatedCompanies}
      />
    );
  }

  const handleSearch = (
    relatedCompanyId: ReportRelatedCompany['id']
  ) => async () => {
    try {
      const response = await createReportFromRelatedCompany({
        caseId,
        reportId,
        contentId: relatedCompanyId
      });

      openSuccessToast('Consulta salva com sucesso!');
      openReportTab(response.data);
    } catch (err) {
      openErrorToast('Houve um erro ao criar a consulta.');
    }
  };

  const handleAddToCase = (
    relatedCompanyId: ReportRelatedCompany['id']
  ) => async () => {
    try {
      await addReportRelatedCompanyToCase({
        caseId,
        reportId,
        contentId: relatedCompanyId
      });

      openSuccessToast('Empresa adicionada ao caso com sucesso!');
    } catch (err) {
      openErrorToast('Houve um erro ao adicionar a empresa ao caso.');
    }
  };

  const isEmpty = relatedCompanies.length < 1;

  const buildActions = (item: ReportRelatedCompany) => {
    const createReportAction = {
      text: 'Buscar',
      callback: handleSearch(item.id),
      isDisabled: Actions.isDisabled(
        item.field.actions,
        ActionName.CREATE_REPORT
      ),
      description: Actions.unavailableReasons(
        item.field.actions,
        ActionName.CREATE_REPORT,
        ActionEntity.PARTNER
      )
    };

    const addToCaseAction = {
      text: 'Adicionar ao caso',
      callback: handleAddToCase(item.id),
      isDisabled: Actions.isDisabled(
        item.field.actions,
        ActionName.ADD_TO_CASE
      ),
      description: Actions.unavailableReasons(
        item.field.actions,
        ActionName.ADD_TO_CASE,
        ActionEntity.PARTNER
      )
    };

    const allActionsAllowed = compact([
      addToCase && addToCaseAction,
      research && createReportAction
    ]);

    return allActionsAllowed;
  };

  const columns = [
    {
      name: 'CNPJ',
      columnKey: 'cnpj',
      render: (item: ReportRelatedCompany) => (
        <FormerValueTooltip
          fieldName="cnpj"
          fieldValue={item.cnpj}
          history={item.field.history}
          formatter={value => applyMask('cnpj', value)}
        />
      )
    },
    {
      name: 'Razão social',
      columnKey: 'businessName',
      render: (item: ReportRelatedCompany) => (
        <FormerValueTooltip
          fieldName="businessName"
          fieldValue={item.businessName}
          history={item.field.history}
        />
      )
    },
    {
      name: 'Data de abertura',
      columnKey: 'openingDate',
      render: (item: ReportRelatedCompany) => (
        <FormerValueTooltip
          fieldName="openingDate"
          fieldValue={item.openingDate}
          history={item.field.history}
          formatter={value => applyDateMask(value)}
        />
      )
    }
  ];

  const actionColumn = [
    {
      positionModifier: TablePositionModifier.RIGHT,
      hasActions: true,
      name: '',
      columnKey: 'actions',
      render: (item: ReportRelatedCompany) => (
        <>
          <ProviderTooltip
            providers={item.field.providers}
            searchedByHomonym={item.field.searchedByHomonym}
            dataTestId="provider-tooltip"
          />
          {buildActions(item).length > 0 && (
            <DropDown options={buildActions(item)} />
          )}
        </>
      )
    }
  ];

  const rowKey = (item: ReportRelatedCompany) => item.id;

  const refetchWithPagination = (pagination?: {
    page: number;
    pageSize: number;
  }) => {
    refetch({
      caseId,
      reportId,
      pagination
    });
  };

  const paginationProps = buildPaginationParams(
    pagination,
    refetchWithPagination
  );

  if (isSummary) {
    return (
      <>
        <Stack marginBottom={StackMargin.SMALL}>
          <SummaryTitle>{`Empresas relacionadas (${relatedCompanies.length})`}</SummaryTitle>
        </Stack>
        <DynamicTable
          columns={columns}
          data={relatedCompanies}
          rowKey={rowKey}
          getRowClassName={() => style.summaryTableRow}
          noAutoPaginate
          isLoading={isLoading}
        />
      </>
    );
  }

  return (
    <Accordion
      title="Empresas relacionadas"
      badgeContent={pagination?.totalEntries}
      tooltipText={providerStatus}
      isClosed={isEmpty && !tableMessage}
    >
      <Card small>
        <ReportDynamicTable
          columns={[...columns, ...actionColumn]}
          data={relatedCompanies}
          rowKey={rowKey}
          isLoading={isLoading}
          pagination={paginationProps}
          emptyText={tableMessage}
          reportAssetName={ReportAssetName.RELATED_COMPANY}
        />
      </Card>
    </Accordion>
  );
};

export default RelatedCompaniesTable;
