import React from 'react';
import { Button, Link, Modal, Pagination, Stack, StackMargin } from 'ui';

import { usePagination } from 'hooks/usePagination';

import style from './ModalPaginationItems.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  listItems: string[];
  title: string;
  isLink?: boolean;
};

const ModalPaginationItems = ({
  isOpen,
  onClose,
  title,
  listItems,
  isLink = true
}: Props) => {
  const { data, paginationProps } = usePagination({
    dataToPaginate: listItems
  });

  return (
    <Modal
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      className={style.paginationItemsModal}
    >
      <ul className={style.paginationItemsList}>
        {data.map((url: string, index: number) => (
          <li
            data-testid="pagination-items-list-item"
            key={`pagination-items-list-item-${index}`}
          >
            <Stack marginBottom={StackMargin.MEDIUM}>
              <Link
                highlight
                data-testid="item-pagination-items-link"
                href={url}
                external
                underline={isLink}
                disabled={!isLink}
              >
                {url}
              </Link>
            </Stack>
          </li>
        ))}
      </ul>

      <Stack marginTop={StackMargin.XLARGE}>
        <Pagination {...paginationProps} />

        <Stack marginTop={StackMargin.XLARGE}>
          <Button onClick={onClose} highlight centered>
            Voltar
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default ModalPaginationItems;
