export enum TaxIndexer {
  PREFIXADO = 'prefixado',
  CDI = 'cdi',
  CDI_MAIS = 'cdi_mais',
  IGPM = 'igpm',
  IGPM_MAIS = 'igpm_mais',
  IPCA = 'ipca',
  IPCA_MAIS = 'ipca_mais',
  LIBOR = 'libor',
  LIBOR_MAIS = 'libor_mais',
  SELIC = 'selic',
  SELIC_MAIS = 'selic_mais',
  TJLP = 'tjlp',
  TJLP_MAIS = 'tjlp_mais',
  TR = 'tr',
  TR_MAIS = 'tr_mais',
  OUTRO = 'outro'
}
