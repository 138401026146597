import React, { ReactElement, useState } from 'react';
import { DataImport } from 'types';
import { Icon, Link, Modal } from 'ui';
import Errors from './Errors';

import style from './Summary.module.scss';

type Props = {
  dataImport: DataImport;
  handleDismissLastDataImport: (id: string) => void;
};

const Summary = ({
  dataImport,
  handleDismissLastDataImport
}: Props): ReactElement => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const failedImports = dataImport.failedCaseImportsCount;
  const succeededImports = dataImport.processedCaseImportsCount - failedImports;

  return (
    <div className={style.summary} data-testid="data-import-summary">
      <span className={style.success}>
        {`${succeededImports} casos importados com sucesso`}
      </span>
      {failedImports > 0 && (
        <span className={style.failure}>
          {`, erro ao importar ${failedImports} casos (`}
          <Link
            underline
            className={style.openErrors}
            onClick={() => setModalOpen(true)}
            dataTestId="data-import-open-errors-modal"
          >
            ver erros
          </Link>
          )
        </span>
      )}
      <>
        <Link
          className={style.clearMessage}
          onClick={() => handleDismissLastDataImport(dataImport.id)}
          dataTestId="data-import-clear-message"
        >
          <Icon name="close" />
        </Link>

        <Modal
          medium
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          dataTestId="data-import-errors-modal"
          title="Erros na importação"
          subtitle={`Foram detectados ${dataImport.errors?.length} errors na importação de ${dataImport.caseImportsCount} casos`}
        >
          <Errors errors={dataImport?.errors} />
        </Modal>
      </>
    </div>
  );
};

export default Summary;
