import { reportStatus } from 'domain/reports';
import { handleCopy, NOSELECT } from 'domain/copyTableRow';
import React, { useContext } from 'react';

import { applyMask, handleNullValue, reportFieldFirstValue } from 'utils';
import { DropDown, TablePositionModifier, DynamicTable } from 'ui';
import { DropDownOption } from 'ui/DropDown/DropDown';
import { CaseContext, ReportStatusWithModal } from 'components';

import {
  Report,
  EntityType,
  PaginationProps,
  Status as ReportStatusEnum,
  PaginatedResource
} from 'types';
import { Action, can } from 'api';

type Props = {
  reports: Report[];
  onDelete: (reportId: string) => void;
  onCreateEntity: (reportId: string) => void;
  handleRetryReport: (reportId: string) => void;
  handleGenerateErrorReport: (reportId: string) => void;
  pagination: PaginationProps;
};

const entityName = (report: Report): string => {
  if (report.type === EntityType.COMPANY)
    return handleNullValue(reportFieldFirstValue(report.company?.businessName));

  return handleNullValue(reportFieldFirstValue(report.individual?.name));
};

const ReportTable = ({
  reports,
  onDelete,
  onCreateEntity,
  handleRetryReport,
  handleGenerateErrorReport,
  pagination
}: Props) => {
  const { caseData } = useContext(CaseContext);
  const negotiatorId = caseData?.negotiator.id;
  const canUser = {
    createReport: can(
      Action.CASOS_RASTREAMENTO_SUSPEITOS_GERAR_RELATORIO,
      negotiatorId
    ),
    deleteReport: can(
      Action.CASOS_RASTREAMENTO_CONSULTAS_EXCLUIR,
      negotiatorId
    ),
    showReport: can(
      Action.CASOS_RASTREAMENTO_CONSULTAS_VER_RELATORIO,
      negotiatorId
    ),
    showPartialReport: can(
      Action.CASOS_RASTREAMENTO_CONSULTAS_VER_RELATORIO_PARCIAL,
      negotiatorId
    ),
    addToSupects: can(
      Action.CASOS_RASTREAMENTO_CONSULTAS_ADICIONAR_SUSPEITOS,
      negotiatorId
    ),
    copyLine: can(
      Action.CASOS_RASTREAMENTO_CONSULTAS_COPIAR_LINHA,
      negotiatorId
    ),
    seePartial: can(
      Action.CASOS_RELATORIO_PFPJ_VER_RELATORIO_PARCIAL,
      negotiatorId
    ),
    retry: can(Action.CASOS_RELATORIO_PFPJ_TENTAR_NOVAMENTE, negotiatorId)
  };

  const columns = [
    {
      columnKey: 'entityDocument',
      name: 'CPF ou CNPJ',
      hasOrderBy: true,
      render: (report: Report) =>
        report.type === EntityType.COMPANY
          ? applyMask('cnpj', report.searchTerm)
          : applyMask('cpf', report.searchTerm)
    },
    {
      columnKey: 'entityName',
      name: 'Nome ou razão social',
      hasOrderBy: true,
      render: (report: Report) => entityName(report)
    },
    {
      columnKey: 'status',
      name: 'Status do relatório',
      className: () => NOSELECT,
      render: (report: Report) => {
        const status = reportStatus(report);
        const isError = status === ReportStatusEnum.ERROR;

        return (
          <ReportStatusWithModal
            report={report}
            handleTryAgain={() => handleRetryReport(report.id)}
            handleGenerateErrorReport={() =>
              handleGenerateErrorReport(report.id)
            }
            disabled={!canUser.showPartialReport}
            showLink={canUser.seePartial}
            showAction={isError ? canUser.createReport : canUser.retry}
          />
        );
      }
    },
    {
      columnKey: 'dropdown',
      positionModifier: TablePositionModifier.RIGHT,
      name: '',
      className: () => NOSELECT,
      render: (report: Report) => {
        const status = reportStatus(report);

        const isError = status === ReportStatusEnum.ERROR;

        const createdReportOptions = [
          {
            text: 'Ver relatório',
            isLink: true,
            link: `/cases/${report.caseId}/reports/${report.id}`,
            isHidden: !canUser.showReport
          },
          {
            text: 'Adicionar a suspeitos',
            callback: () => onCreateEntity(report.id),
            dataTestId: `add-to-suspects-${report.id}`,
            isHidden: !canUser.addToSupects
          },
          {
            text: 'Copiar linha',
            callback: () => handleCopy(report.id, 'report'),
            dataTestId: `copy-${report.id}`,
            isHidden: !canUser.copyLine
          }
        ];

        const errorReportOptions = {
          text: 'Gerar relatório',
          callback: () => handleGenerateErrorReport(report.id),
          dataTestId: `generate-report-${report.id}`,
          isHidden: !canUser.createReport
        };

        const options: DropDownOption[] = [
          isError ? errorReportOptions : createdReportOptions,
          {
            text: 'Excluir',
            callback: () => onDelete(report.id),
            dataTestId: `delete-report-${report.id}`,
            isNegative: true,
            isHidden: !canUser.deleteReport
          }
        ].flat();

        if (options.every(option => option.isHidden)) return <></>;

        return (
          <DropDown
            dataTestId={`dropdown-report-${report.id}`}
            options={options}
          />
        );
      }
    }
  ];

  const rowKey = (report: Report) => report.id;

  return (
    <DynamicTable
      data={reports}
      columns={columns}
      rowKey={rowKey}
      pagination={pagination}
      noAutoPaginate
      tableName="report"
      paginatedResource={PaginatedResource.PEOPLE_REPORTS}
    />
  );
};

export default ReportTable;
