import { assetValueCents } from 'domain/assets';
import sumBy from 'lodash/sumBy';
import { Option, GuaranteeObject, GuaranteeModality, Guarantee } from 'types';

const objectDictionary: { [key in GuaranteeObject]: string } = {
  [GuaranteeObject.ACOES_QUOTAS]: 'Ações / Quotas',
  [GuaranteeObject.AERONAVES_EMBARCACOES]: 'Aeronaves / Embarcações',
  [GuaranteeObject.APLICACOES_FINANCEIRAS]: 'Aplicações Financeiras',
  [GuaranteeObject.ATIVO_BIOLOGICO]: 'Ativo Biológico',
  [GuaranteeObject.DUPLICATAS]: 'Duplicatas',
  [GuaranteeObject.EDIFICIO]: 'Edifício',
  [GuaranteeObject.ESTABELECIMENTO_COMERCIAL]: 'Estabelecimento Comercial',
  [GuaranteeObject.FAZENDA_SITIO_CHACARA]: 'Fazenda / Sítio / Chácara',
  [GuaranteeObject.INDUSTRIA]: 'Indústria',
  [GuaranteeObject.MAQUINAS_E_EQUIPAMENTOS]: 'Máquinas e Equipamentos',
  [GuaranteeObject.MARCAS]: 'Marcas',
  [GuaranteeObject.MERCADORIAS]: 'Mercadorias',
  [GuaranteeObject.LOTE_RURAL]: 'Lote Rural',
  [GuaranteeObject.LOTE_URBANO]: 'Lote Urbano',
  [GuaranteeObject.PESSOA_FISICA]: 'Pessoa Física',
  [GuaranteeObject.PESSOA_JURIDICA]: 'Pessoa Jurídica',
  [GuaranteeObject.QUOTAS_DE_FIP_FIDCS]: 'Quotas de FIP / FIDCs',
  [GuaranteeObject.RECEBIVEIS_A_PERFORMAR]: 'Recebíveis a Performar',
  [GuaranteeObject.RECEBIVEIS_PERFORMADOS]: 'Recebíveis Performados',
  [GuaranteeObject.RESIDENCIAL_APTO]: 'Residencial Apartamento',
  [GuaranteeObject.RESIDENCIAL_CASA]: 'Residencial Casa',
  [GuaranteeObject.SALAS_COMERCIAIS]: 'Salas Comerciais',
  [GuaranteeObject.FGI]: 'FGI',
  [GuaranteeObject.VEICULOS]: 'Veículos'
};

const modalityDictionary: {
  [key in GuaranteeModality]: string;
} = {
  [GuaranteeModality.CLEAN]: 'Clean',
  [GuaranteeModality.ALIENACAO_FIDUCIARIA]: 'Alienação Fiduciária',
  [GuaranteeModality.ALIENACAO_FIDUCIARIA_SOBEJO]:
    'Alienação Fiduciária (Sobejo)',
  [GuaranteeModality.AVAL]: 'Aval',
  [GuaranteeModality.CAUCAO]: 'Caução',
  [GuaranteeModality.CESSAO_FIDUCIARIA]: 'Cessão Fiduciária',
  [GuaranteeModality.CESSAO_FIDUCIARIA_SOBEJO]: 'Cessão Fiduciária (Sobejo)',
  [GuaranteeModality.COMFORT_LETTER]: 'Comfort Letter',
  [GuaranteeModality.CPR]: 'CPR',
  [GuaranteeModality.FIANCA]: 'Fiança ',
  [GuaranteeModality.FIANCA_BANCARIA]: 'Fiança Bancária',
  [GuaranteeModality.GUARANTEE_LETTER]: 'Guarantee Letter',
  [GuaranteeModality.HIPOTECA_1_GRAU]: 'Hipoteca 1º grau',
  [GuaranteeModality.HIPOTECA_2_GRAU_OU_MAIOR]: 'Hipoteca 2º grau ou maior',
  [GuaranteeModality.PENHOR_1_GRAU]: 'Penhor 1º grau',
  [GuaranteeModality.PENHOR_2_GRAU_OU_MAIOR]: 'Penhor 2º grau ou maior',
  [GuaranteeModality.SEGURO]: 'Seguro',
  [GuaranteeModality.SBLC]: 'SBLC',
  [GuaranteeModality.WARRANTS_CDAWA]: 'Warrants / CDAWA'
};

export function objectLabel(object: GuaranteeObject): string {
  return objectDictionary[object] || object;
}

export function objectOptions(): Option[] {
  return Object.values(GuaranteeObject).map(object => ({
    value: object,
    label: objectLabel(object)
  }));
}

export function guaranteeTotalValue(guarantee: Guarantee): number {
  return sumBy(guarantee.assets, asset => Number(assetValueCents(asset)) ?? 0);
}

export function modalityLabel(modality: GuaranteeModality): string {
  return modalityDictionary[modality] || modality;
}

export function modalityOptions(): Option[] {
  return Object.values(GuaranteeModality).map(modality => ({
    value: modality,
    label: modalityLabel(modality)
  }));
}

export default objectLabel;
