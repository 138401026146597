import { handleNullValue, createArrayFrom, regularPlural } from 'utils';
import { Option, Execution, ExecutionType, ExecutionEventType } from 'types';

export const EXECUTION_EVENTS_DICTIONARY: {
  [key in ExecutionEventType]: string;
} = {
  [ExecutionEventType.INDICADO_PARA_PENHORA]: 'Indicado para penhora',
  [ExecutionEventType.TERMO_DE_PENHORA]: 'Termo de penhora',
  [ExecutionEventType.AVALIACAO]: 'Avaliação',
  [ExecutionEventType.IMPUGNACAO]: 'Impugnação',
  [ExecutionEventType.POSSIBILIDADE_DE_RECURSO]: 'Possibilidade de Recurso',
  [ExecutionEventType.PRIMEIRO_LEILAO]: '1º leilão',
  [ExecutionEventType.SEGUNDO_LEILAO]: '2º leilão',
  [ExecutionEventType.EXECUCAO]: 'Execução',
  [ExecutionEventType.DECISAO_JUDICIAL_DESFAVORAVEL]:
    'Decisão judicial desfavorável',
  [ExecutionEventType.ARREMATACAO_OU_ADJUDICACAO]: 'Arrematação ou Adjudicação'
};

export function eventTypeLabel(event: ExecutionEventType) {
  return EXECUTION_EVENTS_DICTIONARY[event] || event;
}

export function eventTypeOptions(): Option[] {
  return Object.values(ExecutionEventType).map(event => ({
    value: event,
    label: EXECUTION_EVENTS_DICTIONARY[event]
  }));
}

const EXECUTION_TYPE_DICTIONARY: {
  [key in ExecutionType]: string;
} = {
  [ExecutionType.ASSET]: 'Ativo',
  [ExecutionType.GUARANTEE]: 'Garantia',
  [ExecutionType.EXTERNAL_LAWSUIT]: 'Processo'
};

export function executionDegreeOptions(): Option[] {
  return createArrayFrom({
    size: 10,
    fillFunction: index => {
      const degree = index + 1;

      return {
        value: degree.toString(),
        label: `${degree}º grau`
      };
    }
  });
}

export function getExecutionTitle(execution: Execution): string {
  const {
    type,
    executedAsset,
    executedContract,
    executedGuarantee
  } = execution;
  if (type === ExecutionType.ASSET) return executedAsset!.name;

  if (type === ExecutionType.GUARANTEE) {
    return `Garantia ${
      executedGuarantee!.instrumentNumber
    } do Contrato ${handleNullValue(executedContract!.number, 'string')}`;
  }

  return `Processo nº ${execution.executedExternalLawsuit?.number}`;
}

export function getExecutionCounter(execution: Execution): string | undefined {
  const { executedAsset } = execution;
  if (executedAsset?.isGroup) {
    const counter = executedAsset!.groupedAssets.length;
    return counter === 0
      ? '(sem ativos)'
      : `(${regularPlural(counter, 'ativo')})`;
  }
}

export function getExecutionLink(
  execution: Execution
): { pathname: string; hash: string } {
  const {
    executedContract,
    executedGuarantee,
    executedAsset,
    executedExternalLawsuit,
    type
  } = execution;

  if (type === ExecutionType.ASSET) {
    return {
      pathname: `/cases/${executedAsset!.caseId}/assets/${executedAsset!.id}`,
      hash: ''
    };
  }

  if (type === ExecutionType.GUARANTEE) {
    return {
      pathname: `/cases/${executedContract!.caseId}/debtors/contract/${
        executedContract!.id
      }`,
      hash: `guarantee-form-${executedGuarantee!.id}`
    };
  }

  return {
    pathname: `/cases/${executedExternalLawsuit!.caseId}/external_lawsuits/${
      executedExternalLawsuit!.id
    }`,
    hash: ''
  };
}

export function executionTypeOptions(): Option[] {
  return Object.values(ExecutionType).map(type => ({
    value: type,
    label: executionTypeLabel(type)
  }));
}

export function executionTypeLabel(type: ExecutionType): string {
  return EXECUTION_TYPE_DICTIONARY[type];
}

export function executionEventLabel(type: ExecutionEventType): string {
  return EXECUTION_EVENTS_DICTIONARY[type];
}
