import React from 'react';
import { FormikProps, getIn } from 'formik';
import { FormContainer, SelectField } from 'ui';
import { ExecutionRelatedAttributes, ExternalLawsuit, Option } from 'types';

import { ExternalLawsuitInfo } from 'components';

type Props = {
  formik: FormikProps<ExecutionRelatedAttributes>;
  externalLawsuits: ExternalLawsuit[];
  isLoading: boolean;
};

function executedExternalLawsuitOptions(
  externalLawsuits: ExternalLawsuit[]
): Option[] {
  return externalLawsuits.map(externalLawsuit => ({
    value: externalLawsuit.id,
    label: `Processo nº ${externalLawsuit.number}`
  }));
}

const ExecutedExternalLawsuitField = ({
  formik,
  externalLawsuits,
  isLoading
}: Props) => {
  const externalLawsuit = externalLawsuits.find(
    externalLawsuit =>
      externalLawsuit.id === formik.values.executedExternalLawsuitId
  );

  return (
    <>
      <FormContainer>
        <SelectField
          id="executedExternalLawsuitId"
          name="executedExternalLawsuitId"
          title="Processo"
          value={formik.values.executedExternalLawsuitId?.toString()}
          error={
            getIn(formik.touched, 'executedExternalLawsuitId') &&
            getIn(formik.errors, 'executedExternalLawsuitId')
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          options={executedExternalLawsuitOptions(externalLawsuits)}
          isLoading={isLoading}
          optionalLabel="Selecione..."
          optional
        />
      </FormContainer>

      {externalLawsuit && (
        <ExternalLawsuitInfo
          externalLawsuit={externalLawsuit}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default ExecutedExternalLawsuitField;
