import React, { useState } from 'react';
import cn from 'classnames';
import { DateTime } from 'luxon';
import { Notification } from 'types';
import { Link, Icon, openErrorToast } from 'ui';
import { toggleReadNotification } from 'api/notifications';

import style from './Item.module.scss';
import { buildItem } from './buildItem';

type Props = {
  notification: Notification;
  showCasePrefix?: boolean;
  closeNotificaton: () => void;
};

const Item = ({
  notification,
  showCasePrefix = false,
  closeNotificaton
}: Props) => {
  const [read, setRead] = useState(notification.read);
  const [submiting, setSubmiting] = useState(false);

  const markAsRead = async () => {
    closeNotificaton();

    if (read) return;

    try {
      await toggleReadNotification({ id: notification.id, read: true });
    } catch {}
  };

  const toggleMarkAsRead = async () => {
    setSubmiting(true);

    try {
      await toggleReadNotification({ id: notification.id, read: !read });
      setRead(!read);
    } catch {
      openErrorToast(
        `Não foi possível marcar notificação como ${read ? 'não lida' : 'lida'}`
      );
    }

    setSubmiting(false);
  };

  const item = buildItem(notification);

  return (
    <div className={style.wrapper}>
      <Link
        onClick={markAsRead}
        dataTestId="notification-item"
        highlight={!read}
        className={cn([style.link, read && style.linkRead])}
        to={item.linkTo}
      >
        <div className={style.title}>
          {showCasePrefix && (
            <span className={style.tag}>
              {`caso ${notification.event.case.name} - `}
            </span>
          )}
          {item.message}
        </div>
        <div className={cn([style.timestamp, read && style.timestampRead])}>
          {DateTime.fromISO(notification.event.insertedAt)
            .setLocale('pt-BR')
            .toRelative()}
        </div>
      </Link>

      <button
        disabled={submiting}
        aria-label={read ? 'marcar como não lido' : 'marcar como lido'}
        onClick={toggleMarkAsRead}
        className={cn([style.button, read && style.buttonRead])}
      >
        {read && <Icon name="checked" small fill="#fff" />}
      </button>
    </div>
  );
};

export default Item;
