import { entityName, entityRoleDescription } from 'domain/entity';

import React from 'react';

import { fetchEntityRelationships } from 'api';
import { EntityWithRelations } from 'types';
import { useQuery } from 'hooks';

import { Badge } from 'ui';

import style from './HeaderInfo.module.scss';

type Props = {
  caseId: string;
  entityId: string;
};

const HeaderInfo = ({ caseId, entityId }: Props) => {
  const { data: entity } = useQuery<
    EntityWithRelations,
    { caseId: string; entityId: string }
  >(fetchEntityRelationships, { caseId, entityId });

  if (!entity) return null;

  return (
    <>
      <div className={style.label}>Origem do suspeito</div>
      <div className={style.relationship}>
        <Badge rounded className={style.badge}>
          {entityName(entity)}
        </Badge>
        {entity.relationships.map((relationship, index) => (
          <React.Fragment key={index}>
            <span>{entityRoleDescription(relationship.role)}</span>
            <Badge
              rounded
              className={style.badge}
              dataTestId={`badge-${relationship.id}`}
            >
              {relationship.name}
            </Badge>
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default HeaderInfo;
