import { EntityFormAttributes, CaseEntity } from './entity';
import { CreditClass, CreditClassFormAttributes } from './creditClass';
import { Actions } from './action';
import { CreditContractByModality } from './creditContract';
import { City } from './city';
import { InvoiceByCovenant } from './invoice';

export enum DebtorJudicialSituation {
  NO_JUDICIAL_PROCESS = 'no_judicial_process',
  BANKRUPTCY = 'bankruptcy',
  JUDICIAL_RECOVERY = 'judicial_recovery',
  EXTRAJUDICIAL_RECOVERY = 'extrajudicial_recovery',
  JUDICIAL_EXECUTION = 'judicial_execution'
}

export enum DebtorSector {
  WHOLESALE_TRADE = 'wholesale_trade',
  RETAIL_TRADE = 'retail_trade',
  CONSTRUCTION = 'construction',
  DISTRIBUTION = 'distribution',
  SHIPYARD = 'shipyard',
  POWER_GENERATION = 'power_generation',
  GRAINS = 'grains',
  ESTATE_DEVELOPMENT = 'estate_development',
  INDUSTRY = 'industry',
  LOGISTICS = 'logistics',
  MINING = 'mining',
  OIL_AND_GAS = 'oil_and_gas',
  SERVICES = 'services',
  SUGAR_ALCOHOL = 'sugar_alcohol',
  TRADING = 'trading',
  POWER_TRANSMISSION = 'power_transmission',
  TRANSPORT = 'transport',
  OTHERS = 'others'
}

export enum DebtorJudicialRecoveryVote {
  ABSTENTION = 'abstention',
  NO = 'no',
  YES = 'yes',
  DOES_NOT_VOTE = 'does_not_vote'
}

export type DebtorFormAttributes = {
  entity: EntityFormAttributes;
  judicialSituation: DebtorJudicialSituation | '';
  sector: DebtorSector | '';
  state: string;
  cityId: string;
  judicialRecoveryState: string;
  orderDate: string | null;
  deferralDate: string | null;
  court: string | null;
  judge: string | null;
  judicialAssistant: string | null;
  debtorLawyer: string | null;
  prjApprovalDate: string | null;
  homologationDate: string | null;
  vote: DebtorJudicialRecoveryVote | null | '';
  creditClasses: Array<CreditClassFormAttributes>;
  exitDate?: string;
  comments?: string;
  isAbroad: boolean;
};

export type Debtor = {
  id: string;
  entity: CaseEntity;
  judicialSituation: DebtorJudicialSituation;
  sector: DebtorSector;
  state: string;
  city: City | null;
  changedAt: string;
  orderDate: string | null;
  deferralDate: string | null;
  court: string | null;
  judge: string | null;
  judicialAssistant: string | null;
  debtorLawyer: string | null;
  prjApprovalDate: string | null;
  homologationDate: string | null;
  vote: DebtorJudicialRecoveryVote | null;
  creditClasses: Array<CreditClass>;
  exitDate?: string;
  comments?: string;
  isAbroad: boolean;
  actions: Actions;
};

export type DebtorAmountAggregate = {
  totalAccountingBalance: number;
  totalContractAmount: number;
  totalDebtAmount: number;
  totalGuaranteeAmount: number;
  totalReclaimingAmount: number;
  weightedPddAverage: number;
};

export type DebtorGroupedCreditContract = DebtorAmountAggregate &
  CreditContractByModality;

export type DebtorGroupedInvoice = DebtorAmountAggregate & InvoiceByCovenant;

export type DebtorGroupedWithContractAndInvoice = DebtorAmountAggregate & {
  debtor: Debtor;
  contractsByModality: DebtorGroupedCreditContract[];
  invoicesByCovenant: DebtorGroupedInvoice[];
};

export type DebtorParams = Partial<DebtorFormAttributes>;
