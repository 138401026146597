import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import {
  motion,
  useAnimation,
  AnimationControls,
  AnimateSharedLayout
} from 'framer-motion';

import { ToolTip } from 'ui';
import style from './AccordionTable.module.scss';

import { Header, Row, Alignment } from './AccordionTable';

import FirstCell from './FirstCell';

type Props = {
  row: Row;
  headers: Header[];
  indentation?: number;
  depth: number;
  controls?: AnimationControls;
  parentExpanded?: boolean;
};

const TableRow = ({
  row,
  headers,
  indentation = 0,
  depth,
  controls: parentControls,
  parentExpanded = true
}: Props) => {
  const [isExpanded, setExpanded] = useState<boolean>(false);
  const controls = useAnimation();
  const { onClick, tooltipMessage, childs = [], cells: columns } = row;

  function handleExpanded(event: React.MouseEvent) {
    event.stopPropagation();
    setExpanded(!isExpanded);
  }

  const expanded = isExpanded && parentExpanded;

  const height = (() => {
    if (!indentation) return '56px';
    if (!onClick) return '40px';
    return '32px';
  })();

  const colSpan = depth - indentation;

  const motionRow = (
    <motion.tr
      data-testid={tooltipMessage && 'accordion-motion-row'}
      layout
      className={style.row}
      initial="collapsed"
      animate={parentControls ?? 'open'}
      exit="collapsed"
      variants={{
        open: { opacity: 1, height, lineHeight: 1 },
        collapsed: { opacity: 0, height: 0, lineHeight: 0 }
      }}
      transition={{ duration: 0.1, ease: [0.04, 0.62, 0.23, 0.98] }}
      onClick={onClick}
    >
      {Array.from({ length: indentation }, (_, index) => (
        <motion.td
          layout
          key={`ident-${index}`}
          className={style.indentCol}
        ></motion.td>
      ))}
      {columns.map((column, index) => (
        <motion.td
          layout
          colSpan={index === 0 ? colSpan : 0}
          className={cn(
            style.cell,
            style[headers[index].align ?? Alignment.LEFT],
            {
              [style.visibleCell]: parentExpanded,
              [style.clickableCell]: Boolean(onClick)
            }
          )}
          key={index}
        >
          <FirstCell
            column={column}
            isFirstColumn={index === 0}
            hasChild={!!childs.length}
            expanded={isExpanded}
            parentExpanded={parentExpanded}
            handleExpanded={handleExpanded}
          />
        </motion.td>
      ))}
    </motion.tr>
  );

  useEffect(() => {
    controls.start(expanded ? 'open' : 'collapsed');
  }, [expanded, controls]);

  return (
    <AnimateSharedLayout>
      {tooltipMessage ? (
        <ToolTip content={tooltipMessage} placement="top-start">
          {motionRow}
        </ToolTip>
      ) : (
        motionRow
      )}
      {childs.map((rowChild, index) => (
        <TableRow
          key={index}
          row={rowChild}
          headers={headers}
          indentation={indentation + 1}
          depth={depth}
          controls={controls}
          parentExpanded={expanded}
        />
      ))}
    </AnimateSharedLayout>
  );
};

export default TableRow;
