import { assetValueCents } from 'domain/assets';
import React, { useState, useContext } from 'react';
import sum from 'lodash/sum';
import compact from 'lodash/compact';
import { useLocation } from 'react-router';

import { Action, can, deleteReclaimingAsset, removeAssetFromGroup } from 'api';
import { AssetForm, AssetTable, CaseContext } from 'components';
import { useToggles } from 'hooks';
import { Asset, AssetOptions } from 'types';
import {
  Button,
  Modal,
  openErrorToast,
  openSuccessToast,
  Stack,
  StackMargin
} from 'ui';
import { applyMask } from 'utils';

import RelateAssetModal from './RelateAssetModal/RelateAssetModal';

import style from './AssetList.module.scss';

type Props = {
  reclaimingId?: string;
  reclaimingAssets: Asset[];
  caseId: string;
};

const AssetList = ({ caseId, reclaimingId, reclaimingAssets }: Props) => {
  const [assets, setAssets] = useState(reclaimingAssets);

  const { pathname } = useLocation();
  const { caseData } = useContext(CaseContext);

  const { isOpen, toggle, closeAll } = useToggles<'createAsset'>({
    createAsset: false
  });

  const handleCreate = (asset: Asset) => {
    setAssets([...assets, asset]);
    closeAll();
  };

  const handleAdd = (newAssets: Asset[]) => {
    setAssets([...assets, ...newAssets]);
  };

  const handleDelete = async (asset: Asset) => {
    if (!window.confirm('Você tem certeza de que deseja remover esse ativo?'))
      return;

    try {
      reclaimingId &&
        (await deleteReclaimingAsset(caseId, reclaimingId, asset.id));

      setAssets(assets.filter(a => a.id !== asset.id));

      openSuccessToast('Ativo removido com sucesso!');
    } catch (error) {
      openErrorToast('Houve um erro ao remover o ativo');
    }
  };

  const handleRemoveFromGroup = async (assetToDelete: Asset) => {
    if (
      !window.confirm(
        'Você tem certeza de que deseja remover esse ativo do grupo?'
      )
    )
      return;

    try {
      await removeAssetFromGroup(caseId, assetToDelete.id);

      const updatedAsset = assets.map(asset => {
        if (asset.id === assetToDelete.assetGroupId) {
          return {
            ...asset,
            groupedAssets: asset.groupedAssets.filter(
              grouped => grouped.id !== assetToDelete.id
            )
          };
        }

        return asset;
      });

      setAssets(updatedAsset);

      openSuccessToast('Ativo removido do grupo com sucesso!');
    } catch (error) {
      openErrorToast('Houve um erro ao remover o ativo do grupo');
    }
  };

  const handleDeleteAsset = (asset: Asset) => () => {
    asset.assetGroupId ? handleRemoveFromGroup(asset) : handleDelete(asset);
  };

  const total = sum(assets.map(asset => assetValueCents(asset) ?? 0));

  const negotiatorId = caseData?.negotiator?.id;

  const canUser = {
    addAsset: can(Action.CASOS_ATIVOS_DA_RECUPERACAO_INCLUIR, negotiatorId),
    removeAsset: can(Action.CASOS_ATIVOS_DA_RECUPERACAO_EXCLUIR, negotiatorId),
    showOrigin: can(
      Action.CASOS_ATIVOS_DA_RECUPERACAO_VER_ORIGEM_SUSPEITO,
      negotiatorId
    ),
    copyLine: can(
      Action.CASOS_ATIVOS_DA_RECUPERACAO_COPIAR_LINHA,
      negotiatorId
    ),
    showReport: can(
      Action.CASOS_ATIVOS_DA_RECUPERACAO_VER_RELATORIO,
      negotiatorId
    )
  };

  const assetOptions: AssetOptions[] = compact([
    canUser.showReport && AssetOptions.SHOW_REPORT,
    canUser.showReport && AssetOptions.SHOW_GROUP,
    canUser.showOrigin && AssetOptions.SHOW_ORIGIN,
    canUser.removeAsset && AssetOptions.DELETE,
    canUser.removeAsset && AssetOptions.DELETE_FROM_GROUP,
    canUser.removeAsset && AssetOptions.DELETE_GROUP,
    canUser.copyLine && AssetOptions.COPY_LINE
  ]);

  return (
    <div>
      <h4 className={style.title}>
        Ativos
        <span className={style.totalSum}>{`Total de ativos: ${applyMask(
          'currency',
          total.toString()
        )}`}</span>
      </h4>

      <AssetTable
        caseId={caseId}
        assets={assets}
        isLoading={false}
        onDeleteAsset={handleDeleteAsset}
        emptyText="Não há ativos nesta recuperação."
        returnToLink={pathname}
        assetOptions={assetOptions}
      />

      <Stack marginTop={StackMargin.MEDIUM} className={style.buttonsWrapper}>
        {canUser.addAsset && (
          <Button
            dataTestId="asset-modal-trigger"
            highlight
            outline
            small
            onClick={toggle('createAsset')}
          >
            {!reclaimingId
              ? 'Salve a recuperação para poder adicionar ativos'
              : 'Adicionar novo ativo'}
          </Button>
        )}

        {reclaimingId && (
          <RelateAssetModal
            caseId={caseId}
            onAdd={handleAdd}
            reclaimingId={reclaimingId}
            searchDefaultParams={{ excludeFrom: assets.map(asset => asset.id) }}
            hideAddButton={!canUser.addAsset}
          />
        )}
      </Stack>

      <Modal
        isOpen={isOpen.createAsset}
        onClose={toggle('createAsset')}
        title="Adicionar novo ativo"
        large
      >
        <AssetForm
          onCreateAsset={handleCreate}
          caseId={caseId}
          formDefaultData={{ reclaimingIds: [reclaimingId!] }}
        />
      </Modal>
    </div>
  );
};

export default AssetList;
