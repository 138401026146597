import uniqBy from 'lodash/uniqBy';
import {
  ExternalLawsuitBasicInformation,
  ExternalLawsuitIndication,
  Option
} from 'types';
import { TimelineColor } from 'ui';

const INDICATION_DICTIONARY = {
  [ExternalLawsuitIndication.ACOES_FEDERAIS_ATIVAS]: 'Ações federais ativas',
  [ExternalLawsuitIndication.BENS_PASSIVAS]: 'Bens passivos',
  [ExternalLawsuitIndication.BENS_ATIVAS]: 'Bens ativos',
  [ExternalLawsuitIndication.BENS_EXECUCAO]: 'Bens em execução'
};

export function indicationLabel(indication: ExternalLawsuitIndication): string {
  return INDICATION_DICTIONARY[indication] || indication;
}

export function indicationOptions(): Option[] {
  return Object.values(ExternalLawsuitIndication).map(indicationType => ({
    value: indicationType,
    label: indicationLabel(indicationType)
  }));
}

export function movimentColor(date: string | null) {
  if (!date) return TimelineColor.GRAY;

  return TimelineColor.BLUE;
}

export function externalLawsuitTypeLabel(
  basicInfos: ExternalLawsuitBasicInformation[]
) {
  const uniqType = uniqBy(basicInfos, 'lawSegment').map(
    basicInfo => basicInfo.lawSegment
  );

  return uniqType.join(', ');
}
