import React, { useContext } from 'react';
import { FormikProps } from 'formik';
import { Execution, InternalLawsuit, InternalLawsuitAttributes } from 'types';
import { Button, Modal, openErrorToast, InputField } from 'ui';
import { useToggles, useQuery } from 'hooks';
import { can, Action, fetchExecutions } from 'api';

import { CaseContext } from 'components';

import ExecutionCard from './ExecutionCard';

import Form from './Form';
import RelatedForm from './RelatedForm';
import Files from './Files';

import style from './Executions.module.scss';
import LawsuitForm from './LawsuitForm/LawsuitForm';

type Props = {
  caseId: string;
  lawsuit: InternalLawsuit;
  form: FormikProps<InternalLawsuitAttributes>;
  onUpdateFiles: (ids: string[]) => void;
};

const Executions = ({ caseId, lawsuit, form, onUpdateFiles }: Props) => {
  const {
    isOpen: modalOpen,
    toggle: handleToggleModal,
    closeAll: closeAllModals
  } = useToggles<'create' | 'related' | 'createLawsuit'>({
    create: false,
    related: false,
    createLawsuit: false
  });

  const { data: executions, setData: setExecutions, error } = useQuery(
    fetchExecutions,
    {
      caseId,
      lawsuitId: lawsuit.id
    }
  );

  if (error) {
    openErrorToast('Houve um erro ao listar penhoras!');
  }

  const onRemove = (id: string) => {
    const filteredExecutions = executions?.filter(
      execution => id !== execution.id
    );

    return setExecutions(filteredExecutions);
  };

  const onUpdate = (updatedExecution: Execution) => {
    const updatedExecutions = executions?.map(execution =>
      execution.id === updatedExecution.id ? updatedExecution : execution
    );

    return setExecutions(updatedExecutions);
  };

  const onCreate = (createdExecution: Execution) => {
    closeAllModals();

    return setExecutions([...(executions ?? []), createdExecution]);
  };

  const { caseData } = useContext(CaseContext);
  const negotiatorId = caseData?.negotiator?.id;
  const canUser = {
    createExecution: can(Action.CASOS_PROCESSO_PENHORA_INCLUIR, negotiatorId),
    createExecutionActive: can(
      Action.CASOS_PROCESSO_PENHORA_INCLUIR_NOVO_ATIVO,
      negotiatorId
    ),
    createLawsuit: can(
      Action.CASOS_PROCESSO_PENHORA_INCLUIR_NOVO_PROCESSO,
      negotiatorId
    ),
    editExecution: can(Action.CASOS_PROCESSO_PENHORA_EDITAR, negotiatorId),
    accessExecutionFiles: can(
      Action.CASOS_PROCESSO_ARQUIVOS_DA_PENHORA_CONSULTAR,
      negotiatorId
    ),
    createExecutionFiles: can(
      Action.CASOS_PROCESSO_ARQUIVOS_DA_PENHORA_INCLUIR,
      negotiatorId
    ),
    deleteExecutionFiles: can(
      Action.CASOS_PROCESSO_ARQUIVOS_DA_PENHORA_EXCLUIR,
      negotiatorId
    )
  };

  return (
    <>
      <span className={style.executionsLabel}>Ativos indicados a penhora:</span>
      <div data-testid="executions-card-list">
        {!executions?.length ? (
          <p className={style.emptyParagraph}>
            Não há ativos indicados a penhora neste processo.
          </p>
        ) : (
          executions.map(execution => (
            <ExecutionCard
              key={execution.id}
              execution={execution}
              onUpdate={onUpdate}
              onRemove={onRemove}
              caseId={caseId}
              lawsuitId={lawsuit.id}
            />
          ))
        )}
      </div>
      <div className={style.buttons}>
        {canUser.createExecution && (
          <Button highlight small onClick={handleToggleModal('related')}>
            Adicionar à penhora
          </Button>
        )}

        {canUser.createExecutionActive && (
          <Button highlight small onClick={handleToggleModal('create')}>
            Adicionar novo ativo
          </Button>
        )}

        {canUser.createLawsuit && (
          <Button highlight small onClick={handleToggleModal('createLawsuit')}>
            Adicionar novo processo
          </Button>
        )}
      </div>

      <div className={style.comments}>
        <InputField
          id="executionComments"
          name="executionComments"
          type="text"
          value={form.values.executionComments}
          title="Comentários"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          disabled={!canUser.editExecution}
        />
      </div>

      {canUser.accessExecutionFiles && (
        <Files
          caseId={caseId}
          initialFiles={lawsuit.fileUploads}
          onUpdate={onUpdateFiles}
          showAdd={canUser.createExecutionFiles}
          showDelete={canUser.deleteExecutionFiles}
        />
      )}

      <Modal
        title="Adicionar novo ativo"
        isOpen={modalOpen['create']}
        onClose={handleToggleModal('create')}
        large
      >
        <Form
          caseId={caseId}
          internalLawsuitId={lawsuit.id}
          onCreate={onCreate}
        />
      </Modal>

      <Modal
        title="Adicionar à penhora"
        isOpen={modalOpen['related']}
        onClose={handleToggleModal('related')}
        large
      >
        <RelatedForm
          caseId={caseId}
          internalLawsuitId={lawsuit.id}
          onCreate={onCreate}
        />
      </Modal>

      <Modal
        title="Adicionar novo processo"
        isOpen={modalOpen['createLawsuit']}
        onClose={handleToggleModal('createLawsuit')}
        large
      >
        <LawsuitForm
          caseId={caseId}
          internalLawsuitId={lawsuit.id}
          onCreate={onCreate}
        />
      </Modal>
    </>
  );
};

export default Executions;
