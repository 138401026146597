import React from 'react';
import xor from 'lodash/xor';
import { Case, CaseType } from 'types';

import { Table, Checkbox } from 'ui';
import CaseItem from './CaseItem';

import PeacActivities from './PeacActivities';

import style from './index.module.scss';

type CaseHeader = {
  label: string;
  field: string;
  direction: string | null;
};

type Props = {
  cases?: Case[];
  onCaseClick: (clickedCase: Case) => void;
  onOrderByClick: (orderedField: string) => void;
  headers: CaseHeader[];
  isLoading: boolean;
  onSelectCase: (caseIds: string[]) => void;
  selectedCaseIds: string[];
  totalCases?: number;
};

const CaseList = ({
  cases = [],
  onCaseClick,
  onOrderByClick,
  headers,
  isLoading,
  onSelectCase,
  selectedCaseIds,
  totalCases
}: Props) => {
  const isSort = (header: CaseHeader, direction: string) => {
    return header.direction === direction;
  };

  const isAllSelected = totalCases === selectedCaseIds.length;

  const onCheckboxClick = (caseId: string) => {
    const filteredIds = xor(selectedCaseIds, [caseId]);

    onSelectCase(filteredIds);
  };

  const onCheckAllClick = () => {
    if (isAllSelected) {
      onSelectCase([]);

      return;
    }

    const caseIds = cases.map(c => c.id);

    onSelectCase(caseIds);
  };

  const caseItems = cases.map((currentCase: Case, index: number) => {
    const selected = selectedCaseIds.includes(currentCase.id);

    return (
      <CaseItem
        className={!index ? style.firstRowCell : ''}
        onClick={onCaseClick}
        currentCase={currentCase}
        key={currentCase.id}
        selected={selected}
        onSelectCase={onCheckboxClick}
        secondaryRow={({ type, contracts }) => {
          if (type !== CaseType.PEAC) return <></>;

          return <PeacActivities contracts={contracts} />;
        }}
      />
    );
  });

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.Cell headerSticky key={'checkbox'}>
            <Checkbox
              name={`checkboxall`}
              id={'checkall'}
              onChange={onCheckAllClick}
              checked={isAllSelected}
              indeterminate={selectedCaseIds.length > 0}
            />
          </Table.Cell>
          <>
            {headers.map((header: CaseHeader) => (
              <Table.Cell headerSticky key={header.field}>
                <Table.OrderBy
                  onClick={() => onOrderByClick(header.field)}
                  asc={isSort(header, 'asc')}
                  desc={isSort(header, 'desc')}
                >
                  {header.label}
                </Table.OrderBy>
              </Table.Cell>
            ))}
          </>
        </Table.Row>
      </Table.Header>
      <Table.Body
        isLoading={isLoading}
        isEmpty={cases.length < 1}
        emptyText="Nenhum caso encontrado."
        columns={6}
      >
        {caseItems}
      </Table.Body>
    </Table>
  );
};

export default CaseList;
