import { objectLabel } from 'domain/guarantees';
import React from 'react';
import { getIn, FormikProps } from 'formik';

import { FormContainer, SelectField } from 'ui';
import {
  ExecutionRelatedAttributes,
  CreditContract,
  Guarantee,
  Option
} from 'types';
import { applyDateMask } from 'utils';

type Props = {
  formik: FormikProps<ExecutionRelatedAttributes>;
  contracts: CreditContract[];
  isLoading: boolean;
};

function executedContractOptions(contracts: CreditContract[]): Option[] {
  return contracts.map(contract => ({
    value: contract.id,
    label: `Contrato ${contract.number} (${applyDateMask(contract.endDate)})`
  }));
}

function executedGuaranteeOptions(quarantees: Guarantee[]): Option[] {
  return quarantees.map(quarantee => ({
    value: quarantee.id,
    label: `Garantia ${quarantee.instrumentNumber} (${objectLabel(
      quarantee.object
    )})`
  }));
}

const ExecutedGuaranteeIdField = ({ formik, contracts, isLoading }: Props) => {
  const guarantees =
    contracts.find(contract => contract.id === formik.values.executedContractId)
      ?.guarantees ?? [];

  return (
    <FormContainer className="col-2">
      <SelectField
        id="executedContractId"
        name="executedContractId"
        value={formik.values.executedContractId?.toString()}
        title="Contrato"
        error={
          getIn(formik.touched, 'executedContractId') &&
          getIn(formik.errors, 'executedContractId')
        }
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        options={executedContractOptions(contracts)}
        optional
        isLoading={isLoading}
      />

      <SelectField
        id="executedGuaranteeId"
        name="executedGuaranteeId"
        value={formik.values.executedGuaranteeId?.toString()}
        title="Garantia"
        error={
          getIn(formik.touched, 'executedGuaranteeId') &&
          getIn(formik.errors, 'executedGuaranteeId')
        }
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        options={executedGuaranteeOptions(guarantees)}
        optional
        optionalLabel="Selecione um contrato"
        isLoading={isLoading}
      />
    </FormContainer>
  );
};

export default ExecutedGuaranteeIdField;
