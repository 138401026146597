import { MovementsResponse, Movement, MovementFormAttributes } from 'types';
import Client from './client';

function fetchMovements({
  caseId,
  lawsuitId
}: {
  caseId: string;
  lawsuitId: string;
}) {
  return Client.get<MovementsResponse>(
    `cases/${caseId}/internal_lawsuits/${lawsuitId}/movements`
  );
}

function updateMovement({
  caseId,
  lawsuitId,
  movementId,
  movementData
}: {
  caseId: string;
  lawsuitId: string;
  movementId: string;
  movementData: MovementFormAttributes;
}) {
  return Client.put<MovementFormAttributes, Movement>(
    `cases/${caseId}/internal_lawsuits/${lawsuitId}/movements/${movementId}`,
    movementData
  );
}

function createMovement({
  caseId,
  lawsuitId,
  movementData
}: {
  caseId: string;
  lawsuitId: string;
  movementData: MovementFormAttributes;
}) {
  return Client.post<MovementFormAttributes, Movement>(
    `cases/${caseId}/internal_lawsuits/${lawsuitId}/movements`,
    movementData
  );
}

function deleteMovement({
  caseId,
  lawsuitId,
  id
}: {
  caseId: string;
  lawsuitId: string;
  id: string;
}) {
  return Client.delete(
    `cases/${caseId}/internal_lawsuits/${lawsuitId}/movements/${id}`
  );
}

export { fetchMovements, updateMovement, createMovement, deleteMovement };
