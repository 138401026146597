import { modalityLabel as guaranteeModalityLabel } from 'domain/guarantees';
import { modalityLabel as contractModalityLabel } from 'domain/contracts';
import React, { useEffect, useState } from 'react';
import { Formik, FormikProps, getIn } from 'formik';
import * as Yup from 'yup';

import { createContractGuaranteeRelationship, fetchCreditContracts } from 'api';
import { CreditContract, Guarantee, OnSubmit } from 'types';
import { useQuery } from 'hooks';

import {
  Button,
  FormContainer,
  openErrorToast,
  openSuccessToast,
  SelectField
} from 'ui';

import style from './Form.module.scss';

type Props = {
  caseId: string;
  contractId: string;
  onAddGuarantee: (guarantee: Guarantee) => void;
  onClose: () => void;
};

type FormAttributes = {
  contractId: string;
  guaranteeId: string;
};

type Option = {
  value: string;
  label: string;
};

const initialValues = {
  contractId: '',
  guaranteeId: ''
};

const validationSchema = Yup.object().shape({
  contractId: Yup.string().required('Campo obrigatório'),
  guaranteeId: Yup.string().required('Campo obrigatório')
});

const Form = ({ caseId, contractId, onClose, onAddGuarantee }: Props) => {
  const [contractOptions, setContractOptions] = useState<Option[]>([]);
  const [guaranteeOptions, setGuaranteeOptions] = useState<Option[]>([]);

  const { data: creditContracts, error, isLoading } = useQuery<
    CreditContract[],
    { caseId: string }
  >(fetchCreditContracts, {
    caseId
  });

  if (error) openErrorToast('Ocorreu um erro ao listar os contratos.');

  const creditContractToOptions = (
    creditContracts: CreditContract[]
  ): Option[] => {
    return creditContracts
      .filter(contract => {
        const contractHasGuarantees = contract.guarantees.length > 0;
        const isCurrentContract = contract.id === contractId;
        const isContractGuaranteesInCurrentContract =
          contract.guarantees.filter(
            guarantee =>
              guarantee.contracts.filter(contract => contract.id === contractId)
                .length === 0
          ).length === 0;

        return (
          contractHasGuarantees &&
          !isCurrentContract &&
          !isContractGuaranteesInCurrentContract
        );
      })
      .map(contract => ({
        value: contract.id,
        label: `${contract.number} (${contractModalityLabel(
          contract.modality
        )})`
      }));
  };

  useEffect(() => {
    if (creditContracts) {
      setContractOptions(creditContractToOptions(creditContracts));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditContracts]);

  const handleCreditContractSelectChange = (
    formik: FormikProps<FormAttributes>,
    selectedContractId: string
  ) => {
    const [selectedCreditContract] = creditContracts!.filter(
      creditContract => creditContract.id === selectedContractId
    );
    const guaranteeOptions = selectedCreditContract.guarantees
      .filter(
        guarantee =>
          guarantee.contracts.filter(contract => contract.id === contractId)
            .length === 0
      )
      .map(guarantee => ({
        value: guarantee.id,
        label: `${guarantee.instrumentNumber} (${guaranteeModalityLabel(
          guarantee.modality
        )})`
      }));

    formik.setFieldValue('contractId', contractId);
    setGuaranteeOptions(guaranteeOptions);
  };

  const handleSubmit: OnSubmit<FormAttributes> = async (
    values,
    formikHelpers
  ) => {
    try {
      const response = await createContractGuaranteeRelationship({
        caseId,
        contractId,
        guaranteeId: values.guaranteeId
      });

      onAddGuarantee(response.data);
      openSuccessToast('Garantia adicionada ao contrato com sucesso.');
      onClose();
    } catch (error) {
      openErrorToast('Ocorreu um erro ao adicionar a garantia ao contrato.');
    }
  };

  return (
    <Formik<{ contractId: CreditContract['id']; guaranteeId: Guarantee['id'] }>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {formik => (
        <form>
          <FormContainer>
            <SelectField
              id="contract-select"
              name="contractId"
              title="Contrato"
              value={formik.values.contractId}
              options={contractOptions}
              onChange={event =>
                handleCreditContractSelectChange(formik, event.target.value)
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.contractId && getIn(formik.errors, 'contractId')
              }
              disabled={isLoading || contractOptions.length === 0}
            />

            <SelectField
              id="guarantee-select"
              name="guaranteeId"
              title="Garantia"
              value={formik.values.guaranteeId}
              options={guaranteeOptions}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.guaranteeId &&
                getIn(formik.errors, 'guaranteeId')
              }
              disabled={guaranteeOptions.length === 0}
            />
          </FormContainer>

          <div className={style.actions}>
            <Button onClick={onClose} highlight outline>
              Cancelar
            </Button>

            <Button onClick={formik.submitForm} highlight>
              Adicionar garantia
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default Form;
