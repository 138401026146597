import { BadgeColor } from 'components/ColoredBadge/ColoredBadge';
import { CnpjSituation, CnpjSpecialSituation } from 'types';

interface SituationInterface {
  [key: string]: BadgeColor;
}

export const CnpjSituationColor: SituationInterface = {
  [CnpjSituation.NULA]: BadgeColor.RED,
  [CnpjSituation.ATIVA]: BadgeColor.GREEN,
  [CnpjSituation.SUSPENSA]: BadgeColor.DEFAULT,
  [CnpjSituation.INAPTA]: BadgeColor.DEFAULT,
  [CnpjSituation.ATIVA_NAO_REGULAR]: BadgeColor.DEFAULT,
  [CnpjSituation.BAIXADA]: BadgeColor.DEFAULT
};

export const CnpjSpecialSituationColor: SituationInterface = {
  [CnpjSpecialSituation.FALIDO]: BadgeColor.RED,
  [CnpjSpecialSituation.INICIO_DE_CONCORDATA]: BadgeColor.DEFAULT,
  [CnpjSpecialSituation.EM_LIQUIDACAO]: BadgeColor.DEFAULT,
  [CnpjSpecialSituation.EXTRA_JUDICIAL]: BadgeColor.DEFAULT,
  [CnpjSpecialSituation.INTERVENCAO]: BadgeColor.DEFAULT,
  [CnpjSpecialSituation.FINANCEIRO_E_DE_CAPITAIS]: BadgeColor.DEFAULT,
  [CnpjSpecialSituation.LIQUIDACAO_JUDICIAL]: BadgeColor.DEFAULT,
  [CnpjSpecialSituation.LIQUIDACAO_EXTRA_JUDICIAL]: BadgeColor.DEFAULT,
  [CnpjSpecialSituation.RECUPERACAO_JUDICIAL]: BadgeColor.DEFAULT
};
