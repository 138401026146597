import { buildPaginationParams } from 'domain/pagination';
import { entityDocument } from 'domain/entity';
import { handleCopy, NOSELECT } from 'domain/copyTableRow';
import {
  Action,
  can,
  deleteLocation,
  fetchLocations,
  FetchLocationsParams
} from 'api';
import { useQuery } from 'hooks';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { CaseEntity, Location, LocationEntity, PaginatedResource } from 'types';
import { CaseContext, usePaginationContext } from 'components';
import {
  Accordion,
  Button,
  Card,
  DropDown,
  DynamicTable,
  Modal,
  openErrorToast,
  openSuccessToast,
  TablePositionModifier,
  ToolTip
} from 'ui';
import Form from './Form/Form';

type Props = {
  caseId: string;
};

const entityName = (entity: LocationEntity): string => {
  if (entity === null) return '';
  return entity.company ? entity.company.businessName : entity.individual.name;
};

const entityDoc = (entity: LocationEntity): string => {
  if (entity === null) return '';

  return entityDocument(entity as CaseEntity);
};

const statusTranslation: { [key: string]: string } = {
  suspected: 'Suspeita',
  confirmed: 'Confirmada'
};

const Locations = ({ caseId }: Props): ReactElement => {
  const {
    getPagination,
    changePagination,
    getOrderBy
  } = usePaginationContext();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [editLocation, setEditLocation] = useState<Location | undefined>(
    undefined
  );
  const {
    isLoading,
    refetch,
    pagination,
    error,
    data: locations = [],
    setData: setLocations
  } = useQuery(fetchLocations, {
    caseId,
    ...getPagination(PaginatedResource.LOCATIONS),
    ...getOrderBy(PaginatedResource.LOCATIONS)
  });

  const { caseData } = useContext(CaseContext);

  const negotiatorId = caseData?.negotiator?.id;

  const canUser = {
    createLocation: can(
      Action.CASOS_RASTREAMENTO_LOCALIZACOES_INCLUIR,
      negotiatorId
    ),
    editLocation: can(
      Action.CASOS_RASTREAMENTO_LOCALIZACOES_EDITAR,
      negotiatorId
    ),
    deleteLocation: can(
      Action.CASOS_RASTREAMENTO_LOCALIZACOES_EXCLUIR,
      negotiatorId
    ),
    copyLocation: can(
      Action.CASOS_RASTREAMENTO_LOCALIZACOES_COPIAR_LINHA,
      negotiatorId
    )
  };

  const handleDelete = (locationId: string) => async () => {
    if (
      !window.confirm(
        'Você tem certeza de que deseja remover essa localização?'
      )
    )
      return;
    try {
      await deleteLocation(caseId, locationId);
      const params: FetchLocationsParams = {
        caseId
      };
      if (pagination) {
        params.pagination = {
          pageSize: pagination.size,
          page: pagination.number
        };
      }
      await refetch(params);
      openSuccessToast('Localização deletada com sucesso!');
    } catch (error) {
      openErrorToast('Houve um erro ao excluir a localização');
    }
  };

  const handleCreate = (location: Location) => {
    setLocations([...locations, location]);
    setModalOpen(false);
  };

  const handleUpdate = (updatedLocation: Location) => {
    const updateLocationList = locations.map(location => {
      if (location.id === updatedLocation.id) return updatedLocation;
      return location;
    });

    setLocations(updateLocationList);
    onHandleCloseModal();
  };

  const onHandleEditMode = (location: Location) => () => {
    setEditLocation(location);
    setModalOpen(true);
  };

  const onHandleCloseModal = () => {
    setEditLocation(undefined);
    setModalOpen(false);
  };

  const columns = [
    {
      columnKey: 'status',
      name: 'Status',
      hasOrderBy: true,
      dataTestId: 'location-cell-status',
      render: (location: Location) => (
        <ToolTip content={location.comment} placement="top">
          <span>{statusTranslation[location.status]}</span>
        </ToolTip>
      )
    },
    {
      columnKey: 'entityDocument',
      name: 'CPF ou CNPJ',
      hasOrderBy: true,
      dataTestId: 'location-cell-entityDoc',
      render: (location: Location) => entityDoc(location.entity)
    },
    {
      columnKey: 'entityName',
      name: 'Nome ou razão social',
      hasOrderBy: true,
      dataTestId: 'location-cell-entityName',
      render: (location: Location) => entityName(location.entity)
    },
    {
      columnKey: 'cityState',
      name: 'Estado',
      hasOrderBy: true,
      render: (location: Location) =>
        location.abroadCity ? 'Exterior' : location.city?.state
    },
    {
      columnKey: 'cityName',
      name: 'Município',
      hasOrderBy: true,
      render: (location: Location) => location.abroadCity ?? location.city?.name
    },
    {
      columnKey: 'actions',
      name: ' ',
      positionModifier: TablePositionModifier.RIGHT,
      className: () => NOSELECT,
      render: (location: Location) => {
        const options = [
          {
            text: 'Editar',
            callback: onHandleEditMode(location),
            dataTestId: `dropdown-edit-location-${location.id}`,
            isHidden: !canUser.editLocation
          },
          {
            text: 'Copiar linha',
            callback: () => handleCopy(location.id, 'locations'),
            dataTestId: `copy-${location.id}`,
            isHidden: !canUser.copyLocation
          },
          {
            text: 'Excluir',
            callback: handleDelete(location.id),
            dataTestId: `dropdown-delete-location-${location.id}`,
            isNegative: true,
            isHidden: !canUser.deleteLocation
          }
        ];

        if (options.every(option => option.isHidden)) return <></>;

        return (
          <DropDown
            dataTestId={`dropdown-location-${location.id}`}
            options={options}
          />
        );
      }
    }
  ];

  const rowKey = (location: Location) => location.id;

  const refetchWithPagination = (pagination?: {
    page: number;
    pageSize: number;
  }) => {
    pagination && changePagination(PaginatedResource.LOCATIONS, pagination);
  };

  const paginationProps = buildPaginationParams(
    pagination,
    refetchWithPagination
  );

  useEffect(() => {
    if (error) {
      openErrorToast('Houve um erro ao listar localizações');
    }
  }, [error]);
  return (
    <Accordion
      className="locations-default"
      title="Localizações"
      badgeContent={pagination?.totalEntries ?? 0}
    >
      <Card small>
        <DynamicTable
          data={locations}
          columns={columns}
          rowKey={rowKey}
          pagination={paginationProps}
          noAutoPaginate
          isLoading={isLoading}
          tableName="locations"
          paginatedResource={PaginatedResource.LOCATIONS}
        />
        {canUser.createLocation && (
          <div className="location-button-container">
            <Button
              dataTestId="location-modal-trigger"
              highlight
              outline
              centered
              small
              onClick={() => setModalOpen(true)}
              disabled={isLoading}
            >
              Adicionar Endereço
            </Button>
          </div>
        )}
      </Card>

      <Modal
        isOpen={modalOpen}
        onClose={onHandleCloseModal}
        title={editLocation ? 'Editar localização' : 'Adicionar localização'}
        medium
      >
        <Form
          onCreate={handleCreate}
          onUpdate={handleUpdate}
          locationData={editLocation}
          caseId={caseId}
        />
      </Modal>
    </Accordion>
  );
};

export default Locations;
