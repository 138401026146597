import { CreditClassType } from 'types';

const creditClassesDictionary: { [key in CreditClassType]: string } = {
  [CreditClassType.DIP]: 'Classe de crédito DIP',
  [CreditClassType.EXTRACONCURSAL]: 'Classe de crédito Extraconcursal',
  [CreditClassType.MPE]: 'Classe de crédito 4: MPE',
  [CreditClassType.QUIROGRAFARIO]: 'Classe de crédito 3: Quirográfico',
  [CreditClassType.REAL]: 'Classe de crédito 2: Real'
};

export function creditClassesLabel(creditClass: CreditClassType) {
  return creditClassesDictionary[creditClass] || creditClass;
}
