import { getLabel } from 'domain/summary';
import { entityDocument, entityName } from 'domain/entity';
import { indexerLabel } from 'domain/taxIndexers';
import { creditClassesLabel } from 'domain/creditClasses';
import { fetchDebtorsWithJudicialRecovery } from 'api';
import { useQuery } from 'hooks';
import React from 'react';
import { Debtor, SummaryInfoType, TaxIndexer } from 'types';
import { Card, Stack, StackMargin } from 'ui';
import { SummaryError } from 'components';
import {
  applyCurrencyMask,
  applyDateMask,
  applyPercentMask,
  handleNullValue
} from 'utils';
import SummaryLoader from '../SummaryLoader';
import style from './DebtorsInJudicialRecovery.module.scss';

type Props = {
  caseId: string;
};

const DebtorsInJudicialRecovery = ({ caseId }: Props) => {
  const { data: debtorsWithJudicialRecovery = [], error, isLoading } = useQuery<
    Debtor[],
    { caseId: string }
  >(fetchDebtorsWithJudicialRecovery, {
    caseId
  });

  const title = getLabel(SummaryInfoType.DEBTORS_IN_JUDICIAL_RECOVERY);

  if (isLoading) {
    return <SummaryLoader title={title} />;
  }

  if (error) {
    return (
      <SummaryError
        title={title}
        message="Erro ao listar devedores em RJ, tente novamente."
      />
    );
  }

  return (
    <Stack marginTop={StackMargin.XLARGE}>
      <div className={style.titleWrapper}>
        <h3
          className={style.title}
        >{`Devedores em RJ (${debtorsWithJudicialRecovery.length})`}</h3>
      </div>

      <Stack marginTop={StackMargin.LARGE}>
        {debtorsWithJudicialRecovery.map(debtor => (
          <Card
            className={style.card}
            medium
            dataTestId={`card-${debtor.id}`}
            key={debtor.id}
          >
            <Stack>
              <span className={style.debtorName}>
                {entityName(debtor.entity)}
              </span>
              <span>({entityDocument(debtor.entity)})</span>
            </Stack>

            <Stack
              marginTop={StackMargin.MEDIUM}
              className={style.judicialRecoveryBasicInfo}
            >
              <span>
                Data do pedido:{' '}
                {handleNullValue(debtor.orderDate, 'string', date =>
                  applyDateMask(date)
                )}
              </span>
              <span>
                Local:{' '}
                {debtor.city
                  ? `${debtor.city.name}, ${debtor.city.state}`
                  : '-'}
              </span>
              <span>
                Data da aprovação do PRJ:{' '}
                {handleNullValue(debtor.prjApprovalDate, 'string', date =>
                  applyDateMask(date)
                )}
              </span>
            </Stack>

            {debtor.creditClasses.map(creditClass => (
              <Card key={creditClass.id} className={style.classesCard} small>
                <span className={style.classesCardTitle}>
                  {creditClassesLabel(creditClass.class)}
                </span>
                <Stack
                  className={style.classesCardDetailsWrapper}
                  marginTop={StackMargin.SMALL}
                >
                  <div className={style.classesCardDetails}>
                    <span className={style.classesCardLabel}>
                      Valor do crédito:
                    </span>
                    <span>
                      {handleNullValue(
                        creditClass.creditValueCents,
                        'string',
                        value => applyCurrencyMask(value)
                      )}
                    </span>
                  </div>
                  <div className={style.classesCardDetails}>
                    <span className={style.classesCardLabel}>
                      % do crédito na classe:
                    </span>
                    <span>
                      {handleNullValue(
                        creditClass.classPercentage,
                        'string',
                        value => applyPercentMask(value)
                      )}
                    </span>
                  </div>
                  <div className={style.classesCardDetails}>
                    <span className={style.classesCardLabel}>Haircut (%):</span>
                    <span>
                      {handleNullValue(
                        creditClass.haircutPercentage,
                        'string',
                        value => applyPercentMask(value)
                      )}
                    </span>
                  </div>
                  <div className={style.classesCardDetails}>
                    <span className={style.classesCardLabel}>
                      Bônus de adimplência (%):
                    </span>
                    <span>
                      {handleNullValue(
                        creditClass.complianceBonusPercentage,
                        'string',
                        value => applyPercentMask(value)
                      )}
                    </span>
                  </div>
                  <div className={style.classesCardDetails}>
                    <span className={style.classesCardLabel}>
                      Carência do juros (meses):
                    </span>
                    <span>
                      {handleNullValue(
                        creditClass.interestGraceMonths,
                        'string'
                      )}
                    </span>
                  </div>
                  <div className={style.classesCardDetails}>
                    <span className={style.classesCardLabel}>
                      Carência principal (meses):
                    </span>
                    <span>
                      {handleNullValue(creditClass.mainGraceMonths, 'string')}
                    </span>
                  </div>
                  <div className={style.classesCardDetails}>
                    <span className={style.classesCardLabel}>
                      Amortização (meses):
                    </span>
                    <span>
                      {handleNullValue(
                        creditClass.amortizationMonths,
                        'string'
                      )}
                    </span>
                  </div>
                  <div className={style.classesCardDetails}>
                    <span className={style.classesCardLabel}>Indexador:</span>
                    <span>
                      {handleNullValue(creditClass.indexer, 'string', value =>
                        indexerLabel(value as TaxIndexer)
                      )}
                    </span>
                  </div>
                  <div className={style.classesCardDetails}>
                    <span className={style.classesCardLabel}>
                      Taxa PRJ (%):
                    </span>
                    <span>
                      {handleNullValue(
                        creditClass.prjFeePercentage,
                        'string',
                        value => applyPercentMask(value)
                      )}
                    </span>
                  </div>
                </Stack>
              </Card>
            ))}
          </Card>
        ))}
      </Stack>
    </Stack>
  );
};

export default DebtorsInJudicialRecovery;
