import {
  AddContentToCase,
  AssetAttributes,
  FetchReportTableProps,
  FetchReportTotalValueResult,
  ReportVehicle
} from 'types';
import Client from './client';

export function fetchReportVehicles({
  caseId,
  pagination,
  reportId
}: FetchReportTableProps) {
  return Client.get<ReportVehicle[]>(
    `cases/${caseId}/reports/${reportId}/contents/vehicles`,
    {
      params: {
        page: pagination?.page.toString(),
        pageSize: pagination?.pageSize.toString()
      }
    }
  );
}

export function fetchReportVehiclesSummary({
  caseId,
  reportId
}: FetchReportTableProps) {
  return Client.get<ReportVehicle[]>(
    `cases/${caseId}/reports/${reportId}/contents/vehicles/summary`
  );
}

export function addVehiclesToCase({
  caseId,
  contentId,
  reportId
}: AddContentToCase) {
  return Client.post<unknown, AssetAttributes>(
    `cases/${caseId}/reports/${reportId}/contents/vehicles/${contentId}`
  );
}

export function fetchReportVehiclesTotalValue({
  caseId,
  reportId
}: FetchReportTableProps) {
  return Client.get<FetchReportTotalValueResult>(
    `cases/${caseId}/reports/${reportId}/contents/vehicles/total_value`
  );
}
