import React from 'react';
import { Button, openErrorToast } from 'ui';
import { useQuery } from 'hooks';
import { fetchNotifications } from 'api';

import Item from './Item';
import NotificationLoader from './NotificationLoader';

import style from './ListWindow.module.scss';

const INITIAL_PAGE = 1;
const PAGE_SIZE = 5;

type Props = {
  caseId?: string;
  openModal: () => void;
  closeNotificaton: () => void;
};

const ListWindow = ({ caseId, openModal, closeNotificaton }: Props) => {
  const { data, isLoading, error } = useQuery(fetchNotifications, {
    caseId,
    pagination: { page: INITIAL_PAGE, pageSize: PAGE_SIZE }
  });
  const isEmpty = !isLoading && data?.length === 0;

  if (error) {
    openErrorToast('Erro ao listar notificações');
  }

  return (
    <div className={style.notificationContent}>
      <h3 className={style.title}>Notificações</h3>

      {isLoading && <NotificationLoader />}

      {isEmpty && (
        <p className={style.emptyState}>
          {caseId
            ? 'Não há nenhuma notificação neste caso'
            : 'Não há nenhuma notificação'}
        </p>
      )}

      {data &&
        data?.map((notification, index) => {
          return (
            <Item
              notification={notification}
              key={index}
              showCasePrefix={!caseId}
              closeNotificaton={closeNotificaton}
            />
          );
        })}

      <Button
        dataTestId="notifications-open-modal"
        className={style.button}
        onClick={() => openModal()}
        outline
        highlight
        small
        centered
      >
        Ver todas as notificações
      </Button>
    </div>
  );
};

export default ListWindow;
