import { activityBadgeColor, fromISOToActivityDate } from 'domain/activities';
import React, { useContext, useState } from 'react';
import { DateTime } from 'luxon';

import { updateActivity, createEvent, can, Action } from 'api';
import { useToggles } from 'hooks';
import { pluralize } from 'utils';

import { CaseContext, ColoredBadge, EventForm } from 'components';
import {
  ListItem,
  openErrorToast,
  openSuccessToast,
  Stack,
  StackMargin,
  Button,
  Modal
} from 'ui';

import {
  Activity,
  CreditContract,
  OnSubmit,
  CaseEventFormAttributes
} from 'types';

import style from '../ActivitiesPerContract.module.scss';

type Props = {
  caseId: string;
  activity: Activity;
  setActivity: (entry: Activity) => void;
  contractFirstOverduePaymentDate: CreditContract['firstOverduePaymentDate'];
};

const pluralizeByDays = (days: number | null) =>
  pluralize(days ?? 0, 'dia', 'dias');

const ActivityItem = ({
  activity,
  caseId,
  setActivity,
  contractFirstOverduePaymentDate
}: Props) => {
  const [isLoading, setLoading] = useState(false);
  const [showEventButton, setShowEventButton] = useState<boolean>(false);
  const { isOpen, toggle, toggleTrigger } = useToggles<'eventModal'>({
    eventModal: false
  });
  const { caseData } = useContext(CaseContext);

  const negotiatorId = caseData?.negotiator?.id;

  const canCreateEvent = can(
    Action.CASOS_ACOMPANHAMENTO_EVENTO_INCLUIR,
    negotiatorId
  );
  const canUpdateActivity = can(
    Action.CASOS_ACOMPANHAMENTO_ATIVIDADES_EDITAR,
    negotiatorId
  );

  const now = DateTime.local().toISO();

  const handleUpdate = (activity: Activity) => async () => {
    try {
      setLoading(true);

      const response = await updateActivity({
        caseId,
        activityId: activity.id,
        data: { completed: !activity.completed }
      });

      setActivity({ ...response.data });
      setShowEventButton(response.data.completed);

      return openSuccessToast('Atividade atualizada com sucesso');
    } catch (error) {
      return openErrorToast(
        'Houve um problema ao atualizar a atividade, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitEvent: OnSubmit<CaseEventFormAttributes> = async (
    values: CaseEventFormAttributes,
    formikHelpers
  ) => {
    try {
      await createEvent({ caseId, eventData: values });

      toggleTrigger('eventModal');
      setShowEventButton(false);
      openSuccessToast('Evento salvo com sucesso!');
    } catch (error) {
      formikHelpers.setErrors(error.errors);
      openErrorToast('Erro ao salvar evento.');
    }
  };

  return (
    <>
      <Stack key={activity.id} marginTop={StackMargin.MEDIUM}>
        <ListItem
          id={activity.id}
          onCheckboxClick={handleUpdate(activity)}
          checked={activity.completed}
          title=""
          isCheckBoxDisabled={isLoading || !canUpdateActivity}
          text={activity.name}
          actions={
            <>
              {canCreateEvent && showEventButton && (
                <Button small outline highlight onClick={toggle('eventModal')}>
                  Criar evento
                </Button>
              )}
              <ColoredBadge
                type={activityBadgeColor(
                  activity,
                  contractFirstOverduePaymentDate ?? now
                )}
                title={`${pluralizeByDays(
                  activity.daysAfterOverdueDate
                )} de atraso - ${fromISOToActivityDate(activity.date)}`}
                wrapperClassName={style.badgeWrapper}
              />
            </>
          }
        />
      </Stack>

      <Modal
        title="Novo evento"
        isOpen={isOpen.eventModal}
        onClose={toggle('eventModal')}
      >
        <EventForm
          caseId={caseId}
          eventData={{
            date: now,
            title: activity.name
          }}
          onSubmit={handleSubmitEvent}
        />
      </Modal>
    </>
  );
};

export default ActivityItem;
