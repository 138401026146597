import React, { useState, useEffect, useContext } from 'react';
import * as Yup from 'yup';
import { useFormik, FormikHelpers } from 'formik';

import {
  ReclaimingFormAttributes,
  Reclaiming,
  ReclaimingParams,
  FrequencyType,
  EntityType,
  Feature
} from 'types';
import { Action, can, deleteReclaiming, submitReclaiming } from 'api';
import { useFeatureFlag } from 'hooks/features';
import { FileField, CaseContext } from 'components';
import { Card, openSuccessToast, openErrorToast, Button } from 'ui';
import * as ValidationSchema from 'utils/validationSchema';

import { useFormDirty } from '../../FormDirtyContext';

import AssetList from '../AssetList';
import Form from '../Form';

type Props = {
  onDelete: () => void;
  index: number;
  reclaimingData: Reclaiming | undefined;
  caseId: string;
  contractId: string;
  handleCalcAmount: (totalAmount: number) => void;
  htmlId: string;
};

const initialValues: ReclaimingFormAttributes = {
  type: '',
  frequency: FrequencyType.SINGLE,
  totalAmount: 0,
  date: '',
  comments: '',
  fileUploadIds: [],
  payments: [
    {
      amount: '',
      date: ''
    }
  ],
  payor: {
    id: '',
    type: EntityType.INDIVIDUAL,
    individual: {
      cpf: '',
      name: '',
      isAbroad: false
    },
    company: {
      cnpj: '',
      businessName: '',
      isAbroad: false
    },
    description: ''
  }
};

const today = new Date();
const validationSchema = Yup.object().shape({
  type: Yup.string().required('Campo obrigatório'),
  frequency: Yup.string().required('Campo obrigatório'),
  totalAmount: Yup.number().when('frequency', {
    is: FrequencyType.SINGLE,
    then: Yup.number()
      .moreThan(0, 'Valor deve ser maior que zero')
      .required('Campo obrigatório.'),
    otherwise: Yup.number().notRequired()
  }),
  date: Yup.date().when('frequency', {
    is: FrequencyType.SINGLE,
    then: Yup.date()
      .max(today, 'A data deve ser igual ou anterior a hoje')
      .required('Campo obrigatório'),
    otherwise: Yup.date().notRequired()
  }),
  comments: Yup.string().nullable(),
  fileUploadIds: Yup.array()
    .of(Yup.string())
    .notRequired(),
  payor: Yup.object().shape({
    type: Yup.string().required('Campo obrigatório'),
    individual: Yup.object().when('type', {
      is: EntityType.INDIVIDUAL,
      then: Yup.object().shape({
        isAbroad: Yup.boolean(),
        cpf: Yup.string().when('isAbroad', {
          is: false,
          then: ValidationSchema.cpf()
            .required('Campo obrigatório')
            .nullable(),
          otherwise: ValidationSchema.cpf()
            .notRequired()
            .nullable()
        }),
        name: Yup.string().required('Campo obrigatório')
      }),
      otherwise: Yup.object().shape({
        isAbroad: Yup.boolean(),
        cpf: Yup.string()
          .notRequired()
          .nullable(),
        name: Yup.string()
          .notRequired()
          .nullable()
      })
    }),
    company: Yup.object().when('type', {
      is: EntityType.COMPANY,
      then: Yup.object().shape({
        isAbroad: Yup.boolean(),
        cnpj: Yup.string().when('isAbroad', {
          is: false,
          then: ValidationSchema.cnpj()
            .required('Campo obrigatório')
            .nullable(),
          otherwise: Yup.string()
            .notRequired()
            .nullable()
        }),
        businessName: Yup.string().required('Campo obrigatório')
      }),
      otherwise: Yup.object().shape({
        isAbroad: Yup.boolean(),
        cnpj: Yup.string()
          .notRequired()
          .nullable(),
        businessName: Yup.string()
          .notRequired()
          .nullable()
      })
    })
  }),
  payments: Yup.array().when('frequency', {
    is: FrequencyType.RECURRENT,
    then: Yup.array().of(
      Yup.object().shape({
        date: Yup.date()
          .max(today, 'A data deve ser igual ou inferior á hoje')
          .required('Campo obrigatório'),
        amount: Yup.number().required('Campo obrigatório')
      })
    ),
    otherwise: Yup.array().of(
      Yup.object().shape({
        date: Yup.date().notRequired(),
        amount: Yup.number().notRequired()
      })
    )
  })
});

const formatInitialValue = (
  reclaiming: Reclaiming | undefined
): ReclaimingFormAttributes => {
  if (!reclaiming) return initialValues;

  return {
    type: reclaiming.type,
    frequency: reclaiming.frequency,
    totalAmount: reclaiming.totalAmount,
    date: reclaiming.date ?? '',
    comments: reclaiming.comments,
    payments: reclaiming.payments,
    fileUploadIds: reclaiming.fileUploads.map(file => file.id),
    payor: {
      ...reclaiming.payor,
      type: reclaiming.payor.type,
      individual: {
        ...reclaiming?.payor?.individual
      },
      company: {
        ...reclaiming?.payor?.company
      },
      description: reclaiming.payor.description ?? ''
    }
  };
};

const formatReclaimingData = (reclaimingData: ReclaimingParams) => {
  return {
    ...reclaimingData,
    payments:
      reclaimingData.frequency === 'recurrent' ? reclaimingData.payments : []
  };
};

const ReclaimingCard = ({
  onDelete,
  index,
  reclaimingData,
  caseId,
  contractId,
  handleCalcAmount,
  htmlId
}: Props) => {
  const [reclaiming, setReclaiming] = useState<Reclaiming | undefined>(
    reclaimingData
  );
  const [isUploadEnabled] = useFeatureFlag(Feature.FILE_UPLOADS);

  const { caseData } = useContext(CaseContext);

  const { setIsReclaimingFormDirty, cleanReclaimingFormDirty } = useFormDirty();

  const formik = useFormik({
    initialValues: formatInitialValue(reclaiming),
    validationSchema,
    onSubmit
  });

  useEffect(() => {
    setIsReclaimingFormDirty(formik.dirty, htmlId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.dirty]);

  async function onSubmit(
    reclaimingData: ReclaimingFormAttributes,
    formikHelpers: FormikHelpers<ReclaimingFormAttributes>
  ): Promise<void> {
    try {
      const response = await submitReclaiming({
        reclaiming,
        caseId,
        contractId,
        values: formatReclaimingData(reclaimingData)
      });

      setReclaiming(response.data);

      handleCalcAmount(
        Number(response.data.totalAmount) - Number(reclaiming?.totalAmount || 0)
      );
      if (response.data.frequency === FrequencyType.SINGLE) {
        formik.setFieldValue('payments', []);
      }

      formikHelpers.setValues(formatInitialValue(response.data));

      openSuccessToast('Recuperação salvo com sucesso');
    } catch (error) {
      openErrorToast('Houve um erro ao salvar a recuperação');
    }
  }

  const handleDelete = async (): Promise<void> => {
    const formTouched = Boolean(Object.keys(formik.touched).length);

    if (!formTouched && !reclaiming) {
      return onDelete();
    }

    if (window.confirm('Você deseja excluir?')) {
      try {
        if (reclaiming?.id) {
          await deleteReclaiming(caseId, reclaiming.id);
          handleCalcAmount(-Number(reclaiming.totalAmount));
        }

        cleanReclaimingFormDirty(htmlId);
        onDelete();
      } catch (error) {
        openErrorToast('Houve um erro ao excluir a recuperação');
      }
    }
  };

  const negotiatorId = caseData?.negotiator?.id;

  const canUser = {
    deleteReclaiming: can(Action.CASOS_RECUPERACOES_EXCLUIR, negotiatorId),
    addReclaiming: can(Action.CASOS_RECUPERACOES_INCLUIR, negotiatorId),
    editReclaiming: can(Action.CASOS_RECUPERACOES_EDITAR, negotiatorId),
    showFiles: can(
      Action.CASOS_ARQUIVOS_DA_RECUPERACAO_CONSULTAR,
      negotiatorId
    ),
    addFile: can(Action.CASOS_ARQUIVOS_DA_RECUPERACAO_INCLUIR, negotiatorId),
    deleteFile: can(Action.CASOS_ARQUIVOS_DA_RECUPERACAO_EXCLUIR, negotiatorId)
  };

  const canUpdateFiles = canUser.addFile || canUser.deleteFile;
  const canEditFields = reclaiming
    ? canUser.editReclaiming
    : canUser.addReclaiming;

  return (
    <Card dataTestId="reclaiming-card">
      <div className="header">
        <div className="title-container">
          <h2 data-testid="card-header-title">{`Recuperação #${index + 1}`}</h2>
        </div>

        <div className="actions">
          {canUser.deleteReclaiming && (
            <Button
              dataTestId="delete-reclaiming-buttton"
              onClick={handleDelete}
              danger
              small
              outline
              type="button"
            >
              Excluir recuperação
            </Button>
          )}
          {(canEditFields || canUpdateFiles) && (
            <Button
              disabled={!formik.dirty}
              dataTestId="card-submit"
              onClick={formik.handleSubmit}
              highlight
              small
              type="submit"
            >
              Salvar
            </Button>
          )}
        </div>
      </div>

      <Form
        caseId={caseId}
        values={formik.values}
        errors={formik.errors}
        touched={formik.touched}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        onSubmit={formik.handleSubmit}
        setFieldTouched={formik.setFieldTouched}
        setFieldValue={formik.setFieldValue}
        reclaimingId={reclaiming?.id}
      />

      <AssetList
        caseId={caseId}
        reclaimingId={reclaiming?.id}
        reclaimingAssets={reclaimingData?.assets ?? []}
      />

      {canUser.showFiles && isUploadEnabled && (
        <FileField
          id={`reclaiming-${htmlId}-file-upload`}
          name="fileUploadIds"
          dataTestId="reclaiming-file-upload"
          caseId={caseId}
          title="Arquivos da recuperação"
          initialFiles={reclaiming?.fileUploads}
          onUploadSuccess={ids => {
            formik.setFieldValue('fileUploadIds', ids);
          }}
          showAdd={canUser.addFile}
          showDelete={canUser.deleteFile}
        />
      )}
    </Card>
  );
};

export default ReclaimingCard;
