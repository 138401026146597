import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  ReactElement
} from 'react';

import { fetchInstagramProfileBookmarkedPublications } from 'api';
import { openErrorToast } from 'ui';
import {
  InstagramProfile,
  InstagramPublication,
  PaginatedContent,
  ApiPagination,
  FIRST_PAGE,
  DEFAULT_PAGE_SIZE
} from 'types';

type State = {
  instagramProfile: InstagramProfile | null;
  setUnBookmarkPublication: (publicationId: string) => void;
  setBookmarkPublication: (publication: InstagramPublication) => void;
  setPaginatedPublications: (
    paginatedPublications: PaginatedContent<InstagramPublication[]>
  ) => void;
  setPaginatedFavoritedPublications: (
    paginatedFavoritedPublications: PaginatedContent<InstagramPublication[]>
  ) => void;
};

const InstagramProfileContext = createContext<State | undefined>(undefined);

type Props = {
  caseId: string;
  reportId: string;
  instagramProfile: InstagramProfile | null;
  children: ReactElement | ReactElement[];
};

const InstagramProfileProvider = ({
  children,
  instagramProfile,
  caseId,
  reportId
}: Props) => {
  const [
    instagramProfileState,
    setInstagramProfile
  ] = useState<InstagramProfile | null>(instagramProfile);

  useEffect(() => {
    setInstagramProfile(instagramProfile);
  }, [instagramProfile]);

  const togglePaginatedPublications = (publicationId: string) => {
    const toggledPaginatedPublications = instagramProfileState?.paginatedPublications?.data.map(
      publication => {
        if (publication.id === publicationId) {
          return { ...publication, isFavorite: !publication.isFavorite };
        }

        return publication;
      }
    );

    return toggledPaginatedPublications ?? [];
  };

  const buildToggleProfileState = (
    publicationId: string,
    toggledFavoritedPublications: InstagramPublication[],
    updatedPaginatedFavoritedPublicationsPagination: ApiPagination
  ) => {
    if (!instagramProfileState) return null;

    const toggledPaginatedPublications = togglePaginatedPublications(
      publicationId
    );

    const updatedInstagramProfile = {
      ...instagramProfileState!,
      paginatedPublications: {
        ...instagramProfileState.paginatedPublications!,
        data: toggledPaginatedPublications
      },
      paginatedFavoritedPublications: {
        ...instagramProfileState.paginatedFavoritedPublications!,
        pagination: updatedPaginatedFavoritedPublicationsPagination,
        data: toggledFavoritedPublications
      }
    };

    return updatedInstagramProfile;
  };

  const setUnBookmarkPublication = async (publicationId: string) => {
    const page =
      instagramProfileState?.paginatedFavoritedPublications?.pagination
        ?.number ?? FIRST_PAGE;

    const pageSize =
      instagramProfileState?.paginatedFavoritedPublications?.pagination.size ??
      DEFAULT_PAGE_SIZE;

    try {
      if (instagramProfile?.id) {
        const response = await fetchInstagramProfileBookmarkedPublications({
          caseId,
          reportId,
          instagramProfileId: instagramProfile?.id,
          pagination: {
            page: page,
            pageSize: pageSize
          }
        });

        const updatedInstagramProfile = buildToggleProfileState(
          publicationId,
          response.data,
          response.pagination
        );

        setInstagramProfile(updatedInstagramProfile);
      }
    } catch (e) {
      openErrorToast(
        'Ocorreu um erro ao listar as Postagens do Instagram salvas.'
      );
    }
  };

  const setBookmarkPublication = async (publication: InstagramPublication) => {
    const pageSize =
      instagramProfileState?.paginatedFavoritedPublications?.pagination.size ??
      DEFAULT_PAGE_SIZE;
    const page =
      instagramProfileState?.paginatedFavoritedPublications?.pagination
        .number ?? FIRST_PAGE;

    try {
      if (instagramProfile?.id) {
        const response = await fetchInstagramProfileBookmarkedPublications({
          caseId,
          reportId,
          instagramProfileId: instagramProfile?.id,
          pagination: {
            page: page,
            pageSize: pageSize
          }
        });

        const updatedInstagramProfile = buildToggleProfileState(
          publication.id,
          response.data,
          response.pagination
        );

        setInstagramProfile(updatedInstagramProfile);
      }
    } catch (e) {
      openErrorToast(
        'Ocorreu um erro ao listar as Postagens do Instagram salvas.'
      );
    }
  };

  const setPaginatedPublications = (
    paginatedPublications: PaginatedContent<InstagramPublication[]>
  ) => {
    const updatedInstagramProfile = {
      ...instagramProfileState!,
      paginatedPublications: paginatedPublications
    };

    setInstagramProfile(updatedInstagramProfile);
  };

  const setPaginatedFavoritedPublications = (
    paginatedFavoritedPublications: PaginatedContent<InstagramPublication[]>
  ) => {
    const updatedInstagramProfile = {
      ...instagramProfileState!,
      paginatedFavoritedPublications: paginatedFavoritedPublications
    };

    setInstagramProfile(updatedInstagramProfile);
  };

  return (
    <InstagramProfileContext.Provider
      value={{
        instagramProfile: instagramProfileState,
        setBookmarkPublication: setBookmarkPublication,
        setUnBookmarkPublication: setUnBookmarkPublication,
        setPaginatedPublications: setPaginatedPublications,
        setPaginatedFavoritedPublications: setPaginatedFavoritedPublications
      }}
    >
      {children}
    </InstagramProfileContext.Provider>
  );
};

const useInstagramProfile = () => {
  const context = useContext(InstagramProfileContext);

  if (context === undefined) {
    throw new Error(
      'useInstagramProfile must be used within a InstagramProfileProvider'
    );
  }

  return context;
};

export { useInstagramProfile, InstagramProfileProvider };
