import { ERROR_MESSAGE } from 'domain/reports';
import { mountReportAddress } from 'domain/reportLocation';
import { providerTableText, providerTooltipText } from 'domain/providers';
import React, { ReactElement, useState } from 'react';

import { can, Action } from 'api';
import { ErrorBoundary, ProviderTooltip } from 'components';
import { Toast, ToastType, Stack, StackMargin, CardInfo } from 'ui';

import {
  Report,
  Feature,
  Link,
  ReportExpectedTable,
  DivergentDataProvider,
  DivergentDataValue,
  ReportCompany,
  CardInfoItem,
  ReportAddress,
  ReportFields
} from 'types';

import { useFeatureFlag, useToggles } from 'hooks';

import {
  handleNullValue,
  handleEmptyArray,
  applyMask,
  handleMultipleProviders,
  reportFieldFirstValue
} from 'utils';

import { convertToLinkObj } from '../../utils';

import AlertDivergentData from '../../SharedComponents/AlertDivergentData';
import ModalDivergentDataProvider from '../../SharedComponents/BasicInfo/ModalDivergentDataProvider';
import AirplanesTable from '../../SharedComponents/SharedTables/AirplanesTable';
import RealEstateTable from '../../SharedComponents/SharedTables/RealEstateTable';
import RuralPropertyTable from '../../SharedComponents/SharedTables/RuralPropertyTable';
import VehiclesTable from '../../SharedComponents/SharedTables/VehiclesTable';
import ExternalLawsuits from '../ExternalLawsuit';
import { default as EditableFieldsForm } from '../Form';
import PartnersTable from '../../SharedComponents/CompanyTables/PartnersTable';
import RelatedCompaniesTable from '../../SharedComponents/CompanyTables/RelatedCompaniesTable';
import LocationsTable from '../../SharedComponents/CompanyTables/LocationsTable';
import BasicInfo from '../../SharedComponents/BasicInfo/BasicInfo';
import Cnaes from '../../SharedComponents/Cnaes/Cnaes';
import CitiesAndLocations from '../../SharedComponents/CitiesAndLocations/CitiesAndLocations';

import ModalPaginationItems from './ModalPaginationItems';

import style from './Company.module.scss';

const MAX_INFO_TO_SHOW = 1;

type Props = {
  report: Report;
  onUpdate: (_: Report) => void;
  triggerExternalLawsuitsQueries: () => Promise<void>;
  setReportData: (data: Report) => void;
};

const Company = ({
  onUpdate,
  report,
  triggerExternalLawsuitsQueries,
  setReportData
}: Props): ReactElement => {
  const [isExternalLawsuitsEnabled] = useFeatureFlag(Feature.EXTERNAL_LAWSUITS);
  const [divergentDataProvider, setDivergentDataProvider] = useState<
    DivergentDataProvider
  >();
  const { isOpen, toggle, toggleTrigger } = useToggles<
    'webSiteModal' | 'phoneModal' | 'divergentDataProviderModal'
  >({
    webSiteModal: false,
    phoneModal: false,
    divergentDataProviderModal: false
  });

  function openDivergentDataModal(
    label: string,
    divergentData: DivergentDataValue,
    isLink?: boolean
  ) {
    setDivergentDataProvider({
      label: label,
      divergentData: divergentData,
      isLink
    });
    toggleTrigger('divergentDataProviderModal');
  }

  const buildOverviewList = (
    links: string[],
    onClick: () => void,
    isLink = true
  ) => {
    const parsedElements = isLink ? convertToLinkObj(links) : links;

    if (parsedElements.length > MAX_INFO_TO_SHOW) {
      const splitedElements = [
        ...parsedElements.slice(0, MAX_INFO_TO_SHOW),
        {
          value: `ver todos (${parsedElements.length})`,
          underline: true,
          onClick: onClick
        }
      ];

      return splitedElements;
    }

    return parsedElements;
  };

  const mountAllReportAddress = (addresses: ReportFields<ReportAddress>[]) =>
    addresses
      .map(addr => ({
        fieldValue: mountReportAddress(addr.fieldValue),
        providers: addr.providers
      }))
      .reduce<ReportFields<string>[]>(
        (acc, current) => mergeEqualValues(acc, current),
        []
      );

  const mountAllPostalCodes = (
    addresses: ReportFields<ReportAddress>[]
  ): ReportFields<string>[] =>
    addresses
      .map(addr => ({
        fieldValue: applyMask('cep', addr.fieldValue.postalCode || ''),
        providers: addr.providers
      }))
      .reduce<ReportFields<string>[]>(
        (acc, current) => mergeEqualValues(acc, current),
        []
      );

  const mergeEqualValues = (
    acc: ReportFields<string>[],
    current: ReportFields<string>
  ) => {
    const equalItemIndex: number = acc.findIndex(
      item => item.fieldValue === current.fieldValue
    );

    if (equalItemIndex === -1)
      return [
        ...acc,
        {
          fieldValue: current.fieldValue,
          providers: current.providers
        }
      ];

    acc[equalItemIndex].providers = [
      ...acc[equalItemIndex].providers,
      ...current.providers
    ].sort((provider1, provider2) => provider2.localeCompare(provider1));

    return acc;
  };

  function makeCardInfoOtherInformations(company: ReportCompany | null) {
    const postalCodeFields = mountAllPostalCodes(company?.address || []);
    const addressFields = mountAllReportAddress(company?.address || []);

    const informationArray: Array<CardInfoItem> = [
      {
        label: 'CEP',
        value: handleNullValue(
          reportFieldFirstValue(postalCodeFields),
          'string',
          value => applyMask('cep', value)
        ),
        alert: (
          <AlertDivergentData
            divergentData={postalCodeFields}
            openDivergentDataModal={() =>
              openDivergentDataModal('CEP', postalCodeFields)
            }
          />
        ),
        tooltip:
          postalCodeFields.length > 0 ? (
            <ProviderTooltip
              providers={handleMultipleProviders(postalCodeFields)}
            />
          ) : null
      },
      {
        label: 'Endereço',
        value: handleNullValue(reportFieldFirstValue(addressFields)),
        alert: (
          <AlertDivergentData
            divergentData={addressFields}
            openDivergentDataModal={() =>
              openDivergentDataModal('Endereço', addressFields)
            }
          />
        ),
        tooltip:
          addressFields.length > 0 ? (
            <ProviderTooltip
              providers={handleMultipleProviders(company?.address)}
            />
          ) : null
      },
      {
        label: 'Websites',
        value: handleEmptyArray<Link | string>(
          buildOverviewList(
            reportFieldFirstValue(company?.websites) || [],
            toggle('webSiteModal')
          )
        ),
        alert: (
          <AlertDivergentData
            divergentData={company?.websites}
            openDivergentDataModal={() =>
              openDivergentDataModal('Website', company?.websites)
            }
          />
        ),
        tooltip: company?.websites ? (
          <ProviderTooltip
            providers={handleMultipleProviders(company?.websites)}
          />
        ) : null
      },
      {
        label: 'Email',
        value: handleNullValue(reportFieldFirstValue(company?.email)),
        alert: (
          <AlertDivergentData
            divergentData={company?.email}
            openDivergentDataModal={() =>
              openDivergentDataModal('Email', company?.email)
            }
          />
        ),
        tooltip: company?.email ? (
          <ProviderTooltip
            providers={handleMultipleProviders(company?.email)}
          />
        ) : null
      },
      {
        label: 'Telefones',
        value: handleEmptyArray<Link | string>(
          buildOverviewList(
            reportFieldFirstValue(company?.phones)
              ? reportFieldFirstValue(company?.phones)!.map(phone =>
                  applyMask('phone', phone)
                )
              : [],
            toggle('phoneModal'),
            false
          )
        ),
        alert: (
          <AlertDivergentData
            divergentData={company?.phones}
            openDivergentDataModal={() =>
              openDivergentDataModal('Telefones', company?.phones, false)
            }
          />
        ),
        tooltip: company?.phones ? (
          <ProviderTooltip
            providers={handleMultipleProviders(company?.phones)}
          />
        ) : null
      },
      {
        label: 'Descrição',
        value: handleNullValue(report?.entity?.description),
        tooltip: null
      }
    ];

    return informationArray;
  }

  const negotiatorId = report.caseNegotiatorId;

  const canUser = {
    editReport: can(Action.CASOS_RELATORIO_PFPJ_EDITAR, negotiatorId),
    seeMap: can(Action.CASOS_RELATORIO_PFPJ_MOSTRAR_MAPA, negotiatorId),
    addToCase: can(Action.CASOS_RELATORIO_PFPJ_ADICIONAR_AO_CASO, negotiatorId),
    research: can(Action.CASOS_RELATORIO_PFPJ_BUSCAR, negotiatorId)
  };

  return (
    <>
      <CitiesAndLocations
        title="Praças"
        caseId={report.caseId}
        reportId={report.id}
        showMap={canUser.seeMap}
      />

      {report.entity && (
        <EditableFieldsForm
          className={style.reportCompany}
          caseId={report.caseId}
          entity={report.entity}
          isDisabled={!canUser.editReport}
          onUpdate={entity =>
            onUpdate({
              ...report,
              ...(report!.entity ? { entity } : null)
            })
          }
        />
      )}

      <div className={style.reportCompany} data-testid="report-company">
        <div className={style.information}>
          <div className={style.col}>
            <BasicInfo
              report={report}
              providerStatus={providerTooltipText(
                report,
                ReportExpectedTable.COMPANY
              )}
            />
          </div>

          <div className={style.col}>
            <Stack marginBottom={StackMargin.XLARGE}>
              <CardInfo
                dataTestId="card-info-other-informations"
                title="Outras informações"
                tooltipText={providerTooltipText(
                  report,
                  ReportExpectedTable.COMPANY
                )}
                items={makeCardInfoOtherInformations(report.company)}
              />

              <ModalPaginationItems
                isOpen={isOpen.webSiteModal}
                onClose={toggle('webSiteModal')}
                title="Websites"
                listItems={
                  reportFieldFirstValue(report.company?.websites) || []
                }
              />

              <ModalPaginationItems
                isOpen={isOpen.phoneModal}
                onClose={toggle('phoneModal')}
                title="Telefones"
                listItems={(
                  reportFieldFirstValue(report.company?.phones) || []
                ).map(phone => applyMask('phone', phone))}
                isLink={false}
              />

              <ModalDivergentDataProvider
                isOpen={isOpen.divergentDataProviderModal}
                divergentDataProvider={divergentDataProvider}
                onClose={toggle('divergentDataProviderModal')}
              />
            </Stack>
            <Cnaes
              caseId={report.caseId}
              reportId={report.id}
              providerStatus={providerTooltipText(
                report,
                ReportExpectedTable.CNAE
              )}
            />
          </div>
        </div>

        <Stack marginTop={StackMargin.XLARGE}>
          <ErrorBoundary
            fallBackComponent={
              <Toast type={ToastType.ERROR} message={ERROR_MESSAGE.partners} />
            }
          >
            <PartnersTable
              reportId={report.id}
              caseId={report.caseId}
              addToCase={canUser.addToCase}
              research={canUser.research}
              providerStatus={providerTooltipText(
                report,
                ReportExpectedTable.PARTNER
              )}
              tableMessage={providerTableText(
                report,
                ReportExpectedTable.PARTNER
              )}
            />
          </ErrorBoundary>
        </Stack>

        <Stack marginTop={StackMargin.XLARGE}>
          <ErrorBoundary
            fallBackComponent={
              <Toast
                type={ToastType.ERROR}
                message={ERROR_MESSAGE.relatedCompanies}
              />
            }
          >
            <RelatedCompaniesTable
              caseId={report.caseId}
              reportId={report.id}
              addToCase={canUser.addToCase}
              research={canUser.research}
              providerStatus={providerTooltipText(
                report,
                ReportExpectedTable.RELATED_COMPANY
              )}
              tableMessage={providerTableText(
                report,
                ReportExpectedTable.RELATED_COMPANY
              )}
            />
          </ErrorBoundary>
        </Stack>

        <Stack marginTop={StackMargin.XLARGE}>
          <ErrorBoundary
            fallBackComponent={
              <Toast
                type={ToastType.ERROR}
                message={ERROR_MESSAGE.realEstate}
              />
            }
          >
            <RealEstateTable
              caseId={report.caseId}
              reportId={report.id}
              addToCase={canUser.addToCase}
              providerStatus={providerTooltipText(
                report,
                ReportExpectedTable.REAL_ESTATE
              )}
              tableMessage={providerTableText(
                report,
                ReportExpectedTable.REAL_ESTATE
              )}
            />
          </ErrorBoundary>
        </Stack>

        <Stack marginTop={StackMargin.XLARGE}>
          <ErrorBoundary
            fallBackComponent={
              <Toast type={ToastType.ERROR} message={ERROR_MESSAGE.vehicles} />
            }
          >
            <VehiclesTable
              caseId={report.caseId}
              reportId={report.id}
              addToCase={canUser.addToCase}
              providerStatus={providerTooltipText(
                report,
                ReportExpectedTable.VEHICLE
              )}
              tableMessage={providerTableText(
                report,
                ReportExpectedTable.VEHICLE
              )}
            />
          </ErrorBoundary>
        </Stack>

        <Stack marginTop={StackMargin.XLARGE}>
          <ErrorBoundary
            fallBackComponent={
              <Toast type={ToastType.ERROR} message={ERROR_MESSAGE.airplanes} />
            }
          >
            <AirplanesTable
              caseId={report.caseId}
              reportId={report.id}
              addToCase={canUser.addToCase}
              providerStatus={providerTooltipText(
                report,
                ReportExpectedTable.AIRPLANE
              )}
              tableMessage={providerTableText(
                report,
                ReportExpectedTable.AIRPLANE
              )}
            />
          </ErrorBoundary>
        </Stack>

        <Stack marginTop={StackMargin.XLARGE}>
          <ErrorBoundary
            fallBackComponent={
              <Toast
                type={ToastType.ERROR}
                message={ERROR_MESSAGE.ruralProperty}
              />
            }
          >
            <RuralPropertyTable
              caseId={report.caseId}
              reportId={report.id}
              addToCase={canUser.addToCase}
              providerStatus={providerTooltipText(
                report,
                ReportExpectedTable.RURAL_PROPERTY
              )}
              tableMessage={providerTableText(
                report,
                ReportExpectedTable.RURAL_PROPERTY
              )}
            />
          </ErrorBoundary>
        </Stack>

        <Stack marginTop={StackMargin.XLARGE}>
          <ErrorBoundary
            fallBackComponent={
              <Toast type={ToastType.ERROR} message={ERROR_MESSAGE.locations} />
            }
          >
            <LocationsTable
              caseId={report.caseId}
              reportId={report.id}
              addToCase={canUser.addToCase}
              research={canUser.research}
              providerStatus={providerTooltipText(
                report,
                ReportExpectedTable.LOCATION
              )}
              tableMessage={providerTableText(
                report,
                ReportExpectedTable.LOCATION
              )}
            />
          </ErrorBoundary>
        </Stack>

        {isExternalLawsuitsEnabled && (
          <Stack marginTop={StackMargin.XLARGE}>
            <ErrorBoundary
              fallBackComponent={
                <Toast
                  type={ToastType.ERROR}
                  message={ERROR_MESSAGE.externalLawsuits}
                />
              }
            >
              <ExternalLawsuits
                report={report}
                triggerExternalLawsuitsQueries={triggerExternalLawsuitsQueries}
                setReportData={setReportData}
                providerStatus={providerTooltipText(
                  report,
                  ReportExpectedTable.EXTERNAL_LAWSUIT
                )}
                defaultEmptyMessage={providerTableText(
                  report,
                  ReportExpectedTable.EXTERNAL_LAWSUIT
                )}
              />
            </ErrorBoundary>
          </Stack>
        )}
      </div>
    </>
  );
};

export default Company;
