import React, { ReactElement, ReactNode } from 'react';
import cn from 'classnames';

import style from './Sidebar.module.scss';

type Props = {
  children: ReactNode;
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
};

const Sidebar = ({
  children,
  isOpen = false,
  setOpen = () => {}
}: Props): ReactElement => {
  const classNames = cn(style.systemSidebar, {
    [style.open]: isOpen
  });

  const handleToggleMenu = () => setOpen(!isOpen);

  return (
    <>
      <ul
        className={classNames}
        data-testid="sidebar"
        onClick={handleToggleMenu}
      >
        {children}
      </ul>

      {isOpen && <div className={style.overlayer} onClick={handleToggleMenu} />}
    </>
  );
};

export default Sidebar;
