const CPF_VERIFIERS = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2];

const CNPJ_VERIFIERS = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

export function generateCPF(): string {
  const numbers = randomNumbers(9);
  const [firstDigit, secondDigit] = checkerDigits(numbers, 9, CPF_VERIFIERS);

  return [...numbers, firstDigit, secondDigit].join('');
}

export function generateCNPJ(): string {
  const numbers = randomNumbers(12);
  const [firstDigit, secondDigit] = checkerDigits(numbers, 12, CNPJ_VERIFIERS);

  return [...numbers, firstDigit, secondDigit].join('');
}

export function isValidCPF(cpf: string): boolean {
  if (!/^(\d{11}|\d{3}\.\d{3}\.\d{3}-\d{2})$/g.test(cpf)) {
    return false;
  }

  const numbers = toNumbers(cpf);

  if (allSame(numbers)) return false;

  return isValidCheckerDigits(numbers, 9, CPF_VERIFIERS);
}

export function isValidCNPJ(cnpj: string): boolean {
  if (!/^(\d{14}|\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2})$/g.test(cnpj)) {
    return false;
  }

  const numbers = toNumbers(cnpj);

  if (allSame(numbers)) return false;

  return isValidCheckerDigits(numbers, 12, CNPJ_VERIFIERS);
}

export function isValidCheckerDigits(
  numbers: number[],
  size: number,
  verifiers: number[]
): boolean {
  const [firstDigit, secondDigit] = checkerDigits(numbers, size, verifiers);

  return numbers[size] === firstDigit && numbers[size + 1] === secondDigit;
}

function allSame(numbers: number[]): boolean {
  return numbers.every(d => d === numbers[0]);
}

function toNumbers(value: string): number[] {
  return value
    .replace(/\D/g, '')
    .split('')
    .map(digit => parseInt(digit, 10));
}

function checkerDigits(
  numbers: Array<number>,
  size: number,
  verifiers: Array<number>
) {
  const firstDigitVerifiers = [...verifiers];
  firstDigitVerifiers.shift();

  const firstDigit = elevenMinus(
    numbers
      .slice(0, size)
      .map((value, index) => value * firstDigitVerifiers[index])
      .reduce((a, b) => a + b, 0) % 11
  );

  const secondDigit = elevenMinus(
    numbers
      .slice(0, size)
      .concat([firstDigit])
      .map((value, index) => value * verifiers[index])
      .reduce((a, b) => a + b, 0) % 11
  );

  return [firstDigit, secondDigit];
}

function elevenMinus(num: number): number {
  if (num < 2) return 0;
  return 11 - num;
}

function randomNumbers(amount: number): Array<number> {
  const numbers = [];

  for (let i = 0; i < amount; i++) {
    numbers.push(Math.floor(Math.random() * 10));
  }

  return numbers;
}
