import { DateTime } from 'luxon';
import React, { useState } from 'react';

import { fetchInstagramProfilePublications } from 'api';
import {
  PublicationBookmark,
  BookmarkPosition,
  useInstagramProfile
} from 'components';
import { useFeatureFlag } from 'hooks';
import {
  Feature,
  InstagramPublication,
  DEFAULT_PAGE_SIZE,
  FIRST_PAGE
} from 'types';
import { Accordion, Card, DynamicTable, Link, openErrorToast } from 'ui';
import { handleNullValue } from 'utils';

import style from './InstagramPublicationsTable.module.scss';

type Props = {
  caseId: string;
  reportId: string;
  canBookmarkPublication?: boolean;
};

const InstagramPublicationsTable = ({
  caseId,
  reportId,
  canBookmarkPublication = true
}: Props) => {
  const [isBookmarkInstagramPublicationEnabled] = useFeatureFlag(
    Feature.BOOKMARK_INSTAGRAM_PUBLICATION
  );
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const { instagramProfile, setPaginatedPublications } = useInstagramProfile();

  const paginatedPublications = instagramProfile?.paginatedPublications;
  const instagramProfileId = instagramProfile?.id;

  const onCurrentPageChange = async (newPage: number) => {
    if (!instagramProfileId) return;

    setLoading(true);

    try {
      const response = await fetchInstagramProfilePublications({
        caseId,
        reportId,
        instagramProfileId,
        pagination: {
          page: newPage,
          pageSize: paginatedPublications?.pagination!.size ?? DEFAULT_PAGE_SIZE
        }
      });

      setPaginatedPublications(response);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const onItemsPerPageChange = async (newPageSize: number) => {
    if (!instagramProfileId) return;

    setLoading(true);

    try {
      const response = await fetchInstagramProfilePublications({
        caseId,
        reportId,
        instagramProfileId,
        pagination: {
          page: paginatedPublications?.pagination!.number ?? FIRST_PAGE,
          pageSize: newPageSize
        }
      });

      setPaginatedPublications(response);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  if (error)
    openErrorToast('Ocorreu um erro ao listar as publicações do Instagram.');

  const columns = [
    {
      name: 'Post',
      columnKey: 'publication',
      render: (item: InstagramPublication) => (
        <div className={style.publicationLinkContainer}>
          <Link
            asButton
            social
            small
            external
            iconName="instagram"
            href={item.url}
          >
            {`Post ${DateTime.fromISO(item.createdAt).toFormat('dd/MM/yy T')}`}
          </Link>
          {canBookmarkPublication && isBookmarkInstagramPublicationEnabled && (
            <PublicationBookmark
              caseId={caseId}
              instagramPublication={item}
              reportId={reportId}
              position={BookmarkPosition.right}
            />
          )}
        </div>
      )
    },
    {
      name: 'Localização',
      columnKey: 'location',
      render: (item: InstagramPublication) =>
        handleNullValue(item.instagramLocation?.displayName)
    },
    {
      name: 'Perfis marcados na postagem',
      columnKey: 'mentionedProfiles',
      render: (item: InstagramPublication) =>
        item.mentionedProfiles.length !== 0 ? (
          <>
            {item.mentionedProfiles?.map((profile, index) => [
              index > 0 && ', ',
              <Link
                highlight
                underline
                external
                href={profile.url}
                key={profile.id}
              >
                @{profile.socialName}
              </Link>
            ])}
          </>
        ) : (
          '-'
        )
    }
  ];

  const rowKey = (item: InstagramPublication) => item.id;

  const dynamicTablePagination = paginatedPublications?.pagination && {
    currentPage: paginatedPublications?.pagination.number,
    itemsPerPage: paginatedPublications?.pagination.size,
    hidePagination:
      (paginatedPublications?.pagination.totalEntries ?? 0) <=
      DEFAULT_PAGE_SIZE,
    totalEntries: paginatedPublications?.pagination.totalEntries,
    totalPages: paginatedPublications?.pagination.totalPages,
    onCurrentPageChange,
    onItemsPerPageChange
  };

  return (
    <Accordion
      title="Postagens no Instagram"
      badgeContent={paginatedPublications?.pagination?.totalEntries}
    >
      <Card small>
        <DynamicTable
          columns={columns}
          data={paginatedPublications?.data ?? []}
          rowKey={rowKey}
          pagination={dynamicTablePagination && dynamicTablePagination}
          noAutoPaginate
          isLoading={isLoading}
          dataTestId="instagram-publications-table"
        />
      </Card>
    </Accordion>
  );
};

export default InstagramPublicationsTable;
