import {
  CreditContract,
  CreditContractParams,
  CreditContractFormAttributes
} from 'types';
import Client from './client';

function createCreditContract(
  creditContractData: CreditContractParams,
  caseId: string
) {
  return Client.post<CreditContractParams, CreditContract>(
    `cases/${caseId}/contracts`,
    creditContractData
  );
}

function updateCreditContract(
  creditContractData: CreditContractParams,
  caseId: string,
  id: string
) {
  return Client.put<CreditContractParams, CreditContract>(
    `cases/${caseId}/contracts/${id}`,
    creditContractData
  );
}

function fetchCreditContracts({ caseId }: { caseId: string }) {
  return Client.get<CreditContract[]>(`cases/${caseId}/contracts`);
}

function deleteCreditContract(caseId: string, id: string) {
  return Client.delete(`cases/${caseId}/contracts/${id}`);
}

function fetchCreditContract({
  caseId,
  contractId
}: {
  caseId: string;
  contractId: string;
}) {
  return Client.get<CreditContract>(`cases/${caseId}/contracts/${contractId}`);
}

function submitCreditContract(
  values: CreditContractFormAttributes,
  caseId: string,
  creditContract?: CreditContract
) {
  if (creditContract)
    return updateCreditContract(values, caseId, creditContract!.id);

  return createCreditContract(values, caseId);
}

export {
  createCreditContract,
  fetchCreditContracts,
  fetchCreditContract,
  deleteCreditContract,
  updateCreditContract,
  submitCreditContract
};
