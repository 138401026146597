import { lawsuitNumber } from 'domain/lawsuits';
import React, { useEffect, useRef, useState, useContext } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { ChildLawsuitAttributes, LocationState } from 'types';
import { FormikHelpers, FormikProps } from 'formik';
import { CaseContext } from 'components';
import { fetchInternalLawsuit, createChildLawsuit, can, Action } from 'api';

import Layout from 'Layouts/Layout';
import {
  Button,
  Header,
  Link,
  openErrorToast,
  openSuccessToast,
  Title
} from 'ui';
import Form from '../Form';

import style from './Create.module.scss';

type MatchParams = {
  caseId: string;
  internalLawsuitId: string;
};

const Create = () => {
  const history = useHistory();
  const location = useLocation<LocationState>();

  const {
    params: { caseId, internalLawsuitId }
  } = useRouteMatch<MatchParams>();

  const [isWaitingResponse, setWaitingResponse] = useState<boolean>(false);
  const [parentLawsuitNumber, setParentLawsuitNumber] = useState(
    location.state?.parentLawsuitNumber
  );
  const formRef = useRef<FormikProps<ChildLawsuitAttributes>>(null);

  const getParentLawsuitNumber = async () => {
    if (!parentLawsuitNumber) {
      try {
        const response = await fetchInternalLawsuit({
          caseId,
          lawsuitId: internalLawsuitId
        });
        setParentLawsuitNumber(response.data.number);
      } catch (error) {
        openErrorToast('Houve um erro ao listar o número do processo.');
      }
    }
  };

  useEffect(() => {
    getParentLawsuitNumber();
  });

  const handleSubmit = () => {
    if (formRef.current) formRef.current.handleSubmit();
  };

  const onSubmit = async (
    values: ChildLawsuitAttributes,
    formikHelpers: FormikHelpers<ChildLawsuitAttributes>
  ) => {
    try {
      const response = await createChildLawsuit(
        caseId,
        internalLawsuitId,
        values
      );
      openSuccessToast('Processo cadastrado com sucesso!');

      if (canUser.showChildLawsuit) {
        history.push(
          `/cases/${caseId}/internal_lawsuits/${internalLawsuitId}/children/${response.data.id}`,
          { parentLawsuitNumber: parentLawsuitNumber }
        );
      } else {
        history.push(
          `/cases/${caseId}/internal_lawsuits/${internalLawsuitId}`,
          { parentLawsuitNumber: parentLawsuitNumber }
        );
      }
    } catch (error) {
      formikHelpers.setErrors(error.errors);
      openErrorToast('Erro ao cadastrar um novo processo!');
    }
  };

  const { caseData } = useContext(CaseContext);
  const negotiatorId = caseData?.negotiator?.id;
  const canUser = {
    showChildLawsuit: can(
      Action.CASOS_PROCESSO_INFORMACOES_BASICAS_VER_PROCESSO,
      negotiatorId
    )
  };

  return (
    <Layout scrollTopOnMount>
      <Header>
        <Link
          iconName="arrow-left"
          to={`/cases/${caseId}/internal_lawsuits/${internalLawsuitId}`}
        >
          Voltar
        </Link>
        <Title>{lawsuitNumber(parentLawsuitNumber)}</Title>
      </Header>
      <Header
        className={style.header}
        sticky
        actionsComponent={
          <Button
            type="submit"
            highlight
            small
            disabled={isWaitingResponse}
            onClick={handleSubmit}
          >
            Salvar
          </Button>
        }
      >
        <Title className={style.title}>Novo processo vinculado</Title>
      </Header>
      <div className={style.content}>
        <Form
          caseId={caseId}
          formRef={formRef}
          onSubmit={onSubmit}
          isWaitingResponse={isWaitingResponse}
          setWaitingResponse={setWaitingResponse}
        />
      </div>
    </Layout>
  );
};

export default Create;
