import React from 'react';
import cn from 'classnames';

import { Icon, Loader } from 'ui';
import { InternalLawsuit } from 'types';

import style from '../LawsuitsList.module.scss';

import InternalLawsuitCard from './InternalLawsuitCard/InternalLawsuitCard';

type Props = {
  title: string;
  items?: Array<InternalLawsuit>;
  iconName: string;
  caseId: string;
  isLoading?: boolean;
};

const InternalLawsuitsList = ({
  caseId,
  iconName,
  isLoading,
  items = [],
  title
}: Props) => {
  const isArrayEmpty = !items.length;
  return (
    <div className={style.lawsuitsList} data-testid={`list-${title}`}>
      <div className={style.header} data-testid={`icon-${iconName}`}>
        <Icon name={iconName} fill="#62667A" small />
        <h3>{title}</h3>
      </div>

      <div
        className={cn([
          style.cardsWrapper,
          (isArrayEmpty || isLoading) && style.empty
        ])}
      >
        {isLoading ? (
          <Loader width={2.56} />
        ) : isArrayEmpty ? (
          <>Não há processos {title.toLocaleLowerCase()}</>
        ) : (
          items.map(item => (
            <InternalLawsuitCard
              caseId={caseId}
              key={`card-item-${item.id}`}
              lawsuit={item}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default InternalLawsuitsList;
