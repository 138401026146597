import { checkProviderStatus } from 'domain/providers';
import React, { useState, useEffect } from 'react';
import { Accordion, Button, openErrorToast } from 'ui';
import {
  ApiPagination,
  ExternalLawsuit as ExternalLawsuitType,
  Report,
  ExternalLawsuitIndication,
  Feature,
  ProviderName,
  QueryStatus,
  LegalReportOrderStatus
} from 'types';
import { can, Action } from 'api';

import { fetchReportExternalLawsuits } from 'api/externalLawsuits';

import { ExternalLawsuitCardLoader, ExternalLawsuitList } from 'components';
import { useHashAnchor, useFeatureFlag } from 'hooks';

import style from './ExternalLawsuit.module.scss';
import ExternalLawsuitHeader from './ExternalLawsuitHeader/ExternalLawsuitHeader';

const INITIAL_PAGE = 1;

type Props = {
  report: Report;
  triggerExternalLawsuitsQueries: () => Promise<void>;
  setReportData: (data: Report) => void;
  providerStatus?: string;
  defaultEmptyMessage?: string;
};

type PaginatedExternalLawsuits = {
  data: ExternalLawsuitType[];
  pagination: ApiPagination;
};

const ExternalLawsuit = ({
  report,
  triggerExternalLawsuitsQueries,
  setReportData,
  providerStatus,
  defaultEmptyMessage
}: Props) => {
  const [externalLawsuits, setExternalLawsuits] = useState<
    PaginatedExternalLawsuits
  >(report.paginatedLawsuits);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isCurrentLegalReportOrderedOrPending =
    report.currentLegalReportOrder?.status === LegalReportOrderStatus.ORDERED ||
    report.currentLegalReportOrder?.status === LegalReportOrderStatus.PENDING;
  const [isLegalReportLoading, setIsLegalReportLoading] = useState<boolean>(
    isCurrentLegalReportOrderedOrPending
  );
  const [indicationFilter, setIndicationFilter] = useState<
    ExternalLawsuitIndication | undefined
  >();
  const [isNeowayExternalLawsuitEnabled] = useFeatureFlag(
    Feature.NEOWAY_EXTERNAL_LAWSUITS
  );
  const [isLegalReportsEnabled] = useFeatureFlag(Feature.LEGAL_REPORTS);
  const [emptyMessage, setEmptyMessage] = useState(defaultEmptyMessage);

  useEffect(() => {
    setExternalLawsuits(report.paginatedLawsuits);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report.paginatedLawsuits.pagination.totalEntries]);

  useEffect(() => {
    if (
      report.currentLegalReportOrder?.status ===
        LegalReportOrderStatus.FINISHED ||
      report.currentLegalReportOrder?.status ===
        LegalReportOrderStatus.SUSPENDED
    ) {
      setIsLegalReportLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report.currentLegalReportOrder?.status]);

  useEffect(() => {
    indicationFilter !== undefined &&
      getReportExternalLawsuits(
        INITIAL_PAGE,
        externalLawsuits.pagination.size,
        indicationFilter
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indicationFilter]);

  useHashAnchor();

  const getReportExternalLawsuits = async (
    page: number,
    pageSize: number,
    indication?: ExternalLawsuitIndication
  ) => {
    const { caseId, id: reportId } = report;

    setIsLoading(true);

    try {
      const { data, pagination } = await fetchReportExternalLawsuits({
        caseId,
        reportId,
        pagination: {
          page,
          pageSize
        },
        indication
      });

      const message = !data.length
        ? 'Nenhum dado encontrado com este filtro'
        : defaultEmptyMessage;

      setEmptyMessage(message);
      setExternalLawsuits({ data, pagination: pagination! });
    } catch (error) {
      openErrorToast('Não foi possível listar os processos.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setIndicationFilter(event.target.value as ExternalLawsuitIndication);
  };

  const hasNeowaySucceeded = checkProviderStatus({
    report,
    providerName: ProviderName.NEOWAY,
    status: QueryStatus.SUCCEEDED
  });

  const hasDigestoSucceeded = checkProviderStatus({
    report,
    providerName: ProviderName.DIGESTO,
    status: QueryStatus.SUCCEEDED
  });

  const negotiatorId = report?.caseNegotiatorId;

  const canUser = {
    accessExternalLawsuit: can(
      Action.CASOS_RELATORIO_PFPJ_VER_PROCESSO,
      negotiatorId
    )
  };

  const hasProvidersSucceeded = hasNeowaySucceeded && hasDigestoSucceeded;

  return (
    <Accordion
      title="Processos"
      badgeContent={externalLawsuits.pagination.totalEntries.toString()}
      tooltipText={providerStatus}
      id="externalLawsuits"
      headerActions={
        isNeowayExternalLawsuitEnabled &&
        hasNeowaySucceeded && (
          <Button
            disabled={report.hasExternalLawsuitsQueries}
            small
            outline
            onClick={triggerExternalLawsuitsQueries}
          >
            Buscar processos judiciais
          </Button>
        )
      }
    >
      <>
        <ExternalLawsuitHeader
          report={report}
          paginatedLawsuits={report.paginatedLawsuits}
          handleFilterChange={handleFilterChange}
          hasProvidersSucceeded={hasProvidersSucceeded}
          indicationFilterValue={indicationFilter}
          isLegalReportsEnabled={isLegalReportsEnabled}
          setIsLegalReportLoading={setIsLegalReportLoading}
          setReportData={setReportData}
          isLegalReportLoading={isLegalReportLoading}
        />
        {isLegalReportLoading && (
          <div
            className={style.loadingWrapper}
            data-testid="external-lawsuit-card-loader"
          >
            <ExternalLawsuitCardLoader />
            <ExternalLawsuitCardLoader />
          </div>
        )}
        {(externalLawsuits.data.length || isLoading) && (
          <ExternalLawsuitList
            data={externalLawsuits}
            fetchExternalLawsuits={getReportExternalLawsuits}
            isLoading={isLoading}
            hideTitleLink={!canUser.accessExternalLawsuit}
          />
        )}

        {!isLoading &&
          !isLegalReportLoading &&
          !externalLawsuits.data.length && (
            <div className={style.emptyText}>{emptyMessage}</div>
          )}
      </>
    </Accordion>
  );
};

export default ExternalLawsuit;
