import { handleDateFieldChange } from 'domain/forms';
import React from 'react';
import { getIn, FormikTouched, FormikErrors, FormikHandlers } from 'formik';
import { DateTime } from 'luxon';

import { ReclaimingFormAttributes, Payment } from 'types';

import { InputField, Button, FormContainer, Link, DatePickerField } from 'ui';
import styles from './RecurrentPayments.module.scss';

type Props = {
  errors: FormikErrors<ReclaimingFormAttributes>;
  onBlur: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChange: FormikHandlers['handleChange'];
  setFieldTouched: (field: string, value: boolean) => void;
  setFieldValue: (field: string, value: string | null | Payment[]) => void;
  touched: FormikTouched<ReclaimingFormAttributes>;
  values: ReclaimingFormAttributes;
  setSingleTotalAmount: (amount: number) => void;
  singleTotalAmount: number;
};

const RecurrentPayments = ({
  onChange,
  onBlur,
  values,
  setFieldValue,
  setFieldTouched,
  touched,
  errors,
  setSingleTotalAmount,
  singleTotalAmount
}: Props) => {
  const handleRemoveRecurrentPayment = (index: number) => {
    const payments: Payment[] = values.payments;
    const paymentsFiltered: Payment[] = payments.filter(
      (_payment: Payment, paymentIndex: number) => paymentIndex !== index
    );
    const resultDecreaseTotalAmount =
      singleTotalAmount - Number(values.payments[index].amount || 0);

    setSingleTotalAmount(resultDecreaseTotalAmount);
    setFieldValue('payments', paymentsFiltered);
    setFieldTouched(`payments[${index}].amount`, false);
    setFieldTouched(`payments[${index}].date`, false);
  };

  const addNewRecurrentPayment = (): void => {
    setFieldValue(`payments[${values.payments.length}].amount`, '');
    setFieldValue(`payments[${values.payments.length}].date`, '');
  };

  const handleOnblur = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const singleToalAmountSum: number = values.payments.reduce(
      (accumulator: number, payment: Payment): number => {
        return accumulator + Number(payment.amount);
      },
      0
    );

    setSingleTotalAmount(singleToalAmountSum);
    onBlur(event);
  };

  return (
    <FormContainer well noPadding title="Pagamentos recorrentes">
      <div className={styles.recurrentPaymentsContent}>
        {values.payments.map(
          (payment: Payment, index: number): React.ReactElement => (
            <div
              data-testid="recurrent-payment-row"
              className={styles.row}
              key={`RecorrentPayments-${index}`}
            >
              <InputField
                name={`payments[${index}].amount`}
                type="currency"
                id="reclaiming-payments-amount"
                value={payment.amount}
                error={
                  getIn(touched, `payments[${index}].amount`) &&
                  getIn(errors, `payments[${index}].amount`)
                }
                title="Valor"
                onBlur={handleOnblur}
                onChange={onChange}
                dataTestId="recurrent-payments-amount"
              />

              <DatePickerField
                id="reclaiming-payments-date"
                name={`payments[${index}].date`}
                title="Data"
                placeholder="Selecione..."
                value={payment.date}
                onChange={handleDateFieldChange(
                  setFieldValue,
                  setFieldTouched,
                  `payments[${index}].date`
                )}
                onBlur={handleOnblur}
                max={DateTime.local().toISO()}
                error={
                  getIn(touched, `payments[${index}].date`) &&
                  getIn(errors, `payments[${index}].date`)
                }
              />

              <div className={styles.remove}>
                <Link
                  onClick={() => handleRemoveRecurrentPayment(index)}
                  iconName="close"
                  dataTestId="remove-recurrent-payments"
                />
              </div>
            </div>
          )
        )}

        <Button
          dataTestId="add-new-recurrent-payment"
          onClick={addNewRecurrentPayment}
          highlight
          outline
          centered
        >
          Adicionar pagamento
        </Button>
      </div>
    </FormContainer>
  );
};

export default RecurrentPayments;
