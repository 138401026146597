import React, { useState } from 'react';
import cn from 'classnames';
import { Pagination, Stack, StackMargin } from 'ui';
import { ItemsPerPageOptions, PaginatedResource, PaginationProps } from 'types';
import Loader from 'ui/Loader/Loader';
import { createArrayFrom } from 'utils/createArrayFrom';
import { usePagination } from 'hooks/usePagination';
import ToolTip from 'ui/ToolTip/ToolTip';
import Cell, { TablePositionModifier } from './Cell';
import style from './DynamicTable.module.scss';
import CellContent from './CellContent';
import { OrderByWithContext } from './OrderByWithContext';

type Column<T> = {
  positionModifier?: TablePositionModifier;
  name: string;
  columnKey: string;
  render: (data: T) => JSX.Element | string | number | null | undefined;
  hasActions?: boolean;
  shouldSkipCollapse?: boolean;
  className?: (data: T) => string;
  dataTestId?: string;
  onClick?: (data: T) => void;
  hasOrderBy?: boolean;
};

type Props<T> = {
  areRowsClickable?: boolean;
  columns: Column<T>[];
  data: T[];
  initialItemsPerPage?: ItemsPerPageOptions;
  isCollapsible?: boolean;
  isLoading?: boolean;
  pagination?: PaginationProps;
  noAutoPaginate?: boolean;
  rowKey: (item: T) => string;
  emptyText?: string;
  dataTestId?: string;
  tableName?: string;
  tableClassName?: string;
  paginatedResource?: PaginatedResource;
  getRowClassName?: (item: T) => string;
  rowTooltipText?: (item: T) => string;
};

const DynamicTable = <T extends unknown>({
  areRowsClickable,
  columns,
  data,
  initialItemsPerPage = 10,
  isCollapsible,
  isLoading,
  pagination,
  noAutoPaginate,
  rowKey,
  emptyText = 'Nenhum dado encontrado',
  dataTestId,
  tableName,
  tableClassName,
  paginatedResource,
  getRowClassName = () => '',
  rowTooltipText = () => ''
}: Props<T>): JSX.Element => {
  const { data: stateData, paginationProps: statePagination } = usePagination({
    dataToPaginate: data,
    initialItemsPerPage
  });

  const [columnToExpand, setColumnToExpand] = useState<number | null>(null);

  const paginationProps = pagination ?? statePagination;
  const paginatedData = noAutoPaginate ? data : stateData;

  const maxLoaderSize = 1024 / (columns.length || 1);
  const loaderSize = Math.min(maxLoaderSize, 96) / 100;

  const loadingSkeleton = () =>
    createArrayFrom({
      size: initialItemsPerPage,
      fillFunction: (key: number) => (
        <tr className={style.row} key={key}>
          {columns.map((_, i) => (
            <Cell key={i}>
              <Loader width={loaderSize} />
            </Cell>
          ))}
        </tr>
      )
    });

  return (
    <>
      <table
        className={cn(style.dynamicTable, tableClassName)}
        cellSpacing="0"
        data-testid={dataTestId}
      >
        <thead>
          <tr data-js={`head-${tableName}`}>
            {columns.map(({ name, columnKey, className, hasOrderBy }) => (
              <Cell key={columnKey} className={cn(className)} isHeader>
                {hasOrderBy && paginatedResource ? (
                  <OrderByWithContext
                    resource={paginatedResource}
                    field={columnKey}
                  >
                    {name}
                  </OrderByWithContext>
                ) : (
                  name
                )}
              </Cell>
            ))}
          </tr>
        </thead>
        <tbody className={style.body}>
          {isLoading
            ? loadingSkeleton()
            : paginatedData.map((item, index) => (
                <ToolTip
                  key={index}
                  content={rowTooltipText(item)}
                  placement="top"
                  data-testid="history-tooltip"
                >
                  <tr
                    key={index}
                    data-js={`row-${rowKey(item)}`}
                    data-testid={`row-${rowKey(item)}`}
                    className={cn([
                      style.row,
                      getRowClassName(item),
                      areRowsClickable && style.rowClickable
                    ])}
                  >
                    {columns.map((column, i) => {
                      const {
                        columnKey,
                        hasActions,
                        positionModifier,
                        render,
                        shouldSkipCollapse
                      } = column;

                      const collapsible =
                        isCollapsible && !shouldSkipCollapse
                          ? {
                              isExpanded: i === columnToExpand,
                              onCollapsibleCallback: () =>
                                setColumnToExpand(
                                  i !== columnToExpand ? i : null
                                )
                            }
                          : undefined;

                      const handleClick = () =>
                        column.onClick && column.onClick(item);

                      return (
                        <Cell
                          positionModifier={positionModifier}
                          key={columnKey}
                          collapsible={collapsible}
                          className={column.className && column.className(item)}
                          dataTestId={column.dataTestId}
                          onClick={handleClick}
                        >
                          <CellContent hasActions={hasActions}>
                            {render(item)}
                          </CellContent>
                        </Cell>
                      );
                    })}
                  </tr>
                </ToolTip>
              ))}

          {!isLoading && !paginatedData.length && (
            <tr className={style.row}>
              <Cell colSpan={columns.length} className={style.emptyText}>
                {emptyText}
              </Cell>
            </tr>
          )}
        </tbody>
      </table>
      {(!noAutoPaginate || pagination) && (
        <Stack marginTop={StackMargin.SMALL}>
          <Pagination {...paginationProps} />
        </Stack>
      )}
    </>
  );
};

export default DynamicTable;
