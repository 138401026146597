import React from 'react';
import { fetchNotifications } from 'api';
import { useQuery } from 'hooks';
import { openErrorToast, Pagination } from 'ui';

import Item from './Item';
import style from './ListWindow.module.scss';
import NotificationLoader from './NotificationLoader';

const INITIAL_PAGE = 1;
const PAGE_SIZE = 5;

type Props = {
  caseId?: string;
  closeNotificaton: () => void;
};

const PaginatedItems = ({ caseId, closeNotificaton }: Props) => {
  const { data, pagination, refetch, isLoading, error } = useQuery(
    fetchNotifications,
    {
      caseId,
      pagination: { page: INITIAL_PAGE, pageSize: PAGE_SIZE }
    }
  );

  const isEmpty = !isLoading && data?.length === 0;

  if (error) {
    openErrorToast('Erro ao listar notificações');
  }

  return (
    <div>
      {isLoading && <NotificationLoader />}

      {isEmpty && (
        <p className={style.emptyState}>
          {caseId
            ? 'Não há nenhuma notificação neste caso'
            : 'Não há nenhuma notificação'}
        </p>
      )}

      {!isLoading &&
        data &&
        data?.map((notification, index) => {
          return (
            <Item
              notification={notification}
              key={index}
              showCasePrefix={!caseId}
              closeNotificaton={closeNotificaton}
            />
          );
        })}

      {!isEmpty && pagination && (
        <div className={style.pagination}>
          <Pagination
            currentPage={pagination.number}
            itemsPerPage={pagination.size}
            onCurrentPageChange={page => {
              refetch({
                caseId,
                pagination: { page, pageSize: pagination.size }
              });
            }}
            onItemsPerPageChange={pageSize => {
              refetch({
                caseId,
                pagination: { page: pagination.number, pageSize: pageSize }
              });
            }}
            totalEntries={pagination.totalEntries}
            totalPages={pagination.totalPages}
          />
        </div>
      )}
    </div>
  );
};

export default PaginatedItems;
