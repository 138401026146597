import * as Reports from 'domain/reports';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Layout from 'Layouts/Layout';
import {
  Header,
  Link,
  Title,
  Button,
  openErrorToast,
  openSuccessToast,
  ToolTip,
  Loading,
  Modal
} from 'ui';
import {
  useLiveReport,
  useQuery,
  useQueryCall,
  useToggles,
  useFeatureFlag
} from 'hooks';
import {
  createEntityFromReport,
  refreshReport,
  fetchReport,
  createReportExternalLawsuitQueries,
  retryReportFailedQueries,
  fetchEntityRelationships,
  updateEntityDescription,
  deleteEntity,
  can,
  Action
} from 'api';
import {
  CaseEntity,
  EntityAttributes,
  EntityType,
  EntityWithRelations,
  Feature,
  Report,
  Status
} from 'types';

import { reportFieldFirstValue } from 'utils';
import {
  ReportStatusWithModal,
  OriginModal,
  InstagramProfileProvider
} from 'components';
import {
  default as SuspectsForm,
  FormType
} from 'components/SuspectsForm/Form';
import SummaryForm from '../Summary/Form';
import Company from './Company';
import Individual from './Individual';

import './Show.scss';

type Props = {
  caseId: string;
  reportId: string;
};

const syntheticEntity = (data: Report): EntityAttributes => {
  const isAbroad = false;

  if (data.type === EntityType.INDIVIDUAL) {
    return {
      type: EntityType.INDIVIDUAL,
      description: '',
      company: null,
      individual: {
        name: reportFieldFirstValue(data.individual?.name),
        cpf: reportFieldFirstValue(data.individual?.cpf),
        isAbroad
      }
    } as EntityAttributes;
  }

  return {
    type: EntityType.COMPANY,
    description: '',
    company: {
      cnpj: reportFieldFirstValue(data.company?.cnpj),
      businessName: reportFieldFirstValue(data.company?.businessName),
      isAbroad
    },
    individual: null
  } as EntityAttributes;
};

const Show = ({ caseId, reportId }: Props) => {
  const history = useHistory();
  const [isSummaryEnabled] = useFeatureFlag(Feature.REPORT_SUMMARY);

  const { isOpen, toggle, closeAll } = useToggles<
    'originModal' | 'addToCaseModal' | 'summary'
  >({
    originModal: false,
    addToCaseModal: false,
    summary: false
  });

  const { data, error, refetch, setData } = useQuery<
    Report,
    { caseId: string; reportId: string }
  >(fetchReport, {
    caseId,
    reportId
  });

  const {
    data: entity,
    error: entityError,
    isLoading,
    request: getRelationships
  } = useQueryCall<EntityWithRelations, { caseId: string; entityId: string }>(
    fetchEntityRelationships
  );

  useEffect(() => {
    if (data?.entity) {
      getRelationships({
        caseId: data!.caseId,
        entityId: data!.entity!.id
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useLiveReport(caseId, reportId, () => refetch({ caseId, reportId }));

  if (error) {
    openErrorToast('Erro ao carregar o Relatório.');
  }

  const handleUpdate = (report: Report) =>
    setData({
      ...data,
      ...report
    });

  const handleAddToCase = async ({
    caseId,
    reportId,
    description
  }: {
    caseId: string;
    reportId: string;
    description: string;
  }) => {
    const response = await createEntityFromReport(caseId, reportId);

    await updateEntityDescription({
      caseId,
      entityId: response.data.id,
      data: { description }
    });

    return { ...response, data: { ...response.data, description } };
  };

  const handleAddSuccessToCase = (entity: CaseEntity) => {
    openSuccessToast('Suspeito adicionado com sucesso!');
    setData(
      report =>
        report && {
          ...report,
          entity
        }
    );
    return closeAll();
  };

  const handleAddErrorToCase = () => {
    openErrorToast('Não foi possível adicionar à lista de suspeitos.');
  };

  const handleRemoveFromCase = (entityId: string) => async () => {
    if (!window.confirm('Deseja realmente remover este suspeito?')) return;
    try {
      await deleteEntity(caseId, entityId);
      openSuccessToast('Removido do caso com sucesso!');
      return history.push(`/cases/${caseId}/resources`);
    } catch {
      openErrorToast('Não foi possível remover o suspeito do caso.');
    }
  };

  const handleRefreshReport = async () => {
    try {
      const { data: report } = await refreshReport(caseId, reportId);
      openSuccessToast(
        'Atualizando relatório! Aguarde o término para visualizá-lo.'
      );
      history.push(`/cases/${report.caseId}/reports/${report.id}`);
    } catch (error) {
      openErrorToast('Não foi possível atualizar o relatório.');
    }
  };

  const handleRetryFailedQueries = async () => {
    try {
      await retryReportFailedQueries(caseId, reportId);
      openSuccessToast(
        'Refazendo consultas que falharam! Aguarde o término para visualizá-las.'
      );
    } catch (error) {
      openErrorToast('Não foi possível refazer as consultas.');
    }
  };

  const triggerExternalLawsuitsQueries = async () => {
    if (!data) return;
    if (data.hasExternalLawsuitsQueries) return;

    if (
      !window.confirm(
        'Atenção, ao gerar uma busca por processos judiciais, os custos podem ser muito altos. Deseja continuar?'
      )
    )
      return;

    try {
      const response = await createReportExternalLawsuitQueries({
        caseId: data.caseId,
        reportId: data.id
      });

      setData({
        ...data,
        hasExternalLawsuitsQueries: response.data.hasExternalLawsuitsQueries
      });

      openSuccessToast(
        'Os processos judiciais estão sendo buscados, em instantes o relatório será atualizado.'
      );
    } catch {
      openErrorToast('Não foi possível buscar os processos judiciais.');
    }
  };

  const handleOriginModal = (hasError: boolean) => {
    if (!hasError) return toggle('originModal');

    return () =>
      openErrorToast(
        'Erro ao carregar informações, recarregue a página e tente novamente.'
      );
  };

  const handleRedirectSummary = (path: string) => {
    closeAll();
    return history.push(path);
  };

  const hasOrigin = !!data?.entity;

  const negotiatorId = data?.caseNegotiatorId;

  const canUser = {
    addToCase: can(Action.CASOS_RELATORIO_PFPJ_ADICIONAR_AO_CASO, negotiatorId),
    removeFromCase: can(
      Action.CASOS_RELATORIO_PFPJ_REMOVER_DO_CASO,
      negotiatorId
    ),
    seeSummary: can(Action.CASOS_RELATORIO_PFPJ_VER_RESUMO, negotiatorId),
    seeOrigin: can(Action.CASOS_RELATORIO_PFPJ_VER_ORIGEM, negotiatorId),
    update: can(Action.CASOS_RELATORIO_PFPJ_ATUALIZAR, negotiatorId),
    retry: can(Action.CASOS_RELATORIO_PFPJ_TENTAR_NOVAMENTE, negotiatorId),
    seePartial: can(
      Action.CASOS_RELATORIO_PFPJ_VER_RELATORIO_PARCIAL,
      negotiatorId
    ),
    showInstagramReport: can(
      Action.CASOS_RELATORIO_PFPJ_VER_PERFIL_INSTAGRAM,
      negotiatorId
    )
  };

  return (
    <Layout>
      {!data && <Loading />}
      {data && (
        <>
          <div className="report-show">
            <Header
              actionsComponent={
                <>
                  {isSummaryEnabled && canUser.seeSummary && (
                    <Button highlight outline onClick={toggle('summary')}>
                      Ver resumo
                    </Button>
                  )}

                  {canUser.removeFromCase && hasOrigin && (
                    <Button
                      highlight
                      onClick={handleRemoveFromCase(data!.entity!.id)}
                    >
                      Remover do caso
                    </Button>
                  )}

                  {canUser.addToCase && !hasOrigin && (
                    <Button highlight onClick={toggle('addToCaseModal')}>
                      Adicionar a suspeitos
                    </Button>
                  )}
                </>
              }
            >
              <Link
                to={`/cases/${data.caseId}/resources`}
                dataTestId="back-to-case"
                iconName="arrow-left"
              >
                Voltar
              </Link>
              <Title>
                {data?.type === 'individual'
                  ? reportFieldFirstValue(data.individual?.name)
                  : reportFieldFirstValue(data.company?.businessName)}
              </Title>
              <div className="bottomheader">
                <ReportStatusWithModal
                  report={data}
                  handleTryAgain={handleRetryFailedQueries}
                  handleGenerateErrorReport={handleRefreshReport}
                  showLink={canUser.seePartial}
                  showAction={canUser.retry}
                />
                {(Reports.reportStatus(data) === Status.PARTIAL ||
                  Reports.reportStatus(data) === Status.PENDING) &&
                  canUser.retry && (
                    <ToolTip
                      content="Refazer apenas as consultas que falharam"
                      placement="top"
                    >
                      <span data-testid="report-retry-button">
                        <Button
                          outline
                          highlight
                          small
                          onClick={handleRetryFailedQueries}
                          dataTestId={`report-retry-${reportId}`}
                        >
                          Tentar novamente
                        </Button>
                      </span>
                    </ToolTip>
                  )}
                {canUser.update && (
                  <ToolTip content="Refazer todas as consultas" placement="top">
                    <span data-testid="report-update-button">
                      <Button
                        outline
                        highlight
                        small
                        onClick={handleRefreshReport}
                        dataTestId={`refresh-case-${reportId}`}
                      >
                        Atualizar
                      </Button>
                    </span>
                  </ToolTip>
                )}
              </div>
              {canUser.seeOrigin && (
                <div className="origintoggle">
                  <OriginModal.Toggle
                    renderCondition={hasOrigin}
                    onClick={handleOriginModal(!!entityError)}
                    isLoading={isLoading}
                  />
                </div>
              )}
            </Header>

            <InstagramProfileProvider
              caseId={data.caseId}
              reportId={data.id}
              instagramProfile={data.selectedInstagramProfile}
            >
              {data?.type === 'individual' ? (
                <Individual
                  report={data}
                  onUpdate={handleUpdate}
                  triggerExternalLawsuitsQueries={
                    triggerExternalLawsuitsQueries
                  }
                  setReportData={setData}
                />
              ) : (
                <Company
                  report={data}
                  onUpdate={handleUpdate}
                  triggerExternalLawsuitsQueries={
                    triggerExternalLawsuitsQueries
                  }
                  setReportData={setData}
                />
              )}
            </InstagramProfileProvider>
          </div>
        </>
      )}
      <Modal isOpen={isOpen.addToCaseModal} onClose={toggle('addToCaseModal')}>
        {data && (
          <SuspectsForm
            formMode={FormType.ADD}
            entity={syntheticEntity(data)}
            onError={handleAddErrorToCase}
            onSuccess={handleAddSuccessToCase}
            addFunction={handleAddToCase}
            args={{ reportId, caseId }}
          />
        )}
      </Modal>

      <Modal isOpen={isOpen.summary} onClose={toggle('summary')}>
        <SummaryForm
          caseId={caseId}
          reportId={reportId}
          showSavedPosts={canUser.showInstagramReport}
          entityType={data?.type}
          onConfirm={handleRedirectSummary}
        />
      </Modal>

      {hasOrigin && (
        <OriginModal
          entity={entity}
          isOpen={isOpen.originModal}
          onClose={toggle('originModal')}
        />
      )}
    </Layout>
  );
};

export default Show;
