import * as Yup from 'yup';

const validUrlRegex = /^(https?:\/\/)?.{2,}\..+$/;

export const usefulLinkSchema = Yup.object().shape({
  name: Yup.string()
    .max(30, 'Não pode ter mais de 30 caracteres')
    .required('Campo obrigatório'),
  url: Yup.string()
    .matches(validUrlRegex, 'URL inválida')
    .required('Campo obrigatório'),
  description: Yup.string()
    .max(100, 'Não pode ter mais de 100 caracteres')
    .notRequired()
    .nullable()
});
