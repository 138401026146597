import { ERROR_MESSAGE } from 'domain/reports';
import { buildPaginationParams } from 'domain/pagination';
import React, { useState } from 'react';
import size from 'lodash/size';

import { Accordion, Card, Stack, StackMargin, RadioSwitch } from 'ui';

import {
  fetchReportRuralProperties,
  fetchReportRuralPropertiesSummary
} from 'api/reportRuralProperties';
import SummaryTitle from 'pages/reports/SharedComponents/SummaryTitle';
import { useQuery } from 'hooks';
import { SummaryError } from 'components';
import { DEFAULT_PAGE_SIZE, FIRST_PAGE } from 'types';
import Table from './Table/Table';

type Props = {
  caseId: string;
  reportId: string;
  addToCase?: boolean;
  isSummary?: boolean;
  providerStatus?: string;
  tableMessage?: string;
};

const RuralPropertyTable = ({
  caseId,
  isSummary,
  reportId,
  addToCase = true,
  providerStatus,
  tableMessage
}: Props) => {
  const [shouldExpandAll, setShouldExpandAll] = useState(false);

  const toggleChangeCallback = (isAccordionClosed: boolean) => {
    isAccordionClosed && setShouldExpandAll(false);
  };

  const query = isSummary
    ? fetchReportRuralPropertiesSummary
    : fetchReportRuralProperties;

  const {
    data: ruralProperties = [],
    isLoading,
    pagination,
    error,
    refetch
  } = useQuery(query, {
    caseId,
    reportId
  });

  if (error) {
    return (
      <SummaryError
        title="Imóveis Rurais"
        message={ERROR_MESSAGE.ruralProperty}
      />
    );
  }

  const hasNoRegistrations = ruralProperties.every(
    ({ registrations }) => registrations.length === 0
  );

  const expandAllDisabled =
    (ruralProperties.length < 1 && !tableMessage) || hasNoRegistrations;

  if (isSummary) {
    return (
      <>
        <Stack marginBottom={StackMargin.SMALL}>
          <SummaryTitle
            headerActions={
              <RadioSwitch
                label="Detalhamento de imóveis rurais"
                onChange={() => setShouldExpandAll(prev => !prev)}
                active={shouldExpandAll}
                disabled={expandAllDisabled}
              />
            }
          >{`Imóveis Rurais (${size(ruralProperties)})`}</SummaryTitle>
        </Stack>
        {!isLoading && (
          <Table
            caseId={caseId}
            reportId={reportId}
            addToCase={addToCase}
            ruralProperties={ruralProperties}
            isLoading={isLoading}
            shouldExpandAll={shouldExpandAll}
            isSummary
          />
        )}
      </>
    );
  }

  const refetchWithPagination = (pagination?: {
    page: number;
    pageSize: number;
  }) => {
    refetch({
      caseId,
      reportId,
      pagination: {
        page: pagination?.page ?? FIRST_PAGE,
        pageSize: pagination?.pageSize ?? DEFAULT_PAGE_SIZE
      }
    });
  };

  const paginationProps = buildPaginationParams(
    pagination,
    refetchWithPagination
  );

  return (
    <Accordion
      title="Imóveis Rurais"
      badgeContent={pagination?.totalEntries}
      isClosed={ruralProperties.length < 1 && !tableMessage}
      tooltipText={providerStatus}
      headerActions={
        <RadioSwitch
          label="Expandir matriculas"
          onChange={() => setShouldExpandAll(prev => !prev)}
          dataTestId="expand-all-radio-switch"
          active={shouldExpandAll}
          disabled={expandAllDisabled}
        />
      }
      toggleChangeCallback={toggleChangeCallback}
    >
      {!isLoading && (
        <Card small>
          <Table
            caseId={caseId}
            reportId={reportId}
            addToCase={addToCase}
            ruralProperties={ruralProperties}
            isLoading={isLoading}
            pagination={paginationProps}
            emptyText={tableMessage}
            shouldExpandAll={shouldExpandAll}
          />
        </Card>
      )}
    </Accordion>
  );
};

export default RuralPropertyTable;
