import React from 'react';
import { Icon } from 'ui';

import style from './MissingInformation.module.scss';

const MissingInformation = () => (
  <div className={style.missingInfoContainer}>
    <Icon name="warning" fill="#E53935" small />
    <span className={style.missingInfoMessage}>Não informado</span>
  </div>
);

export default MissingInformation;
