import React, { useRef, useState, ReactNode } from 'react';
import useSupercluster from 'use-supercluster';
import GoogleMapReact, { Coords, fitBounds } from 'google-map-react';

import { PointFeature } from 'supercluster';
import { BBox } from 'geojson';
import { AugmentedWindow, CityMapPoints, LocationMapPoints } from 'types';

import { CityPin, LocationPin } from './MapPins';

import style from './GoogleMaps.module.scss';

type ClusterProps = {
  cluster: boolean;
  point_count?: number;
};

type Props = {
  points?: PointFeature<LocationMapPoints & ClusterProps>[];
  cityPoints?: PointFeature<CityMapPoints & ClusterProps>[];
  showInstagramPostOnPin?: boolean;
  canBookmarkPublication?: boolean;
};

const DEFAULT_CENTER = {
  lat: -23.5489,
  lng: -46.6388
};
const DEFAULT_ZOOM = 10;

const ADJUST_CENTER_POINT = 0.00001;

type MarkerProps = {
  children?: ReactNode;
} & Coords;

const Marker = ({ children }: MarkerProps) => <>{children}</>;

const GoogleMaps = ({
  points = [],
  cityPoints = [],
  showInstagramPostOnPin = true,
  canBookmarkPublication = true
}: Props) => {
  const mapRef = useRef<google.maps.Map>();
  const [zoom, setZoom] = useState<number>(DEFAULT_ZOOM);
  const [center, setCenter] = useState<Coords>(DEFAULT_CENTER);
  const [bounds, setBounds] = useState<BBox>();

  const { clusters, supercluster } = useSupercluster({
    points: points,
    bounds,
    zoom,
    options: {
      radius: 75,
      maxZoom: 20
    }
  });

  const {
    clusters: cityClusters,
    supercluster: citySupercluster
  } = useSupercluster({
    points: cityPoints,
    bounds,
    zoom,
    options: {
      radius: 75,
      maxZoom: 20
    }
  });

  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  const setZoomAndCenter = (maps: any) => {
    if (points.length === 0 && cityPoints.length === 0) return;

    const mapBounds = new maps.LatLngBounds();

    if (points.length) {
      points.forEach(point => {
        mapBounds.extend(
          new maps.LatLng(
            point.geometry.coordinates[1],
            point.geometry.coordinates[0]
          )
        );
      });
    } else {
      cityPoints.forEach(point => {
        mapBounds.extend(
          new maps.LatLng(
            point.geometry.coordinates[1],
            point.geometry.coordinates[0]
          )
        );
      });
    }

    const expandedBounds = {
      ne: {
        lat: mapBounds.getNorthEast().lat(),
        lng: mapBounds.getNorthEast().lng()
      },
      sw: {
        lat: mapBounds.getSouthWest().lat(),
        lng: mapBounds.getSouthWest().lng()
      }
    };

    const hasOnlyOnePoint = points.length === 1 || cityPoints.length === 1;

    if (hasOnlyOnePoint) {
      expandedBounds.sw.lat += ADJUST_CENTER_POINT;
      expandedBounds.sw.lng -= ADJUST_CENTER_POINT;
    }

    const mapContainerSize = {
      width: document.getElementById('map-container')!.clientWidth,
      height: document.getElementById('map-container')!.clientHeight
    };

    const { center: mapCenter, zoom: mapZoom } = fitBounds(
      expandedBounds,
      mapContainerSize
    );

    const zoom = mapZoom <= DEFAULT_ZOOM ? mapZoom : DEFAULT_ZOOM;
    setZoom(zoom);
    setCenter(mapCenter);
  };

  return (
    <div
      className={style.container}
      id="map-container"
      data-testid="google-map-container"
    >
      <GoogleMapReact
        bootstrapURLKeys={{
          key: (window as AugmentedWindow).ENV.REACT_APP_GOOGLE_MAPS_KEY
        }}
        zoom={zoom}
        center={center}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          mapRef.current = map;
          setZoomAndCenter(maps);
        }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat
          ]);
        }}
      >
        {clusters.map((cluster, index) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const {
            cluster: isCluster,
            point_count: pointCount
          } = cluster.properties;

          if (isCluster) {
            return (
              <Marker
                key={`map-cluster-${index}`}
                lat={latitude}
                lng={longitude}
              >
                <div
                  className={style.cluster}
                  onClick={() => {
                    if (cluster.id) {
                      const expansionZoom = Math.min(
                        supercluster!.getClusterExpansionZoom(
                          cluster.id as number
                        ),
                        20
                      );

                      if (mapRef.current) {
                        mapRef.current.setZoom(expansionZoom);
                        mapRef.current.panTo({
                          lat: latitude,
                          lng: longitude
                        });
                      }
                    }
                  }}
                >
                  <span className={style.pointCount}>{pointCount}</span>
                </div>
              </Marker>
            );
          }

          return (
            <Marker key={`map-pin-${index}`} lat={latitude} lng={longitude}>
              <LocationPin
                {...(cluster.properties as LocationMapPoints)}
                showInstagramPostOnPin={showInstagramPostOnPin}
                canBookmarkPublication={canBookmarkPublication}
              />
            </Marker>
          );
        })}

        {cityClusters.map((cluster, index) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const {
            cluster: isCluster,
            point_count: pointCount
          } = cluster.properties;

          if (isCluster) {
            return (
              <Marker
                key={`map-city-cluster-${index}`}
                lat={latitude}
                lng={longitude}
              >
                <div
                  className={style.cityCluster}
                  onClick={() => {
                    if (cluster.id) {
                      const expansionZoom = Math.min(
                        citySupercluster!.getClusterExpansionZoom(
                          cluster.id as number
                        ),
                        20
                      );

                      if (mapRef.current) {
                        mapRef.current.setZoom(expansionZoom);
                        mapRef.current.panTo({
                          lat: latitude,
                          lng: longitude
                        });
                      }
                    }
                  }}
                >
                  <span className={style.pointCount}>{pointCount}</span>
                </div>
              </Marker>
            );
          }

          return (
            <Marker
              key={`map-city-pin-${index}`}
              lat={latitude}
              lng={longitude}
            >
              <CityPin {...(cluster.properties as CityMapPoints)} />
            </Marker>
          );
        })}
      </GoogleMapReact>
    </div>
  );
};

export default GoogleMaps;
