export function addSquareMeterUnit(value: string): string {
  return `${value} \u006D\u00B2`;
}

export function addPercentage(value: string): string {
  return `${value}%`;
}

export function addHectareUnit(value: string): string {
  return `${value} ha`;
}
