import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import compact from 'lodash/compact';
import {
  RadioGroupButton,
  openErrorToast,
  Link,
  Icon,
  Card,
  Modal,
  Button,
  ToolTip
} from 'ui';
import { Action, can, updateReportSelectedInstagram } from 'api';
import { ProviderTooltip } from 'components';
import {
  InstagramSubjectStatus,
  QueryStatus,
  Report,
  ReportExpectedTable
} from 'types';
import { applyDateMask, reportFieldFirstValue } from 'utils';

import InstagramSearchButton from './InstagramSearchButton';
import InstagramTaskCopyButton from './InstagramTaskCopyButton';

import style from './InstagramCardInfo.module.scss';

type Props = {
  report: Report;
  setIsMapUpdating: (flag: boolean) => void;
};

type StatusMessageProps = {
  status: InstagramSubjectStatus | 'info';
  finishedAt?: string | null;
};

const InstagramCardInfo = ({ report, setIsMapUpdating }: Props) => {
  const [selectedProfileId, setSelectedProfileId] = useState<string>(
    report.selectedInstagramProfileId || ''
  );

  const [newSelectedProfileId, setNewSelectedProfileId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isClickDisabled, setIsClickDisabled] = useState(false);

  const [modalType, setModalType] = useState<
    'changeProfile' | 'refreshProfilePosts'
  >('changeProfile');

  useEffect(() => {
    report.selectedInstagramProfileId &&
      setSelectedProfileId(report.selectedInstagramProfileId);
  }, [report.selectedInstagramProfileId]);

  const handleOnChange = (newSelectedId: string) => {
    setModalType('changeProfile');
    setNewSelectedProfileId(newSelectedId);
    setIsModalOpen(true);
  };

  const handleProfileRefreshClick = () => {
    setModalType('refreshProfilePosts');
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setNewSelectedProfileId('');
    setIsModalOpen(false);
  };

  const requestNewSelectedProfileInfo = async () => {
    if (isClickDisabled) return;

    setIsClickDisabled(true);
    newSelectedProfileId && setSelectedProfileId(newSelectedProfileId);
    setIsMapUpdating(true);

    try {
      await updateReportSelectedInstagram({
        caseId: report.caseId,
        reportId: report.id,
        selectedInstagramProfileId: newSelectedProfileId || selectedProfileId
      });
    } catch (e) {
      openErrorToast(
        `Falha ao ${
          modalType === 'changeProfile' ? 'selecionar' : 'atualizar'
        } perfil, tente novamente.`
      );
      report.selectedInstagramProfileId &&
        setSelectedProfileId(report.selectedInstagramProfileId);
    } finally {
      setNewSelectedProfileId('');
      setIsMapUpdating(false);
      setIsModalOpen(false);
      setIsClickDisabled(false);
    }
  };

  const shouldDisableActions =
    report.instagramSubject?.status === InstagramSubjectStatus.PENDING;

  const canUser = {
    updateInstagramReport: can(
      Action.CASOS_RELATORIO_PFPJ_ATUALIZAR_PERFIL_INSTAGRAM,
      report.caseNegotiatorId
    )
  };

  const radioOptions = report.possibleInstagramProfiles.value.map(
    ({ instagramProfile: profile }) => ({
      label: (
        <div className={style.profileLine}>
          <Link
            external
            href={profile.url}
            className={cn({
              [style.profilesLink]: selectedProfileId === profile.id,
              [style.faded]: selectedProfileId !== profile.id
            })}
            underline
          >
            {`@${profile.socialName}`}
          </Link>
          {selectedProfileId === profile.id && (
            <>
              <ToolTip content="Atualizar postagens" placement="top">
                <div
                  className={cn(style.refreshProfileButtonContainer, {
                    [style.refreshProfileButtonContainerDisabled]: shouldDisableActions
                  })}
                >
                  {canUser.updateInstagramReport ? (
                    <Button
                      icon="refresh"
                      dataTestId="button-refresh-instagram-profile"
                      className={style.refreshProfileButton}
                      onClick={handleProfileRefreshClick}
                      disabled={shouldDisableActions}
                      borderless
                      small
                      highlight
                    />
                  ) : null}
                </div>
              </ToolTip>
              <InstagramTaskCopyButton
                taskId={profile.instagramId}
                individualName={reportFieldFirstValue(report.individual?.name)}
                className={style.taskCopyButton}
              />
            </>
          )}
        </div>
      ),
      value: profile.id,
      className: style.profileLineContainer,
      disabled: !canUser.updateInstagramReport
    })
  );

  const hasAllIndividualQueriesFailed = report.queries
    .filter(query =>
      query.expectedTables.includes(ReportExpectedTable.INDIVIDUAL)
    )
    .every(query => query.status === QueryStatus.FAILED);

  const isSearchButtonDisabled =
    shouldDisableActions || hasAllIndividualQueriesFailed;

  return (
    <>
      <Card dataTestId="card-info-instagram" medium>
        <div className={style.header}>
          <div className={style.titleContainer}>
            <h3 className={style.title}>Instagram</h3>
            <ProviderTooltip
              providers={compact([
                report.instagramProfilesQueries[0]?.provider
              ])}
            />
          </div>
          <div>
            {report.instagramSubject && (
              <StatusMessage
                status={report.instagramSubject.status}
                finishedAt={report.instagramSubject.processedAt}
              />
            )}
            {report.instagramSubject?.status ===
              InstagramSubjectStatus.FAILED && <StatusMessage status="info" />}
          </div>
        </div>
        <div className={style.content}>
          {report.possibleInstagramProfiles.value.length > 0 && (
            <div className={style.profiles}>
              <span className={style.profilesLabel}>Possíveis perfis</span>
              <span className={style.profilesValue}>
                <RadioGroupButton
                  value={selectedProfileId}
                  onChange={e => handleOnChange(e.target.value)}
                  id="instagram-profiles"
                  name="instagram-profiles"
                  options={radioOptions}
                  disabled={shouldDisableActions}
                  showWithRadioButton={canUser.updateInstagramReport}
                />
              </span>
            </div>
          )}
          {canUser.updateInstagramReport && (
            <div className={style.buttonContainer}>
              <InstagramSearchButton
                caseId={report.caseId}
                reportId={report.id}
                disabled={isSearchButtonDisabled}
                tooltipText={
                  hasAllIndividualQueriesFailed
                    ? 'Não é possível realizar a busca pois as consultas principais falharam.'
                    : ''
                }
                isInitialSearch={!report.instagramSubject}
              />
            </div>
          )}
        </div>
      </Card>
      <Modal
        title={
          modalType === 'changeProfile'
            ? 'Deseja realmente substituir o perfil marcado?'
            : 'Deseja realmente atualizar postagens?'
        }
        isOpen={isModalOpen}
        onClose={handleModalClose}
        dataTestId="instagram-profile-modal"
        contentClassName={style.modalContent}
        medium
      >
        <div>
          {modalType === 'changeProfile' ? (
            <>
              <p className={style.modalText}>
                Ao substituir o perfil, será gerado um novo custo
              </p>
              <p className={style.modalText}>
                Perfil marcado: As postagens são referentes ao perfil marcado
              </p>
              <p className={style.modalText}>
                Ao selecionar outro perfil, as informações do perfil anterior
                não serão mais exibidas e não permanecerão salvas na plataforma
              </p>
            </>
          ) : (
            <p className={style.modalText}>
              Ao atualizar postagens, um novo custo será gerado
            </p>
          )}
        </div>

        <div className={style.modalButtonsContainer}>
          <Button highlight small onClick={requestNewSelectedProfileInfo}>
            OK
          </Button>
          <Button small highlight outline onClick={handleModalClose}>
            Cancelar
          </Button>
        </div>
      </Modal>
    </>
  );
};

const StatusMessage = ({ status, finishedAt }: StatusMessageProps) => {
  const finishedDate = finishedAt ? `(${applyDateMask(finishedAt)})` : '';

  const statusSettings = {
    [InstagramSubjectStatus.PROCESSED]: {
      iconName: 'checked-rounded',
      iconColor: '#43A047',
      className: style.succeeded,
      content: `Finalizado ${finishedDate}`
    },
    [InstagramSubjectStatus.PENDING]: {
      iconName: 'hourglass',
      iconColor: '#3949AB',
      className: style.pending,
      content: `Buscando ${finishedDate}. Esta busca pode demorar até 15 dias, aguarde.`
    },
    [InstagramSubjectStatus.NOT_FOUND]: {
      iconName: 'warning',
      iconColor: '#EE6633',
      className: style.notFound,
      content: `O provedor não encontrou nenhum perfil relacionado ${finishedDate}`
    },
    [InstagramSubjectStatus.FAILED]: {
      iconName: 'warning',
      iconColor: '#E53935',
      className: style.failed,
      content: `Erro ao realizar busca no provedor ${finishedDate}`
    },
    info: {
      iconName: 'question-mark-rounded',
      iconColor: '#969AAB',
      className: style.info,
      content: 'Tente novamente ou comunique sobre as falhas.'
    }
  };

  if (!status) return <></>;

  return (
    <>
      <div className={style.status}>
        <Icon
          name={statusSettings[status].iconName}
          small
          fill={statusSettings[status].iconColor}
        />
        <span className={statusSettings[status].className}>
          {statusSettings[status].content}
        </span>
      </div>
    </>
  );
};

export default InstagramCardInfo;
