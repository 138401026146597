import React from 'react';
import {
  ccirSituationTitle,
  landClassificationTitle,
  ownershipConditionTitle,
  ReportRuralProperty
} from 'types';
import { Accordion, Table } from 'ui';
import {
  addPercentage,
  applyCurrencyMask,
  applyDateMask,
  applyDocumentMask,
  formatArea,
  handleNullValue
} from 'utils';
import style from './SerproExtraInformation.module.scss';

type Props = {
  extraInformation: ReportRuralProperty;
};

const SerproExtraInformation = ({ extraInformation }: Props) => {
  return (
    <>
      <Table>
        <Table.Header children={<></>} />
        <Table.Body>
          <Table.Row small>
            <Table.Cell className={style.tableLabel}>
              Código do Imóvel INCRA
            </Table.Cell>
            <Table.Cell className={style.contentCell}>
              {handleNullValue(extraInformation.propertyCode)}
            </Table.Cell>
          </Table.Row>
          <Table.Row small>
            <Table.Cell className={style.tableLabel}>Denominação</Table.Cell>
            <Table.Cell>
              {handleNullValue(extraInformation.propertyName)}
            </Table.Cell>
          </Table.Row>
          <Table.Row small>
            <Table.Cell className={style.tableLabel}>Área Total</Table.Cell>
            <Table.Cell>
              {handleNullValue(
                extraInformation.totalArea,
                'string',
                formatArea
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row small>
            <Table.Cell className={style.tableLabel}>
              Classificação fundiária
            </Table.Cell>
            <Table.Cell>
              {handleNullValue(
                extraInformation.landClassification &&
                  landClassificationTitle(extraInformation.landClassification)
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row small>
            <Table.Cell className={style.tableLabel}>
              Data de processamento da última declaração
            </Table.Cell>
            <Table.Cell>
              {applyDateMask(
                handleNullValue(extraInformation.dateProcessingLastDeclaration)
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row small>
            <Table.Cell className={style.tableLabel}>
              Indicação de localização
            </Table.Cell>
            <Table.Cell>
              {handleNullValue(extraInformation.locationIndications)}
            </Table.Cell>
          </Table.Row>
          <Table.Row small>
            <Table.Cell className={style.tableLabel}>Município sede</Table.Cell>
            <Table.Cell>
              {handleNullValue(extraInformation.address?.city?.name)}
            </Table.Cell>
          </Table.Row>
          <Table.Row small>
            <Table.Cell className={style.tableLabel}>UF sede</Table.Cell>
            <Table.Cell>
              {handleNullValue(extraInformation.address?.city?.state)}
            </Table.Cell>
          </Table.Row>
          <Table.Row small>
            <Table.Cell className={style.tableLabel}>
              Área módulo rural
            </Table.Cell>
            <Table.Cell>
              {handleNullValue(
                extraInformation.ruralModuleArea,
                'number',
                formatArea
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row small>
            <Table.Cell className={style.tableLabel}>
              Nº de módulos rurais
            </Table.Cell>
            <Table.Cell>
              {handleNullValue(extraInformation.quantityRuralModule, 'number')}
            </Table.Cell>
          </Table.Row>
          <Table.Row small>
            <Table.Cell className={style.tableLabel}>
              Área de módulo fiscal
            </Table.Cell>
            <Table.Cell>
              {handleNullValue(
                extraInformation.taxModuleArea,
                'number',
                formatArea
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row small>
            <Table.Cell className={style.tableLabel}>
              Nº de módulos fiscais
            </Table.Cell>
            <Table.Cell>
              {handleNullValue(extraInformation.taxModuleQuantity, 'number')}
            </Table.Cell>
          </Table.Row>
          <Table.Row small>
            <Table.Cell className={style.tableLabel}>
              Fração mínima de parcelamento
            </Table.Cell>
            <Table.Cell>
              {handleNullValue(extraInformation.minimumFractionInstallment)}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Accordion
        isClosed
        title="Áreas registradas: Informações vindas do cartório"
        headerInfo={{
          label: 'Quantidade de matrículas: ',
          value: extraInformation.registrations.length
        }}
        titleClassName={style.accordionTitle}
      >
        {extraInformation.registrations.map((registration, index) => (
          <Table
            className={style.accordionTable}
            key={`${registration}+${index}`}
          >
            <Table.Header>
              <Table.Row>
                <Table.Cell
                  textAlign="right"
                  colSpan={2}
                >{`Informações de cartório #${index + 1}`}</Table.Cell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row small>
                <Table.Cell className={style.tableLabel}>
                  Matrícula ou transição
                </Table.Cell>
                <Table.Cell className={style.contentReducedCell}>
                  {handleNullValue(registration.enrollmentTranscript)}
                </Table.Cell>
              </Table.Row>
              <Table.Row small>
                <Table.Cell className={style.tableLabel}>Registro</Table.Cell>
                <Table.Cell>{handleNullValue(registration.record)}</Table.Cell>
              </Table.Row>
              <Table.Row small>
                <Table.Cell className={style.tableLabel}>
                  Data de registro
                </Table.Cell>
                <Table.Cell>
                  {applyDateMask(
                    handleNullValue(registration.registrationDate)
                  )}
                </Table.Cell>
              </Table.Row>
              <Table.Row small>
                <Table.Cell className={style.tableLabel}>
                  Livro ficha
                </Table.Cell>
                <Table.Cell>
                  {handleNullValue(registration.bookCard)}
                </Table.Cell>
              </Table.Row>
              <Table.Row small>
                <Table.Cell className={style.tableLabel}>CNS ofício</Table.Cell>
                <Table.Cell>
                  {handleNullValue(registration.cnsCraft)}
                </Table.Cell>
              </Table.Row>
              <Table.Row small>
                <Table.Cell className={style.tableLabel}>
                  Município do cartório
                </Table.Cell>
                <Table.Cell>
                  {handleNullValue(registration.notaryCity)}
                </Table.Cell>
              </Table.Row>
              <Table.Row small>
                <Table.Cell className={style.tableLabel}>
                  UF do cartório
                </Table.Cell>
                <Table.Cell>
                  {handleNullValue(registration.notaryState)}
                </Table.Cell>
              </Table.Row>
              <Table.Row small>
                <Table.Cell className={style.tableLabel}>Área</Table.Cell>
                <Table.Cell>
                  {handleNullValue(registration.area, 'number', formatArea)}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        ))}
      </Accordion>
      <Table>
        <Table.Header>
          <Table.Row small className={style.tableTitle}>
            <Table.Cell>Total de área registrada</Table.Cell>
            <Table.Cell className={style.contentCell}>
              {handleNullValue(
                extraInformation.totalRegisteredArea,
                'number',
                formatArea
              )}
            </Table.Cell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row small>
            <Table.Cell className={style.tableLabel}>
              Total de área de posse a justo título
            </Table.Cell>
            <Table.Cell>
              {handleNullValue(
                extraInformation.totalPossessionTitleArea,
                'number',
                formatArea
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row small>
            <Table.Cell className={style.tableLabel}>
              Total de área de posse a simples ocupação
            </Table.Cell>
            <Table.Cell>
              {handleNullValue(
                extraInformation.totalPossessionOccupancyArea,
                'number',
                formatArea
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row small>
            <Table.Cell className={style.tableLabel}>Área medida</Table.Cell>
            <Table.Cell>
              {handleNullValue(
                extraInformation.measuredArea,
                'number',
                formatArea
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row small>
            <Table.Cell className={style.tableLabel}>
              Total de pessoas relacionadas ao imóvel
            </Table.Cell>
            <Table.Cell>
              {handleNullValue(
                extraInformation.totalPeopleRelatedProperty,
                'number'
              )}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Accordion
        isClosed
        title="Titulares"
        headerInfo={{
          label: 'Quantidade de proprietários: ',
          value: extraInformation.owners.length
        }}
        titleClassName={style.accordionTitle}
      >
        {extraInformation.owners.map((owner, index) => (
          <Table
            className={style.accordionTable}
            key={`${owner.document}+${index}`}
          >
            <Table.Header>
              <Table.Row>
                <Table.Cell textAlign="right" colSpan={2}>{`Titular #${index +
                  1}`}</Table.Cell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row small>
                <Table.Cell className={style.tableLabel}>
                  Nome ou Razão Social
                </Table.Cell>
                <Table.Cell className={style.contentReducedCell}>
                  {handleNullValue(owner.name)}
                </Table.Cell>
              </Table.Row>
              <Table.Row small>
                <Table.Cell className={style.tableLabel}>CPF/CNPJ</Table.Cell>
                <Table.Cell>
                  {handleNullValue(owner.document, 'string', applyDocumentMask)}
                </Table.Cell>
              </Table.Row>
              <Table.Row small>
                <Table.Cell className={style.tableLabel}>
                  Nacionalidade
                </Table.Cell>
                <Table.Cell>{handleNullValue(owner.nationality)}</Table.Cell>
              </Table.Row>
              <Table.Row small>
                <Table.Cell className={style.tableLabel}>
                  % de detenção
                </Table.Cell>
                <Table.Cell>
                  {handleNullValue(
                    owner.detentionPercentage,
                    'number',
                    addPercentage
                  )}
                </Table.Cell>
              </Table.Row>
              <Table.Row small>
                <Table.Cell className={style.tableLabel}>Declarante</Table.Cell>
                <Table.Cell>{handleNullValue(owner.declarant)}</Table.Cell>
              </Table.Row>
              <Table.Row small>
                <Table.Cell className={style.tableLabel}>
                  Condição de titularidade
                </Table.Cell>
                <Table.Cell>
                  {handleNullValue(
                    owner.ownershipCondition &&
                      ownershipConditionTitle(owner.ownershipCondition)
                  )}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        ))}
      </Accordion>
      <Table className={style.lastTable}>
        <Table.Header>
          <Table.Row small className={style.tableTitle}>
            <Table.Cell>Dados do último CCIR</Table.Cell>
            <Table.Cell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row small>
            <Table.Cell className={style.tableLabel}>Nº do CCIR</Table.Cell>
            <Table.Cell className={style.contentCell}>
              {handleNullValue(extraInformation.lastCcir?.number, 'number')}
            </Table.Cell>
          </Table.Row>
          <Table.Row small>
            <Table.Cell className={style.tableLabel}>
              Nº de autenticidade CCIR
            </Table.Cell>
            <Table.Cell>
              {handleNullValue(
                extraInformation.lastCcir?.authenticityNumber,
                'number'
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row small>
            <Table.Cell className={style.tableLabel}>
              Data de lançamento
            </Table.Cell>
            <Table.Cell>
              {applyDateMask(
                handleNullValue(extraInformation.lastCcir?.releaseDate)
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row small>
            <Table.Cell className={style.tableLabel}>
              Data de geração
            </Table.Cell>
            <Table.Cell>
              {applyDateMask(
                handleNullValue(extraInformation.lastCcir?.generationDate)
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row small>
            <Table.Cell className={style.tableLabel}>
              Data de vencimento
            </Table.Cell>
            <Table.Cell>
              {applyDateMask(
                handleNullValue(extraInformation.lastCcir?.expirationDate)
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row small>
            <Table.Cell className={style.tableLabel}>
              Débitos anteriores
            </Table.Cell>
            <Table.Cell>
              {handleNullValue(
                extraInformation.lastCcir?.previousDebts,
                'number',
                applyCurrencyMask
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row small>
            <Table.Cell className={style.tableLabel}>
              Taxa de serviços cadastrais
            </Table.Cell>
            <Table.Cell>
              {handleNullValue(
                extraInformation.lastCcir?.cadastralServicesFee,
                'number',
                applyCurrencyMask
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row small>
            <Table.Cell className={style.tableLabel}>Valor cobrado</Table.Cell>
            <Table.Cell>
              {handleNullValue(
                extraInformation.lastCcir?.amountCharged,
                'number',
                applyCurrencyMask
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row small>
            <Table.Cell className={style.tableLabel}>Multa</Table.Cell>
            <Table.Cell>
              {handleNullValue(
                extraInformation.lastCcir?.fine,
                'number',
                applyCurrencyMask
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row small>
            <Table.Cell className={style.tableLabel}>Juros</Table.Cell>
            <Table.Cell>
              {handleNullValue(
                extraInformation.lastCcir?.fees,
                'number',
                applyCurrencyMask
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row small>
            <Table.Cell className={style.tableLabel}>Valor total</Table.Cell>
            <Table.Cell>
              {handleNullValue(
                extraInformation.lastCcir?.totalValue,
                'number',
                applyCurrencyMask
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row small>
            <Table.Cell className={style.tableLabel}>
              Situação do CCIR
            </Table.Cell>
            <Table.Cell>
              {handleNullValue(
                extraInformation.lastCcir?.situation &&
                  ccirSituationTitle(extraInformation.lastCcir?.situation)
              )}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
};

export default SerproExtraInformation;
