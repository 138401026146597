import { formatInitialValues, assetValidationSchema } from 'domain/assets';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useCallback
} from 'react';
import cn from 'classnames';

import { Action, can as apiCan } from 'api';
import { FormikProps, useFormik } from 'formik';
import { Asset, OnSubmit, AssetAttributes } from 'types';

import { Card, Stack, StackMargin, TextArea } from 'ui';
import { FileField, ImageGalleryField } from 'components';

import { AssetOwnerProvider } from './AssetOwnerContext';
import AssetTypeFields from './AssetTypeFields';
import Appraisals from './Appraisals';
import AssetsList from './AssetsList';
import EntityFields from './EntityFields';

import styles from './Form.module.scss';

type Props = {
  asset?: Asset;
  caseId: string;
  isLoading: boolean;
  onDirty: (value: boolean) => void;
  onSubmit: OnSubmit<AssetAttributes>;
};

const Form = forwardRef<FormikProps<AssetAttributes>, Props>(
  ({ asset, caseId, isLoading, onDirty, onSubmit }, ref) => {
    const formik = useFormik({
      initialValues: formatInitialValues(asset),
      validationSchema: assetValidationSchema,
      enableReinitialize: true,
      onSubmit
    });

    useImperativeHandle(ref, () => ({
      ...formik
    }));

    useEffect(() => {
      onDirty(formik.dirty);
    }, [formik.dirty, onDirty]);

    const handleGalleryChange = useCallback(
      (value: string[]) => formik.setFieldValue('galleryFileUploadIds', value),
      [formik]
    );

    const can = (action: Action): boolean =>
      apiCan(action, asset?.caseNegotiatorId);

    const canUser = {
      addPhotos: can(Action.CASOS_RELATORIO_ATIVO_SUBIR_FOTO),
      deletePhotos: can(Action.CASOS_RELATORIO_ATIVO_EXCLUIR_FOTO),
      showGroupAssets: can(Action.CASOS_RELATORIO_GRUPO_ATIVOS_CONSULTAR),
      showAppraisals: asset?.isGroup
        ? can(Action.CASOS_RELATORIO_GRUPO_AVALIACAO_CONSULTAR)
        : can(Action.CASOS_RELATORIO_ATIVO_AVALIACAO_CONSULTAR),
      editAppraisals: asset?.isGroup
        ? can(Action.CASOS_RELATORIO_GRUPO_AVALIACAO_EDITAR)
        : can(Action.CASOS_RELATORIO_ATIVO_AVALIACAO_EDITAR),
      editFields: asset?.isGroup
        ? can(Action.CASOS_RELATORIO_GRUPO_EDITAR)
        : can(Action.CASOS_RELATORIO_ATIVO_EDITAR),
      showFiles: asset?.isGroup
        ? can(Action.CASOS_RELATORIO_GRUPO_ARQUIVOS_CONSULTAR)
        : can(Action.CASOS_RELATORIO_ATIVO_ARQUIVOS_CONSULTAR),
      addFiles: asset?.isGroup
        ? can(Action.CASOS_RELATORIO_GRUPO_ARQUIVOS_INCLUIR)
        : can(Action.CASOS_RELATORIO_ATIVO_ARQUIVOS_INCLUIR),
      deleteFiles: asset?.isGroup
        ? can(Action.CASOS_RELATORIO_GRUPO_ARQUIVOS_EXCLUIR)
        : can(Action.CASOS_RELATORIO_ATIVO_ARQUIVOS_EXCLUIR)
    };

    return (
      <form data-testid="asset-report-form">
        <div className={cn(styles.row, { [styles.isGroup]: asset?.isGroup })}>
          <AssetTypeFields
            asset={asset}
            formik={formik}
            isLoading={isLoading}
            disabled={!canUser.editFields}
          />

          {!asset?.isGroup && (
            <ImageGalleryField
              isLoading={isLoading}
              onUploadSuccess={handleGalleryChange}
              caseId={caseId}
              images={asset?.galleryFileUploads ?? []}
              showAdd={canUser.addPhotos}
              showDelete={canUser.deletePhotos}
            />
          )}
        </div>

        <AssetOwnerProvider groupedAssets={asset?.groupedAssets || []}>
          <EntityFields
            asset={asset}
            caseId={caseId}
            formik={formik}
            isLoading={isLoading}
            disabled={!canUser.editFields}
          />

          {asset && asset.isGroup && canUser.showGroupAssets && (
            <Stack marginTop={StackMargin.XLARGE}>
              <AssetsList
                caseId={caseId}
                assetGroupId={asset.id}
                assetType={asset.type}
                negotiatorId={asset?.caseNegotiatorId}
              />
            </Stack>
          )}
        </AssetOwnerProvider>

        {canUser.showAppraisals && (
          <Stack marginTop={StackMargin.XLARGE}>
            <Card
              medium
              customHeader={<h3 className={styles.title}>Avaliação</h3>}
            >
              <Appraisals
                appraisals={formik.values.appraisals}
                providerAppraisalValue={asset?.providerValueCents}
                setFieldValue={formik.setFieldValue}
                isGroup={asset?.isGroup}
                negotiatorId={asset?.caseNegotiatorId}
              />

              <TextArea
                showCounter
                limit={500}
                id="appraisalComment"
                name="appraisalComment"
                title="Comentários"
                isLoading={isLoading}
                value={formik.values.appraisalComment}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={!canUser.editAppraisals}
              />
            </Card>
          </Stack>
        )}

        {canUser.showFiles && (
          <Stack marginTop={StackMargin.XLARGE}>
            <Card>
              <FileField
                caseId={caseId}
                title="Arquivos"
                name="documentFileUploadIds"
                id="documentFileUploadIds"
                initialFiles={asset?.documentFileUploads}
                onUploadSuccess={ids =>
                  formik.setFieldValue('documentFileUploadIds', ids)
                }
                dataTestId="document-file-upload"
                showAdd={canUser.addFiles}
                showDelete={canUser.deleteFiles}
              />
            </Card>
          </Stack>
        )}
      </form>
    );
  }
);

export default Form;
