import { FORMER_VALUE_CONTENT_PREFIX, getFormerValues } from 'domain/field';
import { buildPaginationParams } from 'domain/pagination';
import { ERROR_MESSAGE, ReportAssetName } from 'domain/reports';
import React from 'react';
import size from 'lodash/size';
import compact from 'lodash/compact';

import { applyCurrencyMask, applyMask, handleNullValue } from 'utils';
import {
  Accordion,
  Card,
  DynamicTable,
  TablePositionModifier,
  Stack,
  StackMargin,
  openSuccessToast,
  openErrorToast,
  DropDown
} from 'ui';
import { ActionEntity, ActionName, FieldHistory, ReportVehicle } from 'types';

import {
  addVehiclesToCase,
  fetchReportVehicles,
  fetchReportVehiclesSummary,
  fetchReportVehiclesTotalValue
} from 'api';
import { FormerValueTooltip, ProviderTooltip, SummaryError } from 'components';
import SummaryTitle from 'pages/reports/SharedComponents/SummaryTitle';
import { useQuery } from 'hooks';
import * as Actions from 'utils/actions';
import ReportDynamicTable from '../../ReportDynamicTable/ReportDynamicTable';

import style from './VehiclesTable.module.scss';

type Props = {
  caseId: string;
  reportId: string;
  addToCase?: boolean;
  isSummary?: boolean;
  providerStatus?: string;
  tableMessage?: string;
};

const VehiclesTable = ({
  isSummary,
  caseId,
  reportId,
  addToCase = true,
  providerStatus,
  tableMessage
}: Props) => {
  const query = isSummary ? fetchReportVehiclesSummary : fetchReportVehicles;

  const {
    data: vehicles = [],
    error: fetchVehiclesError,
    isLoading,
    pagination,
    refetch
  } = useQuery(query, {
    caseId,
    reportId
  });

  const { data: vehiclesTotalValue } = useQuery(fetchReportVehiclesTotalValue, {
    caseId,
    reportId
  });

  if (fetchVehiclesError) {
    return <SummaryError title="Veículos" message={ERROR_MESSAGE.vehicles} />;
  }

  const handleAddToCase = (vehicleId: ReportVehicle['id']) => async () => {
    try {
      await addVehiclesToCase({
        caseId,
        reportId,
        contentId: vehicleId
      });

      openSuccessToast('Veículo adicionado ao caso com sucesso!');
    } catch (err) {
      openErrorToast('Falha ao adicionar veículo ao caso.');
    }
  };

  const totalAmount = handleNullValue(
    vehiclesTotalValue?.totalValue,
    'string',
    value => applyMask('currency', value)
  );

  const isEmpty = vehicles.length < 1;

  const buildActions = ({ field: { actions }, id }: ReportVehicle) => {
    const addToCaseAction = {
      text: 'Adicionar ao caso',
      callback: handleAddToCase(id),
      isDisabled: Actions.isDisabled(actions, ActionName.ADD_TO_CASE),
      description: Actions.unavailableReasons(
        actions,
        ActionName.ADD_TO_CASE,
        ActionEntity.VEHICLE
      )
    };

    const allActionsAllowed = compact([addToCase && addToCaseAction]);

    return allActionsAllowed;
  };

  const getFormerVehicleYearContent = (
    originalManufactureYear: string | null,
    originalModelYear: string | null,
    history: FieldHistory | null
  ) => {
    const formerValues = getFormerValues(
      ['manufactureYear', 'modelYear'],
      history
    );

    if (!formerValues?.manufactureYear && !formerValues?.modelYear) return null;

    const { manufactureYear, modelYear } = formerValues;

    const manufacture = manufactureYear || originalManufactureYear;
    const model = modelYear || originalModelYear;

    return `${FORMER_VALUE_CONTENT_PREFIX} ${manufacture} / ${model}`;
  };

  const columns = [
    {
      name: 'Marca/modelo',
      columnKey: 'brandModel',
      render: (item: ReportVehicle) => (
        <FormerValueTooltip
          fieldName="brandModel"
          fieldValue={item.brandModel}
          history={item.field.history}
        />
      )
    },
    {
      name: 'Placa',
      columnKey: 'licensePlate',
      render: (item: ReportVehicle) => (
        <FormerValueTooltip
          fieldName="licensePlate"
          fieldValue={item.licensePlate}
          history={item.field.history}
        />
      )
    },
    {
      name: 'Ano de fabricação/modelo',
      columnKey: 'manufactureYear',
      render: (item: ReportVehicle) => (
        <FormerValueTooltip
          fieldName="manufactureYear"
          history={item.field.history}
          content={getFormerVehicleYearContent(
            item.manufactureYear,
            item.modelYear,
            item.field.history
          )}
        >
          {`${handleNullValue(item.manufactureYear)} / ${handleNullValue(
            item.modelYear
          )}`}
        </FormerValueTooltip>
      )
    },
    {
      name: 'Estado',
      columnKey: 'cityState',
      render: (item: ReportVehicle) => (
        <FormerValueTooltip
          fieldName="cityState"
          fieldValue={item.city?.state}
          history={item.field.history}
        />
      )
    },
    {
      name: 'Cidade',
      columnKey: 'cityName',
      render: (item: ReportVehicle) => (
        <FormerValueTooltip
          fieldName="cityName"
          fieldValue={item.city?.name}
          history={item.field.history}
        />
      )
    },
    {
      name: 'Valor',
      columnKey: 'fipeValueCents',
      render: (item: ReportVehicle) => (
        <FormerValueTooltip
          fieldName="fipeValueCents"
          fieldValue={item.fipeValueCents}
          history={item.field.history}
          formatter={value => applyCurrencyMask(value)}
        />
      )
    }
  ];

  const actionColumn = [
    {
      positionModifier: TablePositionModifier.RIGHT,
      hasActions: true,
      name: '',
      columnKey: 'actions',
      render: (item: ReportVehicle) => (
        <>
          <ProviderTooltip
            searchedByHomonym={item.field.searchedByHomonym}
            providers={item.field.providers}
            dataTestId="provider-tooltip"
          />
          {buildActions(item).length > 0 && (
            <DropDown options={buildActions(item)} />
          )}
        </>
      )
    }
  ];

  const rowKey = (item: ReportVehicle) => item.id;

  if (isSummary) {
    return (
      <>
        <Stack marginBottom={StackMargin.SMALL}>
          <SummaryTitle
            headerInfo={{
              value: totalAmount.toString(),
              label: 'Soma dos valores dos veículos:'
            }}
          >{`Veículos (${size(vehicles)})`}</SummaryTitle>
        </Stack>
        <DynamicTable
          columns={columns}
          data={vehicles}
          rowKey={rowKey}
          getRowClassName={() => style.summaryTableRow}
          noAutoPaginate
          isLoading={isLoading}
        />
      </>
    );
  }

  const refetchWithPagination = (pagination?: {
    page: number;
    pageSize: number;
  }) => {
    refetch({
      caseId,
      reportId,
      pagination
    });
  };

  const paginationProps = buildPaginationParams(
    pagination,
    refetchWithPagination
  );

  return (
    <Accordion
      title="Veículos"
      badgeContent={pagination?.totalEntries}
      tooltipText={providerStatus}
      headerInfo={{
        value: totalAmount.toString(),
        label: 'Soma dos valores dos veículos:'
      }}
      isClosed={isEmpty && !tableMessage}
    >
      <Card small>
        <ReportDynamicTable
          data={vehicles}
          columns={[...columns, ...actionColumn]}
          rowKey={rowKey}
          isLoading={isLoading}
          pagination={paginationProps}
          emptyText={tableMessage}
          reportAssetName={ReportAssetName.VEHICLES}
        />
      </Card>
    </Accordion>
  );
};

export default VehiclesTable;
