import { deleteProfile } from 'api';
import { useToggles } from 'hooks';
import React, { useState } from 'react';
import { ProfileItem } from 'types';
import {
  Button,
  Checkbox,
  Modal,
  openErrorToast,
  openSuccessToast,
  ToolTip
} from 'ui';

import style from './DeleteProfileModalButton.module.scss';

type Props = {
  profiles: ProfileItem[] | undefined;
  refetchProfiles: () => void;
};

const DeleteProfileModalButton = ({ profiles, refetchProfiles }: Props) => {
  const { isOpen, toggle } = useToggles<'deleteProfileModal'>({
    deleteProfileModal: false
  });

  const [selectedProfilesIds, setSelectedProfilesIds] = useState<string[]>([]);

  const handleSelect = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    event.preventDefault();

    setSelectedProfilesIds(previousSelectedProfileIds =>
      previousSelectedProfileIds.includes(id)
        ? previousSelectedProfileIds.filter(selectedId => selectedId !== id)
        : [...previousSelectedProfileIds, id]
    );
  };

  const handleDelete = async () => {
    if (
      !window.confirm(
        'Você tem certeza de que deseja remover os perfis selecionados?'
      )
    )
      return;

    for (const profileId of selectedProfilesIds) {
      const selectedProfile = profiles?.find(
        profile => profileId === profile.id
      );

      if (selectedProfile) {
        try {
          await deleteProfile(profileId);
          openSuccessToast(
            `Perfil ${selectedProfile.name} removido com sucesso!`
          );
        } catch {
          openErrorToast(
            `Ocorreu um problema ao excluir o perfil ${selectedProfile.name}. Atualize a página e tente novamente.`
          );
        }
      }
    }

    refetchProfiles();
  };

  return (
    <>
      <Button
        small
        highlight
        outline
        danger
        onClick={toggle('deleteProfileModal')}
        disabled={profiles?.length === 0}
      >
        Excluir perfil
      </Button>

      {isOpen.deleteProfileModal && (
        <Modal
          title="Excluir perfil"
          isOpen={isOpen.deleteProfileModal}
          onClose={toggle('deleteProfileModal')}
          className={style.modal}
          small
        >
          <>
            <ul className={style.listWrapper}>
              {profiles?.map(profile => (
                <li key={profile.id}>
                  <ToolTip
                    placement="top"
                    content={
                      !profile.deletable
                        ? 'Não é possível excluir, pois existem usuários relacionados a este perfil'
                        : null
                    }
                  >
                    <div data-testid={`${profile.name}-tooltip`}>
                      <Button
                        outline
                        centered
                        onClick={e => handleSelect(e, profile.id)}
                        className={style.buttonWrapper}
                        disabled={!profile.deletable}
                      >
                        <Checkbox
                          id={`${profile.name}-checkbox`}
                          name={`${profile.name}-checkbox`}
                          value={profile.name}
                          label={profile.name}
                          onChange={() => {}}
                          checked={selectedProfilesIds.includes(profile.id)}
                          disabled={!profile.deletable}
                        />
                      </Button>
                    </div>
                  </ToolTip>
                </li>
              ))}
            </ul>
            <Button
              outline
              centered
              danger
              onClick={handleDelete}
              disabled={selectedProfilesIds.length === 0}
            >
              Excluir
            </Button>
          </>
        </Modal>
      )}
    </>
  );
};

export default DeleteProfileModalButton;
