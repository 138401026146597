import React from 'react';
import cn from 'classnames';
import { fetchInternalLawsuits, fetchMonitoredExternalLawsuits } from 'api';
import { useQuery } from 'hooks';
import { Stack, StackMargin, SubTitle } from 'ui';

import style from './Lawsuits.module.scss';

import InternalLawsuitList from './InternalLawsuitsList';
import ExternalLawsuitList from './ExternalLawsuitList';

type Props = {
  caseId: string;
};

const Lawsuit = ({ caseId }: Props) => {
  const {
    data: internalLawsuits = [],
    isLoading: isLoadingInternal,
    error: internalError
  } = useQuery(fetchInternalLawsuits, { caseId });

  const {
    data: externalLawsuits = [],
    isLoading: isLoadingExternal,
    error: externalError
  } = useQuery(fetchMonitoredExternalLawsuits, { caseId });

  return (
    <Stack
      marginTop={StackMargin.XLARGE}
      className={cn({
        [style.lawsuitWithError]: externalError || internalError
      })}
    >
      <h3 className={style.title}>Processos</h3>

      <Stack marginTop={StackMargin.LARGE}>
        <SubTitle>
          {`Processos criados manualmente (${internalLawsuits.length})`}
        </SubTitle>

        <InternalLawsuitList
          internalLawsuits={internalLawsuits}
          isLoading={isLoadingInternal}
          error={internalError}
        />
      </Stack>

      <Stack marginTop={StackMargin.LARGE}>
        <SubTitle>{`Em acompanhamento (${externalLawsuits.length})`}</SubTitle>

        <ExternalLawsuitList
          externalLawsuits={externalLawsuits}
          isLoading={isLoadingExternal}
          error={externalError}
        />
      </Stack>
    </Stack>
  );
};

export default Lawsuit;
