import React from 'react';

import { InternalLawsuit } from 'types';
import { Card } from 'ui';

import InternalLawsuitFile from './InternalLawsuitFile';

import style from './InternalLawsuitFiles.module.scss';

type Props = {
  internalLawsuits: InternalLawsuit[];
  caseId: string;
  showDelete?: boolean;
  showLink?: boolean;
};

const InternalLawsuitFiles = ({
  internalLawsuits,
  caseId,
  showDelete = true,
  showLink = true
}: Props) => {
  const isLawsuitEmpty = internalLawsuits.length === 0;

  return (
    <Card>
      <h2 className={style.cardTitle}>Processos</h2>

      {isLawsuitEmpty && (
        <p className={style.emptyList}>Não há processos neste caso</p>
      )}

      {!isLawsuitEmpty && (
        <>
          {internalLawsuits.map((internalLawsuit: InternalLawsuit) => (
            <InternalLawsuitFile
              key={internalLawsuit.id}
              caseId={caseId}
              lawsuit={internalLawsuit}
              showDelete={showDelete}
              showLink={showLink}
            />
          ))}
        </>
      )}
    </Card>
  );
};

export default InternalLawsuitFiles;
