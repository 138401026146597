import React from 'react';
import { getIn, FormikHandlers } from 'formik';
import { Button, InputField, Title, Link } from 'ui';
import { useFeatureFlag } from 'hooks';
import { Feature } from 'types';
import CaptchaField from './CaptchaField';

type Field = 'email' | 'password' | 'captcha';
type Values = { [key in Field]: string };
type Errors = { [key in Field]?: string };
type Touched = { [key in Field]?: boolean };

type Props = {
  touched: Touched;
  errors: Errors;
  values: Values;
  isSubmitting: boolean;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  handleBlur: (event: React.ChangeEvent<HTMLInputElement>) => void;
  showResetPassword: () => void;
  handleOnChange: FormikHandlers['handleChange'];
  setFieldValue: (key: string, value: string | null) => void;
};

const Form = ({
  handleSubmit,
  handleBlur,
  touched,
  errors,
  values,
  handleOnChange,
  isSubmitting,
  showResetPassword,
  setFieldValue
}: Props) => {
  const [isMailerEnabled] = useFeatureFlag(Feature.MAILER);
  const [isCaptchaEnabled] = useFeatureFlag(Feature.LOGIN_CAPTCHA);

  const handleCaptchaChange = (value: string | null) => {
    setFieldValue('captcha', value);
  };

  return (
    <React.Fragment>
      <Title>Login</Title>
      <form data-testid="form-login" onSubmit={handleSubmit} autoComplete="off">
        <InputField
          id="email"
          name="email"
          type="text"
          title="Email"
          error={touched.email && getIn(errors, 'email')}
          onChange={handleOnChange}
          onBlur={handleBlur}
          value={values.email}
          dataTestId="login-input-email"
          autoComplete="off"
        />
        <InputField
          id="password"
          name="password"
          type="password"
          title="Senha"
          error={touched.password && getIn(errors, 'password')}
          onChange={handleOnChange}
          onBlur={handleBlur}
          value={values.password}
          dataTestId="login-input-password"
          autoComplete="off"
        />
        {isCaptchaEnabled && (
          <CaptchaField
            onChange={handleCaptchaChange}
            errors={touched.captcha && getIn(errors, 'captcha')}
          />
        )}
        <Button highlight type="submit" disabled={isSubmitting}>
          Entrar
        </Button>
      </form>
      {isMailerEnabled && (
        <Link
          onClick={showResetPassword}
          highlight
          data-testid="reset-password-link"
        >
          Esqueceu a senha?
        </Link>
      )}
    </React.Fragment>
  );
};

export default Form;
