import { ChildLawsuit, ChildLawsuitAttributes } from 'types';

import Client from './client';

function createChildLawsuit(
  caseId: string,
  internalLawsuitId: string,
  childLawsuitData: ChildLawsuitAttributes
) {
  return Client.post<ChildLawsuitAttributes, ChildLawsuit>(
    `cases/${caseId}/internal_lawsuits/${internalLawsuitId}/children`,
    childLawsuitData
  );
}

function deleteChildLawsuit(
  caseId: string,
  internalLawsuitId: string,
  childLawsuitId: string
) {
  return Client.delete(
    `cases/${caseId}/internal_lawsuits/${internalLawsuitId}/children/${childLawsuitId}`
  );
}

function fetchChildLawsuit({
  caseId,
  internalLawsuitId,
  childLawsuitId
}: {
  caseId: string;
  internalLawsuitId: string;
  childLawsuitId: string;
}) {
  return Client.get<ChildLawsuit>(
    `cases/${caseId}/internal_lawsuits/${internalLawsuitId}/children/${childLawsuitId}`
  );
}

export type FetchChildLawsuitsParams = {
  caseId: string;
  internalLawsuitId: string;
  pagination?: {
    page: number;
    pageSize: number;
  };
};

function fetchChildLawsuits({
  caseId,
  internalLawsuitId,
  pagination
}: FetchChildLawsuitsParams) {
  return Client.get<ChildLawsuit[]>(
    `cases/${caseId}/internal_lawsuits/${internalLawsuitId}/children`,
    {
      params: {
        page: pagination?.page.toString(),
        pageSize: pagination?.pageSize.toString()
      }
    }
  );
}

function updateChildLawsuit(
  caseId: string,
  internalLawsuitId: string,
  childLawsuitId: string,
  childLawsuitData: ChildLawsuitAttributes
) {
  return Client.put<ChildLawsuitAttributes, ChildLawsuit>(
    `cases/${caseId}/internal_lawsuits/${internalLawsuitId}/children/${childLawsuitId}`,
    childLawsuitData
  );
}

function autoUpdateChildLawsuit(
  caseId: string,
  internalLawsuitId: string,
  childLawsuitId: string
) {
  return Client.patch<void, ChildLawsuit>(
    `cases/${caseId}/internal_lawsuits/${internalLawsuitId}/children/${childLawsuitId}/auto_update`
  );
}

export {
  fetchChildLawsuit,
  fetchChildLawsuits,
  createChildLawsuit,
  updateChildLawsuit,
  deleteChildLawsuit,
  autoUpdateChildLawsuit
};
