import React from 'react';
import some from 'lodash/some';
import sumBy from 'lodash/sumBy';

import { UploadState, ProgressBar as ProgressBarType } from '../types';

import style from './ProgressBar.module.scss';

type Props = {
  progressBar: Array<ProgressBarType | undefined>;
};

const ProgressBar = ({ progressBar }: Props) => {
  const isUploading = some(progressBar, ['state', UploadState.INPROGRESS]);

  if (isUploading) {
    const uploadProgress = Math.round(
      sumBy(progressBar, 'progress') / progressBar.length
    );

    return (
      <div className={style.progressBar}>
        <div
          className={style.progressBarBackGround}
          style={{ width: `${uploadProgress}%` }}
        ></div>
        Subindo fotos… ({uploadProgress}%)
      </div>
    );
  }

  const uploadError = some(progressBar, ['state', UploadState.ERROR]);

  const totalError = progressBar.filter(
    progress => progress?.state === UploadState.ERROR
  ).length;

  const uploadSuccess = some(progressBar, ['state', UploadState.SUCCESS]);

  const totalSuccess = progressBar.filter(
    progress => progress?.state === UploadState.SUCCESS
  ).length;

  return (
    <>
      {uploadSuccess && (
        <div className={style.progressSuccess}>
          {totalSuccess} fotos adicionadas com sucesso, salve suas alterações
        </div>
      )}
      {uploadError && (
        <div className={style.progressError}>
          Erro ao subir {totalError} fotos
        </div>
      )}
    </>
  );
};

export default ProgressBar;
