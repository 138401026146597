import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { Link as LinkRouter } from 'react-router-dom';

import { Icon } from 'ui';

import './Link.scss';

export type LinkLocation = {
  pathname: string;
  hash?: string;
  state: Record<string, unknown>;
};

type Props = {
  asButton?: boolean;
  disabled?: boolean;
  iconName?: string;
  highlight?: boolean;
  children?: ReactNode;
  href?: string;
  onClick?: () => void;
  dataTestId?: string;
  className?: string;
  external?: boolean;
  outline?: boolean;
  to?: LinkLocation | string;
  target?: string;
  underline?: boolean;
  download?: string;
  title?: string;
  small?: boolean;
  social?: boolean;
};

function buildHref(href: string, external: boolean): string {
  if (!external) return href;

  return href.startsWith('http') ? href : 'https://' + href;
}

const Link = ({
  asButton,
  disabled,
  iconName,
  children,
  highlight,
  onClick,
  href,
  className = '',
  external = false,
  dataTestId = 'link',
  to,
  target,
  underline,
  title,
  outline,
  small,
  social,
  ...rest
}: Props) => {
  const linkClass = classNames('link-default', className, {
    '-highlight': !Boolean(disabled) && Boolean(highlight),
    '-underline': !Boolean(disabled) && Boolean(underline),
    '-as-button': Boolean(asButton),
    '-disabled': Boolean(disabled),
    '-outline': Boolean(outline),
    '-small': Boolean(small),
    '-social': Boolean(social)
  });

  const LinkChildren = (
    <>
      {iconName && <Icon name={iconName} />}
      {children}
    </>
  );

  const defaultProps = {
    className: linkClass,
    onClick: disabled ? undefined : onClick,
    'data-testid': dataTestId
  };

  return !disabled && to ? (
    <LinkRouter {...defaultProps} to={to} target={target} {...rest}>
      {LinkChildren}
    </LinkRouter>
  ) : (
    <a
      {...defaultProps}
      href={disabled ? undefined : href && buildHref(href, external)}
      rel={external ? 'noopener noreferrer' : undefined}
      target={external ? '_blank' : target}
      title={title}
      {...rest}
    >
      {LinkChildren}
    </a>
  );
};

export default Link;
