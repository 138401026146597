import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { InternalLawsuit, CreditContract, StatusType, Feature } from 'types';
import { CaseContext } from 'components';

import { Button, openErrorToast, Link } from 'ui';
import { Action, can, fetchInternalLawsuits, fetchCreditContracts } from 'api';
import { useQuery, useFeatureFlag } from 'hooks';
import MonitoredExternalLawsuits from './MonitoredExternalLawsuits';
import InternalLawsuitsList from './InternalLawsuitsList';

import style from './InternalLawsuits.module.scss';

const Lawsuits = ({
  match: {
    params: { caseId }
  }
}: RouteComponentProps<{ caseId: string }>) => {
  const { data: lawsuits, error } = useQuery<
    InternalLawsuit[],
    { caseId: string }
  >(fetchInternalLawsuits, {
    caseId
  });
  const {
    data: contracts,
    error: contractsError,
    isLoading: isLoadingContracts
  } = useQuery<CreditContract[], { caseId: string }>(fetchCreditContracts, {
    caseId: caseId
  });

  const { caseData } = useContext(CaseContext);

  if (contractsError) {
    openErrorToast('Erro ao listar contratos');
  }

  if (error) {
    openErrorToast('Erro ao listar processos');
  }

  function filterBy(status: string) {
    return lawsuits?.filter(lawsuit => lawsuit.status === status);
  }

  const [isExternalLawsuitsMonitoringEnabled] = useFeatureFlag(
    Feature.EXTERNAL_LAWSUITS_MONITORING
  );

  const canAddInternalLawsuits = can(
    Action.CASOS_PROCESSOS_JUDICIAIS_INCLUIR,
    caseData?.negotiator?.id
  );

  const canShowLawsuits = can(
    Action.CASOS_PROCESSOS_JUDICIAIS_VER_PROCESSO,
    caseData?.negotiator?.id
  );

  return (
    <div className={style.lawsuitsIndex}>
      {!isLoadingContracts && (
        <>
          {canAddInternalLawsuits &&
            (contracts && contracts.length > 0 ? (
              <div className={style.addProcessButton}>
                <Link
                  to={`/cases/${caseId}/internal_lawsuits/create`}
                  highlight
                >
                  <Button highlight outline small centered>
                    Adicionar novo processo
                  </Button>
                </Link>
              </div>
            ) : (
              <p className={style.warning}>
                Para poder criar processos, crie um contrato antes
              </p>
            ))}
        </>
      )}
      <InternalLawsuitsList
        isLoading={isLoadingContracts}
        caseId={caseId}
        title="Em distribuição"
        items={filterBy(StatusType.IN_DISTRIBUTION)}
        iconName="fullscreen"
      />
      <InternalLawsuitsList
        isLoading={isLoadingContracts}
        caseId={caseId}
        title="Ajuizado"
        items={filterBy(StatusType.FILED)}
        iconName="law"
      />
      <InternalLawsuitsList
        isLoading={isLoadingContracts}
        caseId={caseId}
        title="Encerrado"
        items={filterBy(StatusType.CLOSED)}
        iconName="chequered-flag"
      />
      {isExternalLawsuitsMonitoringEnabled && (
        <MonitoredExternalLawsuits
          caseId={caseId}
          hideTitleLink={!canShowLawsuits}
        />
      )}
    </div>
  );
};

export default Lawsuits;
