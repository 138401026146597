import { assetOriginOptions, assetRealEstateTypeOptions } from 'domain/assets';
import React from 'react';
import { FormikErrors, FormikHandlers, FormikTouched, getIn } from 'formik';

import { AssetMissingDataAttribute, Asset } from 'types';
import { FormContainer, SelectField, Toggle } from 'ui';

import style from './AssetRealEstateForm.module.scss';

type Props = {
  index: number;
  asset: Asset;
  assetGroup?: Asset;
  formAttributes: AssetMissingDataAttribute;
  touched: FormikTouched<{ assets: AssetMissingDataAttribute[] }>;
  errors: FormikErrors<{ assets: AssetMissingDataAttribute[] }>;
  handleBlur: FormikHandlers['handleBlur'];
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: (field: string, value: string | null | undefined) => void;
};

const AssetRealEstateForm = ({
  index,
  asset,
  assetGroup,
  formAttributes,
  touched,
  errors,
  handleBlur,
  handleChange,
  setFieldValue
}: Props) => {
  return (
    <div role="listitem" className={style.assetContent}>
      <h3 className={style.assetTitle}>
        {asset.name}
        {asset.identification && ` (Matrícula: ${asset.identification})`}
        {asset.isGroup && ' (Grupo)'}
        {assetGroup && (
          <div className={style.assetGroupNameWrapper}>
            <span>Ativo do grupo</span>
            <span className={style.assetGroupName}> {assetGroup.name}</span>
          </div>
        )}
      </h3>

      <FormContainer className="col-3">
        {!asset.isGroup && (
          <>
            <SelectField
              title="Subtipo"
              id={`assets[${index}].assetRealEstate.type`}
              name={`assets[${index}].assetRealEstate.type`}
              value={formAttributes.assetRealEstate?.type ?? ''}
              error={
                getIn(touched, `assets[${index}].assetRealEstate.type`) &&
                getIn(errors, `assets[${index}].assetRealEstate.type`)
              }
              optional
              onChange={handleChange}
              onBlur={handleBlur}
              options={assetRealEstateTypeOptions()}
            />

            <Toggle
              title="Ocupação"
              name={`assets[${index}].assetRealEstate.occupied`}
              options={[
                { value: 'false', label: 'Desocupado' },
                { value: 'true', label: 'Ocupado' }
              ]}
              checked={`${formAttributes.assetRealEstate?.occupied}`}
              error={
                getIn(touched, `assets[${index}].assetRealEstate.occupied`) &&
                getIn(errors, `assets[${index}].assetRealEstate.occupied`)
              }
              onChange={event =>
                setFieldValue(
                  `assets[${index}].assetRealEstate.occupied`,
                  event?.target.value
                )
              }
            />
          </>
        )}

        {asset.assetGroupId === null && (
          <SelectField
            title="Origem"
            id={`assets[${index}].assetRealEstate.origin`}
            name={`assets[${index}].assetRealEstate.origin`}
            value={formAttributes.assetRealEstate?.origin ?? ''}
            error={
              getIn(touched, `assets[${index}].assetRealEstate.origin`) &&
              getIn(errors, `assets[${index}].assetRealEstate.origin`)
            }
            optional
            onChange={handleChange}
            onBlur={handleBlur}
            options={assetOriginOptions()}
          />
        )}
      </FormContainer>
    </div>
  );
};

export default AssetRealEstateForm;
