import React, { Component, ReactElement, ErrorInfo } from 'react';
import ErrorsPage from 'pages/errors';

type Props = {
  fallBackComponent?: ReactElement;
};

type State = {
  hasError: boolean;
};

class ErrorBoundary extends Component<Props, State> {
  state = { hasError: false };

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error({
      error,
      errorInfo
    });
  }

  setErrorFalse = () => {
    this.setState({ hasError: false });
  };

  render() {
    if (!this.state.hasError) return this.props.children;
    if (this.props.fallBackComponent) return this.props.fallBackComponent;
    return <ErrorsPage onErrorFalse={this.setErrorFalse} />;
  }
}

export default ErrorBoundary;
