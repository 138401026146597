import { DateTime } from 'luxon';

export const daysSince = (from: string, till: string) => {
  const start = DateTime.fromISO(from);
  const end = DateTime.fromISO(till);

  const { days = 0 } = end.diff(start, 'days').toObject();

  return Math.round(days);
};
