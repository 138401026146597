import { InvoiceSituation, Option, Covenant, Invoice } from 'types';

const invoiceSituationDictionary: {
  [key in InvoiceSituation]: string;
} = {
  [InvoiceSituation.OPEN]: 'Em aberto',
  [InvoiceSituation.OVERDUE]: 'Vencida',
  [InvoiceSituation.RESTRUCTURING]: 'Reestruturação',
  [InvoiceSituation.EXECUTION]: 'Em execução',
  [InvoiceSituation.PAID_OFF]: 'Liquidada'
};

export function invoiceLabel(invoiceType: InvoiceSituation) {
  return invoiceSituationDictionary[invoiceType] || invoiceType;
}

export function situationOptions(): Option[] {
  return Object.values(InvoiceSituation).map(situation => ({
    value: situation,
    label: invoiceSituationDictionary[situation]
  }));
}

export function covenantOptions(covenants: Covenant[]): Option[] {
  return covenants.map(covenant => ({
    value: covenant.id,
    label: covenant.name
  }));
}

export function isInvoiceClosed(invoice: Invoice) {
  return [InvoiceSituation.PAID_OFF, InvoiceSituation.RESTRUCTURING].includes(
    invoice.situation
  );
}
