import { ERROR_MESSAGE } from 'domain/reports';
import { mountReportAddress } from 'domain/reportLocation';
import React from 'react';

import { ErrorBoundary } from 'components';

import { CardInfoItem, Feature, Report } from 'types';
import { CardInfo, Toast, ToastType } from 'ui';
import {
  applyMask,
  handleNullValue,
  handleEmptyArray,
  reportFieldFirstValue
} from 'utils';
import { useFeatureFlag } from 'hooks';

import style from './OtherInfosSummary.module.scss';

type Props = {
  report?: Report;
  providerStatus?: string;
  showPossibleInstagramProfiles?: boolean;
};

const OtherInfosSummary = ({
  report,
  providerStatus,
  showPossibleInstagramProfiles = false
}: Props) => {
  const [isInstagramReportEnabled] = useFeatureFlag(Feature.INSTAGRAM_REPORT);

  const individual = report?.individual;
  const company = report?.company;

  function makeCardInfoOtherInformations() {
    const informationArray: Array<CardInfoItem> = [
      {
        label: 'Telefones',
        value: handleEmptyArray(
          reportFieldFirstValue(individual?.phones)?.map(phone =>
            applyMask('phone', phone)
          )
        ),
        tooltip: null
      },
      {
        label: 'Endereço',
        value: mountReportAddress(reportFieldFirstValue(individual?.address)),
        tooltip: null
      },
      {
        label: 'Número',
        value: handleNullValue(
          reportFieldFirstValue(individual?.address)?.number
        ),
        tooltip: null
      },
      {
        label: 'CEP',
        value: handleNullValue(
          reportFieldFirstValue(individual?.address)?.postalCode,
          'string',
          value => applyMask('cep', value)
        ),
        tooltip: null
      },
      {
        label: 'E-mail pessoal',
        value: handleNullValue(reportFieldFirstValue(individual?.email)),
        tooltip: null
      },
      {
        label: 'Descrição',
        value: handleNullValue(report?.entity?.description),
        tooltip: null
      }
    ];

    if (isInstagramReportEnabled && showPossibleInstagramProfiles)
      informationArray.push({
        label: 'Possíveis perfis do Instagram',
        value: handleEmptyArray(
          report?.possibleInstagramProfiles.value.map(
            profile => `@${profile.instagramProfile.socialName}`
          )
        ),
        tooltip: null
      });

    return informationArray;
  }

  function makeCardInfoCompanyOtherInformations() {
    const informationArray: Array<CardInfoItem> = [
      {
        label: 'CEP',
        value: handleNullValue(
          reportFieldFirstValue(company?.address)?.postalCode,
          'string',
          value => applyMask('cep', value)
        ),
        tooltip: null
      },
      {
        label: 'Endereço',
        value: mountReportAddress(reportFieldFirstValue(company?.address)),
        tooltip: null
      },
      {
        label: 'WebsitesKKKK',
        value: handleEmptyArray(reportFieldFirstValue(company?.websites)),
        tooltip: null
      },
      {
        label: 'E-mail',
        value: handleNullValue(reportFieldFirstValue(company?.email)),
        tooltip: null
      },
      {
        label: 'Telefone',
        value: handleEmptyArray(
          reportFieldFirstValue(company?.phones)?.map(phone =>
            applyMask('phone', phone)
          )
        ),
        tooltip: null
      },
      {
        label: 'Descrição',
        value: handleNullValue(report?.entity?.description),
        tooltip: null
      }
    ];
    return informationArray;
  }

  return (
    <ErrorBoundary
      fallBackComponent={
        <Toast
          type={ToastType.ERROR}
          message={ERROR_MESSAGE.otherInformations}
        />
      }
    >
      {report?.type === 'individual' ? (
        <CardInfo
          className={style.cardInfo}
          dataTestId="card-info-other-informations"
          title="Outras informações"
          tooltipText={providerStatus}
          items={makeCardInfoOtherInformations()}
        />
      ) : (
        <CardInfo
          className={style.cardInfo}
          dataTestId="card-info-other-informations"
          title="Outras informações"
          tooltipText={providerStatus}
          items={makeCardInfoCompanyOtherInformations()}
        />
      )}
    </ErrorBoundary>
  );
};

export default OtherInfosSummary;
