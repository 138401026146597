import React, { useState } from 'react';
import cn from 'classnames';
import { PermissionAction } from 'types';
import { openErrorToast, openSuccessToast, RadioSwitch, Table } from 'ui';
import { disablePermission, enablePermission } from 'api/profiles';
import { Action, can } from 'api/permissions';

import style from './ActionRow.module.scss';

type Props = {
  action: PermissionAction;
  profileId: string;
  columnsSizeStyle?: string;
  onPermissionChange: (permissionId: string, value: boolean) => void;
};

const ActionRow = ({
  action,
  profileId,
  columnsSizeStyle,
  onPermissionChange
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const handlePermissionChange = async (
    permissionId: string,
    isEnabled: boolean,
    profileId: string
  ) => {
    try {
      if (isEnabled) {
        await disablePermission(permissionId, profileId);
        openSuccessToast('Permissão removida com sucesso!');
      } else {
        await enablePermission(permissionId, profileId);
        openSuccessToast('Permissão adicionada com sucesso!');
      }

      onPermissionChange(permissionId, !isEnabled);
    } catch {
      openErrorToast(
        'Ocorreu um erro ao atualizar a permissão. Recarregue a página e tente novamente.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Table.Row
      key={action.id}
      className={cn(
        style.row,
        { [style.enabledAction]: action.value },
        columnsSizeStyle
      )}
    >
      <Table.Cell centeredContent>
        <div className={style.nameColumn}>{action.name}</div>
      </Table.Cell>
      <Table.Cell>
        <span className={style.toggleColumn}>
          <RadioSwitch
            onChange={() => {
              setIsLoading(true);
              handlePermissionChange(action.id, action.value, profileId);
            }}
            active={action.value}
            inputId={`${profileId}-${action.id}-toggle`}
            isLoading={isLoading}
            disabled={
              !can(Action.PERMISSOES_GERENCIAMENTO_DE_PERMISSOES_EDITAR)
            }
          />
        </span>
      </Table.Cell>
    </Table.Row>
  );
};

export default ActionRow;
