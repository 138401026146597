import React from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import * as queryString from 'query-string';

import { Header, Title, Button, Link, openErrorToast, Modal } from 'ui';

import { Action, can, fetchCase } from 'api';

import { useQuery, useToggles } from 'hooks';

import { SummaryInfoType } from 'types';

import Layout from 'Layouts/Layout';
import Form from './Form/Form';
import BasicInformations from './BasicInformations';
import Debtors from './Debtors/Debtors';
import DebtorsInJudicialRecovery from './DebtorsInJudicialRecovery/DebtorsInJudicialRecovery';
import Guarantees from './Guarantees/Guarantees';
import Lawsuits from './Lawsuits/Lawsuits';
import Assets from './Assets/Assets';
import Suspects from './Suspects/Suspects';
import Events from './Events/Events';
import Cities from './Cities/Cities';

import style from './Summary.module.scss';

type InfosObj = { [key in SummaryInfoType]: string };

const Summary = () => {
  const { isOpen, toggle, closeAll } = useToggles<'updateSummary'>({
    updateSummary: false
  });
  const location = useLocation();
  const history = useHistory();
  const params = useParams<{ caseId: string }>();
  const caseId = params.caseId;

  const parsedQS = queryString.parse(location.search, {
    arrayFormat: 'comma'
  });
  const infos: InfosObj = [parsedQS.infos!]
    .filter(Boolean)
    .flatMap(key => key)
    .reduce((infoObj, key) => ({ ...infoObj, [key]: key }), {} as InfosObj);

  const { data, error, isLoading } = useQuery(fetchCase, caseId);

  if (error) {
    openErrorToast(
      'Erro ao carregar informações, recarregue a página e tente novamente.'
    );
  }

  const updateSummaryUrl = (path: string) => {
    closeAll();
    return history.replace(path);
  };

  const handlePrint = () => {
    if (data) {
      const tempTitle = document.title;
      document.title = `Caso ${data.name}`;
      window.print();
      document.title = tempTitle;
    }
  };

  const isInfoChecked = (infoKey: SummaryInfoType) => {
    return infos[infoKey];
  };

  const canShowSummary = can(
    Action.CASOS_RESUMO_DO_CASO_CONSULTAR,
    data?.negotiator?.id
  );

  if (data && !canShowSummary) {
    window.location.replace('/permission_denied');
    return <></>;
  }

  return (
    <>
      <Layout mainClassName={style.main}>
        <Header
          isLoading={isLoading}
          actionsComponent={
            <>
              <Button highlight small outline onClick={toggle('updateSummary')}>
                Alterar resumo
              </Button>
              <Button highlight small outline onClick={handlePrint}>
                Imprimir
              </Button>
            </>
          }
        >
          <Link to={`/cases/${caseId}`} iconName="arrow-left">
            voltar
          </Link>
          <Title className={style.title}>Resumo do caso {data?.name}</Title>
        </Header>
        <BasicInformations
          caseData={data}
          isLoading={isLoading}
          error={!!error}
        />

        {isInfoChecked(SummaryInfoType.DEBTORS_AND_CONTRACTS) && (
          <Debtors caseId={caseId} />
        )}

        {isInfoChecked(SummaryInfoType.DEBTORS_IN_JUDICIAL_RECOVERY) && (
          <DebtorsInJudicialRecovery caseId={caseId} />
        )}

        {isInfoChecked(SummaryInfoType.GUARANTEES) && (
          <Guarantees caseId={caseId} />
        )}

        {isInfoChecked(SummaryInfoType.EVENTS) && <Events caseId={caseId} />}

        {isInfoChecked(SummaryInfoType.SUSPECTS) && (
          <Suspects caseId={caseId} />
        )}
        {isInfoChecked(SummaryInfoType.ASSETS) && <Assets caseId={caseId} />}

        {isInfoChecked(SummaryInfoType.CITIES) && <Cities caseId={caseId} />}

        {isInfoChecked(SummaryInfoType.LAWSUITS) && (
          <Lawsuits caseId={caseId} />
        )}
      </Layout>
      <Modal isOpen={isOpen.updateSummary} onClose={toggle('updateSummary')}>
        <Form
          caseId={caseId}
          onConfirm={updateSummaryUrl}
          preSelected={Object.values(infos)}
        />
      </Modal>
    </>
  );
};

export default Summary;
