import {
  AddContentToCase,
  FetchReportTableProps,
  Report,
  ReportLocation,
  ReportLocationAttributes
} from 'types';
import Client from './client';

export function fetchReportLocations({
  caseId,
  reportId,
  pagination
}: FetchReportTableProps) {
  return Client.get<ReportLocation[]>(
    `cases/${caseId}/reports/${reportId}/contents/locations`,
    {
      params: {
        page: pagination?.page.toString(),
        pageSize: pagination?.pageSize.toString()
      }
    }
  );
}

export function fetchReportLocationsSummary({
  caseId,
  reportId
}: FetchReportTableProps) {
  return Client.get<ReportLocation[]>(
    `cases/${caseId}/reports/${reportId}/contents/locations/summary`
  );
}

export function addLocationsToCase({
  caseId,
  contentId,
  reportId
}: AddContentToCase) {
  return Client.post<unknown, ReportLocationAttributes>(
    `cases/${caseId}/reports/${reportId}/contents/locations/${contentId}`
  );
}

export function createReportFromLocations({
  caseId,
  contentId,
  reportId
}: AddContentToCase) {
  return Client.post<unknown, Report>(
    `cases/${caseId}/reports/${reportId}/contents/locations/${contentId}/reports`
  );
}
