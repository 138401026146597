import { ProviderName, ReportFields } from 'types';

export function reportFieldFirstValue<Type>(
  arr: ReportFields<Type>[] | undefined
): Type | undefined {
  return arr && arr[0]?.fieldValue;
}

export function handleMultipleProviders<Type>(
  dataProviders?: ReportFields<Type>[]
): ProviderName[] | undefined {
  if (!dataProviders) return undefined;
  return dataProviders.flatMap(data => {
    return data.providers;
  });
}
