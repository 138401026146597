import { lawsuitNumber } from 'domain/lawsuits';
import * as _ from 'lodash';

import {
  Notification,
  NotificationEventTarget,
  ChildLawsuitMovementEventContent,
  ExternalLawsuitMovementEventContent,
  InternalLawsuitMovementEventContent,
  NotificationEventType,
  ReportLegalReportEventContent,
  ReportInstagramProfileEventContent,
  SocialReportInstagramProfileEventContent,
  ScheduledCaseEventContent,
  ScheduledManualMovementEventContent
} from 'types';

type NotificationItem = {
  linkTo: string;
  message: string;
};

export function buildItem(notification: Notification): NotificationItem {
  const caseId = notification.event.case.id;
  const target = notification.event.target;
  const type = notification.event.type;

  if (
    type === NotificationEventType.MOVEMENT_CREATED &&
    target === NotificationEventTarget.CHILD_LAWSUIT
  ) {
    const content = notification.event
      .content as ChildLawsuitMovementEventContent;
    const movementId = encodeURIComponent(content.movementId);

    return {
      linkTo: `/cases/${caseId}/internal_lawsuits/${content.parentId}/children/${content.childId}/movements?id=${movementId}`,
      message: `Nova movimentação no ${lawsuitNumber(content.number)}`
    };
  }

  if (
    type === NotificationEventType.MOVEMENT_CREATED &&
    target === NotificationEventTarget.EXTERNAL_LAWSUIT
  ) {
    const content = notification.event
      .content as ExternalLawsuitMovementEventContent;
    const movementId = encodeURIComponent(content.movementId);
    return {
      linkTo: `/cases/${caseId}/external_lawsuits/${content.externalLawsuitId}/movements?id=${movementId}`,
      message: `Nova movimentação no ${lawsuitNumber(content.number)}`
    };
  }

  if (
    type === NotificationEventType.MOVEMENT_CREATED &&
    target === NotificationEventTarget.INTERNAL_LAWSUIT
  ) {
    const content = notification.event
      .content as InternalLawsuitMovementEventContent;
    const movementId = encodeURIComponent(content.movementId);

    return {
      linkTo: `/cases/${caseId}/internal_lawsuits/${content.internalLawsuitId}/movements?id=${movementId}`,
      message: `Nova movimentação no ${lawsuitNumber(content.number)}`
    };
  }

  if (
    type === NotificationEventType.LEGAL_REPORT_FAILED &&
    target === NotificationEventTarget.REPORT
  ) {
    const content = notification.event.content as ReportLegalReportEventContent;

    return {
      linkTo: `/cases/${caseId}/reports/${content.reportId}#externalLawsuits`,
      message: `Falha na busca por processos de ${content.name}`
    };
  }

  if (
    type === NotificationEventType.LEGAL_REPORT_FINISHED &&
    target === NotificationEventTarget.REPORT
  ) {
    const content = notification.event.content as ReportLegalReportEventContent;

    return {
      linkTo: `/cases/${caseId}/reports/${content.reportId}#externalLawsuits`,
      message: `Busca por processos de ${content.name} finalizada com sucesso`
    };
  }

  if (
    type === NotificationEventType.INSTAGRAM_PROFILE_FOUND &&
    target === NotificationEventTarget.REPORT
  ) {
    const content = notification.event
      .content as ReportInstagramProfileEventContent;

    return {
      linkTo: `/cases/${caseId}/reports/${content.reportId}`,
      message: `Perfil de Instagram encontrado para ${content.name}`
    };
  }

  if (
    type === NotificationEventType.INSTAGRAM_PROFILE_NOT_FOUND &&
    target === NotificationEventTarget.REPORT
  ) {
    const content = notification.event
      .content as ReportInstagramProfileEventContent;

    return {
      linkTo: `/cases/${caseId}/reports/${content.reportId}`,
      message: `Perfil de Instagram não encontrado para ${content.name}`
    };
  }

  if (
    type === NotificationEventType.INSTAGRAM_PROFILE_FOUND &&
    target === NotificationEventTarget.SOCIAL_REPORT
  ) {
    const content = notification.event
      .content as SocialReportInstagramProfileEventContent;

    return {
      linkTo: `/cases/${caseId}/social_reports/${content.socialReportId}`,
      message: `Perfil de Instagram encontrado para @${content.socialName}`
    };
  }

  if (
    type === NotificationEventType.INSTAGRAM_PROFILE_NOT_FOUND &&
    target === NotificationEventTarget.SOCIAL_REPORT
  ) {
    const content = notification.event
      .content as SocialReportInstagramProfileEventContent;

    return {
      linkTo: `/cases/${caseId}/social_reports/${content.socialReportId}`,
      message: `Perfil de Instagram não encontrado para @${content.socialName}`
    };
  }

  if (
    type === NotificationEventType.SCHEDULED_EVENT_HAPPENED &&
    target === NotificationEventTarget.CASE_EVENT
  ) {
    const content = notification.event.content as ScheduledCaseEventContent;

    const eventId = encodeURIComponent(content.eventId);

    return {
      linkTo: `/cases/${caseId}/events?id=${eventId}`,
      message: `Evento: ${truncate(content.title)}`
    };
  }

  if (
    type === NotificationEventType.SCHEDULED_EVENT_HAPPENED &&
    target === NotificationEventTarget.MANUAL_MOVEMENT
  ) {
    const content = notification.event
      .content as ScheduledManualMovementEventContent;

    const movementId = encodeURIComponent(content.manualMovementId);

    return {
      linkTo: `/cases/${caseId}/internal_lawsuits/${content.internalLawsuitId}/movements?id=${movementId}`,
      message: `Evento: ${truncate(content.title)}`
    };
  }

  return {
    linkTo: '',
    message: ''
  };
}

function truncate(text: string): string {
  return _.truncate(text, { length: 80, omission: '...' });
}
