import { useContainer } from 'hooks';
import { useMemo } from 'react';
import { Duplicate } from 'types';

export function useDuplicatesList(initialDuplicates: Duplicate[]) {
  const {
    entriesCount: duplicatesCount,
    newEntry: newDuplicate,
    setEntry: setDuplicate,
    mapEntries: mapDuplicates,
    setEntries: setDuplicates,
    reduceEntries: reduceCards,
    deleteEntry: deleteDuplicate,
    duplicateEntry
  } = useContainer(initialDuplicates);

  const totals = useMemo(
    () =>
      reduceCards(
        (totals, duplicate) => {
          if (duplicate) {
            return {
              value: totals.value + duplicate.valueCents,
              overdue: totals.overdue + duplicate.overdueAmount
            };
          } else {
            return totals;
          }
        },
        {
          value: 0,
          overdue: 0
        }
      ),
    [reduceCards]
  );

  return {
    setDuplicate,
    setDuplicates,
    newDuplicate,
    mapDuplicates,
    totals,
    duplicatesCount,
    deleteDuplicate,
    duplicateEntry
  };
}
