import React from 'react';
import cn from 'classnames';

import { Icon, Pagination } from 'ui';
import { FileUpload } from 'types';
import { usePagination } from 'hooks';

import { ReducerImage } from '../types';
import style from './Thumbnails.module.scss';

type Props = {
  images: Array<ReducerImage | FileUpload>;
  caseId?: string;
  onOpenGallery: (imageIndex: number) => void;
  onDelete: (imageId: string) => void;
  thumbnailsPerRow?: number;
  showDelete?: boolean;
};

const Thumbnails = ({
  images,
  onDelete,
  onOpenGallery,
  thumbnailsPerRow = 6,
  showDelete = true
}: Props) => {
  const { data: paginatedImages, paginationProps } = usePagination({
    dataToPaginate: images,
    initialItemsPerPage: 12
  });

  const isEmpty = Boolean(!images.length);

  if (isEmpty) {
    return <div className={style.empty}>Não há fotos para este ativo</div>;
  }

  return (
    <>
      <div
        className={style.images}
        style={{
          gridTemplateColumns: `repeat(${thumbnailsPerRow}, 1fr)`
        }}
      >
        {paginatedImages.map((image, index) => {
          return (
            <div className={style.thumbnail} key={index}>
              {image.signedUrl && (
                <div className={style.overlay}>
                  <button
                    aria-label="visualizar"
                    type="button"
                    title="visualizar"
                    onClick={() => onOpenGallery(index)}
                  >
                    <Icon name="eye" fill="#fff" small />
                  </button>
                  <a
                    title="download"
                    href={image.signedUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon name="download" fill="#fff" small />
                  </a>
                  {showDelete && (
                    <button
                      aria-label="excluir"
                      type="button"
                      title="excluir"
                      onClick={() => image.id && onDelete(image.id)}
                    >
                      <Icon name="trash" fill="#fff" small />
                    </button>
                  )}
                </div>
              )}
              <img
                src={image.signedUrl}
                alt={image.name}
                className={cn([
                  style.image,
                  !image.signedUrl && style.imageLoading
                ])}
              />
            </div>
          );
        })}
      </div>

      <div className={style.pagination}>
        <Pagination {...paginationProps} />
      </div>
    </>
  );
};

export default Thumbnails;
