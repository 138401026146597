import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useInitNavigation } from 'hooks';

import Contract from './Contract';

import { FormDirtyProvider } from './FormDirtyContext';

const Page = (
  props: RouteComponentProps<{ caseId: string; contractId?: string }>
) => {
  const caseId = props.match.params.caseId;
  const contractId = props.match.params.contractId;

  useInitNavigation(pathname => {
    const creationRoute = pathname.endsWith('/contract/create');
    if (creationRoute) return [pathname.replace('/contract/create', '')];

    return [pathname.replace(`/contract/${contractId}`, '')];
  });

  return (
    <FormDirtyProvider>
      <Contract caseId={caseId} contractId={contractId} />
    </FormDirtyProvider>
  );
};

export default Page;
