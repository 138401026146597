import {
  AddContentToCase,
  AssetAttributes,
  FetchReportTableProps,
  FetchReportTotalValueResult,
  ReportRealEstate
} from 'types';
import Client from './client';

export function fetchReportRealEstates({
  caseId,
  reportId,
  pagination
}: FetchReportTableProps) {
  return Client.get<ReportRealEstate[]>(
    `cases/${caseId}/reports/${reportId}/contents/real_estates`,
    {
      params: {
        page: pagination?.page.toString(),
        pageSize: pagination?.pageSize.toString()
      }
    }
  );
}

export function fetchReportRealEstatesSummary({
  caseId,
  reportId
}: FetchReportTableProps) {
  return Client.get<ReportRealEstate[]>(
    `cases/${caseId}/reports/${reportId}/contents/real_estates/summary`
  );
}

export function addRealEstateToCase({
  caseId,
  contentId,
  reportId
}: AddContentToCase) {
  return Client.post<unknown, AssetAttributes>(
    `cases/${caseId}/reports/${reportId}/contents/real_estates/${contentId}`
  );
}

export function fetchReportRealEstatesTotalValue({
  caseId,
  reportId
}: FetchReportTableProps) {
  return Client.get<FetchReportTotalValueResult>(
    `cases/${caseId}/reports/${reportId}/contents/real_estates/total_value`
  );
}
