import React from 'react';
import * as Yup from 'yup';
import { useFormik, getIn } from 'formik';

import {
  FormContainer,
  Toggle,
  InputField,
  Button,
  openErrorToast,
  openSuccessToast,
  Stack,
  StackMargin
} from 'ui';
import { createReport } from 'api';
import {
  Report,
  EntityReportFormAttributes,
  ReportAttributes,
  OnSubmit
} from 'types';
import * as ValidationSchema from 'utils/validationSchema';

const initialValues = {
  type: 'individual',
  searchTerm: ''
};

const validationSchema = Yup.object().shape({
  type: Yup.string().required('Campo obrigatório'),
  searchTerm: Yup.string().when('type', {
    is: 'individual',
    then: ValidationSchema.cpf().required('Campo obrigatório'),
    otherwise: ValidationSchema.cnpj().required('Campo obrigatório')
  })
});

type Props = {
  caseId: string;
  onCreateReport: (report: Report) => void;
};

const Form = ({ caseId, onCreateReport }: Props) => {
  const buildReportAttributes = (
    reportData: EntityReportFormAttributes
  ): ReportAttributes => {
    return {
      ...reportData,
      caseId: caseId,
      type: reportData.type === 'individual' ? 'individual' : 'company'
    };
  };

  const onFormSubmit: OnSubmit<EntityReportFormAttributes> = async (
    values,
    formikHelpers
  ) => {
    try {
      const report = await createReport(buildReportAttributes(values));
      openSuccessToast('Consulta salva com sucesso!');
      onCreateReport(report.data);
    } catch (error) {
      formikHelpers.setErrors(error.errors);
      openErrorToast('Houve um erro ao criar a consulta.');
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onFormSubmit
  });

  return (
    <form className="report-form" data-testid="create-report-form">
      <FormContainer>
        <Toggle
          dataTestId="report-type-toggle"
          title="Tipo de pessoa"
          options={[
            { value: 'individual', label: 'Física' },
            { value: 'company', label: 'Jurídica' }
          ]}
          checked={formik.values.type}
          onChange={event => {
            formik.resetForm();
            formik.setFieldValue('type', event.target.value);
          }}
        />

        {formik.values.type === 'individual' ? (
          <InputField
            id="searchterm-cpf"
            dataTestId="searchterm-cpf"
            name="searchTerm"
            title="CPF"
            type="cpf"
            value={formik.values.searchTerm}
            error={
              formik.touched.searchTerm && getIn(formik.errors, 'searchTerm')
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        ) : (
          <InputField
            id="searchterm-cnpj"
            dataTestId="searchterm-cnpj"
            name="searchTerm"
            title="CNPJ"
            type="cnpj"
            value={formik.values.searchTerm}
            error={
              formik.touched.searchTerm && getIn(formik.errors, 'searchTerm')
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        )}
      </FormContainer>
      <Stack marginTop={StackMargin.MEDIUM}>
        <Button
          highlight
          centered
          dataTestId="create-report"
          onClick={formik.handleSubmit}
          type="submit"
        >
          Buscar
        </Button>
      </Stack>
    </form>
  );
};

export default Form;
