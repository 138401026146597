import React, { useState } from 'react';

import { Link, Modal } from 'ui';
import { MovementAttachment } from 'types';

import style from './AttachmentItem.module.scss';

type Props = {
  attachment: MovementAttachment;
};

const AttachmentItem = ({ attachment }: Props) => {
  const [isAttachmentModalOpen, toggleAttachmentModal] = useState<boolean>(
    false
  );

  const handleAttachmentModalToggle = () => {
    toggleAttachmentModal(!isAttachmentModalOpen);
  };

  return (
    <div className={style.item}>
      <div className={style.links}>
        <Link
          highlight
          iconName="download"
          href={attachment.link}
          external
          title="Download do anexo"
          className={style.action}
        ></Link>

        <Link
          highlight
          iconName="eye"
          onClick={() => handleAttachmentModalToggle()}
          title="Visualizar conteúdo do anexo"
          className={style.action}
        ></Link>
      </div>

      <div className={style.links}>
        <span>{attachment.type}</span>
      </div>

      {isAttachmentModalOpen && (
        <Modal
          title={`Conteúdo de ${attachment.type}`}
          isOpen={isAttachmentModalOpen}
          onClose={handleAttachmentModalToggle}
          large
        >
          <div
            data-testid="modal-content-attachment"
            className="attachment-content"
          >
            {attachment.content}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AttachmentItem;
