import { movimentColor } from 'domain/externalLawsuits';
import React from 'react';

import { Card, TimeLine, openErrorToast } from 'ui';

import { useQuery, useLiveLawsuit, useSearchAnchor } from 'hooks';
import { fetchExternalMovements } from 'api';

import { MovementTimelineContent } from 'components';
import style from './Movements.module.scss';

type Props = {
  caseId: string;
  externalLawsuitId: string;
};

const Movements = ({ caseId, externalLawsuitId }: Props) => {
  const { data: fetchedData, isLoading, error, refetch } = useQuery(
    fetchExternalMovements,
    {
      caseId,
      externalLawsuitId
    }
  );

  useLiveLawsuit(`cases:${caseId}:external_lawsuits:${externalLawsuitId}`, () =>
    refetch({ caseId, externalLawsuitId })
  );

  useSearchAnchor({ isLoading, field: 'id' });

  if (error) {
    openErrorToast('Erro ao listar movimentações.');
  }

  const isEmpty = !fetchedData?.movements.length;

  return (
    <Card>
      <div className={style.content}>
        {isLoading && (
          <>
            <TimeLine isLoading />
            <TimeLine isLoading />
            <TimeLine isLoading />
          </>
        )}

        {!isLoading &&
          !isEmpty &&
          fetchedData?.movements.map((movement, index) => (
            <TimeLine
              id={movement.id}
              title={movement.title || movement.description}
              date={movement.date}
              key={`timeline-item-${index}`}
              dataTestId="timeline-item"
              color={movimentColor(movement.date)}
            >
              <MovementTimelineContent
                caseId={caseId}
                movement={movement}
                showDescription={Boolean(movement.title)}
              />
            </TimeLine>
          ))}

        {!isLoading && isEmpty && (
          <p className={style.emptyText}>Não há movimentações neste processo</p>
        )}
      </div>
    </Card>
  );
};

export default Movements;
