import { ERROR_MESSAGE, ReportAssetName } from 'domain/reports';
import { buildPaginationParams } from 'domain/pagination';
import React from 'react';
import compact from 'lodash/compact';
import {
  Accordion,
  Card,
  DropDown,
  openSuccessToast,
  openErrorToast,
  DynamicTable,
  TablePositionModifier,
  Stack,
  StackMargin
} from 'ui';
import {
  addAirplanesToCase,
  fetchReportAirplanes,
  fetchReportAirplanesSummary
} from 'api';

import {
  ReportAirplane,
  ActionName,
  ActionEntity,
  FIRST_PAGE,
  DEFAULT_PAGE_SIZE
} from 'types';
import { FormerValueTooltip, ProviderTooltip, SummaryError } from 'components';
import * as Actions from 'utils/actions';

import SummaryTitle from 'pages/reports/SharedComponents/SummaryTitle';
import { useQuery } from 'hooks';

import ReportDynamicTable from '../../ReportDynamicTable/ReportDynamicTable';
import style from './AirplanesTable.module.scss';

type Props = {
  caseId: string;
  reportId: string;
  addToCase?: boolean;
  isSummary?: boolean;
  providerStatus?: string;
  tableMessage?: string;
};

const AirplanesTable = ({
  caseId,
  reportId,
  addToCase = true,
  isSummary,
  providerStatus,
  tableMessage
}: Props) => {
  const query = isSummary ? fetchReportAirplanesSummary : fetchReportAirplanes;

  const {
    data: airplanes = [],
    isLoading,
    pagination,
    error,
    refetch
  } = useQuery(query, {
    caseId,
    reportId
  });

  if (error) {
    return <SummaryError title="Aeronaves" message={ERROR_MESSAGE.airplanes} />;
  }

  const handleAddToCase = (airplaneId: ReportAirplane['id']) => async () => {
    try {
      await addAirplanesToCase({ caseId, reportId, contentId: airplaneId });

      openSuccessToast('Aeronave adicionada ao caso com sucesso!');
    } catch (err) {
      openErrorToast('Falha ao adicionar a aeronave ao caso.');
    }
  };

  const isEmpty = airplanes.length < 1;

  const buildActions = ({ field: { actions }, id }: ReportAirplane) => {
    const addToCaseAction = {
      text: 'Adicionar ao caso',
      callback: handleAddToCase(id),
      isDisabled: Actions.isDisabled(actions, ActionName.ADD_TO_CASE),
      description: Actions.unavailableReasons(
        actions,
        ActionName.ADD_TO_CASE,
        ActionEntity.AIRPLANE
      )
    };

    const allActionsAllowed = compact([addToCase && addToCaseAction]);

    return allActionsAllowed;
  };

  const columns = [
    {
      name: 'Ano de fabricação',
      columnKey: 'year',
      render: (item: ReportAirplane) => (
        <FormerValueTooltip
          fieldName="year"
          fieldValue={item.year}
          history={item.field.history}
        />
      )
    },
    {
      name: 'Fabricante',
      columnKey: 'manufacturer',
      render: (item: ReportAirplane) => (
        <FormerValueTooltip
          fieldName="manufacturer"
          fieldValue={item.manufacturer}
          history={item.field.history}
        />
      )
    },
    {
      name: 'Matrícula',
      columnKey: 'registration',
      render: (item: ReportAirplane) => (
        <FormerValueTooltip
          fieldName="registration"
          fieldValue={item.registration}
          history={item.field.history}
        />
      )
    },
    {
      name: 'Modelo',
      columnKey: 'model',
      render: (item: ReportAirplane) => (
        <FormerValueTooltip
          fieldName="model"
          fieldValue={item.model}
          history={item.field.history}
        />
      )
    },
    {
      name: 'Proprietário',
      columnKey: 'operator',
      render: (item: ReportAirplane) => (
        <FormerValueTooltip
          fieldName="operator"
          fieldValue={item.operator}
          history={item.field.history}
        />
      )
    },
    {
      name: 'Situação Aeronave',
      columnKey: 'situation',
      render: (item: ReportAirplane) => (
        <FormerValueTooltip
          fieldName="situation"
          fieldValue={item.situation}
          history={item.field.history}
        />
      )
    },
    {
      name: 'Situação RAB',
      columnKey: 'rabSituation',
      render: (item: ReportAirplane) => (
        <FormerValueTooltip
          fieldName="rabSituation"
          fieldValue={item.rabSituation}
          history={item.field.history}
        />
      )
    }
  ];

  const actionColumn = [
    {
      positionModifier: TablePositionModifier.RIGHT,
      hasActions: true,
      name: '',
      columnKey: 'actions',
      render: (item: ReportAirplane) => (
        <>
          <ProviderTooltip
            providers={item.field.providers}
            searchedByHomonym={item.field.searchedByHomonym}
            dataTestId="provider-tooltip"
          />
          {buildActions(item).length > 0 && (
            <DropDown options={buildActions(item)} />
          )}
        </>
      )
    }
  ];

  const rowKey = (item: ReportAirplane) => item.id!;

  const refetchWithPagination = (pagination?: {
    page: number;
    pageSize: number;
  }) => {
    refetch({
      caseId,
      reportId,
      pagination: {
        page: pagination?.page ?? FIRST_PAGE,
        pageSize: pagination?.pageSize ?? DEFAULT_PAGE_SIZE
      }
    });
  };

  const paginationProps = buildPaginationParams(
    pagination,
    refetchWithPagination
  );

  if (isSummary) {
    return (
      <>
        <Stack marginBottom={StackMargin.SMALL}>
          <SummaryTitle>{`Aeronaves (${airplanes.length})`}</SummaryTitle>
        </Stack>
        <DynamicTable
          columns={columns}
          data={airplanes}
          rowKey={rowKey}
          getRowClassName={() => style.summaryTableRow}
          isLoading={isLoading}
          noAutoPaginate
        />
      </>
    );
  }

  return (
    <Accordion
      title="Aeronaves"
      badgeContent={pagination?.totalEntries}
      tooltipText={providerStatus}
      isClosed={isEmpty && !tableMessage}
    >
      <Card small>
        <ReportDynamicTable
          columns={[...columns, ...actionColumn]}
          data={airplanes}
          rowKey={rowKey}
          isLoading={isLoading}
          pagination={paginationProps}
          emptyText={tableMessage}
          reportAssetName={ReportAssetName.AIRPLANES}
        />
      </Card>
    </Accordion>
  );
};

export default AirplanesTable;
