import { CaseEventFormAttributes, CaseEvent } from 'types';
import Client from './client';

function createEvent({
  caseId,
  eventData
}: {
  caseId: string;
  eventData: CaseEventFormAttributes;
}) {
  return Client.post<CaseEventFormAttributes, CaseEvent>(
    `cases/${caseId}/events`,
    eventData
  );
}

function fetchEvents({ caseId }: { caseId: string }) {
  return Client.get<CaseEvent[]>(`cases/${caseId}/events`);
}

function updateEvent({
  caseId,
  eventId,
  eventData
}: {
  caseId: string;
  eventId: string;
  eventData: CaseEventFormAttributes;
}) {
  return Client.put<CaseEventFormAttributes, CaseEvent>(
    `cases/${caseId}/events/${eventId}`,
    eventData
  );
}

function deleteEvent({ caseId, id }: { caseId: string; id: string }) {
  return Client.delete(`cases/${caseId}/events/${id}`);
}

function submitEvent({
  event,
  values,
  caseId
}: {
  event: CaseEvent | undefined;
  values: CaseEventFormAttributes;
  caseId: string;
}) {
  if (event) {
    return updateEvent({ caseId, eventId: event.id, eventData: values });
  }

  return createEvent({ caseId, eventData: values });
}

export { fetchEvents, deleteEvent, createEvent, updateEvent, submitEvent };
