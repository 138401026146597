import React from 'react';

import { Guarantee } from 'types';

import { Modal } from 'ui';
import Form from './Form';

type Props = {
  caseId: string;
  contractId: string;
  isOpen: boolean;
  onAddGuarantee: (guarantee: Guarantee) => void;
  onClose: () => void;
};

const ContractGuaranteeRelationshipModal = ({
  caseId,
  contractId,
  isOpen,
  onClose,
  onAddGuarantee
}: Props) => (
  <Modal title="Selecione a garantia" isOpen={isOpen} onClose={onClose}>
    <Form
      caseId={caseId}
      contractId={contractId}
      onAddGuarantee={onAddGuarantee}
      onClose={onClose}
    />
  </Modal>
);

export default ContractGuaranteeRelationshipModal;
