import React from 'react';
import { Stack, StackMargin, Toast, ToastType } from 'ui';

import style from './SummaryError.module.scss';

type Props = {
  title: string;
  message: string;
};

const SummaryError = ({ title, message }: Props) => {
  return (
    <Stack marginTop={StackMargin.XLARGE} className={style.errorWrapper}>
      <h3 className={style.errorTitle}>{title}</h3>
      <Stack marginTop={StackMargin.MEDIUM}>
        <Toast type={ToastType.ERROR} message={message} />
      </Stack>
    </Stack>
  );
};

export default SummaryError;
