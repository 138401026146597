import React, { useState } from 'react';
import { openErrorToast, Button, Modal, ToolTip, Stack, StackMargin } from 'ui';
import { createInstagramProfileSearch } from 'api';

import styles from './InstagramSearchButton.module.scss';

type Props = {
  reportId: string;
  caseId: string;
  tooltipText?: string;
  disabled?: boolean;
  isInitialSearch?: boolean;
};

const InstagramSearchButton = ({
  reportId,
  caseId,
  tooltipText = '',
  disabled,
  isInitialSearch = true
}: Props) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isClickDisabled, setIsClickDisabled] = useState(false);

  const handleCreateInstagramSearch = async () => {
    if (isClickDisabled) return;

    setIsClickDisabled(true);

    try {
      await createInstagramProfileSearch({
        caseId: caseId,
        reportId: reportId
      });
    } catch (error) {
      openErrorToast(
        `Falha ao ${
          isInitialSearch ? 'buscar' : 'refazer busca'
        } por perfis, tente novamente.`
      );
    } finally {
      setConfirmModalOpen(false);
      setIsClickDisabled(false);
    }
  };

  return (
    <>
      <ToolTip content={tooltipText} placement="top">
        <span data-testid="instagram-button-tooltip">
          <Button
            outline
            highlight
            small
            disabled={disabled}
            onClick={() => setConfirmModalOpen(true)}
          >
            {isInitialSearch
              ? 'Buscar perfis do Instagram'
              : 'Refazer busca por perfis'}
          </Button>
        </span>
      </ToolTip>
      <Modal
        title={
          isInitialSearch
            ? 'Deseja realmente buscar perfis?'
            : 'Deseja refazer a busca por perfis?'
        }
        isOpen={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        className={styles.modal}
        contentClassName={styles.content}
      >
        <p className={styles.text}>
          {isInitialSearch
            ? 'Um custo será gerado ao buscar perfis'
            : 'Ao refazer busca, um novo custo será gerado e pode ocorrer alteração no perfil principal e nas postagens'}
        </p>
        <Stack marginTop={StackMargin.XLARGE} className={styles.actions}>
          <Button highlight small onClick={handleCreateInstagramSearch}>
            OK
          </Button>
          <Button
            small
            highlight
            outline
            onClick={() => setConfirmModalOpen(false)}
          >
            Cancelar
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

export default InstagramSearchButton;
