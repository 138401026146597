import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as queryString from 'query-string';

import {
  CompanySummaryInfoType,
  IndividualSummaryInfoType,
  Report
} from 'types';
import { Action, can, fetchReport } from 'api';

import { reportFieldFirstValue } from 'utils';

import Layout from 'Layouts/Layout';
import {
  Button,
  Header,
  Link,
  Modal,
  openErrorToast,
  Stack,
  StackMargin,
  Title
} from 'ui';
import { useQuery, useToggles } from 'hooks';

import { ReportStatus } from 'components';

import Company from './Company';
import Individual from './Individual';

import SummaryForm from './Form';
import HeaderInfo from './HeaderInfo';
import style from './Summary.module.scss';

type CompanyOrIndividualSummaryInfoType =
  | CompanySummaryInfoType
  | IndividualSummaryInfoType;

type InfosObj = { [key in CompanyOrIndividualSummaryInfoType]: string };

const Summary = () => {
  const { isOpen, toggle, closeAll } = useToggles<'updateSummary'>({
    updateSummary: false
  });
  const location = useLocation();
  const history = useHistory();
  const params = useParams<{ caseId: string; reportId: string }>();
  const caseId = params.caseId;
  const reportId = params.reportId;

  const parsedQS = queryString.parse(location.search, {
    arrayFormat: 'comma'
  });
  const infos: InfosObj = [parsedQS.infos!]
    .filter(Boolean)
    .flatMap(key => key)
    .reduce((infoObj, key) => ({ ...infoObj, [key]: key }), {} as InfosObj);

  const { data, error, isLoading } = useQuery<
    Report,
    { caseId: string; reportId: string }
  >(fetchReport, {
    caseId,
    reportId
  });

  if (error) {
    openErrorToast(
      'Erro ao carregar informações, recarregue a página e tente novamente.'
    );
  }

  const handlePrint = () => {
    if (data) {
      const tempTitle = document.title;
      document.title = `${
        data?.type === 'individual'
          ? reportFieldFirstValue(data.individual?.name)
          : reportFieldFirstValue(data.company?.businessName)
      }`;
      window.print();
      document.title = tempTitle;
    }
  };

  const updateSummaryUrl = (path: string) => {
    closeAll();
    return history.replace(path);
  };

  const isIndividualReport = data?.type === 'individual';

  const negotiatorId = data?.caseNegotiatorId;

  const canUser = {
    showInstagramReport: can(
      Action.CASOS_RELATORIO_PFPJ_VER_PERFIL_INSTAGRAM,
      negotiatorId
    )
  };

  return (
    <Layout mainClassName={style.main}>
      <Header
        isLoading={isLoading}
        actionsComponent={
          <>
            <Button highlight outline onClick={toggle('updateSummary')}>
              Alterar resumo
            </Button>
            <Button highlight outline onClick={handlePrint}>
              Imprimir
            </Button>
          </>
        }
      >
        <Link to={`/cases/${caseId}/reports/${reportId}`} iconName="arrow-left">
          Voltar
        </Link>
        <Title className={style.title}>
          {isIndividualReport
            ? reportFieldFirstValue(data?.individual?.name)
            : reportFieldFirstValue(data?.company?.businessName)}
        </Title>
        <Stack marginBottom={StackMargin.MEDIUM}>
          <ReportStatus report={data} />
        </Stack>
        {data?.entity && (
          <HeaderInfo caseId={caseId} entityId={data.entity.id} />
        )}
      </Header>

      {data && isIndividualReport && (
        <Individual
          reportId={reportId}
          caseId={caseId}
          data={data}
          checkedInfos={infos}
        />
      )}
      {data && !isIndividualReport && (
        <Company
          reportId={reportId}
          caseId={caseId}
          data={data}
          checkedInfos={infos}
        />
      )}

      <Modal isOpen={isOpen.updateSummary} onClose={toggle('updateSummary')}>
        <SummaryForm
          caseId={caseId}
          reportId={reportId}
          showSavedPosts={canUser.showInstagramReport}
          entityType={data?.type}
          preSelected={Object.values(infos)}
          onConfirm={updateSummaryUrl}
        />
      </Modal>
    </Layout>
  );
};

export default Summary;
