import React, { ReactElement, useState, useCallback, useEffect } from 'react';
import Downshift from 'downshift';
import cn from 'classnames';
import debounce from 'lodash/debounce';

import { Entity, AutoCompleteItem, EntityType } from 'types';
import { searchCaseEntities } from 'api';
import { InputField, openErrorToast } from 'ui';
import { applyMask } from 'utils';
import { InputFieldProps } from '../../ui/Form/InputField/InputField';

import './AutoCompleteField.scss';

type Props = InputFieldProps & {
  type: 'cpf' | 'cnpj' | 'text';
  onSelect: (selection: AutoCompleteItem | null) => void;
  dataToItem: (data: Entity) => AutoCompleteItem;
  caseId: string;
  entityType: Entity['type'];
  suggestionsName?: string;
  isLoading?: boolean;
  isAbroad?: boolean;
};

type FetchItemsParams = {
  caseId: Props['caseId'];
  entityType: Props['entityType'];
  dataToItem: Props['dataToItem'];
  value: Props['value'];
  setItems: (items: AutoCompleteItem[]) => void;
  setSuggestionsItems: (suggestions: AutoCompleteItem[]) => void;
  type: Props['type'];
  inputValue: string;
  suggestionsName?: string;
  isAbroad: boolean;
};

const DEBOUNCE_TIME = 500;

const itemToString = (item: AutoCompleteItem | null): string =>
  item?.value || '';

const entityKey = (
  entityType: FetchItemsParams['entityType'],
  type: FetchItemsParams['type']
): string => {
  if (type === 'cnpj') return 'cnpj';
  if (type === 'cpf') return 'cpf';

  if (entityType === EntityType.COMPANY && type === 'text')
    return 'businessName';
  if (entityType === EntityType.INDIVIDUAL && type === 'text') return 'name';
  return '';
};

const fetchItemsDebounced = debounce(
  async ({
    caseId,
    entityType,
    dataToItem,
    value,
    setItems,
    setSuggestionsItems,
    type,
    inputValue,
    suggestionsName,
    isAbroad
  }: FetchItemsParams) => {
    if (inputValue === value && !suggestionsName) return;
    if (!inputValue && !suggestionsName) return;

    try {
      const response = await searchCaseEntities(
        caseId,
        entityType,
        isAbroad,
        {
          key: entityKey(entityType, type),
          value: inputValue
        },
        suggestionsName
      );

      const items = response.data.entitiesSearch.map((entity: Entity) =>
        dataToItem(entity)
      );

      const suggestionsItems = response.data.suggestions.map((entity: Entity) =>
        dataToItem(entity)
      );

      setItems(items);
      setSuggestionsItems(suggestionsItems);
    } catch (error) {
      openErrorToast('Erro ao listar auto complete.');
    }
  },
  DEBOUNCE_TIME
);

const AutoCompleteField = ({
  id,
  name,
  type,
  value = '',
  onChange,
  onBlur,
  title,
  error,
  showMode,
  dataTestId,
  onSelect,
  dataToItem,
  caseId,
  entityType,
  disabled,
  suggestionsName,
  isLoading = false,
  isAbroad = false
}: Props): ReactElement => {
  const [items, setItems] = useState<Array<AutoCompleteItem>>([]);
  const [suggestionsItems, setSuggestionsItems] = useState<
    Array<AutoCompleteItem>
  >([]);

  const fetchItems = useCallback(
    (inputValue: string) => {
      fetchItemsDebounced({
        caseId,
        entityType,
        dataToItem,
        value,
        setItems,
        setSuggestionsItems,
        type,
        inputValue,
        suggestionsName,
        isAbroad
      });
    },
    [
      caseId,
      entityType,
      dataToItem,
      value,
      setItems,
      type,
      suggestionsName,
      setSuggestionsItems,
      isAbroad
    ]
  );

  useEffect(() => {
    return () => {
      fetchItemsDebounced.cancel();
    };
  }, []);

  useEffect(() => {
    setSuggestionsItems([]);
    setItems([]);
  }, [entityType, isAbroad]);

  return (
    <Downshift
      onSelect={onSelect}
      itemToString={itemToString}
      onInputValueChange={fetchItems}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        selectedItem,
        openMenu
      }) => (
        <div className="input-field auto-complete-default">
          <InputField
            {...getInputProps({
              onChange: onChange,
              onBlur: onBlur,
              onFocus: () => {
                if (value === null) return;

                fetchItems(value);
                openMenu();
              }
            })}
            dataTestId={dataTestId}
            id={id}
            name={name}
            type={type}
            value={value}
            title={title}
            error={error}
            showMode={showMode}
            disabled={disabled}
            isLoading={isLoading}
          />

          {isOpen && (items.length || suggestionsItems.length) ? (
            <ul
              {...getMenuProps()}
              data-testid="auto-complete-dropdown"
              className="dropdown"
            >
              {items.map((item, index) => (
                <li
                  data-testid="items-autocomplete"
                  className={cn('dropdown-item', {
                    '-selected': Boolean(selectedItem === item)
                  })}
                  {...getItemProps({
                    key: `auto-complete-item-${index}`,
                    index,
                    item
                  })}
                >
                  {applyMask(type, item.value || '')}
                </li>
              ))}

              {suggestionsItems.length > 0 && (
                <li
                  data-testid="label-autocomplete"
                  className="dropdown-item label"
                >
                  Sugestões
                </li>
              )}

              {suggestionsItems.map((item, index) => (
                <li
                  data-testid="suggestions-autocomplete"
                  className={cn('dropdown-item', {
                    '-selected': Boolean(selectedItem === item)
                  })}
                  {...getItemProps({
                    key: `suggestion-item-${index}`,
                    index,
                    item
                  })}
                >
                  {applyMask(type, item.value || '')}
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      )}
    </Downshift>
  );
};

export default AutoCompleteField;
