import { FORMER_VALUE_CONTENT_PREFIX, getFormerValues } from 'domain/field';
import { mountReportAddress } from 'domain/reportLocation';
import { ReportAssetName } from 'domain/reports';
import React, { useEffect } from 'react';
import compact from 'lodash/compact';
import { DynamicTable, TablePositionModifier, IconTooltip, Link } from 'ui';
import { handleNullValue, addPercentage, formatArea, applyMask } from 'utils';

import { FormerValueTooltip, ProviderTooltip } from 'components';
import {
  FieldHistory,
  PaginationProps,
  ReportAddress,
  ReportRuralProperty,
  RuralPropertyOwner
} from 'types';
import cn from 'classnames';
import { useToggles } from 'hooks';
import ReportDynamicTable from 'pages/reports/SharedComponents/ReportDynamicTable/ReportDynamicTable';
import Actions from '../Actions/Actions';

import style from './Table.module.scss';

type Props = {
  ruralProperties: ReportRuralProperty[];
  caseId: string;
  reportId: string;
  addToCase?: boolean;
  isSummary?: boolean;
  isLoading?: boolean;
  shouldExpandAll?: boolean;
  pagination?: PaginationProps;
  emptyText?: string;
};

const mountRegistrationAddress = (
  city?: string | null,
  state?: string | null
): string => {
  if (!city && !state) return '-';

  return compact([city, state]).join(', ');
};

const getFormerAddressContent = (
  originalAddress: ReportAddress | null,
  history: FieldHistory | null
) => {
  const formerValues = getFormerValues(['cityName', 'cityState'], history);

  if (!formerValues?.cityName && !formerValues?.cityState) return null;

  const { cityName, cityState } = formerValues;

  const city = cityName || originalAddress?.city?.name || '';
  const state = cityState || originalAddress?.city?.state || '';

  return `${FORMER_VALUE_CONTENT_PREFIX} ${city} - ${state}`;
};

const Table = ({
  caseId,
  isSummary,
  reportId,
  addToCase = true,
  ruralProperties,
  isLoading = false,
  pagination,
  emptyText,
  shouldExpandAll
}: Props) => {
  const getOwnerReport = (owners: RuralPropertyOwner[]) => {
    const ownerReport = owners.find(
      (owner: RuralPropertyOwner) => owner.isOwner
    );
    return ownerReport;
  };

  const ruralRegistrationsToggles = ruralProperties.reduce(
    (prev, current) =>
      current.registrations.length > 0
        ? { ...prev, [current.id]: false }
        : { ...prev },
    {}
  );

  const { isOpen, toggle, openAll, closeAll } = useToggles<string>(
    ruralRegistrationsToggles
  );

  const ruralPropertiesWithRegistrationsList = ruralProperties.flatMap(
    ruralProperty => {
      if (!isOpen[ruralProperty.id]) return ruralProperty;

      const ruralPropertyRegistrationLines = ruralProperty.registrations.map(
        registration => ({
          ...ruralProperty,
          virtualRegistration: registration,
          isChildrenRowOpen: true
        })
      );

      return [ruralProperty, ...ruralPropertyRegistrationLines];
    }
  );

  useEffect(() => {
    shouldExpandAll ? openAll() : closeAll();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldExpandAll]);

  const columns = [
    {
      name: 'Nome do Imóvel',
      columnKey: 'propertyName',
      render: (item: ReportRuralProperty) =>
        isOpen[item.id] && item.virtualRegistration ? (
          ''
        ) : (
          <FormerValueTooltip
            fieldName="propertyName"
            fieldValue={item.propertyName}
            history={item.field.history}
          />
        )
    },
    {
      name: 'Matrícula',
      columnKey: 'enrollmentTranscript',
      render: (item: ReportRuralProperty) =>
        item.registrations.length > 0 ? (
          isOpen[item.id] && item.virtualRegistration ? (
            <FormerValueTooltip
              fieldName="enrollmentTranscript"
              fieldValue={item.virtualRegistration.enrollmentTranscript}
              history={item.field.history}
            />
          ) : (
            <Link
              onClick={toggle(item.id)}
              iconName={isOpen[item.id] ? 'arrow-down' : 'arrow-right'}
              dataTestId="link-enrollmentTranscript"
              highlight
            >{`Matrículas (${item.registrations.length})`}</Link>
          )
        ) : (
          '-'
        )
    },
    {
      name: 'Área',
      columnKey: 'totalArea',
      render: (item: ReportRuralProperty) => {
        const isRegistrationOpen = isOpen[item.id] && item.virtualRegistration;

        return (
          <>
            {isRegistrationOpen ? (
              <div className={style.flex}>
                <div className={style.areaTooltip}>
                  {item.virtualRegistration.area && (
                    <IconTooltip
                      dataTestId="table-tooltip-registrationArea"
                      text="Área registrada, referente à matrícula"
                    />
                  )}
                </div>

                <FormerValueTooltip
                  fieldName="area"
                  fieldValue={item.virtualRegistration.area}
                  history={item.virtualRegistration.history}
                  formatter={(value: string) => formatArea(Number(value))}
                />
              </div>
            ) : (
              <div className={style.flex}>
                <div className={style.areaTooltip}>
                  {item.totalArea && (
                    <IconTooltip
                      dataTestId="table-tooltip-totalArea"
                      text="Área total do imóvel"
                      className={style.flex}
                    />
                  )}
                </div>

                <FormerValueTooltip
                  fieldName="totalArea"
                  fieldValue={item.totalArea}
                  history={item.field.history}
                  formatter={(value: string) => formatArea(Number(value))}
                />
              </div>
            )}
          </>
        );
      }
    },
    {
      name: 'Área (georef.)',
      columnKey: 'certifiedArea',
      render: (item: ReportRuralProperty) =>
        isOpen[item.id] && item.virtualRegistration ? (
          ''
        ) : (
          <FormerValueTooltip
            fieldName="certifiedArea"
            fieldValue={item.certifiedArea}
            history={item.field.history}
            formatter={(value: string) => formatArea(Number(value))}
          />
        )
    },
    {
      name: 'Localização do cartório',
      columnKey: 'notary',
      render: (item: ReportRuralProperty) =>
        item.registrations.length > 0 ? (
          isOpen[item.id] && item.virtualRegistration ? (
            <FormerValueTooltip
              fieldName="notary"
              history={item.virtualRegistration.history}
            >
              {mountRegistrationAddress(
                item.virtualRegistration.notaryCity,
                item.virtualRegistration.notaryState
              )}
            </FormerValueTooltip>
          ) : (
            ''
          )
        ) : (
          '-'
        )
    },
    {
      name: 'Localização',
      columnKey: 'state',
      render: (item: ReportRuralProperty) =>
        isOpen[item.id] && item.virtualRegistration ? (
          ''
        ) : (
          <FormerValueTooltip
            fieldName="state"
            history={item.field.history}
            content={getFormerAddressContent(item.address, item.field.history)}
          >
            {mountRegistrationAddress(
              item.address?.city?.name,
              item.address?.city?.state
            )}
          </FormerValueTooltip>
        )
    },
    {
      name: '% de detenção',
      columnKey: 'number',
      render: (item: ReportRuralProperty) =>
        isOpen[item.id] && item.virtualRegistration ? (
          ''
        ) : (
          <FormerValueTooltip
            fieldName="detentionPercentage"
            history={item.field.history}
            formatter={(value: string) => addPercentage(value)}
          >
            {handleNullValue(
              getOwnerReport(item.owners)?.detentionPercentage,
              'number',
              addPercentage
            )}
          </FormerValueTooltip>
        )
    }
  ];

  const summaryColumns = [
    {
      name: 'Nome do imóvel',
      columnKey: 'propertyName',
      render: (item: ReportRuralProperty) =>
        isOpen[item.id] && item.virtualRegistration
          ? ''
          : handleNullValue(item.propertyName)
    },
    {
      name: 'Matrícula',
      columnKey: 'enrollmentTranscript',
      render: (item: ReportRuralProperty) =>
        item.registrations.length > 0 ? (
          isOpen[item.id] && item.virtualRegistration ? (
            <FormerValueTooltip
              fieldName="enrollmentTranscript"
              fieldValue={item.virtualRegistration.enrollmentTranscript}
              history={item.field.history}
            />
          ) : (
            <Link
              onClick={toggle(item.id)}
              dataTestId="link-enrollmentTranscript"
            >{`Matrículas (${item.registrations.length})`}</Link>
          )
        ) : (
          '-'
        )
    },
    {
      name: 'Área',
      columnKey: 'totalArea',
      render: (item: ReportRuralProperty) =>
        isOpen[item.id] && item.virtualRegistration ? (
          <FormerValueTooltip
            fieldName="area"
            fieldValue={item.virtualRegistration.area}
            history={item.virtualRegistration.history}
            formatter={(value: string) => applyMask('area', value.toString())}
          />
        ) : (
          <FormerValueTooltip
            fieldName="totalArea"
            fieldValue={item.totalArea}
            history={item.field.history}
            formatter={(value: string) => applyMask('area', value.toString())}
          />
        )
    },
    {
      name: 'Área (georef.)',
      columnKey: 'certifiedArea',
      render: (item: ReportRuralProperty) =>
        isOpen[item.id] && item.virtualRegistration ? (
          ''
        ) : (
          <FormerValueTooltip
            fieldName="certifiedArea"
            fieldValue={item.certifiedArea}
            history={item.field.history}
            formatter={(value: string) => formatArea(Number(value))}
          />
        )
    },
    {
      name: 'Localização do Cartório',
      columnKey: 'notary',
      render: (item: ReportRuralProperty) =>
        item.registrations.length > 0 ? (
          isOpen[item.id] && item.virtualRegistration ? (
            <FormerValueTooltip
              fieldName="notary"
              history={item.virtualRegistration.history}
            >
              {mountRegistrationAddress(
                item.virtualRegistration.notaryCity,
                item.virtualRegistration.notaryState
              )}
            </FormerValueTooltip>
          ) : (
            ''
          )
        ) : (
          '-'
        )
    },
    {
      name: 'Localização',
      columnKey: 'state',
      render: (item: ReportRuralProperty) =>
        isOpen[item.id] && item.virtualRegistration ? (
          ''
        ) : (
          <FormerValueTooltip
            fieldName="state"
            history={item.field.history}
            content={getFormerAddressContent(item.address, item.field.history)}
          >
            {handleNullValue(mountReportAddress(item.address, 'state'))}
          </FormerValueTooltip>
        )
    },
    {
      name: '% de detenção',
      columnKey: 'number',
      render: (item: ReportRuralProperty) =>
        isOpen[item.id] && item.virtualRegistration ? (
          ''
        ) : (
          <FormerValueTooltip
            fieldName="detentionPercentage"
            history={item.field.history}
            formatter={(value: string) => addPercentage(value)}
          >
            {handleNullValue(
              getOwnerReport(item.owners)?.detentionPercentage,
              'number',
              addPercentage
            )}
          </FormerValueTooltip>
        )
    }
  ];

  const actionColumn = [
    {
      positionModifier: TablePositionModifier.RIGHT,
      hasActions: true,
      name: '',
      columnKey: 'actions',
      render: (item: ReportRuralProperty) =>
        isOpen[item.id] && item.virtualRegistration ? (
          ''
        ) : (
          <>
            <ProviderTooltip
              searchedByHomonym={item.field.searchedByHomonym}
              providers={item.field.providers}
              dataTestId="rural-provider-tooltip"
            />
            <Actions
              caseId={caseId}
              reportId={reportId}
              ruralProperty={item}
              addToCase={addToCase}
            />
          </>
        )
    }
  ];

  const rowKey = (item: ReportRuralProperty) => item.id;

  if (isSummary) {
    return (
      <DynamicTable
        columns={[...summaryColumns]}
        data={ruralPropertiesWithRegistrationsList}
        rowKey={rowKey}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getRowClassName={(item: any) =>
          cn({
            [style.summaryTableRow]: !item.isChildrenRowOpen
          })
        }
        noAutoPaginate
        isLoading={isLoading}
      />
    );
  }

  return (
    <ReportDynamicTable
      columns={[...columns, ...actionColumn]}
      data={ruralPropertiesWithRegistrationsList}
      rowKey={rowKey}
      isLoading={isLoading}
      pagination={pagination}
      emptyText={emptyText}
      reportAssetName={ReportAssetName.RURAL_PROPERTY}
      reportAssetChildrenName={ReportAssetName.REGISTRATION}
      childrenField="virtualRegistration"
    />
  );
};

export default Table;
