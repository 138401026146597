import { useEffect } from 'react';

import { useChannel } from 'components';
import { DataImport } from 'types';
import { openErrorToast, openSuccessToast } from 'ui';
import { fetchLastDataImport } from 'api';

function useLiveDataImport(
  dataImport: DataImport | undefined,
  setDataImport: (dataImport: DataImport) => void
) {
  const { channel, isConnected } = useChannel('data_imports');

  async function onDataImportUpdate(response: DataImport): Promise<void> {
    if (!dataImport) setDataImport(response);

    if (
      dataImport &&
      response.processedCaseImportsCount > dataImport.processedCaseImportsCount!
    ) {
      setDataImport(response);
    }
  }

  async function handleFetchLastDataImport(): Promise<void> {
    try {
      const { data } = await fetchLastDataImport();
      setDataImport(data);
    } catch (error) {
      if (error.status !== 404) {
        openErrorToast(
          'Ocorreu um erro ao verificar se há importações em andamento'
        );
      }
    }
  }

  useEffect(() => {
    if (!channel || !isConnected) return;

    channel!.on('new_data_import', response => {
      onDataImportUpdate(response);
    });

    channel!.on('processed_case_import', response => {
      onDataImportUpdate(response);
    });

    channel!.on('finished_data_import', response => {
      onDataImportUpdate(response);
      handleFetchLastDataImport();
      openSuccessToast('Importação finalizada');
    });

    return () => {
      if (channel) {
        channel.off('processed_case_import');
        channel.off('new_data_import');
        channel.off('finished_data_import');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, isConnected]);

  return [isConnected];
}

export { useLiveDataImport };
