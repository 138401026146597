import React from 'react';
import cn from 'classnames';

import { LegalReportOrderStatus } from 'types';
import { Icon, Spinner } from 'ui';
import { useLegalReport } from 'components';
import { handleNullValue } from 'utils';

import styles from './LegalReportStatusMessage.module.scss';

type Props = {
  status: LegalReportOrderStatus | undefined;
};

const LegalReportStatusMessage = ({ status }: Props) => {
  const { legalReportQuotationData } = useLegalReport();
  const legalReportContent = legalReportQuotationData?.content;

  if (status === LegalReportOrderStatus.FINISHED) {
    return (
      <div className={cn([styles.boxMessage, styles.success])}>
        <Icon
          name="checked-rounded"
          small
          fill="#43A047"
          className={styles.icon}
          dataTestId="legal-report-status-message-finished"
        />
        {`Busca realizada com sucesso, ${handleNullValue(
          legalReportContent?.totalLawsuits
        )} novos processos encontrados.`}
      </div>
    );
  }

  if (status === LegalReportOrderStatus.SUSPENDED) {
    return (
      <div className={cn([styles.boxMessage, styles.failure])}>
        <Icon
          name="warning"
          small
          fill="#E53935"
          className={styles.icon}
          dataTestId="legal-report-status-message-suspended"
        />
        Erro ao buscar processos no provedor Digesto. Tente novamente ou
        comunique sobre a falha.
      </div>
    );
  }

  return (
    <div className={cn([styles.boxMessage, styles.loading])}>
      <Spinner color="blue" dataTestId="legal-report-status-message-loading" />
      Buscando processos. Esta busca pode demorar até 72 horas, aguarde.
    </div>
  );
};

export default LegalReportStatusMessage;
