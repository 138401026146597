import { ERROR_MESSAGE, ReportAssetName } from 'domain/reports';
import { mountReportAddress } from 'domain/reportLocation';
import { buildPaginationParams } from 'domain/pagination';
import React from 'react';

import {
  Accordion,
  DropDown,
  Card,
  openSuccessToast,
  openErrorToast,
  TablePositionModifier,
  DynamicTable,
  StackMargin,
  Stack
} from 'ui';

import { FormerValueTooltip, ProviderTooltip, SummaryError } from 'components';
import {
  addPartnershipCompanyToCase,
  createReportFromPartnershipCompany,
  fetchReportPartnershipCompanies,
  fetchReportPartnershipCompaniesSummary,
  fetchReportPartnershipCompaniesTotalValue
} from 'api';
import { applyMask, handleNullValue, applyPercentMask } from 'utils';
import compact from 'lodash/compact';
import { openReportTab } from 'pages/reports/utils';

import { ReportPartnershipCompanies, ActionName, ActionEntity } from 'types';
import * as Actions from 'utils/actions';

import SummaryTitle from 'pages/reports/SharedComponents/SummaryTitle';

import { useQuery } from 'hooks';
import ReportDynamicTable from '../../ReportDynamicTable/ReportDynamicTable';
import style from './PartnershipCompaniesTable.module.scss';

type Props = {
  caseId: string;
  reportId: string;
  addToCase?: boolean;
  research?: boolean;
  isSummary?: boolean;
  providerStatus?: string;
  tableMessage?: string;
};

const PartnershipCompaniesTable = ({
  caseId,
  reportId,
  addToCase = true,
  research = true,
  isSummary,
  providerStatus,
  tableMessage
}: Props) => {
  const query = isSummary
    ? fetchReportPartnershipCompaniesSummary
    : fetchReportPartnershipCompanies;

  const {
    data: partnershipCompanies = [],
    isLoading,
    pagination,
    error,
    refetch
  } = useQuery(query, {
    caseId,
    reportId
  });

  const { data: partnershipCompaniesTotalValue } = useQuery(
    fetchReportPartnershipCompaniesTotalValue,
    {
      caseId,
      reportId
    }
  );

  if (error) {
    return (
      <SummaryError
        title="Empresas em que é sócia"
        message={ERROR_MESSAGE.partnershipCompanies}
      />
    );
  }

  const totalAmount = handleNullValue(
    partnershipCompaniesTotalValue?.totalValue,
    'string',
    value => applyMask('currency', value)
  );

  const handleAddToCase = (
    partnershipCompanyId: ReportPartnershipCompanies['id']
  ) => async () => {
    try {
      await addPartnershipCompanyToCase({
        caseId,
        reportId,
        contentId: partnershipCompanyId
      });
      openSuccessToast('Empresa adicionada ao caso com sucesso!');
    } catch (error) {
      openErrorToast('Não foi possível adicionar a empresa ao caso.');
    }
  };

  const handleSearch = (
    partnershipCompanyId: ReportPartnershipCompanies['id']
  ) => async () => {
    try {
      const response = await createReportFromPartnershipCompany({
        caseId,
        reportId,
        contentId: partnershipCompanyId
      });

      openSuccessToast('Consulta salva com sucesso!');
      openReportTab(response.data);
    } catch (error) {
      openErrorToast('Houve um erro ao criar a consulta.');
    }
  };

  const buildActions = ({
    field: { actions },
    id
  }: ReportPartnershipCompanies) => {
    const createReportAction = {
      text: 'Buscar',
      callback: handleSearch(id),
      isDisabled: Actions.isDisabled(actions, ActionName.CREATE_REPORT),
      description: Actions.unavailableReasons(
        actions,
        ActionName.CREATE_REPORT,
        ActionEntity.COMPANY
      )
    };

    const addToCaseAction = {
      text: 'Adicionar ao caso',
      callback: handleAddToCase(id!),
      isDisabled: Actions.isDisabled(actions, ActionName.ADD_TO_CASE),
      description: Actions.unavailableReasons(
        actions,
        ActionName.ADD_TO_CASE,
        ActionEntity.COMPANY
      )
    };

    const allActionsAllowed = compact([
      addToCase && addToCaseAction,
      research && createReportAction
    ]);

    return allActionsAllowed;
  };

  const isEmpty = partnershipCompanies.length < 1;

  const summaryColumns = [
    {
      name: 'Razão social e CNPJ ',
      columnKey: 'cnpj',
      render: (item: ReportPartnershipCompanies) => {
        return (
          <>
            <div>
              {handleNullValue(item.cnpj, 'string', value =>
                applyMask('cnpj', value)
              )}
            </div>
            <div className={style.businessNameSummary}>
              {handleNullValue(item.businessName)}
            </div>
          </>
        );
      }
    },
    {
      name: 'Faturamento e PLR',
      columnKey: 'businessName',
      render: (item: ReportPartnershipCompanies) => {
        return (
          <>
            <div>{handleNullValue(item.estimatedRevenue)}</div>
            <div className={style.prlSummary}>
              {handleNullValue(item.partnershipShare, 'number', value =>
                applyPercentMask(value)
              )}
              %
            </div>
          </>
        );
      }
    }
  ];

  const standardColumns = [
    {
      name: 'CNPJ',
      columnKey: 'cnpj',
      render: (item: ReportPartnershipCompanies) => (
        <FormerValueTooltip
          fieldName="cnpj"
          fieldValue={item.cnpj}
          history={item.field.history}
          formatter={value => applyMask('cnpj', value)}
        />
      )
    },
    {
      name: 'Razão social',
      columnKey: 'businessName',
      render: (item: ReportPartnershipCompanies) => (
        <FormerValueTooltip
          fieldName="businessName"
          fieldValue={item.businessName}
          history={item.field.history}
        />
      )
    },
    {
      name: 'Participação',
      columnKey: 'partnershipShare',
      shouldSkipCollapse: true,
      render: (item: ReportPartnershipCompanies) => (
        <FormerValueTooltip
          fieldName="partnershipShare"
          fieldValue={item.partnershipShare}
          history={item.field.history}
        />
      )
    },
    {
      name: 'Faturamento',
      columnKey: 'estimatedRevenue',
      render: (item: ReportPartnershipCompanies) => (
        <FormerValueTooltip
          fieldName="estimatedRevenue"
          fieldValue={item.estimatedRevenue}
          history={item.field.history}
        />
      )
    }
  ];

  const columns = [
    {
      name: 'Capital social',
      columnKey: 'capitalSocialCents',
      render: (item: ReportPartnershipCompanies) => (
        <FormerValueTooltip
          fieldName="capitalSocialCents"
          history={item.field.history}
        >
          {item.capitalSocialCents
            ? applyMask('currency', item.capitalSocialCents.toString())
            : handleNullValue(item.capitalSocialCents, 'string', value =>
                applyMask('currency', value)
              )}
        </FormerValueTooltip>
      )
    },
    {
      name: 'Endereço',
      columnKey: 'adress',
      render: (item: ReportPartnershipCompanies) => (
        <FormerValueTooltip
          fieldName="address"
          fieldValue={mountReportAddress(item.address)}
          history={item.field.history}
        />
      )
    },
    {
      name: 'Qualificação',
      columnKey: 'role',
      render: (item: ReportPartnershipCompanies) => (
        <FormerValueTooltip
          fieldName="role"
          fieldValue={item.role}
          history={item.field.history}
        />
      )
    },
    {
      name: 'Ramo de atividade',
      columnKey: 'industry',
      render: (item: ReportPartnershipCompanies) => (
        <FormerValueTooltip
          fieldName="industry"
          fieldValue={item.industry}
          history={item.field.history}
        />
      )
    },
    {
      name: 'Situação',
      columnKey: 'status',
      shouldSkipCollapse: true,
      render: (item: ReportPartnershipCompanies) => (
        <FormerValueTooltip
          fieldName="status"
          fieldValue={item.status}
          history={item.field.history}
        />
      )
    }
  ];

  const actionColumn = [
    {
      positionModifier: TablePositionModifier.RIGHT,
      shouldSkipCollapse: true,
      hasActions: true,
      name: '',
      columnKey: 'actions',
      render: (item: ReportPartnershipCompanies) => (
        <>
          <ProviderTooltip
            providers={item.field.providers}
            searchedByHomonym={item.field.searchedByHomonym}
            dataTestId="provider-tooltip"
          />
          {buildActions(item).length > 0 && (
            <DropDown options={buildActions(item)} />
          )}
        </>
      )
    }
  ];

  const rowKey = (item: ReportPartnershipCompanies) => item.id;

  const refetchWithPagination = (pagination?: {
    page: number;
    pageSize: number;
  }) => {
    refetch({
      caseId,
      reportId,
      pagination
    });
  };

  const paginationProps = buildPaginationParams(
    pagination,
    refetchWithPagination
  );

  if (isSummary) {
    return (
      <>
        <Stack marginBottom={StackMargin.SMALL}>
          <SummaryTitle
            headerInfo={{
              label: 'Soma do capital social das empresas:',
              value: totalAmount
            }}
          >
            {`Empresas em que é sócia (${partnershipCompanies.length})`}
          </SummaryTitle>
        </Stack>
        <DynamicTable
          columns={[...summaryColumns, ...columns]}
          data={partnershipCompanies}
          rowKey={rowKey}
          getRowClassName={() => style.summaryTableRow}
          noAutoPaginate
          isLoading={isLoading}
        />
      </>
    );
  }

  return (
    <Accordion
      title="Empresas em que é sócia"
      badgeContent={pagination?.totalEntries}
      tooltipText={providerStatus}
      headerInfo={{
        label: 'Soma do capital social das empresas:',
        value: totalAmount
      }}
      isClosed={isEmpty && !tableMessage}
    >
      <Card small>
        <ReportDynamicTable
          columns={[...standardColumns, ...columns, ...actionColumn]}
          data={partnershipCompanies}
          rowKey={rowKey}
          isCollapsible
          isLoading={isLoading}
          pagination={paginationProps}
          emptyText={tableMessage}
          reportAssetName={ReportAssetName.PARTNERSHIP_COMPANIES}
        />
      </Card>
    </Accordion>
  );
};

export default PartnershipCompaniesTable;
