import React, { ReactElement } from 'react';
import cn from 'classnames';
import { FormikHandlers } from 'formik';

import {
  applyDateMask,
  applyMask,
  revertMask,
  formatMaxDate,
  handleNullValue
} from 'utils';

import { Icon } from 'ui';

import './InputField.scss';
import InputLoader from 'ui/InputLoader/InputLoader';

const LIMIT: { [key: string]: number } = {
  date: 10,
  cpf: 14,
  cnpj: 18,
  currency: 24,
  percent: 6,
  cep: 9,
  area: 20
};

type Props = {
  id: string;
  name: string;
  type:
    | 'date'
    | 'text'
    | 'cpf'
    | 'cnpj'
    | 'currency'
    | 'password'
    | 'percent'
    | 'cep'
    | 'area';
  limit?: number;
  value?: string | null;
  title?: string;
  error?: Array<string> | string;
  placeholder?: string;
  icon?: string;
  onChange: FormikHandlers['handleChange'];
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  showMode?: boolean;
  dataTestId?: string;
  showCounter?: boolean;
  disabled?: boolean;
  autoComplete?: string;
  max?: string | number;
  isLoading?: boolean;
  className?: string;
};

const InputField = ({
  id,
  name,
  type,
  value,
  onChange,
  onBlur = () => {},
  onFocus = () => {},
  title,
  placeholder = 'Digite aqui…',
  error,
  showMode,
  dataTestId,
  icon,
  limit,
  showCounter = false,
  disabled = false,
  autoComplete = 'off',
  max,
  isLoading = false,
  className
}: Props): ReactElement => {
  const sanitizedValue = value || '';
  const disabledPlaceholder = 'Indisponível';

  const inputClass = cn([
    'input-field',
    Boolean(error) && '-error',
    Boolean(icon) && '-icon',
    Boolean(className) && className
  ]);

  function setType(type: string): string {
    if (!type) {
      return 'text';
    }
    const types: { [key: string]: string } = {
      date: 'text',
      text: 'text',
      password: 'password'
    };

    return types[type] ?? 'text';
  }

  const maxLength = limit || LIMIT[type];

  if (isLoading) {
    return (
      <InputLoader name={name} title={title} id={id} dataTestId={dataTestId} />
    );
  }

  if (showMode) {
    const maskedValue =
      type === 'date'
        ? applyDateMask(sanitizedValue)
        : applyMask(type, sanitizedValue);

    return (
      <div className={inputClass} data-testid="input-field-show-mode">
        {title && <span className="label">{title}</span>}
        <span className="value">{handleNullValue(maskedValue)}</span>
      </div>
    );
  }

  return (
    <div className={inputClass} data-testid="input-field">
      {title && (
        <label className="label" htmlFor={id} data-testid="input-field-label">
          {title}
          {showCounter && ` (${sanitizedValue.length}/${maxLength})`}
        </label>
      )}
      <div className="container">
        <input
          autoComplete={autoComplete}
          disabled={disabled}
          data-testid={dataTestId}
          maxLength={maxLength}
          className={cn('input', className)}
          id={id}
          name={name}
          type={setType(type)}
          value={applyMask(type, sanitizedValue)}
          onChange={event => {
            const target = event.target;
            target.value = revertMask(type, event.target.value);

            return onChange({ ...event, target });
          }}
          onBlur={onBlur}
          onFocus={onFocus}
          placeholder={disabled ? disabledPlaceholder : placeholder}
          max={handleMaxValue(max)}
        />
        {icon && <Icon name={icon} />}
      </div>
      {error && (
        <p className="message">
          {Array.isArray(error) ? error.join(', ') : error}
        </p>
      )}
    </div>
  );
};

function handleMaxValue(max: string | number | undefined) {
  if (!max) return '';
  return typeof max === 'number' ? max : formatMaxDate(max);
}

export type InputFieldProps = Props;
export default InputField;
