import { handleDateFieldChange } from 'domain/forms';
import React from 'react';

import { DateTime } from 'luxon';

import * as Yup from 'yup';
import { getIn, Formik } from 'formik';

import { DatePickerField, FormContainer, InputField, Button } from 'ui';
import { Appraisal } from 'types';

const validationSchema = Yup.object().shape({
  valueCents: Yup.number()
    .moreThan(0, 'Valor deve ser maior que zero')
    .required('Campo obrigatório.')
    .typeError('Deve ser um número')
    .nullable(),
  date: Yup.date()
    .required('Campo obrigatório.')
    .max(new Date(), 'A data deve ser igual ou anterior a hoje')
    .nullable(),
  companyName: Yup.string().nullable()
});

type Props = {
  onSubmit: (appraisalAddData: Appraisal) => void;
  appraisaldata?: Appraisal;
};

const Form = ({ onSubmit, appraisaldata }: Props) => {
  const initialValues = appraisaldata ?? {
    id: Date.now().toString(),
    date: '',
    valueCents: 0,
    companyName: '',
    marketValueCents: null
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {formik => (
        <FormContainer>
          <InputField
            type="currency"
            id="valueCents"
            name="valueCents"
            title="Valor liquidação forçada"
            value={formik.values.valueCents?.toString()}
            error={
              formik.touched.valueCents && getIn(formik.errors, 'valueCents')
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <DatePickerField
            id="date"
            name="date"
            title="Data da avaliação"
            placeholder="Digite aqui..."
            value={formik.values.date}
            error={
              getIn(formik.touched, 'date') && getIn(formik.errors, 'date')
            }
            onChange={handleDateFieldChange(
              formik.setFieldValue,
              formik.setFieldTouched,
              'date'
            )}
            onBlur={formik.handleBlur}
            max={DateTime.local().toISO()}
          />

          <InputField
            type="currency"
            id="marketValueCents"
            name="marketValueCents"
            title="Valor de mercado (opcional)"
            value={formik.values.marketValueCents?.toString()}
            error={
              formik.touched.marketValueCents &&
              getIn(formik.errors, 'marketValueCents')
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <InputField
            type="text"
            id="companyName"
            name="companyName"
            title="Empresa avaliadora (opcional)"
            value={formik.values.companyName}
            error={
              formik.touched.companyName && getIn(formik.errors, 'companyName')
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <Button
            type="submit"
            dataTestId="submit-button"
            highlight
            centered
            disabled={!formik.isValid || !formik.dirty}
            onClick={formik.handleSubmit}
          >
            {appraisaldata ? 'Salvar avaliação' : 'Adicionar avaliação'}
          </Button>
        </FormContainer>
      )}
    </Formik>
  );
};

export default Form;
