import React, {
  useState,
  createContext,
  useContext,
  ReactElement
} from 'react';
import { DataImport } from 'types';
import { useLiveDataImport } from 'hooks';

type State = {
  dataImport: DataImport | undefined;
  setDataImport: (dataImport: DataImport) => void;
  isConnected: boolean;
};

const DataImportContext = createContext<State>({
  dataImport: undefined,
  setDataImport: (dataImport: DataImport) => {},
  isConnected: false
});

type Props = {
  children: ReactElement | ReactElement[];
};

const DataImportProvider = ({ children }: Props) => {
  const [dataImport, setDataImport] = useState<DataImport>();
  const [isConnected] = useLiveDataImport(dataImport, setDataImport);

  return (
    <DataImportContext.Provider
      value={{ dataImport, setDataImport, isConnected }}
    >
      {children}
    </DataImportContext.Provider>
  );
};

const useDataImport = () => {
  return useContext(DataImportContext);
};

export { useDataImport, DataImportProvider };
