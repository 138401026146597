import {
  FieldHistory,
  QueryStatus,
  Report,
  ReportExpectedTable,
  Status
} from 'types';
import { numericDateFormat, applyDateMask } from 'utils';

export function reportPercentageCompleted(report: Report): number {
  if (report.totalQueries === 0) return 100;
  return Math.round((report.completedQueries / report.totalQueries) * 100);
}

export function reportStatus(report?: Report | null) {
  if (!report || report.totalQueries === 0) return Status.NOSTATUS;
  if (report.totalQueries !== report.completedQueries + report.failedQueries)
    return Status.PENDING;
  if (report.failedQueries === report.totalQueries) return Status.ERROR;
  if (report.failedQueries > 0) return Status.PARTIAL;

  return Status.SUCCEEDED;
}

export function reportStatusText(report: Report, status: Status) {
  if (status === Status.NOSTATUS) {
    return 'Não gerado';
  }

  if (status === Status.SUCCEEDED) {
    return `Finalizado (${applyDateMask(report.finishedAt!)})`;
  }
  if (status === Status.PARTIAL) {
    return `Relatório parcial ${reportPercentageCompleted(
      report
    )}% (${applyDateMask(report.insertedAt)})`;
  }

  if (status === Status.ERROR) {
    return 'Erro ao gerar relatório';
  }

  return `Gerando... ${reportPercentageCompleted(report)}% (${applyDateMask(
    report.insertedAt
  )})`;
}

export function reportLink(report: Report): string {
  return `/cases/${report.caseId}/reports/${report.id}`;
}

export const getExpectedTableQueries = (
  data: Report,
  tableName: ReportExpectedTable,
  type: 'error' | 'success'
) => {
  if (!data) return false;

  if (type === 'error') {
    return data.queries.filter(
      query =>
        query.status === QueryStatus.FAILED &&
        query.expectedTables.some(t => t === tableName)
    );
  }

  return data.queries.filter(
    query =>
      (query.status === QueryStatus.SUCCEEDED ||
        query.status === QueryStatus.NOT_FOUND) &&
      query.expectedTables.some(t => t === tableName)
  );
};

export const ERROR_MESSAGE = {
  airplanes: 'Não foi possível exibir a listagem de aeronaves',
  externalLawsuits:
    'Não foi possível exibir a listagem de relatórios de processos',
  locations: 'Não foi possível exibir a listagem de localizações',
  partners: 'Não foi possível exibir a listagem de estrutura societária',
  realEstate: 'Não foi possível exibir a listagem de imóveis',
  relatedCompanies:
    'Não foi possível exibir a listagem de empresas relacionadas',
  ruralProperty: 'Não foi possível exibir a listagem de imóveis rurais',
  vehicles: 'Não foi possível exibir a listagem de veículos',
  partnershipCompanies:
    'Não foi possível exibir a listagem de empresas que é sócia',
  bookmarkedInstagramPublications:
    'Não foi possível exibir a listagem de posts salvos do Instagram',
  electoralDonations:
    'Não foi possível exibir a listagem de doações eleitorais',
  familyMember: 'Não foi possível exibir a listagem de família',
  instagramPublications:
    'Não foi possível exibir a listagem de publicações no Instagram',
  otherInformations: 'Não foi possível exibir outras informações',
  relatedInstagramProfiles:
    'Não foi possível exibir a listagem de perfis de Instagram relacionados',
  cnae: 'Não foi possível exibir a listagem de CNAES'
};

export enum ReportAssetName {
  FAMILY_MEMBERS = 'familiar',
  PARTNERSHIP_COMPANIES = 'empresa',
  REAL_ESTATE = 'imóvel',
  ELECTORAL_DONATIONS = 'doação eleitoral',
  AIRPLANES = 'aeronave',
  VEHICLES = 'veículo',
  LOCATION = 'locação',
  PARTNERS = 'sociedade',
  RELATED_COMPANY = 'empresa relacionada',
  RURAL_PROPERTY = 'imóvel rural',
  REGISTRATION = 'matrícula'
}

enum TextGenre {
  MALE = 'M',
  FEMALE = 'F'
}

type TextGenreType = TextGenre.MALE | TextGenre.FEMALE;

const newText = (assetName: ReportAssetName, genre: TextGenreType): string => {
  return genre === TextGenre.MALE
    ? `Novo ${assetName} encontrado`
    : `Nova ${assetName} encontrada`;
};

const lastSeenText = (
  assetName: ReportAssetName,
  genre: TextGenreType,
  date: string
): string => {
  const genreText = genre === TextGenre.MALE ? 'esse' : 'essa';

  return `Desde o relatório do dia ${date} não é possível retornar ${genreText} ${assetName}`;
};

export const renderTooltipText = (
  history: FieldHistory | null,
  assetName: ReportAssetName
): string => {
  if (history) {
    const maleReportAssetNames = [
      ReportAssetName.FAMILY_MEMBERS,
      ReportAssetName.REAL_ESTATE,
      ReportAssetName.VEHICLES,
      ReportAssetName.RURAL_PROPERTY
    ];
    const genre = maleReportAssetNames.includes(assetName)
      ? TextGenre.MALE
      : TextGenre.FEMALE;

    if (history.isReportNew) return '';

    if (history.isNew) return newText(assetName, genre);

    if (history.lastSeenAt)
      return lastSeenText(
        assetName,
        genre,
        numericDateFormat(history.lastSeenAt)
      );
  }

  return '';
};
