import React, { useEffect } from 'react';
import Layout from 'Layouts/Layout';
import { Header, Stack, StackMargin, Title } from 'ui';
import { can, Action } from 'api';

import UsefulLinksList from './UsefulLinksList';
import style from './UsefulLinks.module.scss';

const UsefulLinks = () => {
  const canUser = {
    accessUsefulLinks: can(Action.LINKS_UTEIS_LINKS_CONSULTAR)
  };

  useEffect(() => {
    if (canUser.accessUsefulLinks) return;

    window.location.replace('/permission_denied');
  }, [canUser.accessUsefulLinks]);

  return (
    <Layout>
      <Header className={style.header}>
        <Title>Links Úteis</Title>
      </Header>
      <Stack marginTop={StackMargin.XLARGE} className={style.container}>
        <UsefulLinksList />
      </Stack>
    </Layout>
  );
};

export default UsefulLinks;
