import { buildPaginationParams } from 'domain/pagination';
import React, { useContext, useState } from 'react';

import {
  createEntityReport,
  refreshReport,
  deleteEntity,
  createEntity,
  retryReportFailedQueries,
  can,
  Action
} from 'api';
import { CaseContext, usePaginationContext } from 'components';
import { useFeatureFlag } from 'hooks';

import {
  CaseEntity,
  Feature,
  Report,
  ApiPagination,
  PaginatedResource
} from 'types';

import {
  Card,
  Button,
  Modal,
  openSuccessToast,
  openErrorToast,
  SubTitle
} from 'ui';
import {
  default as SuspectsForm,
  FormType
} from 'components/SuspectsForm/Form';
import SuspectTable from './SuspectTable';

type Props = {
  caseId: string;
  suspects: CaseEntity[];
  setSuspects: React.Dispatch<React.SetStateAction<CaseEntity[] | undefined>>;
  refetchReports: () => void;
  isLoading: boolean;
  refetchSuspects: () => void;
  pagination?: ApiPagination;
};

const Suspects = ({
  caseId,
  suspects,
  setSuspects,
  refetchReports,
  isLoading,
  refetchSuspects,
  pagination
}: Props) => {
  const { changePagination } = usePaginationContext();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [reportIsEnabled] = useFeatureFlag(Feature.CASE_REPORTS);
  const { caseData } = useContext(CaseContext);

  const updateEntityInListing = (updatedEntity: CaseEntity) => {
    const updatedEntities = suspects.map(suspect =>
      suspect.id === updatedEntity.id ? updatedEntity : suspect
    );
    setSuspects(updatedEntities);
  };

  const updateReportInListing = (entity: CaseEntity, updatedReport: Report) => {
    const updatedEntities = suspects.map(suspect =>
      suspect.id === entity.id
        ? { ...suspect, currentReport: updatedReport }
        : suspect
    );
    setSuspects(updatedEntities);
  };

  const handleEntityCreateSuccess = (_entity: CaseEntity) => {
    openSuccessToast('Pessoa adicionada com sucesso!');
    refetchSuspects();
    setModalOpen(false);
  };

  const handleEntityCreateError = () => {
    openErrorToast('Não foi possível adicionar a pessoa.');
  };

  const handleDelete = async (entityId: string): Promise<void> => {
    if (!window.confirm('Você tem certeza de que deseja remover esta pessoa?'))
      return;
    try {
      await deleteEntity(caseId, entityId);
      openSuccessToast('Pessoa removida com sucesso!');
      refetchSuspects();
      refetchReports();
    } catch (error) {
      openErrorToast('Não foi possível remover a pessoa.');
    }
  };

  const handleCreateEntityReport = async (entityId: string): Promise<void> => {
    try {
      const { data: updatedEntity } = await createEntityReport(
        caseId,
        entityId
      );
      updateEntityInListing(updatedEntity);
      openSuccessToast(
        'Gerando relatório! Aguarde o término para visualizá-lo.'
      );
    } catch (error) {
      openErrorToast('Não foi possível gerar o relatório.');
    }
  };

  const handleUpdateReport = async (
    entity: CaseEntity,
    isErrorReport: boolean
  ): Promise<void> => {
    try {
      const { data: updatedReport } = await refreshReport(
        entity.currentReport!.caseId,
        entity.currentReport!.id
      );
      updateReportInListing(entity, updatedReport);
      openSuccessToast(
        isErrorReport
          ? 'Gerando relatório! Aguarde o término para visualizá-lo.'
          : 'Atualizando relatório! Aguarde o término para visualizá-lo.'
      );
    } catch (error) {
      openErrorToast(
        isErrorReport
          ? 'Não foi possível gerar o relatório.'
          : 'Não foi possível refazer as consultas.'
      );
    }
  };

  const handleRetryFailedQueries = async (entity: CaseEntity) => {
    try {
      await retryReportFailedQueries(caseId, entity.currentReport!.id);
      openSuccessToast(
        'Refazendo consultas que falharam! Aguarde o término para visualizá-las.'
      );
    } catch (error) {
      openErrorToast('Não foi possível refazer as consultas.');
    }
  };

  const handleChangePagination = (pagination?: {
    page: number;
    pageSize: number;
  }) => {
    const { page, pageSize } = pagination!;
    changePagination(PaginatedResource.PEOPLE_SUSPECTS, { page, pageSize });
  };

  const paginationProps = buildPaginationParams(
    pagination,
    handleChangePagination
  );

  const canAddSupects = can(
    Action.CASOS_RASTREAMENTO_SUSPEITOS_INCLUIR,
    caseData?.negotiator.id
  );

  return (
    <div className="suspects-container" data-testid="suspects-list">
      <SubTitle
        badgeText={pagination?.totalEntries}
        badgeTestId="suspect-count"
      >
        Suspeitos
      </SubTitle>

      <Card small>
        <SuspectTable
          suspects={suspects}
          handleDelete={handleDelete}
          handleCreateEntityReport={handleCreateEntityReport}
          handleUpdateReport={handleUpdateReport}
          handleRetryFailedQueries={handleRetryFailedQueries}
          reportIsEnabled={reportIsEnabled}
          pagination={paginationProps}
        />

        {canAddSupects && (
          <div className="actions">
            <Button
              dataTestId="suspect-modal-trigger"
              outline
              highlight
              centered
              small
              onClick={() => setModalOpen(true)}
              disabled={isLoading}
            >
              Adicionar suspeito
            </Button>
          </div>
        )}
      </Card>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        title="Novo(a) suspeito(a)"
      >
        <SuspectsForm
          formMode={FormType.CREATE}
          onSuccess={handleEntityCreateSuccess}
          onError={handleEntityCreateError}
          createFunction={createEntity}
          args={{ caseId }}
        />
      </Modal>
    </div>
  );
};

export default Suspects;
