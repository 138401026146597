import React from 'react';
import { Link, Icon, ToolTip } from 'ui';

type Props = {
  downloadUrl?: string;
  error?: boolean;
  isUploading?: boolean;
  isDeleting?: boolean;
  onResend?: () => void;
  onDelete?: () => void;
  onCancel?: () => void;
  onUndoDelete?: () => void;
  showDelete?: boolean;
};

const FileActions = ({
  error,
  isUploading,
  isDeleting,
  onResend,
  onDelete,
  onCancel,
  onUndoDelete,
  downloadUrl,
  showDelete = true
}: Props) => {
  if (isDeleting) {
    return (
      <div className="actions">
        <ToolTip placement="top" content="Desfazer deleção">
          <div>
            <Link onClick={onUndoDelete} dataTestId="undo-delete-trigger">
              <Icon name="undo" fill="#62667A" />
            </Link>
          </div>
        </ToolTip>
      </div>
    );
  }

  return (
    <div className="actions">
      {error && (
        <ToolTip placement="top" content="Reenviar">
          <div>
            <Link highlight onClick={onResend} dataTestId="resend-trigger">
              <Icon name="refresh" />
            </Link>
          </div>
        </ToolTip>
      )}

      {!isUploading && !error && (
        <>
          {downloadUrl && (
            <ToolTip placement="top" content="Download">
              <div>
                <Link
                  highlight
                  href={downloadUrl}
                  target="_blank"
                  dataTestId="download-trigger"
                >
                  <Icon name="download" />
                </Link>
              </div>
            </ToolTip>
          )}
          {showDelete && (
            <ToolTip placement="top" content="Excluir">
              <div>
                <Link onClick={onDelete} dataTestId="delete-trigger">
                  <Icon name="trash" />
                </Link>
              </div>
            </ToolTip>
          )}
        </>
      )}

      {(isUploading || error) && (
        <ToolTip placement="top" content="Cancelar upload">
          <div>
            <Link onClick={onCancel} dataTestId="cancel-trigger">
              <Icon name="close" />
            </Link>
          </div>
        </ToolTip>
      )}
    </div>
  );
};

export default FileActions;
