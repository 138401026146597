import {
  lawsuitNumber,
  internalLawsuitInitialValues,
  internalLawsuitValidationSchema
} from 'domain/lawsuits';
import React, { useState, useContext } from 'react';
import { generatePath, RouteComponentProps } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';

import {
  Header,
  Title,
  Button,
  Tabs,
  TabContent,
  openErrorToast,
  openSuccessToast
} from 'ui';

import {
  useQuery,
  useFeatureFlag,
  useHashAnchor,
  useInitNavigation
} from 'hooks';

import {
  TabContent as TabContentProps,
  InternalLawsuitAttributes,
  Feature
} from 'types';
import {
  deleteInternalLawsuit,
  updateInternalLawsuit,
  fetchInternalLawsuit,
  autoUpdateLawsuit,
  can,
  Action
} from 'api';

import { CaseContext } from 'components';

import Form from '../Form';
import Movements from '../Movements';
import ChildList from './ChildLawsuitList';
import Executions from './Executions';

import './Edit.scss';

const Edit = ({
  match: {
    params: { caseId, tab, lawsuitId, lawsuitTab },
    path
  }
}: RouteComponentProps<{
  caseId: string;
  tab: string;
  lawsuitId: string;
  lawsuitTab: string;
}>) => {
  const { history } = useInitNavigation(pathname => {
    const tab = lawsuitTab ? `/${lawsuitTab}` : '';
    return [pathname.replace(`/${lawsuitId}${tab}`, '')];
  });
  const [isWaitingResponse, setWaitingResponse] = useState(false);
  const [isMovementsEnable] = useFeatureFlag(Feature.LAWSUIT_MOVEMENTS);
  const [isChildLawsuitsEnable] = useFeatureFlag(Feature.LINKED_LAWSUITS);
  const [isExecutionsEnable] = useFeatureFlag(Feature.LAWSUITS_EXECUTIONS);

  const { data, error, isLoading, setData: setLawsuit } = useQuery(
    fetchInternalLawsuit,
    {
      caseId,
      lawsuitId
    }
  );

  useHashAnchor({ isLoading });

  const linkTo = (newTab: TabContentProps) => {
    if (!newTab.path) return;

    return generatePath(`${path}`, {
      caseId: caseId,
      tab: tab,
      lawsuitId: lawsuitId,
      lawsuitTab: newTab.path
    });
  };

  const handleDelete = async () => {
    if (
      window.confirm('Você tem certeza de que deseja remover esse processo?')
    ) {
      try {
        await deleteInternalLawsuit(caseId, lawsuitId);
        openSuccessToast('Processo removido com sucesso!');

        history.push(`/cases/${caseId}/internal_lawsuits`);
      } catch (error) {
        openErrorToast('Não foi possível remover o processo.');
      }
    }
  };

  const handleAutoUpdate = async () => {
    if (!data) return;

    try {
      const response = await autoUpdateLawsuit(caseId, data.id);

      setLawsuit(response.data);
    } catch (error) {
      openErrorToast(
        'Não foi possível ativar a atualização automática de movimentações.'
      );
    }
  };

  async function onSubmit(
    values: InternalLawsuitAttributes,
    formikHelpers: FormikHelpers<InternalLawsuitAttributes>
  ) {
    try {
      const response = await updateInternalLawsuit(caseId, data!.id, values);

      openSuccessToast('Processo atualizado com sucesso!');
      setLawsuit(response.data);
    } catch (error) {
      formikHelpers.setErrors(error.errors);
      openErrorToast('Erro ao atualizar processo!');
    }
  }

  if (error) openErrorToast('Erro ao buscar processo');

  const { caseData } = useContext(CaseContext);
  const negotiatorId = caseData?.negotiator?.id;
  const canUser = {
    deleteLawsuit: can(Action.CASOS_PROCESSO_EXCLUIR, negotiatorId),
    accessMovements: can(
      Action.CASOS_PROCESSO_MOVIMENTACOES_CONSULTAR,
      negotiatorId
    ),
    accessExecutions: can(
      Action.CASOS_PROCESSO_PENHORA_CONSULTAR,
      negotiatorId
    ),
    editExecution: can(Action.CASOS_PROCESSO_PENHORA_EDITAR, negotiatorId),
    addExecutionFile: can(
      Action.CASOS_PROCESSO_ARQUIVOS_DA_PENHORA_INCLUIR,
      negotiatorId
    ),
    removeExecutionFile: can(
      Action.CASOS_PROCESSO_ARQUIVOS_DA_PENHORA_EXCLUIR,
      negotiatorId
    ),
    editLawsuitBasicInfo: can(
      Action.CASOS_PROCESSO_INFORMACOES_BASICAS_EDITAR,
      negotiatorId
    ),
    addLawyer: can(Action.CASOS_PROCESSO_ADVOGADOS_INCLUIR, negotiatorId),
    removeLawyer: can(Action.CASOS_PROCESSO_ADVOGADOS_EXCLUIR, negotiatorId),
    addInvolvedParties: can(
      Action.CASOS_PROCESSO_PARTES_ENVOLVIDAS_INCLUIR,
      negotiatorId
    ),
    editInvolvedParties: can(
      Action.CASOS_PROCESSO_PARTES_ENVOLVIDAS_EDITAR,
      negotiatorId
    ),
    removeInvolvedParties: can(
      Action.CASOS_PROCESSO_PARTES_ENVOLVIDAS_EXCLUIR,
      negotiatorId
    ),
    addContractToLawsuit: can(
      Action.CASOS_PROCESSO_INFORMACOES_BASICAS_VINCULAR_CONTRATO,
      negotiatorId
    ),
    addJudicialRecoveryToLawsuit: can(
      Action.CASOS_PROCESSO_INFORMACOES_BASICAS_VINCULAR_RECUPERACAO,
      negotiatorId
    )
  };

  const canEditLawsuit =
    canUser.editLawsuitBasicInfo ||
    canUser.addLawyer ||
    canUser.removeLawyer ||
    canUser.addInvolvedParties ||
    canUser.editInvolvedParties ||
    canUser.removeInvolvedParties ||
    canUser.addContractToLawsuit ||
    canUser.addJudicialRecoveryToLawsuit ||
    canUser.editExecution ||
    canUser.addExecutionFile ||
    canUser.removeExecutionFile;

  return (
    <Formik<InternalLawsuitAttributes>
      enableReinitialize
      initialValues={internalLawsuitInitialValues(data)}
      validationSchema={internalLawsuitValidationSchema}
      onSubmit={onSubmit}
    >
      {form => {
        return (
          <div className="lawsuits-edit-page">
            <Header
              sticky
              actionsComponent={
                <>
                  {canUser.deleteLawsuit && (
                    <Button
                      small
                      outline
                      danger
                      type="button"
                      onClick={handleDelete}
                    >
                      Excluir processo
                    </Button>
                  )}
                  {canEditLawsuit && (
                    <Button
                      disabled={!form.dirty}
                      small
                      highlight
                      type="submit"
                      onClick={form.handleSubmit}
                    >
                      Salvar
                    </Button>
                  )}
                </>
              }
            >
              <Title>{lawsuitNumber(data?.number)}</Title>
            </Header>
            <Tabs linkTab={linkTo} defaultPath={lawsuitTab}>
              <TabContent
                title="Informações básicas"
                path="basic-information"
                dataTestId="lawsuits-basic-information-tab"
                isLoading={isLoading}
              >
                {data && (
                  <>
                    <Form
                      caseId={caseId}
                      onSubmit={onSubmit}
                      form={form}
                      isWaitingResponse={isWaitingResponse}
                      setWaitingResponse={setWaitingResponse}
                      isDisabledOnEdit={!canUser.editLawsuitBasicInfo}
                    />
                    {isChildLawsuitsEnable && (
                      <ChildList
                        childLawsuits={data.paginatedChildren.data}
                        paginationParams={data.paginatedChildren.pagination}
                        caseId={caseId}
                        parentLawsuitId={data.id}
                        parentLawsuitNumber={data.number}
                      />
                    )}
                  </>
                )}
              </TabContent>
              {isMovementsEnable && canUser.accessMovements ? (
                <TabContent
                  title="Movimentações"
                  path="movements"
                  dataTestId="movements-tab"
                >
                  {data && (
                    <Movements
                      caseId={caseId}
                      lawsuit={data}
                      onAutoUpdateToggle={handleAutoUpdate}
                    />
                  )}
                </TabContent>
              ) : (
                <></>
              )}

              {isExecutionsEnable && canUser.accessExecutions ? (
                <TabContent
                  title="Penhora"
                  path="executions"
                  dataTestId="executions-tab"
                  isLoading={isLoading}
                >
                  {data && (
                    <Executions
                      form={form}
                      caseId={caseId}
                      lawsuit={data}
                      onUpdateFiles={ids =>
                        form.setFieldValue('fileUploadIds', [...ids])
                      }
                    />
                  )}
                </TabContent>
              ) : (
                <></>
              )}
            </Tabs>
          </div>
        );
      }}
    </Formik>
  );
};

export default Edit;
