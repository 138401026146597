import { ReportAddress } from 'types';

export enum CaseCityEntriesType {
  INTERNAL_LAWSUITS = 'internal_lawsuit',
  LOCATION = 'location',
  REPORT = 'report',
  ASSET = 'asset'
}

export enum ReportCaseCityEntriesType {
  REAL_ESTATE = 'real_estate',
  RURAL_PROPERTY = 'rural_property',
  PARTNERSHIP_COMPANY = 'partnership_company',
  COMPANY = 'company',
  INDIVIDUAL = 'individual',
  VEHICLE = 'vehicle',
  LOCATION = 'location'
}

export type CaseCityEntry = {
  entityDocument: string;
  entityName: string;
  id: string | null;
  type: CaseCityEntriesType;
  count: number;
};

export type ReportCaseCityEntry = {
  address: ReportAddress;
  id: string;
  identification: string | null;
  type: ReportCaseCityEntriesType;
};

export type CaseCityOrigin = {
  count: number;
  entries: CaseCityEntry[];
};

export type ReportCaseCityOrigin = {
  count: number;
  entries: ReportCaseCityEntry[];
};

export type City<ORIGIN = never> = {
  id: string;
  name: string;
  state: string;
  latitude: number | null;
  longitude: number | null;
  origin: ORIGIN | null;
};

export type CaseCity = City<CaseCityOrigin>;

export type ReportCaseCity = City<ReportCaseCityOrigin>;
