import { Court } from 'types';
import Client from './client';

export function searchCourts({ state }: { state: string }) {
  return Client.get<Court[]>('courts', {
    params: {
      state: state
    }
  });
}
