import * as Reports from 'domain/reports';
import React from 'react';
import identity from 'lodash/identity';
import cn from 'classnames';

import { Status } from 'ui';
import { useToggles } from 'hooks';
import { Report, Status as StatusEnum } from 'types';

import StatusModal from './StatusModal';

import style from './ReportStatus.module.scss';

type Props = {
  report?: Report | null;
  formatter?: (_: string) => string;
  handleTryAgain: () => void;
  handleGenerateErrorReport: () => void;
  disabled?: boolean;
  showLink?: boolean;
  showAction?: boolean;
};

const ReportStatusWithModal = ({
  report,
  formatter = identity,
  handleTryAgain,
  handleGenerateErrorReport,
  disabled = false,
  showLink = true,
  showAction = true
}: Props) => {
  const status = Reports.reportStatus(report);
  const { isOpen, toggle } = useToggles<'provider'>({
    provider: false
  });

  const reportStatus =
    status === StatusEnum.PARTIAL ||
    status === StatusEnum.ERROR ||
    status === StatusEnum.PENDING;

  const reportStatusText = formatter(Reports.reportStatusText(report!, status));

  const isProviderModalEnabled = reportStatus && showLink && !disabled;

  return (
    <>
      <Status status={status}>
        <span
          onClick={isProviderModalEnabled ? toggle('provider') : undefined}
          className={cn({
            [style.link]: isProviderModalEnabled
          })}
          data-testid="report-status"
        >
          {reportStatusText}
        </span>
      </Status>

      {isOpen.provider && (
        <StatusModal
          report={report}
          statusText={reportStatusText}
          isOpen={isOpen.provider}
          onClose={toggle('provider')}
          showAction={showAction}
          handleTryAgain={handleTryAgain}
          handleGenerateErrorReport={handleGenerateErrorReport}
        />
      )}
    </>
  );
};
export default ReportStatusWithModal;
