import React, { useRef, useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { DateTime } from 'luxon';
import { FormikProps } from 'formik';

import {
  Header,
  Button,
  Title,
  openErrorToast,
  openSuccessToast,
  ToolTip
} from 'ui';

import { useInitNavigation, useQuery } from 'hooks';
import { Action, can, fetchDebtor, FetchDebtorArgs, deleteDebtor } from 'api';
import {
  EntityType,
  CaseEntity,
  ActionName,
  ActionEntity,
  DebtorFormAttributes,
  Debtor
} from 'types';
import { CaseContext } from 'components';
import * as Actions from 'utils/actions';

import Form from '../Form';
import style from './Edit.module.scss';

type Props = RouteComponentProps<{ caseId: string; debtorId: string }>;

function entityName(entity: CaseEntity) {
  if (entity.type === EntityType.COMPANY) return entity.company!.businessName;

  return entity.individual!.name;
}

const Edit = ({ match }: Props) => {
  const formRef = useRef<FormikProps<DebtorFormAttributes>>(null);
  const { debtorId, caseId } = match.params;
  const { caseData } = useContext(CaseContext);

  const { history } = useInitNavigation(pathname => [
    pathname.replace(`/${debtorId}`, '')
  ]);

  const { isLoading, data, error, setData: setDebtor } = useQuery<
    Debtor,
    FetchDebtorArgs
  >(fetchDebtor, {
    caseId,
    id: debtorId
  });

  async function handleDelete() {
    if (!window.confirm('Você tem certeza de que deseja remover esse devedor?'))
      return;

    try {
      await deleteDebtor(caseId, data!.id);
      openSuccessToast('Devedor deletado com sucesso!');
      history.push(`/cases/${caseId}/debtors`);
    } catch {
      openErrorToast('Houve um erro ao excluir o devedor');
    }
  }

  function handleSubmit() {
    if (formRef.current) return formRef.current.handleSubmit();
  }

  if (error) {
    openErrorToast('Não foi possivel carregar o devedor!');
  }

  const negotiatorId = caseData?.negotiator?.id;

  const canUser = {
    editDebtor: can(Action.CASOS_DEVEDOR_EDITAR, negotiatorId),
    removeDebtor: can(Action.CASOS_DEVEDOR_EXCLUIR, negotiatorId),
    showJudicialRecovery: can(
      Action.CASOS_DEVEDOR_RECUPERACAO_JUDICIAL_CONSULTAR,
      negotiatorId
    ),
    addJudicialRecovery: can(
      Action.CASOS_DEVEDOR_RECUPERACAO_JUDICIAL_INCLUIR,
      negotiatorId
    ),
    editJudicialRecovery: can(
      Action.CASOS_DEVEDOR_RECUPERACAO_JUDICIAL_EDITAR,
      negotiatorId
    ),
    showCreditClass: can(
      Action.CASOS_DEVEDOR_CLASSE_DE_CREDITO_CONSULTAR,
      negotiatorId
    ),
    addCreditClass: can(
      Action.CASOS_DEVEDOR_CLASSE_DE_CREDITO_INCLUIR,
      negotiatorId
    ),
    editCreditClass: can(
      Action.CASOS_DEVEDOR_CLASSE_DE_CREDITO_EDITAR,
      negotiatorId
    ),
    removeCreditClass: can(
      Action.CASOS_DEVEDOR_CLASSE_DE_CREDITO_EXCLUIR,
      negotiatorId
    )
  };

  const canUserAddOrEditJudicialRecovery =
    canUser.showJudicialRecovery &&
    (canUser.addJudicialRecovery || canUser.editJudicialRecovery);

  const canUserAddOrEditCreditClass =
    canUser.showCreditClass &&
    (canUser.addCreditClass ||
      canUser.editCreditClass ||
      canUser.removeCreditClass);

  const showSaveButton =
    data?.judicialSituation === 'judicial_recovery'
      ? canUser.editDebtor ||
        canUserAddOrEditJudicialRecovery ||
        canUserAddOrEditCreditClass
      : canUser.editDebtor;

  return (
    <div className="debtors-container">
      <Header
        containerClassName={style.container}
        isLoading={isLoading}
        sticky
        actionsComponent={
          data && (
            <>
              <ToolTip
                content={Actions.unavailableReasons(
                  data!.actions,
                  ActionName.DELETE,
                  ActionEntity.DEBTOR
                )}
                placement="top"
              >
                <span data-testid="delete-button-span">
                  {canUser.removeDebtor && (
                    <Button
                      small
                      outline
                      danger
                      type="button"
                      onClick={handleDelete}
                      disabled={Actions.isDisabled(
                        data!.actions,
                        ActionName.DELETE
                      )}
                    >
                      Excluir devedor
                    </Button>
                  )}
                </span>
              </ToolTip>

              {showSaveButton && (
                <Button type="submit" highlight small onClick={handleSubmit}>
                  Salvar
                </Button>
              )}
            </>
          )
        }
      >
        {data && (
          <Title className={style.title}>
            Devedor: {entityName(data!.entity)}
          </Title>
        )}
        <span className={style.changedAt}>
          {data &&
            `Editado pela última vez em ${DateTime.fromISO(data.changedAt)
              .setLocale('pt-BR')
              .toLocaleString(DateTime.DATE_FULL)}`}
        </span>
      </Header>

      <div className={style.content}>
        <Form
          isLoading={isLoading}
          caseId={caseId}
          formRef={formRef}
          debtor={data}
          onUpdate={setDebtor}
        />
      </div>
    </div>
  );
};

export default Edit;
