import { lawsuitNumber } from 'domain/lawsuits';
import React, { useContext } from 'react';

import { InternalLawsuit, ChildLawsuit } from 'types';
import { can, Action } from 'api';
import { Accordion, Link } from 'ui';
import { FileList, CaseContext } from 'components';

import style from './InternalLawsuitFiles.module.scss';

type Props = {
  lawsuit: InternalLawsuit | ChildLawsuit;
  caseId: string;
  showDelete?: boolean;
  showLink?: boolean;
};

const InternalLawsuitFile = ({
  lawsuit,
  caseId,
  showDelete = true,
  showLink = true
}: Props) => {
  const lawsuitLink = (lawsuit: InternalLawsuit | ChildLawsuit) => {
    if (lawsuit.isParent) {
      return `/cases/${caseId}/internal_lawsuits/${lawsuit.id}`;
    } else {
      const childLawsuit = lawsuit as ChildLawsuit;

      return `/cases/${caseId}/internal_lawsuits/${childLawsuit.parent.id}/children/${childLawsuit.id}`;
    }
  };

  const { caseData } = useContext(CaseContext);
  const negotiatorId = caseData?.negotiator?.id;
  const canUser = {
    accessMovementFiles: can(
      Action.CASOS_PROCESSO_ARQUIVOS_DO_MOVIMENTO_CONSULTAR,
      negotiatorId
    )
  };

  return (
    <>
      <Accordion
        isClosed
        key={lawsuit.id}
        title={lawsuitNumber(lawsuit.number)}
        headerActions={
          showLink && (
            <Link target="_blank" highlight href={lawsuitLink(lawsuit)}>
              Ver processo
            </Link>
          )
        }
      >
        <div className={style.caseFilesList}>
          {canUser.accessMovementFiles && (
            <FileList
              caseId={caseId}
              className={style.filesList}
              files={lawsuit.movementFiles}
              placeholder="Não há arquivos de movimentações neste processo"
              title="Arquivos de movimentações"
              showDelete={showDelete}
            />
          )}

          <FileList
            caseId={caseId}
            className={style.filesList}
            files={lawsuit.fileUploads}
            placeholder="Não há arquivos de ativos em penhoras neste processo"
            title="Arquivos de ativos em penhora"
            showDelete={showDelete}
          />
        </div>
      </Accordion>

      {lawsuit.isParent &&
        (lawsuit as InternalLawsuit).children?.map(childLawsuit => (
          <InternalLawsuitFile
            key={childLawsuit.id}
            caseId={caseId}
            lawsuit={childLawsuit}
            showDelete={showDelete}
            showLink={showLink}
          />
        ))}
    </>
  );
};

export default InternalLawsuitFile;
