import React from 'react';

import { Asset } from 'types';
import { Card, Accordion, Link } from 'ui';
import { FileList, ImageGallery } from 'components';

import style from './AssetFile.module.scss';

type Props = {
  caseId: string;
  assets: Asset[];
  showDelete?: boolean;
  showLink?: boolean;
};

const AssetFiles = ({
  caseId,
  assets,
  showDelete = true,
  showLink = true
}: Props) => {
  const isAssetEmpty = !assets.length;

  return (
    <Card>
      <h2 className={style.cardTitle}>Ativos</h2>

      {isAssetEmpty && (
        <p className={style.emptyList}>Não há ativos neste caso</p>
      )}

      {!isAssetEmpty &&
        assets.map(asset => {
          const isGalleryEmpty = !asset.galleryFileUploads.length;
          const isFileEmpty = !asset.documentFileUploads.length;

          return (
            <Accordion
              isClosed
              key={asset.id}
              title={asset.name}
              titleClassName={style.accordionHeader}
              headerActions={
                showLink && (
                  <Link
                    target="_blank"
                    highlight
                    href={`/cases/${caseId}/assets/${asset.id}`}
                  >
                    Ver ativo
                  </Link>
                )
              }
            >
              {isGalleryEmpty && isFileEmpty && (
                <p className={style.emptyList}>Não há arquivos neste ativo</p>
              )}

              {!isGalleryEmpty && (
                <div data-testid="gallery-files" className={style.thumbnails}>
                  <ImageGallery
                    caseId={caseId}
                    images={asset.galleryFileUploads}
                    showDelete={showDelete}
                  />
                </div>
              )}

              {!isFileEmpty && (
                <div
                  data-testid="documents-files"
                  className={style.caseFilesList}
                >
                  <FileList
                    files={asset.documentFileUploads}
                    caseId={caseId}
                    className={style.filesList}
                    showDelete={showDelete}
                  />
                </div>
              )}
            </Accordion>
          );
        })}
    </Card>
  );
};

export default AssetFiles;
