import React, { useState } from 'react';

import { InstagramPublication } from 'types';

import { PublicationBookmark, BookmarkPosition } from 'components';

type Props = {
  reportId: string;
  caseId: string;
  instagramPublication: InstagramPublication;
};

const PublicationBookmarkModal = ({
  reportId,
  caseId,
  instagramPublication
}: Props) => {
  const [publication, setPublication] = useState(instagramPublication);
  const handleBookmark = () => {
    setPublication({
      ...publication,
      isFavorite: !publication.isFavorite
    });
  };

  return (
    <PublicationBookmark
      caseId={caseId}
      instagramPublication={publication}
      reportId={reportId}
      position={BookmarkPosition.left}
      onBookmarkCallBack={handleBookmark}
    />
  );
};

export default PublicationBookmarkModal;
