import { ReportCnae, FetchReportTableProps } from 'types';

import Client from './client';

function fetchReportCnae({
  caseId,
  reportId,
  pagination
}: FetchReportTableProps) {
  return Client.get<ReportCnae[]>(
    `cases/${caseId}/reports/${reportId}/contents/cnaes`,
    {
      params: {
        page: pagination?.page.toString(),
        pageSize: pagination?.pageSize.toString()
      }
    }
  );
}

function fetchReportCnaeSummary({ caseId, reportId }: FetchReportTableProps) {
  return Client.get<ReportCnae[]>(
    `cases/${caseId}/reports/${reportId}/contents/cnaes/summary`
  );
}

export { fetchReportCnae, fetchReportCnaeSummary };
