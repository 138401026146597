export enum CPFSituation {
  REGULAR = 'regular',
  SUSPENSA = 'suspensa',
  FALECIDO = 'titular_falecido',
  PENDENTE = 'pendente_de_regularizacao',
  CANCELADA_MULTIPLICIDADE = 'cancelada_por_multiplicidade',
  NULA = 'nula',
  CENCELADA_OFICIO = 'cancelada_de_oficio'
}

const NAME_DICTIONARY = {
  [CPFSituation.REGULAR]: 'Regular',
  [CPFSituation.SUSPENSA]: 'Suspensa',
  [CPFSituation.FALECIDO]: 'Titular falecido',
  [CPFSituation.PENDENTE]: 'Pendente de regularização',
  [CPFSituation.CANCELADA_MULTIPLICIDADE]: 'Cancelada por multiplicidade',
  [CPFSituation.NULA]: 'Nula',
  [CPFSituation.CENCELADA_OFICIO]: 'Cancelada de ofício'
};

export function cpfSituationName(name: CPFSituation): string {
  return NAME_DICTIONARY[name] || name;
}
