import React from 'react';
import cn from 'classnames';
import { DateTime } from 'luxon';
import pt from 'date-fns/locale/pt';
import DatePicker, { registerLocale } from 'react-datepicker';
import InputField from '../InputField/InputField';

import 'react-datepicker/dist/react-datepicker.css';
import style from './DatePickerField.module.scss';

registerLocale('pt', pt);

type Props = {
  id: string;
  name: string;
  value?: string | null;
  onChange: (date: string | null) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  title?: string;
  placeholder?: string;
  error?: Array<string> | string;
  max?: string;
  disabled?: boolean;
};

const DatePickerField = ({
  id,
  title,
  value,
  name,
  placeholder,
  disabled,
  onChange,
  onBlur,
  error,
  max
}: Props) => {
  const maxDate = max ? DateTime.fromISO(max).toJSDate() : null;
  const startDate = value ? DateTime.fromISO(value).toJSDate() : null;

  const inputClass = cn(style.datePickerContainer, {
    [style.datePickerContainerError]: Boolean(error),
    [style.datePickerContainerDisabled]: Boolean(disabled)
  });

  return (
    <div className={inputClass} data-testid="date-picker">
      <label
        className={style.datePickerLabel}
        htmlFor={id}
        data-testid="date-picker-label"
      >
        {title}
      </label>
      <DatePicker
        className={style.datePickerInput}
        calendarClassName={style.datePicker}
        weekDayClassName={() => style.datePickerWeek}
        dayClassName={() => style.datePickerDay}
        id={id}
        maxDate={maxDate}
        name={name}
        dateFormat="dd/MM/yyyy"
        locale="pt"
        showMonthDropdown
        showYearDropdown
        selected={startDate}
        onChange={date => {
          const newDate = DateTime.fromJSDate(date as Date).toISODate();

          onChange(newDate || '');
        }}
        onBlur={onBlur}
        placeholderText={placeholder}
        disabled={disabled}
        customInputRef="inputRef"
        customInput={
          <InputField
            id={id}
            name={name}
            type="date"
            onChange={() => {}}
            error={error}
          />
        }
      />
    </div>
  );
};

export default DatePickerField;
