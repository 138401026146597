import { tableColapseOptions } from 'domain/transitions';
import { useToggles } from 'hooks';
import React, { useState } from 'react';
import { CaseCity, CaseCityEntriesType } from 'types';
import { Icon, Link, Table } from 'ui';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import styles from './CityRow.module.scss';
import OriginRow from './OriginRow/OriginRow';

type Props = {
  city: CaseCity;
  isSummary?: boolean;
  showOrigin?: boolean;
  hiddenEntriesLinks?: CaseCityEntriesType[];
};

const DEFAULT_PAGE_SIZE = 10;

const CityRow = ({
  city,
  isSummary,
  showOrigin = false,
  hiddenEntriesLinks = []
}: Props) => {
  const { isOpen, toggle } = useToggles<'originsRow'>({
    originsRow: false
  });

  const cityOrigins = city.origin?.entries ?? [];
  const [origins, setOrigins] = useState(
    isSummary ? cityOrigins : cityOrigins.slice(0, DEFAULT_PAGE_SIZE)
  );
  const [totalLoaded, setTotalLoaded] = useState(DEFAULT_PAGE_SIZE);
  const haveToShowLoadMoreLink =
    cityOrigins.length > 10 &&
    origins.length !== cityOrigins.length &&
    !isSummary;

  const handleShowMoreOrigins = () => {
    const newPageSize = totalLoaded + DEFAULT_PAGE_SIZE;
    const newOrigins = cityOrigins.slice(0, newPageSize);

    setOrigins(newOrigins);
    setTotalLoaded(newPageSize);
  };

  return (
    <>
      <motion.tr className={styles.cityRow} {...tableColapseOptions}>
        {isSummary ? (
          <Table.Cell colSpan={3}>
            <div className={styles.cityCellIcon}>{city.name}</div>
          </Table.Cell>
        ) : (
          <Table.Cell
            colSpan={3}
            className={styles.cityCell}
            onClick={toggle('originsRow')}
          >
            <div className={styles.cityCellIcon}>
              <Icon
                name="arrow-right"
                fill="#62667A"
                small
                className={cn({
                  [styles.cityCellIconClose]: !isOpen.originsRow,
                  [styles.cityCellIconOpen]: isOpen.originsRow
                })}
              />
              {city.name}
            </div>
          </Table.Cell>
        )}
        <Table.Cell className={styles.locationQuantityCell}>
          {city.origin?.count.toString()}
        </Table.Cell>
      </motion.tr>
      <AnimatePresence initial={false}>
        {(isOpen.originsRow || showOrigin) && (
          <>
            {origins.map((origin, index) => (
              <OriginRow
                key={`${index}-${city.id}`}
                origin={origin}
                isSummary={isSummary}
                hiddenEntriesLinks={hiddenEntriesLinks}
              />
            ))}
            {haveToShowLoadMoreLink && (
              <motion.tr {...tableColapseOptions}>
                <Table.Cell colSpan={4} textAlign="center">
                  <Link
                    highlight
                    className={styles.loadMoreOrigins}
                    onClick={handleShowMoreOrigins}
                  >
                    Carregar mais ({totalLoaded} de {cityOrigins.length})
                  </Link>
                </Table.Cell>
              </motion.tr>
            )}
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default CityRow;
