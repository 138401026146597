import { useToggles } from 'hooks';
import React from 'react';
import { Icon, Table } from 'ui';
import cn from 'classnames';
import { AnimatePresence } from 'framer-motion';
import { CaseCity, CaseCityEntriesType, GroupedState } from 'types';
import orderBy from 'lodash/orderBy';

import CityRow from './CityRow/CityRow';
import styles from './StateRow.module.scss';

type Props = {
  state: GroupedState;
  isSummary?: boolean;
  showOrigin?: boolean;
  hiddenEntriesLinks?: CaseCityEntriesType[];
};

const StateRow = ({
  state,
  isSummary = false,
  showOrigin,
  hiddenEntriesLinks = []
}: Props) => {
  const { isOpen, toggle } = useToggles<'cityRow'>({ cityRow: isSummary });
  const orderedCities = orderBy(state.cities, ['name'], ['asc']);

  const getTableCellProps = (isSummary?: boolean) => {
    const defaultProps = {
      colSpan: 3,
      className: styles.stateCellSummary,
      dataTestId: 'state-cell-summary'
    };

    if (isSummary) {
      return defaultProps;
    }

    return {
      ...defaultProps,
      className: styles.stateCell,
      dataTestId: 'state-cell',
      onClick: toggle('cityRow')
    };
  };

  return (
    <>
      <Table.Row className={styles.stateRow}>
        <Table.Cell {...getTableCellProps(isSummary)}>
          <div className={styles.stateCellIcon}>
            {!isSummary && (
              <Icon
                className={cn({
                  [styles.stateCellIconClose]: !isOpen.cityRow,
                  [styles.stateCellIconOpen]: isOpen.cityRow
                })}
                name="arrow-right"
                fill="#62667A"
                small
              />
            )}
            {state.name}
          </div>
        </Table.Cell>
        <Table.Cell
          className={styles.locationQuantityCell}
          dataTestId={`${state.name}-location-quantity`}
        >
          {state.count.toString()}
        </Table.Cell>
      </Table.Row>
      <AnimatePresence initial={false}>
        {isOpen.cityRow &&
          orderedCities.map(city => (
            <CityRow
              key={city.id}
              city={city as CaseCity}
              isSummary={isSummary}
              showOrigin={showOrigin}
              hiddenEntriesLinks={hiddenEntriesLinks}
            />
          ))}
      </AnimatePresence>
    </>
  );
};

export default StateRow;
