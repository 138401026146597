import { CaseEventType, Option } from 'types';

export const CASE_EVENT_DICTIONARY = {
  [CaseEventType.REUNIAO_CREDORES]: 'Reunião credores',
  [CaseEventType.REUNIAO_EMPRESA_OU_ADVISOR]: 'Reunião empresa/Advisor',
  [CaseEventType.REUNIAO_ESCRITORIO]: 'Reuniao escritório',
  [CaseEventType.REUNIAO_INTERNA]: 'Reuniao interna',
  [CaseEventType.AGC]: 'AGC',
  [CaseEventType.VISITA]: 'Visita',
  [CaseEventType.UPDATE]: 'Update',
  [CaseEventType.APROVAÇÃO_OU_COMITE]: 'Aprovação/Comitê',
  [CaseEventType.PROPOSTA_ACORDO]: 'Proposta acordo',
  [CaseEventType.PROPOSTA_CESSAO]: 'Proposta cessão',
  [CaseEventType.ALTERACOES_CONTRATUAIS]: 'Alterações contratuais',
  [CaseEventType.CLIPPING]: 'Clipping',
  [CaseEventType.OUTROS]: 'Outros'
};

export function eventTypeLabel(event: CaseEventType) {
  return CASE_EVENT_DICTIONARY[event] ?? event;
}

export function eventTypeOptions(): Option[] {
  return Object.values(CaseEventType).map(event => ({
    value: event,
    label: CASE_EVENT_DICTIONARY[event]
  }));
}
