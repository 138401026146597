import React from 'react';

import { fetchCaseCities } from 'api';
import { useQuery, useToggles } from 'hooks';

import { Stack, StackMargin, RadioSwitch } from 'ui';

import { SummaryError, CaseCitiesTable } from 'components';

import style from './Cities.module.scss';

type Props = {
  caseId: string;
};

const Cities = ({ caseId }: Props) => {
  const { toggle, isOpen } = useToggles<'fullView'>({ fullView: false });

  const { data: caseCities = [], error, isLoading } = useQuery(
    fetchCaseCities,
    {
      caseId
    }
  );

  if (error) {
    return (
      <SummaryError
        title="Praças"
        message="Erro ao listar praças, tente novamente."
      />
    );
  }

  return (
    <Stack marginTop={StackMargin.XLARGE}>
      <div className={style.titleWrapper}>
        <h3 className={style.title}>Praças</h3>

        {caseCities.length > 0 && (
          <div className={style.fullView}>
            <RadioSwitch
              label="Detalhamento das praças"
              onChange={toggle('fullView')}
              active={isOpen.fullView}
              inputId="cities-full-view"
            />
          </div>
        )}
      </div>

      <Stack marginTop={StackMargin.MEDIUM}>
        <CaseCitiesTable
          caseCities={caseCities}
          isLoading={isLoading}
          isSummary
          showOrigin={isOpen.fullView}
          showOriginHeader={isOpen.fullView}
        />
      </Stack>
    </Stack>
  );
};

export default Cities;
