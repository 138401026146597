import Client from './client';

export type UserCredentials = {
  email: string;
  password: string;
};

export type AccessToken = {
  accessToken: string;
  allowedActions: string[];
  message: string;
};

export type OAuthCredentials = {
  code: string;
};

export function createAccessToken(credentials: UserCredentials) {
  return Client.post<UserCredentials, AccessToken>(
    'access_tokens',
    credentials,
    { authenticated: false }
  );
}

export function createAccessTokenWithOauth(credentials: OAuthCredentials) {
  return Client.post<OAuthCredentials, AccessToken>(
    'access_tokens',
    credentials,
    { authenticated: false }
  );
}

export function deleteAccessToken() {
  return Client.delete('access_tokens/current', {
    logoutOnAuthenticationError: false
  });
}
