import React, { useState } from 'react';
import cn from 'classnames';
import { ProfileItem as ProfileItemType } from 'types';
import { Accordion, RadioSwitch } from 'ui';
import ProfileItem from './ProfileItem';

import style from './ProfileItemWrapper.module.scss';

type Props = {
  profile: ProfileItemType;
};

const ProfileItemWrapper = ({ profile }: Props) => {
  const [enabledCount, setEnabledCount] = useState(profile.enabledAmount);
  const [shouldExpandAll, setShouldExpandAll] = useState(false);

  const toggleChangeCallback = (isAccordionClosed: boolean) => {
    isAccordionClosed && setShouldExpandAll(false);
  };

  return (
    <li>
      <Accordion
        titleClassName={cn({ [style.accordionTitle]: enabledCount === 0 })}
        title={profile.name}
        badgeContent={enabledCount}
        tooltipText={
          enabledCount === 0
            ? 'Perfil possui 0 permissões, adicione permissões para habilitá-lo'
            : undefined
        }
        headerActions={
          <RadioSwitch
            label="Expandir tudo"
            onChange={() => setShouldExpandAll(prev => !prev)}
            active={shouldExpandAll}
            inputId={`expand-toggle-${profile.name}`}
          />
        }
        isClosed={!shouldExpandAll}
        toggleChangeCallback={toggleChangeCallback}
      >
        <ProfileItem
          profileId={profile.id}
          columnsSizeStyle={style.columnsSize}
          setPermissionCounter={setEnabledCount}
          shouldExpandAll={shouldExpandAll}
        />
      </Accordion>
    </li>
  );
};

export default ProfileItemWrapper;
