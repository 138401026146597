import { TaxIndexer } from './taxIndexer';

export enum CreditClassType {
  EXTRACONCURSAL = 'extraconcursal',
  DIP = 'dip',
  REAL = 'real',
  QUIROGRAFARIO = 'quirografario',
  MPE = 'mpe'
}

export type CreditClassFormAttributes = {
  amortizationMonths: string;
  classPercentage: string;
  class: CreditClassType | string;
  complianceBonusPercentage: string;
  haircutPercentage: string;
  indexer: TaxIndexer | string;
  interestGraceMonths: string;
  mainGraceMonths: string;
  creditValueCents: string;
  prjFeePercentage: string;
};

export type CreditClass = {
  id: string;
  amortizationMonths: number | null;
  classPercentage: number | null;
  class: CreditClassType;
  complianceBonusPercentage: number | null;
  haircutPercentage: number | null;
  indexer: TaxIndexer | null;
  interestGraceMonths: number | null;
  mainGraceMonths: number | null;
  creditValueCents: number;
  prjFeePercentage: number | null;
};
