import React, { ReactNode } from 'react';
import { Status as StatusEnum } from 'types';
import { Icon } from 'ui';

import style from './Status.module.scss';

type Props = {
  status: StatusEnum;
  children: ReactNode;
};

const getIconSetting = (status: StatusEnum) => {
  if (status === StatusEnum.NOSTATUS) return { name: 'ban', color: '#969AAB' };
  if (status === StatusEnum.SUCCEEDED)
    return { name: 'checked-rounded', color: '#43A047' };
  if (status === StatusEnum.PENDING)
    return { name: 'hourglass', color: '#3949AB' };
  if (status === StatusEnum.PARTIAL)
    return { name: 'warning', color: '#EE9933' };

  return { name: 'warning', color: '#E53935' };
};

const Status = ({ status, children }: Props) => {
  const iconSettings = getIconSetting(status);

  return (
    <span className={style[status]} data-testid="status">
      <Icon
        name={iconSettings.name}
        fill={iconSettings.color}
        className={style.icon}
      />
      {children}
    </span>
  );
};

export default Status;
