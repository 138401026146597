import React, { useState } from 'react';

import Tippy from '@tippyjs/react';

import { CityMapPoints } from 'types';

import style from './Pin.module.scss';

const Pin = ({ displayName, locationCount }: CityMapPoints) => {
  const [shouldRender, setShouldRender] = useState(false);

  const TippyInfo = () => {
    return (
      <div className={style.content}>
        <div>{displayName}</div>
        {locationCount && <div>Qta. de localizações: {locationCount}</div>}
      </div>
    );
  };

  return (
    <Tippy
      interactive
      trigger="click"
      maxWidth="none"
      theme="translucent"
      className={style.container}
      content={shouldRender ? <TippyInfo /> : undefined}
      onShow={() => setShouldRender(true)}
      onHidden={() => setShouldRender(false)}
    >
      <div className={style.pin} data-testid="pin" />
    </Tippy>
  );
};

export default Pin;
