import React from 'react';
import { CaseType } from 'types';

import CasesStatsForAtivosEspeciais from './CasesStatsForAtivosEspeciais';
import CasesStatsForPEACS from './CasesStatsForPEACS';
import CasesStatsForSalesFinance from './CasesStatsForSalesFinance';

type Props = {
  type: CaseType;
};

const StatsByCaseType = {
  [CaseType.ATIVOS_ESPECIAIS]: <CasesStatsForAtivosEspeciais />,
  [CaseType.PEAC]: <CasesStatsForPEACS />,
  [CaseType.SALES_FINANCE]: <CasesStatsForSalesFinance />
};

const Stats = ({ type }: Props) => {
  return StatsByCaseType[type] ?? <div />;
};

export default Stats;
