import React, { ReactElement } from 'react';
import cn from 'classnames';

import RadioButton from '../RadioButton';

import style from './RadioGroupButton.module.scss';

type Props = {
  id: string;
  name: string;
  options: Array<{
    label: string | ReactElement;
    value: string;
    disabled?: boolean;
    className?: string;
  }>;
  title?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: Array<string> | string;
  disabled?: boolean;
  className?: string;
  showWithRadioButton?: boolean;
};

const RadioGroupButton = ({
  id,
  name,
  value,
  title,
  options,
  error,
  className,
  disabled,
  showWithRadioButton = true,
  onChange
}: Props) => {
  return (
    <div className={cn(className)}>
      {title && (
        <label
          className={cn(style.label, error && style.labelError)}
          htmlFor={id}
        >
          {title}
        </label>
      )}

      <div className={style.fields}>
        {options.map((option, index) => (
          <RadioButton
            key={index}
            id={`${name}-${option.value}`}
            name={name}
            label={option.label}
            value={option.value}
            disabled={disabled || option.disabled}
            checked={value === option.value}
            onChange={onChange}
            className={option.className}
            showWithRadioButton={showWithRadioButton}
          />
        ))}
      </div>

      {error && (
        <p className={cn(style.message, error && style.messageError)}>
          {Array.isArray(error) ? error.join(', ') : error}
        </p>
      )}
    </div>
  );
};

export default RadioGroupButton;
