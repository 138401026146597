import {
  internalLawsuitInitialValues,
  internalLawsuitValidationSchema
} from 'domain/lawsuits';
import React, { ReactElement, useState, useContext } from 'react';
import { CaseContext } from 'components';
import { RouteComponentProps } from 'react-router-dom';
import { FormikHelpers, Formik } from 'formik';

import { Action, can, createInternalLawsuit } from 'api';
import { useInitNavigation } from 'hooks';

import { Header, Button, openErrorToast, openSuccessToast } from 'ui';

import { InternalLawsuitAttributes } from 'types';

import Form from '../Form';

import './Create.scss';

const Create = ({
  match: {
    params: { caseId }
  }
}: RouteComponentProps<{ caseId: string }>): ReactElement => {
  const { history } = useInitNavigation(pathname => {
    const returnTo = pathname.replace(`/create`, '');
    return [returnTo];
  });
  const [isWaitingResponse, setWaitingResponse] = useState<boolean>(false);
  const { caseData } = useContext(CaseContext);
  async function onSubmit(
    values: InternalLawsuitAttributes,
    formikHelpers: FormikHelpers<InternalLawsuitAttributes>
  ) {
    try {
      const response = await createInternalLawsuit(caseId, values);
      openSuccessToast('Processo cadastrado com sucesso!');

      const canShowInternalLawsuit = can(
        Action.CASOS_PROCESSOS_JUDICIAIS_VER_PROCESSO,
        caseData?.negotiator?.id
      );

      const redirectUrl = canShowInternalLawsuit
        ? `/cases/${caseId}/internal_lawsuits/${response.data.id}`
        : `/cases/${caseId}/internal_lawsuits/`;

      history.push(redirectUrl);
    } catch (error) {
      formikHelpers.setErrors(error.errors);
      openErrorToast('Erro ao cadastrar um novo processo!');
    }
  }

  return (
    <Formik<InternalLawsuitAttributes>
      initialValues={internalLawsuitInitialValues()}
      validationSchema={internalLawsuitValidationSchema}
      onSubmit={onSubmit}
    >
      {form => (
        <div className="lawsuits-container">
          <Header
            actionsComponent={
              <Button
                type="submit"
                highlight
                small
                disabled={isWaitingResponse}
                onClick={form.handleSubmit}
              >
                Salvar
              </Button>
            }
          >
            <h2 className="title">Novo processo</h2>
          </Header>

          <Form
            caseId={caseId}
            form={form}
            onSubmit={onSubmit}
            isWaitingResponse={isWaitingResponse}
            setWaitingResponse={setWaitingResponse}
          />
        </div>
      )}
    </Formik>
  );
};

export default Create;
