import { Field, FieldHistory, ReportAddress } from 'types';

export type ReportRuralProperty = {
  id: string;
  field: Field;
  address: ReportAddress | null;
  nirfCodes: string[];
  propertyCode: string | null;
  propertyName: string | null;
  relatedProperties: ReportRuralProperty[];
  totalArea: number | null;
  totalAreaUnit: string | null;
  virtualRegistration: RuralPropertyRegistration;
  registrations: RuralPropertyRegistration[];
  owners: RuralPropertyOwner[];
  incraCode: string | null;
  landClassification: LandClassificationType | null;
  dateProcessingLastDeclaration: string | null;
  certifiedArea: number | null;
  locationIndications: string | null;
  ruralModuleArea: number | null;
  quantityRuralModule: number | null;
  taxModuleArea: number | null;
  taxModuleQuantity: number | null;
  minimumFractionInstallment: number | null;
  totalRegisteredArea: number | null;
  totalPossessionTitleArea: number | null;
  totalPossessionOccupancyArea: number | null;
  measuredArea: number | null;
  totalPeopleRelatedProperty: number | null;
  lastCcir: CCIRData | null;
};

export enum LandClassificationType {
  MINIFUNDIO = 'minifundio',
  PEQUENA_PROPRIEDADE_PRODUTIVA = 'pequena_propriedade_produtiva',
  PEQUENA_PROPRIEDADE_IMPRODUTIVA = 'pequena_propriedade_improdutiva',
  MEDIA_PROPRIEDADE_PRODUTIVA = 'media_propriedade_produtiva',
  MEDIA_PROPRIEDADE_IMPRODUTIVA = 'media_propriedade_improdutiva',
  GRANDE_PROPRIEDADE_PRODUTIVA = 'grande_propriedade_produtiva',
  GRANDE_PROPRIEDADE_IMPRODUTIVA = 'grande_propriedade_improdutiva',
  PEQUENA = 'pequena',
  NAO_CLASSIFICADA = 'nao_classificada',
  NAO_CLASSIFICADA_ART7_L8629 = 'nao_classificada_lei',
  MEDIA = 'media',
  GRANDE = 'grande'
}

const LandClassificationTypeDictionary = {
  [LandClassificationType.MINIFUNDIO]: 'Minifundio',
  [LandClassificationType.PEQUENA_PROPRIEDADE_PRODUTIVA]:
    'Pequena propriedade produtiva',
  [LandClassificationType.PEQUENA_PROPRIEDADE_IMPRODUTIVA]:
    'Pequena propriedade improdutiva',
  [LandClassificationType.MEDIA_PROPRIEDADE_PRODUTIVA]:
    'Média propriedade produtiva',
  [LandClassificationType.MEDIA_PROPRIEDADE_IMPRODUTIVA]:
    'Média propriedade improdutiva',
  [LandClassificationType.GRANDE_PROPRIEDADE_PRODUTIVA]:
    'Grande propriedade produtiva',
  [LandClassificationType.GRANDE_PROPRIEDADE_IMPRODUTIVA]:
    'Grande propriedade improdutiva',
  [LandClassificationType.PEQUENA]: 'Pequena',
  [LandClassificationType.NAO_CLASSIFICADA]: 'Não classificada',
  [LandClassificationType.NAO_CLASSIFICADA_ART7_L8629]:
    'Não classificada Art. 7º Lei 8629/93',
  [LandClassificationType.MEDIA]: 'Média',
  [LandClassificationType.GRANDE]: 'Grande'
};

export function landClassificationTitle(
  classification: LandClassificationType
): string {
  return LandClassificationTypeDictionary[classification] || classification;
}

export type CCIRData = {
  releaseDate: string | null;
  number: string | null;
  authenticityNumber: string | null;
  generationDate: string | null;
  expirationDate: string | null;
  previousDebts: number | null;
  cadastralServicesFee: number | null;
  amountCharged: number | null;
  fine: number | null;
  fees: number | null;
  totalValue: number | null;
  situation: CCIRSituation | null;
};

export enum CCIRSituation {
  MINIFUNDIO = 'minifundio',
  EMISSAO_GERAL = 'emissao_geral',
  EMISSAO_INDIVIDUAL = 'emissao_individual',
  SEGUNDA_VIA_SEM_PRORROGACAO = 'segunda_via_sem_prorrogacao',
  SEGUNDA_VIA_COM_PRORROGACAO = 'segunda_via_com_prorrogacao',
  EMISSAO_VIA_WEB = 'emissao_via_web',
  SEGUNDA_VIA_EMISSAO_VIA_WEB = 'segunda_via_por_emissao_via_web',
  CCIR_QUITADO = 'ccir_quitado',
  PRORROGACAO_ESTRAVIO_CCIR = 'prorrogacao_por_extravio_de_ccir',
  PRORROCACAO_ERRO_INCRA = 'prorrogacao_por_erro_do_incra',
  CCIR_COBRANCA_INDEVIDA = 'ccir_com_cobranca_indevida',
  CCIR_ISENTO = 'ccir_isento'
}

const CCIRSituationDictonary = {
  [CCIRSituation.MINIFUNDIO]: 'Minifundio',
  [CCIRSituation.EMISSAO_GERAL]: 'Emissão geral',
  [CCIRSituation.EMISSAO_INDIVIDUAL]: 'Emissão individual',
  [CCIRSituation.SEGUNDA_VIA_SEM_PRORROGACAO]: '2ª via sem prorrogação',
  [CCIRSituation.SEGUNDA_VIA_COM_PRORROGACAO]: '2ª via com prorrogação',
  [CCIRSituation.EMISSAO_VIA_WEB]: 'Emissão via web',
  [CCIRSituation.SEGUNDA_VIA_EMISSAO_VIA_WEB]: '2ª via por emissão via web',
  [CCIRSituation.CCIR_QUITADO]: 'CCIR quitado',
  [CCIRSituation.PRORROGACAO_ESTRAVIO_CCIR]: 'Prorrogação por extravio de CCIR',
  [CCIRSituation.PRORROCACAO_ERRO_INCRA]: 'Prorrogação por erro do Incra',
  [CCIRSituation.CCIR_COBRANCA_INDEVIDA]: 'CCIR com cobrança indevida',
  [CCIRSituation.CCIR_ISENTO]: 'CCIR Isento'
};

export function ccirSituationTitle(situation: CCIRSituation): string {
  return CCIRSituationDictonary[situation] || situation;
}

export type RuralPropertyRegistration = {
  notaryState: string | null;
  notaryCity: string | null;
  registrationDate: string | null;
  cnsCraft: string | null;
  enrollmentTranscript: string | null;
  record: string | null;
  bookCard: string | null;
  area: number | null;
  history: FieldHistory | null;
};

export type RuralPropertyOwner = {
  document: string | null;
  name: string | null;
  declarant: 'S' | 'N';
  type: string | null;
  ownershipCondition: OwnershipConditionType | null;
  detentionPercentage: number | null;
  nationality: string;
  isOwner: boolean;
};

export enum OwnershipConditionType {
  PROPRIETARIO_INDIVIDUAL = 'proprietario_ou_posseiro_individual',
  PROPRIETARIO_COMUM = 'proprietario_ou_posseiro_comum',
  USUFRUTUARIO = 'usufrutuario',
  NU_PROPRIETARIO = 'nu_proprietario',
  CONCESSIONARIO = 'concessionario',
  PROPRIETARIO_ASSOCIADO = 'proprietario_ou_posseiro_associado'
}

const OwnershipConditionTypeDictionary = {
  [OwnershipConditionType.PROPRIETARIO_INDIVIDUAL]:
    'Proprietário ou posseiro individual',
  [OwnershipConditionType.PROPRIETARIO_COMUM]: 'Proprietário ou posseiro comum',
  [OwnershipConditionType.USUFRUTUARIO]: 'Usufrutuário',
  [OwnershipConditionType.NU_PROPRIETARIO]: 'Nu-Proprietario',
  [OwnershipConditionType.CONCESSIONARIO]: 'Concessionário',
  [OwnershipConditionType.PROPRIETARIO_ASSOCIADO]:
    'Proprietário ou posseiro associado'
};

export function ownershipConditionTitle(
  situation: OwnershipConditionType
): string {
  return OwnershipConditionTypeDictionary[situation] || situation;
}
