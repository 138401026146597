import {
  executionDegreeOptions,
  executionTypeOptions
} from 'domain/executions';
import React from 'react';
import * as Yup from 'yup';
import { useFormik, getIn } from 'formik';

import {
  SelectField,
  Toggle,
  FormContainer,
  Button,
  openErrorToast,
  openSuccessToast,
  StackMargin,
  Stack
} from 'ui';
import {
  ExecutionRelatedAttributes,
  Execution,
  OnSubmit,
  ApiError,
  ExecutionType
} from 'types';
import { createExecution } from 'api';

import ExecutedFields from './ExecutedFields';

const validationSchema = Yup.object().shape({
  degree: Yup.string()
    .required('Campo obrigatório')
    .nullable(),
  type: Yup.string().required('Campo obrigatório'),
  executedGuaranteeId: Yup.string()
    .nullable()
    .when('type', {
      is: ExecutionType.GUARANTEE,
      then: Yup.string().required('Campo obrigatório')
    }),
  executedContractId: Yup.string()
    .nullable()
    .when('type', {
      is: ExecutionType.GUARANTEE,
      then: Yup.string().required('Campo obrigatório')
    }),
  executedAssetId: Yup.string()
    .nullable()
    .when('type', {
      is: ExecutionType.ASSET,
      then: Yup.string().required()
    }),
  executedExternalLawsuitId: Yup.string()
    .nullable()
    .when('type', {
      is: ExecutionType.EXTERNAL_LAWSUIT,
      then: Yup.string().required('Campo obrigatório')
    })
});

type Props = {
  onCreate: (execution: Execution) => void;
  caseId: string;
  internalLawsuitId: string;
};

const formatError = (errors?: ApiError) => {
  if (!errors) return {};

  return {
    ...errors,
    entity: { individual: errors?.individual, company: errors?.company }
  };
};

const initialValues: ExecutionRelatedAttributes = {
  degree: null,
  type: ExecutionType.ASSET,
  executedAssetId: null,
  executedGuaranteeId: null,
  executedContractId: null,
  executedExternalLawsuitId: null
};

const RelatedForm = ({ onCreate, caseId, internalLawsuitId }: Props) => {
  const onSubmit: OnSubmit<ExecutionRelatedAttributes> = async (
    values,
    formikHelpers
  ) => {
    try {
      const response = await createExecution(caseId, internalLawsuitId, values);

      onCreate(response.data);

      openSuccessToast('Penhora salva com sucesso!');
    } catch (error) {
      formikHelpers.setErrors(formatError(error.errors));
      openErrorToast('Houve um erro ao salvar a Penhora');
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onSubmit
  });

  return (
    <div>
      <FormContainer className="col-2">
        <SelectField
          id="degree"
          name="degree"
          value={formik.values.degree?.toString()}
          title="Grau da penhora"
          error={
            getIn(formik.touched, 'degree') && getIn(formik.errors, 'degree')
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          options={executionDegreeOptions()}
          optional
        />

        <Toggle
          title="Tipo"
          options={executionTypeOptions()}
          onChange={event => formik.setFieldValue('type', event.target.value)}
          checked={formik.values.type}
        />
      </FormContainer>

      <FormContainer>
        <ExecutedFields
          formik={formik}
          caseId={caseId}
          selectedType={formik.values.type}
        />
      </FormContainer>

      <Stack marginTop={StackMargin.MEDIUM}>
        <Button
          onClick={formik.handleSubmit}
          disabled={!formik.isValid}
          highlight
          centered
          type="submit"
        >
          Adicionar
        </Button>
      </Stack>
    </div>
  );
};

export default RelatedForm;
