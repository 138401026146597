import * as Debtors from 'domain/debtors';
import React, { RefObject, useEffect, useContext } from 'react';
import set from 'lodash/set';
import * as Yup from 'yup';
import { Formik, getIn, FormikProps } from 'formik';
import { useHistory } from 'react-router-dom';

import {
  Card,
  Toggle,
  FormContainer,
  SelectField,
  openSuccessToast,
  openErrorToast,
  StackMargin,
  Stack
} from 'ui';
import { AutoCompleteField, CaseContext } from 'components';

import { can, Action, submitDebtor } from 'api';

import {
  OnSubmit,
  EntityType,
  DebtorFormAttributes,
  DebtorJudicialSituation,
  DebtorSector,
  CreditClassType,
  Debtor,
  ApiError,
  CreditClass,
  CreditClassFormAttributes
} from 'types';
import * as ValidationSchema from 'utils/validationSchema';

import JudicialRecovery from './JudicialRecovery/JudicialRecovery';

import './Form.scss';

const initialValues: DebtorFormAttributes = {
  entity: {
    type: EntityType.INDIVIDUAL,
    individual: {
      cpf: '',
      name: '',
      isAbroad: false
    },
    company: {
      cnpj: '',
      businessName: '',
      isAbroad: false
    },
    description: ''
  },
  judicialSituation: '',
  sector: '',
  isAbroad: false,
  state: '',
  orderDate: '',
  deferralDate: '',
  judicialRecoveryState: '',
  cityId: '',
  court: '',
  judge: '',
  judicialAssistant: '',
  debtorLawyer: '',
  prjApprovalDate: '',
  homologationDate: '',
  vote: '',
  exitDate: '',
  comments: '',
  creditClasses: []
};

const validationSchema = Yup.object().shape({
  entity: Yup.object().when('isAbroad', {
    is: false,
    then: Yup.object().shape({
      type: Yup.string().required('Campo obrigatório'),
      individual: Yup.object().when('type', {
        is: EntityType.INDIVIDUAL,
        then: Yup.object().shape({
          cpf: ValidationSchema.cpf().required('Campo obrigatório'),
          name: Yup.string()
            .min(3, 'Precisa ter ao menos 3 caracteres')
            .max(255, 'Precisa ter menos de 256 caracteres')
            .required('Campo obrigatório')
        }),
        otherwise: Yup.object().shape({
          cpf: ValidationSchema.cpf().notRequired(),
          name: Yup.string().notRequired()
        })
      }),
      company: Yup.object().when('type', {
        is: EntityType.COMPANY,
        then: Yup.object().shape({
          cnpj: ValidationSchema.cnpj().required('Campo obrigatório'),
          businessName: Yup.string()
            .min(3, 'Precisa ter ao menos 3 caracteres')
            .max(255, 'Precisa ter menos de 256 caracteres')
            .required('Campo obrigatório')
        }),
        otherwise: Yup.object().shape({
          cnpj: ValidationSchema.cnpj().notRequired(),
          businessName: Yup.string().notRequired()
        })
      })
    }),
    otherwise: Yup.object().shape({
      type: Yup.string().required('Campo obrigatório'),
      individual: Yup.object().when('type', {
        is: EntityType.INDIVIDUAL,
        then: Yup.object().shape({
          cpf: ValidationSchema.cpf()
            .notRequired()
            .nullable(),
          name: Yup.string()
            .min(3, 'Precisa ter ao menos 3 caracteres')
            .max(255, 'Precisa ter menos de 256 caracteres')
            .required('Campo obrigatório')
        }),
        otherwise: Yup.object().shape({
          cpf: ValidationSchema.cpf()
            .notRequired()
            .nullable(),
          name: Yup.string().notRequired()
        })
      }),
      company: Yup.object().when('type', {
        is: EntityType.COMPANY,
        then: Yup.object().shape({
          cnpj: ValidationSchema.cnpj()
            .notRequired()
            .nullable(),
          businessName: Yup.string()
            .min(3, 'Precisa ter ao menos 3 caracteres')
            .max(255, 'Precisa ter menos de 256 caracteres')
            .required('Campo obrigatório')
        }),
        otherwise: Yup.object().shape({
          cnpj: ValidationSchema.cnpj()
            .notRequired()
            .nullable(),
          businessName: Yup.string().notRequired()
        })
      })
    })
  }),

  judicialSituation: Yup.string().required('Campo obrigatório'),
  sector: Yup.string().notRequired(),
  state: Yup.string().notRequired(),
  cityId: Yup.string()
    .notRequired()
    .nullable(),
  orderDate: Yup.date().when('judicialSituation', {
    is: DebtorJudicialSituation.JUDICIAL_RECOVERY,
    then: Yup.date()
      .max(new Date(), 'A data deve ser igual ou anterior a hoje')
      .required('Campo obrigatório'),
    otherwise: Yup.date().notRequired()
  }),
  deferralDate: Yup.date().notRequired(),
  court: Yup.string().notRequired(),
  judge: Yup.string().notRequired(),
  judicialAssistant: Yup.string().notRequired(),
  debtorLawyer: Yup.string().notRequired(),
  prjApprovalDate: Yup.date()
    .max(new Date(), 'A data deve ser igual ou anterior a hoje')
    .notRequired(),
  homologationDate: Yup.date()
    .max(new Date(), 'A data deve ser igual ou anterior a hoje')
    .notRequired(),
  vote: Yup.string().notRequired(),
  exitDate: Yup.date()
    .max(new Date(), 'A data deve ser igual ou anterior a hoje')
    .notRequired(),
  creditClasses: Yup.array().when('judicialSituation', {
    is: DebtorJudicialSituation.JUDICIAL_RECOVERY,
    then: Yup.array()
      .of(
        Yup.object().shape({
          amortizationMonths: Yup.number()
            .typeError('Deve ser um número')
            .notRequired()
            .nullable(),
          classPercentage: Yup.number()
            .typeError('Deve ser um número')
            .max(10000, 'deve ser menor que 100%')
            .min(0, 'Deve ser maior que 0%')
            .notRequired()
            .nullable(),
          class: Yup.string().required('Campo obrigatório'),
          complianceBonusPercentage: Yup.number()
            .typeError('Deve ser um número')
            .max(10000, 'deve ser menor que 100%')
            .min(0, 'Deve ser maior que 0%')
            .notRequired()
            .nullable(),
          haircutPercentage: Yup.number()
            .typeError('Deve ser um número')
            .max(10000, 'deve ser menor que 100%')
            .min(0, 'Deve ser maior que 0%')
            .notRequired()
            .nullable(),
          indexer: Yup.string().notRequired(),
          interestGraceMonths: Yup.number()
            .typeError('Deve ser um número')
            .notRequired()
            .nullable(),
          mainGraceMonths: Yup.number()
            .typeError('Deve ser um número')
            .notRequired(),
          creditValueCents: Yup.number()
            .typeError('Deve ser um número')
            .required('Campo obrigatório'),
          prjFeePercentage: Yup.number()
            .typeError('Deve ser um número')
            .min(0, 'Deve ser maior que 0%')
            .notRequired()
            .nullable()
        })
      )
      .notRequired(),
    otherwise: Yup.array().notRequired()
  })
});

type Props = {
  caseId: string;
  formRef: RefObject<FormikProps<DebtorFormAttributes>>;
  debtor?: Debtor;
  isLoading?: boolean;
  onUpdate?: (debtor: Debtor) => void;
};

function idFor(name: string): string {
  return `debtor-form-${name}`;
}

function buildInitialCreditClasses(
  creditClasses: CreditClass[]
): CreditClassFormAttributes[] {
  if (creditClasses.length === 0) {
    return initialValues.creditClasses;
  }

  return creditClasses.map(creditClass => {
    return {
      amortizationMonths: creditClass.amortizationMonths?.toString() || '',
      classPercentage: creditClass.classPercentage?.toString() || '',
      class: creditClass.class?.toString() || CreditClassType.EXTRACONCURSAL,
      complianceBonusPercentage:
        creditClass.complianceBonusPercentage?.toString() || '',
      haircutPercentage: creditClass.haircutPercentage?.toString() || '',
      indexer: creditClass.indexer || '',
      interestGraceMonths: creditClass.interestGraceMonths?.toString() || '',
      mainGraceMonths: creditClass.mainGraceMonths?.toString() || '',
      creditValueCents: creditClass.creditValueCents?.toString() || '',
      prjFeePercentage: creditClass.prjFeePercentage?.toString() || ''
    };
  });
}

function buildInitialValues(debtor: Debtor | undefined): DebtorFormAttributes {
  if (!debtor) {
    return initialValues;
  }

  return {
    judicialSituation: debtor.judicialSituation || '',
    sector: debtor.sector || '',
    isAbroad: debtor.isAbroad || false,
    state: debtor.state || '',
    orderDate: debtor.orderDate || '',
    deferralDate: debtor.deferralDate || '',
    judicialRecoveryState: debtor.city?.state || '',
    cityId: debtor.city?.id || '',
    court: debtor.court || '',
    judge: debtor.judge || '',
    judicialAssistant: debtor.judicialAssistant || '',
    debtorLawyer: debtor.debtorLawyer || '',
    prjApprovalDate: debtor.prjApprovalDate || '',
    homologationDate: debtor.homologationDate || '',
    vote: debtor.vote || '',
    exitDate: debtor.exitDate || '',
    comments: debtor.comments || '',
    entity: {
      type: debtor.entity.type,
      individual: {
        cpf: '',
        name: '',
        isAbroad: false,
        ...debtor.entity.individual
      },
      company: {
        cnpj: '',
        businessName: '',
        isAbroad: false,
        ...debtor.entity.company
      },
      description: debtor.entity.description ?? ''
    },
    creditClasses: buildInitialCreditClasses(debtor.creditClasses)
  };
}

function formatError(values: DebtorFormAttributes, errors?: ApiError) {
  if (!errors) return {};

  if (values.entity.type === EntityType.INDIVIDUAL) {
    return set(errors, 'entity.individual.cpf', errors.entityId);
  }

  if (values.entity.type === EntityType.COMPANY) {
    return set(errors, 'entity.company.cnpj', errors.entityId);
  }

  return errors;
}

const Form = ({
  caseId,
  formRef,
  debtor,
  onUpdate = (_: Debtor) => {},
  isLoading = false
}: Props) => {
  const history = useHistory();
  const { caseData } = useContext(CaseContext);

  useEffect(() => {
    formRef?.current?.setValues(buildInitialValues(debtor));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const onSubmit: OnSubmit<DebtorFormAttributes> = async (
    values,
    formikHelpers
  ) => {
    try {
      const response = await submitDebtor(values, caseId, debtor);

      if (response.status === 201) {
        history.push(`/cases/${caseId}/debtors/${response.data.id}`);
      }

      onUpdate(response.data);

      openSuccessToast('Devedor salvo com sucesso!');
    } catch (error) {
      openErrorToast('Houve um erro ao salvar o devedor');

      if (error.errors) {
        formikHelpers.setErrors(formatError(values, error.errors));
      }
    }
  };

  const negotiatorId = caseData?.negotiator?.id;

  const canUser = {
    addDebtor: can(Action.CASOS_DEVEDOR_INCLUIR, negotiatorId),
    editDebtor: can(Action.CASOS_DEVEDOR_EDITAR, negotiatorId),
    showJudicialRecovery: can(
      Action.CASOS_DEVEDOR_RECUPERACAO_JUDICIAL_CONSULTAR,
      negotiatorId
    ),
    addJudicialRecovery: can(
      Action.CASOS_DEVEDOR_RECUPERACAO_JUDICIAL_INCLUIR,
      negotiatorId
    )
  };

  const isDisabled = debtor ? !canUser.editDebtor : !canUser.addDebtor;

  const judicialSituationOptions =
    canUser.showJudicialRecovery && canUser.addJudicialRecovery
      ? Debtors.judicialSituationOptions()
      : Debtors.judicialSituationOptions().map(el =>
          el.value === 'judicial_recovery' ? { ...el, disabled: true } : el
        );

  return (
    <Formik<DebtorFormAttributes>
      innerRef={formRef}
      initialValues={buildInitialValues(debtor)}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {formik => (
        <div className="debtor-form" data-testid="debtor-form">
          <Card>
            <h3 className="title">Devedor</h3>
            <FormContainer className="col-4">
              <Toggle
                checked={formik.values.entity.type}
                onChange={event =>
                  formik.setFieldValue('entity.type', event?.target.value)
                }
                title="Tipo de pessoa"
                options={[
                  { value: EntityType.INDIVIDUAL, label: 'Física' },
                  { value: EntityType.COMPANY, label: 'Jurídica' }
                ]}
                isLoading={isLoading}
                disabled={isDisabled}
              />

              {formik.values.entity.type === 'individual' ? (
                <>
                  <AutoCompleteField
                    caseId={caseId}
                    isAbroad={formik.values.isAbroad}
                    entityType={EntityType.INDIVIDUAL}
                    onSelect={selection => {
                      formik.setFieldValue(
                        'entity.individual.cpf',
                        selection!.value
                      );
                      formik.setFieldValue(
                        'entity.individual.name',
                        selection!.complement
                      );
                    }}
                    dataToItem={data => {
                      return {
                        value: data.individual.cpf,
                        complement: data.individual.name
                      };
                    }}
                    id={idFor('entity-individual-cpf')}
                    name="entity.individual.cpf"
                    type="cpf"
                    title="CPF"
                    value={formik.values.entity.individual.cpf}
                    error={
                      getIn(formik.touched, 'entity.individual.cpf') &&
                      getIn(formik.errors, 'entity.individual.cpf')
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isLoading={isLoading}
                    disabled={isDisabled}
                  />

                  <AutoCompleteField
                    caseId={caseId}
                    isAbroad={formik.values.isAbroad}
                    entityType={EntityType.INDIVIDUAL}
                    onSelect={selection => {
                      formik.setFieldValue(
                        'entity.individual.name',
                        selection!.value
                      );
                      formik.setFieldValue(
                        'entity.individual.cpf',
                        selection!.complement
                      );
                    }}
                    dataToItem={data => ({
                      value: data.individual.name,
                      complement: data.individual.cpf
                    })}
                    id={idFor('entity-individual-name')}
                    name="entity.individual.name"
                    type="text"
                    value={formik.values.entity.individual.name}
                    title="Nome completo"
                    error={
                      getIn(formik.touched, 'entity.individual.name') &&
                      getIn(formik.errors, 'entity.individual.name')
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isLoading={isLoading}
                    disabled={isDisabled}
                  />
                </>
              ) : (
                <>
                  <AutoCompleteField
                    caseId={caseId}
                    isAbroad={formik.values.isAbroad}
                    entityType={EntityType.COMPANY}
                    onSelect={selection => {
                      formik.setFieldValue(
                        'entity.company.cnpj',
                        selection!.value
                      );
                      formik.setFieldValue(
                        'entity.company.businessName',
                        selection!.complement
                      );
                    }}
                    dataToItem={data => {
                      return {
                        value: data.company.cnpj,
                        complement: data.company.businessName
                      };
                    }}
                    id={idFor('entity-company-cnpj')}
                    name="entity.company.cnpj"
                    value={formik.values.entity.company.cnpj}
                    title="CNPJ"
                    type="cnpj"
                    error={
                      getIn(formik.touched, 'entity.company.cnpj') &&
                      getIn(formik.errors, 'entity.company.cnpj')
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isLoading={isLoading}
                    disabled={isDisabled}
                  />

                  <AutoCompleteField
                    caseId={caseId}
                    isAbroad={formik.values.isAbroad}
                    entityType={EntityType.COMPANY}
                    onSelect={selection => {
                      formik.setFieldValue(
                        'entity.company.businessName',
                        selection!.value
                      );
                      formik.setFieldValue(
                        'entity.company.cnpj',
                        selection!.complement
                      );
                    }}
                    dataToItem={data => {
                      return {
                        value: data.company.businessName,
                        complement: data.company.cnpj
                      };
                    }}
                    id={idFor('entity-company-businessName')}
                    name="entity.company.businessName"
                    value={formik.values.entity.company.businessName}
                    title="Razão social"
                    type="text"
                    error={
                      getIn(formik.touched, 'entity.company.businessName') &&
                      getIn(formik.errors, 'entity.company.businessName')
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isLoading={isLoading}
                    disabled={isDisabled}
                  />
                </>
              )}

              <SelectField
                title="Situação judicial"
                options={judicialSituationOptions}
                name="judicialSituation"
                id="judicial_situation"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.judicialSituation}
                error={
                  getIn(formik.touched, 'judicialSituation') &&
                  getIn(formik.errors, 'judicialSituation')
                }
                isLoading={isLoading}
                disabled={isDisabled}
              />

              <SelectField
                title="Setor (opcional)"
                options={[
                  {
                    value: DebtorSector.WHOLESALE_TRADE,
                    label: 'Comércio atacado'
                  },
                  {
                    value: DebtorSector.RETAIL_TRADE,
                    label: 'Comércio varejo'
                  },
                  {
                    value: DebtorSector.CONSTRUCTION,
                    label: 'Construção civil'
                  },
                  { value: DebtorSector.DISTRIBUTION, label: 'Distribuição' },
                  { value: DebtorSector.SHIPYARD, label: 'Estaleiro' },
                  { value: DebtorSector.POWER_GENERATION, label: 'Geração' },
                  { value: DebtorSector.GRAINS, label: 'Grãos' },
                  {
                    value: DebtorSector.ESTATE_DEVELOPMENT,
                    label: 'Incorporadora'
                  },
                  { value: DebtorSector.INDUSTRY, label: 'Industria' },
                  { value: DebtorSector.LOGISTICS, label: 'Logística' },
                  { value: DebtorSector.MINING, label: 'Mineração' },
                  { value: DebtorSector.OIL_AND_GAS, label: 'Óleo e gás' },
                  { value: DebtorSector.SERVICES, label: 'Serviços' },
                  {
                    value: DebtorSector.SUGAR_ALCOHOL,
                    label: 'Sucroalcooleiro'
                  },
                  { value: DebtorSector.TRADING, label: 'Trading' },
                  {
                    value: DebtorSector.POWER_TRANSMISSION,
                    label: 'Transmissão'
                  },
                  { value: DebtorSector.TRANSPORT, label: 'Transporte' },
                  { value: DebtorSector.OTHERS, label: 'Outros' }
                ]}
                value={formik.values.sector}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="sector"
                id="sector"
                error={
                  getIn(formik.touched, 'sector') &&
                  getIn(formik.errors, 'sector')
                }
                isLoading={isLoading}
                disabled={isDisabled}
              />

              <Toggle
                name="isAbroad"
                title="País"
                options={[
                  { value: 'false', label: 'Brasil' },
                  { value: 'true', label: 'Exterior' }
                ]}
                checked={formik.values.isAbroad.toString()}
                onChange={event =>
                  formik.setFieldValue('isAbroad', event?.target.value)
                }
                isLoading={isLoading}
                disabled={isDisabled}
              />

              <SelectField
                title="Estado (opcional)"
                options={[
                  { value: 'AC', label: 'AC' },
                  { value: 'AL', label: 'AL' },
                  { value: 'AP', label: 'AP' },
                  { value: 'AM', label: 'AM' },
                  { value: 'BA', label: 'BA' },
                  { value: 'CE', label: 'CE' },
                  { value: 'DF', label: 'DF' },
                  { value: 'ES', label: 'ES' },
                  { value: 'GO', label: 'GO' },
                  { value: 'MA', label: 'MA' },
                  { value: 'MT', label: 'MT' },
                  { value: 'MS', label: 'MS' },
                  { value: 'MG', label: 'MG' },
                  { value: 'PA', label: 'PA' },
                  { value: 'PB', label: 'PB' },
                  { value: 'PR', label: 'PR' },
                  { value: 'PE', label: 'PE' },
                  { value: 'PI', label: 'PI' },
                  { value: 'RJ', label: 'RJ' },
                  { value: 'RN', label: 'RN' },
                  { value: 'RS', label: 'RS' },
                  { value: 'RO', label: 'RO' },
                  { value: 'RR', label: 'RR' },
                  { value: 'SC', label: 'SC' },
                  { value: 'SP', label: 'SP' },
                  { value: 'SE', label: 'SE' },
                  { value: 'TO', label: 'TO' }
                ]}
                value={formik.values.state}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="state"
                id="state"
                error={
                  getIn(formik.touched, 'state') &&
                  getIn(formik.errors, 'state')
                }
                isLoading={isLoading}
                disabled={Boolean(formik.values.isAbroad) || isDisabled}
              />
            </FormContainer>
          </Card>

          {canUser.showJudicialRecovery &&
            !isLoading &&
            formik.values.judicialSituation ===
              DebtorJudicialSituation.JUDICIAL_RECOVERY && (
              <Stack
                dataTestId="judicial-recovery"
                marginTop={StackMargin.XLARGE}
              >
                <JudicialRecovery formik={formik} />
              </Stack>
            )}
        </div>
      )}
    </Formik>
  );
};

export default Form;
