import { FileUpload, Entity, Asset, CreditContract } from 'types';

export enum ReclamingType {
  MONEY = 'dinheiro',
  CREDIT_ASSIGNMENT = 'cessao_de_credito',
  MACHINES_AND_EQUIPMENTS = 'maquinas_e_equipamentos',
  VEHICLES = 'veiculos',
  RURAL_PROPERTY = 'imovel_rural',
  COMMERCIAL_RESIDENTIAL_PROPERTY = 'imovel_comercial_residencial',
  URBAN_LAND_PROPERTY = 'imovel_terreno_urbano',
  STOCK_OR_QUOTAS = 'acoes_ou_quotas',
  STOCK = 'estoque',
  GUARANTEE_LETTER_RETURN = 'devolucao_carta_de_fianca'
}

export enum FrequencyType {
  RECURRENT = 'recurrent',
  SINGLE = 'single'
}

export type ReclaimingFormAttributes = {
  type: string;
  frequency: FrequencyType;
  totalAmount: number;
  date: string;
  comments?: string;
  payments: Payment[];
  payor: Entity;
  fileUploadIds: Array<string>;
};

export type Payment = {
  amount: string;
  date: string;
};

export type Reclaiming = ReclaimingFormAttributes & {
  id: string;
  lastChangedAt: string;
  contractId?: string;
  contract: CreditContract;
  fileUploads: Array<FileUpload>;
  assets: Asset[];
};

export type ReclaimingParams = Partial<Reclaiming>;
