import React, { ReactNode, ReactElement } from 'react';
import cn from 'classnames';

import './Card.scss';
import CardHeader from './CardHeader/CardHeader';

type Props = {
  children: ReactNode;
  customHeader?: ReactNode;
  small?: boolean;
  medium?: boolean;
  className?: string;
  dataTestId?: string;
  id?: string;
};

const Card = ({
  children,
  className = '',
  dataTestId = 'card',
  medium,
  small,
  customHeader,
  ...rest
}: Props): ReactElement => {
  const classNames = cn('card-default', className, {
    '-small': small,
    '-medium': medium
  });

  return (
    <div data-testid={dataTestId} className={classNames} {...rest}>
      {customHeader}
      {children}
    </div>
  );
};

export default Card;
Card.Header = CardHeader;
