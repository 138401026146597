import * as Contracts from 'domain/contracts';
import React from 'react';
import { ListItem, Link, EmptyState, Card, Loader } from 'ui';
import xor from 'lodash/xor';
import cn from 'classnames';
import { CreditContract } from 'types';
import './ContractList.scss';

type Props = {
  caseId: string;
  onCheckToogle: (value: string[]) => void;
  selectedContractIds?: string[];
  fetchedContracts?: Array<CreditContract>;
  error?: string[] | string;
  isLoading?: boolean;
  showContractLink?: boolean;
};

const ContractList = ({
  caseId,
  onCheckToogle,
  selectedContractIds,
  fetchedContracts,
  error,
  isLoading,
  showContractLink = true
}: Props) => {
  const handleToggle = (contractId: string) => () => {
    const selectedContracts = xor(selectedContractIds, [contractId]);
    onCheckToogle(selectedContracts);
  };

  return (
    <div
      id="contracts"
      className={cn('lawsuits-contract-list', {
        '-error': Boolean(error)
      })}
    >
      <span className="title">Vincular contratos</span>

      {fetchedContracts?.length === 0 && (
        <EmptyState text="Caso não tem nenhum contrato" />
      )}

      {isLoading && (
        <Card>
          <Loader height={1.5} width={4} />
        </Card>
      )}

      {fetchedContracts?.map((contract: CreditContract) => (
        <ListItem
          id={contract.id}
          key={`contract-item-${contract.id}`}
          onCheckboxClick={handleToggle(contract.id)}
          checked={selectedContractIds?.includes(contract.id)}
          title={`Contrato ${contract.number}`}
          text={Contracts.modalityLabel(contract.modality)}
          actions={
            showContractLink ? (
              <Link
                target="_blank"
                highlight
                href={`/cases/${caseId}/debtors/contract/${contract.id}`}
                iconName="open-in-browser"
              >
                Ver contrato
              </Link>
            ) : (
              <></>
            )
          }
        />
      ))}

      {error && (
        <p className="message">
          {Array.isArray(error) ? error.join(', ') : error}
        </p>
      )}
    </div>
  );
};

export default ContractList;
