import React, { useContext, useEffect } from 'react';
import { Action, can, fetchTrackedExternalLawsuits } from 'api';
import { PaginatedResource } from 'types';
import { Accordion, openErrorToast } from 'ui';
import {
  CaseContext,
  ExternalLawsuitList,
  usePaginationContext
} from 'components';
import { useQuery } from 'hooks';

type Props = {
  caseId: string;
};

const ExternalLawsuits = ({ caseId }: Props) => {
  const { getPagination } = usePaginationContext();
  const { caseData } = useContext(CaseContext);

  const contextPagination = getPagination(PaginatedResource.EXTERNAL_LAWSUITS);

  const fetchPagination = contextPagination ?? {
    pagination: { page: 1, pageSize: 6 }
  };

  const { data: externalLawsuits, pagination, error, isLoading } = useQuery(
    fetchTrackedExternalLawsuits,
    {
      caseId,
      ...fetchPagination
    }
  );

  useEffect(() => {
    error && openErrorToast('Não foi possível listar os processos.');
  }, [error]);

  const isExternalLawsuitsEmpty = !!(
    externalLawsuits && externalLawsuits.length === 0
  );

  const canShowLawsuitsLinks = can(
    Action.CASOS_RASTREAMENTO_PROCESSOS_VER_PROCESSO,
    caseData?.negotiator?.id
  );

  return (
    <Accordion
      title="Processos"
      badgeContent={pagination?.totalEntries.toString()}
      isClosed={isExternalLawsuitsEmpty}
    >
      {(externalLawsuits || isLoading) && (
        <ExternalLawsuitList
          data={{ data: externalLawsuits!, pagination: pagination! }}
          isLoading={isLoading}
          hideTrackingIcon
          hideTitleLink={!canShowLawsuitsLinks}
        />
      )}
    </Accordion>
  );
};

export default ExternalLawsuits;
