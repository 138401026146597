import * as Reports from 'domain/reports';
import React from 'react';
import identity from 'lodash/identity';

import { Status } from 'ui';
import { Report } from 'types';

type Props = {
  report?: Report | null;
  formatter?: (_: string) => string;
};

const ReportStatus = ({ report, formatter = identity }: Props) => {
  const status = Reports.reportStatus(report);

  return (
    <>
      <Status status={status}>
        <span data-testid="report-status">
          {formatter(Reports.reportStatusText(report!, status))}
        </span>
      </Status>
    </>
  );
};
export default ReportStatus;
