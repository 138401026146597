import React, { ReactElement } from 'react';
import { Icon } from 'ui';
import './FileIcon.scss';

type Props = {
  extension: string;
  fill?: string;
  dataTestId?: string;
};

const FileIcon = ({
  extension,
  fill = '#28292f80',
  dataTestId = 'file-icon'
}: Props): ReactElement => {
  return (
    <div className="file-icon" data-testid={dataTestId}>
      <Icon name="document" fill={fill}></Icon>
      <span className="extension">{extension}</span>
    </div>
  );
};

export default FileIcon;
