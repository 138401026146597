import { Notification, UnreadNotificationsCount } from 'types';
import Client from './client';

type FetchNotificationsParams = {
  caseId?: string;
  pagination?: {
    page: number;
    pageSize: number;
  };
};

function fetchNotifications({ caseId, pagination }: FetchNotificationsParams) {
  if (caseId) {
    return Client.get<Notification[]>(`cases/${caseId}/notifications`, {
      params: {
        page: pagination?.page.toString(),
        pageSize: pagination?.pageSize.toString()
      }
    });
  }

  return Client.get<Notification[]>('notifications', {
    params: {
      page: pagination?.page.toString(),
      pageSize: pagination?.pageSize.toString()
    }
  });
}

function unreadNotificationsCount({ caseId }: { caseId?: string }) {
  if (caseId) {
    return Client.get<UnreadNotificationsCount>(
      `cases/${caseId}/notifications/unread_count`
    );
  }

  return Client.get<UnreadNotificationsCount>('notifications/unread_count');
}

function toggleReadNotification({ id, read }: { id: string; read: boolean }) {
  return Client.patch<void, Notification>(`notifications/${id}/toggle_read`, {
    read
  });
}

export { fetchNotifications, unreadNotificationsCount, toggleReadNotification };
