import { Case, ApiSuccessfulResponse } from 'types';

export enum NotificationEventTarget {
  INTERNAL_LAWSUIT = 'internal_lawsuit',
  EXTERNAL_LAWSUIT = 'external_lawsuit',
  CHILD_LAWSUIT = 'child_lawsuit',
  REPORT = 'report',
  SOCIAL_REPORT = 'social_report',
  MANUAL_MOVEMENT = 'manual_movement',
  CASE_EVENT = 'case_event'
}

export enum NotificationEventType {
  MOVEMENT_CREATED = 'movement_created',
  LEGAL_REPORT_FAILED = 'legal_report_failed',
  LEGAL_REPORT_FINISHED = 'legal_report_finished',
  INSTAGRAM_PROFILE_FOUND = 'instagram_profile_found',
  INSTAGRAM_PROFILE_NOT_FOUND = 'instagram_profile_not_found',
  SCHEDULED_EVENT_HAPPENED = 'scheduled_event_happened'
}

export type MovementContent = {
  movementId: string;
  number: string | null;
};

export type ExternalLawsuitMovementEventContent = {
  externalLawsuitId: string;
} & MovementContent;

export type ChildLawsuitMovementEventContent = {
  parentId: string;
  childId: string;
} & MovementContent;

export type InternalLawsuitMovementEventContent = {
  internalLawsuitId: string;
} & MovementContent;

export type ReportLegalReportEventContent = {
  reportId: string;
  name: string;
};

export type ReportInstagramProfileEventContent = {
  reportId: string;
  name: string;
};

export type SocialReportInstagramProfileEventContent = {
  socialReportId: string;
  socialName: string;
};

export type ScheduledManualMovementEventContent = {
  manualMovementId: string;
  internalLawsuitId: string;
  title: string;
};

export type ScheduledCaseEventContent = {
  eventId: string;
  title: string;
};

type EventContent =
  | ExternalLawsuitMovementEventContent
  | ChildLawsuitMovementEventContent
  | InternalLawsuitMovementEventContent
  | ReportLegalReportEventContent
  | ReportInstagramProfileEventContent
  | SocialReportInstagramProfileEventContent
  | ScheduledManualMovementEventContent
  | ScheduledCaseEventContent;

export type NotificationEvent = {
  id: string;
  target: NotificationEventTarget;
  type: NotificationEventType;
  insertedAt: string;
  content: EventContent;
  case: Case;
};

export type Notification = {
  id: string;
  read: boolean;
  event: NotificationEvent;
};

export type NotificationsResponse = ApiSuccessfulResponse<Notification[]>;

export type UnreadNotificationsCount = {
  unreadCount: number;
};
