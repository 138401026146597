import { useEffect } from 'react';
import { useChannel } from 'components';

function useLiveLawsuit(topic: string, refetchCallback: () => Promise<void>) {
  const { channel, isConnected } = useChannel(topic);

  useEffect(() => {
    if (!channel || !isConnected) return;

    const queue: string[] = [];
    let isRefetching = false;

    const clearQueue = () => queue.splice(0, queue.length);

    const refetchData = async () => {
      if (!isRefetching && queue.length > 0) {
        isRefetching = true;
        clearQueue();

        await refetchCallback().finally(() => {
          isRefetching = false;

          refetchData();
        });
      }
    };

    const addToQueue = () => {
      queue.push('lawsuit_updated');

      refetchData();
    };

    channel.on('lawsuit_updated', addToQueue);

    return () => {
      if (channel) {
        channel.off('lawsuit_updated');
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topic, isConnected, channel]);
}

export { useLiveLawsuit };
