import React from 'react';
import { getIn, FormikHandlers } from 'formik';
import { Button, InputField } from 'ui';

type Field = 'email';
type Values = { [key in Field]: string };
type Errors = { [key in Field]?: string };
type Touched = { [key in Field]?: boolean };

type Props = {
  touched: Touched;
  errors: Errors;
  values: Values;
  isSubmitting: boolean;
  handleOnChange: FormikHandlers['handleChange'];
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  handleBlur: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const Form = ({
  handleSubmit,
  touched,
  errors,
  handleOnChange,
  handleBlur,
  values,
  isSubmitting
}: Props) => {
  return (
    <form data-testid="reset-password-form" onSubmit={handleSubmit}>
      <InputField
        id="email"
        name="email"
        type="text"
        title="Email"
        error={touched.email && getIn(errors, 'email')}
        onChange={handleOnChange}
        onBlur={handleBlur}
        value={values.email}
        dataTestId="reset-password-input-email"
      />

      <Button highlight type="submit" disabled={isSubmitting}>
        Resetar
      </Button>
    </form>
  );
};

export default Form;
