import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { DateTime } from 'luxon';

import { useFeatureFlag, useQueryCall } from 'hooks';
import { Feature } from 'types';
import { fetchInstagramLocationPublications } from 'api';

import Tippy from '@tippyjs/react';
import { Button, Link, openErrorToast } from 'ui';
import { PublicationBookmark, BookmarkPosition } from 'components';

import 'tippy.js/themes/translucent.css';
import style from './PinTippy.module.scss';

type Props = {
  caseId: string;
  reportId: string;
  children: ReactElement;
  count: number;
  googleMapsUrl: string;
  locationId: string;
  showInstagramPostOnPin?: boolean;
  canBookmarkPublication?: boolean;
  onClickPostsButton: () => void;
};

const PinTippy = ({
  caseId,
  reportId,
  children,
  count,
  googleMapsUrl,
  locationId,
  showInstagramPostOnPin = true,
  canBookmarkPublication = true,
  onClickPostsButton
}: Props) => {
  const [shouldRender, setShouldRender] = useState(false);

  const TippyInfo = () => {
    const [isBookmarkInstagramPublicationEnabled] = useFeatureFlag(
      Feature.BOOKMARK_INSTAGRAM_PUBLICATION
    );

    const { data, request, isLoading, error } = useQueryCall(
      fetchInstagramLocationPublications
    );

    if (error) openErrorToast('Ocorreu um erro ao carregar o link');

    const fetchPublications = useCallback(
      async () => {
        if (count === 1) {
          await request({ caseId, reportId, instagramLocationId: locationId });
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    useEffect(() => {
      fetchPublications();
    }, [fetchPublications]);

    return (
      <div className={style.content}>
        <Link
          asButton
          external
          social
          small
          iconName="google-maps"
          href={googleMapsUrl}
        >
          Google Maps
        </Link>
        {showInstagramPostOnPin ? (
          count === 1 ? (
            <div className={style.publicationLinkWrapper}>
              {isLoading || !data || error ? (
                <Button disabled social small icon="instagram">
                  Carregando link...
                </Button>
              ) : (
                <>
                  <Link
                    asButton
                    external
                    social
                    small
                    iconName="instagram"
                    href={data?.[0].url}
                  >
                    {`Post ${DateTime.fromISO(data![0].createdAt).toFormat(
                      'dd/MM/yy T'
                    )}`}
                  </Link>

                  {canBookmarkPublication &&
                    isBookmarkInstagramPublicationEnabled && (
                      <PublicationBookmark
                        caseId={caseId}
                        reportId={reportId}
                        instagramPublication={data![0]}
                        position={BookmarkPosition.right}
                        color="#fff"
                      />
                    )}
                </>
              )}
            </div>
          ) : (
            <Button
              social
              small
              icon="instagram"
              className={style.instagram}
              onClick={onClickPostsButton}
            >
              {`Postagens (${count})`}
            </Button>
          )
        ) : null}
      </div>
    );
  };

  return (
    <Tippy
      interactive
      trigger="click"
      maxWidth="none"
      theme="translucent"
      className={style.container}
      content={shouldRender ? <TippyInfo /> : undefined}
      onShow={() => setShouldRender(true)}
      onHidden={() => setShouldRender(false)}
    >
      {children}
    </Tippy>
  );
};

export default PinTippy;
