import { entityName, entityTypeOptions } from 'domain/entity';
import React from 'react';
import { FormikProps, getIn } from 'formik';
import uniqBy from 'lodash/uniqBy';

import { Asset, AssetAttributes, Entity, EntityType } from 'types';
import { AutoCompleteField } from 'components';
import {
  Card,
  FormContainer,
  SelectField,
  Stack,
  StackMargin,
  Toggle
} from 'ui';

import { useAssetOwner } from '../AssetOwnerContext';

import styles from './EntityFields.module.scss';

type Props = {
  asset?: Asset;
  caseId: string;
  formik: FormikProps<AssetAttributes>;
  isLoading: boolean;
  disabled?: boolean;
};

type Options = {
  value: string;
  label: string;
}[];

const OPTIONS_ABROAD: Options = [
  { value: 'false', label: 'Brasil' },
  { value: 'true', label: 'Exterior' }
];

const EntityFields = ({
  asset,
  caseId,
  formik,
  isLoading,
  disabled
}: Props) => {
  const { groupAssetsList } = useAssetOwner();

  const entitiesList = uniqBy(
    asset
      ? [...groupAssetsList.map((asset: Asset) => asset.entity), asset.entity]
      : groupAssetsList.map((asset: Asset) => asset.entity),
    'id'
  );

  const entityNameOptions = () =>
    entitiesList.map((entity: Entity) => {
      return {
        value: entity.id,
        label: entityName(entity)
      };
    });

  const handleSelectChanges = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const entity = groupAssetsList.find(
      (asset: Asset) => asset.entity.id === event.target.value
    )?.entity;

    formik.setFieldValue('entity', entity);
  };

  return (
    <Stack marginTop={StackMargin.XLARGE}>
      <Card medium>
        <h3 className={styles.title}>Proprietário</h3>
        <FormContainer className={`col-4 ${styles.formContainer}`}>
          <Toggle
            title="Tipo de pessoa"
            name="entity.type"
            isLoading={isLoading}
            options={entityTypeOptions()}
            checked={formik.values.entity.type}
            showMode={!formik.values.isEditable}
            onChange={event =>
              formik.setFieldValue('entity.type', event.target.value)
            }
            disabled={disabled}
          />

          {formik.values.entity?.type === EntityType.INDIVIDUAL ? (
            <>
              <Toggle
                title="País"
                name={'entity.individual.isAbroad'}
                isLoading={isLoading}
                options={OPTIONS_ABROAD}
                checked={formik.values.entity.individual.isAbroad.toString()}
                showMode={!formik.values.isEditable}
                onChange={event =>
                  formik.setFieldValue(
                    'entity.individual.isAbroad',
                    event.target.value
                  )
                }
                disabled={disabled}
              />
              <AutoCompleteField
                title="CPF"
                id="entity-cpf"
                name="entity.individual.cpf"
                type="cpf"
                caseId={caseId}
                isLoading={isLoading}
                showMode={!formik.values.isEditable}
                isAbroad={formik.values.entity.individual.isAbroad}
                entityType={EntityType.INDIVIDUAL}
                onSelect={selection => {
                  formik.setFieldValue(
                    'entity.individual.cpf',
                    selection!.value
                  );
                  formik.setFieldValue(
                    'entity.individual.name',
                    selection!.complement
                  );
                }}
                dataToItem={data => ({
                  value: data.individual.cpf,
                  complement: data.individual.name
                })}
                value={formik.values.entity.individual.cpf}
                error={getIn(formik.errors, 'entity.individual.cpf')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={disabled}
              />

              {!asset?.isGroup && (
                <AutoCompleteField
                  title="Nome completo"
                  id="entity-name"
                  name="entity.individual.name"
                  type="text"
                  caseId={caseId}
                  isLoading={isLoading}
                  showMode={!formik.values.isEditable}
                  isAbroad={formik.values.entity.individual.isAbroad}
                  entityType={EntityType.INDIVIDUAL}
                  onSelect={selection => {
                    formik.setFieldValue(
                      'entity.individual.name',
                      selection!.value
                    );
                    formik.setFieldValue(
                      'entity.individual.cpf',
                      selection!.complement
                    );
                  }}
                  dataToItem={data => ({
                    value: data.individual.name,
                    complement: data.individual.cpf
                  })}
                  value={formik.values.entity.individual.name}
                  error={getIn(formik.errors, 'entity.individual.name')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={disabled}
                />
              )}
            </>
          ) : (
            <>
              <Toggle
                title="País"
                name={'entity.company.isAbroad'}
                isLoading={isLoading}
                options={OPTIONS_ABROAD}
                checked={formik.values.entity?.company.isAbroad.toString()}
                showMode={!formik.values.isEditable}
                onChange={event =>
                  formik.setFieldValue(
                    'entity.company.isAbroad',
                    event.target.value
                  )
                }
                disabled={disabled}
              />
              <AutoCompleteField
                title="CNPJ"
                id="entity-cnpj"
                name="entity.company.cnpj"
                type="cnpj"
                caseId={caseId}
                isLoading={isLoading}
                showMode={!formik.values.isEditable}
                isAbroad={formik.values.entity?.company.isAbroad}
                entityType={EntityType.COMPANY}
                onSelect={selection => {
                  formik.setFieldValue('entity.company.cnpj', selection!.value);
                  formik.setFieldValue(
                    'entity.company.businessName',
                    selection!.complement
                  );
                }}
                dataToItem={data => ({
                  value: data.company.cnpj,
                  complement: data.company.businessName
                })}
                value={formik.values.entity?.company.cnpj}
                error={getIn(formik.errors, 'entity.company.cnpj')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={disabled}
              />

              {!asset?.isGroup && (
                <AutoCompleteField
                  title="Razão social"
                  id="entity-businessName"
                  name="entity.company.businessName"
                  type="text"
                  caseId={caseId}
                  isLoading={isLoading}
                  showMode={!formik.values.isEditable}
                  isAbroad={formik.values.entity?.company.isAbroad}
                  entityType={EntityType.COMPANY}
                  onSelect={selection => {
                    formik.setFieldValue(
                      'entity.company.businessName',
                      selection!.value
                    );
                    formik.setFieldValue(
                      'entity.company.cnpj',
                      selection!.complement
                    );
                  }}
                  dataToItem={data => ({
                    value: data.company.businessName,
                    complement: data.company.cnpj
                  })}
                  value={formik.values.entity?.company.businessName}
                  error={getIn(formik.errors, 'entity.company.businessName')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={disabled}
                />
              )}
            </>
          )}

          {asset?.isGroup && (
            <SelectField
              title={
                formik.values.entity.type === EntityType.INDIVIDUAL
                  ? 'Nome completo'
                  : 'Razão social'
              }
              id="entity.id"
              name="entity.id"
              aria-label="entity-select"
              value={formik.values.entity.id}
              error={getIn(formik.errors, 'entity.id')}
              onChange={handleSelectChanges}
              onBlur={formik.handleBlur}
              options={entityNameOptions() || []}
              showMode={entitiesList.length === 1}
              disabled={disabled}
            />
          )}
        </FormContainer>
      </Card>
    </Stack>
  );
};

export default EntityFields;
