import { contractBadgeColor } from 'domain/contracts';
import React, { useContext } from 'react';
import { DateTime } from 'luxon';

import { useToggles, useContainer } from 'hooks';
import { daysSince, pluralizeByDays } from 'utils';

import { Action, can } from 'api';
import { CaseContext, ColoredBadge } from 'components';
import { Link, Stack, StackMargin, Trigger } from 'ui';

import { CreditContract } from 'types';

import ActivityItem from './ActivityItem';

import style from './ActivitiesPerContract.module.scss';

type Props = {
  caseId: string;
  contract: CreditContract;
};

const ActivitiesPerContract = ({ contract, caseId }: Props) => {
  const { isOpen, toggle } = useToggles<'activity'>({
    activity: false
  });

  const { mapEntries: mapActivities, setEntry: setActivity } = useContainer(
    contract.activities
  );

  const { caseData } = useContext(CaseContext);

  const now = DateTime.local().toISO();

  const daysInOverdue = pluralizeByDays(
    daysSince(contract.firstOverduePaymentDate ?? now, now)
  );

  const canViewContract = can(
    Action.CASOS_ACOMPANHAMENTO_ATIVIDADES_VER_CONTRATO,
    caseData?.negotiator?.id
  );

  return (
    <>
      <Stack className={style.titleStack} marginTop={StackMargin.XLARGE}>
        <Trigger
          className={style.trigger}
          isClosed={!isOpen.activity}
          onClick={toggle('activity')}
          label={`contract-${contract.id}`}
        />
        <Link
          highlight
          underline
          to={`/cases/${caseId}/debtors/contract/${contract.id}`}
          className={style.title}
          disabled={!canViewContract}
        >
          Contrato {contract.number}
        </Link>
        <ColoredBadge
          type={contractBadgeColor(contract.firstOverduePaymentDate)}
          title={`${daysInOverdue} desde o primeiro atraso no pagamento`}
          wrapperClassName={style.badgeWrapper}
          className={style.activitiesBadge}
        />
      </Stack>

      {isOpen.activity &&
        mapActivities((activity, key) => {
          if (!activity) return null;

          return (
            <Stack key={activity.id} marginTop={StackMargin.MEDIUM}>
              <ActivityItem
                activity={activity}
                setActivity={setActivity(key)}
                contractFirstOverduePaymentDate={
                  contract.firstOverduePaymentDate
                }
                caseId={caseId}
              />
            </Stack>
          );
        })}
    </>
  );
};

export default ActivitiesPerContract;
