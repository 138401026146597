import { useToggles } from 'hooks';
import React from 'react';
import { Icon, Table } from 'ui';
import cn from 'classnames';
import { AnimatePresence } from 'framer-motion';
import { GroupedState, ReportCaseCity } from 'types';

import orderBy from 'lodash/orderBy';
import CityRow from './CityRow/CityRow';
import styles from './StateRow.module.scss';

type Props = {
  state: GroupedState;
  isSummary?: boolean;
  shouldShowDetails?: boolean;
};

const StateRow = ({
  state,
  isSummary = false,
  shouldShowDetails = false
}: Props) => {
  const { isOpen, toggle } = useToggles<'cityRow'>({ cityRow: false });
  const orderedCities = orderBy(state.cities, ['name'], ['asc']);

  const getTableCellProps = (isSummary?: boolean) => {
    const defaultProps = {
      colSpan: 2,
      className: styles.stateCellSummary,
      dataTestId: 'state-cell-summary'
    };

    if (isSummary) {
      return defaultProps;
    }

    return {
      ...defaultProps,
      className: styles.stateCell,
      dataTestId: 'state-cell',
      onClick: toggle('cityRow')
    };
  };

  return (
    <>
      <Table.Row className={styles.stateRow}>
        <Table.Cell {...getTableCellProps(isSummary)}>
          <div className={styles.stateCellIcon}>
            {!isSummary && (
              <Icon
                className={cn({
                  [styles.stateCellIconClose]: !isOpen.cityRow,
                  [styles.stateCellIconOpen]: isOpen.cityRow
                })}
                name="arrow-right"
                fill="#62667A"
                small
                dataTestId="collapse-state-icon"
              />
            )}
            {`${state.name} (${state.count})`}
          </div>
        </Table.Cell>
      </Table.Row>
      <AnimatePresence initial={false}>
        {(isOpen.cityRow || isSummary) &&
          orderedCities.map(city => (
            <CityRow
              key={city.id}
              city={city as ReportCaseCity}
              isSummary={isSummary}
              shouldShowDetails={shouldShowDetails}
            />
          ))}
      </AnimatePresence>
    </>
  );
};

export default StateRow;
