import React, { ReactElement } from 'react';
import { DataImport } from 'types';
import { Spinner } from 'ui';

import style from './Progress.module.scss';

type Props = {
  dataImport: DataImport;
};

const Progress = ({ dataImport }: Props): ReactElement => {
  const totalImports = dataImport.caseImportsCount;
  const processedImports = dataImport.processedCaseImportsCount;
  const percentage = Math.round((processedImports! / totalImports!) * 100);

  return (
    <div className={style.progress} data-testid="data-import-progress">
      <Spinner color="blue" />
      {`${processedImports}/${totalImports} casos processados... (${percentage}%)`}
    </div>
  );
};

export default Progress;
