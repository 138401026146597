import React from 'react';

import { ApiPagination, ExternalLawsuit, PaginatedResource } from 'types';

import { ExternalLawsuitCard, usePaginationContext } from 'components';
import { Pagination, TwoColumnsMasonry, Stack, StackMargin } from 'ui';

import styles from './ExternalLawsuitList.module.scss';

type Props = {
  data?: PaginatedExternalLawsuits;
  fetchExternalLawsuits?: (page: number, pageSize: number) => Promise<void>;
  hideMonitoringIcon?: boolean;
  hideTrackingIcon?: boolean;
  hideTitleLink?: boolean;
  isLoading?: boolean;
};

type PaginatedExternalLawsuits = {
  data: ExternalLawsuit[];
  pagination: ApiPagination;
};

const ExternalLawsuitList = ({
  data: PaginatedExternalLawsuits,
  fetchExternalLawsuits,
  hideMonitoringIcon,
  hideTrackingIcon,
  hideTitleLink = false,
  isLoading
}: Props) => {
  const { changePagination } = usePaginationContext();

  const MAX_ITEMS_WITHOUT_PAGINATION = 6;

  const columnRender = (data: ExternalLawsuit[]) =>
    data.map(externalLawsuit => (
      <Stack
        marginTop={StackMargin.MEDIUM}
        marginBottom={StackMargin.MEDIUM}
        key={externalLawsuit.id}
      >
        <ExternalLawsuitCard
          externalLawsuit={externalLawsuit}
          isLoading={isLoading}
          hideMonitoringIcon={hideMonitoringIcon}
          hideTrackingIcon={hideTrackingIcon}
          title={
            <ExternalLawsuitCard.TriggerTitle
              lawsuit={externalLawsuit}
              hideTitleLink={hideTitleLink}
            />
          }
        />
      </Stack>
    ));

  if (isLoading) {
    return (
      <div className={styles.twoColumns}>
        <ExternalLawsuitCard isLoading={isLoading} />
        <ExternalLawsuitCard isLoading={isLoading} />
      </div>
    );
  }

  return (
    <>
      <TwoColumnsMasonry
        data={PaginatedExternalLawsuits!.data}
        render={columnRender}
      />

      {PaginatedExternalLawsuits!.pagination.totalEntries >
        MAX_ITEMS_WITHOUT_PAGINATION && (
        <Pagination
          currentPage={PaginatedExternalLawsuits!.pagination.number}
          itemsPerPage={PaginatedExternalLawsuits!.pagination.size}
          onCurrentPageChange={page => {
            fetchExternalLawsuits
              ? fetchExternalLawsuits(
                  page,
                  PaginatedExternalLawsuits!.pagination.size
                )
              : changePagination(PaginatedResource.EXTERNAL_LAWSUITS, {
                  page,
                  pageSize: PaginatedExternalLawsuits!.pagination.size
                });
          }}
          onItemsPerPageChange={pageSize => {
            fetchExternalLawsuits
              ? fetchExternalLawsuits(
                  PaginatedExternalLawsuits!.pagination.number,
                  pageSize
                )
              : changePagination(PaginatedResource.EXTERNAL_LAWSUITS, {
                  page: PaginatedExternalLawsuits!.pagination.number,
                  pageSize
                });
          }}
          totalEntries={PaginatedExternalLawsuits!.pagination.totalEntries}
          totalPages={PaginatedExternalLawsuits!.pagination.totalPages}
        />
      )}
    </>
  );
};

export default ExternalLawsuitList;
