import { indicationOptions } from 'domain/externalLawsuits';
import { useToggles } from 'hooks';
import React, { useState } from 'react';
import {
  ApiPagination,
  ExternalLawsuit,
  ExternalLawsuitIndication,
  LegalReportOrder,
  Report
} from 'types';
import { Button, SelectField, Stack, StackMargin } from 'ui';

import { can, Action } from 'api';
import styles from './ExternalLawsuitHeader.module.scss';
import LegalReportCloneModal from './LegalReportCloneModal/LegalReportCloneModal';
import LegalReportModal from './LegalReportModal';
import { LegalReportStatusMessage } from './LegalReportStatusMessage';

type PaginatedExternalLawsuits = {
  data: ExternalLawsuit[];
  pagination: ApiPagination;
};

type Props = {
  report: Report;
  isLegalReportsEnabled: boolean;
  hasProvidersSucceeded: boolean;
  indicationFilterValue: ExternalLawsuitIndication | undefined;
  paginatedLawsuits: PaginatedExternalLawsuits;
  showStatusMessage?: boolean;
  isLegalReportLoading: boolean;
  handleFilterChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  setIsLegalReportLoading: (loading: boolean) => void;
  setReportData: (data: Report) => void;
};

const ExternalLawsuitHeader = ({
  report,
  hasProvidersSucceeded,
  isLegalReportsEnabled,
  indicationFilterValue,
  handleFilterChange,
  setIsLegalReportLoading,
  setReportData,
  paginatedLawsuits,
  isLegalReportLoading
}: Props) => {
  const { isOpen, toggle } = useToggles({
    legalReportModal: false,
    legalReportCloneModal: false
  });
  const [haveToShowStatusMessage, setHaveToShowStatusMessage] = useState(
    isLegalReportLoading
  );

  const baseValidation =
    report.hasLegalReport && isLegalReportsEnabled && hasProvidersSucceeded;
  const isPaginatedLawsuitsEmpty = paginatedLawsuits.data.length === 0;

  const hasToShowFilter = baseValidation && !isPaginatedLawsuitsEmpty;
  const hasToShowLegalReport = baseValidation && isPaginatedLawsuitsEmpty;
  const hasToShowSearchNewLawsuits = !isPaginatedLawsuitsEmpty;

  const handleCreateOrder = (data: LegalReportOrder) => {
    setIsLegalReportLoading(true);
    setHaveToShowStatusMessage(true);
    setReportData({ ...report, currentLegalReportOrder: data });
  };

  const negotiatorId = report.caseNegotiatorId;

  const canUser = {
    createLegalReport: can(
      Action.CASOS_RELATORIO_PFPJ_ORCAR_PROCESSOS,
      negotiatorId
    ),
    cloneLegalReport: can(
      Action.CASOS_RELATORIO_PFPJ_BUSCAR_NOVOS_PROCESSOS,
      negotiatorId
    )
  };

  return (
    <>
      {hasToShowLegalReport && canUser.createLegalReport && (
        <Stack marginBottom={StackMargin.MEDIUM}>
          <Button
            small
            outline
            highlight
            onClick={toggle('legalReportModal')}
            disabled={isLegalReportLoading}
          >
            Orçar busca por processos
          </Button>
        </Stack>
      )}

      <div className={styles.filters}>
        {hasToShowFilter && (
          <SelectField
            dataTestId="external-lawsuit-indication-filter"
            value={indicationFilterValue}
            id="external-lawsuit-indication"
            name="external-lawsuit-indication"
            onChange={handleFilterChange}
            options={indicationOptions()}
            optional
            optionalLabel="Todos"
            title="Filtros"
          />
        )}

        {hasToShowSearchNewLawsuits && canUser.cloneLegalReport && (
          <Button
            small
            outline
            highlight
            onClick={toggle('legalReportCloneModal')}
            disabled={isLegalReportLoading}
          >
            Buscar novos processos judiciais
          </Button>
        )}
      </div>

      {haveToShowStatusMessage && (
        <LegalReportStatusMessage
          status={report.currentLegalReportOrder?.status}
        />
      )}

      {isOpen.legalReportModal && (
        <LegalReportModal
          caseId={report.caseId}
          isOpen={isOpen.legalReportModal}
          reportId={report.id}
          onClose={toggle('legalReportModal')}
          onCreateOrder={handleCreateOrder}
        />
      )}

      {isOpen.legalReportCloneModal && (
        <LegalReportCloneModal
          caseId={report.caseId}
          isOpen={isOpen.legalReportCloneModal}
          onClose={toggle('legalReportCloneModal')}
          reportId={report.id}
          onCreateOrder={handleCreateOrder}
        />
      )}
    </>
  );
};

export default ExternalLawsuitHeader;
