import React from 'react';
import { useRouteMatch, generatePath } from 'react-router-dom';

import { can, Action } from 'api/permissions';
import { TabContent as TabContentProps } from 'types';

import compact from 'lodash/compact';

import Layout from 'Layouts/Layout';
import { Header, Tabs, TabContent, Title } from 'ui';
import LawFirms from './LawFirms';
import Users from './Users/Users';
import PermissionsManagement from './PermissionsManagement';

type Props = {
  title?: string;
};

const PermissionsTabs = ({ title = 'Permissões' }: Props) => {
  const match: {
    params: { tab: string };
    isExact: boolean;
    path: string;
    url: string;
  } = useRouteMatch();

  const linkTo = (newTab: TabContentProps) => {
    if (!newTab.path) return;

    return generatePath(`${match.path}`, {
      tab: newTab.path
    });
  };

  const tabContents = [
    can(Action.PERMISSOES_GERENCIAMENTO_DE_PERMISSOES_CONSULTAR) && (
      <TabContent
        key="permissions-management"
        title="Gerenciamento de permissões"
        path="permissions-management"
        dataTestId="permissions-management-tab"
      >
        <PermissionsManagement />
      </TabContent>
    ),

    can(Action.PERMISSOES_USUARIOS_INTERNOS_DO_BANCO_CONSULTAR) && (
      <TabContent
        key="intern-users"
        title="Usuários internos do banco"
        path="intern-users"
        dataTestId="intern-users-tab"
      >
        <Users />
      </TabContent>
    ),
    can(Action.PERMISSOES_ESCRITORIOS_DE_ADVOCACIA_CONSULTAR) && (
      <TabContent
        key="law-firms"
        title="Escritórios de advocacia"
        path="law-firms"
        dataTestId="law-firms-tab"
      >
        <LawFirms />
      </TabContent>
    )
  ];

  const allowedTabContents = compact(tabContents);

  return (
    <Layout>
      <React.Fragment>
        <Header>
          <Title>{title}</Title>
        </Header>
        <Tabs linkTab={linkTo} defaultPath={match.params.tab}>
          {allowedTabContents.length === 0 ? <div /> : allowedTabContents}
        </Tabs>
      </React.Fragment>
    </Layout>
  );
};

export default PermissionsTabs;
