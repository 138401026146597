import React, { useState, useEffect } from 'react';
import xor from 'lodash/xor';
import flatten from 'lodash/flatten';
import { CreditContract, Reclaiming } from 'types';
import { ListItem, Link, EmptyState } from 'ui';
import { applyMask } from 'utils/masks';

import './ReclaimingList.scss';

type Props = {
  caseId: string;
  onCheckToggle: (values: string[]) => void;
  contracts?: CreditContract[];
  selectedReclaimingIds: string[];
  showContractLink?: boolean;
};

const ReclaimingList = ({
  caseId,
  onCheckToggle,
  contracts,
  selectedReclaimingIds,
  showContractLink = true
}: Props) => {
  const [reclaimings, setReclaimings] = useState<Reclaiming[]>([]);

  useEffect(() => {
    setReclaimings(flatten(contracts?.map(contract => contract.reclaimings)));
  }, [contracts]);

  const handleToggle = (reclaimingId: string) => () => {
    const toggledReclaimingIds = xor(selectedReclaimingIds, [reclaimingId]);

    onCheckToggle(toggledReclaimingIds);
  };

  const fetchContractNumber = (contractId: string) => {
    const contract = contracts?.find(contract => contract.id === contractId);
    return contract?.number;
  };

  return (
    <div className="lawsuits-reclaimings-list">
      <span className="title">Vincular recuperação</span>
      {contracts?.length === 0 && (
        <EmptyState text="Selecione um contrato para recuperação" />
      )}
      {contracts?.length !== 0 && reclaimings?.length === 0 && (
        <EmptyState text="Contratos selecionados sem recuperação" />
      )}

      {reclaimings.map(reclaiming => (
        <ListItem
          id={reclaiming.id}
          key={`reclaiming-item-${reclaiming.id}`}
          onCheckboxClick={handleToggle(reclaiming.id)}
          checked={selectedReclaimingIds.includes(reclaiming.id)}
          title={`Recuperação ${applyMask(
            'currency',
            reclaiming.totalAmount.toString()
          )}`}
          text={`Contrato ${fetchContractNumber(reclaiming.contractId!)}`}
          actions={
            showContractLink ? (
              <Link
                highlight
                target="_blank"
                href={`/cases/${caseId}/debtors/contract/${reclaiming.contractId}`}
                iconName="open-in-browser"
              >
                Ver contrato
              </Link>
            ) : (
              <></>
            )
          }
        />
      ))}
    </div>
  );
};

export default ReclaimingList;
