import { ExternalLawsuit } from 'types';
import { Asset, AssetAttributes } from './assets';
import { Guarantee } from './guarantee';
import { CreditContract } from './creditContract';
import { FileUpload } from './file';
import { InternalLawsuit } from './internalLawsuit';

export enum ExecutionEventType {
  INDICADO_PARA_PENHORA = 'indicado_para_penhora',
  TERMO_DE_PENHORA = 'termo_de_penhora',
  AVALIACAO = 'avaliacao',
  IMPUGNACAO = 'impugnacao',
  POSSIBILIDADE_DE_RECURSO = 'possibilidade_de_recurso',
  PRIMEIRO_LEILAO = 'primeiro_leilao',
  SEGUNDO_LEILAO = 'segundo_leilao',
  EXECUCAO = 'execucao',
  DECISAO_JUDICIAL_DESFAVORAVEL = 'decisao_judicial_desfavoravel',
  ARREMATACAO_OU_ADJUDICACAO = 'arrematacao_ou_adjudicacao'
}

export enum ExecutionType {
  ASSET = 'asset',
  GUARANTEE = 'guarantee',
  EXTERNAL_LAWSUIT = 'external_lawsuit'
}

export type ExecutionEvent = {
  id: string;
  date: string;
  type: ExecutionEventType;
};

type ExecutionBase = {
  degree: number | null;
  type: ExecutionType;
};

export type ExecutionRequestAttributes = {
  caseId: string;
  lawsuitId: string;
  executionId: string;
};

export type ExecutionAttributes = ExecutionBase & {
  executedAsset: AssetAttributes;
};

export type Execution = ExecutionBase & {
  id: string;
  executedContract: CreditContract | null;
  executedAsset: Asset | null;
  executedGuarantee: Guarantee | null;
  executedExternalLawsuit: ExternalLawsuit | null;
  events: ExecutionEvent[];
  internalLawsuit: InternalLawsuit;
};

export type LawsuitExecution = {
  executions: Execution[];
  files: FileUpload[];
  description: string | null;
};

export type ExecutionRelatedAttributes = ExecutionBase & {
  executedAssetId: string | null;
  executedGuaranteeId: string | null;
  executedContractId: string | null;
  executedExternalLawsuitId: string | null;
};

export type ExternalLawsuitExecutionAttributes = {
  degree: number | null;
  type: ExecutionType;
  executedExternalLawsuit: {
    number: string | null;
    searchId: string | null;
  };
};
