import * as Debtors from 'domain/debtors';
import { entityDocument, entityName } from 'domain/entity';
import React, { useState, useEffect } from 'react';
import { FormikProps, getIn } from 'formik';

import { CreditContractFormAttributes, InvoiceFormAttributes } from 'types';
import { useQuery } from 'hooks';
import { fetchDebtors } from 'api';
import { Debtor } from 'types';

import { SelectField, openErrorToast, FormContainer, InfoArea } from 'ui';

import style from './DebtorInfo.module.scss';

type Props = {
  caseId: string;
  formik:
    | FormikProps<InvoiceFormAttributes>
    | FormikProps<CreditContractFormAttributes>;
  isLoading?: boolean;
  isDisabled?: boolean;
};

const DebtorInfo = ({
  formik,
  caseId,
  isLoading: isLoadingContract,
  isDisabled
}: Props) => {
  const { data: debtors, error: debtorsError, isLoading } = useQuery<
    Debtor[],
    string
  >(fetchDebtors, caseId);
  const [selectedDebtor, setSelectedDebtor] = useState<Debtor | null>(null);
  const hasNoDebtors = !debtors?.length;
  const debtorsOptions =
    debtors?.map(debtor => ({
      value: debtor.id,
      label: entityName(debtor.entity)
    })) ?? [];

  if (debtorsError) {
    openErrorToast('Falha ao listar devedores');
  }

  useEffect(() => {
    if (debtors) {
      const selectedDebtor = debtors.find(
        debtor => formik.values.debtorId === debtor.id
      );

      setSelectedDebtor(selectedDebtor!);
    }
  }, [debtors, formik.values.debtorId]);

  const handleDebtorChange = (debtorId: string) => {
    const selectedDebtor = debtors!.find(debtor => debtorId === debtor.id);

    setSelectedDebtor(selectedDebtor!);

    formik.setFieldValue('debtorId', debtorId);
  };

  return (
    <div>
      <h2 className={style.title}>Devedor</h2>
      <FormContainer className="col-3">
        <SelectField
          disabled={
            isDisabled || isLoading || isLoadingContract || hasNoDebtors
          }
          error={formik.touched.debtorId && getIn(formik.errors, 'debtorId')}
          id="debtor"
          isLoading={isLoading || isLoadingContract}
          name="debtorId"
          onBlur={formik.handleBlur}
          onChange={event => handleDebtorChange(event.target.value)}
          options={debtorsOptions}
          title="Devedor"
          value={formik.values.debtorId}
        />

        <InfoArea
          label={selectedDebtor?.entity.company ? 'CNPJ' : 'CPF'}
          isLoading={isLoading || isLoadingContract}
          value={selectedDebtor ? entityDocument(selectedDebtor.entity) : ''}
        />

        <InfoArea
          isLoading={isLoading || isLoadingContract}
          label="Situação judicial"
          value={
            selectedDebtor
              ? Debtors.judicialSituationLabel(selectedDebtor.judicialSituation)
              : ''
          }
        />
      </FormContainer>
    </div>
  );
};

export default DebtorInfo;
