import {
  involvedPartyValidationSchema,
  lawyersValidationSchema
} from 'domain/lawsuits';
import React, { RefObject } from 'react';
import * as Yup from 'yup';

import { FieldArray, Formik, FormikProps } from 'formik';
import {
  ChildLawsuitAttributes,
  OnSubmit,
  StatusType,
  ChildLawsuit
} from 'types';

import LawsuitFields from '../../Form/LawsuitFields';
import Lawyers from '../../Form/Lawyers';
import InvolvedParties from '../../Form/InvolvedParties';

type Props = {
  caseId: string;
  onSubmit: OnSubmit<ChildLawsuitAttributes>;
  formRef: RefObject<FormikProps<ChildLawsuitAttributes>>;
  data?: ChildLawsuit;
  isWaitingResponse: boolean;
  setWaitingResponse: (isWaitingResponse: boolean) => void;
  isDisabledOnEdit?: boolean;
};

const initialValues: ChildLawsuitAttributes = {
  action: '',
  cause: undefined,
  causeInitialDate: '',
  courtId: '',
  externalNumber: undefined,
  number: '',
  status: '',
  type: '',
  parties: [],
  lawyers: []
};

const validationSchema = Yup.object().shape({
  status: Yup.string().required('Campo obrigatório'),
  number: Yup.string().when('status', {
    is: StatusType.IN_DISTRIBUTION,
    then: Yup.string()
      .notRequired()
      .nullable(),
    otherwise: Yup.string()
      .required('Campo obrigatório')
      .nullable()
  }),
  externalNumber: Yup.string()
    .notRequired()
    .nullable(),
  causeInitialDate: Yup.date()
    .max(new Date(), 'A data deve ser igual ou anterior a hoje')
    .notRequired()
    .nullable(),
  courtId: Yup.string().notRequired(),
  action: Yup.string().notRequired(),
  type: Yup.string()
    .notRequired()
    .nullable(),
  parties: Yup.array().of(involvedPartyValidationSchema),
  lawyers: lawyersValidationSchema
});

const formatInitialValues = (
  fetchedData: ChildLawsuit | undefined
): ChildLawsuitAttributes => {
  if (!fetchedData) return initialValues;

  return {
    ...fetchedData,
    courtId: fetchedData?.court?.id
  };
};

const Form = ({
  onSubmit,
  caseId,
  formRef,
  data,
  setWaitingResponse,
  isWaitingResponse,
  isDisabledOnEdit = false
}: Props) => {
  return (
    <Formik<ChildLawsuitAttributes>
      innerRef={formRef}
      initialValues={formatInitialValues(data)}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {form => (
        <>
          <LawsuitFields
            caseId={caseId}
            isWaitingResponse={isWaitingResponse}
            setWaitingResponse={setWaitingResponse}
            form={form}
            isDisabledOnEdit={isDisabledOnEdit}
          />

          <FieldArray name="lawyers">
            {formikArrayHelpers => (
              <Lawyers
                disabled={isWaitingResponse}
                formikArrayHelpers={formikArrayHelpers}
                formik={form}
              />
            )}
          </FieldArray>

          <FieldArray name="parties">
            {formikArrayHelpers => (
              <InvolvedParties
                caseId={caseId}
                disabled={isWaitingResponse}
                arrayHelpers={formikArrayHelpers}
                form={form}
              />
            )}
          </FieldArray>
        </>
      )}
    </Formik>
  );
};

export default Form;
