import React, { ReactChild } from 'react';
import cn from 'classnames';
import style from './AccordionTable.module.scss';

import RowTable from './Row';

export enum Alignment {
  LEFT = 'left',
  CENTER = 'center',
  JUSTIFY = 'justify',
  RIGHT = 'right'
}

export type Row = {
  cells: ReactChild[];
  childs: Row[];
  onClick?: () => void;
  tooltipMessage?: string;
};

export type Header = {
  cell: ReactChild;
  align?: Alignment;
};

type Props = {
  headers: Header[];
  rows: Row[];
  depth: number;
};

const AccordionTable = ({ rows, headers, depth }: Props) => {
  return (
    <table className={style.accordionTable}>
      {!!headers.length && (
        <thead>
          <tr>
            {headers.map(
              ({ cell, align: alignment = Alignment.LEFT }, index) => (
                <th
                  key={index}
                  colSpan={index === 0 ? depth : 0}
                  className={cn(style.tableHeader, style[alignment])}
                >
                  {cell}
                </th>
              )
            )}
          </tr>
        </thead>
      )}
      {!!rows.length && (
        <tbody className={style.tableBody}>
          {rows.map((row, index) => (
            <RowTable key={index} row={row} depth={depth} headers={headers} />
          ))}
        </tbody>
      )}
    </table>
  );
};

export default AccordionTable;
