import { eventTypeLabel } from 'domain/executions';
import React from 'react';
import flatMap from 'lodash/flatMap';
import last from 'lodash/last';

import ColoredBadge, { BadgeColor } from 'components/ColoredBadge/ColoredBadge';

import { Asset, AssetAutoStatus, AssetManualStatus } from 'types';

import style from './AssetStatusBadges.module.scss';

export enum StatusBadgeType {
  NEUTRAL = 'neutral'
}

type Props = {
  asset: Asset;
};

const getExecutionsEventsLabeled = (asset: Asset) => {
  const { executions } = asset;
  const events = flatMap(executions, ({ events }) => last(events));

  return events.map(event => (event?.type ? eventTypeLabel(event.type) : ''));
};

const metadataFromType = (
  type: StatusBadgeType | AssetManualStatus | AssetAutoStatus,
  event: string | undefined = ''
) =>
  ({
    [AssetAutoStatus.EXECUTED]: {
      type: BadgeColor.PURPLE,
      title: 'Penhorado',
      event
    },
    [AssetAutoStatus.GUARANTEED]: {
      type: BadgeColor.AQUAMARINE,
      title: 'Em garantia',
      event
    },
    [StatusBadgeType.NEUTRAL]: {
      type: BadgeColor.DEFAULT,
      title: 'Sem classificação',
      event
    },
    [AssetManualStatus.AVAILABLE]: {
      type: BadgeColor.GREEN,
      title: 'Livre',
      event
    },
    [AssetManualStatus.GUARANTEED_BY_THIRD_PARTY]: {
      type: BadgeColor.ORANGE,
      title: 'Garantia de terceiros',
      event
    },
    [AssetManualStatus.EXECUTED_BY_THIRD_PARTY]: {
      type: BadgeColor.PINK,
      title: 'Penhorado por terceiros',
      event
    },
    [AssetManualStatus.UNAVAILABLE]: {
      type: BadgeColor.RED,
      title: 'Indisponibilidade fiscal',
      event
    },
    [AssetAutoStatus.RECLAIMED]: {
      type: BadgeColor.BLUE,
      title: 'Recuperado',
      event
    }
  }[type]);

const AssetStatusBadges = ({ asset }: Props) => {
  if (!asset.manualStatus.length && !asset.autoStatus?.length) {
    const { type, title } = metadataFromType(StatusBadgeType.NEUTRAL);

    return <ColoredBadge type={type} title={title} />;
  }

  const executionEvents = getExecutionsEventsLabeled(asset);
  const autoStatusWithoutExecutions = asset.autoStatus?.filter(
    status => status !== AssetAutoStatus.EXECUTED
  );

  const executedTags = executionEvents.map(event =>
    metadataFromType(AssetAutoStatus.EXECUTED, event)
  );
  const otherStatusTags = [
    ...autoStatusWithoutExecutions,
    ...asset.manualStatus
  ].map(status => metadataFromType(status));

  return (
    <div className={style.badgesWrapper}>
      {[...executedTags, ...otherStatusTags].map(
        ({ type, title, event }, idx) => (
          <ColoredBadge
            key={`${idx}-${title}`}
            type={type}
            title={title}
            subtitle={event}
          />
        )
      )}
    </div>
  );
};

export default AssetStatusBadges;
