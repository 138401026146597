import cond from 'lodash/cond';
import { DateTime } from 'luxon';

import { Activity } from 'types';
import { BadgeColor } from 'components/ColoredBadge/ColoredBadge';
import { daysSince } from 'utils';

export const fromISOToActivityDate = (date: string) =>
  DateTime.fromISO(date)
    .setLocale('pt-BR')
    .toFormat('dd LLLL yyyy');

export const activityBadgeColor = (
  activity: Activity,
  firstOverduePaymentDate: string
) => {
  const { daysAfterOverdueDate, completed } = activity;

  if (completed) return BadgeColor.DEFAULT;

  const currentOverdueDays = daysSince(
    firstOverduePaymentDate,
    DateTime.local().toISO()
  );

  return cond([
    [(days: number) => days >= daysAfterOverdueDate, () => BadgeColor.RED],
    [() => true, () => BadgeColor.DEFAULT]
  ])(currentOverdueDays);
};
