import { executionDegreeOptions } from 'domain/executions';
import { getIn, useFormik } from 'formik';
import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import isEqual from 'lodash/isEqual';

import {
  Execution,
  ExecutionType,
  ExternalLawsuitSocketResponse,
  ExternalLawsuitExecutionAttributes,
  OnSubmit,
  QueryStatus,
  ExternalLawsuitContent,
  ApiError
} from 'types';
import {
  Button,
  FormContainer,
  InputField,
  openErrorToast,
  openSuccessToast,
  SelectField,
  Stack,
  StackMargin
} from 'ui';

import { createExecution, createExternalLawsuitSearch } from 'api';
import { useLiveSearchExternalLawsuits } from 'hooks';

import styles from './LawsuitForm.module.scss';

import LawsuitFormContent from './LawsuitFormContent/LawsuitFormContent';

const validationSchema = Yup.object().shape({
  degree: Yup.string()
    .required('Campo obrigatório')
    .nullable()
});

const initialValues: ExternalLawsuitExecutionAttributes = {
  degree: null,
  type: ExecutionType.EXTERNAL_LAWSUIT,
  executedExternalLawsuit: {
    number: null,
    searchId: null
  }
};

const nulledExternalLawsuitContent: ExternalLawsuitContent = {
  basicInformation: [
    {
      class: null,
      court: null,
      degree: null,
      distributionDate: null,
      judgeName: null,
      judgingBody: null,
      lawSegment: null,
      originUnit: null,
      status: null,
      value: null
    }
  ],
  parties: [],
  relatedLawsuits: [],
  subjects: []
};

type Props = {
  caseId: string;
  internalLawsuitId: string;
  onCreate: (event: Execution) => void;
};

const LawsuitForm = ({ caseId, internalLawsuitId, onCreate }: Props) => {
  const [
    executedExternalLawsuitNumber,
    setExecutedExternalLawsuitNumber
  ] = useState('');
  const searchIdRef = useRef<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasSearch, setHasSearch] = useState(false);
  const [hasWebsocketFailed, setHasWebsocketFailed] = useState(false);
  const [
    externalLawsuitContent,
    setExternalLawsuitsContent
  ] = useState<ExternalLawsuitContent | null>(null);

  const formatError = (errors?: ApiError) => {
    if (!errors) return {};

    return {
      ...errors,
      entity: { individual: errors?.individual, company: errors?.company }
    };
  };

  const onSubmit: OnSubmit<ExternalLawsuitExecutionAttributes> = async (
    values,
    formikHelpers
  ) => {
    values = {
      ...values,
      executedExternalLawsuit: {
        number: executedExternalLawsuitNumber,
        searchId: searchIdRef.current
      }
    };

    try {
      const response = await createExecution(caseId, internalLawsuitId, values);

      onCreate(response.data);

      openSuccessToast('Penhora salva com sucesso!');
    } catch (error) {
      formikHelpers.setErrors(formatError(error.errors));
      openErrorToast('Houve um erro ao salvar a Penhora');
    }
  };

  const onLawsuitNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setExecutedExternalLawsuitNumber(event.currentTarget.value);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  });

  const onSearchResponseFromSocket = async (
    response: ExternalLawsuitSocketResponse
  ) => {
    setIsLoading(false);

    if (response.status === QueryStatus.SUCCEEDED) {
      if (isEqual(response.content, nulledExternalLawsuitContent)) {
        setExternalLawsuitsContent(null);
      }

      setExternalLawsuitsContent(response.content);
    } else if (response.status === QueryStatus.FAILED) {
      setHasWebsocketFailed(true);
    }
  };

  useLiveSearchExternalLawsuits(onSearchResponseFromSocket, {
    caseId,
    searchId: searchIdRef
  });

  async function handleCreateLawsuitSearch() {
    try {
      setHasSearch(true);
      setIsLoading(true);

      const response = await createExternalLawsuitSearch(
        caseId,
        executedExternalLawsuitNumber
      );

      searchIdRef.current = response.data.id;
    } catch (error) {
      setHasWebsocketFailed(true);
    }
  }

  const executedExternalLawsuitNumberErrorMessage =
    (getIn(formik.errors, 'executedExternalLawsuitId') ||
      getIn(formik.errors, 'executedExternalLawsuit.number')) &&
    'Já está em uso';

  return (
    <>
      <form>
        <FormContainer className="col-2">
          <div className={styles.lawsuitNumberInputWrapper}>
            <InputField
              id="lawsuitNumber"
              name="lawsuitNumber"
              type="text"
              value={executedExternalLawsuitNumber}
              title="Número do processo"
              dataTestId="executedExternalLawsuitNumberInput"
              onChange={onLawsuitNumberChange}
              onBlur={formik.handleBlur}
              className={styles.lawsuitNumberInput}
              error={executedExternalLawsuitNumberErrorMessage}
            />
            <div className={styles.searchButtonWrapper}>
              <Button
                small
                outline
                highlight
                onClick={handleCreateLawsuitSearch}
                disabled={isLoading || !executedExternalLawsuitNumber}
              >
                Buscar
              </Button>
            </div>
          </div>

          <SelectField
            id="degree"
            name="degree"
            dataTestId="lawsuit-degree"
            value={formik.values.degree?.toString()}
            title="Grau da penhora"
            error={
              getIn(formik.touched, 'degree') && getIn(formik.errors, 'degree')
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            options={executionDegreeOptions()}
            optional
          />
        </FormContainer>

        <LawsuitFormContent
          externalLawsuitContent={externalLawsuitContent!}
          isLoading={isLoading}
          isHidden={!hasSearch}
          hasWebsocketFailed={hasWebsocketFailed}
        />

        <Stack marginTop={StackMargin.MEDIUM}>
          <Button
            onClick={formik.handleSubmit}
            highlight
            centered
            type="submit"
            disabled={!formik.isValid || !externalLawsuitContent}
          >
            Adicionar
          </Button>
        </Stack>
      </form>
    </>
  );
};

export default LawsuitForm;
