import {
  DebtorParams,
  Debtor,
  DebtorGroupedWithContractAndInvoice
} from 'types';
import Client from './client';

export function deleteDebtor(caseId: string, debtorId: string) {
  return Client.delete(`cases/${caseId}/debtors/${debtorId}`);
}

export type FetchDebtorArgs = { caseId: string; id: string };

export function fetchDebtor({ caseId, id }: FetchDebtorArgs) {
  return Client.get<Debtor>(`cases/${caseId}/debtors/${id}`);
}

export function fetchDebtors(caseId: string) {
  return Client.get<Debtor[]>(`cases/${caseId}/debtors`);
}

function createDebtor(caseId: string, debtorData: DebtorParams) {
  return Client.post<DebtorParams, Debtor>(
    `cases/${caseId}/debtors`,
    debtorData
  );
}

function updateDebtor(debtorData: DebtorParams, caseId: string, id: string) {
  return Client.put<DebtorParams, Debtor>(
    `cases/${caseId}/debtors/${id}`,
    debtorData
  );
}

export function fetchGroupedWithContractAndInvoices({
  caseId
}: Pick<FetchDebtorArgs, 'caseId'>) {
  return Client.get<DebtorGroupedWithContractAndInvoice[]>(
    `cases/${caseId}/debtors/with_contracts_invoices`
  );
}

export function fetchDebtorsWithJudicialRecovery({
  caseId
}: Pick<FetchDebtorArgs, 'caseId'>) {
  return Client.get<Debtor[]>(`cases/${caseId}/debtors/with_judicial_recovery`);
}

export function submitDebtor(
  values: DebtorParams,
  caseId: string,
  debtor?: Debtor | undefined
) {
  if (debtor) {
    return updateDebtor(values, caseId, debtor!.id);
  }

  return createDebtor(caseId, values);
}
