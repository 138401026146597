import React, { useState } from 'react';
import sortBy from 'lodash/sortBy';

import { Button, Modal } from 'ui';

import { User } from 'types';
import UserList from '../../Users/UserList';
import Form from './Form';

type Props = {
  lawFirmId: string;
  users: Array<User>;
};

const ExternalLawyer = ({ lawFirmId, users }: Props) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [userData, setUsers] = useState(users);
  const [userBeingEdited, setUserBeingEdited] = useState<User>();

  const closeModal = () => {
    setModalOpen(false);
    setUserBeingEdited(undefined);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const handleCreateUser = (user: User) => {
    setUsers(sortBy([...userData, user], user => user.name.toLowerCase()));
    closeModal();
  };

  const handleEditClick = (user: User) => {
    setUserBeingEdited(user);
    openModal();
  };

  const handleUpdateUser = (updatedUser: User) => {
    const updatedUsers = users.map(user => {
      if (user.id === updatedUser.id) return updatedUser;
      return user;
    });

    setUsers(sortBy(updatedUsers, user => user.name.toLowerCase()));
    closeModal();
  };

  const handleSave = (user: User) => {
    if (userBeingEdited) {
      handleUpdateUser(user);
    } else {
      handleCreateUser(user);
    }
  };

  return (
    <React.Fragment>
      <Button
        onClick={openModal}
        dataTestId="invite-user-button"
        icon="add"
        outline
        block
        disabled
      >
        Convidar usuário
      </Button>

      <UserList
        usersData={userData}
        onEditClick={handleEditClick}
        isEditDisabled
        isInvitationDisabled
        isExternalLawyerList
      />

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={userBeingEdited ? 'Editar usuário' : 'Convidar usuário'}
      >
        <Form
          lawFirmId={lawFirmId}
          handleSave={handleSave}
          formData={userBeingEdited}
        />
      </Modal>
    </React.Fragment>
  );
};

export default ExternalLawyer;
