import { handleDateFieldChange } from 'domain/forms';
import { statusTypeOptions } from 'domain/lawsuits';
import React, { useRef } from 'react';
import { FormikProps, getIn } from 'formik';
import { DateTime } from 'luxon';

import { createLawsuitSearch, fetchLawsuitSearch } from 'api';
import {
  CourtFormAttributes,
  InternalLawsuitAttributes,
  ChildLawsuitAttributes,
  InvolvedParty,
  LawsuitAutocomplete,
  LawsuitAutocompleteContent,
  Lawyer,
  QueryStatus,
  Feature
} from 'types';
import { useFeatureFlag, useLiveSearchLawsuits } from 'hooks';

import { CourtField } from 'components';
import {
  Button,
  DatePickerField,
  FormContainer,
  InputField,
  openErrorToast,
  SelectField,
  Toggle
} from 'ui';

import {
  normalizeCourts,
  normalizeLawyers,
  normalizeParties
} from '../normalize';

import style from './LawsuitFields.module.scss';

type Props = {
  caseId: string;
  form:
    | FormikProps<ChildLawsuitAttributes>
    | FormikProps<InternalLawsuitAttributes>;
  isWaitingResponse: boolean;
  setWaitingResponse: (isWaitingResponse: boolean) => void;
  isDisabledOnEdit?: boolean;
};

type Option = { value: string; label: string };

function idFor(name: string): string {
  return `lawsuit-form-${name}`;
}

const causesOptions: Option[] = [
  { value: 'against', label: 'Contra' },
  { value: 'in_favor', label: 'Pró' }
];

const LawsuitFields = ({
  caseId,
  form,
  isWaitingResponse,
  setWaitingResponse,
  isDisabledOnEdit = false
}: Props) => {
  const timeoutRef = useRef<number>();
  const searchIdRef = useRef<string>('');
  const [isAutocompleteEnabled] = useFeatureFlag(Feature.LAWSUITS_AUTOCOMPLETE);
  const isStatusDistribution = form.values.status === 'in_distribution';

  const setFormikState = async (
    content: LawsuitAutocompleteContent
  ): Promise<void> => {
    const { parties, lawyers, court, ...rest } = content;
    const normalizedPartise: InvolvedParty[] = normalizeParties(parties);
    const normalizedLawyers: Lawyer[] = normalizeLawyers(lawyers);
    const normalizedCourt: CourtFormAttributes = normalizeCourts(court);

    const values = {
      ...form.values,
      ...rest,
      court: normalizedCourt,
      courtId: court.id || '',
      lawyers: normalizedLawyers,
      parties: normalizedPartise,
      reclaimingIds: [],
      contractIds: [],
      fileUploadIds: []
    };

    form.setValues(values);

    setWaitingResponse(false);
  };

  const onAutocomplete = async (response: LawsuitAutocomplete) => {
    if (!isWaitingResponse) return;

    if (response.status === QueryStatus.SUCCEEDED) {
      await setFormikState(response.content!);
    } else if (response.status === QueryStatus.FAILED) {
      openErrorToast('Não foi possível buscar o processo');
    } else if (response.status === QueryStatus.NOT_FOUND) {
      openErrorToast(
        'Não encontramos resultados para o número do processo informado'
      );
    }

    setWaitingResponse(false);
    window.clearTimeout(timeoutRef.current);
  };

  const [isConnected] = useLiveSearchLawsuits(onAutocomplete, {
    caseId,
    searchId: searchIdRef
  });

  async function fetchFallbackTimeout({
    searchId,
    caseId
  }: {
    searchId: string;
    caseId: string;
  }) {
    try {
      const response = await fetchLawsuitSearch({
        searchId,
        caseId
      });
      const { status, content } = response.data;

      if (status === QueryStatus.SUCCEEDED) {
        content && setFormikState(content);
      } else if (status === QueryStatus.FAILED) {
        openErrorToast('Não foi possível buscar o processo');
      } else if (status === QueryStatus.NOT_FOUND) {
        openErrorToast(
          'Não encontramos resultados para o número do processo informado'
        );
      }
    } catch (error) {
      openErrorToast('Não foi possível buscar o processo');
    }
  }

  function runFallbackTimer() {
    const TIMEOUT_TIMER = 15000;
    const timeout = window.setTimeout(() => {
      fetchFallbackTimeout({ caseId, searchId: searchIdRef.current });
      setWaitingResponse(false);
    }, TIMEOUT_TIMER);

    timeoutRef.current = timeout;
  }

  async function handleCreateLawsuitSearch(number: string) {
    try {
      const response = await createLawsuitSearch(caseId, number);
      searchIdRef.current = response.data.id;

      setWaitingResponse(true);
      runFallbackTimer();
    } catch (error) {
      setWaitingResponse(false);
      openErrorToast('Erro ao buscar o processo.');
    }
  }

  return (
    <>
      <FormContainer className="col-2">
        <div className={style.search}>
          <InputField
            id={idFor('number')}
            name="number"
            onBlur={form.handleBlur}
            onChange={form.handleChange}
            title="Número do processo"
            type="text"
            value={form.values.number}
            disabled={
              isStatusDistribution || isWaitingResponse || isDisabledOnEdit
            }
            placeholder={isStatusDistribution ? 'Indisponível' : undefined}
            error={form.touched.number && getIn(form.errors, 'number')}
            className={style.searchInput}
          />
          {isAutocompleteEnabled && isConnected && (
            <Button
              dataTestId="lawsuitSearchButton"
              small
              highlight
              onClick={() =>
                handleCreateLawsuitSearch(form.values.number ?? '')
              }
              disabled={isStatusDistribution || isDisabledOnEdit}
              loading={isWaitingResponse}
              className={style.searchButton}
            >
              Buscar
            </Button>
          )}
        </div>
        <SelectField
          name="status"
          id={idFor('status')}
          title="Status do processo"
          value={form.values.status}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          error={form.touched.status && getIn(form.errors, 'status')}
          disabled={isWaitingResponse || isDisabledOnEdit}
          options={statusTypeOptions()}
        />
      </FormContainer>
      <FormContainer className="col-2">
        <InputField
          dataTestId="input-external-number"
          id={idFor('external-number')}
          name="externalNumber"
          onBlur={form.handleBlur}
          onChange={form.handleChange}
          title="Nº do processo no sistema externo (opcional)"
          type="text"
          value={form.values.externalNumber}
          disabled={isStatusDistribution || isDisabledOnEdit}
          placeholder={isStatusDistribution ? 'Indisponível' : undefined}
          error={
            form.touched.externalNumber && getIn(form.errors, 'externalNumber')
          }
        />

        <Toggle
          title="Causa (opcional)"
          options={causesOptions}
          onChange={event => form.setFieldValue('cause', event.target.value)}
          checked={form.values.cause}
          name="cause"
          disabled={isDisabledOnEdit}
        />

        <InputField
          dataTestId="input-initial-value"
          id={idFor('initial-value')}
          name="causeInitialValue"
          onBlur={form.handleBlur}
          onChange={form.handleChange}
          title="Valor da causa (opcional)"
          type="currency"
          value={form.values.causeInitialValue?.toString()}
          disabled={isDisabledOnEdit}
          error={
            getIn(form.touched, 'causeInitialValue') &&
            getIn(form.errors, 'causeInitialValue')
          }
        />

        <DatePickerField
          id={idFor('cause-initial-date')}
          name="causeInitialDate"
          title="Data de início da causa (opcional)"
          placeholder="Selecione..."
          value={form.values.causeInitialDate}
          disabled={isDisabledOnEdit}
          onChange={handleDateFieldChange(
            form.setFieldValue,
            form.setFieldTouched,
            'causeInitialDate'
          )}
          onBlur={form.handleBlur}
          max={DateTime.local().toISO()}
          error={
            form.touched.causeInitialDate &&
            getIn(form.errors, 'causeInitialDate')
          }
        />

        <CourtField
          onChange={value => form.setFieldValue('courtId', value)}
          onBlur={() => form.setFieldTouched('courtId', true)}
          error={form.touched.courtId && getIn(form.errors, 'courtId')}
          defaultValue={form.values.court}
          isDisabledOnEdit={isDisabledOnEdit}
        />

        <InputField
          id={idFor('action')}
          name="action"
          type="text"
          title="Ação (opcional)"
          value={form.values.action}
          disabled={isDisabledOnEdit}
          placeholder="Digite aqui..."
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          error={form.touched.action && getIn(form.errors, 'action')}
        />

        <InputField
          id={idFor('type')}
          name="type"
          type="text"
          title="Tipo (opcional)"
          value={form.values.type}
          disabled={isDisabledOnEdit}
          placeholder="Digite aqui..."
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          error={form.touched.type && getIn(form.errors, 'type')}
        />
      </FormContainer>
      <FormContainer>
        <InputField
          id={idFor('description')}
          name="description"
          type="text"
          title="Descrição (opcional)"
          value={form.values.description}
          disabled={isDisabledOnEdit}
          placeholder="Digite aqui..."
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          error={
            getIn(form.touched, 'description') &&
            getIn(form.errors, 'description')
          }
          limit={140}
        />
      </FormContainer>
    </>
  );
};

export default LawsuitFields;
