import React, { useState, ReactNode, useEffect } from 'react';
import cn from 'classnames';

import { SubTitle, Trigger as Toggle } from 'ui';

import style from './Accordion.module.scss';

type Props = {
  children: ReactNode;
  badgeContent?: string | number | ReactNode;
  tooltipText?: string;
  className?: string;
  headerActions?: ReactNode;
  headerInfo?: {
    label: string | ReactNode;
    value: string | ReactNode;
    dataTestId?: string;
  };
  iconName?: string;
  isClosed?: boolean;
  title: string;
  titleClassName?: string;
  titleExtra?: JSX.Element;
  id?: string;
  renderTitle?: boolean;
  renderToggle?: boolean;
  renderHeader?: boolean;
  toggleChangeCallback?: (isClosed: boolean) => void;
};

const Accordion = ({
  badgeContent,
  tooltipText,
  children,
  className,
  headerActions,
  headerInfo,
  iconName = '',
  isClosed = false,
  title,
  titleClassName,
  titleExtra,
  id,
  renderToggle = true,
  renderTitle = true,
  renderHeader = true,
  toggleChangeCallback
}: Props) => {
  const [isAccordionClosed, toggleAccordion] = useState<boolean>(isClosed);

  useEffect(() => {
    toggleAccordion(isClosed);
  }, [isClosed, toggleAccordion]);

  const containerClassName = cn(className, 'accordion-container');

  return (
    <div
      className={containerClassName}
      data-testid={`accordion-${title}`}
      id={id}
    >
      {renderHeader && (
        <div className={style.accordionHeader}>
          {renderToggle && (
            <Toggle
              onClick={() => {
                toggleAccordion(!isAccordionClosed);
                toggleChangeCallback &&
                  toggleChangeCallback(!isAccordionClosed);
              }}
              isClosed={isAccordionClosed}
              label={title}
            />
          )}
          {renderTitle && (
            <SubTitle
              badgeText={badgeContent}
              badgeTestId="accordion-badge"
              tooltipText={tooltipText}
              className={titleClassName ?? style.title}
              additionalInfo={headerInfo}
              actions={headerActions}
              iconName={iconName}
            >
              {title}
              {titleExtra}
            </SubTitle>
          )}
        </div>
      )}

      <div
        className="body"
        data-testid={`accordion-${title}-body`}
        hidden={isAccordionClosed}
      >
        {!isAccordionClosed && children}
      </div>
    </div>
  );
};

export default Accordion;
