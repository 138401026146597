import React, { useContext } from 'react';

import { generatePath, useParams } from 'react-router-dom';
import compact from 'lodash/compact';

import { Tabs, TabContent } from 'ui';
import { CaseContext } from 'components';
import { Action, can } from 'api';
import { TabContent as TabContentProps } from 'types/tabContent';
import { CaseType } from 'types';
import TimeLine from './TimeLine';
import Activities from './Activities';

import style from './Events.module.scss';

const Events = () => {
  const { caseId, eventTab } = useParams<{
    eventTab: string;
    caseId: string;
  }>();

  const { caseData } = useContext(CaseContext);

  const linkTo = (newTab: TabContentProps) => {
    if (!newTab.path) return;

    return generatePath('/cases/:caseId/events/:eventTab?', {
      caseId: caseId,
      tab: 'events',
      eventTab: newTab.path
    });
  };

  const shouldShowActivityTab = caseData?.type !== CaseType.ATIVOS_ESPECIAIS;

  const negotiatorId = caseData?.negotiator?.id;

  const canShowTimelineTab = can(
    Action.CASOS_ACOMPANHAMENTO_TIMELINE_CONSULTAR,
    negotiatorId
  );

  const canShowActivityTab = can(
    Action.CASOS_ACOMPANHAMENTO_ATIVIDADES_CONSULTAR,
    negotiatorId
  );

  const tabContents = [
    canShowTimelineTab && (
      <TabContent
        title="Timeline"
        path="timeline"
        dataTestId="timeline-tab"
        key="timeline-tab"
      >
        <TimeLine caseId={caseId} />
      </TabContent>
    ),
    canShowActivityTab && shouldShowActivityTab && (
      <TabContent
        title="Atividades"
        path="activities"
        dataTestId="activity-tab"
        key="activity-tab"
      >
        <Activities caseId={caseId} />
      </TabContent>
    )
  ];

  const visibleTabContents = compact(tabContents);

  return (
    <div className={style.content}>
      <Tabs linkTab={linkTo} defaultPath={eventTab}>
        {visibleTabContents.length === 0 ? <div /> : visibleTabContents}
      </Tabs>
    </div>
  );
};

export default Events;
