export enum Reason {
  ALREADY_DEBTOR_OF_CONTRACT = 'already_debtor_of_contract',
  ALREADY_GUARANTOR_OF_GUARANTEE = 'already_guarantor_of_guarantee',
  ALREADY_PARTY_OF_LAWSUIT = 'already_party_of_lawsuit',
  ALREADY_PAYOR_OF_RECLAIMING = 'already_payor_of_reclaiming',
  ALREADY_RELATED_TO_ASSET = 'already_related_to_asset',
  ALREADY_RELATED_TO_DEBTOR = 'already_related_to_debtor',
  ALREADY_RELATED_TO_LOCATION = 'already_related_to_location',
  BASIC_INFORMATION_MISSING = 'basic_information_missing',
  INTERNAL_LAWYER_IN_A_CASE = 'internal_lawyer_in_a_case',
  MISSING_DATA = 'missing_data',
  NEGOTIATOR_IN_A_CASE = 'negotiator_in_a_case',
  NO_AVAILABLE_GUARANTEES = 'no_available_guarantees',
  ALREADY_IN_CASE = 'already_in_case'
}

export enum ActionName {
  ADD_TO_CASE = 'addToCase',
  CREATE_CONTRACT_GUARANTEE_RELATIONSHIP = 'createContractGuaranteeRelationship',
  CREATE_REPORT = 'createReport',
  DELETE = 'delete',
  UPDATE = 'update'
}

export enum ActionEntity {
  AIRPLANE,
  COMPANY,
  DEBTOR,
  INDIVIDUAL,
  LOCATION,
  PARTNER,
  REAL_ESTATE,
  RURAL_PROPERTY,
  SUSPECT,
  USER,
  VEHICLE
}

export type Action = {
  available: boolean;
  reasons: Reason[];
};

export type Actions = {
  [key in ActionName]?: Action;
};
