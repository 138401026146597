import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';

export function joinArray(
  value?: string[] | (string[] | null)[] | null
): string {
  if (!value) return '-';

  return uniq(flatten(value)).join(', ');
}
