import React from 'react';

import { Link } from 'ui';

import style from './HeaderStatus.module.scss';

export type LinkProps = {
  linkTo: string;
  label: string;
  linkId: string;
};

type Props = {
  links: LinkProps[];
  strongText: string;
  middleText?: string;
  dataTestId?: string;
  disabled?: boolean;
};

const Separator = ({
  listLength,
  currentIndex
}: Record<'listLength' | 'currentIndex', number>) => {
  if (currentIndex === listLength - 1) return <></>;
  if (currentIndex === listLength - 2) return <> e</>;
  return <>,</>;
};

const HeaderStatus = ({
  links,
  strongText,
  middleText = '',
  dataTestId = 'header-status',
  disabled
}: Props) => {
  return (
    <div className={style.headerStatus} data-testid={dataTestId}>
      <strong className={style.bold}>{strongText} </strong>
      <span>{middleText}</span>

      <div className={style.linksList}>
        {links.map((link, index) => (
          <span key={`linked-contract-link-${link.linkId}`}>
            <Link to={link.linkTo} disabled={disabled} underline highlight>
              {link.label}
            </Link>
            <Separator listLength={links.length} currentIndex={index} />
          </span>
        ))}
      </div>
    </div>
  );
};

export default HeaderStatus;
