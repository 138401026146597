import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Title, openSuccessToast, openErrorToast } from 'ui';

import { NewPasswordCredentials } from 'types';
import { acceptInvitation } from 'api';
import Form from './Form';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Campo obrigatório')
    .matches(/[a-z]/, 'Precisa ter no mínimo uma letra minúscula')
    .matches(/[A-Z]/, 'Precisa ter no mínimo uma letra maiúscula')
    .matches(/[0-9]/, 'Precisa ter no mínimo um dígito')
    .matches(/[!?@#$%^&*]/, 'Precisa ter no mínimo um caracter especial')
    .min(8, 'Precisa ter no mínimo 8 caracteres')
    .max(80, 'Pode ter no máximo 80 caracteres'),
  passwordConfirmation: Yup.string()
    .required('Campo obrigatório')
    .oneOf([Yup.ref('password'), null], 'As senhas são diferentes')
});

type Props = {
  invitationToken: string;
  setIsInvalid: (invalid: boolean) => void;
};

const CreatePassword = ({ invitationToken, setIsInvalid }: Props) => {
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: ''
    },
    validationSchema,
    onSubmit: async (values, formikHelpers) => {
      const credentials: NewPasswordCredentials = {
        password: values.password,
        passwordConfirmation: values.passwordConfirmation,
        token: invitationToken
      };

      try {
        await acceptInvitation(credentials);
        openSuccessToast('Senha alterada com sucesso!');
        history.push('/login');
      } catch (error) {
        if (error.status === 422) {
          formikHelpers.setErrors(error.errors);
        } else if (error.status === 404) {
          setIsInvalid(true);
        } else {
          openErrorToast('Não foi possível criar a senha.');
        }
      }
    }
  });

  return (
    <React.Fragment>
      <Title>Crie sua senha</Title>
      <p className="paragraph">
        A senha deve conter ao menos 8 caracteres entre letras, números e
        caracteres especiais.
      </p>
      <Form
        handleBlur={formik.handleBlur}
        handleSubmit={formik.handleSubmit}
        errors={formik.errors}
        touched={formik.touched}
        isSubmitting={formik.isSubmitting}
        handleOnChange={formik.handleChange}
        values={formik.values}
      />
    </React.Fragment>
  );
};

export default CreatePassword;
