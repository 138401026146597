import { DataImport } from 'types';
import Client from './client';

export function uploadSpreadsheet(fileData: FormData) {
  return Client.post<FormData, DataImport>('data_imports', fileData, {
    resource: false,
    customHeaders: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export function fetchLastDataImport() {
  return Client.get<DataImport>('data_imports/last', {
    redirectOnNotFoundError: false
  });
}

export function dismissLastDataImport(id: string) {
  return Client.patch<string, DataImport>(`data_imports/${id}/dismiss`);
}
