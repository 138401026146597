import { Activity, ActivityUpdateAttribute, CreditContract } from 'types';

import Client from './client';

function fetchActivitiesGroupedByContract({ caseId }: { caseId: string }) {
  return Client.get<CreditContract[]>(`cases/${caseId}/activities`);
}

type UpdateParams = {
  caseId: string;
  activityId: string;
  data: ActivityUpdateAttribute;
};

function updateActivity({ caseId, activityId, data }: UpdateParams) {
  return Client.put<ActivityUpdateAttribute, Activity>(
    `cases/${caseId}/activities/${activityId}`,
    data
  );
}

export { fetchActivitiesGroupedByContract, updateActivity };
