import { DateTime } from 'luxon';
import React, { useEffect } from 'react';

import { fetchInstagramAllBookmarkedPublications } from 'api';
import { InstagramPublication } from 'types';
import { DynamicTable, Icon, openErrorToast } from 'ui';
import { handleNullValue } from 'utils';
import { useQueryCall } from 'hooks';

import SummaryTitle from 'pages/reports/SharedComponents/SummaryTitle';

import style from './InstagramBookmarkSummaryTable.module.scss';

type Props = {
  caseId: string;
  reportId: string;
  instagramProfileId?: string;
};

const InstagramBookmarkSummaryTable = ({
  caseId,
  reportId,
  instagramProfileId
}: Props) => {
  const { data = [], isLoading, error, request } = useQueryCall<
    InstagramPublication[],
    {
      caseId: string;
      reportId: string;
      instagramProfileId: string;
    }
  >(fetchInstagramAllBookmarkedPublications);

  useEffect(() => {
    instagramProfileId &&
      request({
        caseId,
        reportId,
        instagramProfileId
      });
  }, [caseId, reportId, instagramProfileId, request]);

  if (error)
    openErrorToast(
      'Ocorreu um erro ao listar as publicações salvas do Instagram.'
    );

  const columns = [
    {
      name: 'Post',
      columnKey: 'publication',
      render: (item: InstagramPublication) => (
        <div className={style.summaryPost}>
          {`Post ${DateTime.fromISO(item.createdAt).toFormat('dd/MM/yy T')}`}
        </div>
      )
    },
    {
      name: 'Localização',
      columnKey: 'location',
      render: (item: InstagramPublication) =>
        handleNullValue(item.instagramLocation?.displayName)
    },
    {
      name: 'Perfis marcados na postagem',
      columnKey: 'mentionedProfiles',
      render: (item: InstagramPublication) =>
        item.mentionedProfiles.length !== 0 ? (
          <>
            {item.mentionedProfiles?.map((profile, index) => [
              index > 0 && ', ',
              <React.Fragment key={profile.id}>
                <div className={style.summaryPostUser}>
                  @{profile.socialName}
                </div>
              </React.Fragment>
            ])}
          </>
        ) : (
          '-'
        )
    }
  ];

  const rowKey = (item: InstagramPublication) => item.id;

  return (
    <>
      <SummaryTitle>
        <Icon
          className={style.summaryTitleIcon}
          name="bookmark"
          fill="#7a7e94"
          small
        />
        {`Publicações do Instagram salvas (${data?.length})`}
      </SummaryTitle>
      <DynamicTable
        columns={columns}
        rowKey={rowKey}
        data={data}
        noAutoPaginate
        isLoading={isLoading}
        getRowClassName={() => style.summaryTableRow}
      />
    </>
  );
};

export default InstagramBookmarkSummaryTable;
