import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { Button, FormContainer, InputField } from 'ui';

type Props = {
  onSave: (nirfCode: string) => void;
};

const validationSchema = Yup.object().shape({
  nirfCode: Yup.string().required('Campo obrigatório.')
});

const NirfForm = ({ onSave }: Props) => (
  <Formik<{ nirfCode: string }>
    initialValues={{ nirfCode: '' }}
    validationSchema={validationSchema}
    onSubmit={({ nirfCode }) => onSave(nirfCode)}
  >
    {form => (
      <FormContainer>
        <InputField
          dataTestId="nirf-code-input"
          id="nirf-code"
          name="nirfCode"
          type="text"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.nirfCode}
          error={form.errors.nirfCode}
        />

        <Button
          dataTestId="nirf-submit-button"
          onClick={form.handleSubmit}
          highlight
          centered
          outline={false}
          type="submit"
        >
          Adicionar
        </Button>
      </FormContainer>
    )}
  </Formik>
);

export default NirfForm;
