import React, { ReactChild } from 'react';
import cn from 'classnames';

import { Icon } from 'ui';

import style from './Trigger.module.scss';

type BaseProps = {
  label?: string;
  className?: string;
  onClick?: () => void;
};

type Props =
  | (BaseProps & { content: ReactChild; isClosed?: never })
  | (BaseProps & { content?: never; isClosed: boolean });

type DefaultIconProps = Pick<Props, 'isClosed'>;

const DefaultIcon = ({ isClosed }: DefaultIconProps) => (
  <Icon name={isClosed ? 'arrow-right' : 'arrow-down'} fill="#62667A" small />
);

const Trigger = ({
  label,
  isClosed,
  onClick,
  content = DefaultIcon({ isClosed }),
  className = ''
}: Props) => {
  const triggerClassName = cn(style.icon, className);

  return (
    <div
      data-testid="accordion-trigger"
      className={triggerClassName}
      onClick={onClick}
      aria-label={`${isClosed ? 'abrir' : 'fechar'} seção ${label}`}
    >
      {content}
    </div>
  );
};

export default Object.assign(Trigger, { DefaultIcon });
