import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from 'components';

import Create from './Create';
import Edit from './Edit';
import DebtorsList from './DebtorsList';
import Contract from './Contract/Page';
import Invoice from './Invoice';

const DebtorsRoutes = () => {
  const { path } = useRouteMatch<{ caseId: string }>();

  return (
    <Switch>
      <PrivateRoute exact path={path} component={DebtorsList} />
      <PrivateRoute exact path={`${path}/create`} component={Create} />
      <PrivateRoute exact path={`${path}/:debtorId`} component={Edit} />

      <PrivateRoute
        exact
        path={`${path}/contract/create`}
        component={Contract}
      />

      <PrivateRoute
        exact
        path={`${path}/contract/:contractId`}
        component={Contract}
      />

      <PrivateRoute exact path={`${path}/invoice/create`} component={Invoice} />
      <PrivateRoute
        exact
        path={`${path}/invoice/:invoiceId`}
        component={Invoice}
      />
    </Switch>
  );
};

export default DebtorsRoutes;
