import get from 'lodash/get';
import defaultTo from 'lodash/defaultTo';

export const getWithDefault = <T, I>(
  keys: string | string[],
  initialValues: I,
  obj?: T,
  opts?: { keyOnDefault: string | string[] }
) =>
  defaultTo(
    get(obj, keys),
    get(initialValues, opts?.keyOnDefault ?? (keys as keyof I))
  );
