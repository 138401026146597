import React, { useContext, useState } from 'react';

import { fetchEvents, deleteEvent, submitEvent, can, Action } from 'api';

import { useQuery, useSearchAnchor } from 'hooks';
import {
  Card,
  Button,
  TimeLine,
  openErrorToast,
  Modal,
  openSuccessToast,
  Loading,
  Trigger,
  Link,
  Stack,
  StackMargin
} from 'ui';
import { CaseContext, EventForm } from 'components';
import { CaseEvent, CaseEventFormAttributes, OnSubmit } from 'types';

import TimeLineContent from './TimelineContent';

import style from './Timeline.module.scss';

type Props = {
  caseId: string;
};

const Timeline = ({ caseId }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [event, setEvent] = useState<CaseEvent | undefined>(undefined);
  const { data = [], isLoading, error, refetch, setData: setEvents } = useQuery<
    CaseEvent[],
    { caseId: string }
  >(fetchEvents, {
    caseId
  });

  const { caseData } = useContext(CaseContext);

  useSearchAnchor({ isLoading, field: 'id' });

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
    setEvent(undefined);
  };

  const isEventEmpty = !data.length;

  if (error) {
    openErrorToast('Erro ao listar eventos.');
  }

  const toggleEditMode = (event: CaseEvent) => {
    setEvent(event);
    setIsModalOpen(!isModalOpen);
  };

  const handleSubmitEvent: OnSubmit<CaseEventFormAttributes> = async (
    values: CaseEventFormAttributes,
    formikHelpers
  ) => {
    try {
      await submitEvent({ event, values, caseId });

      refetch({ caseId });
      handleModalToggle();
      openSuccessToast('Evento salvo com sucesso!');
    } catch (error) {
      formikHelpers.setErrors(error.errors);
      openErrorToast('Erro ao salvar evento.');
    }
  };

  const handleDelete = async (id: string) => {
    if (!window.confirm('Tem certeza que deseja remover esse Evento?')) {
      return;
    }
    try {
      await deleteEvent({ caseId, id });

      const filteredWithoutDeleteEvent = data?.filter(event => event.id !== id);
      setEvents(filteredWithoutDeleteEvent);
      handleModalToggle();
      openSuccessToast('Evento removido com sucesso.');
    } catch (error) {
      openErrorToast('Erro ao deleter o Evento.');
    }
  };

  const canCreateEvent = can(
    Action.CASOS_ACOMPANHAMENTO_EVENTO_INCLUIR,
    caseData?.negotiator?.id
  );

  return (
    <>
      <Card>
        {canCreateEvent && (
          <Button onClick={handleModalToggle} highlight outline small centered>
            Adicionar evento
          </Button>
        )}

        {isLoading && <Loading />}
        {!isLoading && (
          <Stack marginTop={StackMargin.XLARGE}>
            {!isEventEmpty &&
              data.map((event: CaseEvent) => (
                <TimeLine
                  id={event.id}
                  title={
                    <Link
                      dataTestId={`event-${event.id}`}
                      className={style.eventTitle}
                      highlight
                      underline
                      onClick={() => toggleEditMode(event)}
                    >
                      {event.title}
                      <Trigger.DefaultIcon isClosed />
                    </Link>
                  }
                  date={event.date}
                  key={event.id}
                  dataTestId="event-item"
                >
                  <TimeLineContent event={event} />
                </TimeLine>
              ))}

            {isEventEmpty && (
              <p className={style.empty}>Não há eventos neste caso</p>
            )}
          </Stack>
        )}
      </Card>
      <Modal
        title={event ? 'Editar evento' : 'Novo evento'}
        isOpen={isModalOpen}
        onClose={handleModalToggle}
      >
        <EventForm
          caseId={caseId}
          eventData={event}
          onDelete={handleDelete}
          onSubmit={handleSubmitEvent}
        />
      </Modal>
    </>
  );
};

export default Timeline;
