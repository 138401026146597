import React from 'react';

type Props = {
  id: string;
  name: string;
  title?: string;
  dataTestId?: string;
};

const InputLoader = ({ id, name, title, dataTestId }: Props) => {
  return (
    <div className="input-field" data-testid="input-loader">
      {title && (
        <label className="label" htmlFor={id} data-testid="input-field-label">
          {title}
        </label>
      )}
      <div className="container">
        <input
          disabled
          data-testid={dataTestId}
          className="input"
          id={id}
          name={name}
          type="text"
          placeholder="Carregando..."
        />
      </div>
    </div>
  );
};

export default InputLoader;
