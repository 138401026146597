import { eventTypeLabel } from 'domain/caseEvents';
import React from 'react';

import { useToggles } from 'hooks';
import { handleNullValue } from 'utils';
import {
  Link,
  Modal,
  CollapsibleParagraph,
  Stack,
  StackMargin,
  Badge
} from 'ui';

import { CaseEvent } from 'types';

import style from './TimelineContent.module.scss';

type Props = {
  event: CaseEvent;
};

const TimelineContent = ({ event }: Props) => {
  const { isOpen, toggle } = useToggles<'content'>({
    content: false
  });

  return (
    <div>
      <CollapsibleParagraph
        className={style.description}
        text={event.description}
      />
      <p className={style.createdBy}>Evento criado por {event.user.name}</p>

      {!!event.type && (
        <Stack marginTop={StackMargin.SMALL}>
          <Badge
            dataTestId={`${event.id}-${event.type}`}
            className={style.eventBadge}
          >
            {eventTypeLabel(event.type)}
          </Badge>
        </Stack>
      )}

      <Stack className={style.actions} marginTop={StackMargin.SMALL}>
        <Link onClick={toggle('content')} iconName="eye" highlight>
          Ver conteúdo
        </Link>

        {!!event.fileUploads.length && (
          <Link
            dataTestId={`file-count-${event.id}`}
            iconName="attach"
            highlight
            disabled
          >
            {event.fileUploads.length}
          </Link>
        )}
      </Stack>

      {isOpen.content && (
        <Modal
          title={
            <>
              <span>Conteúdo da movimentação</span> {event.title}
            </>
          }
          isOpen={isOpen.content}
          onClose={toggle('content')}
          large
        >
          <p data-testid="modal-content-event">
            {handleNullValue(event.content, 'string')}
          </p>
        </Modal>
      )}
    </div>
  );
};

export default TimelineContent;
