import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { ReportCaseCity, GroupedState } from 'types';
import { Table } from 'ui';

import StateRow from './StateRow/StateRow';
import styles from './ReportCaseCitiesTable.module.scss';

type Props = {
  reportCaseCities: ReportCaseCity[];
  isLoading?: boolean;
  isSummary?: boolean;
  shouldShowDetails?: boolean;
};

const ReportCaseCitiesTable = ({
  reportCaseCities,
  isLoading = false,
  isSummary = false,
  shouldShowDetails = false
}: Props) => {
  const states = Object.entries(groupBy(reportCaseCities, 'state')).map<
    GroupedState
  >(([state, cities]) => ({
    name: state,
    count: cities.reduce((acc, city) => acc + (city.origin?.count ?? 0), 0),
    cities
  }));

  const shouldHideHeaders = shouldShowDetails || !isSummary;

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.Cell className={styles.tableHeaderCell}>
            {shouldHideHeaders ? 'Praça/Identificação' : 'Praça'}
          </Table.Cell>
          {shouldHideHeaders && (
            <Table.Cell className={styles.tableHeaderCell}>Tipo</Table.Cell>
          )}
        </Table.Row>
      </Table.Header>
      <Table.Body
        isLoading={isLoading}
        isEmpty={!reportCaseCities.length}
        columns={3}
        emptyText="Nenhuma praça identificada"
      >
        {orderBy(states, ['name'], ['asc']).map(state => (
          <StateRow
            key={state.name}
            state={state}
            isSummary={isSummary}
            shouldShowDetails={shouldShowDetails}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

export default ReportCaseCitiesTable;
