import React from 'react';

import { fetchReportCaseCities } from 'api';
import { useQuery, useToggles } from 'hooks';

import { LocationMap } from 'components';
import { Card, openErrorToast, RadioSwitch, Stack, StackMargin } from 'ui';
import SummaryTitle from '../SummaryTitle';
import ReportCaseCitiesTable from './ReportCaseCitiesTable';
import styles from './CitiesAndLocation.module.scss';

type Props = {
  caseId: string;
  reportId: string;
  title: string;
  showMap?: boolean;
  showInstagramPostOnPin?: boolean;
  showInstagramLocations?: boolean;
  canBookmarkPublication?: boolean;
  isMapUpdating?: boolean;
  isSummary?: boolean;
};

const CitiesAndLocations = ({
  caseId,
  reportId,
  title,
  showMap = true,
  showInstagramPostOnPin = true,
  showInstagramLocations = true,
  canBookmarkPublication = true,
  isMapUpdating = false,
  isSummary = false
}: Props) => {
  const { toggle, isOpen } = useToggles<'caseCitiesDetails'>({
    caseCitiesDetails: false
  });

  const { data: reportCaseCities = [], isLoading, error } = useQuery(
    fetchReportCaseCities,
    {
      caseId,
      reportId
    }
  );

  if (error) {
    openErrorToast('Houve um erro ao listar praças');
  }

  if (isSummary) {
    return (
      <>
        <SummaryTitle
          headerActions={
            reportCaseCities.length > 0 && (
              <RadioSwitch
                label="Detalhamento das praças"
                onChange={toggle('caseCitiesDetails')}
                active={isOpen.caseCitiesDetails}
                inputId="case-cities-summary-details"
              />
            )
          }
        >
          {title}
        </SummaryTitle>
        <Stack marginTop={StackMargin.SMALL}>
          <ReportCaseCitiesTable
            reportCaseCities={reportCaseCities}
            isLoading={isLoading}
            isSummary
            shouldShowDetails={isOpen.caseCitiesDetails}
          />
        </Stack>
      </>
    );
  }

  return (
    <>
      <LocationMap
        caseId={caseId}
        cities={reportCaseCities}
        isLoading={isMapUpdating || isLoading}
        reportId={reportId}
        title={title}
        showMap={showMap}
        showInstagramPostOnPin={showInstagramPostOnPin}
        showInstagramLocations={showInstagramLocations}
        canBookmarkPublication={canBookmarkPublication}
        hideEmptyMessage
      />

      <Stack marginTop={StackMargin.XLARGE} className={styles.cityTable}>
        <Card>
          <ReportCaseCitiesTable
            reportCaseCities={reportCaseCities}
            isLoading={isLoading}
          />
        </Card>
      </Stack>
    </>
  );
};

export default CitiesAndLocations;
