import React from 'react';
import cn from 'classnames';

import { Icon } from 'ui';

import { Marker, PAGES_TO_SKIP_LABEL } from '../utils';
import style from './PageButton.module.scss';

type Props = {
  onClick: () => void;
  isCurrent?: boolean;
  isDisabled?: boolean;
  label: string | Marker;
  borderless?: boolean;
};

const PageButton = ({
  onClick,
  borderless,
  isCurrent,
  isDisabled,
  label
}: Props) => (
  <button
    onClick={onClick}
    className={cn([
      style.page,
      isCurrent && style.current,
      borderless && style.borderless
    ])}
    disabled={isCurrent || isDisabled}
  >
    {pageButtonContent(label)}
  </button>
);

const pageButtonContent = (label: string | Marker) => {
  switch (label) {
    case Marker.DOUBLE_LEFT:
      return (
        <>
          <span className={style.hoverLabel} aria-hidden>
            ...
          </span>
          <span
            className={style.hoverChevron}
            aria-label={`voltar ${PAGES_TO_SKIP_LABEL} páginas`}
          >
            «
          </span>
        </>
      );
    case Marker.DOUBLE_RIGHT:
      return (
        <>
          <span className={style.hoverLabel} aria-hidden>
            ...
          </span>
          <span
            className={style.hoverChevron}
            aria-label={`avançar ${PAGES_TO_SKIP_LABEL} páginas`}
          >
            »
          </span>
        </>
      );

    case Marker.LEFT:
      return (
        <span className={style.label} aria-label={'página anterior'}>
          <Icon name={'arrow-left'} fill="#62667a" />
        </span>
      );

    case Marker.RIGHT:
      return (
        <span className={style.label} aria-label={'próxima página'}>
          <Icon name={'arrow-right'} fill="#62667a" />
        </span>
      );

    default:
      return <span>{label}</span>;
  }
};

export default PageButton;
