import {
  AddContentToCase,
  CaseEntity,
  FetchReportTableProps,
  ReportPartnershipCompanies,
  Report,
  FetchReportTotalValueResult
} from 'types';
import Client from './client';

export function fetchReportPartnershipCompanies({
  caseId,
  pagination,
  reportId
}: FetchReportTableProps) {
  return Client.get<ReportPartnershipCompanies[]>(
    `cases/${caseId}/reports/${reportId}/contents/partnership_companies`,
    {
      params: {
        page: pagination?.page.toString(),
        pageSize: pagination?.pageSize.toString()
      }
    }
  );
}

export function fetchReportPartnershipCompaniesSummary({
  caseId,
  reportId
}: FetchReportTableProps) {
  return Client.get<ReportPartnershipCompanies[]>(
    `cases/${caseId}/reports/${reportId}/contents/partnership_companies/summary`
  );
}

export function addPartnershipCompanyToCase({
  caseId,
  reportId,
  contentId
}: AddContentToCase) {
  return Client.post<unknown, CaseEntity>(
    `cases/${caseId}/reports/${reportId}/contents/partnership_companies/${contentId}`
  );
}

export function createReportFromPartnershipCompany({
  caseId,
  reportId,
  contentId
}: AddContentToCase) {
  return Client.post<unknown, Report>(
    `cases/${caseId}/reports/${reportId}/contents/partnership_companies/${contentId}/reports`
  );
}

export function fetchReportPartnershipCompaniesTotalValue({
  caseId,
  reportId
}: FetchReportTableProps) {
  return Client.get<FetchReportTotalValueResult>(
    `cases/${caseId}/reports/${reportId}/contents/partnership_companies/total_value`
  );
}
