import { Asset } from 'types';
import Client from './client';

type UpdateData = {
  assetIds: string[];
};

function updateAssetGroup(
  caseId: string,
  assetGroupId: string,
  data: UpdateData
) {
  return Client.put<UpdateData, Asset>(
    `cases/${caseId}/assets/${assetGroupId}/groups`,
    data
  );
}

export type AssetGroupParams = {
  name: string;
  assetIds: string[];
};

function createAssetGroup(caseId: string, data: AssetGroupParams) {
  return Client.post<AssetGroupParams, Asset>(
    `cases/${caseId}/asset_groups`,
    data
  );
}

function removeAssetFromGroup(caseId: string, id: string) {
  return Client.delete<Asset>(`cases/${caseId}/assets/${id}/groups`);
}

export { createAssetGroup, updateAssetGroup, removeAssetFromGroup };
