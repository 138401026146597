const MAX_FILE_SIZE = 50000000;
const ONE_MB = 1000000;

export function isValidFileSize(size: number) {
  return size <= MAX_FILE_SIZE;
}

export function fileTooBigErrorMessage(): string {
  return `Arquivo muito grande. Máximo: ${Math.ceil(MAX_FILE_SIZE / ONE_MB)}MB`;
}
