import { UsefulLink, UsefulLinkAttributes } from 'types';
import Client from './client';

function fetchUsefulLinks() {
  return Client.get<UsefulLink[]>('useful_links');
}

function createUsefulLink(linkData: UsefulLinkAttributes) {
  return Client.post<UsefulLinkAttributes, UsefulLink>(
    'useful_links',
    linkData
  );
}

function updateUsefulLink(id: string, linkData: UsefulLinkAttributes) {
  return Client.put<UsefulLinkAttributes, UsefulLink>(
    `useful_links/${id}`,
    linkData
  );
}

function deleteUsefulLink(id: string) {
  return Client.delete(`useful_links/${id}`);
}

export {
  fetchUsefulLinks,
  createUsefulLink,
  updateUsefulLink,
  deleteUsefulLink
};
