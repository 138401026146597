import React, { useState } from 'react';
import compact from 'lodash/compact';
import { DateTime } from 'luxon';

import { DEFAULT_PAGE_SIZE, FIRST_PAGE, InstagramPublication } from 'types';
import {
  fetchInstagramProfileBookmarkedPublications,
  unbookmarkInstagramPublication
} from 'api';

import {
  Accordion,
  Card,
  DropDown,
  DynamicTable,
  Link,
  Stack,
  StackMargin,
  TablePositionModifier,
  Toast,
  ToastType,
  openErrorToast,
  openInfoToast
} from 'ui';

import { useInstagramProfile } from 'components';

type Props = {
  caseId: string;
  reportId: string;
  canBookmarkPublication?: boolean;
};

const MINIMUN_PAGE_SIZE = 6;

const BookmarkedInstagramPublicationsTable = ({
  caseId,
  reportId,
  canBookmarkPublication = true
}: Props) => {
  const {
    instagramProfile,
    setUnBookmarkPublication,
    setPaginatedFavoritedPublications
  } = useInstagramProfile();

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const paginatedInstagramPublications =
    instagramProfile?.paginatedFavoritedPublications;

  const instagramProfileId = instagramProfile?.id;

  const onCurrentPageChange = async (newPage: number) => {
    if (!instagramProfileId) return;

    setLoading(true);

    try {
      const response = await fetchInstagramProfileBookmarkedPublications({
        caseId,
        reportId,
        instagramProfileId,
        pagination: {
          page: newPage,
          pageSize:
            paginatedInstagramPublications?.pagination!.size ??
            DEFAULT_PAGE_SIZE
        }
      });

      setPaginatedFavoritedPublications(response);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const onItemsPerPageChange = async (newPageSize: number) => {
    if (!instagramProfileId) return;

    setLoading(true);

    try {
      const response = await fetchInstagramProfileBookmarkedPublications({
        caseId,
        reportId,
        instagramProfileId,
        pagination: {
          page:
            paginatedInstagramPublications?.pagination!.number ?? FIRST_PAGE,
          pageSize: newPageSize
        }
      });

      setPaginatedFavoritedPublications(response);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleUnbookmarkInstagramPublication = (
    instagramPublicationId: string
  ) => async () => {
    try {
      await unbookmarkInstagramPublication({
        caseId,
        reportId,
        instagramPublicationId
      });

      setUnBookmarkPublication(instagramPublicationId);

      openInfoToast('Post removido de postagens salvas');
    } catch (e) {
      openErrorToast('Ocorreu um erro ao remover postagem.');
    }
  };

  if (error)
    openErrorToast(
      'Ocorreu um erro ao listar as Postagens do Instagram salvas.'
    );

  const columns = compact([
    {
      name: 'Post',
      columnKey: 'publication',
      render: (item: InstagramPublication) => (
        <Link
          asButton
          social
          small
          external
          iconName="instagram"
          href={item.url}
        >
          {`Post ${DateTime.fromISO(item.createdAt).toFormat('dd/MM/yy T')}`}
        </Link>
      )
    },
    {
      name: 'Localização',
      columnKey: 'location',
      render: (item: InstagramPublication) =>
        item.instagramLocation?.displayName
    },
    {
      name: 'Usuário do Instagram',
      columnKey: 'socialName',
      render: (item: InstagramPublication) => (
        <Link highlight underline external href={item.instagramProfile?.url}>
          @{item.instagramProfile?.socialName}
        </Link>
      )
    },
    canBookmarkPublication && {
      positionModifier: TablePositionModifier.RIGHT,
      hasActions: true,
      name: '',
      columnKey: 'actions',
      render: (item: InstagramPublication) => (
        <DropDown
          dataTestId="actions-dropdown"
          options={[
            {
              text: 'Remover de postagens salvas',
              callback: handleUnbookmarkInstagramPublication(item.id)
            }
          ]}
        />
      )
    }
  ]);

  const rowKey = (item: InstagramPublication) => item.id;

  const dynamicTablePagination = paginatedInstagramPublications?.pagination && {
    currentPage: paginatedInstagramPublications?.pagination.number,
    itemsPerPage: paginatedInstagramPublications?.pagination.size,
    hidePagination:
      paginatedInstagramPublications?.pagination.totalEntries <=
      MINIMUN_PAGE_SIZE,
    totalEntries: paginatedInstagramPublications?.pagination.totalEntries,
    totalPages: paginatedInstagramPublications?.pagination.totalPages,
    onCurrentPageChange,
    onItemsPerPageChange
  };

  return (
    <Accordion
      title="Postagens do Instagram salvas"
      iconName="bookmark"
      badgeContent={paginatedInstagramPublications?.pagination.totalEntries}
    >
      <Toast
        type={ToastType.DEFAULT}
        message="As postagens no Instagram estão sujeitas a edições e deleções do usuário"
      />

      <Stack marginTop={StackMargin.MEDIUM}>
        <Card small>
          <DynamicTable
            columns={columns}
            data={paginatedInstagramPublications?.data ?? []}
            rowKey={rowKey}
            pagination={dynamicTablePagination && dynamicTablePagination}
            noAutoPaginate
            dataTestId="bookmarkInstagramPublicationtable"
            isLoading={isLoading}
          />
        </Card>
      </Stack>
    </Accordion>
  );
};

export default BookmarkedInstagramPublicationsTable;
