import React, { useEffect, useState } from 'react';
import { fetchFeatureFlags } from 'api';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'pages/routes';
import { SocketProvider, ErrorBoundary, PaginationProvider } from 'components';
import { DataImportProvider } from 'components/DataImportField';

import 'styles/app.scss';
import 'styles/global.scss';

function App() {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetchFeatureFlags()
      .catch(console.error)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (isLoading) {
    return <p data-testid="feature-flags-loader">Carregando...</p>;
  }

  return (
    <SocketProvider>
      <DataImportProvider>
        <BrowserRouter>
          <ErrorBoundary>
            <PaginationProvider>
              <Routes />
            </PaginationProvider>
          </ErrorBoundary>
        </BrowserRouter>
      </DataImportProvider>
    </SocketProvider>
  );
}

export default App;
