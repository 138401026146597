import { caseCityLabel } from 'domain/cities';
import { tableColapseOptions } from 'domain/transitions';
import React from 'react';
import { Icon, Link, Table } from 'ui';
import { motion } from 'framer-motion';

import { CaseCityEntriesType, CaseCityEntry } from 'types';
import { applyDocumentMask } from 'utils';
import styles from './OriginRow.module.scss';

type Props = {
  origin: CaseCityEntry;
  isSummary?: boolean;
  hiddenEntriesLinks?: CaseCityEntriesType[];
};

const OriginRow = ({
  origin,
  isSummary = false,
  hiddenEntriesLinks = []
}: Props) => {
  const allowOpenInBrowserTypes = [
    CaseCityEntriesType.ASSET,
    CaseCityEntriesType.INTERNAL_LAWSUITS,
    CaseCityEntriesType.REPORT
  ];

  const getOpenInBrowserPath = (type: CaseCityEntriesType) => {
    if (type === CaseCityEntriesType.ASSET) {
      return 'assets';
    }

    if (type === CaseCityEntriesType.INTERNAL_LAWSUITS) {
      return 'internal_lawsuits';
    }

    return 'reports';
  };

  return (
    <motion.tr className={styles.originRow} {...tableColapseOptions}>
      <Table.Cell>
        <div className={styles.documentCell}>
          {applyDocumentMask(origin.entityDocument)}
        </div>
      </Table.Cell>
      <Table.Cell>{origin.entityName}</Table.Cell>
      <Table.Cell>{caseCityLabel(origin.type)}</Table.Cell>
      <Table.Cell>
        <div className={styles.openInBrowserWrapper}>
          <span className={styles.locationQuantityCell}>{origin.count}</span>
          {!hiddenEntriesLinks.includes(origin.type) &&
            allowOpenInBrowserTypes.includes(origin.type) &&
            !isSummary && (
              <Link
                target="_blank"
                to={`${getOpenInBrowserPath(origin.type)}/${origin.id}`}
                dataTestId={`open-in-browser-${origin.id}`}
              >
                <Icon name="open-in-browser" fill="#62667A" small />
              </Link>
            )}
        </div>
      </Table.Cell>
    </motion.tr>
  );
};

export default OriginRow;
