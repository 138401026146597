import React, { ReactElement } from 'react';
import cn from 'classnames';

import './SelectField.scss';
import InputLoader from 'ui/InputLoader/InputLoader';

import Select, { Props as SelectProps } from 'ui/Select/Select';

type Props = SelectProps & {
  error?: string;
  isLoading?: boolean;
  name: string;
  showMode?: boolean;
  title?: string;
};

const SelectField = ({
  name,
  id,
  value,
  onChange,
  onBlur,
  options,
  title,
  error,
  disabled,
  showMode,
  dataTestId,
  optional = false,
  isLoading = false,
  optionalLabel
}: Props): ReactElement => {
  const selectClass = cn('select-field', {
    '-error': Boolean(error),
    '-disabled': Boolean(disabled),
    '-empty': !Boolean(value)
  });

  if (isLoading) {
    return (
      <div className="select-field">
        <InputLoader
          name={name}
          title={title}
          id={id}
          dataTestId={dataTestId}
        />
      </div>
    );
  }

  if (showMode) {
    const selectedOption:
      | { value: string; label: string }
      | undefined = options.find(option => option.value === value);

    return (
      <div className={selectClass} data-testid="select-field-show-mode">
        {title && <span className="label">{title}</span>}
        <span className="value">{selectedOption && selectedOption.label}</span>
      </div>
    );
  }

  return (
    <div className={selectClass} data-testid="select-field">
      {title && (
        <label className="label" htmlFor={id}>
          {title}
        </label>
      )}
      <div className="container">
        <Select
          {...{
            dataTestId,
            disabled,
            id,
            name,
            onBlur,
            onChange,
            optional,
            options,
            value,
            optionalLabel,
            title
          }}
        />
      </div>
      {error && <p className="message">{error}</p>}
    </div>
  );
};

export default SelectField;
