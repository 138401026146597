import { getFormikError } from 'domain/forms';
import { FormikProps } from 'formik';
import React from 'react';
import { Asset, AssetAttributes, AssetType } from 'types';
import { Card, TextArea } from 'ui';
import AirplaneFields from './AirplaneFields';
import OtherFields from './OtherFields';
import RealEstateFields from './RealEstateFields';
import RuralPropertyFields from './RuralPropertyFields';
import StatusSelector from './StatusSelector';
import VehicleFields from './VehicleFields';

import GroupRuralPropertyFields from './GroupFields/RuralPropertyFields';
import GroupRealEstateFields from './GroupFields/RealEstateFields';

type Props = {
  asset?: Asset;
  isLoading: boolean;
  formik: FormikProps<AssetAttributes>;
  disabled?: boolean;
};

const AssetTypeFields = ({ asset, isLoading, formik, disabled }: Props) => {
  const assetTypeField = {
    [AssetType.AIRPLANE]: (
      <AirplaneFields
        assetAirplane={asset?.assetAirplane ?? null}
        identificationLocked={!!asset?.identificationLocked}
        isLoading={isLoading}
        formik={formik}
        disabled={disabled}
      />
    ),
    [AssetType.VEHICLE]: (
      <VehicleFields
        assetVehicle={asset?.assetVehicle ?? null}
        identificationLocked={!!asset?.identificationLocked}
        isLoading={isLoading}
        formik={formik}
        disabled={disabled}
      />
    ),
    [AssetType.RURAL_PROPERTY]: (
      <>
        {asset?.isGroup ? (
          <GroupRuralPropertyFields
            assetRuralProperty={asset?.assetRuralProperty ?? null}
            isLoading={isLoading}
            formik={formik}
            disabled={disabled}
          />
        ) : (
          <RuralPropertyFields
            assetRuralProperty={asset?.assetRuralProperty ?? null}
            identificationLocked={!!asset?.identificationLocked}
            isLoading={isLoading}
            formik={formik}
            disabled={disabled}
          />
        )}
      </>
    ),
    [AssetType.REAL_ESTATE]: (
      <>
        {asset?.isGroup ? (
          <GroupRealEstateFields
            assetRealEstate={asset?.assetRealEstate ?? null}
            isLoading={isLoading}
            formik={formik}
            disabled={disabled}
          />
        ) : (
          <RealEstateFields
            assetRealEstate={asset?.assetRealEstate ?? null}
            identificationLocked={!!asset?.identificationLocked}
            isLoading={isLoading}
            formik={formik}
            disabled={disabled}
          />
        )}
      </>
    ),
    [AssetType.OTHER]: (
      <OtherFields
        assetOther={asset?.assetOther ?? null}
        identificationLocked={!!asset?.identificationLocked}
        isLoading={isLoading}
        formik={formik}
        disabled={disabled}
      />
    )
  };

  const hasError = getFormikError(formik);

  return (
    <Card medium>
      {assetTypeField[asset?.type ?? AssetType.OTHER]}

      <StatusSelector
        formik={formik}
        isLoading={isLoading}
        isDisabled={Boolean(asset?.assetGroupId || disabled)}
      />

      <TextArea
        showCounter
        limit={500}
        id="comment"
        name="comment"
        title="Comentários (opcional)"
        isLoading={isLoading}
        value={formik.values.comment}
        error={hasError('comment')}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        disabled={disabled}
      />
    </Card>
  );
};

export default AssetTypeFields;
