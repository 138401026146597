import { reportCaseCityLabel } from 'domain/cities';
import { tableColapseOptions } from 'domain/transitions';
import React from 'react';
import { Table } from 'ui';
import { motion } from 'framer-motion';

import { ReportCaseCityEntry } from 'types';
import { handleNullValue } from 'utils';
import styles from './OriginRow.module.scss';

type Props = {
  origin: ReportCaseCityEntry;
};

const OriginRow = ({ origin }: Props) => {
  return (
    <motion.tr className={styles.originRow} {...tableColapseOptions}>
      <Table.Cell dataTestId="origin-cell">
        <div className={styles.identificationCell}>
          {handleNullValue(origin.identification)}
        </div>
      </Table.Cell>
      <Table.Cell>{reportCaseCityLabel(origin.type)}</Table.Cell>
    </motion.tr>
  );
};

export default OriginRow;
