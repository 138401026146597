import React from 'react';

import { CompanySummaryInfoType, Report } from 'types';

import { Stack, StackMargin } from 'ui';

import BasicInfo from '../SharedComponents/BasicInfo/BasicInfo';
import Cnaes from '../SharedComponents/Cnaes/Cnaes';

import PartnersTable from '../SharedComponents/CompanyTables/PartnersTable';
import RelatedCompaniesTable from '../SharedComponents/CompanyTables/RelatedCompaniesTable';
import LocationsTable from '../SharedComponents/CompanyTables/LocationsTable';
import VehiclesTable from '../SharedComponents/SharedTables/VehiclesTable';
import CitiesAndLocations from '../SharedComponents/CitiesAndLocations/CitiesAndLocations';

import {
  RealEstateTable,
  AirplanesTable,
  RuralPropertyTable
} from '../SharedComponents/SharedTables';
import OtherInfosSummary from '../SharedComponents/OtherInfosSummary/OtherInfosSummary';
import ExternalLawsuitsSummary from './ExternalLawsuitsSummary';

import style from './Summary.module.scss';

type InfosObj = { [key in CompanySummaryInfoType]: string };

type Props = {
  caseId: string;
  reportId: string;
  data: Report;
  checkedInfos: InfosObj;
};

const Company = ({ data, caseId, reportId, checkedInfos }: Props) => {
  const isInfoChecked = (infoKey: CompanySummaryInfoType) => {
    return checkedInfos[infoKey];
  };

  return (
    <>
      <div className={style.information}>
        <div className={style.col}>
          <BasicInfo report={data} isSummary />
        </div>
        <div className={style.col}>
          <OtherInfosSummary report={data} />
        </div>
        <div className={style.fullWidthCol}>
          {<Cnaes caseId={caseId} reportId={reportId} isSummary />}
        </div>
      </div>

      {isInfoChecked(CompanySummaryInfoType.REPORT_CITIES) && (
        <Stack marginTop={StackMargin.XLARGE}>
          <CitiesAndLocations
            reportId={reportId}
            caseId={caseId}
            title="Praças"
            isSummary
          />
        </Stack>
      )}

      {isInfoChecked(CompanySummaryInfoType.SOCIETAL_STRUCTURE) && (
        <Stack marginTop={StackMargin.XLARGE}>
          <PartnersTable caseId={caseId} reportId={reportId} isSummary />
        </Stack>
      )}

      {isInfoChecked(CompanySummaryInfoType.RELATED_COMPANIES) && (
        <Stack marginTop={StackMargin.XLARGE}>
          <RelatedCompaniesTable
            caseId={data.caseId}
            reportId={data.id}
            isSummary
          />
        </Stack>
      )}

      {isInfoChecked(CompanySummaryInfoType.REAL_ESTATE) && (
        <Stack marginTop={StackMargin.XLARGE}>
          <RealEstateTable caseId={data.caseId} reportId={data.id} isSummary />
        </Stack>
      )}

      {isInfoChecked(CompanySummaryInfoType.VEHICLE) && (
        <Stack marginTop={StackMargin.XLARGE}>
          <VehiclesTable caseId={data.caseId} reportId={data.id} isSummary />
        </Stack>
      )}

      {isInfoChecked(CompanySummaryInfoType.AIRPLANE) && (
        <Stack marginTop={StackMargin.XLARGE}>
          <AirplanesTable caseId={caseId} reportId={reportId} isSummary />
        </Stack>
      )}

      {isInfoChecked(CompanySummaryInfoType.RURAL_PROPERTY) && (
        <Stack marginTop={StackMargin.XLARGE}>
          <RuralPropertyTable
            caseId={data.caseId}
            reportId={data.id}
            isSummary
          />
        </Stack>
      )}

      {isInfoChecked(CompanySummaryInfoType.LOCATIONS) && (
        <Stack marginTop={StackMargin.XLARGE}>
          <LocationsTable caseId={data.caseId} reportId={data.id} isSummary />
        </Stack>
      )}

      {isInfoChecked(CompanySummaryInfoType.LAWSUITS) && (
        <Stack marginTop={StackMargin.XLARGE}>
          <ExternalLawsuitsSummary caseId={caseId} reportId={reportId} />
        </Stack>
      )}
    </>
  );
};

export default Company;
