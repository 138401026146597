import React from 'react';

import { PointFeature } from 'supercluster';

import { CaseCity, CityMapPoints } from 'types';

import GoogleMaps from 'components/GoogleMaps/GoogleMaps';

type Props = {
  caseId: string;
  cities: CaseCity[];
};

const CityMap = ({ caseId, cities }: Props) => {
  const points: PointFeature<CityMapPoints>[] = cities.map(city => ({
    type: 'Feature',
    properties: {
      cluster: false,
      displayName: `${city.name} - ${city.state}`,
      locationCount: city.origin?.count,
      id: city.id,
      caseId: caseId
    },
    geometry: {
      type: 'Point',
      coordinates: [city.longitude!, city.latitude!]
    }
  }));

  return (
    <div>
      <GoogleMaps cityPoints={points} />
    </div>
  );
};

export default CityMap;
