import React from 'react';
import cn from 'classnames';

import { InputLoader } from 'ui';
import styles from './TextArea.module.scss';

type Props = {
  dataTestId?: string;
  disabled?: boolean;
  error?: string[] | string;
  id: string;
  isLoading?: boolean;
  name: string;
  onBlur?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  rows?: number;
  title: string;
  value?: string | null;
  limit?: number;
  showCounter?: boolean;
};

const TextArea = ({
  disabled,
  error,
  id,
  isLoading,
  name,
  onBlur,
  onChange,
  placeholder = 'Digite aqui...',
  rows = 4,
  title,
  value = '',
  dataTestId,
  limit: maxLength,
  showCounter = false
}: Props) => {
  const sanitizedValue = value || '';
  const labelClassName = cn([styles.label, error && styles.labelError]);
  const messageClassName = cn([styles.message, error && styles.messageError]);

  const onTextAreaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    onChange(event);

    event.target.style.height = 'inherit';

    const computed = window.getComputedStyle(event.target);

    const height =
      parseInt(computed.getPropertyValue('border-top-width'), 10) +
      event.target.scrollHeight +
      parseInt(computed.getPropertyValue('border-bottom-width'), 10);

    event.target.style.height = `${height}px`;
  };

  if (isLoading) {
    return (
      <InputLoader name={name} title={title} id={id} dataTestId={dataTestId} />
    );
  }

  return (
    <div className={styles.textareaField} data-testid="textarea-field">
      <label className={labelClassName} htmlFor={id}>
        {title}
        {showCounter && ` (${sanitizedValue.length}/${maxLength})`}
      </label>
      <div className="container">
        <textarea
          id={id}
          className={cn([styles.textarea, styles.textareaAutoResizable])}
          aria-label={title}
          name={name}
          maxLength={maxLength}
          value={sanitizedValue}
          placeholder={placeholder}
          onChange={onTextAreaChange}
          onBlur={event => onBlur && onBlur(event)}
          disabled={disabled}
          data-testid={dataTestId}
        />
      </div>
      {error && (
        <p className={messageClassName}>
          {Array.isArray(error) ? error.join(', ') : error}
        </p>
      )}
    </div>
  );
};

export default TextArea;
