import {
  assetOriginOptions,
  assetRuralPropertyTypeOptions,
  ASSET_IDENTIFICATION_DICTIONARY,
  occupancyOptions
} from 'domain/assets';
import { areaUnitOptions } from 'domain/unit';
import { buildCitiesOptions } from 'domain/cities';
import React, { useEffect } from 'react';
import { FormikProps, getIn } from 'formik';
import { FormContainer, InputField, SelectField, GroupField, Toggle } from 'ui';
import { stateOptions } from 'utils';
import { useQueryCall } from 'hooks';
import { fetchCities } from 'api';
import { City, AssetAttributes, AssetType, AssetRuralProperty } from 'types';

import NirfCodes from '../NirfCodes/NirfCodes';

import styles from '../Form.module.scss';

type Props = {
  assetRuralProperty: AssetRuralProperty | null;
  isLoading: boolean;
  formik: FormikProps<AssetAttributes>;
  identificationLocked: boolean;
  disabled?: boolean;
};

const RuralPropertyFields = ({
  assetRuralProperty,
  isLoading,
  formik,
  identificationLocked,
  disabled
}: Props) => {
  const { data: cities, request: callCities } = useQueryCall<
    City[],
    { state: string }
  >(fetchCities);

  const selectedState = formik?.values.assetRuralProperty?.state;

  useEffect(() => {
    if (selectedState) {
      callCities({ state: selectedState });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedState]);

  const getErrors = (field: string) =>
    getIn(formik.touched, field) && getIn(formik.errors, field);

  const handleNirfCodes = (codes: string[]) =>
    formik.setFieldValue('assetRuralProperty.nirfCodes', [...codes]);

  return (
    <>
      <h3 className={styles.title}>Informações do Imóvel Rural</h3>

      <InputField
        type="text"
        id="name"
        name="name"
        title="Nome do ativo"
        isLoading={isLoading}
        value={formik.values.name}
        error={getErrors('name')}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        disabled={disabled}
      />

      <InputField
        type="text"
        id="assetRuralProperty.address"
        name="assetRuralProperty.address"
        title="Endereço (opcional)"
        isLoading={isLoading}
        value={formik.values.assetRuralProperty?.address}
        error={getErrors('assetRuralProperty.address')}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        showMode={
          assetRuralProperty ? !assetRuralProperty?.address?.isEditable : false
        }
        disabled={disabled}
      />

      <FormContainer className={'col-2'}>
        <InputField
          type="text"
          id="assetRuralProperty.number"
          name="assetRuralProperty.number"
          title="Número (opcional)"
          isLoading={isLoading}
          value={formik.values.assetRuralProperty?.number?.toString()}
          error={getErrors('assetRuralProperty.number')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          showMode={
            assetRuralProperty ? !assetRuralProperty.number.isEditable : false
          }
          disabled={disabled}
        />

        <InputField
          type="cep"
          id="assetRuralProperty.postalCode"
          name="assetRuralProperty.postalCode"
          title="CEP (opcional)"
          isLoading={isLoading}
          value={formik.values.assetRuralProperty?.postalCode?.toString()}
          error={getErrors('assetRuralProperty.postalCode')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          showMode={
            assetRuralProperty
              ? !assetRuralProperty.postalCode.isEditable
              : false
          }
          disabled={disabled}
        />

        <SelectField
          name="assetRuralProperty.state"
          id="assetRuralProperty.state"
          title="Estado (opcional)"
          value={formik.values.assetRuralProperty?.state ?? ''}
          onChange={(event: React.ChangeEvent<{ value: string }>) => {
            event.preventDefault();
            formik.handleChange(event);
            callCities({ state: event.currentTarget.value });
          }}
          onBlur={formik.handleBlur}
          options={stateOptions}
          error={getErrors('assetRuralProperty.state')}
          showMode={
            assetRuralProperty ? !assetRuralProperty.state.isEditable : false
          }
          disabled={disabled}
        />

        <SelectField
          name="assetRuralProperty.cityId"
          id="assetRuralProperty.cityId"
          title="Município (opcional)"
          value={formik.values.assetRuralProperty?.cityId ?? ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={!selectedState || isLoading || disabled}
          options={buildCitiesOptions(cities)}
          error={getErrors('assetRuralProperty.cityId')}
          showMode={
            assetRuralProperty ? !assetRuralProperty.city.isEditable : false
          }
        />

        <GroupField
          id="assetRuralProperty.landArea"
          title="Área do terreno (opcional)"
          error={
            getErrors('assetRuralProperty.landArea') ||
            getErrors('assetRuralProperty.landAreaUnit')
          }
        >
          <InputField
            type="area"
            id="assetRuralProperty.landArea"
            name="assetRuralProperty.landArea"
            isLoading={isLoading}
            value={formik.values.assetRuralProperty?.landArea?.toString()}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            showMode={
              assetRuralProperty
                ? !assetRuralProperty.landArea.isEditable
                : false
            }
            disabled={disabled}
          />
          <SelectField
            dataTestId="landAreaUnit"
            name="assetRuralProperty.landAreaUnit"
            id="assetRuralProperty.landAreaUnit"
            value={formik.values.assetRuralProperty?.landAreaUnit?.toString()}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={isLoading || disabled}
            options={areaUnitOptions()}
            showMode={
              assetRuralProperty
                ? !assetRuralProperty.landAreaUnit.isEditable
                : false
            }
            optional
          />
        </GroupField>

        <GroupField
          id="assetRuralProperty.constructedArea"
          title="Área útil (opcional)"
          error={
            getErrors('assetRuralProperty.constructedArea') ||
            getErrors('assetRuralProperty.constructedAreaUnit')
          }
        >
          <InputField
            type="area"
            id="assetRuralProperty.constructedArea"
            name="assetRuralProperty.constructedArea"
            isLoading={isLoading}
            value={formik.values.assetRuralProperty?.constructedArea?.toString()}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            showMode={
              assetRuralProperty
                ? !assetRuralProperty.constructedArea.isEditable
                : false
            }
            disabled={disabled}
          />
          <SelectField
            dataTestId="constructedAreaUnit"
            name="assetRuralProperty.constructedAreaUnit"
            id="assetRuralProperty.constructedAreaUnit"
            value={formik.values.assetRuralProperty?.constructedAreaUnit?.toString()}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={isLoading || disabled}
            options={areaUnitOptions()}
            showMode={
              assetRuralProperty
                ? !assetRuralProperty.constructedAreaUnit.isEditable
                : false
            }
            optional
          />
        </GroupField>

        <Toggle
          checked={formik.values?.assetRuralProperty?.occupied?.toString()}
          name="assetRuralProperty.occupied"
          onChange={event =>
            formik.setFieldValue(
              'assetRuralProperty.occupied',
              event.target.value === 'true'
            )
          }
          isLoading={isLoading}
          options={occupancyOptions}
          title="Ocupação (opcional)"
          showMode={
            assetRuralProperty ? !assetRuralProperty.occupied.isEditable : false
          }
          disabled={disabled}
        />

        <SelectField
          id="assetRuralProperty.origin"
          name="assetRuralProperty.origin"
          value={formik.values?.assetRuralProperty?.origin ?? ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isLoading={isLoading}
          options={assetOriginOptions()}
          title="Origem (opcional)"
          showMode={
            assetRuralProperty ? !assetRuralProperty.origin.isEditable : false
          }
          optional
          disabled={disabled}
        />

        <SelectField
          id="assetRuralProperty.type"
          name="assetRuralProperty.type"
          value={formik.values?.assetRuralProperty?.type ?? ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isLoading={isLoading}
          options={assetRuralPropertyTypeOptions()}
          title="Subtipo (opcional)"
          showMode={
            assetRuralProperty ? !assetRuralProperty.type.isEditable : false
          }
          optional
          disabled={disabled}
        />

        <InputField
          type="text"
          id="identification."
          name="identification"
          title={`${
            ASSET_IDENTIFICATION_DICTIONARY[AssetType.RURAL_PROPERTY]
          } (opcional)`}
          isLoading={isLoading}
          value={formik.values?.identification}
          error={getErrors('identification')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          showMode={identificationLocked}
          disabled={disabled}
        />

        <InputField
          type="text"
          id="assetRuralProperty.incraCode"
          name="assetRuralProperty.incraCode"
          title="Nº Incra (opcional)"
          isLoading={isLoading}
          value={formik.values?.assetRuralProperty?.incraCode}
          error={getErrors('assetRuralProperty.incraCode')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          showMode={
            assetRuralProperty
              ? !assetRuralProperty.incraCode.isEditable
              : false
          }
          disabled={disabled}
        />
      </FormContainer>

      <NirfCodes
        onSave={handleNirfCodes}
        nirfCodes={formik.values.assetRuralProperty?.nirfCodes}
        disabled={Boolean(
          (assetRuralProperty && !assetRuralProperty.incraCode.isEditable) ||
            disabled
        )}
      />
    </>
  );
};

export default RuralPropertyFields;
