import { entityDocument, entityName } from 'domain/entity';
import { judicialSituationLabel } from 'domain/debtors';
import { modalityLabel, statusLabel } from 'domain/contracts';
import { invoiceLabel, isInvoiceClosed } from 'domain/invoice';
import { guaranteeTotalValue } from 'domain/guarantees';
import React, { useMemo, useCallback, useContext } from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import sumBy from 'lodash/sumBy';
import sum from 'lodash/sum';

import { Action, can } from 'api';
import { CaseContext } from 'components';
import { AccordionTable, Toast, ToastType } from 'ui';
import { Header, Alignment } from 'ui/AccordionTable/AccordionTable';
import { valueToMillions, handleNullValue } from 'utils';

import {
  DebtorGroupedWithContractAndInvoice,
  DebtorGroupedCreditContract,
  DebtorGroupedInvoice
} from 'types';

import style from './DebtorTable.module.scss';

type Props = {
  caseId: string;
  debtors: DebtorGroupedWithContractAndInvoice[];
  isSalesFinance?: boolean;
  error?: boolean;
};

const DebtorTable = ({
  caseId,
  debtors,
  error = false,
  isSalesFinance
}: Props) => {
  const history = useHistory();
  const { caseData } = useContext(CaseContext);

  const negotiatorId = caseData?.negotiator?.id;

  const canUser = {
    showDebtor: can(Action.CASOS_DEVEDOR_CONSULTAR, negotiatorId),
    showContract: can(Action.CASOS_CONTRATO_CONSULTAR, negotiatorId),
    showInvoices: can(Action.CASOS_NOTA_FISCAL_CONSULTAR, negotiatorId)
  };

  const handleDebtorRedirect = useCallback(
    (debtorId: string) => () => {
      history.push(`/cases/${caseId}/debtors/${debtorId}`);
    },
    [caseId, history]
  );

  const handleContractRedirect = useCallback(
    (contractId: string) => () => {
      history.push(`/cases/${caseId}/debtors/contract/${contractId}`);
    },
    [caseId, history]
  );

  const handleInvoiceRedirect = useCallback(
    (invoiceId: string) => () => {
      history.push(`/cases/${caseId}/debtors/invoice/${invoiceId}`);
    },
    [caseId, history]
  );

  const headers: Header[] = [
    {
      cell: 'Devedor / Contrato',
      align: Alignment.LEFT
    },
    {
      cell: 'Situação judicial',
      align: Alignment.LEFT
    },
    {
      cell: 'Valor do contrato',
      align: Alignment.RIGHT
    },
    {
      cell: 'Saldo devedor',
      align: Alignment.RIGHT
    },
    {
      cell: 'Saldo contábil',
      align: Alignment.RIGHT
    },
    {
      cell: 'Garantias',
      align: Alignment.RIGHT
    },
    {
      cell: 'Recuperações',
      align: Alignment.RIGHT
    }
  ];

  const buildContractRows = useCallback(
    (contractsByModality: DebtorGroupedCreditContract[]) =>
      contractsByModality.map(contractByModality => {
        return {
          cells: [
            modalityLabel(contractByModality.modality),
            <span></span>,
            <span className={style.debtorSumValue}>
              {valueToMillions(contractByModality.totalContractAmount)}
            </span>,
            <span className={style.debtorSumValue}>
              {valueToMillions(contractByModality.totalDebtAmount)}
            </span>,
            <span className={style.debtorSumValue}>
              {valueToMillions(contractByModality.totalAccountingBalance)}
            </span>,
            <span className={style.debtorSumValue}>
              {valueToMillions(contractByModality.totalGuaranteeAmount)}
            </span>,
            <span className={style.debtorSumValue}>
              {valueToMillions(contractByModality.totalReclaimingAmount)}
            </span>
          ],
          childs: contractByModality.contracts.map(contract => {
            const className = cn(style.contractValue, {
              [style.halfOpacity]: contract.isClosed
            });

            return {
              cells: [
                <span className={className}>
                  {contract.isClosed
                    ? `${contract.number} (${statusLabel(contract.status)})`
                    : contract.number}
                </span>,
                <span></span>,
                <span className={className}>
                  {valueToMillions(contract.initialAmount)}
                </span>,
                <span className={className}>
                  {valueToMillions(contract.overdueAmountAtCuttingDate)}
                </span>,
                <span className={className}>
                  {valueToMillions(contract.accountingBalance ?? 0)}
                </span>,
                <span className={className}>
                  {valueToMillions(
                    sum(
                      contract.guarantees.flatMap(guarantee =>
                        guaranteeTotalValue(guarantee)
                      )
                    )
                  )}
                </span>,
                <span className={className}>
                  {valueToMillions(sumBy(contract.reclaimings, 'totalAmount'))}
                </span>
              ],
              childs: [],
              onClick: canUser.showContract
                ? handleContractRedirect(contract.id)
                : undefined,
              tooltipMessage: canUser.showContract
                ? undefined
                : 'Seu perfil não possui permissão para consultar o contrato.'
            };
          })
        };
      }),
    [canUser.showContract, handleContractRedirect]
  );

  const buildContractWrapperRow = useCallback(
    (contractsByModality: DebtorGroupedCreditContract[]) => {
      if (!contractsByModality.length) return [];

      const totalContractAmount = sumBy(
        contractsByModality,
        'totalContractAmount'
      );
      const totalDebtAmount = sumBy(contractsByModality, 'totalDebtAmount');
      const totalReclaimingAmount = sumBy(
        contractsByModality,
        'totalReclaimingAmount'
      );

      const totalAccountingBalance = sumBy(
        contractsByModality,
        'totalAccountingBalance'
      );

      const totalGuaranteeAmount = sumBy(
        contractsByModality,
        'totalGuaranteeAmount'
      );

      return [
        {
          cells: [
            <span>Contratos</span>,
            <span></span>,
            <span className={style.contractSumValue}>
              {valueToMillions(totalContractAmount)}
            </span>,
            <span className={style.contractSumValue}>
              {valueToMillions(totalDebtAmount)}
            </span>,
            <span className={style.contractSumValue}>
              {valueToMillions(totalAccountingBalance)}
            </span>,
            <span className={style.contractSumValue}>
              {valueToMillions(totalGuaranteeAmount)}
            </span>,
            <span className={style.contractSumValue}>
              {valueToMillions(totalReclaimingAmount)}
            </span>
          ],
          childs: buildContractRows(contractsByModality)
        }
      ];
    },
    [buildContractRows]
  );

  const buildInvoiceRows = useCallback(
    (invoicesByCovenant: DebtorGroupedInvoice[]) =>
      invoicesByCovenant.map(invoiceByCovenant => {
        return {
          cells: [
            invoiceByCovenant.covenant.name,
            <span></span>,
            <span className={style.contractSumValue}>
              {valueToMillions(invoiceByCovenant.totalContractAmount)}
            </span>,
            <span className={style.contractSumValue}>
              {valueToMillions(invoiceByCovenant.totalDebtAmount)}
            </span>,
            <span className={style.contractSumValue}>-</span>,
            <span className={style.contractSumValue}>-</span>,
            <span className={style.contractSumValue}>
              {valueToMillions(invoiceByCovenant.totalReclaimingAmount)}
            </span>
          ],
          childs: invoiceByCovenant.invoices.map(
            ({ invoice, totalReclaimingAmount, totalDebtAmount }) => {
              const isClosed = isInvoiceClosed(invoice);

              const className = cn(style.contractValue, {
                [style.halfOpacity]: isClosed
              });

              return {
                cells: [
                  <span className={className}>
                    {isClosed
                      ? `${invoice.number} (${invoiceLabel(invoice.situation)})`
                      : invoice.number}
                  </span>,
                  <span></span>,
                  <span className={className}>
                    {valueToMillions(invoice.valueCents)}
                  </span>,
                  <span className={className}>
                    {valueToMillions(totalDebtAmount)}
                  </span>,
                  <span className={className}>-</span>,
                  <span className={className}>-</span>,
                  <span className={className}>
                    {handleNullValue(totalReclaimingAmount, 'string', value =>
                      valueToMillions(parseInt(value))
                    )}
                  </span>
                ],
                childs: [],
                onClick: canUser.showInvoices
                  ? handleInvoiceRedirect(invoice.id)
                  : undefined,
                tooltipMessage: canUser.showInvoices
                  ? undefined
                  : 'Seu perfil não possui permissão para consultar a nota fiscal.'
              };
            }
          )
        };
      }),
    [canUser.showInvoices, handleInvoiceRedirect]
  );

  const buildInvoiceWrapperRow = useCallback(
    (invoicesByCovenant: DebtorGroupedInvoice[]) => {
      if (!invoicesByCovenant.length) return [];

      const totalContractAmount = sumBy(
        invoicesByCovenant,
        'totalContractAmount'
      );
      const totalDebtAmount = sumBy(invoicesByCovenant, 'totalDebtAmount');
      const totalReclaimingAmount = sumBy(
        invoicesByCovenant,
        'totalReclaimingAmount'
      );

      return [
        {
          cells: [
            <span>Notas Fiscais</span>,
            <span></span>,
            <span className={style.contractSumValue}>
              {valueToMillions(totalContractAmount)}
            </span>,
            <span className={style.contractSumValue}>
              {valueToMillions(totalDebtAmount)}
            </span>,
            <span className={style.contractSumValue}>-</span>,
            <span className={style.contractSumValue}>-</span>,
            <span className={style.contractSumValue}>
              {valueToMillions(totalReclaimingAmount)}
            </span>
          ],
          childs: buildInvoiceRows(invoicesByCovenant)
        }
      ];
    },
    [buildInvoiceRows]
  );

  const rows = useMemo(
    () =>
      debtors.map(debtorWith => {
        const {
          debtor,
          totalContractAmount,
          totalDebtAmount,
          totalAccountingBalance,
          totalGuaranteeAmount,
          totalReclaimingAmount,
          contractsByModality,
          invoicesByCovenant
        } = debtorWith;
        return {
          cells: [
            <div className={style.debtorWrapper}>
              <span className={style.debtorName}>
                {entityName(debtor.entity)}
              </span>
              <span className={style.debtorDoc}>
                {entityDocument(debtor.entity)}
              </span>
            </div>,
            judicialSituationLabel(debtor.judicialSituation),
            <span className={style.debtorSumValue}>
              {valueToMillions(totalContractAmount)}
            </span>,
            <span className={style.debtorSumValue}>
              {valueToMillions(totalDebtAmount)}
            </span>,
            <span className={style.debtorSumValue}>
              {valueToMillions(totalAccountingBalance)}
            </span>,
            <span className={style.debtorSumValue}>
              {valueToMillions(totalGuaranteeAmount)}
            </span>,
            <span className={style.debtorSumValue}>
              {valueToMillions(totalReclaimingAmount)}
            </span>
          ],
          onClick: canUser.showDebtor
            ? handleDebtorRedirect(debtor.id)
            : undefined,
          tooltipMessage: canUser.showDebtor
            ? undefined
            : 'Seu perfil não possui permissão para consultar o devedor.',
          childs: isSalesFinance
            ? [
                ...buildContractWrapperRow(contractsByModality),
                ...buildInvoiceWrapperRow(invoicesByCovenant)
              ]
            : [
                ...buildContractRows(contractsByModality),
                ...buildInvoiceRows(invoicesByCovenant)
              ]
        };
      }),
    [
      debtors,
      isSalesFinance,
      canUser.showDebtor,
      handleDebtorRedirect,
      buildContractWrapperRow,
      buildInvoiceWrapperRow,
      buildContractRows,
      buildInvoiceRows
    ]
  );

  if (error) {
    return (
      <Toast
        type={ToastType.ERROR}
        message="Houve um erro ao listar devedores"
      />
    );
  }

  return (
    <AccordionTable
      headers={headers}
      rows={rows}
      depth={isSalesFinance ? 4 : 3}
    />
  );
};

export default DebtorTable;
