import { ReportAddress, ReportLocationType } from 'types';

export function mountReportAddress(
  address?: ReportAddress | null,
  partialAddress?: 'street' | 'state'
): string {
  if (!address) {
    return '-';
  }

  const allKeyAddressAreNull = Object.entries(address).every(([key, value]) => {
    if (['field', 'id', 'fieldId', 'cityId'].includes(key)) return true;
    return value === null;
  });

  if (allKeyAddressAreNull) {
    return '-';
  }

  const street = [address.street, address.number, address.additionalInformation]
    .filter(item => item)
    .join(', ');

  const state = [address.city?.name, address.city?.state]
    .filter(item => item)
    .join(' - ');

  if (partialAddress === 'street' || state === '') return street;
  if (partialAddress === 'state' || street === '') return state;
  return street + '. ' + state;
}

export const ReportLocationTypeDictionary = {
  [ReportLocationType.BRANCH]: 'Filial',
  [ReportLocationType.HEADQUARTER]: 'Matriz'
};
