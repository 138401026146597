import React, { ReactNode, useMemo } from 'react';

export enum StackMargin {
  ZERO = '0px',
  SMALL = '8px',
  MEDIUM = '16px',
  LARGE = '24px',
  XLARGE = '32px'
}

type Props = {
  className?: string;
  children: ReactNode;
  marginTop?: StackMargin;
  marginLeft?: StackMargin;
  marginRight?: StackMargin;
  marginBottom?: StackMargin;
  dataTestId?: string;
};

const Stack = ({
  className,
  children,
  marginBottom = StackMargin.ZERO,
  marginLeft = StackMargin.ZERO,
  marginRight = StackMargin.ZERO,
  marginTop = StackMargin.ZERO,
  dataTestId
}: Props) => {
  const style = useMemo(() => {
    return {
      marginRight,
      marginLeft,
      marginBottom,
      marginTop
    };
  }, [marginRight, marginLeft, marginBottom, marginTop]);

  return (
    <div
      className={className}
      data-testid={dataTestId ?? 'stack'}
      style={style}
    >
      {children}
    </div>
  );
};

export default Stack;
