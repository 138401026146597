import React from 'react';

import { applyMask, handleNullValue } from 'utils';

import { ExecutionStatus, ExternalLawsuitCard } from 'components';
import { Badge, Card, DynamicTable, SubTitle, StackMargin, Stack } from 'ui';
import { ExternalLawsuit, ExternalLawsuitParty } from 'types';

import RelatedLawsuitList from './RelatedLawsuitList';

import style from './BasicInformation.module.scss';

type Props = {
  externalLawsuit?: ExternalLawsuit;
  isLoading?: boolean;
  disableStatusLinks?: boolean;
};

const BasicInformation = ({
  externalLawsuit,
  isLoading,
  disableStatusLinks
}: Props) => {
  const tableColumns = [
    {
      name: 'CPF/CNPJ',
      columnKey: 'document',
      render: (item: ExternalLawsuitParty) =>
        handleNullValue(item.document.value, 'string', value =>
          applyMask(item.document.type!, value)
        )
    },
    {
      name: 'Nome',
      columnKey: 'name',
      render: (item: ExternalLawsuitParty) => handleNullValue(item.name)
    },
    {
      name: 'Tipo da parte',
      columnKey: 'type',
      render: (item: ExternalLawsuitParty) => handleNullValue(item.type)
    },
    {
      name: 'Advogado',
      columnKey: 'lawyer',
      render: (item: ExternalLawsuitParty) =>
        handleNullValue(item.lawyerNames.join(', '))
    }
  ];

  const rowKey = (item: ExternalLawsuitParty) => item.name!;

  const hasSubjectItems =
    externalLawsuit && externalLawsuit.content.subjects.length > 0;

  return (
    <>
      {externalLawsuit?.executions.map(execution => (
        <ExecutionStatus
          key={execution.id}
          caseId={externalLawsuit.caseId}
          execution={execution}
          disabled={disableStatusLinks}
        />
      ))}

      {hasSubjectItems && (
        <Stack marginTop={StackMargin.LARGE}>
          <span className={style.cardLabel}>Assunto</span>
          <Card small>
            <div className={style.tags}>
              {externalLawsuit?.content.subjects.map((subject, i) => (
                <Badge rounded key={`subject-tag-${i}`}>
                  {subject}
                </Badge>
              ))}
            </div>
          </Card>
        </Stack>
      )}

      {externalLawsuit?.content.basicInformation.map(
        (basicInformation, index) => (
          <Stack key={index} marginTop={StackMargin.LARGE}>
            <span className={style.cardLabel}>Informações do processo</span>
            <ExternalLawsuitCard
              isLoading={isLoading}
              externalLawsuit={{
                ...externalLawsuit,
                content: {
                  ...externalLawsuit.content,
                  basicInformation: [basicInformation]
                }
              }}
              withoutTitle
              hideMonitoringIcon
              hideTrackingIcon
              title={
                <ExternalLawsuitCard.TriggerTitle lawsuit={externalLawsuit} />
              }
            />
          </Stack>
        )
      )}

      <div className={style.involvedParties}>
        <SubTitle badgeText={externalLawsuit?.content.parties.length}>
          Partes envolvidas
        </SubTitle>
        <Card small>
          <DynamicTable
            columns={tableColumns}
            data={externalLawsuit?.content.parties ?? []}
            isLoading={isLoading}
            rowKey={rowKey}
            emptyText="Não há partes envolvidas"
          />
        </Card>
      </div>

      {!isLoading && externalLawsuit?.children && (
        <Stack marginTop={StackMargin.LARGE}>
          <SubTitle badgeText={externalLawsuit.children.length}>
            Processos relacionados
          </SubTitle>
          <RelatedLawsuitList relatedLawsuits={externalLawsuit.children} />
        </Stack>
      )}
    </>
  );
};

export default BasicInformation;
