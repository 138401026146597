import { FileUpload } from './file';
import { User } from './user';
import { MovementAttachment } from './movementAttachment';

export enum SourceType {
  MANUAL = 'manual',
  EXTERNAL = 'external'
}

export type MovementAttributes = {
  date: string;
  title: null | string;
  content: null | string;
  description: null | string;
  source: string;
  finishedAt: string;
  type: string;
  user?: User;
  fileUploads: FileUpload[];
  attachments: MovementAttachment[];
};

export type Movement = MovementAttributes & {
  id: string;
};

export type MovementsResponse = {
  lastUpdatedAt: string;
  movements: Movement[];
};

export type MovementFormAttributes = {
  title: string;
  date: string;
  description: string;
  content: string;
  fileUploadIds: string[];
};
