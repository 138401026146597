import React, { ReactChild, ReactNode } from 'react';
import cn from 'classnames';
import { applyDateMask } from 'utils';

import { Loader } from 'ui';

import './TimeLine.scss';

export enum TimelineHeight {
  SMALL = '-small',
  MEDIUM = '-medium',
  LARGE = '-large'
}

export enum TimelineColor {
  BLUE = '-blue',
  GRAY = '-gray'
}

type Props = {
  id?: string;
  date?: string | null;
  title?: string | ReactChild | null;
  children?: ReactNode;
  dataTestId?: string;
  actions?: ReactNode;
  isLoading?: boolean;
  timelineHeight?: TimelineHeight;
  color?: TimelineColor;
};

const TimeLine = ({
  id,
  title,
  date,
  children,
  dataTestId,
  actions,
  isLoading,
  timelineHeight,
  color = TimelineColor.BLUE
}: Props) => {
  const dateClassNames = cn('date', color);

  const circleClassNames = cn('circle', timelineHeight, color, {
    '-loading': isLoading
  });

  const timelineClassName = cn('timeline', timelineHeight, color);

  if (isLoading) {
    return (
      <div className={timelineClassName} data-testid={dataTestId} id={id}>
        <span className={dateClassNames}>
          <Loader />
        </span>
        <div className="line">
          <div className={circleClassNames} />
        </div>
        <div className="body">
          <span className="title">
            <Loader width={4} />
          </span>
          <div className="loader">
            <Loader width={2} />
            <Loader width={3} height={1.5} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={timelineClassName} data-testid={dataTestId} id={id}>
      <span className={dateClassNames}>
        {date ? applyDateMask(date) : 'Sem data'}
      </span>
      <div className="line">
        <div className={circleClassNames} />
      </div>
      <div className="body">
        <span className="title">
          {title}
          {actions}
        </span>
        {children && <div data-testid="timeline-children">{children}</div>}
      </div>
    </div>
  );
};

export default TimeLine;
