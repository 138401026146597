import { eventTypeOptions } from 'domain/executions';
import { handleDateFieldChange } from 'domain/forms';

import React from 'react';
import { DateTime } from 'luxon';
import * as Yup from 'yup';
import { useFormik, getIn } from 'formik';

import {
  SelectField,
  FormContainer,
  openErrorToast,
  openSuccessToast,
  DatePickerField,
  Button
} from 'ui';
import {
  OnSubmit,
  ExecutionEvent,
  ExecutionRequestAttributes,
  ExecutionEventType
} from 'types';

import { createExecutionEvent } from 'api';

type Props = ExecutionRequestAttributes & {
  onCreate: (event: ExecutionEvent) => void;
};

const validationSchema = Yup.object().shape({
  type: Yup.string().notRequired(),
  date: Yup.date()
    .max(new Date(), 'A data deve ser igual ou anterior a hoje')
    .required()
});

const Form = ({ caseId, lawsuitId, executionId, onCreate }: Props) => {
  const onSubmit: OnSubmit<Omit<ExecutionEvent, 'id'>> = async values => {
    try {
      const response = await createExecutionEvent({
        caseId,
        lawsuitId,
        executionId,
        data: values
      });

      onCreate(response.data);

      openSuccessToast('Evento adicionado com sucesso!');
    } catch (error) {
      openErrorToast('Houve um erro ao adicionar o evento.');
    }
  };

  const formik = useFormik({
    initialValues: {
      date: '',
      type: ExecutionEventType.INDICADO_PARA_PENHORA
    },
    validationSchema,
    onSubmit: onSubmit
  });

  return (
    <form>
      <FormContainer>
        <SelectField
          id="type"
          name="type"
          value={formik.values.type}
          title="Tipo"
          error={getIn(formik.touched, 'type') && getIn(formik.errors, 'type')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          options={eventTypeOptions()}
        />

        <DatePickerField
          id="date"
          name="date"
          title="Data do evento"
          placeholder="DD/MM/AAAA"
          value={formik.values.date}
          error={getIn(formik.touched, 'date') && getIn(formik.errors, 'date')}
          onChange={handleDateFieldChange(
            formik.setFieldValue,
            formik.setFieldTouched,
            'date'
          )}
          onBlur={formik.handleBlur}
          max={DateTime.local().toISO()}
        />
        <Button centered highlight onClick={formik.handleSubmit} type="submit">
          Adicionar
        </Button>
      </FormContainer>
    </form>
  );
};

export default Form;
