import {
  EntityType,
  Option,
  EntityAttributes,
  EntityRoleCompany,
  EntityRoleIndividual,
  EntityRole,
  EntityFormAttributes
} from 'types';

import { handleNullValue, applyMask } from 'utils';

const ROLES_COMPANY: { [key in EntityRoleCompany]: string } = {
  [EntityRoleCompany.ABROAD_PARTNER]: 'Sócio(a) estrangeiro',
  [EntityRoleCompany.ADMINISTRATOR]: 'Administrator(a)',
  [EntityRoleCompany.DIRECTOR]: 'Diretor(a)',
  [EntityRoleCompany.EMPLOYEER]: 'Empregado(a)',
  [EntityRoleCompany.PARTNER]: 'Sócio(a)',
  [EntityRoleCompany.PRESIDENT]: 'Presidente(a)'
};

const ROLES_INDIVIDUAL: { [key in EntityRoleIndividual]: string } = {
  [EntityRoleIndividual.AUNT_OR_UNCLE]: 'Tio(a)',
  [EntityRoleIndividual.CHILD]: 'Filho(a)',
  [EntityRoleIndividual.COUSIN]: 'Primo(a)',
  [EntityRoleIndividual.DONOR]: 'Doador(a)',
  [EntityRoleIndividual.FATHER]: 'Pai',
  [EntityRoleIndividual.GRANDPARENT]: 'Avô(ó)',
  [EntityRoleIndividual.GRANDCHILD]: 'Neto(a)',
  [EntityRoleIndividual.MOTHER]: 'Mãe',
  [EntityRoleIndividual.RELATIVE]: 'Parente',
  [EntityRoleIndividual.SIBLING]: 'Irmão(ã)',
  [EntityRoleIndividual.SPOUSE]: 'Cônjuge',
  [EntityRoleIndividual.PARTNER]: 'Empresa'
};

const ENTITY_ROLE: { [key in EntityRole]: string } = {
  [EntityRole.DEBTOR]: 'Devedor',
  [EntityRole.SUSPECT]: 'Suspeito',
  [EntityRole.PAYOR]: 'Pagante',
  [EntityRole.GUARANTOR]: 'Avalista'
};

export const entityDocument = <
  T extends EntityAttributes | EntityFormAttributes
>(
  entity: T
) => {
  if (entity.type === EntityType.COMPANY) {
    return handleNullValue(entity.company?.cnpj, 'string', value =>
      applyMask('cnpj', value)
    );
  }

  return handleNullValue(entity.individual?.cpf, 'string', value =>
    applyMask('cpf', value)
  );
};

export const entityName = <T extends EntityAttributes | EntityFormAttributes>(
  entity: T
) => {
  if (entity.type === EntityType.COMPANY) {
    return handleNullValue(entity.company?.businessName);
  }

  return handleNullValue(entity.individual?.name);
};

const ENTITY_TYPE_DICTIONARY = {
  [EntityType.INDIVIDUAL]: 'Física',
  [EntityType.COMPANY]: 'Jurídica'
};

export function entityRoleLabel(role: EntityRole): string {
  return ENTITY_ROLE[role] || role;
}

export function entityRoleOptions(): Option[] {
  return Object.values(EntityRole).map(role => ({
    value: role,
    label: entityRoleLabel(role)
  }));
}

export function entityRolesToString(
  roles: string[],
  formatter?: (_: string) => string
) {
  const innerText = entityRoleOptions()
    .filter(role => roles.includes(role.value))
    .map(role => role.label)
    .sort()
    .join(', ');

  if (!innerText.length) return '';

  return formatter?.(innerText) ?? innerText;
}

export function entityTypeLabel(type: EntityType): string {
  return ENTITY_TYPE_DICTIONARY[type] || type;
}

export function entityTypeOptions(): Option[] {
  return Object.values(EntityType).map(type => ({
    value: type,
    label: entityTypeLabel(type)
  }));
}

export function entityRoleDescription(
  role: EntityRoleCompany | EntityRoleIndividual
): string {
  const roleLabel = entityRelationshipLabel(role);

  return roleLabel ? `é ${roleLabel.toLowerCase()} de` : '';
}

export function entityLink(entity: EntityAttributes): string {
  const { currentReport } = entity;

  if (!currentReport) return '';

  return `/cases/${currentReport?.caseId}/reports/${currentReport.id}`;
}

export function entityRelationshipLabel(
  relationship: EntityRoleIndividual | EntityRoleCompany
): string {
  const roleLabel =
    ROLES_INDIVIDUAL[relationship as EntityRoleIndividual] ||
    ROLES_COMPANY[relationship as EntityRoleCompany];

  return roleLabel;
}
