import React, { ReactElement, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { FormikProps } from 'formik';

import { useInitNavigation } from 'hooks';

import { Header, Button } from 'ui';

import { DebtorFormAttributes } from 'types';

import Form from '../Form';

import './Create.scss';

const Create = (
  props: RouteComponentProps<{ caseId: string }>
): ReactElement => {
  useInitNavigation(pathname => [pathname.replace('/create', '')]);
  const caseId = props.match.params.caseId;

  const formRef = useRef<FormikProps<DebtorFormAttributes>>(null);

  const handleSubmit = () => {
    if (formRef.current) return formRef.current.handleSubmit();
  };

  return (
    <div className="debtors-container">
      <Header
        sticky
        actionsComponent={
          <Button type="submit" highlight small onClick={handleSubmit}>
            Salvar
          </Button>
        }
      >
        <h2 className="title">Novo devedor</h2>
      </Header>

      <div className="content">
        <Form caseId={caseId} formRef={formRef} />
      </div>
    </div>
  );
};

export default Create;
