import { assetOriginOptions } from 'domain/assets';
import { areaUnitOptions } from 'domain/unit';

import React from 'react';

import { FormikProps, getIn } from 'formik';
import { FormContainer, InputField, SelectField, GroupField } from 'ui';
import { AssetAttributes, AssetRuralProperty } from 'types';

import styles from '../../Form.module.scss';

type Props = {
  assetRuralProperty: AssetRuralProperty | null;
  isLoading: boolean;
  formik: FormikProps<AssetAttributes>;
  disabled?: boolean;
};

const RuralPropertyFields = ({
  assetRuralProperty,
  isLoading,
  formik,
  disabled
}: Props) => {
  const getErrors = (field: string) =>
    getIn(formik.touched, field) && getIn(formik.errors, field);

  return (
    <>
      <h3 className={styles.title}>Informações do Grupo</h3>

      <FormContainer className="col-2">
        <InputField
          type="text"
          id="name"
          name="name"
          title="Nome do grupo"
          isLoading={isLoading}
          value={formik.values.name}
          error={getErrors('name')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={disabled}
        />

        <SelectField
          id="assetRuralProperty.origin"
          name="assetRuralProperty.origin"
          value={formik.values?.assetRuralProperty?.origin ?? ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isLoading={isLoading}
          options={assetOriginOptions()}
          title="Origem (opcional)"
          showMode={
            assetRuralProperty ? !assetRuralProperty.origin.isEditable : false
          }
          optional
          error={getErrors('assetRuralProperty.origin')}
          disabled={disabled}
        />

        <GroupField
          id="assetRuralProperty.landArea"
          title="Área do terreno (opcional)"
          error={
            getErrors('assetRuralProperty.landArea') ||
            getErrors('assetRuralProperty.landAreaUnit')
          }
        >
          <InputField
            type="area"
            id="assetRuralProperty.landArea"
            name="assetRuralProperty.landArea"
            isLoading={isLoading}
            value={formik.values.assetRuralProperty?.landArea?.toString()}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            showMode={
              assetRuralProperty
                ? !assetRuralProperty.landArea.isEditable
                : false
            }
            disabled={disabled}
          />
          <SelectField
            dataTestId="landAreaUnit"
            name="assetRuralProperty.landAreaUnit"
            id="assetRuralProperty.landAreaUnit"
            value={formik.values.assetRuralProperty?.landAreaUnit?.toString()}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={isLoading || disabled}
            options={areaUnitOptions()}
            showMode={
              assetRuralProperty
                ? !assetRuralProperty.landAreaUnit.isEditable
                : false
            }
            optional
          />
        </GroupField>

        <GroupField
          id="assetRuralProperty.constructedArea"
          title="Área útil (opcional)"
          error={
            getErrors('assetRuralProperty.constructedArea') ||
            getErrors('assetRuralProperty.constructedAreaUnit')
          }
        >
          <InputField
            type="area"
            id="assetRuralProperty.constructedArea"
            name="assetRuralProperty.constructedArea"
            isLoading={isLoading}
            value={formik.values.assetRuralProperty?.constructedArea?.toString()}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            showMode={
              assetRuralProperty
                ? !assetRuralProperty.constructedArea.isEditable
                : false
            }
            disabled={disabled}
          />
          <SelectField
            dataTestId="constructedAreaUnit"
            name="assetRuralProperty.constructedAreaUnit"
            id="assetRuralProperty.constructedAreaUnit"
            value={formik.values.assetRuralProperty?.constructedAreaUnit?.toString()}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={isLoading || disabled}
            options={areaUnitOptions()}
            showMode={
              assetRuralProperty
                ? !assetRuralProperty.constructedAreaUnit.isEditable
                : false
            }
            optional
          />
        </GroupField>
      </FormContainer>
    </>
  );
};

export default RuralPropertyFields;
