import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Title, Link } from 'ui';
import { resetPassword } from 'api';
import { openErrorToast } from 'ui';
import Form from './Form';

type Props = {
  initialEmail: string;
  showLogin: () => void;
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Digite um e-mail válido')
    .required('Campo obrigatório')
});

const ResetPassword = ({ initialEmail, showLogin }: Props) => {
  const [isSuccess, setSuccess] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: initialEmail
    },
    validationSchema,
    onSubmit: async values => {
      try {
        await resetPassword(values);
        setSuccess(true);
      } catch (err) {
        openErrorToast('Ocorreu um problema ao resetar a senha!');
      }
    }
  });

  return (
    <React.Fragment>
      <Title>Resetar a senha</Title>
      {isSuccess ? (
        <p className="paragraph" data-testid="reset-password-success-message">
          Enviamos instruções para a redefinição de senha no seu email.
        </p>
      ) : (
        <Form
          handleSubmit={formik.handleSubmit}
          touched={formik.touched}
          errors={formik.errors}
          handleOnChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          values={formik.values}
          isSubmitting={formik.isSubmitting}
        />
      )}

      <Link
        onClick={showLogin}
        iconName="arrow-left"
        highlight
        data-testid="go-back-to-login"
      >
        Voltar
      </Link>
    </React.Fragment>
  );
};

export default ResetPassword;
