import { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { LinkLocation } from 'ui/Link/Link';
import { LocationState } from 'types';

type Navigation = {
  history: ReturnType<typeof useHistory>;
  location: ReturnType<typeof useLocation>;
};

export function useInitNavigation(
  initReturnTo: (pathname: string) => string[],
  onRouteChange?: ({
    currentPathname,
    currentState
  }: {
    currentPathname: string;
    currentState: LinkLocation['state'] & { returnTo?: string[] };
  }) => LinkLocation['state']
): Navigation {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const navigationRef = useRef<LinkLocation>({
    pathname: location.pathname,
    hash: location.hash,
    state: {
      ...location.state,
      returnTo: initReturnTo(location.pathname)
    }
  });

  useEffect(() => {
    history.replace(navigationRef.current);
  }, [history]);

  useEffect(() => {
    if (location.pathname !== navigationRef.current.pathname) {
      navigationRef.current = {
        pathname: location.pathname,
        hash: location.hash,
        state: {
          ...(onRouteChange?.({
            currentPathname: location.pathname,
            currentState: navigationRef.current.state
          }) ?? navigationRef.current.state)
        }
      };

      history.replace(navigationRef.current);
    }
  }, [history, location, onRouteChange]);

  return {
    history,
    location
  };
}
