import React, { useState } from 'react';
import ReactModal from 'react-modal';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Thumbs, Navigation, Swiper as SwiperType } from 'swiper';

import { Button, Icon, Link } from 'ui';

import { ReducerImage } from '../types';

import 'swiper/swiper.scss';
import style from './Gallery.module.scss';

type Props = {
  images: ReducerImage[];
  isOpen: boolean;
  onClose: () => void;
  onDelete: (imageId: string) => void;
  currentActiveIndex?: number;
  showDelete?: boolean;
};

SwiperCore.use([Navigation, Thumbs]);

const Gallery = ({
  isOpen,
  onClose,
  images,
  onDelete,
  currentActiveIndex = 0,
  showDelete = true
}: Props) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType>();
  const [currentActiveImage, setCurrentActiveImage] = useState(
    currentActiveIndex
  );

  const handlesDelete = (imageId?: string) => () => {
    imageId && onDelete(imageId);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      className={style.modalBox}
      ariaHideApp={false}
      overlayClassName={style.modalOverlay}
      onRequestClose={onClose}
    >
      <div className={style.swiperContainer} data-testid="gallery-modal">
        <div className={style.modalClose} onClick={onClose}>
          <Icon name="close" fill="#fff" />
        </div>

        <div className={style.galleryWrapper}>
          <span className={`prev ${style.galleryNavigation}`}>
            <Icon name="arrow-left" fill="#fff" />
          </span>

          <Swiper
            autoHeight
            initialSlide={currentActiveImage}
            className={style.gallery}
            slidesPerView={1}
            navigation={{
              nextEl: '.next',
              prevEl: '.prev'
            }}
            thumbs={{
              swiper: thumbsSwiper,
              slideThumbActiveClass: style.carouselThumbnailActive
            }}
            onSlideChange={swiper => setCurrentActiveImage(swiper.activeIndex)}
          >
            {images.map((image, index) => {
              return (
                <SwiperSlide key={index} className={style.imageSwiper}>
                  <img
                    src={image.signedUrl}
                    alt={image.name}
                    className={style.galleryImage}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>

          <span className={`next ${style.galleryNavigation}`}>
            <Icon name="arrow-right" fill="#fff" />
          </span>
        </div>

        <div className={style.actions}>
          {showDelete ? (
            <Button
              small
              outline
              danger
              onClick={handlesDelete(images[currentActiveImage]?.id)}
            >
              Excluir
            </Button>
          ) : (
            <div />
          )}

          <span className={style.totalSlides}>
            {currentActiveImage + 1}/{images.length}
          </span>

          <Link
            href={images[currentActiveImage]?.signedUrl}
            target="_blank"
            dataTestId="download-trigger"
            small
            outline
            asButton
          >
            Download
          </Link>
        </div>

        <div className={style.carouselThumbsContainer}>
          <Swiper
            centerInsufficientSlides
            spaceBetween={32}
            slidesPerView={'auto'}
            onSwiper={setThumbsSwiper}
            watchSlidesVisibility
            watchSlidesProgress
            slideToClickedSlide
          >
            {images.map((image, index) => (
              <SwiperSlide
                key={index}
                className={style.carouselThumbnailWrapper}
              >
                <img
                  className={style.carouselThumbnail}
                  src={image.signedUrl}
                  alt={image.name}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </ReactModal>
  );
};

export default Gallery;
