import { ERROR_MESSAGE, ReportAssetName } from 'domain/reports';
import {
  mountReportAddress,
  ReportLocationTypeDictionary
} from 'domain/reportLocation';
import { buildPaginationParams } from 'domain/pagination';
import React from 'react';
import size from 'lodash/size';

import {
  Accordion,
  DropDown,
  Card,
  openErrorToast,
  openSuccessToast,
  DynamicTable,
  TablePositionModifier,
  Stack,
  StackMargin
} from 'ui';
import { FormerValueTooltip, ProviderTooltip, SummaryError } from 'components';
import { applyMask, handleNullValue } from 'utils';
import * as Actions from 'utils/actions';
import compact from 'lodash/compact';
import {
  fetchReportLocations,
  fetchReportLocationsSummary,
  addLocationsToCase,
  createReportFromLocations
} from 'api';
import { openReportTab } from 'pages/reports/utils';
import { ActionName, ActionEntity, ReportLocation } from 'types';
import { useQuery } from 'hooks';

import SummaryTitle from 'pages/reports/SharedComponents/SummaryTitle';
import ReportDynamicTable from 'pages/reports/SharedComponents/ReportDynamicTable/ReportDynamicTable';

import style from './LocationsTable.module.scss';

type Props = {
  caseId: string;
  reportId: string;
  addToCase?: boolean;
  research?: boolean;
  isSummary?: boolean;
  providerStatus?: string;
  tableMessage?: string;
};

const LocationsTable = ({
  isSummary,
  caseId,
  reportId,
  addToCase = true,
  research = true,
  providerStatus,
  tableMessage
}: Props) => {
  const query = isSummary ? fetchReportLocationsSummary : fetchReportLocations;

  const {
    data: locations = [],
    error,
    isLoading,
    pagination,
    refetch
  } = useQuery(query, {
    caseId,
    reportId
  });

  if (error) {
    return (
      <SummaryError title="Localizações" message={ERROR_MESSAGE.locations} />
    );
  }

  const handleSearch = (locationId: ReportLocation['id']) => async () => {
    try {
      const response = await createReportFromLocations({
        caseId,
        reportId,
        contentId: locationId
      });

      openSuccessToast('Consulta salva com sucesso!');
      openReportTab(response.data);
    } catch (err) {
      openErrorToast('Houve um erro ao criar a consulta.');
    }
  };

  const handleAddToCase = (locationId: ReportLocation['id']) => async () => {
    try {
      await addLocationsToCase({
        caseId,
        reportId,
        contentId: locationId
      });

      openSuccessToast('Localização adicionada ao caso com sucesso');
    } catch (err) {
      openErrorToast('Não foi possivel adicionar a localização ao caso');
    }
  };

  const isEmpty = locations.length < 1;

  const buildActions = ({ id, field: { actions } }: ReportLocation) => {
    const createReportAction = {
      text: 'Buscar',
      callback: handleSearch(id),
      isDisabled: Actions.isDisabled(actions, ActionName.CREATE_REPORT),
      description: Actions.unavailableReasons(
        actions,
        ActionName.CREATE_REPORT,
        ActionEntity.LOCATION
      )
    };

    const addToCaseAction = {
      text: 'Adicionar ao caso',
      callback: handleAddToCase(id),
      isDisabled: Actions.isDisabled(actions, ActionName.ADD_TO_CASE),
      description: Actions.unavailableReasons(
        actions,
        ActionName.ADD_TO_CASE,
        ActionEntity.LOCATION
      ),
      dataTestId: 'add-to-case'
    };

    const allActionsAllowed = compact([
      addToCase && addToCaseAction,
      research && createReportAction
    ]);

    return allActionsAllowed;
  };

  const columns = [
    {
      name: 'Tipo',
      columnKey: 'type',
      render: (item: ReportLocation) => (
        <FormerValueTooltip
          fieldName="type"
          fieldValue={ReportLocationTypeDictionary[item.type]}
          history={item.field.history}
        />
      )
    },
    {
      name: 'CNPJ',
      columnKey: 'cnpj',
      render: (item: ReportLocation) => (
        <FormerValueTooltip
          fieldName="cnpj"
          fieldValue={item.cnpj}
          history={item.field.history}
          formatter={value => applyMask('cnpj', value)}
        />
      )
    },
    {
      name: 'Razão social',
      columnKey: 'businessName',
      render: (item: ReportLocation) => (
        <FormerValueTooltip
          fieldName="businessName"
          fieldValue={handleNullValue(item.businessName)}
          history={item.field.history}
        />
      )
    },
    {
      name: 'Endereço',
      columnKey: 'street',
      render: (item: ReportLocation) => (
        <FormerValueTooltip
          fieldName="street"
          fieldValue={mountReportAddress(item.address, 'street')}
          history={item.field.history}
        />
      )
    },
    {
      name: 'Estado',
      columnKey: 'cityState',
      render: (item: ReportLocation) => (
        <FormerValueTooltip
          fieldName="cityState"
          fieldValue={
            item.address?.abroadCity ? 'Exterior' : item.address?.city?.state
          }
          history={item.field.history}
        />
      )
    },
    {
      name: 'Município',
      columnKey: 'cityName',
      render: (item: ReportLocation) => (
        <FormerValueTooltip
          fieldName="cityName"
          fieldValue={item.address?.abroadCity ?? item.address?.city?.name}
          history={item.field.history}
        />
      )
    }
  ];

  const actionColumn = [
    {
      positionModifier: TablePositionModifier.RIGHT,
      hasActions: true,
      name: '',
      columnKey: 'actions',
      render: (item: ReportLocation) => (
        <>
          <ProviderTooltip
            searchedByHomonym={item.field.searchedByHomonym}
            providers={item.field.providers}
            dataTestId="provider-tooltip"
          />
          {buildActions(item).length > 0 && (
            <DropDown options={buildActions(item)} />
          )}
        </>
      )
    }
  ];

  const rowKey = (item: ReportLocation) => item.id;

  if (isSummary) {
    return (
      <>
        <Stack marginBottom={StackMargin.SMALL}>
          <SummaryTitle>{`Localizações (${size(locations)})`}</SummaryTitle>
        </Stack>
        <DynamicTable
          columns={columns}
          data={locations}
          rowKey={rowKey}
          getRowClassName={() => style.summaryTableRow}
          noAutoPaginate
          isLoading={isLoading}
        />
      </>
    );
  }

  const refetchWithPagination = (pagination?: {
    page: number;
    pageSize: number;
  }) => {
    refetch({
      caseId,
      reportId,
      pagination
    });
  };

  const paginationProps = buildPaginationParams(
    pagination,
    refetchWithPagination
  );

  return (
    <Accordion
      title="Localizações"
      badgeContent={pagination?.totalEntries}
      tooltipText={providerStatus}
      isClosed={isEmpty && !tableMessage}
    >
      <Card small>
        <ReportDynamicTable
          columns={[...columns, ...actionColumn]}
          data={locations}
          rowKey={rowKey}
          isLoading={isLoading}
          pagination={paginationProps}
          emptyText={tableMessage}
          reportAssetName={ReportAssetName.LOCATION}
        />
      </Card>
    </Accordion>
  );
};

export default LocationsTable;
