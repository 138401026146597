import React, { useEffect, useState } from 'react';
import { getIn, useFormik } from 'formik';

import * as Yup from 'yup';

import {
  FormContainer,
  InputField,
  SelectField,
  openErrorToast,
  openSuccessToast,
  Button,
  Stack,
  StackMargin
} from 'ui';

import { User, UserFormAttributes, OnSubmit, ProfileItem } from 'types';

import { fetchProfiles, inviteUser, updateUser } from 'api';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(255, 'Deve ter no máximo 255 caractere(s)')
    .matches(/.+\s.+/, { message: 'Precisa ter no mínimo duas palavras' })
    .matches(/^\D+$/, { message: 'Não deve conter número' })
    .required('Campo obrigatório'),
  email: Yup.string()
    .required('Campo obrigatório')
    .max(255, 'Deve ter no máximo 255 caractere(s)')
    .email('Digite um e-mail válido'),
  profileId: Yup.string().required('Campo obrigatório')
});

type Props = {
  handleSave: (user: User) => void;
  formData?: User;
  isNameAndEmailDisabled?: boolean;
};

const Form = ({
  handleSave,
  formData,
  isNameAndEmailDisabled = false
}: Props) => {
  const [profilesList, setProfilesList] = useState<ProfileItem[]>([]);

  useEffect(() => {
    const fetchProfilesList = async () => {
      try {
        const response = await fetchProfiles();

        setProfilesList(response.data);
      } catch {
        openErrorToast(
          'Ocorreu um erro na listagem de perfis. Atualize a página e tente novamente.'
        );
      }
    };

    fetchProfilesList();
  }, []);

  const handleUpdate: OnSubmit<UserFormAttributes> = async (
    userData,
    formikHelpers
  ): Promise<void> => {
    if (!formData) return;

    try {
      const response = await updateUser(formData.id, { ...userData });
      openSuccessToast('Usuário atualizado com sucesso');
      handleSave(response.data);
    } catch (error) {
      openErrorToast('Houve um erro ao atualizar usuário');
      formikHelpers.setErrors(error.errors);
    }
  };

  const handleInvite: OnSubmit<UserFormAttributes> = async (
    userData,
    formikHelpers
  ): Promise<void> => {
    try {
      const response = await inviteUser({ ...userData });
      openSuccessToast('Convite enviado com sucesso');
      handleSave(response.data);
    } catch (error) {
      openErrorToast('Houve um erro ao enviar o convite');
      formikHelpers.setErrors(error.errors);
    }
  };

  const onSubmit: OnSubmit<UserFormAttributes> = (userData, formikHelpers) => {
    if (formData) {
      handleUpdate(userData, formikHelpers);
    } else {
      handleInvite(userData, formikHelpers);
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues: {
      name: formData?.name || '',
      email: formData?.email || '',
      profileId: formData?.profile.id || ''
    },
    onSubmit
  });

  return (
    <form onSubmit={formik.handleSubmit} data-testid="invitation-form">
      <FormContainer>
        <InputField
          dataTestId="input-name"
          error={formik.touched.name && getIn(formik.errors, 'name')}
          id="invitation-name"
          name="name"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          title="Nome"
          type="text"
          value={formik.values.name}
          disabled={formData && isNameAndEmailDisabled}
        />

        <InputField
          dataTestId="input-email"
          error={formik.touched.email && getIn(formik.errors, 'email')}
          id="invitation-email"
          name="email"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          title="E-mail"
          type="text"
          value={formik.values.email}
          disabled={formData && isNameAndEmailDisabled}
        />

        <SelectField
          dataTestId="select-profile"
          id="profileId"
          name="profileId"
          value={formik.values.profileId}
          title="Perfil"
          error={formik.touched.profileId && getIn(formik.errors, 'profileId')}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          options={profilesList.map(profile => ({
            label: profile.name,
            value: profile.id,
            disabled: profile.enabledAmount <= 0
          }))}
        />
      </FormContainer>
      <Stack marginTop={StackMargin.MEDIUM}>
        <Button
          disabled={!formik.isValid || !formik.dirty}
          highlight
          centered
          onClick={formik.handleSubmit}
          type="submit"
        >
          {formData ? 'Salvar' : 'Convidar'}
        </Button>
      </Stack>
    </form>
  );
};

export default Form;
