import React, { useState } from 'react';

import { Movement, ExternalLawsuitMovement } from 'types';
import { Button, Modal, Stack, StackMargin } from 'ui';

import InfoDetail from './InfoDetail';
import AttachmentItem from './AttachmentItem';

import './MovementTimelineContent.scss';

type Props = {
  movement: Movement | ExternalLawsuitMovement;
  caseId: string;
  showDescription?: boolean;
  showMovementFiles?: boolean;
};

const MovementTimelineContent = ({
  movement,
  caseId,
  showDescription = true,
  showMovementFiles = true
}: Props) => {
  const [isContentModalOpen, toggleContentModal] = useState<boolean>(false);
  const [isAttachmentModalOpen, toggleAttachmentModal] = useState<boolean>(
    false
  );

  const handleContentModalToggle = () => {
    toggleContentModal(!isContentModalOpen);
  };

  const handleAttachmentModalToggle = () => {
    toggleAttachmentModal(!isAttachmentModalOpen);
  };

  return (
    <div className="timeline-content">
      <InfoDetail
        caseId={caseId}
        movement={movement}
        showDescription={showDescription}
        showMovementFiles={showMovementFiles}
      />

      <div className="actions">
        {movement.content && (
          <Button
            onClick={handleContentModalToggle}
            icon="eye"
            className="content-button"
            borderless
            highlight
            small
          >
            Ver conteúdo
          </Button>
        )}

        {movement.attachments.length > 0 && (
          <Button
            onClick={handleAttachmentModalToggle}
            icon="attach"
            borderless
            highlight
            small
          >
            Ver anexos
          </Button>
        )}
      </div>

      {isContentModalOpen && (
        <Modal
          title={
            <>
              <span>Conteúdo da movimentação</span> {movement.title}
            </>
          }
          isOpen={isContentModalOpen}
          onClose={handleContentModalToggle}
          large
        >
          <p data-testid="modal-content-movement">{movement.content}</p>
        </Modal>
      )}

      {isAttachmentModalOpen && (
        <Modal
          title="Anexos"
          isOpen={isAttachmentModalOpen}
          onClose={handleAttachmentModalToggle}
        >
          <ul className="attachment-list" data-testid="attachment-list">
            {movement.attachments.map((attachment, index) => (
              <AttachmentItem key={index} attachment={attachment} />
            ))}
          </ul>
          <Stack marginTop={StackMargin.LARGE}>
            <Button onClick={handleAttachmentModalToggle} highlight centered>
              Voltar
            </Button>
          </Stack>
        </Modal>
      )}
    </div>
  );
};

export default MovementTimelineContent;
