import { causeLabel } from 'domain/lawsuits';
import React, { useState } from 'react';
import { FieldArrayRenderProps, FormikProps, getIn } from 'formik';
import cn from 'classnames';

import {
  ChildLawsuitAttributes,
  InternalLawsuitAttributes,
  Lawyer,
  LawyerCauseType
} from 'types';
import { Button, Card, Checkbox, Modal, Table } from 'ui';
import Form from './Form';

import style from './Lawyers.module.scss';

type Props = {
  formikArrayHelpers: FieldArrayRenderProps;
  formik:
    | FormikProps<ChildLawsuitAttributes>
    | FormikProps<InternalLawsuitAttributes>;
  disabled?: boolean;
  showAdd?: boolean;
  showDelete?: boolean;
  showEdit?: boolean;
};

const Lawyers = ({
  formikArrayHelpers,
  formik,
  disabled = false,
  showAdd = true,
  showDelete = true,
  showEdit = true
}: Props) => {
  const [selectedLawyersIndexes, setSelectedLawyersIndexes] = useState<
    Array<number>
  >([]);
  const isDeleteDisabled = selectedLawyersIndexes.length === 0;
  const isLawyersEmpty = formik.values.lawyers.length === 0;
  const lawyersErrors = getIn(formik.errors, 'lawyers');

  const hasDuplicatedLawyer =
    lawyersErrors ===
    'Remova os advogados duplicados antes de salvar o processo';

  const duplicatedNames = new Set(
    hasDuplicatedLawyer
      ? formik.values.lawyers
          .map((lawyer: Lawyer) => lawyer.name)
          .filter((name, index, names) => names.indexOf(name) !== index)
      : []
  );

  const handleCheckboxChange = (itemIndex: number) => {
    const selectedItemIndex = selectedLawyersIndexes.indexOf(itemIndex);
    const isAlreadySelected = selectedItemIndex !== -1;
    const lawyersIndexes = [...selectedLawyersIndexes];

    if (isAlreadySelected) {
      lawyersIndexes.splice(selectedItemIndex, 1);
    } else {
      lawyersIndexes.push(itemIndex);
    }

    setSelectedLawyersIndexes(lawyersIndexes);
  };

  const handleDeleteSelected = () => {
    const lawyers = formik.values.lawyers.filter(
      (_lawyer, index) => !selectedLawyersIndexes.includes(index)
    );

    formik.setFieldValue('lawyers', [...lawyers]);

    setSelectedLawyersIndexes([]);
  };

  const [lawyerModalOpen, setLawyerModalOpen] = useState<boolean>(false);
  const [lawyerIndex, setLawyerIndex] = useState<number>();
  const selectedLawyer =
    lawyerIndex === undefined ? undefined : formik.values.lawyers[lawyerIndex];

  function openLawyerModal(lawyerIndex?: number): void {
    lawyerIndex === undefined
      ? setLawyerIndex(undefined)
      : setLawyerIndex(lawyerIndex);
    setLawyerModalOpen(true);
  }

  function closeLawyerModal(): void {
    setLawyerIndex(undefined);
    setLawyerModalOpen(false);
  }

  function handleSave(lawyer: Lawyer, lawyerIndex?: number): void {
    if (lawyerIndex === undefined) {
      formikArrayHelpers.push(lawyer);
    } else {
      formikArrayHelpers.replace(lawyerIndex, lawyer);
    }

    closeLawyerModal();
  }

  function handleDelete(lawyerIndex: number): void {
    formikArrayHelpers.remove(lawyerIndex);
    closeLawyerModal();
  }

  return (
    <>
      <p className={style.label}>Advogados</p>
      <Card dataTestId="lawyers-card" small>
        <Table className={style.table}>
          <Table.Header>
            <Table.Row small>
              <Table.Cell className={style.checkboxCell}></Table.Cell>
              <Table.Cell>Nome completo</Table.Cell>
              <Table.Cell>Parte</Table.Cell>
              <Table.Cell className={style.actionCell}></Table.Cell>
            </Table.Row>
          </Table.Header>
          <Table.Body
            isEmpty={isLawyersEmpty}
            emptyText="Nenhum advogado neste processo"
            columns={4}
          >
            {formik.values.lawyers.map((lawyer, index) => (
              <Table.Row
                key={lawyer.name.replace(/\s/g, '') + index}
                className={cn({
                  [style.hasError]: duplicatedNames.has(lawyer.name)
                })}
              >
                <Table.Cell centeredContent className={style.checkboxCell}>
                  {showDelete ? (
                    <Checkbox
                      id={`lawyer-${lawyer.name.replace(/\s/g, '')}` + index}
                      name={`lawyer-${lawyer.name.replace(/\s/g, '')}`}
                      value={lawyer.name}
                      onChange={() => handleCheckboxChange(index)}
                      checked={selectedLawyersIndexes.includes(index)}
                    />
                  ) : (
                    <></>
                  )}
                </Table.Cell>
                <Table.Cell centeredContent>{lawyer.name}</Table.Cell>
                <Table.Cell centeredContent>
                  {(lawyer.cause &&
                    causeLabel(lawyer.cause as LawyerCauseType)) ||
                    ''}
                </Table.Cell>
                <Table.Cell
                  centeredContent
                  className={style.actionCell}
                  textAlign="right"
                >
                  {showEdit ? (
                    <Button
                      icon="arrow-right"
                      small
                      dataTestId={`edit-button-${lawyer.name}`}
                      className={style.icon}
                      borderless
                      onClick={() => openLawyerModal(index)}
                    ></Button>
                  ) : (
                    <></>
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        {hasDuplicatedLawyer && (
          <p className={style.errorMessage}>{lawyersErrors}</p>
        )}

        <div className={style.actions}>
          {showAdd && (
            <Button
              highlight
              outline
              small
              disabled={disabled}
              onClick={() => openLawyerModal()}
            >
              Adicionar advogado
            </Button>
          )}
          {showDelete && (
            <Button
              highlight
              outline
              danger
              small
              disabled={isDeleteDisabled || disabled}
              onClick={handleDeleteSelected}
            >
              Excluir advogados selecionados
            </Button>
          )}
        </div>
      </Card>

      <Modal
        isOpen={lawyerModalOpen}
        onClose={closeLawyerModal}
        dataTestId="lawyer-modal"
        title={selectedLawyer ? 'Editar advogado' : 'Adicionar advogado'}
      >
        <Form
          lawyer={selectedLawyer}
          lawyerIndex={lawyerIndex}
          onSave={handleSave}
          onDelete={handleDelete}
          onCancel={closeLawyerModal}
        />
      </Modal>
    </>
  );
};

export default Lawyers;
