import { providerNames } from 'domain/providers';
import React from 'react';
import uniq from 'lodash/uniq';
import { joinArray } from 'utils';
import { IconTooltip } from 'ui';
import { ProviderName } from 'types';

type Props = {
  providers?: ProviderName[];
  dataTestId?: string;
  searchedByHomonym?: boolean;
  extraText?: string;
  className?: string;
};

const ProviderTooltip = ({
  providers = [],
  searchedByHomonym = false,
  dataTestId = 'provider-tooltip',
  extraText,
  className
}: Props) => {
  const uniqProvider = uniq(providers);

  if (!uniqProvider.length) return null;

  const uniqProviderNames = providerNames(uniqProvider);

  let text =
    uniqProviderNames!.length > 1
      ? `Provedores: ${joinArray(uniqProviderNames)}`
      : `Provedor: ${joinArray(uniqProviderNames)}`;

  if (extraText) {
    text = text + `\n\n${extraText}`;
  }

  if (searchedByHomonym) {
    return (
      <IconTooltip
        text={text + '\nBusca feita por homônimo'}
        dataTestId={dataTestId}
        icon="warning-triangle"
        color="#E57373"
        className={className}
      />
    );
  }

  return (
    <IconTooltip text={text} dataTestId={dataTestId} className={className} />
  );
};

export default ProviderTooltip;
