import React, { ReactNode } from 'react';
import { Title } from 'ui';

import style from './SummaryTitle.module.scss';

type Props = {
  children: ReactNode;
  headerInfo?: {
    label: string;
    value: string;
  };
  headerActions?: ReactNode;
};

const SummaryTitle = ({ children, headerInfo, headerActions }: Props) => {
  return (
    <div className={style.header}>
      <Title className={style.title} actions={headerActions}>
        {children}
      </Title>
      {headerInfo && (
        <h3 className={style.info}>
          {headerInfo.label}
          <span className={style.value}>{headerInfo.value}</span>
        </h3>
      )}
    </div>
  );
};

export default SummaryTitle;
