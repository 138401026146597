import { Entity } from 'types/entity';
import { Guarantee } from 'types/guarantee';
import { FileUpload } from './file';
import { Reclaiming } from './reclaiming';
import { City } from './city';
import { AreaUnit } from './unit';
import { Execution } from './execution';

export enum AssetType {
  AIRPLANE = 'airplane',
  REAL_ESTATE = 'real_estate',
  RURAL_PROPERTY = 'rural_property',
  VEHICLE = 'vehicle',
  OTHER = 'other'
}

export enum AssetManualStatus {
  AVAILABLE = 'available',
  GUARANTEED_BY_THIRD_PARTY = 'guaranteed_by_third_party',
  EXECUTED_BY_THIRD_PARTY = 'executed_by_third_party',
  UNAVAILABLE = 'unavailable'
}

export enum AssetAutoStatus {
  EXECUTED = 'executed',
  GUARANTEED = 'guaranteed',
  RECLAIMED = 'reclaimed'
}

export enum AssetOrigin {
  PAYMENT_GRANT = 'payment_grant',
  COURT_ORDER = 'court_order',
  CONSOLIDATION = 'consolidation'
}

export enum AssetRealEstateType {
  LAND = 'land',
  ALLOTMENT = 'allotment',
  APARTMENT = 'apartment',
  APARTMENT_HOTEL = 'apartment_hotel',
  FLAT = 'flat',
  HOUSE = 'house',
  OFFICE = 'office',
  COMMERCIAL_STORE = 'commercial_store',
  COMMERCIAL_BUILDING = 'commercial_building',
  INDUSTRIAL_HANGAR = 'industrial_hangar',
  SCHOOL = 'school',
  DISTRIBUTION_CENTER = 'distribution_center',
  CONVENTION_CENTER = 'convention_center'
}

export enum AssetRuralPropertyType {
  FARM = 'farm',
  SMALL_FARM = 'small_farm',
  RANCH = 'ranch',
  RURAL_LOT = 'rural_lot'
}

export enum AssetOptions {
  SHOW_REPORT = 'Ver relatório',
  SHOW_ORIGIN = 'Origem do suspeito',
  COPY_LINE = 'Copiar linha',
  DELETE = 'Excluir',
  DELETE_FROM_GROUP = 'Remover do grupo',
  DELETE_GROUP = 'Excluir grupo',
  SHOW_GROUP = 'Ver grupo'
}

export type AssetTotalValue = { totalValue: number };

export type AssetRealEstateAttribute = {
  id: string | null;
  address: string | null;
  constructedArea: number | null;
  landArea: number | null;
  number: number | null;
  postalCode: string | null;
  registration: number | null;
  cityId: string;
  state: string;
  origin: AssetOrigin | null;
  occupied: boolean | null;
  type: AssetRealEstateType | null;
};

export type AssetRuralPropertyAttribute = {
  id: string | null;
  address: string | null;
  detentionPercentage: number | null;
  incraCode: string | null;
  landArea: number | null;
  landAreaUnit: AreaUnit | null;
  constructedArea: number | null;
  constructedAreaUnit: AreaUnit | null;
  nirfCodes: string[] | null;
  registration: string | null;
  number: string | null;
  postalCode: string | null;
  cityId: string;
  state: string;
  origin: AssetOrigin | null;
  occupied: boolean | null;
  type: AssetRealEstateType | null;
};

export type AssetVehicleAttribute = {
  id: string | null;
  brandAndModel: string | null;
  serialNumber: string | null;
  yearOfManufacture: number | null;
  renavam: string | null;
  modelYear: number | null;
};

export type AssetOtherAttribute = {
  id: string | null;
  quantity: number | null;
};

export type AssetAirplaneAttribute = {
  id: string | null;
  engineSpecification: string | null;
  manufacturer: string | null;
  model: string | null;
  serialNumber: string | null;
  seatingCapacity: number | null;
  yearOfManufacture: number | null;
  rabSituation: string | null;
};

export type Appraisal = {
  id: string;
  date: string;
  valueCents: number;
  companyName: string | null;
  marketValueCents: number | null;
};

type AssetBase = {
  comment: string;
  entity: Entity;
  identification: string | null;
  isEditable: boolean;
  type: AssetType;
  name: string;
  autoStatus: AssetAutoStatus[];
  manualStatus: AssetManualStatus[];
  appraisals: Appraisal[];
  assetGroupId?: string | null;
  appraisalComment: string | null;
};

export type AssetAttributes = AssetBase & {
  id: string;
  documentFileUploadIds: string[];
  reclaimingIds: string[];
  galleryFileUploadIds: string[];
  assetRealEstate: AssetRealEstateAttribute | null;
  assetVehicle: AssetVehicleAttribute | null;
  assetOther: AssetOtherAttribute | null;
  assetAirplane: AssetAirplaneAttribute | null;
  assetRuralProperty: AssetRuralPropertyAttribute | null;
  guaranteeId?: string | null;
};

export type EditableContent<T> = {
  value: T;
  isEditable: boolean;
};

export type AssetRealEstate = {
  address: EditableContent<string | null>;
  city: EditableContent<City | null>;
  constructedArea: EditableContent<number | null>;
  id: EditableContent<string>;
  landArea: EditableContent<number | null>;
  number: EditableContent<number | null>;
  postalCode: EditableContent<string | null>;
  registration: EditableContent<number | null>;
  state: EditableContent<string | null>;
  origin: EditableContent<AssetOrigin | null>;
  occupied: EditableContent<boolean | null>;
  type: EditableContent<AssetRealEstateType | null>;
};

export type AssetVehicle = {
  brandAndModel: EditableContent<string | null>;
  id: EditableContent<string>;
  serialNumber: EditableContent<string | null>;
  yearOfManufacture: EditableContent<number | null>;
  renavam: EditableContent<string | null>;
  modelYear: EditableContent<number | null>;
  identification: EditableContent<string | null>;
};

export type AssetOther = {
  id: EditableContent<string>;
  quantity: EditableContent<number | null>;
};

export type AssetAirplane = {
  id: EditableContent<string>;
  engineSpecification: EditableContent<string | null>;
  manufacturer: EditableContent<string | null>;
  model: EditableContent<string | null>;
  rabSituation: EditableContent<string | null>;
  serialNumber: EditableContent<string | null>;
  seatingCapacity: EditableContent<number | null>;
  yearOfManufacture: EditableContent<number | null>;
};

export type AssetRuralProperty = {
  id: EditableContent<string>;
  address: EditableContent<string | null>;
  city: EditableContent<City | null>;
  constructedArea: EditableContent<number | null>;
  constructedAreaUnit: EditableContent<AreaUnit | null>;
  detentionPercentage: EditableContent<number | null>;
  incraCode: EditableContent<string | null>;
  landArea: EditableContent<number | null>;
  landAreaUnit: EditableContent<AreaUnit | null>;
  nirfCodes: EditableContent<string[] | null>;
  registration: EditableContent<number | null>;
  number: EditableContent<number | null>;
  postalCode: EditableContent<string | null>;
  state: EditableContent<string | null>;
  origin: EditableContent<AssetOrigin | null>;
  occupied: EditableContent<boolean | null>;
  type: EditableContent<AssetRuralPropertyType | null>;
};

export type Asset = AssetBase & {
  id: string;
  caseId: string;
  caseNegotiatorId?: string;
  providerValueCents: number | null;
  reclaimings: Reclaiming[];
  guarantee: Guarantee | null;
  galleryFileUploads: FileUpload[];
  documentFileUploads: FileUpload[];
  assetRealEstate: AssetRealEstate | null;
  assetVehicle: AssetVehicle | null;
  assetOther: AssetOther | null;
  assetAirplane: AssetAirplane | null;
  assetRuralProperty: AssetRuralProperty | null;
  identificationLocked: boolean;
  executions: Execution[];
  isGroup: boolean;
  groupedAssets: Asset[];
  assetGroup: Asset | null;
};

export type AssetMissingDataAttribute = Pick<
  AssetBase,
  'type' | 'name' | 'identification'
> & {
  assetRealEstate: Pick<
    AssetRealEstateAttribute,
    'type' | 'occupied' | 'origin' | 'id'
  > | null;
  assetVehicle: AssetVehicleAttribute | null;
  assetOther: AssetOtherAttribute | null;
  assetAirplane: AssetAirplaneAttribute | null;
  assetRuralProperty: Pick<
    AssetRuralPropertyAttribute,
    'type' | 'occupied' | 'origin' | 'id'
  > | null;
};
