import { entityName, entityDocument, entityRolesToString } from 'domain/entity';
import { mountReportAddress } from 'domain/reportLocation';
import React from 'react';

import { fetchEntitiesReports } from 'api';
import { useQuery, useToggles } from 'hooks';

import { ReportStatus, SummaryError } from 'components';
import { Card, Stack, StackMargin, RadioSwitch } from 'ui';

import {
  EntityWithReports,
  EntityType,
  ReportAddress,
  ReportFields
} from 'types';

import { applyMask, reportFieldFirstValue } from 'utils';

import SummaryLoader from '../SummaryLoader';
import ContentBadges from './ContentBadges/ContentBadges';

import style from './Suspects.module.scss';

type Props = {
  caseId: string;
};

const Suspects = ({ caseId }: Props) => {
  const { toggle, isOpen } = useToggles<'fullView'>({ fullView: false });

  const { data: suspects = [], error, isLoading } = useQuery<
    EntityWithReports[],
    Pick<EntityWithReports, 'caseId'>
  >(fetchEntitiesReports, {
    caseId
  });

  if (isLoading) return <SummaryLoader title="Suspeitos" />;

  if (error)
    return (
      <SummaryError
        title="Suspeitos"
        message="Erro ao listar suspeitos, tente novamente."
      />
    );

  const customHeader = (entity: EntityWithReports) => {
    const { roles: entityRoles, type } = entity;
    const repo = entity.currentReport;
    const address = mountReportAddress(
      reportFieldFirstValue(repo?.individual?.address || repo?.company?.address)
    );

    const mergeEqualValues = (
      acc: ReportFields<string>[],
      current: ReportFields<string>
    ) => {
      const equalItemIndex: number = acc.findIndex(
        item => item.fieldValue === current.fieldValue
      );

      if (equalItemIndex === -1)
        return [
          ...acc,
          {
            fieldValue: current.fieldValue,
            providers: current.providers
          }
        ];

      acc[equalItemIndex].providers = [
        ...acc[equalItemIndex].providers,
        ...current.providers
      ].sort((provider1, provider2) => provider2.localeCompare(provider1));

      return acc;
    };

    const mountAllPostalCodes = (
      addresses: ReportFields<ReportAddress>[]
    ): ReportFields<string>[] =>
      addresses
        .map(addr => ({
          fieldValue: applyMask('cep', addr.fieldValue.postalCode || ''),
          providers: addr.providers
        }))
        .reduce<ReportFields<string>[]>(
          (acc, current) => mergeEqualValues(acc, current),
          []
        );

    const postalCodeFields = mountAllPostalCodes(
      repo?.company?.address || repo?.individual?.address || []
    );
    const cep = reportFieldFirstValue(postalCodeFields);
    const phones = reportFieldFirstValue(
      repo?.individual?.phones || repo?.company?.phones
    )?.map(phone => applyMask('phone', phone));

    const documentLabel = {
      [EntityType.INDIVIDUAL]: 'CPF',
      [EntityType.COMPANY]: 'CNPJ'
    }[type];

    const roles = entityRolesToString(entityRoles, value => `(${value})`);

    return (
      <div>
        <div>
          <div className={style.suspectWrapper}>
            <div className={style.suspect}>
              <span className={style.name}>{entityName(entity)}</span>
              <span>{roles}</span>
            </div>
            <span className={style.document}>
              {`${documentLabel}: ${entityDocument(entity)}`}
            </span>
          </div>
        </div>
        <p></p>
        <div>
          <h5>
            Endereço: {address} - CEP: {cep}
          </h5>
          <h5>Telefone(s): {phones} </h5>
        </div>
        <p></p>
      </div>
    );
  };

  return (
    <Stack marginTop={StackMargin.XLARGE}>
      <div className={style.titleWrapper}>
        <h3 className={style.title}>{`Suspeitos (${suspects.length})`}</h3>

        <div className={style.fullView}>
          <RadioSwitch
            label="Detalhamento dos suspeitos"
            onChange={toggle('fullView')}
            active={isOpen.fullView}
            inputId="suspects-full-view"
          />
        </div>
      </div>

      {suspects.map((suspect, idx) => (
        <Stack key={`${suspect.id}-${idx}`} marginTop={StackMargin.LARGE}>
          <Card
            className={style.card}
            dataTestId={`card-${suspect.id}`}
            customHeader={customHeader(suspect)}
          >
            <Stack marginTop={StackMargin.SMALL}>
              <ReportStatus
                report={suspect.currentReport}
                formatter={value =>
                  suspect.description
                    ? `${value} / ${suspect.description}`
                    : value
                }
              />
            </Stack>
            {suspect.currentReport && (
              <Stack marginTop={StackMargin.MEDIUM}>
                <ContentBadges
                  report={suspect.currentReport}
                  advancedInfoActive={isOpen.fullView}
                />
              </Stack>
            )}
          </Card>
        </Stack>
      ))}
    </Stack>
  );
};

export default Suspects;
