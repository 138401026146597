import React, { useContext } from 'react';
import { FieldArray, getIn, FormikProps } from 'formik';
import flatten from 'lodash/flatten';

import { Action, can as apiCan, fetchCreditContracts } from 'api';
import { useQuery } from 'hooks';
import { CreditContract, InternalLawsuitAttributes, OnSubmit } from 'types';

import { openErrorToast } from 'ui';
import { CaseContext, ContractList, ReclaimingList } from 'components';
import InvolvedParties from './InvolvedParties';
import Lawyers from './Lawyers';
import LawsuitFields from './LawsuitFields';

type Props = {
  caseId: string;
  onSubmit: OnSubmit<InternalLawsuitAttributes>;
  form: FormikProps<InternalLawsuitAttributes>;
  isWaitingResponse: boolean;
  setWaitingResponse: (isWaitingResponse: boolean) => void;
  isDisabledOnEdit?: boolean;
};

const Form = ({
  caseId,
  form,
  setWaitingResponse,
  isWaitingResponse,
  isDisabledOnEdit = false
}: Props) => {
  const { data: contracts, error } = useQuery<
    CreditContract[],
    { caseId: string }
  >(fetchCreditContracts, { caseId: caseId });

  const avaliableReclaimings = (contractIds: string[]): string[] => {
    return flatten(
      contracts
        ?.filter(contract => contractIds.includes(contract.id))
        .map(contract => contract.reclaimings)
    ).map(reclaiming => reclaiming.id);
  };

  if (error) openErrorToast('Erro ao listar contratos');

  const { caseData } = useContext(CaseContext);

  const negotiatorId = caseData?.negotiator?.id;

  const can = (action: Action): boolean => apiCan(action, negotiatorId);

  const canUser = {
    showContractList: can(
      Action.CASOS_PROCESSO_INFORMACOES_BASICAS_VINCULAR_CONTRATO
    ),
    showReclaimingList: can(
      Action.CASOS_PROCESSO_INFORMACOES_BASICAS_VINCULAR_RECUPERACAO
    ),
    showContractLink: can(
      Action.CASOS_PROCESSO_INFORMACOES_BASICAS_VER_CONTRATO
    ),
    showLawyers: can(Action.CASOS_PROCESSO_ADVOGADOS_CONSULTAR),
    showInvolvedParties: can(Action.CASOS_PROCESSO_PARTES_ENVOLVIDAS_CONSULTAR),
    addInvolvedParty: can(Action.CASOS_PROCESSO_PARTES_ENVOLVIDAS_INCLUIR),
    deleteInvolvedParty: can(Action.CASOS_PROCESSO_PARTES_ENVOLVIDAS_EXCLUIR),
    editInvolvedParty: can(Action.CASOS_PROCESSO_PARTES_ENVOLVIDAS_EDITAR),
    addToSuspect: can(
      Action.CASOS_PROCESSO_PARTES_ENVOLVIDAS_ADICIONAR_A_SUSPEITOS
    ),
    addLawyers: can(Action.CASOS_PROCESSO_ADVOGADOS_INCLUIR),
    deleteLawyers: can(Action.CASOS_PROCESSO_ADVOGADOS_EXCLUIR),
    editLawyers: can(Action.CASOS_PROCESSO_ADVOGADOS_EDITAR),
    addInternalLawsuit: can(Action.CASOS_PROCESSOS_JUDICIAIS_INCLUIR)
  };

  const canEditOrAdd = (can: boolean) =>
    form.initialValues.number ? can : canUser.addInternalLawsuit;

  return (
    <form className="lawsuit-form">
      <LawsuitFields
        caseId={caseId}
        form={form}
        isWaitingResponse={isWaitingResponse}
        setWaitingResponse={setWaitingResponse}
        isDisabledOnEdit={isDisabledOnEdit}
      />

      {canEditOrAdd(canUser.showLawyers) && (
        <FieldArray name="lawyers">
          {formikArrayHelpers => (
            <Lawyers
              disabled={isWaitingResponse}
              formikArrayHelpers={formikArrayHelpers}
              formik={form}
              showAdd={canEditOrAdd(canUser.addLawyers)}
              showDelete={canEditOrAdd(canUser.deleteLawyers)}
              showEdit={canEditOrAdd(canUser.editLawyers)}
            />
          )}
        </FieldArray>
      )}

      {canEditOrAdd(canUser.showInvolvedParties) && (
        <FieldArray name="parties">
          {formikArrayHelpers => (
            <InvolvedParties
              caseId={caseId}
              disabled={isWaitingResponse}
              arrayHelpers={formikArrayHelpers}
              form={form}
              showAdd={canEditOrAdd(canUser.addInvolvedParty)}
              showDelete={canEditOrAdd(canUser.deleteInvolvedParty)}
              showEdit={canEditOrAdd(canUser.editInvolvedParty)}
              showAddToCase={canUser.addToSuspect}
            />
          )}
        </FieldArray>
      )}
      {canEditOrAdd(canUser.showContractList) && (
        <FieldArray
          name="contractIds"
          render={() => (
            <ContractList
              caseId={caseId}
              showContractLink={canUser.showContractLink}
              selectedContractIds={form.values.contractIds}
              onCheckToogle={value => {
                form.setFieldValue('contractIds', value, true);

                const avaliableReclaimingsIds = avaliableReclaimings(value);

                const filteredReclaimingIds = form.values.reclaimingIds.filter(
                  id => avaliableReclaimingsIds.includes(id)
                );

                form.setFieldValue('reclaimingIds', filteredReclaimingIds);
              }}
              fetchedContracts={contracts}
              error={
                getIn(form.touched, 'contractIds') &&
                getIn(form.errors, 'contractIds')
              }
            />
          )}
        />
      )}

      {canEditOrAdd(canUser.showReclaimingList) && (
        <ReclaimingList
          showContractLink={canUser.showContractLink}
          caseId={caseId}
          onCheckToggle={value => form.setFieldValue('reclaimingIds', value)}
          contracts={contracts?.filter(contract =>
            form.values.contractIds.includes(contract.id)
          )}
          selectedReclaimingIds={form.values.reclaimingIds}
        />
      )}
    </form>
  );
};

export default Form;
