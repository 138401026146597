import { Option, CaseStatus, CaseType } from 'types';

const STATUS_DICTIONARY = {
  [CaseStatus.UNDER_ANALYSIS]: 'Análise',
  [CaseStatus.FINISHED]: 'Finalizado',
  [CaseStatus.JUDICIAL]: 'Judicial',
  [CaseStatus.NOT_ACQUIRED]: 'Não Adquirido',
  [CaseStatus.NEGOTIATION]: 'Negocial',
  [CaseStatus.RESEARCH]: 'Pesquisa',
  [CaseStatus.RECLAIMED]: 'Recuperado'
};

export const TYPE_DICTIONARY = {
  [CaseType.ATIVOS_ESPECIAIS]: 'Ativos Especiais',
  [CaseType.PEAC]: 'PEAC',
  [CaseType.SALES_FINANCE]: 'Sales Finance'
};

export function statusLabel(status: CaseStatus): string {
  return STATUS_DICTIONARY[status] || status;
}

export function statusOptions(): Option[] {
  return Object.values(CaseStatus).map(status => ({
    value: status,
    label: statusLabel(status)
  }));
}

export function casesInitialValues(type?: CaseType) {
  return {
    adverseParties: [],
    creditor: 'bv',
    internalLawyerId: '',
    lawFirmId: '',
    name: '',
    negotiatorId: '',
    type: type ?? CaseType.ATIVOS_ESPECIAIS,
    startedAt: '',
    status: ''
  };
}
