import {
  Option,
  DebtorJudicialSituation,
  DebtorGroupedWithContractAndInvoice
} from 'types';

const JUDICIAL_SITUATION_DICTIONARY = {
  [DebtorJudicialSituation.NO_JUDICIAL_PROCESS]: 'Sem Processo Judicial',
  [DebtorJudicialSituation.BANKRUPTCY]: 'Falência',
  [DebtorJudicialSituation.JUDICIAL_RECOVERY]: 'Recuperação Judicial',
  [DebtorJudicialSituation.EXTRAJUDICIAL_RECOVERY]: 'Recuperação extrajudicial',
  [DebtorJudicialSituation.JUDICIAL_EXECUTION]: 'Execução Judicial'
};

export function judicialSituationLabel(
  judicialSituation: DebtorJudicialSituation
): string {
  return JUDICIAL_SITUATION_DICTIONARY[judicialSituation] || judicialSituation;
}

export function judicialSituationOptions(): Option[] {
  return Object.values(DebtorJudicialSituation).map(judicialSituation => ({
    value: judicialSituation,
    label: judicialSituationLabel(judicialSituation)
  }));
}

export function countContracts(
  debtorsWithContracts: DebtorGroupedWithContractAndInvoice[]
) {
  return debtorsWithContracts
    .flatMap(debtorWithContract =>
      debtorWithContract.contractsByModality.map(
        (contracts): number => contracts.contracts.length
      )
    )
    .reduce((acc, length) => acc + length, 0);
}
