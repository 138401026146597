import React, { useState } from 'react';

import { Asset, AssetType } from 'types';
import { AssetSelectableList } from 'components';
import { Button, Stack, StackMargin } from 'ui';

type Props = {
  caseId: string;
  assetTypes?: AssetType[];
  onAdd: (assets: Asset[]) => void;
};

const RelateAssetModal = ({ assetTypes, caseId, onAdd }: Props) => {
  const [assets, setAssets] = useState<Asset[]>([]);

  const handleAdd = () => {
    onAdd(assets);
  };

  return (
    <div>
      <AssetSelectableList
        caseId={caseId}
        onSelect={setAssets}
        searchDefaultParams={{
          types: assetTypes,
          isGroup: false
        }}
      />

      <Stack marginTop={StackMargin.SMALL}>
        <Button
          centered
          disabled={assets.length === 0}
          highlight
          onClick={handleAdd}
        >
          Adicionar
        </Button>
      </Stack>
    </div>
  );
};

export default RelateAssetModal;
