import { entityName } from 'domain/entity';
import { buildPaginationParams } from 'domain/pagination';
import {
  ASSET_TYPE_DICTIONARY,
  assetValueCents,
  assetTypeLabel
} from 'domain/assets';
import React, { useState, useCallback } from 'react';
import xorBy from 'lodash/xorBy';
import find from 'lodash/find';

import cn from 'classnames';
import { applyMask, handleNullValue, pluralize } from 'utils';
import { Asset, FIRST_PAGE } from 'types';
import {
  fetchAssets,
  AssetSearchParams,
  fetchCaseGuarantees,
  fetchAllCaseEntities
} from 'api';
import { useQuery } from 'hooks';

import {
  ToolTip,
  DynamicTable,
  Stack,
  StackMargin,
  Icon,
  TablePositionModifier
} from 'ui';
import { ColoredBadge } from 'components';
import { BadgeColor } from 'components/ColoredBadge/ColoredBadge';

import Filters from './Filters/Filters';

import style from './AssetSelectableList.module.scss';

type Props = {
  caseId: string;
  onSelect: (assets: Asset[]) => void;
  searchDefaultParams?: AssetSearchParams;
  restrictOnlyOneType?: boolean;
  singleSelection?: boolean;
};

const DEFAULT_PAGE_SIZE = 6;

const AssetSelectableList = ({
  caseId,
  onSelect,
  searchDefaultParams,
  restrictOnlyOneType = false,
  singleSelection = false
}: Props) => {
  const {
    data: assets = [],
    isLoading,
    refetch: searchAssets,
    pagination
  } = useQuery(fetchAssets, {
    caseId,
    pagination: { page: FIRST_PAGE, pageSize: DEFAULT_PAGE_SIZE },
    params: searchDefaultParams
  });

  const [filters, setFilters] = useState<AssetSearchParams>({
    name: undefined,
    entityId: undefined,
    types: undefined,
    guaranteeId: undefined
  });

  const {
    data: guarantees = [],
    isLoading: isGuaranteeOptionLoading,
    error: isGuaranteeOptionError
  } = useQuery(fetchCaseGuarantees, { caseId });

  const {
    data: entities = [],
    isLoading: isEntityOptionLoading,
    error: isEntityOptionError
  } = useQuery(fetchAllCaseEntities, { caseId });

  const [selectedAssets, setSelectedAssets] = useState<Asset[]>([]);

  const isSelectedDifferentType = useCallback(
    (asset: Asset) => {
      if (selectedAssets.length === 0) return false;
      return selectedAssets[0].type !== asset.type;
    },
    [selectedAssets]
  );

  const handleOnClick = useCallback(
    (asset: Asset) => {
      if (restrictOnlyOneType && isSelectedDifferentType(asset)) return;

      const toggledAssets = singleSelection
        ? [asset]
        : xorBy(selectedAssets, [asset], 'id');

      setSelectedAssets(toggledAssets);
      onSelect(toggledAssets);
    },
    [
      selectedAssets,
      onSelect,
      isSelectedDifferentType,
      restrictOnlyOneType,
      singleSelection
    ]
  );

  const isSelected = useCallback(
    (asset: Asset) => {
      return !!find(selectedAssets, asset);
    },
    [selectedAssets]
  );

  const className = useCallback(
    (asset: Asset): string => {
      return cn(style.assetSelectWrapper, {
        [style.assetSelected]: isSelected(asset),
        [style.assetDisabled]:
          restrictOnlyOneType && isSelectedDifferentType(asset)
      });
    },
    [isSelected, isSelectedDifferentType, restrictOnlyOneType]
  );

  const columns = [
    {
      name: '',
      columnKey: 'checkbox',
      positionModifier: TablePositionModifier.CENTER,
      className: className,
      onClick: handleOnClick,
      render: (item: Asset) => {
        const checkBoxClassName = cn(style.checkbox, {
          [style.showCheckbox]: isSelected(item),
          [style.roundedCheckbox]: singleSelection
        });

        const iconName = singleSelection ? 'bullet' : 'checked';

        return (
          <Icon
            dataTestId={`asset-checked-${item.id}`}
            name={iconName}
            fill="#fff"
            small
            className={checkBoxClassName}
          />
        );
      }
    },
    {
      name: 'Tipo',
      columnKey: 'type',
      className: className,
      onClick: handleOnClick,
      render: (item: Asset) => assetTypeLabel(item)
    },
    {
      name: 'Nome do ativo',
      columnKey: 'name',
      className: className,
      onClick: handleOnClick,
      render: (item: Asset) => {
        return (
          <ToolTip content={item.comment} placement="top">
            <span>{item.name}</span>
          </ToolTip>
        );
      }
    },
    {
      name: 'Identificação',
      columnKey: 'identification',
      className: className,
      onClick: handleOnClick,
      render: (item: Asset) => handleNullValue(item.identification)
    },
    {
      name: 'Valor',
      columnKey: 'value',
      dataTestId: 'asset-cell-value-cents',
      className: className,
      onClick: handleOnClick,
      render: (item: Asset) =>
        handleNullValue(assetValueCents(item), 'string', value =>
          applyMask('currency', value)
        )
    },
    {
      name: 'Proprietário',
      columnKey: 'entity',
      dataTestId: 'entity-name-cell',
      className: className,
      onClick: handleOnClick,
      render: (item: Asset) => entityName(item.entity)
    },
    {
      name: 'Descrição',
      columnKey: 'description',
      dataTestId: 'description-cell',
      className: className,
      onClick: handleOnClick,
      render: (item: Asset) =>
        handleNullValue(item.entity.description, 'string')
    }
  ];

  const rowKey = (item: Asset) => item.id;

  const normalizeFilters = (params: AssetSearchParams) => {
    return {
      types: params.types ?? searchDefaultParams?.types,
      entityId: params.entityId ?? searchDefaultParams?.entityId,
      guaranteeId: params.guaranteeId ?? searchDefaultParams?.guaranteeId,
      name: params.name ?? searchDefaultParams?.name,
      isGroup: searchDefaultParams?.isGroup,
      excludeFrom: searchDefaultParams?.excludeFrom,
      excludeGuaranteeAsset: searchDefaultParams?.excludeGuaranteeAsset
    };
  };

  const refetchWithPagination = (pagination?: {
    page: number;
    pageSize: number;
  }) =>
    searchAssets({
      caseId,
      pagination: {
        page: pagination?.page ?? FIRST_PAGE,
        pageSize: pagination?.pageSize ?? DEFAULT_PAGE_SIZE
      },
      params: normalizeFilters(filters)
    });

  const paginationParams = buildPaginationParams(
    pagination,
    refetchWithPagination
  );

  const handleApplyFilter = (params: AssetSearchParams) => {
    setFilters(params);

    searchAssets({
      caseId,
      pagination: { page: FIRST_PAGE, pageSize: DEFAULT_PAGE_SIZE },
      params: normalizeFilters(params)
    });
  };

  const isAdvancedFilterActive =
    Object.entries(filters).filter(([filterKey, filterValue]) => {
      return filterKey !== 'name' && filterValue !== undefined;
    }).length > 0;

  return (
    <div>
      <Filters
        onApplyFilter={handleApplyFilter}
        entities={entities}
        guarantees={guarantees}
        activeFilters={filters}
        isGuaranteeOptionLoading={isGuaranteeOptionLoading}
        isGuaranteeOptionError={isGuaranteeOptionError}
        isEntityOptionLoading={isEntityOptionLoading}
        isEntityOptionError={isEntityOptionError}
        isFilterActive={isAdvancedFilterActive}
        defaultTypes={searchDefaultParams?.types}
      />

      {isAdvancedFilterActive && (
        <Stack marginTop={StackMargin.SMALL} className={style.activeFilters}>
          <span className={style.infoTexts}>Filtros ativos:</span>

          {filters?.entityId && (
            <ColoredBadge
              wrapperClassName={style.filtersBadge}
              type={BadgeColor.BLUE}
              title={`PROPRIETÁRIO:
              ${entityName(
                entities.find(entity => entity.id === filters.entityId)!
              )}`}
            />
          )}

          {filters?.types &&
            filters.types.map((type, index) => (
              <ColoredBadge
                key={index}
                wrapperClassName={style.filtersBadge}
                type={BadgeColor.BLUE}
                title={`TIPO:
              ${ASSET_TYPE_DICTIONARY[type]}`}
              />
            ))}

          {filters?.guaranteeId && (
            <ColoredBadge
              wrapperClassName={style.filtersBadge}
              type={BadgeColor.BLUE}
              title={`NA GARANTIA:
              ${
                guarantees.find(
                  guarantee => guarantee.id === filters.guaranteeId
                )?.instrumentNumber
              }`}
            />
          )}
        </Stack>
      )}

      {selectedAssets.length > 0 && (
        <Stack marginTop={StackMargin.SMALL} className={style.infoTexts}>
          {pluralize(
            selectedAssets.length,
            'ativo selecionado',
            'ativos selecionados'
          )}
        </Stack>
      )}

      <Stack marginTop={StackMargin.LARGE}>
        <DynamicTable<Asset>
          tableClassName={style.table}
          columns={columns}
          data={assets}
          rowKey={rowKey}
          pagination={paginationParams}
          noAutoPaginate
          isLoading={isLoading}
          emptyText="Não há nenhum ativo neste caso"
          initialItemsPerPage={DEFAULT_PAGE_SIZE}
        />
      </Stack>
    </div>
  );
};

export default AssetSelectableList;
