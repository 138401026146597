import { ERROR_MESSAGE, ReportAssetName } from 'domain/reports';
import { buildPaginationParams } from 'domain/pagination';
import { mountReportAddress } from 'domain/reportLocation';
import React from 'react';
import size from 'lodash/size';
import compact from 'lodash/compact';

import {
  Accordion,
  Card,
  DropDown,
  openErrorToast,
  openSuccessToast,
  DynamicTable,
  TablePositionModifier,
  Stack,
  StackMargin
} from 'ui';
import { FormerValueTooltip, ProviderTooltip, SummaryError } from 'components';
import {
  applyMask,
  addSquareMeterUnit,
  handleNullValue,
  applyCurrencyMask
} from 'utils';
import { ReportRealEstate, ActionName, ActionEntity } from 'types';
import {
  addRealEstateToCase,
  fetchReportRealEstates,
  fetchReportRealEstatesSummary,
  fetchReportRealEstatesTotalValue
} from 'api';
import * as Actions from 'utils/actions';
import { useQuery } from 'hooks';

import SummaryTitle from 'pages/reports/SharedComponents/SummaryTitle';
import ReportDynamicTable from '../../ReportDynamicTable/ReportDynamicTable';

import style from './RealEstateTable.module.scss';

type Props = {
  caseId: string;
  reportId: string;
  addToCase?: boolean;
  isSummary?: boolean;
  providerStatus?: string;
  tableMessage?: string;
};

const RealEstateTable = ({
  caseId,
  reportId,
  addToCase = true,
  isSummary,
  providerStatus,
  tableMessage
}: Props) => {
  const query = isSummary
    ? fetchReportRealEstatesSummary
    : fetchReportRealEstates;
  const {
    data: realEstates = [],
    isLoading,
    pagination,
    error: realEstatesError,
    refetch
  } = useQuery(query, {
    caseId,
    reportId
  });

  const { data: realEstatesTotalValues } = useQuery(
    fetchReportRealEstatesTotalValue,
    {
      caseId,
      reportId
    }
  );

  if (realEstatesError) {
    return <SummaryError title="Imóveis" message={ERROR_MESSAGE.realEstate} />;
  }

  const handleAddToCase = (
    realEstateId: ReportRealEstate['id']
  ) => async () => {
    try {
      await addRealEstateToCase({
        caseId,
        reportId,
        contentId: realEstateId
      });

      openSuccessToast('Imóvel adicionado ao caso com sucesso!');
    } catch (err) {
      openErrorToast('Falha ao adicionar imóvel ao caso.');
    }
  };

  const totalAmount = handleNullValue(
    realEstatesTotalValues?.totalValue,
    'string',
    value => applyMask('currency', value)
  );

  const isEmpty = realEstates.length < 1;

  const buildActions = ({ field: { actions }, id }: ReportRealEstate) => {
    const addToCaseAction = {
      text: 'Adicionar ao caso',
      callback: handleAddToCase(id),
      isDisabled: Actions.isDisabled(actions, ActionName.ADD_TO_CASE),
      description: Actions.unavailableReasons(
        actions,
        ActionName.ADD_TO_CASE,
        ActionEntity.REAL_ESTATE
      )
    };

    const allActionsAllowed = compact([addToCase && addToCaseAction]);

    return allActionsAllowed;
  };

  const columns = [
    {
      name: 'Endereço',
      columnKey: 'street',
      render: (item: ReportRealEstate) => (
        <FormerValueTooltip
          fieldName="street"
          fieldValue={mountReportAddress(item.address, 'street')}
          history={item.field.history}
        />
      )
    },
    {
      name: 'Município',
      columnKey: 'cityName',
      render: (item: ReportRealEstate) => (
        <FormerValueTooltip
          fieldName="cityName"
          fieldValue={item.address?.city?.name}
          history={item.field.history}
        />
      )
    },
    {
      name: 'Estado',
      columnKey: 'cityState',
      render: (item: ReportRealEstate) => (
        <FormerValueTooltip
          fieldName="cityState"
          fieldValue={item.address?.city?.state}
          history={item.field.history}
        />
      )
    },
    {
      name: 'Valor avaliação',
      columnKey: 'assessedValueCents',
      render: (item: ReportRealEstate) => (
        <FormerValueTooltip
          fieldName="valueCents"
          fieldValue={item.valueCents}
          history={item.field.history}
          formatter={value => applyCurrencyMask(value)}
        />
      )
    },
    {
      name: 'Área terreno',
      columnKey: 'landArea',
      render: (item: ReportRealEstate) => (
        <FormerValueTooltip
          fieldName="landArea"
          fieldValue={item.landArea}
          history={item.field.history}
          formatter={value => addSquareMeterUnit(value)}
        />
      )
    },
    {
      name: 'Área construída',
      columnKey: 'buildingArea',
      render: (item: ReportRealEstate) => (
        <FormerValueTooltip
          fieldName="buildingArea"
          fieldValue={item.buildingArea}
          history={item.field.history}
          formatter={value => addSquareMeterUnit(value)}
        />
      )
    }
  ];

  const actionColumns = [
    {
      positionModifier: TablePositionModifier.RIGHT,
      hasActions: true,
      name: '',
      columnKey: 'actions',
      render: (item: ReportRealEstate) => (
        <>
          <ProviderTooltip
            searchedByHomonym={item.field.searchedByHomonym}
            providers={item.field.providers}
            dataTestId="provider-tooltip"
          />
          {buildActions(item).length > 0 && (
            <DropDown options={buildActions(item)} />
          )}
        </>
      )
    }
  ];

  const rowKey = (item: ReportRealEstate) => item.id;

  if (isSummary) {
    return (
      <>
        <Stack marginBottom={StackMargin.SMALL}>
          <SummaryTitle
            headerInfo={{
              value: totalAmount.toString(),
              label: 'Soma dos valores dos imóveis:'
            }}
          >{`Imóveis (${size(realEstates)})`}</SummaryTitle>
        </Stack>
        <DynamicTable
          columns={columns}
          data={realEstates}
          rowKey={rowKey}
          getRowClassName={() => style.summaryTableRow}
          noAutoPaginate
          isLoading={isLoading}
        />
      </>
    );
  }

  const refetchWithPagination = (pagination?: {
    page: number;
    pageSize: number;
  }) => {
    refetch({
      caseId,
      reportId,
      pagination
    });
  };

  const paginationProps = buildPaginationParams(
    pagination,
    refetchWithPagination
  );

  return (
    <Accordion
      title="Imóveis"
      badgeContent={pagination?.totalEntries}
      tooltipText={providerStatus}
      headerInfo={{
        value: totalAmount.toString(),
        label: 'Soma dos valores dos imóveis:'
      }}
      isClosed={isEmpty && !tableMessage}
    >
      <Card small>
        <ReportDynamicTable
          columns={[...columns, ...actionColumns]}
          data={realEstates}
          rowKey={rowKey}
          isLoading={isLoading}
          pagination={paginationProps}
          emptyText={tableMessage}
          reportAssetName={ReportAssetName.REAL_ESTATE}
        />
      </Card>
    </Accordion>
  );
};

export default RealEstateTable;
