import React, {
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useRef
} from 'react';
import cn from 'classnames';
import useOnclickOutside from 'react-cool-onclickoutside';
import { Checkbox, SelectField } from 'ui';
import xor from 'lodash/xor';
import { useToggles } from 'hooks';
import styles from './MultiSelect.module.scss';

type MultiSelectEvent<T> = {
  target: {
    name?: string;
    id?: string;
    value?: T[];
  };
};

type MultiSelectProps<T> = {
  selected: T[];
  id: string;
  name: string;
  dataTestId?: string;
  onBlur?: (event: MultiSelectEvent<T>) => void;
  onChange?: (selected: T[]) => void;
  title?: string;
  error?: string | string[];
  className?: string;
  children?: ReactElement<MultiSelectOptionProps<T>>[];
  isLoading?: boolean;
  disabled?: boolean;
};

type MultiSelectOptionProps<T> = {
  value: T;
  disabled?: boolean;
};

function isSelected<T>(items: T[]) {
  return (element: ReactElement<MultiSelectOptionProps<T>>) =>
    items.includes(element.props.value);
}

interface MultiSelectComponetProps {
  <T>(props: MultiSelectProps<T>): ReactElement;
  Option<T>(
    props: PropsWithChildren<MultiSelectOptionProps<T>>
  ): ReactElement<MultiSelectOptionProps<T>>;
}

const MultiSelect: MultiSelectComponetProps = ({
  error,
  className,
  title,
  name,
  dataTestId,
  id,
  selected,
  onChange,
  onBlur,
  isLoading,
  disabled,
  children = []
}) => {
  const {
    isOpen: { dropdownCard: isOpen },
    toggleTrigger,
    closeAll
  } = useToggles<'dropdownCard'>({
    dropdownCard: false
  });
  const handleOpenDropdown = () => {
    if (!disabled) toggleTrigger('dropdownCard');
  };

  const handleClickOutside = () => {
    if (isOpen) {
      closeAll();
      onBlur &&
        onBlur({
          target: {
            name,
            id
          }
        });
    }
  };

  const ref = useRef<null | HTMLDivElement>(null);
  useOnclickOutside(ref, handleClickOutside);

  if (isLoading) {
    return (
      <SelectField
        isLoading={isLoading}
        disabled={disabled}
        id={id ?? ''}
        name={name ?? ''}
        onChange={() => {}}
        title={title}
        options={[]}
      />
    );
  }

  const selectedChildren = children.filter(isSelected(selected));
  const isEmpty = selectedChildren.length === 0;
  return (
    <div className={className} ref={ref}>
      {title && (
        <label
          className={cn(styles.label, { [styles.labelError]: !!error })}
          htmlFor={id}
        >
          {title}
        </label>
      )}
      <div className={styles.container}>
        <span
          data-testid={dataTestId}
          className={cn(
            styles.selectValues,
            isEmpty && styles.empty,
            disabled && styles.disabledSelectValues,
            className
          )}
          id={id}
          onClick={handleOpenDropdown}
        >
          {isEmpty
            ? disabled
              ? 'Indisponível'
              : 'Selecione...'
            : selectedChildren}
        </span>

        <div
          className={cn(styles.dropdownCard, {
            [styles.dropdownCardClosed]: !isOpen
          })}
        >
          <fieldset className={styles.dropdownList}>
            {disabled ||
              children.map((ch, index) => {
                const checked = isSelected(selected)(ch);
                return (
                  <Checkbox
                    key={index}
                    label={(ch as unknown) as string}
                    id={`multiselect-${id}-${index}-checbox`}
                    name={`multiselect-${id}-${index}-checbox`}
                    disabled={ch.props.disabled}
                    checked={checked}
                    onChange={() => {
                      if (onChange && !ch.props.disabled) {
                        onChange(xor(selected, [ch.props.value]));
                      }
                    }}
                  />
                );
              })}
          </fieldset>
        </div>
      </div>
      {error && (
        <p className={styles.message}>
          {Array.isArray(error) ? error.join(', ') : error}
        </p>
      )}
    </div>
  );
};

type OptionWrapperProps = {
  children?: ReactNode;
};
const OptionWrapper = ({ children }: OptionWrapperProps) => <>{children}</>;
MultiSelect.Option = props => <OptionWrapper {...props} />;

export default MultiSelect;
