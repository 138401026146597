import { fileTooBigErrorMessage, isValidFileSize } from 'domain/files';
import React, { ReactElement, useCallback, useEffect } from 'react';
import { DataImportStatus } from 'types';
import { Button, openErrorToast } from 'ui';
import {
  dismissLastDataImport,
  fetchLastDataImport,
  uploadSpreadsheet
} from 'api/dataImports';
import cn from 'classnames';
import Progress from './Progress';
import Summary from './Summary';

import style from './DataImportField.module.scss';
import { useDataImport } from './DataImportContext';

const DataImportField = (): ReactElement => {
  const { dataImport, setDataImport, isConnected } = useDataImport();
  const formData = new FormData();

  const fetchDataImport = useCallback(async () => {
    try {
      const response = await fetchLastDataImport();

      setDataImport(response.data);
    } catch (error) {
      if (error.status !== 404) {
        openErrorToast(
          'Ocorreu um erro ao verificar se há importações em andamento'
        );
      }
    }
  }, [setDataImport]);

  useEffect(() => {
    fetchDataImport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleDismissLastDataImport(id: string): Promise<void> {
    try {
      const { data } = await dismissLastDataImport(id);
      setDataImport(data);
    } catch (error) {
      openErrorToast('Ocorreu um erro ao fechar a notificação de importação');
    }
  }

  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>): void {
    if (!event.target.files || event.target.files.length === 0) return;

    const file = event.target.files[0];

    if (!isValidFileSize(file.size)) {
      openErrorToast(fileTooBigErrorMessage());
      return;
    }
    formData.append('data[file]', file, file.name);

    upload(formData);
  }

  async function upload(formData: FormData): Promise<void> {
    try {
      const { data } = await uploadSpreadsheet(formData);
      setDataImport(data);
    } catch (error) {
      openErrorToast('Ocorreu um erro no upload do arquivo');
    }
  }

  const isActive = dataImport?.status === DataImportStatus.ACTIVE;
  const isFinishedWithoutDismiss =
    dataImport?.status === DataImportStatus.FINISHED &&
    !dataImport.resultsDismissed;
  const hasStatusToDispaly = isActive || isFinishedWithoutDismiss;

  return (
    <div className={style.container}>
      <input
        data-testid="data-import-file-input"
        className={style.input}
        name="file"
        type="file"
        onChange={handleOnChange}
        accept=".xlsx"
        id="data-import-file-input"
      />
      <Button
        highlight
        outline
        small
        className={cn([
          style.dataImportButton,
          hasStatusToDispaly && style.dataImportButtonWithStatus
        ])}
        disabled={
          !isConnected || dataImport?.status === DataImportStatus.ACTIVE
        }
      >
        <label
          className={style.trigger}
          htmlFor="data-import-file-input"
          data-testid="file-field-trigger"
        >
          Importar
        </label>
      </Button>

      {dataImport && hasStatusToDispaly && (
        <div className={style.dataImportStatus}>
          {isActive && <Progress dataImport={dataImport} />}

          {isFinishedWithoutDismiss && (
            <Summary
              dataImport={dataImport}
              handleDismissLastDataImport={handleDismissLastDataImport}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DataImportField;
