export const tableColapseOptions = {
  initial: 'collapsed',
  animate: 'open',
  exit: 'collapsed',
  variants: {
    open: { opacity: 1 },
    collapsed: { opacity: 0 }
  },
  transition: { duration: 0.2 }
};
