import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { Action, can, fetchGroupedWithContractAndInvoices } from 'api';
import { useQuery } from 'hooks';

import { Link, ToolTip } from 'ui';
import { CaseContext } from 'components';

import { CaseType } from 'types';

import DebtorTable from './DebtorTable/DebtorTable';
import ListSummary from './ListSummary';

import style from './DebtorsList.module.scss';

const DebtorsList = () => {
  const { caseId } = useParams<{ caseId: string }>();

  const {
    caseData: {
      type: caseType = CaseType.ATIVOS_ESPECIAIS,
      negotiator = undefined
    } = {}
  } = useContext(CaseContext);

  const isSalesFinance = CaseType.SALES_FINANCE === caseType;

  const {
    data = [],
    isLoading,
    error
  } = useQuery(fetchGroupedWithContractAndInvoices, { caseId });

  const areDebtorsEmpty = !data.length;

  const canUser = {
    showNewContract: can(Action.CASOS_CONTRATO_INCLUIR, negotiator?.id),
    addDebtor: can(Action.CASOS_DEVEDOR_INCLUIR, negotiator?.id),
    addInvoice: can(Action.CASOS_NOTA_FISCAL_INCLUIR, negotiator?.id)
  };

  return (
    <div className={style.contractsPage}>
      <div className={style.header}>
        <ListSummary
          isLoading={isLoading}
          isSalesFinance={isSalesFinance}
          debtorsWith={data}
        />

        <div className={style.actions}>
          {canUser.addDebtor && (
            <Link
              to={`/cases/${caseId}/debtors/create`}
              highlight
              asButton
              outline
              small
              dataTestId="add-new-debtor"
            >
              Novo devedor
            </Link>
          )}

          {canUser.showNewContract && (
            <ToolTip
              content={areDebtorsEmpty ? 'Crie Devedores antes' : ''}
              placement="top"
            >
              <span data-testid="new-contract-span">
                <Link
                  to={`/cases/${caseId}/debtors/contract/create`}
                  asButton
                  outline
                  highlight
                  small
                  disabled={isLoading || areDebtorsEmpty}
                  dataTestId="add-new-contract"
                >
                  Novo contrato
                </Link>
              </span>
            </ToolTip>
          )}

          {isSalesFinance && canUser.addInvoice && (
            <ToolTip
              content={areDebtorsEmpty ? 'Crie Devedores antes' : ''}
              placement="top"
            >
              <span data-testid="new-invoice-span">
                <Link
                  to={`/cases/${caseId}/debtors/invoice/create`}
                  asButton
                  outline
                  highlight
                  small
                  disabled={isLoading || areDebtorsEmpty}
                  dataTestId="add-new-invoice"
                >
                  Nova nota fiscal
                </Link>
              </span>
            </ToolTip>
          )}
        </div>
      </div>

      <DebtorTable
        caseId={caseId}
        debtors={data}
        error={error}
        isSalesFinance={isSalesFinance}
      />

      {!isLoading && areDebtorsEmpty && (
        <p className={style.emptyDebtorsList}>
          Não há contratos nem devedores cadastrados neste caso
        </p>
      )}
    </div>
  );
};

export default DebtorsList;
