import { ActionName, Reason, ActionEntity, Actions } from 'types';

const reasonsDictionary = {
  [Reason.ALREADY_DEBTOR_OF_CONTRACT]: 'faz parte de um contrato',
  [Reason.ALREADY_GUARANTOR_OF_GUARANTEE]: 'é garantidor de uma garantia',
  [Reason.ALREADY_PARTY_OF_LAWSUIT]: 'faz parte de um processo judicial',
  [Reason.ALREADY_PAYOR_OF_RECLAIMING]: 'é pagador de uma recuperação',
  [Reason.ALREADY_RELATED_TO_ASSET]: 'está relacionado a um ativo',
  [Reason.ALREADY_RELATED_TO_DEBTOR]: 'está relacionado a um devedor',
  [Reason.ALREADY_RELATED_TO_LOCATION]: 'está relacionado à uma localização',
  [Reason.BASIC_INFORMATION_MISSING]:
    'está com as informações básicas faltando',
  [Reason.INTERNAL_LAWYER_IN_A_CASE]: 'é advogado interno de um caso',
  [Reason.MISSING_DATA]: 'está com os dados incompletos',
  [Reason.NEGOTIATOR_IN_A_CASE]: 'é negociador de um caso',
  [Reason.NO_AVAILABLE_GUARANTEES]: 'não tem garantias disponíveis',
  [Reason.ALREADY_IN_CASE]: 'já está em rastreamento'
};

const actionsDictionary = {
  [ActionName.ADD_TO_CASE]: 'adicionar ao caso',
  [ActionName.CREATE_CONTRACT_GUARANTEE_RELATIONSHIP]:
    'adicionar uma garantia existente ao contrato',
  [ActionName.CREATE_REPORT]: 'buscar',
  [ActionName.DELETE]: 'excluir',
  [ActionName.UPDATE]: 'editar'
};

const entitiesDictionary = {
  [ActionEntity.AIRPLANE]: 'a aeronave',
  [ActionEntity.COMPANY]: 'a empresa',
  [ActionEntity.DEBTOR]: 'o devedor',
  [ActionEntity.INDIVIDUAL]: 'o indivíduo',
  [ActionEntity.LOCATION]: 'a localização',
  [ActionEntity.PARTNER]: 'o sócio',
  [ActionEntity.REAL_ESTATE]: 'o imóvel',
  [ActionEntity.RURAL_PROPERTY]: 'o imóvel rural',
  [ActionEntity.SUSPECT]: 'o suspeito',
  [ActionEntity.USER]: 'o usuário',
  [ActionEntity.VEHICLE]: 'o veículo'
};

export function unavailableReasons(
  actions: Actions,
  actionName: ActionName,
  entity: ActionEntity
): string {
  if (isAvailable(actions, actionName)) return '';

  const reasons = actions[actionName]?.reasons || [];

  const message = joinList(
    reasons.map(reason => reasonsDictionary[reason]).filter(r => r)
  );

  if (!message) return '';

  return `Não é possível ${actionsDictionary[actionName]} pois ${entitiesDictionary[entity]} ${message}`;
}

export function isAvailable(actions: Actions, actionName: ActionName): boolean {
  return !!actions[actionName]?.available;
}

export function isDisabled(actions: Actions, actionName: ActionName): boolean {
  return !isAvailable(actions, actionName);
}

function joinList(arr: string[]): string {
  const listStart = arr.slice(0, -1).join(', ');
  const listEnd = arr.slice(-1);
  const conjunction = arr.length <= 1 ? '' : ' e ';

  return [listStart, listEnd].join(conjunction);
}
