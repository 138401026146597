import {
  LawFirm,
  LawFirmAttributes,
  User,
  ExternalLawyerFormAttributes
} from 'types';

import Client from './client';

type Args = {
  includes?: string[];
};

function fetchAllLawFirms(params?: Args) {
  return Client.get<LawFirm[]>('law_firms', {
    params: { includes: params?.includes || [] }
  });
}

function fetchLawFirm(lawFirmId: string) {
  return Client.get<LawFirm>(`law_firms/${lawFirmId}`);
}

function createLawFirm(lawFirmData: LawFirmAttributes) {
  return Client.post<LawFirmAttributes, LawFirm>('law_firms', lawFirmData);
}

function deleteLawFirm(lawFirmId: string) {
  return Client.delete(`law_firms/${lawFirmId}`);
}

function updateLawFirm(id: string, lawFirmData: LawFirmAttributes) {
  return Client.put<LawFirmAttributes, LawFirm>(`law_firms/${id}`, lawFirmData);
}

function createExternalLawyer(
  lawFirmId: string,
  externalLawyerData: ExternalLawyerFormAttributes
) {
  return Client.post<ExternalLawyerFormAttributes, User>(
    `law_firms/${lawFirmId}/users`,
    externalLawyerData
  );
}

function submitLawFirm({
  lawfirm,
  values
}: {
  lawfirm: LawFirm | undefined;
  values: LawFirmAttributes;
}) {
  if (lawfirm) return updateLawFirm(lawfirm.id, values);

  return createLawFirm(values);
}

export {
  fetchAllLawFirms,
  fetchLawFirm,
  createLawFirm,
  deleteLawFirm,
  updateLawFirm,
  submitLawFirm,
  createExternalLawyer
};
