import React, { ReactElement, ReactNode } from 'react';
import TableRow from '../TableRow/TableRow';
import TableCell from '../TableCell/TableCell';
import TableLoader from '../TableLoader/TableLoader';

import styles from './TableBody.module.scss';

type Props = {
  children: ReactElement[] | ReactElement | ReactNode;
  columns?: number;
  isLoading?: boolean;
  isEmpty?: boolean;
  emptyText?: string;
};

const TableBody = ({
  children,
  columns = 1,
  isEmpty = false,
  isLoading = false,
  emptyText = 'Nenhum dado encontrado'
}: Props) => {
  return (
    <tbody className="body" data-testid="tableBody">
      {!isLoading && !isEmpty && children}
      {!isLoading && isEmpty && (
        <TableRow clickable={false} dataTestid="table-row-empty">
          <TableCell colSpan={columns} className={styles.emptyText}>
            {emptyText}
          </TableCell>
        </TableRow>
      )}
      {isLoading && <TableLoader columns={columns} />}
    </tbody>
  );
};

export default TableBody;
