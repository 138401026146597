import {
  ContentField,
  Field,
  PaginationRequestParams,
  ProviderName,
  Query,
  QueryStatus,
  ReportAddress,
  ReportPartner
} from 'types';
import { ReportFamilyMember } from 'types/reportFamilyMember';
import { ReportLocation } from 'types/reportLocation';
import { ReportPartnershipCompanies } from 'types/reportPartnershipCompany';
import { ReportRelatedCompany } from 'types/reportRelatedCompany';
import { InstagramProfile } from './instagram';
import { ExternalLawsuit } from './externalLawsuit';
import { PaginatedContent } from './api';
import { EntityAttributes } from './entity';

export type LegalReportQuotationContent = {
  maxYear: number | null;
  minYear: number | null;
  totalCost: number | null;
  totalLawsuits: number | null;
};

export type LegalReportQuotation = {
  content: null | LegalReportQuotationContent;
  id: string;
  reportId: string;
  status: QueryStatus;
};

export enum LegalReportOrderStatus {
  PENDING = 'pending',
  FINISHED = 'finished',
  ORDERED = 'ordered',
  SUSPENDED = 'suspended'
}

export type LegalReportOrder = {
  id: string;
  reportId: string;
  status: LegalReportOrderStatus;
  errorMessage: null | string;
};

export type ReportType = 'company' | 'individual';

export type ReportAttributes = {
  caseId: string;
  caseNegotiatorId?: string;
  searchTerm: string;
  type: ReportType;
};

export type Report = ReportAttributes & {
  id: string;
  hasExternalLawsuitsQueries: boolean;
  hasLegalReport: boolean;
  totalQueries: number;
  completedQueries: number;
  failedQueries: number;
  finishedAt: string | null;
  insertedAt: string;
  queries: Query[];
  entity: EntityAttributes | null;
  paginatedLawsuits: PaginatedContent<ExternalLawsuit[]>;
  currentLegalReportOrder: LegalReportOrder | null;
  selectedInstagramProfileId: string | null;
  selectedInstagramProfile: InstagramProfile | null;
  instagramSubject: InstagramSubject | null;
  instagramProfilesQueries: Query[];
  possibleInstagramProfiles: PossibleInstagramProfilesField;
  individual: ReportIndividual | null;
  company: ReportCompany | null;
};

export type ReportCaseSummary = Report & {
  content?: {
    airplanes?: number;
    electoralDonations?: number;
    realEstates?: number;
    ruralProperties?: number;
    vehicles?: number;
  };
  familyMembers?: ReportFamilyMember[];
  locations?: ReportLocation[];
  partners?: ReportPartner[];
  partnershipCompanies?: ReportPartnershipCompanies[];
  relatedCompanies?: ReportRelatedCompany[];
};

export type EntityReportFormAttributes = {
  type: string;
  searchTerm: string;
};

export type InstagramField = ContentField<{
  profiles: Array<InstagramProfile>;
}>;

export type DivergentDataValue =
  | ReportFields<string | number | string[] | ReportAddress>[]
  | undefined;

export type DivergentDataProvider = {
  label: string;
  divergentData: DivergentDataValue;
  isLink?: boolean;
};

export type ReportFields<T> = {
  fieldValue: T;
  providers: ProviderName[];
};

export type ReportCompany = {
  id: string;
  field: Field;
  address: ReportFields<ReportAddress>[];
  businessName: ReportFields<string>[];
  tradeName: ReportFields<string>[];
  cnpj: ReportFields<string>[];
  cnpjSituation: ReportFields<string>[];
  cnpjSituationDate: ReportFields<string>[];
  cnpjSpecialSituation: ReportFields<string>[];
  cnpjSpecialSituationDate: ReportFields<string>[];
  legalNature: ReportFields<string>[];
  openingDate: ReportFields<string>[];
  companySize: ReportFields<string>[];
  totalEmployees: ReportFields<number>[];
  estimatedRevenue: ReportFields<string>[];
  phones: ReportFields<string[]>[];
  websites: ReportFields<string[]>[];
  email: ReportFields<string>[];
};

export type ReportIndividual = {
  id: string;
  field: Field;
  address: ReportFields<ReportAddress>[];
  age: ReportFields<number>[];
  cpf: ReportFields<string>[];
  cpfSituation: ReportFields<string>[];
  dateOfBirth: ReportFields<string>[];
  obitYear: ReportFields<number>[];
  email: ReportFields<string>[];
  incomePrediction: ReportFields<string>[];
  motherName: ReportFields<string>[];
  name: ReportFields<string>[];
  phones: ReportFields<string[]>[];
  politicalLevel: ReportFields<string>[];
  politicalPosition: ReportFields<string>[];
  sex: ReportFields<string>[];
};

export enum InstagramSubjectStatus {
  PENDING = 'pending',
  NOT_FOUND = 'not_found',
  PROCESSED = 'processed',
  FAILED = 'failed'
}

export type InstagramSubject = {
  status: InstagramSubjectStatus;
  processedAt: string | null;
};

export type PossibleInstagramProfilesField = {
  value: PossibleInstagramProfiles[];
  providers: ProviderName[];
  searchedByHomonym: boolean;
};

export type PossibleInstagramProfiles = {
  instagramProfile: InstagramProfile;
  isSelected: boolean;
};

export type ReportStatus = {
  completedQueries: number;
  failedQueries: number;
  totalQueries: number;
  finishedAt: number;
};

export type Link = {
  href?: string;
  value?: string;
  external?: boolean;
  underline?: boolean;
  onClick?: () => void;
};

export type FetchReportTableProps = PaginationRequestParams & {
  caseId: string;
  reportId: string;
};

export type AddContentToCase = {
  caseId: string;
  reportId: string;
  contentId: string;
};

export type FetchReportTotalValueResult = {
  totalValue: number | null;
};
