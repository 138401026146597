import { fileTooBigErrorMessage, isValidFileSize } from 'domain/files';
import React from 'react';

import { FileItem } from 'ui';
import { uploadFile } from 'api';
import { FileUpload, GenericFile } from 'types';

type Props = {
  file: File;
  caseId: string;
  onSuccess: (file: GenericFile) => void;
  onDelete?: (id: string) => void;
};

type State = {
  uploadProgress?: number;
  error?: string | string[];
  initialFile: File;
  responseFile: null | FileUpload;
};

const validMagicNumber = async (file: File) => {
  const magicNumber = [
    '52656365', // .msg
    'd0cf11e0' // .msg
  ];

  const buffer = await file.arrayBuffer();
  const data = new Uint8Array(buffer);
  const hex = [...data]
    .map(b => b.toString(16))
    .join('')
    .slice(0, 8);

  return magicNumber.includes(hex);
};

const validMimeType = async (file: File) => {
  if (file.type === '') {
    return await validMagicNumber(file);
  }

  return [
    'image/jpeg',
    'image/png',
    'image/jpg',
    'application/pdf',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'message/rfc822'
  ].includes(file.type);
};

export default class FileUploader extends React.Component<Props, State> {
  state = {
    error: undefined,
    uploadProgress: undefined,
    initialFile: this.props.file,
    responseFile: null
  };

  progressbar = (ev: ProgressEvent) => {
    const progress = (ev.loaded / ev.total) * 100;

    this.setState({ uploadProgress: Math.round(progress) });
  };

  upload = async () => {
    this.setState({ error: undefined, uploadProgress: 0 });

    const { file, caseId, onSuccess } = this.props;

    if (!isValidFileSize(file.size)) {
      this.setState({ error: fileTooBigErrorMessage() });
      return;
    }

    if (!(await validMimeType(file))) {
      this.setState({ error: 'Extensão do arquivo inválida' });
      return;
    }

    const formData = new FormData();
    formData.append('data[file]', file, file.name);

    try {
      const response = await uploadFile(caseId, formData, this.progressbar);

      onSuccess(response.data);
      this.setState({ uploadProgress: undefined, responseFile: response.data });
    } catch (error) {
      this.setState({ error: 'Carregamento falhou' });
    }
  };

  componentDidMount() {
    this.upload();
  }

  render() {
    const { onDelete, onSuccess } = this.props;
    const { error, uploadProgress, initialFile, responseFile } = this.state;

    return (
      <FileItem
        file={(responseFile || initialFile) as GenericFile}
        error={error}
        onResend={this.upload}
        uploadProgress={uploadProgress}
        onDelete={onDelete}
        onUndoDelete={onSuccess}
      />
    );
  }
}
