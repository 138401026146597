import { TYPE_DICTIONARY } from 'domain/cases';
import React, { useState, ReactElement } from 'react';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';

import { Sidebar, NavigationItem, NavigationSubItem, ToolTip, Icon } from 'ui';
import { useFeatureFlag } from 'hooks';
import { can, Action, getUserInfo } from 'api';

import { Feature, CaseType } from 'types';
import style from './SystemSidebar.module.scss';

const SystemSidebar = (): ReactElement => {
  const location = useLocation();
  const [isOpen, setOpen] = useState(false);

  const isActive = (path: string): boolean => location.pathname.includes(path);
  const [isSalesFinanceEnabled] = useFeatureFlag(Feature.CASE_SALES_FINANCE);

  const logoClassName = cn(style.logo, {
    [style.logoOpen]: isOpen
  });

  const logoImage = isOpen ? '/logo-horizontal.svg' : '/logo-small.svg';
  const logoIcon = isOpen ? 'arrow-left' : 'arrow-right';
  const userInfo = getUserInfo();

  return (
    <Sidebar isOpen={isOpen} setOpen={setOpen} data-testid="system-sidebar">
      <NavigationItem
        position="top"
        content={
          <div className={logoClassName}>
            <img src={logoImage} alt="Yield" className={style.logoImage} />
            <Icon
              dataTestId="open-sidebar-icon"
              fill="#62667A"
              name={logoIcon}
              small
            />
          </div>
        }
      />

      {userInfo && (
        <ToolTip
          content={`${userInfo.name}\n${userInfo.email}\nPerfil: ${userInfo.profile}`}
          placement={isOpen ? 'top' : 'right'}
        >
          <span className="tooltip">
            <NavigationItem
              iconName="profile"
              fill="#62667a"
              content={userInfo.name.split(' ')[0]}
            />
          </span>
        </ToolTip>
      )}

      {can(Action.CASOS_LISTAGEM_DE_CASOS_CONSULTAR) && (
        <ToolTip content={!isOpen ? 'Casos' : ''} placement="right">
          <span className="tooltip">
            <NavigationItem
              fill="#62667a"
              active={isActive('/cases')}
              iconName="bullet-list"
              content="Casos"
              showChild={isOpen}
            >
              {Object.values(CaseType)
                .filter(
                  caseType =>
                    caseType !== CaseType.SALES_FINANCE || isSalesFinanceEnabled
                )
                .map((caseType, index) => (
                  <NavigationSubItem
                    key={index}
                    active={isActive(`/cases/${caseType}`)}
                    pathname={`/cases/${caseType}`}
                    content={TYPE_DICTIONARY[caseType]}
                  />
                ))}
            </NavigationItem>
          </span>
        </ToolTip>
      )}

      {(can(Action.PERMISSOES_GERENCIAMENTO_DE_PERMISSOES_CONSULTAR) ||
        can(Action.PERMISSOES_USUARIOS_INTERNOS_DO_BANCO_CONSULTAR) ||
        can(Action.PERMISSOES_ESCRITORIOS_DE_ADVOCACIA_CONSULTAR)) && (
        <ToolTip content={!isOpen ? 'Permissões' : ''} placement="right">
          <span className="tooltip">
            <NavigationItem
              active={isActive('/permissions')}
              pathname="/permissions"
              iconName="privacy"
              fill="#62667a"
              content="Permissões"
            />
          </span>
        </ToolTip>
      )}

      {can(Action.LINKS_UTEIS_LINKS_CONSULTAR) && (
        <ToolTip content={isOpen ? '' : 'Links Úteis'} placement="right">
          <span className="tooltip">
            <NavigationItem
              active={isActive('/useful_links')}
              pathname="/useful_links"
              target="_blank"
              iconName="star"
              fill="#62667a"
              content="Links Úteis"
            />
          </span>
        </ToolTip>
      )}

      <NavigationItem
        position="bottom"
        iconName="logout"
        fill="#62667a"
        pathname="/logout"
        content="Logout"
      />
    </Sidebar>
  );
};

export default SystemSidebar;
