import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import qs from 'qs';
import {
  ApiSuccessfulResponse,
  ApiFailedResponse,
  ClientOptions,
  ApiError
} from 'types';
import { logoutUrl, setToken, getToken } from './session';

type ApiResource<T> = {
  data: T;
};

const defaultOptions: Partial<ClientOptions> = {
  authenticated: true,
  params: {},
  customHeaders: {},
  resource: true,
  logoutOnAuthenticationError: true,
  redirectOnNotFoundError: true
};

export default async function request<P, T>(
  baseUrl: string,
  customConfig: Partial<AxiosRequestConfig>,
  opts: Partial<ClientOptions>
): Promise<ApiSuccessfulResponse<T>> {
  const options = { ...defaultOptions, ...opts };
  const customHeaders = buildCustomHeaders(options);

  const config: AxiosRequestConfig = {
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...customHeaders
    },
    params: options.params,
    onUploadProgress: options.onUploadProgress,
    paramsSerializer: function(params) {
      return qs.stringify(params, { arrayFormat: 'brackets' });
    },
    ...customConfig,
    data: buildBody<P>(customConfig.data, options)
  };

  try {
    const data = await axios(config);
    return handleApiResponse<T>(data, options);
  } catch (error) {
    return handleRequestError(error, options);
  }
}

function buildBody<T>(
  body: T | undefined,
  options: Partial<ClientOptions>
): T | ApiResource<T> | undefined {
  if (!body) return undefined;

  if (options.resource) return { data: body };
  return body;
}

function buildCustomHeaders(options: Partial<ClientOptions>) {
  const authHeaders = options.authenticated
    ? {
        authorization: 'Bearer ' + getToken()
      }
    : {};

  return { ...options.customHeaders, ...authHeaders };
}

function handleApiResponse<T>(
  response: AxiosResponse,
  options: Partial<ClientOptions>
): ApiSuccessfulResponse<T> {
  const newToken = response.headers['yield-next-access-token'];

  if (options.authenticated && newToken) {
    setToken(newToken);
  }

  return {
    status: response.status,
    pagination: response.data.pagination,
    data: response.data.data
  };
}

function handleRequestError(
  error: AxiosError<{ errors: ApiError[] }>,
  options: Partial<ClientOptions>
): Promise<never> {
  const responseError = parseError(error);
  console.error(responseError);

  if (options.logoutOnAuthenticationError && responseError.status === 401) {
    window.location.assign(logoutUrl());
  }

  if (responseError.status === 403) {
    window.location.replace('/permission_denied');
  }

  if (options.redirectOnNotFoundError && responseError.status === 404) {
    window.location.replace('/not_found');
  }

  return Promise.reject(responseError);
}

function parseError(
  error: AxiosError<{ errors: ApiError[] }>
): ApiFailedResponse {
  return error.response
    ? {
        status: error.response.status,
        errors: error.response.data?.errors || []
      }
    : { status: 503, errors: [error] };
}
