import { contractBadgeColor } from 'domain/contracts';
import React from 'react';
import { DateTime } from 'luxon';
import sumBy from 'lodash/sumBy';
import cn from 'classnames';

import { useToggles } from 'hooks';
import { daysSince, pluralize, pluralizeByDays } from 'utils';

import { ColoredBadge } from 'components';
import { Table, Trigger } from 'ui';

import { Activity, CreditContract } from 'types';

import style from './PeacActivities.module.scss';

type Props = { contracts: CreditContract[] };

const activitiesDescription = (activities: Activity[]) => {
  if (!activities.length) return '';

  const [firstActivity, ...rest] = activities;

  if (activities.length === 1) return firstActivity.name;

  return `${firstActivity.name} (e ${pluralize(
    rest.length,
    'outra atividade',
    'outras atividades'
  )})`;
};

const badgeMetadata = (date: string | null) => {
  const now = DateTime.local().toISO();
  const contractOverdueDate = date ?? now;

  return {
    type: contractBadgeColor(contractOverdueDate),
    title: `${pluralizeByDays(daysSince(contractOverdueDate, now))} de atraso`
  };
};

const PeacActivities = ({ contracts }: Props) => {
  const { isOpen, toggle } = useToggles<'contracts'>({ contracts: false });

  const activitiesAmount = sumBy(
    contracts,
    ({ activities }) => activities.length
  );

  if (!activitiesAmount) {
    return (
      <tr>
        <td></td>
        <td className={cn({ [style.noActivities]: !activitiesAmount })}>
          <span className={style.text}>Não há atividades pendentes</span>
        </td>
      </tr>
    );
  }

  const renderOverdueActivityRows = isOpen.contracts && activitiesAmount;

  return (
    <>
      <tr>
        <td></td>
        <td className={cn({ [style.noActivities]: !activitiesAmount })}>
          <Trigger
            className={style.triggerContainer}
            onClick={toggle('contracts')}
            label="atividades-peac"
            content={
              <div className={style.centered}>
                <Trigger.DefaultIcon isClosed={!isOpen.contracts} />
                <span className={cn(style.text, style.textTrigger)}>
                  {pluralize(
                    activitiesAmount,
                    'atividade pendente',
                    'atividades pendentes'
                  )}
                </span>
              </div>
            }
          />
        </td>
      </tr>

      {renderOverdueActivityRows &&
        contracts.map(({ activities, number, firstOverduePaymentDate, id }) => {
          const cellClassName = cn(style.activitiesCell, style.text);

          return (
            <Table.Row className={style.activityRow} key={id}>
              <Table.Cell className={style.emptyCell}></Table.Cell>
              <Table.Cell className={cellClassName}>
                {`Contrato ${number}`}
              </Table.Cell>
              <Table.Cell
                colSpan={4}
                className={cn(cellClassName, style.description)}
              >
                {`${activitiesDescription(activities)}`}
              </Table.Cell>
              <Table.Cell className={cellClassName}>
                <ColoredBadge {...badgeMetadata(firstOverduePaymentDate)} />
              </Table.Cell>
            </Table.Row>
          );
        })}
    </>
  );
};

export default PeacActivities;
