export enum Feature {
  CASE_DEBTORS = 'caseDebtors',
  CASE_EVENTS = 'caseEvents',
  CASE_LAWSUITS = 'caseLawsuits',
  CASE_REPORTS = 'caseReports',
  CASE_SALES_FINANCE = 'caseSalesFinance',
  CASE_SUMMARY = 'caseSummary',
  CASES_EXPORT = 'casesExport',
  CASE_ASSET_GROUP = 'caseAssetGroup',
  CLUSTER_MODE = 'clusterMode',
  DATA_IMPORTS = 'dataImports',
  FILE_UPLOADS = 'fileUploads',
  API_GATEWAY = 'apiGateway',
  LAWSUIT_AUTO_UPDATE = 'lawsuitAutoUpdate',
  LAWSUIT_MOVEMENTS = 'lawsuitMovements',
  LAWSUITS_AUTOCOMPLETE = 'lawsuitsAutocomplete',
  LOGIN_CAPTCHA = 'loginCaptcha',
  MAILER = 'mailer',
  EXTERNAL_LAWSUITS = 'externalLawsuits',
  EXTERNAL_LAWSUITS_MONITORING = 'externalLawsuitsMonitoring',
  LINKED_LAWSUITS = 'linkedLawsuits',
  NOTIFICATIONS = 'notifications',
  ASSET_REPORTS = 'assetReports',
  LOGIN_OAUTH = 'loginOauth',
  LEGAL_REPORTS = 'legalReports',
  NEOWAY_EXTERNAL_LAWSUITS = 'neowayExternalLawsuits',
  INSTAGRAM_REPORT = 'instagramReport',
  BOOKMARK_INSTAGRAM_PUBLICATION = 'bookmarkInstagramPublication',
  LAWSUITS_EXECUTIONS = 'lawsuitsExecutions',
  REPORT_SUMMARY = 'reportSummary'
}
