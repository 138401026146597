import React, { ReactElement, ReactNode } from 'react';
import cn from 'classnames';
import styles from './Badge.module.scss';

type Props = {
  children: ReactNode;
  dataTestId?: string;
  rounded?: boolean;
  className?: string;
};

const Badge = ({
  children,
  dataTestId,
  rounded,
  className
}: Props): ReactElement => {
  return (
    <div
      className={cn(className, rounded ? styles.rounded : styles.badge)}
      data-testid={dataTestId}
    >
      {children}
    </div>
  );
};

export default Badge;
