import React from 'react';

import { Asset } from 'types';
import HeaderStatus from '../HeaderStatus';

type Props = {
  caseId: string;
  asset: Asset;
  disabled?: boolean;
};

const GroupStatus = ({ caseId, asset, disabled }: Props) => {
  if (!asset.assetGroup) {
    return null;
  }

  return (
    <HeaderStatus
      links={[
        {
          label: asset.assetGroup.name,
          linkId: asset.id,
          linkTo: `/cases/${caseId}/assets/${asset.assetGroupId}`
        }
      ]}
      strongText="No grupo"
      dataTestId="group-status"
      disabled={disabled}
    />
  );
};

export default GroupStatus;
