import React, { useEffect, useRef, useState } from 'react';
import { Prompt, useHistory, useRouteMatch } from 'react-router-dom';
import { FormikProps } from 'formik';

import {
  deleteAsset,
  fetchAsset,
  updateAsset,
  removeAssetFromGroup,
  can as apiCan,
  Action
} from 'api';
import { useQuery, useToggles } from 'hooks';
import {
  ApiError,
  AssetAttributes,
  OnSubmit,
  LocationState,
  Asset,
  AssetType
} from 'types';
import { ExecutionStatus, AssetSelectableList } from 'components';
import Layout from 'Layouts/Layout';
import {
  Button,
  Header,
  Link,
  openErrorToast,
  openSuccessToast,
  Title,
  Modal,
  Stack,
  StackMargin
} from 'ui';
import GuaranteeStatus from './GuaranteeStatus';
import ReclaimingStatus from './ReclaimingStatus';
import GroupStatus from './GroupStatus';
import Form from './Form';

import styles from './AssetReport.module.scss';

type MatchParams = {
  caseId: string;
  assetId: string;
};

const AssetReport = () => {
  const history = useHistory<LocationState>();
  const returnTo = history.location?.state?.returnTo;

  const {
    params: { caseId, assetId }
  } = useRouteMatch<MatchParams>();
  const formRef = useRef<FormikProps<AssetAttributes>>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const { toggle, isOpen, closeAll } = useToggles<'addToGroup'>({
    addToGroup: false
  });

  const { data: asset, error, isLoading, setData: setAsset } = useQuery(
    fetchAsset,
    {
      caseId,
      assetId
    }
  );

  if (error) openErrorToast('Não foi possível exibir o ativo');

  const handleDelete = async () => {
    if (window.confirm('Você tem certeza de que deseja excluir este ativo?')) {
      try {
        setIsDeleting(true);
        await deleteAsset(caseId, assetId);

        openSuccessToast(
          (asset?.isGroup ? 'Grupo' : 'Ativo') + ' excluído com sucesso!'
        );

        history.push(`/cases/${caseId}/resources`);
      } catch (error) {
        setIsDeleting(false);
        openErrorToast('Não foi possível excluir o ativo.');
      }
    }
  };

  const handleDeleteGroup = async () => {
    if (
      window.confirm(
        'O último ativo do grupo foi excluído, deseja excluir o grupo?'
      )
    ) {
      try {
        asset?.assetGroupId && (await deleteAsset(caseId, asset?.assetGroupId));

        openSuccessToast('Grupo excluído com sucesso!');
      } catch (error) {
        openErrorToast('Não foi possível excluir o grupo.');
      }
    }
  };

  const handleRemove = async () => {
    if (window.confirm('Deseja remover o ativo do grupo?')) {
      try {
        const response = await removeAssetFromGroup(caseId, assetId);
        openSuccessToast('Ativo removido do grupo com sucesso!');

        if (asset?.assetGroupId) {
          const { data: group } = await fetchAsset({
            caseId,
            assetId: asset.assetGroupId
          });

          if (group.groupedAssets.length === 0) {
            handleDeleteGroup();
          }
        }

        setAsset(response.data);
      } catch (error) {
        openErrorToast('Não foi possível remover o ativo do grupo.');
      }
    }
  };

  const formatError = (errors?: ApiError) => {
    if (!errors) return {};

    return {
      ...errors,
      entity: { individual: errors?.individual, company: errors?.company }
    };
  };

  const onSubmit: OnSubmit<AssetAttributes> = async (values, formikHelpers) => {
    closeAll();

    try {
      const response = await updateAsset(caseId, asset!.id, values);
      setAsset(response.data);

      openSuccessToast('Ativo salvo com sucesso!');
    } catch (error) {
      formikHelpers.setErrors(formatError(error.errors));
      openErrorToast('Houve um erro ao salvar o ativo.');
    }
  };

  const handleSubmit = () => {
    if (formRef.current) formRef.current.handleSubmit();
  };

  const handleAddToGroup = (selectedAssets: Asset[]) => {
    formRef.current?.setFieldValue('assetGroupId', selectedAssets[0].id);
  };

  const handleCloseAddToGroupModal = () => {
    formRef.current?.setFieldValue('assetGroupId', null);

    closeAll();
  };

  const showAlert = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = true;
    return true;
  };

  useEffect(() => {
    if (isDirty) {
      window.addEventListener('beforeunload', showAlert);
    }

    return function cleanup() {
      window.removeEventListener('beforeunload', showAlert);
    };
  }, [isDirty]);

  const can = (action: Action): boolean =>
    apiCan(action, asset?.caseNegotiatorId);

  const canUser = {
    showLawsuit: asset?.isGroup
      ? can(Action.CASOS_RELATORIO_GRUPO_VER_PROCESSO)
      : can(Action.CASOS_RELATORIO_ATIVO_VER_PROCESSO),
    showContract: asset?.isGroup
      ? can(Action.CASOS_RELATORIO_GRUPO_VER_CONTRATO)
      : can(Action.CASOS_RELATORIO_ATIVO_VER_CONTRATO),
    showGroup: can(Action.CASOS_RELATORIO_ATIVO_VER_GRUPO),
    deleteAsset: can(Action.CASOS_RELATORIO_ATIVO_EXCLUIR),
    deleteGroup: can(Action.CASOS_RELATORIO_GRUPO_EXCLUIR),
    addToAGroup: can(Action.CASOS_RELATORIO_ATIVO_ADICIONAR_A_UM_GRUPO),
    removeFromGroup: can(Action.CASOS_RELATORIO_ATIVO_REMOVER_DO_GRUPO)
  };

  const saveOptions = asset?.isGroup
    ? {
        editReport: can(Action.CASOS_RELATORIO_GRUPO_EDITAR),
        addAppraisals: can(Action.CASOS_RELATORIO_GRUPO_AVALIACAO_INCLUIR),
        editAppraisals: can(Action.CASOS_RELATORIO_GRUPO_AVALIACAO_EDITAR),
        deleteAppraisals: can(Action.CASOS_RELATORIO_GRUPO_AVALIACAO_EXCLUIR),
        addFiles: can(Action.CASOS_RELATORIO_GRUPO_ARQUIVOS_INCLUIR),
        deleteFiles: can(Action.CASOS_RELATORIO_GRUPO_ARQUIVOS_EXCLUIR)
      }
    : {
        addPhoto: can(Action.CASOS_RELATORIO_ATIVO_SUBIR_FOTO),
        editReport: can(Action.CASOS_RELATORIO_ATIVO_EDITAR),
        addAppraisals: can(Action.CASOS_RELATORIO_ATIVO_AVALIACAO_INCLUIR),
        editAppraisals: can(Action.CASOS_RELATORIO_ATIVO_AVALIACAO_EDITAR),
        deleteAppraisals: can(Action.CASOS_RELATORIO_ATIVO_AVALIACAO_EXCLUIR),
        addFiles: can(Action.CASOS_RELATORIO_ATIVO_ARQUIVOS_INCLUIR),
        deleteFiles: can(Action.CASOS_RELATORIO_ATIVO_ARQUIVOS_EXCLUIR)
      };

  const shouldShowSaveButton = Object.values(saveOptions).includes(true);

  const shouldShowGroupButton =
    asset &&
    !asset.isGroup &&
    [AssetType.REAL_ESTATE, AssetType.RURAL_PROPERTY].includes(asset.type);

  return (
    <Layout scrollTopOnMount>
      <Prompt
        when={isDirty}
        message="Existem alterações não salvas. Deseja realmente sair da página?"
      />

      <Header
        sticky
        isLoading={isLoading}
        actionsComponent={
          <>
            {(asset?.isGroup ? canUser.deleteGroup : canUser.deleteAsset) && (
              <Button
                small
                outline
                danger
                type="button"
                onClick={handleDelete}
                disabled={isDeleting}
              >
                Excluir {asset?.isGroup ? 'grupo' : 'ativo'}
              </Button>
            )}

            {!asset?.assetGroupId &&
              shouldShowGroupButton &&
              canUser.addToAGroup && (
                <Button
                  small
                  outline
                  highlight
                  type="button"
                  onClick={toggle('addToGroup')}
                >
                  Adicionar a um grupo
                </Button>
              )}

            {asset?.assetGroupId &&
              shouldShowGroupButton &&
              canUser.removeFromGroup && (
                <Button
                  small
                  outline
                  danger
                  type="button"
                  onClick={handleRemove}
                >
                  Remover do grupo
                </Button>
              )}

            {shouldShowSaveButton && (
              <Button
                small
                highlight
                type="submit"
                onClick={handleSubmit}
                disabled={!isDirty}
              >
                Salvar
              </Button>
            )}
          </>
        }
      >
        <Link
          iconName="arrow-left"
          to={returnTo ?? `/cases/${caseId}/resources`}
        >
          Voltar
        </Link>
        <Title>{asset?.name ?? ''}</Title>

        {asset?.executions?.map(execution => (
          <ExecutionStatus
            key={execution.id}
            caseId={caseId}
            execution={execution}
            disabled={!canUser.showLawsuit}
          />
        ))}

        {asset?.guarantee && (
          <GuaranteeStatus
            caseId={caseId}
            guarantee={asset.guarantee}
            disabled={!canUser.showContract}
          />
        )}

        {asset?.reclaimings.map(reclaiming => (
          <ReclaimingStatus
            key={reclaiming.id}
            caseId={caseId}
            reclaiming={reclaiming}
            disabled={!canUser.showContract}
          />
        ))}

        {asset?.assetGroupId && (
          <GroupStatus
            caseId={caseId}
            asset={asset}
            disabled={!canUser.showGroup}
          />
        )}

        {isDirty && (
          <span className={styles.info}>Existem alterações não salvas</span>
        )}
      </Header>

      <Form
        asset={asset}
        caseId={caseId}
        ref={formRef}
        isLoading={isLoading}
        onDirty={value => setIsDirty(value)}
        onSubmit={onSubmit}
      />

      {isOpen.addToGroup && asset && (
        <Modal
          title="Adicionar ativo a um grupo"
          isOpen={isOpen.addToGroup}
          onClose={handleCloseAddToGroupModal}
          large
        >
          <AssetSelectableList
            caseId={caseId}
            onSelect={handleAddToGroup}
            searchDefaultParams={{ types: [asset.type], isGroup: true }}
            singleSelection
          />
          <Stack marginTop={StackMargin.LARGE}>
            <Button
              small
              highlight
              type="submit"
              onClick={handleSubmit}
              disabled={!isDirty}
              centered
              dataTestId="add-to-grup"
            >
              Adicionar
            </Button>
          </Stack>
        </Modal>
      )}
    </Layout>
  );
};

export default AssetReport;
