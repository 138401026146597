import { getLabel } from 'domain/summary';

import React, { useState } from 'react';
import { generatePath } from 'react-router-dom';

import {
  Button,
  Checkbox,
  FormContainer,
  Stack,
  StackMargin,
  openErrorToast
} from 'ui';

import { SummaryInfoType } from 'types';

import style from './Form.module.scss';

type BoxLabelValue = {
  label: string;
  checked: boolean;
};

type Boxes = { [key in SummaryInfoType]: BoxLabelValue };

type Props = {
  onConfirm: (path: string) => void;
  caseId: string;
  preSelected?: string[];
};

const initialState = Object.values(SummaryInfoType).reduce(
  (values, key) => ({
    ...values,
    [key]: { label: getLabel(key), checked: false }
  }),
  {} as Boxes
);

const Form = ({ caseId, onConfirm, preSelected }: Props) => {
  const [boxes, setBoxes] = useState<Boxes>(
    preSelected?.filter(Boolean).reduce(
      (acc, id) => ({
        ...acc,
        [id]: { ...initialState[id as SummaryInfoType], checked: true }
      }),
      initialState
    ) ?? initialState
  );

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    event.preventDefault();

    const { label, checked } = boxes[id as SummaryInfoType];

    return setBoxes(currentBoxes => ({
      ...currentBoxes,
      [id]: { label, checked: !checked }
    }));
  };

  const handleNavigate = () => {
    const checkedBoxes = Object.entries(boxes).filter(
      ([, { checked }]) => checked
    );

    if (!checkedBoxes.length)
      return openErrorToast(
        'É necessário selecionar ao menos uma checkbox para gerar o resumo'
      );

    const path = generatePath('/cases/:caseId/summary', {
      caseId
    });

    const queryValues = checkedBoxes.map(([id]) => id).join(',');

    return onConfirm(path + '?infos=' + queryValues);
  };

  return (
    <FormContainer>
      <h2 className={style.subtitle}>Ver resumo do caso</h2>
      {Object.entries(boxes).map(([id, { label, checked }]) => (
        <Stack marginTop={StackMargin.MEDIUM} key={id}>
          <Button
            className={style.buttonWrap}
            outline
            centered
            highlight={checked}
            onClick={e => handleClick(e, id)}
          >
            <Checkbox
              label={label}
              id={id}
              name={id}
              checked={checked}
              onChange={() => {}}
            />
          </Button>
        </Stack>
      ))}
      <Stack marginTop={StackMargin.XLARGE}>
        <Button highlight onClick={handleNavigate} centered>
          Ver resumo
        </Button>
      </Stack>
    </FormContainer>
  );
};

export default Form;
