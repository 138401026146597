import React, { ReactNode, useEffect } from 'react';
import cn from 'classnames';

import { SystemSidebar } from 'components';

import style from './Layout.module.scss';

type Props = {
  scrollTopOnMount?: boolean;
  children: ReactNode;
  mainClassName?: string;
};

const Layout = ({
  children,
  scrollTopOnMount = false,
  mainClassName
}: Props) => {
  useEffect(() => {
    if (scrollTopOnMount) {
      window.scrollTo(0, 0);
    }
  }, [scrollTopOnMount]);

  const mainClass = cn(style.main, mainClassName);

  return (
    <div className={style.pageLayout}>
      <aside className={style.sidebar}>
        <SystemSidebar />
      </aside>

      <main className={mainClass}>{children}</main>
    </div>
  );
};

export default Layout;
