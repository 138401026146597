import { getReportLabel } from 'domain/summary';

import React, { useState } from 'react';
import { generatePath } from 'react-router';

import {
  Button,
  Checkbox,
  FormContainer,
  Stack,
  StackMargin,
  openErrorToast
} from 'ui';

import {
  IndividualSummaryInfoType,
  CompanySummaryInfoType,
  EntityType
} from 'types';

import style from './Form.module.scss';

type BoxLabelValue = {
  label: string;
  checked: boolean;
};

type Boxes = {
  [key in IndividualSummaryInfoType | CompanySummaryInfoType]: BoxLabelValue;
};

type Props = {
  caseId: string;
  reportId: string;
  showSavedPosts: boolean;
  entityType?: string;
  preSelected?: string[];
  onConfirm: (path: string) => void;
};

const Form = ({
  caseId,
  reportId,
  showSavedPosts,
  entityType,
  preSelected,
  onConfirm
}: Props) => {
  const ReportSummaryInfoType =
    entityType === EntityType.INDIVIDUAL
      ? IndividualSummaryInfoType
      : CompanySummaryInfoType;

  const allowedBoxes = (id: string) => {
    if (id === IndividualSummaryInfoType.SAVED_POSTS) return showSavedPosts;

    return id;
  };

  const initialState = Object.values(ReportSummaryInfoType).reduce(
    (values, key) => ({
      ...values,
      [key]: { label: getReportLabel(key), checked: false }
    }),
    {} as Boxes
  );

  const [boxes, setBoxes] = useState<Boxes>(
    preSelected?.filter(Boolean).reduce(
      (acc, id) => ({
        ...acc,
        [id]: {
          ...initialState[
            id as IndividualSummaryInfoType | CompanySummaryInfoType
          ],
          checked: true
        }
      }),
      initialState
    ) ?? initialState
  );

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    event.preventDefault();

    const { label, checked } = boxes[
      id as IndividualSummaryInfoType | CompanySummaryInfoType
    ];

    return setBoxes(currentBoxes => ({
      ...currentBoxes,
      [id]: { label, checked: !checked }
    }));
  };

  const handleNavigate = () => {
    const checkedBoxes = Object.entries(boxes).filter(
      ([, { checked }]) => checked
    );

    if (!checkedBoxes.length)
      return openErrorToast(
        'É necessário selecionar ao menos uma checkbox para gerar o resumo'
      );

    const path = generatePath('/cases/:caseId/reports/:reportId/summary', {
      caseId,
      reportId
    });

    const queryValues = checkedBoxes.map(([id]) => id).join(',');

    return onConfirm(path + '?infos=' + queryValues);
  };

  return (
    <FormContainer>
      <h2 className={style.subtitle}>Ver resumo do suspeito</h2>

      {Object.entries(boxes)
        .filter(([id, _]) => allowedBoxes(id))
        .map(([id, { label, checked }]) => (
          <Stack marginTop={StackMargin.MEDIUM} key={id}>
            <Button
              outline
              centered
              highlight={checked}
              className={style.buttonWrap}
              onClick={e => handleClick(e, id)}
            >
              <Checkbox
                label={label}
                id={id}
                name={id}
                checked={checked}
                onChange={() => {}}
              />
            </Button>
          </Stack>
        ))}
      <Stack marginTop={StackMargin.XLARGE}>
        <Button centered highlight onClick={handleNavigate}>
          Ver resumo
        </Button>
      </Stack>
    </FormContainer>
  );
};

export default Form;
