import React, { useState, useContext } from 'react';
import _ from 'lodash';

import { Button, openSuccessToast, Accordion, StackMargin, Stack } from 'ui';
import { can, Action } from 'api';
import { CaseContext } from 'components';
import { applyMask } from 'utils';
import { Reclaiming as ReclaimingData } from 'types';
import Card from './Card';

type ReclaimingCard = {
  [index: string]: {
    showModeEnabled?: boolean;
    data?: ReclaimingData;
  };
};

type Props = {
  contractId?: string;
  fetchedReclaimings?: ReclaimingData[];
  caseId: string;
};

const Reclaimings = ({
  contractId,
  fetchedReclaimings = [],
  caseId
}: Props) => {
  const reclaimingsState: ReclaimingCard = fetchedReclaimings.reduce(function(
    accumulator: ReclaimingCard,
    reclaiming: ReclaimingData,
    key: number
  ) {
    const reclaimingsUpdated: ReclaimingCard = {
      [key]: {
        data: reclaiming,
        showModeEnabled: true
      },
      ...accumulator
    };

    return reclaimingsUpdated;
  },
  {});
  const [reclaimings, setReclaimings] = useState<ReclaimingCard>(
    reclaimingsState
  );

  const [totalAmount, setTotalAmount] = useState<number>(
    _.sumBy(fetchedReclaimings, 'totalAmount')
  );

  const { caseData } = useContext(CaseContext);

  const handleNewReclaiming = (): void => {
    const reclaimingKeys: string | undefined = Object.keys(reclaimings).pop();
    const newKey: number = reclaimingKeys
      ? parseInt(reclaimingKeys, 10) + 1
      : 0;

    const newReclaimings: ReclaimingCard = {
      [newKey]: {
        showModeEnabled: false
      }
    };
    setReclaimings({ ...reclaimings, ...newReclaimings });
  };

  function handleDeleteReclaiming(key: string) {
    return function() {
      setReclaimings(_.omit(reclaimings, key));
      openSuccessToast('Recuperação excluído com sucesso!');
    };
  }

  function handleCalcAmount(amount: number) {
    setTotalAmount(totalAmount + amount);
  }

  const negotiatorId = caseData?.negotiator?.id;

  const canUser = {
    showReclaimings: can(Action.CASOS_RECUPERACOES_CONSULTAR, negotiatorId),
    addReclaiming: can(Action.CASOS_RECUPERACOES_INCLUIR, negotiatorId)
  };

  return (
    <>
      {canUser.showReclaimings && (
        <Accordion
          title="Recuperações"
          badgeContent={_.size(reclaimings)}
          headerInfo={{
            label: 'Total de recuperações: ',
            value: applyMask('currency', totalAmount.toString()),
            dataTestId: 'reclaimings-total-amount'
          }}
        >
          {Object.entries(reclaimings).map(([key, reclaiming], index) => (
            <Stack
              marginTop={index === 0 ? StackMargin.ZERO : StackMargin.MEDIUM}
              key={`stack-${key}`}
            >
              <Card
                contractId={contractId!}
                caseId={caseId}
                data-testid="reclaiming-card"
                onDelete={handleDeleteReclaiming(key)}
                key={`ReclaimingCard-${key}`}
                index={index}
                reclaimingData={reclaiming.data}
                handleCalcAmount={handleCalcAmount}
                htmlId={key}
              />
            </Stack>
          ))}
          {canUser.addReclaiming && (
            <Stack marginTop={StackMargin.MEDIUM}>
              <Button
                onClick={handleNewReclaiming}
                highlight
                outline
                centered
                dataTestId="add-new-reclaiming"
              >
                Adicionar recuperação
              </Button>
            </Stack>
          )}
        </Accordion>
      )}
    </>
  );
};

export default Reclaimings;
