export type Page = {
  label: string;
  incrementValue: number;
};

export enum Marker {
  LEFT = 'left-marker',
  RIGHT = 'right-marker',
  DOUBLE_LEFT = 'double-left-marker',
  DOUBLE_RIGHT = 'double-right-marker'
}

export const PER_PAGE = 'por página';

export const SELECT_OPTIONS = [
  {
    value: '6',
    label: `6 ${PER_PAGE}`
  },
  {
    value: '10',
    label: `10 ${PER_PAGE}`
  },
  {
    value: '12',
    label: `12 ${PER_PAGE}`
  },
  {
    value: '20',
    label: `20 ${PER_PAGE}`
  },
  {
    value: '100',
    label: `100 ${PER_PAGE}`
  }
];

export const MAX_PAGES_WITHOUT_MARKERS = 5;
export const MAX_PAGE_WITHOUT_FIRST_MARKER = 4;
export const MAX_DISTANCE_FROM_END_WITHOUT_SECOND_MARKER = 3;

export const PAGES_TO_RENDER = 3;
export const PAGES_TO_SKIP = 5;
export const EXTRA_PAGES_TO_RENDER = 1;

export const PAGES_TO_SKIP_LABEL = 'cinco';

const shouldShowAllPages = (totalPages: number): boolean =>
  totalPages <= MAX_PAGES_WITHOUT_MARKERS;

const isAtStart = (currentPage: number): boolean =>
  currentPage < MAX_PAGE_WITHOUT_FIRST_MARKER;

const isAtEnd = (totalPages: number, currentPage: number): boolean =>
  totalPages - currentPage < MAX_DISTANCE_FROM_END_WITHOUT_SECOND_MARKER;

type CreatePagesProps = {
  pagesToCreate: number;
  startPage: number;
  currentPage: number;
};

const createPages = ({
  pagesToCreate,
  startPage,
  currentPage
}: CreatePagesProps): Page[] =>
  Array.from({ length: pagesToCreate }, () =>
    createPage(startPage - currentPage, (startPage++).toString())
  );

type CreateMarkerPageProps = {
  currentPage: number;
  marker: Marker;
  totalPages: number;
};

const createMarkerPage = ({
  currentPage,
  marker,
  totalPages
}: CreateMarkerPageProps) => {
  const incrementValue =
    marker === Marker.DOUBLE_LEFT
      ? Math.max(-PAGES_TO_SKIP, 1 - currentPage)
      : Math.min(PAGES_TO_SKIP, totalPages - currentPage);
  return { incrementValue, label: marker };
};

const createPage = (incrementValue: number, label: string) => ({
  incrementValue: incrementValue,
  label: label
});

type RenderPagesProps = {
  currentPage: number;
  totalPages: number;
};

const createInitialPages = ({ currentPage, totalPages }: RenderPagesProps) => {
  const pagesToRender = Math.max(
    currentPage + EXTRA_PAGES_TO_RENDER,
    PAGES_TO_RENDER
  );
  const initialPages = createPages({
    pagesToCreate: pagesToRender,
    startPage: 1,
    currentPage
  });

  return [
    ...initialPages,
    createMarkerPage({ currentPage, marker: Marker.DOUBLE_RIGHT, totalPages }),
    createPage(totalPages - currentPage, totalPages.toString())
  ];
};

const createFinalPages = ({ currentPage, totalPages }: RenderPagesProps) => {
  const pagesToRender = Math.max(
    totalPages - currentPage + EXTRA_PAGES_TO_RENDER + 1,
    PAGES_TO_RENDER
  );
  const lastPages = createPages({
    pagesToCreate: pagesToRender,
    startPage: totalPages - pagesToRender + EXTRA_PAGES_TO_RENDER,
    currentPage: currentPage
  });

  return [
    createPage(1 - currentPage, '1'),
    createMarkerPage({ currentPage, marker: Marker.DOUBLE_LEFT, totalPages }),
    ...lastPages
  ];
};

const createPagesWithBothMarkers = ({
  currentPage,
  totalPages
}: RenderPagesProps) => {
  const pages = createPages({
    pagesToCreate: PAGES_TO_RENDER,
    startPage: currentPage - EXTRA_PAGES_TO_RENDER,
    currentPage: currentPage
  });

  return [
    createPage(1 - currentPage, '1'),
    createMarkerPage({ currentPage, marker: Marker.DOUBLE_LEFT, totalPages }),
    ...pages,
    createMarkerPage({ currentPage, marker: Marker.DOUBLE_RIGHT, totalPages }),
    createPage(totalPages - currentPage, totalPages.toString())
  ];
};

export const createPaginationArray = (
  currentPage: number,
  totalPages: number
): Page[] => {
  if (shouldShowAllPages(totalPages)) {
    return createPages({
      pagesToCreate: totalPages,
      startPage: 1,
      currentPage: currentPage
    });
  }

  if (isAtStart(currentPage)) {
    return createInitialPages({ currentPage, totalPages });
  }

  if (isAtEnd(totalPages, currentPage)) {
    return createFinalPages({ currentPage, totalPages });
  }

  return createPagesWithBothMarkers({ currentPage, totalPages });
};

export const calculateBaseIndex = (
  currentPage: number,
  itemsPerPage: number
): number => (currentPage - 1) * itemsPerPage + 1;

export const calculateEndIndex = (
  currentPage: number,
  itemsPerPage: number,
  totalEntries: number
): number => Math.min(currentPage * itemsPerPage, totalEntries);
