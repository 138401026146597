import React, { ReactNode } from 'react';
import cn from 'classnames';

type Props = {
  children: ReactNode;
  onClick?: () => void;
  small?: boolean;
  clickable?: boolean;
  dataTestid?: string;
  className?: string;
};

const TableRow = ({
  children,
  onClick,
  small,
  clickable = false,
  dataTestid = 'tableRow',
  className = ''
}: Props) => {
  const rowClass = cn('row', className, {
    '-small': small,
    '-clickable': clickable
  });

  return (
    <tr className={rowClass} data-testid={dataTestid} onClick={onClick}>
      {children}
    </tr>
  );
};

export default TableRow;
