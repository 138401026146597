import React from 'react';

import { Card, Loader } from 'ui';

import styles from './ExternalLawsuitContentCard.module.scss';

const ExternalLawsuitContentCardLoader = () => {
  return (
    <Card medium aria-label="lawsuit">
      <Loader width={1.6} />

      <div className={styles.info}>
        <Loader width={1.28} />
      </div>

      <Loader width={2.56} />

      <div className={styles.distributionDateWrapper}>
        <Loader width={1.28} />
        <Loader width={1.28} />
      </div>
    </Card>
  );
};

export default ExternalLawsuitContentCardLoader;
