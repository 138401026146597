import React, { ReactNode } from 'react';

import style from './CellContent.module.scss';

type Props = {
  hasActions?: boolean;
  children: ReactNode;
};

const CellContent = ({ children, hasActions = false }: Props) => {
  if (hasActions) return <div className={style.actions}> {children} </div>;

  return <>{children}</>;
};

export default CellContent;
