import { lawsuitNumber } from 'domain/lawsuits';
import { fetchReportExternalLawsuitsSummary } from 'api';
import { ExternalLawsuitCard } from 'components';
import { useQuery } from 'hooks';
import SummaryTitle from 'pages/reports/SharedComponents/SummaryTitle';
import React from 'react';
import { openErrorToast, Stack, StackMargin } from 'ui';
import { createArrayFrom } from 'utils';
import style from './ExternalLawsuitsSummary.module.scss';

type Props = {
  caseId: string;
  reportId: string;
};

const ExternalLawsuitsSummary = ({ reportId, caseId }: Props) => {
  const { data: externalLawsuits = [], isLoading, error } = useQuery(
    fetchReportExternalLawsuitsSummary,
    {
      caseId,
      reportId
    }
  );

  if (error) {
    openErrorToast(
      'Erro ao carregar informações, recarregue a página e tente novamente.'
    );
  }

  let content = createArrayFrom({
    size: 2,
    fillFunction: index => <ExternalLawsuitCard isLoading key={index} />
  });

  if (!isLoading) {
    content = externalLawsuits.map(externalLawsuit => (
      <ExternalLawsuitCard
        key={externalLawsuit.id}
        className={style.card}
        externalLawsuit={externalLawsuit}
        showParties
        title={
          <h3 className={style.cardTitle}>
            {lawsuitNumber(externalLawsuit.number)}
          </h3>
        }
      />
    ));
  }

  return (
    <>
      <Stack marginBottom={StackMargin.SMALL}>
        <SummaryTitle>{`Processos (${externalLawsuits.length})`}</SummaryTitle>
      </Stack>
      <div className={style.cardWrapper}>{content}</div>
    </>
  );
};

export default ExternalLawsuitsSummary;
