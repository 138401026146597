import React from 'react';

import { Movement, SourceType, ExternalLawsuitMovement } from 'types';
import { FileList } from 'components';
import { CollapsibleParagraph } from 'ui';

type Props = {
  caseId: string;
  movement: Movement | ExternalLawsuitMovement;
  showDescription?: boolean;
  showMovementFiles?: boolean;
};

const InfoDetail = ({
  caseId,
  movement,
  showDescription = true,
  showMovementFiles = true
}: Props) => {
  const hasMovementFiles = movement.fileUploads.length > 0;

  return (
    <>
      {movement.source === SourceType.MANUAL && (
        <>
          {showDescription && movement.description && (
            <CollapsibleParagraph
              dataTestId="manual-movement-description"
              className="item"
              text={movement.description}
            />
          )}
          <p className="item">Movimentação criada por {movement.user?.name}</p>
          {showMovementFiles && hasMovementFiles && (
            <FileList
              showDelete={false}
              caseId={caseId}
              files={movement.fileUploads}
            />
          )}
        </>
      )}

      {movement.source === SourceType.EXTERNAL && (
        <>
          <p className="item">{movement.type}</p>
          {showDescription && movement.description && (
            <CollapsibleParagraph
              dataTestId="external-movement-description"
              className="item"
              text={movement.description}
            />
          )}
        </>
      )}
    </>
  );
};

export default InfoDetail;
