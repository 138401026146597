import React, { useEffect, useCallback, useContext } from 'react';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';

import { fetchReports, fetchReport, fetchCaseEntities, can, Action } from 'api';
import { useFeatureFlag, useQuery } from 'hooks';

import {
  Feature,
  Report,
  FIRST_PAGE,
  DEFAULT_PAGE_SIZE,
  PaginatedResource
} from 'types';

import { CaseContext, useChannel, usePaginationContext } from 'components';
import { Accordion, openErrorToast, Stack, StackMargin } from 'ui';
import Reports from './Reports';
import Suspects from './Suspects/Suspects';

type Props = {
  caseId: string;
};

const DEBOUNCE_TIME = 500;

const People = ({ caseId }: Props) => {
  const { getPagination, getOrderBy } = usePaginationContext();
  const { caseData } = useContext(CaseContext);

  const {
    data: suspects = [],
    pagination: suspectsPagination,
    isLoading: isLoadingSuspects,
    error: suspectsError,
    setData: setSuspects,
    refetch: refetchSuspects
  } = useQuery(fetchCaseEntities, {
    caseId,
    ...getPagination(PaginatedResource.PEOPLE_SUSPECTS),
    ...getOrderBy(PaginatedResource.PEOPLE_SUSPECTS)
  });

  const {
    data: reports = [],
    pagination: reportsPagination,
    isLoading: isLoadingReports,
    error: reportsError,
    setData: setReports,
    refetch: refetchReports
  } = useQuery(fetchReports, {
    caseId,
    ...getPagination(PaginatedResource.PEOPLE_REPORTS),
    ...getOrderBy(PaginatedResource.PEOPLE_REPORTS)
  });

  const updateReport = useCallback(
    (report: Report): void => {
      setReports(reports => {
        return reports?.map(currentReport => {
          if (currentReport.id === report.id) return report;
          return currentReport;
        });
      });

      setSuspects(suspects => {
        return suspects?.map(suspect => {
          if (!suspect.currentReport) return suspect;
          if (suspect.currentReport.id !== report.id) return suspect;

          return { ...suspect, currentReport: report };
        });
      });
    },
    [setReports, setSuspects]
  );

  const [reportIsEnabled] = useFeatureFlag(Feature.CASE_REPORTS);

  const { channel, isConnected } = useChannel('cases:' + caseId);

  const getReport = useCallback(
    async reportId => {
      try {
        const response = await fetchReport({ caseId, reportId });

        updateReport(response.data);
      } catch (error) {
        noop();
      }
    },
    [caseId, updateReport]
  );

  useEffect(() => {
    if (!channel || !isConnected || !reportIsEnabled) return;

    const debouncer = debounce(response => {
      if (response.id) {
        getReport(response.id);
      }
    }, DEBOUNCE_TIME);

    channel!.on('report_updated', debouncer);

    return () => {
      if (channel) {
        channel.off('report_updated');
      }

      debouncer.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, isConnected, reportIsEnabled]);

  const handleRefetchSuspects = () => {
    refetchSuspects({
      caseId,
      pagination: {
        page: suspectsPagination?.number ?? FIRST_PAGE,
        pageSize: suspectsPagination?.size ?? DEFAULT_PAGE_SIZE
      }
    });
  };

  const handleRefetchReports = () => {
    refetchReports({
      caseId,
      pagination: {
        page: reportsPagination?.number ?? FIRST_PAGE,
        pageSize: reportsPagination?.size ?? DEFAULT_PAGE_SIZE
      }
    });
  };

  if (suspectsError) {
    openErrorToast('Ocorreu um erro ao listar suspeitos.');
  }

  if (reportsError) {
    openErrorToast('Ocorreu um erro ao listar consultas.');
  }

  const canShowReports = can(
    Action.CASOS_RASTREAMENTO_CONSULTAS_CONSULTAR,
    caseData?.negotiator.id
  );

  const canShowSuspects = can(
    Action.CASOS_RASTREAMENTO_SUSPEITOS_CONSULTAR,
    caseData?.negotiator.id
  );

  return (
    <Accordion title="Pessoas">
      <>
        {canShowSuspects && (
          <Suspects
            isLoading={isLoadingSuspects}
            caseId={caseId}
            suspects={suspects}
            setSuspects={setSuspects}
            refetchReports={handleRefetchReports}
            refetchSuspects={handleRefetchSuspects}
            pagination={suspectsPagination}
          />
        )}
        {reportIsEnabled && canShowReports && (
          <Stack marginTop={StackMargin.LARGE}>
            <Reports
              isLoading={isLoadingReports}
              caseId={caseId}
              reports={reports}
              refetchSuspects={handleRefetchSuspects}
              refetchReports={handleRefetchReports}
              pagination={reportsPagination}
              setReports={setReports}
            />
          </Stack>
        )}
      </>
    </Accordion>
  );
};

export default People;
