import { lawsuitNumber } from 'domain/lawsuits';
import React from 'react';
import { ExternalLawsuitCard } from 'components';
import { ExternalLawsuit } from 'types';
import { Loader, Toast, ToastType } from 'ui';

import style from './Lawsuits.module.scss';

type Props = {
  externalLawsuits: ExternalLawsuit[];
  isLoading: boolean;
  error?: boolean;
};

const ExternalLawsuitList = ({ externalLawsuits, isLoading, error }: Props) => {
  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return (
      <Toast
        type={ToastType.ERROR}
        message={'Erro ao listar processos, tente novamente.'}
      />
    );
  }

  return (
    <div className={style.cardsWrapper}>
      {externalLawsuits?.map(externalLawsuit => (
        <ExternalLawsuitCard
          className={style.card}
          key={externalLawsuit.id}
          externalLawsuit={externalLawsuit}
          hideMonitoringIcon
          hideTrackingIcon
          showParties
          title={
            <h3 className={style.cardTitle}>
              {lawsuitNumber(externalLawsuit.number)}
            </h3>
          }
        />
      ))}
    </div>
  );
};

export default ExternalLawsuitList;
