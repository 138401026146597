import { ReportAddress } from 'types';
import { CaseEntity, City, Field } from 'types';

export type ReportLocation = {
  id: string;
  field: Field;
  address: ReportAddress | null;
  businessName: string | null;
  cnpj: string | null;
  type: ReportLocationType;
};

export type ReportLocationAttributes = {
  id: string;
  caseId: string;
  abroadCity: string | null;
  city: City | null;
  comment: string | null;
  isFromReport: boolean;
  status: string;
  entity: CaseEntity;
};

export enum ReportLocationType {
  BRANCH = 'branch',
  HEADQUARTER = 'headquarter'
}
