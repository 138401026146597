import * as Courts from 'domain/courts';
import React, { useEffect, useReducer } from 'react';
import filter from 'lodash/filter';
import find from 'lodash/find';
import pickBy from 'lodash/pickBy';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import { Court, CourtFormAttributes, Option, City } from 'types';
import { SelectField } from 'ui';
import { searchCourts } from 'api';
import { useQueryCall } from 'hooks';
import { stateOptions } from 'utils';

enum FieldNames {
  STATE = 'state',
  CITY_ID = 'cityId',
  NAME = 'name',
  JUDGING_BODY = 'judgingBody',
  INSTANCE = 'instance'
}

type Props = {
  onChange: (courtID: string) => void;
  onBlur: () => void;
  error?: string;
  defaultValue?: CourtFormAttributes;
  isDisabledOnEdit?: boolean;
};

type ReducerAction = {
  type: FieldNames | 'reset';
  payload: string;
  resetPayload: CourtFormAttributes;
};

const courtReducer = (state: CourtFormAttributes, action: ReducerAction) => {
  switch (action.type) {
    case FieldNames.STATE:
      return {
        ...state,
        instance: '',
        name: '',
        judgingBody: '',
        city: { id: '', state: action.payload, name: '' }
      };
    case FieldNames.CITY_ID:
      return {
        ...state,
        instance: '',
        name: '',
        judgingBody: '',
        city: { id: action.payload, state: state.city.state, name: '' }
      };
    case FieldNames.NAME:
      return {
        ...state,
        instance: '',
        name: action.payload
      };
    case FieldNames.JUDGING_BODY:
      return {
        ...state,
        instance: '',
        name: '',
        judgingBody: action.payload
      };
    case FieldNames.INSTANCE:
      return {
        ...state,
        instance: action.payload
      };
    case 'reset':
      return action.resetPayload;
    default:
      throw new Error();
  }
};

const CourtField = ({
  onChange,
  onBlur,
  error,
  defaultValue,
  isDisabledOnEdit = false
}: Props) => {
  const [court, setCourt] = useReducer(courtReducer, {
    instance: '',
    id: '',
    name: '',
    judgingBody: '',
    city: { id: '', state: '', name: '' }
  });

  const { data: courts, isLoading, request: fetchCourts } = useQueryCall<
    Court[],
    { state: string }
  >(searchCourts);

  useEffect(() => {
    court.city.state && fetchCourts({ state: court.city.state });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [court.city.state]);

  useEffect(() => {
    handleOnChangeCallback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [court.instance]);

  useEffect(() => {
    defaultValue &&
      setCourt({ type: 'reset', payload: '', resetPayload: defaultValue });
  }, [defaultValue]);

  const handleOnChangeCallback = (): void => {
    const selectedCourt = find<CourtFormAttributes>(courts, {
      city: { id: court.city.id },
      name: court.name,
      judgingBody: court.judgingBody,
      instance: court.instance
    });

    selectedCourt && onChange(selectedCourt.id!);
  };

  const cities: City[] = uniqBy(
    courts?.flatMap(coutr => coutr.city),
    'id'
  );

  const filterCourts = (
    filters: Partial<{
      name: string;
      judgingBody: string;
      instance: string;
      city: { id: string };
    }>
  ): Court[] =>
    filter(courts, {
      ...pickBy(filters, v => v !== '')
    });

  const handleChangeSelects = (fieldName: FieldNames, value: string) => {
    setCourt({ type: fieldName, payload: value, resetPayload: court });
    onChange('');
  };

  return (
    <>
      <SelectField
        name="uf"
        id="uf"
        title="UF (opcional)"
        value={court.city.state}
        disabled={isDisabledOnEdit}
        onChange={(event: React.ChangeEvent<{ value: string }>) => {
          handleChangeSelects(FieldNames.STATE, event.currentTarget.value);
        }}
        onBlur={onBlur}
        options={stateOptions}
        error={error && !court.city.state ? error : ''}
      />

      <SelectField
        name="cityId"
        id="cityId"
        title="Município (opcional)"
        value={court.city.id}
        onChange={(event: React.ChangeEvent<{ value: string }>) => {
          handleChangeSelects(FieldNames.CITY_ID, event.currentTarget.value);
        }}
        options={sortBy(cities || [], city => city.name).map(
          (city: City): Option => ({
            label: city.name ?? '',
            value: city.id ?? ''
          })
        )}
        onBlur={onBlur}
        disabled={isLoading || !court.city.state || isDisabledOnEdit}
        optional
        error={error && !court.city.id ? error : ''}
      />

      <SelectField
        name="judgingBody"
        id="judgingBody"
        title="Orgão/Tribunal (opcional)"
        value={court.judgingBody}
        onChange={(event: React.ChangeEvent<{ value: string }>) => {
          handleChangeSelects(
            FieldNames.JUDGING_BODY,
            event.currentTarget.value
          );
        }}
        options={sortBy(
          uniqBy(
            filterCourts({
              city: { id: court.city.id ?? '' }
            }),
            'judgingBody'
          ),
          court => court.judgingBody
        ).map(
          (court: Court): Option => ({
            label: court.judgingBody,
            value: court.judgingBody
          })
        )}
        onBlur={onBlur}
        disabled={isLoading || !court.city.id || isDisabledOnEdit}
        optional
        error={error && !court.judgingBody ? error : ''}
      />

      <SelectField
        name="courtName"
        id="courtName"
        title="Denominação/Vara (opcional)"
        value={court.name}
        onChange={(event: React.ChangeEvent<{ value: string }>) => {
          handleChangeSelects(FieldNames.NAME, event.currentTarget.value);
        }}
        options={sortBy(
          filterCourts({
            city: { id: court.city.id ?? '' },
            judgingBody: court.judgingBody
          }),
          court => court.name
        ).map(
          (court: Court): Option => ({
            label: court.name,
            value: court.name
          })
        )}
        onBlur={onBlur}
        disabled={isLoading || !court.judgingBody || isDisabledOnEdit}
        optional
        error={error && !court.name ? error : ''}
      />

      <SelectField
        name="instance"
        id="instance"
        title="Instância (opcional)"
        value={court.instance}
        onChange={(event: React.ChangeEvent<{ value: string }>) => {
          setCourt({
            type: FieldNames.INSTANCE,
            payload: event.currentTarget.value,
            resetPayload: court
          });
        }}
        onBlur={onBlur}
        options={sortBy(
          filterCourts({
            city: { id: court.city.id ?? '' },
            judgingBody: court.judgingBody,
            name: court.name
          }),
          court => court.instance
        ).map((court: Court): Option => Courts.instanceOption(court.instance))}
        disabled={isLoading || !court.name || isDisabledOnEdit}
        optional
        error={error && !court.instance ? error : ''}
      />
    </>
  );
};

export default CourtField;
