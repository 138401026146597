import React, {
  createContext,
  useContext,
  useReducer,
  useMemo,
  ReactNode
} from 'react';
import {
  Pagination,
  PaginationRequestParams,
  PaginatedResource,
  OrderBy,
  OrderByRequestParams
} from 'types';

type State = {
  state: PaginationState;
  changePagination: (page: PaginatedResource, pagination: Pagination) => void;
  changeOrderBy: (page: PaginatedResource, orderBy: OrderBy) => void;
  getPagination: (
    page: PaginatedResource
  ) => PaginationRequestParams | undefined;
  getOrderBy: (page: PaginatedResource) => OrderByRequestParams | undefined;
  changeCaseId: (caseId: string) => void;
};

type PaginationState = {
  caseId: string;
  paginations: {
    [key: string]: {
      pagination: Pagination;
      orderBy: OrderBy;
    };
  };
};

type Props = {
  children: ReactNode;
  value?: PaginationState;
};

enum ActionTypes {
  CHANGE_CASE_ID = 'change_case_id',
  CHANGE_PAGINATION = 'change_pagination',
  CHANGE_ORDER_BY = 'change_order_by'
}

type ChangeCaseId = {
  type: ActionTypes.CHANGE_CASE_ID;
  payload: string;
};

type ChangePagination = {
  type: ActionTypes.CHANGE_PAGINATION;
  payload: { resource: PaginatedResource; pagination: Pagination };
};

type ChangeOrderBy = {
  type: ActionTypes.CHANGE_ORDER_BY;
  payload: { resource: PaginatedResource; orderBy: OrderBy };
};

type Action = ChangeCaseId | ChangePagination | ChangeOrderBy;

const initialState: PaginationState = {
  caseId: '',
  paginations: {}
};

const PaginationContext = createContext<State>({
  state: initialState,
  changePagination: () => undefined,
  changeOrderBy: () => undefined,
  changeCaseId: () => undefined,
  getPagination: () => undefined,
  getOrderBy: () => undefined
});

const reducer = (state: PaginationState, action: Action): PaginationState => {
  switch (action.type) {
    case ActionTypes.CHANGE_PAGINATION: {
      const { resource, pagination } = action.payload;

      const updatedResource = {
        ...state.paginations[resource],
        ...{ pagination }
      };

      const updatedPaginations = Object.assign(state.paginations, {
        [resource]: updatedResource
      });

      return {
        ...state,
        paginations: updatedPaginations
      };
    }

    case ActionTypes.CHANGE_ORDER_BY: {
      const { resource, orderBy } = action.payload;

      const currentPagination = state.paginations[resource]?.pagination;

      const newPagination = currentPagination
        ? { pagination: { page: 1, pageSize: currentPagination.pageSize } }
        : {};

      const updatedPaginations = Object.assign(state.paginations, {
        [resource]: { orderBy, ...newPagination }
      });

      return {
        ...state,
        paginations: updatedPaginations
      };
    }

    case ActionTypes.CHANGE_CASE_ID: {
      if (state.caseId === action.payload) return state;

      return { caseId: action.payload, paginations: {} };
    }

    default:
      return state;
  }
};

const PaginationProvider = ({ children, value }: Props) => {
  const [state, dispatch] = useReducer(reducer, value ?? initialState);

  const contextValue = useMemo(() => {
    const getPagination = (
      paginatedResource: PaginatedResource
    ): PaginationRequestParams | undefined => {
      const pagination = state.paginations[paginatedResource]?.pagination;

      return pagination && { pagination };
    };

    const getOrderBy = (
      paginatedResource: PaginatedResource
    ): OrderByRequestParams | undefined => {
      const orderBy = state.paginations[paginatedResource]?.orderBy;

      return orderBy && { orderBy };
    };

    const changePagination = (
      paginatedResource: PaginatedResource,
      pagination: Pagination
    ) => {
      dispatch({
        type: ActionTypes.CHANGE_PAGINATION,
        payload: {
          resource: paginatedResource,
          pagination
        }
      });
    };

    const changeOrderBy = (
      paginatedResource: PaginatedResource,
      orderBy: OrderBy
    ) => {
      dispatch({
        type: ActionTypes.CHANGE_ORDER_BY,
        payload: {
          resource: paginatedResource,
          orderBy
        }
      });
    };

    const changeCaseId = (caseId: string) => {
      dispatch({
        type: ActionTypes.CHANGE_CASE_ID,
        payload: caseId
      });
    };

    return {
      state,
      changePagination,
      changeOrderBy,
      changeCaseId,
      getPagination,
      getOrderBy
    };
  }, [state]);

  return (
    <PaginationContext.Provider value={contextValue}>
      {children}
    </PaginationContext.Provider>
  );
};

const usePaginationContext = () => {
  return useContext(PaginationContext);
};

export { usePaginationContext, PaginationProvider };
