import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Action } from 'api';
import { PrivateRoute } from 'components';
import { CaseType } from 'types';
import ErrorsPage from 'pages/errors';

import { default as CasesEdit } from './cases/Edit';
import { default as CasesList } from './cases/List';
import { default as CasesNew } from './cases/New';
import { default as ReportsShow } from './reports/Show';
import { default as ReportsSummary } from './reports/Summary';
import { default as InstagramProfileShow } from './instagramProfiles/Show';

import ExternalLawsuit from './cases/ExternalLawsuit';
import { default as ChildLawsuitCreate } from './cases/InternalLawsuits/ChildLawsuit/Create';
import { default as ChildLawsuitEdit } from './cases/InternalLawsuits/ChildLawsuit/Edit';
import NewPassword from './authentication/NewPassword/NewPassword';
import Permissions from './permissions';
import UsefulLinks from './usefulLinks';
import Login from './authentication/Login';
import Logout from './authentication/Logout';
import Authenticate from './authentication/Login/Authenticate';
import AssetReport from './cases/AssetReport';
import CaseSummary from './cases/Summary';

const error404 = () => (
  <ErrorsPage title="Pagina não encontrada" description="ERRO 404" />
);

const error403 = () => (
  <ErrorsPage title="Você não tem essa permissão" description="ERRO 403" />
);

const caseTypes = Object.values(CaseType);

const Routes = () => {
  return (
    <Switch>
      <Route exact path={['/', '/cases', '/cases/']}>
        <Redirect to={`/cases/${CaseType.ATIVOS_ESPECIAIS}`} />
      </Route>
      <Route exact path="/authenticate" component={Authenticate} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/logout" component={Logout} />
      <Route exact path="/new_password" component={NewPassword} />
      <Route exact path="/not_found" component={error404} />
      <Route exact path="/permission_denied" component={error403} />

      <Switch>
        <PrivateRoute
          exact
          path={caseTypes.map(caseType => `/cases/:type(${caseType})`)}
          component={CasesList}
        />

        <Route strict exact path={['/cases/new', '/cases/new/*']}>
          <Switch>
            <PrivateRoute
              strict
              exact
              action={Action.CREATE_CASE}
              path={caseTypes.map(caseType => `/cases/new/:type(${caseType})`)}
              component={CasesNew}
            />

            <Redirect to="/not_found" />
          </Switch>
        </Route>

        <PrivateRoute
          exact
          path="/cases/:caseId/reports/:reportId"
          component={ReportsShow}
        />
        <PrivateRoute
          path="/cases/:caseId/reports/:reportId/summary"
          component={ReportsSummary}
        />
        <PrivateRoute
          exact
          path="/cases/:caseId/assets/:assetId"
          component={AssetReport}
        />
        <PrivateRoute
          exact
          path="/cases/:caseId/reports/:reportId/instagram_profiles/:instagramProfileId"
          component={InstagramProfileShow}
        />
        <PrivateRoute
          path="/cases/:caseId/external_lawsuits/:externalLawsuitId/:tab?"
          component={ExternalLawsuit}
        />
        <PrivateRoute
          exact
          path="/cases/:caseId/internal_lawsuits/:internalLawsuitId/children"
          component={ChildLawsuitCreate}
        />
        <PrivateRoute
          exact
          path="/cases/:caseId/internal_lawsuits/:internalLawsuitId/children/:childLawsuitId/:tab?"
          component={ChildLawsuitEdit}
        />

        <PrivateRoute path="/cases/:caseId/summary" component={CaseSummary} />

        <PrivateRoute path="/cases/:caseId/:tab?" component={CasesEdit} />

        <PrivateRoute path="/permissions/:tab?" component={Permissions} />

        <PrivateRoute path="/useful_links" component={UsefulLinks} />

        <Route
          component={() => (
            <ErrorsPage title="Pagina não encontrada" description="ERRO 404" />
          )}
        />
      </Switch>
    </Switch>
  );
};

export default Routes;
