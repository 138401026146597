import React from 'react';
import Icon from 'ui/Icon/Icon';
import cn from 'classnames';
import { DropDown } from 'ui';
import Button from '../Button';
import styles from './SplitDropdown.module.scss';

type Props = {
  danger?: boolean;
  disabled?: boolean;
  highlight?: boolean;
  options: DropdownOptions[];
  small?: boolean;
};

type DropdownOptions = {
  text: string;
  callback: () => void;
};

const getIconFillColor = ({
  danger,
  highlight,
  disabled
}: {
  danger?: boolean;
  highlight?: boolean;
  disabled?: boolean;
}): string => {
  if (danger) return '#e53935';
  if (highlight) return '#3949ab';
  if (disabled) return '#7a7e94';
  return '#62667a';
};

const SplitDropdown = ({
  danger,
  disabled,
  highlight,
  options,
  small
}: Props) => {
  const [mainButton, ...dropdown] = options;
  const iconFillColor = getIconFillColor({ danger, highlight, disabled });

  const dropdownOptions = dropdown.map(item => ({
    ...item,
    isNegative: danger,
    isDisabled: disabled
  }));

  const hasActions = dropdownOptions.length > 0;
  return (
    <div className={cn([styles.splitButton, small && styles.splitButtonSmall])}>
      <Button
        className={hasActions ? styles.borderlessRightButton : ''}
        danger={danger}
        disabled={disabled}
        highlight={highlight}
        onClick={mainButton.callback}
        outline
        small={small}
      >
        {mainButton.text}
      </Button>

      {hasActions && (
        <div
          className={cn([
            styles.dropdown,
            danger && styles.danger,
            highlight && styles.highlight
          ])}
        >
          <DropDown
            dropDownTrigger={
              <Icon small name="arrow-down" fill={iconFillColor} />
            }
            offset="-1px 0px"
            options={dropdownOptions}
            smallerFont
          />
        </div>
      )}
    </div>
  );
};

export default SplitDropdown;
