import { ExternalLawsuitInfo } from 'components';
import React from 'react';
import { ExternalLawsuitContent } from 'types';
import { Loader, Stack, StackMargin, Toast, ToastType } from 'ui';

import styles from './LawsuitFormContent.module.scss';

type Props = {
  externalLawsuitContent?: ExternalLawsuitContent | null;
  isLoading?: boolean;
  isHidden?: boolean;
  hasWebsocketFailed?: boolean;
};

const LawsuitFormContent = ({
  externalLawsuitContent,
  isLoading = false,
  isHidden = false,
  hasWebsocketFailed = false
}: Props) => {
  if (isHidden) return null;

  if (isLoading) {
    return (
      <Stack
        className={styles.modalContent}
        marginTop={StackMargin.XLARGE}
        marginBottom={StackMargin.XLARGE}
        dataTestId="lawsuit-content-loader"
      >
        <Loader width={2.56} />
        <span className={styles.loaderLabel}>
          Conectando-se com provedor Digesto
        </span>
      </Stack>
    );
  }

  if (hasWebsocketFailed) {
    return (
      <Stack
        className={styles.modalContent}
        marginTop={StackMargin.XLARGE}
        marginBottom={StackMargin.XLARGE}
      >
        <Toast
          type={ToastType.ERROR}
          message="Provedor Digesto falhou. Tente novamente ou comunique sobre a falha"
        />
      </Stack>
    );
  }

  if (!externalLawsuitContent) {
    return (
      <Stack
        className={styles.modalContent}
        marginTop={StackMargin.XLARGE}
        marginBottom={StackMargin.XLARGE}
      >
        Nenhum dado encontrado pelo provedor Digesto
      </Stack>
    );
  }

  return (
    <ExternalLawsuitInfo externalLawsuitContent={externalLawsuitContent} />
  );
};

export default LawsuitFormContent;
