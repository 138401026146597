import React from 'react';
import head from 'lodash/head';
import { FormikProps } from 'formik';

import { ExecutionRelatedAttributes } from 'types';
import { AssetSelectableList } from 'components';

type Props = {
  caseId: string;
  formik: FormikProps<ExecutionRelatedAttributes>;
};

const ExecutedAssetIdField = ({ caseId, formik }: Props) => {
  return (
    <AssetSelectableList
      caseId={caseId}
      onSelect={assets =>
        formik.setFieldValue('executedAssetId', head(assets)?.id)
      }
      singleSelection
    />
  );
};

export default ExecutedAssetIdField;
