import { Action, can, copyPermissions, fetchProfile, fetchProfiles } from 'api';
import { useQuery } from 'hooks';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Icon,
  Modal,
  openErrorToast,
  openSuccessToast,
  SelectField,
  Stack,
  StackMargin,
  Table
} from 'ui';
import sumBy from 'lodash/sumBy';
import { ProfileItem as ProfileItemType } from 'types';
import ExpandableRowItem from '../../ExpandableRowItem';
import FunctionalityRow from './FunctionalityRow';

import style from './ProfileItem.module.scss';

type Props = {
  profileId: string;
  columnsSizeStyle: string;
  setPermissionCounter: (amount: number) => void;
  shouldExpandAll: boolean;
};

const ProfileItem = ({
  profileId,
  columnsSizeStyle,
  setPermissionCounter,
  shouldExpandAll
}: Props) => {
  const {
    data: currentProfile,
    setData: setCurrentProfile,
    isLoading
  } = useQuery(fetchProfile, profileId);

  const currentEnabledAmount = currentProfile?.enabledAmount;
  const [selectedProfileIdToCopy, setSelectedProfileIdToCopy] = useState('');
  const [copiedFromProfileName, setCopiedFromProfileName] = useState('');
  const [profilesList, setProfilesList] = useState<ProfileItemType[]>([]);
  const [isCopyPermissionsModalOpen, setIsCopyPermissionsModalOpen] = useState(
    false
  );

  useEffect(() => {
    if (currentEnabledAmount !== undefined) {
      setPermissionCounter(currentEnabledAmount);
    }
  }, [currentEnabledAmount, setPermissionCounter]);

  const fetchProfilesList = useCallback(async () => {
    try {
      const response = await fetchProfiles();

      setProfilesList(response.data);
    } catch {
      openErrorToast(
        'Ocorreu um erro na listagem de perfis. Atualize a página e tente novamente.'
      );
    }
  }, []);

  useEffect(() => {
    isCopyPermissionsModalOpen && fetchProfilesList();
  }, [isCopyPermissionsModalOpen, fetchProfilesList]);

  const onPermissionChange = (permissionId: string, newValue: boolean) => {
    setCurrentProfile(previousProfile => {
      if (!previousProfile) return;

      const newPermissions = previousProfile.permissions.map(menu => {
        const newFunctionalities = menu.functionalities.map(functionality => {
          const newActions = functionality.actions.map(action =>
            action.id === permissionId ? { ...action, value: newValue } : action
          );

          return {
            ...functionality,
            actions: newActions,
            enabledAmount: sumBy(newActions, action => (action.value ? 1 : 0))
          };
        });

        return {
          ...menu,
          functionalities: newFunctionalities,
          enabledAmount: sumBy(newFunctionalities, 'enabledAmount')
        };
      });

      const newEnabledAmount = sumBy(newPermissions, 'enabledAmount');

      return {
        ...previousProfile,
        permissions: newPermissions,
        enabledAmount: newEnabledAmount
      };
    });

    setCopiedFromProfileName('');
  };

  const handleProfileCopySelectChange = (
    event: React.ChangeEvent<{ value: string }>
  ) => {
    setSelectedProfileIdToCopy(event.target.value);
  };

  const handleProfileCopy = async () => {
    try {
      const copiedProfile = await copyPermissions(
        selectedProfileIdToCopy,
        profileId
      );

      const selectedProfile = profilesList.find(
        profile => profile.id === selectedProfileIdToCopy
      );

      setIsCopyPermissionsModalOpen(false);
      setCurrentProfile(copiedProfile.data);
      selectedProfile && setCopiedFromProfileName(selectedProfile.name);

      openSuccessToast('Permissões copiadas com sucesso!');
    } catch (error) {
      openErrorToast('Não foi possível copiar as permissões.');
    }
  };

  const cloneWarningMessage = copiedFromProfileName ? (
    <>
      <Icon name="warning" fill="#ee9933" small />
      <span className={style.messageChangePermissions}>
        Permissões copiadas com sucesso, mas o perfil é uma cópia do{' '}
        {copiedFromProfileName}. É importante alterar as permissões.
      </span>
    </>
  ) : (
    <>
      <Icon name="warning" fill="#e53935" small />
      <span className={style.messageEmptyPermissions}>
        Para habilitar um perfil é necessário adicionar permissões. Você pode
        adicionar manualmente ou copiar permissões de um perfil existente.
      </span>
    </>
  );

  const filteredProfilesList = profilesList.filter(
    profile => profile.id !== profileId && profile.enabledAmount !== 0
  );

  return (
    <>
      {(currentEnabledAmount === 0 || copiedFromProfileName) && (
        <Stack
          className={style.cloneContainer}
          marginBottom={StackMargin.XLARGE}
        >
          <div className={style.cloneWarningMessage}>{cloneWarningMessage}</div>
          {currentEnabledAmount === 0 && !copiedFromProfileName && (
            <Button
              small
              highlight
              outline
              onClick={() => setIsCopyPermissionsModalOpen(true)}
              centered
              disabled={
                !can(Action.PERMISSOES_GERENCIAMENTO_DE_PERMISSOES_EDITAR)
              }
            >
              Copiar permissões
            </Button>
          )}
          {isCopyPermissionsModalOpen && (
            <Modal
              isOpen={isCopyPermissionsModalOpen}
              onClose={() => setIsCopyPermissionsModalOpen(false)}
              className={style.copyPermissionsModal}
              contentClassName={style.copyPermissionsModalContent}
              small
            >
              <SelectField
                title="Copiar permissões do perfil:"
                id="types"
                name="types"
                onChange={handleProfileCopySelectChange}
                options={filteredProfilesList.map(profile => ({
                  label: profile.name,
                  value: profile.id
                }))}
                optional
              />
              <Button
                small
                highlight
                outline
                onClick={() => handleProfileCopy()}
                centered
                className={style.copyButton}
                disabled={!selectedProfileIdToCopy}
              >
                Copiar
              </Button>
            </Modal>
          )}
        </Stack>
      )}
      <Card small className={style.container}>
        <Table>
          <Table.Header>
            <Table.Row className={columnsSizeStyle}>
              <Table.Cell>Menu / Funcionalidades e permissões</Table.Cell>
              <Table.Cell>Qta. permissões habilitadas</Table.Cell>
            </Table.Row>
          </Table.Header>
          <Table.Body isLoading={isLoading} columns={2}>
            {currentProfile &&
              currentProfile.permissions.map(menu => (
                <ExpandableRowItem
                  key={menu.name}
                  itemName={menu.name}
                  itemAmount={menu.enabledAmount.toString()}
                  columnsSizeStyle={columnsSizeStyle}
                  shouldExpandAll={shouldExpandAll}
                >
                  {menu.functionalities.map(functionality => (
                    <FunctionalityRow
                      key={functionality.name}
                      functionality={functionality}
                      columnsSizeStyle={columnsSizeStyle}
                      onPermissionChange={onPermissionChange}
                      profileId={currentProfile.id}
                      shouldExpandAll={shouldExpandAll}
                    />
                  ))}
                </ExpandableRowItem>
              ))}
          </Table.Body>
        </Table>
      </Card>
    </>
  );
};

const ProfileItemMemoized = React.memo(ProfileItem);

export default ProfileItemMemoized;
