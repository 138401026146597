import {
  getAllowedActions,
  getUserId,
  getProfileHasSpecialPermissions as profileHasSpecialPermissions
} from './authentication';

const ALLOWED_ACTIONS_KEY = '_yield_allowed_actions';

export function can(action: Action, caseNegotiatorId?: string): boolean {
  if (!caseNegotiatorId) return canUser(action);

  return isNegotiator(caseNegotiatorId) && profileHasSpecialPermissions()
    ? canUser(actionToActionNegotiator(action))
    : canUser(action);
}

function canUser(action: string): boolean {
  return existsInLocalStorage(action, ALLOWED_ACTIONS_KEY);
}

function actionToActionNegotiator(action: Action): string {
  const [, functionality, act] = action.split('.');

  return `casos_do_negociador.${functionality}.${act}`;
}

function isNegotiator(negotiatorId: string | null | undefined) {
  const userId = getUserId();

  if (!userId) return false;

  return userId === negotiatorId;
}

function existsInLocalStorage(item: string, localStorageKey: string): boolean {
  const dataFromLocalStorage = window.localStorage.getItem(localStorageKey);

  if (!dataFromLocalStorage) {
    return false;
  }

  return dataFromLocalStorage.split(',').includes(item);
}

export function saveAllowedActionsOnLocalStorage(permissions: string[]) {
  window.localStorage.setItem(ALLOWED_ACTIONS_KEY, permissions.toString());
}

export function deleteAllowedActionsFromLocalStorage() {
  window.localStorage.removeItem(ALLOWED_ACTIONS_KEY);
}

export function oldCan(action: string): boolean {
  const allowedActions = getAllowedActions();

  if (!allowedActions) {
    return false;
  }

  return allowedActions.includes(action);
}

export enum Action {
  INVITE = 'invite',
  DELETE_USER = 'delete_user',
  FETCH_INTERNAL_USERS = 'fetch_internal_users',
  SEND_INVITATION = 'send_invitation',
  CREATE_LAW_FIRM = 'create_law_firm',
  UPDATE_LAW_FIRM = 'update_law_firm',
  DELETE_LAW_FIRM = 'delete_law_firm',
  FETCH_LAW_FIRM = 'fetch_law_firm',
  FETCH_ALL_LAW_FIRMS = 'fetch_all_law_firms',
  CREATE_CASE = 'create_case',
  MANAGE_CASE = 'manage_case',
  DELETE_CASE = 'delete_case',
  ADD_LAW_FIRM_TO_CASE = 'add_law_firm_to_case',
  IMPORT_DATA = 'import_data',
  FETCH_OVERALL_CASE_STATS = 'fetch_overall_case_stats',
  EXPORT_DATA = 'export_data',
  EDIT_USER = 'edit_user',
  CASOS_LISTAGEM_DE_CASOS_CONSULTAR = 'casos.listagem_de_casos.consultar',
  CASOS_LISTAGEM_DE_CASOS_EXPORTAR = 'casos.listagem_de_casos.exportar',
  CASOS_LISTAGEM_DE_CASOS_IMPORTAR = 'casos.listagem_de_casos.importar',
  CASOS_LISTAGEM_DE_CASOS_INCLUIR = 'casos.listagem_de_casos.incluir',
  CASOS_CASO_EXCLUIR = 'casos.caso.excluir',
  CASOS_RESUMO_DO_CASO_CONSULTAR = 'casos.resumo_do_caso.consultar',
  CASOS_INFORMACOES_BASICAS_CONSULTAR = 'casos.informacoes_basicas.consultar',
  CASOS_INFORMACOES_BASICAS_EDITAR = 'casos.informacoes_basicas.editar',
  CASOS_INFORMACOES_BASICAS_PARTE_ADVERSA_CONSULTAR = 'casos.informacoes_basicas_parte_adversa.consultar',
  CASOS_INFORMACOES_BASICAS_PARTE_ADVERSA_INCLUIR = 'casos.informacoes_basicas_parte_adversa.incluir',
  CASOS_INFORMACOES_BASICAS_PARTE_ADVERSA_EDITAR = 'casos.informacoes_basicas_parte_adversa.editar',
  CASOS_INFORMACOES_BASICAS_PARTE_ADVERSA_EXCLUIR = 'casos.informacoes_basicas_parte_adversa.excluir',
  CASOS_DEVEDOR_CONSULTAR = 'casos.devedor.consultar',
  CASOS_DEVEDOR_INCLUIR = 'casos.devedor.incluir',
  CASOS_DEVEDOR_EDITAR = 'casos.devedor.editar',
  CASOS_DEVEDOR_EXCLUIR = 'casos.devedor.excluir',
  CASOS_DEVEDOR_RECUPERACAO_JUDICIAL_CONSULTAR = 'casos.devedor_recuperacao_judicial.consultar',
  CASOS_DEVEDOR_RECUPERACAO_JUDICIAL_INCLUIR = 'casos.devedor_recuperacao_judicial.incluir',
  CASOS_DEVEDOR_RECUPERACAO_JUDICIAL_EDITAR = 'casos.devedor_recuperacao_judicial.editar',
  CASOS_DEVEDOR_CLASSE_DE_CREDITO_CONSULTAR = 'casos.devedor_classe_de_credito.consultar',
  CASOS_DEVEDOR_CLASSE_DE_CREDITO_INCLUIR = 'casos.devedor_classe_de_credito.incluir',
  CASOS_DEVEDOR_CLASSE_DE_CREDITO_EDITAR = 'casos.devedor_classe_de_credito.editar',
  CASOS_DEVEDOR_CLASSE_DE_CREDITO_EXCLUIR = 'casos.devedor_classe_de_credito.excluir',
  CASOS_CONTRATO_CONSULTAR = 'casos.contrato.consultar',
  CASOS_CONTRATO_INCLUIR = 'casos.contrato.incluir',
  CASOS_CONTRATO_EDITAR = 'casos.contrato.editar',
  CASOS_CONTRATO_EXCLUIR = 'casos.contrato.excluir',
  CASOS_ARQUIVOS_DO_CONTRATO_CONSULTAR = 'casos.arquivos_do_contrato.consultar',
  CASOS_ARQUIVOS_DO_CONTRATO_INCLUIR = 'casos.arquivos_do_contrato.incluir',
  CASOS_ARQUIVOS_DO_CONTRATO_EXCLUIR = 'casos.arquivos_do_contrato.excluir',
  CASOS_GARANTIAS_CONSULTAR = 'casos.garantias.consultar',
  CASOS_GARANTIAS_INCLUIR = 'casos.garantias.incluir',
  CASOS_GARANTIAS_EDITAR = 'casos.garantias.editar',
  CASOS_GARANTIAS_EXCLUIR = 'casos.garantias.excluir',
  CASOS_ATIVOS_DA_GARANTIA_INCLUIR = 'casos.ativos_da_garantia.incluir',
  CASOS_ATIVOS_DA_GARANTIA_EXCLUIR = 'casos.ativos_da_garantia.excluir',
  CASOS_ATIVOS_DA_GARANTIA_VER_ORIGEM_SUSPEITO = 'casos.ativos_da_garantia.ver_origem_suspeito',
  CASOS_ATIVOS_DA_GARANTIA_COPIAR_LINHA = 'casos.ativos_da_garantia.copiar_linha',
  CASOS_ATIVOS_DA_GARANTIA_VER_RELATORIO = 'casos.ativos_da_garantia.ver_relatorio',
  CASOS_ARQUIVOS_DA_GARANTIA_CONSULTAR = 'casos.arquivos_da_garantia.consultar',
  CASOS_ARQUIVOS_DA_GARANTIA_INCLUIR = 'casos.arquivos_da_garantia.incluir',
  CASOS_ARQUIVOS_DA_GARANTIA_EXCLUIR = 'casos.arquivos_da_garantia.excluir',
  CASOS_RECUPERACOES_CONSULTAR = 'casos.recuperacoes.consultar',
  CASOS_RECUPERACOES_INCLUIR = 'casos.recuperacoes.incluir',
  CASOS_RECUPERACOES_EDITAR = 'casos.recuperacoes.editar',
  CASOS_RECUPERACOES_EXCLUIR = 'casos.recuperacoes.excluir',
  CASOS_ATIVOS_DA_RECUPERACAO_INCLUIR = 'casos.ativos_da_recuperacao.incluir',
  CASOS_ATIVOS_DA_RECUPERACAO_EXCLUIR = 'casos.ativos_da_recuperacao.excluir',
  CASOS_ATIVOS_DA_RECUPERACAO_VER_ORIGEM_SUSPEITO = 'casos.ativos_da_recuperacao.ver_origem_suspeito',
  CASOS_ATIVOS_DA_RECUPERACAO_COPIAR_LINHA = 'casos.ativos_da_recuperacao.copiar_linha',
  CASOS_ATIVOS_DA_RECUPERACAO_VER_RELATORIO = 'casos.ativos_da_recuperacao.ver_relatorio',
  CASOS_ARQUIVOS_DA_RECUPERACAO_CONSULTAR = 'casos.arquivos_da_recuperacao.consultar',
  CASOS_ARQUIVOS_DA_RECUPERACAO_INCLUIR = 'casos.arquivos_da_recuperacao.incluir',
  CASOS_ARQUIVOS_DA_RECUPERACAO_EXCLUIR = 'casos.arquivos_da_recuperacao.excluir',
  CASOS_NOTA_FISCAL_CONSULTAR = 'casos.nota_fiscal.consultar',
  CASOS_NOTA_FISCAL_INCLUIR = 'casos.nota_fiscal.incluir',
  CASOS_NOTA_FISCAL_EDITAR = 'casos.nota_fiscal.editar',
  CASOS_NOTA_FISCAL_EXCLUIR = 'casos.nota_fiscal.excluir',
  CASOS_ARQUIVOS_DA_NOTA_FISCAL_CONSULTAR = 'casos.arquivos_da_nota_fiscal.consultar',
  CASOS_ARQUIVOS_DA_NOTA_FISCAL_INCLUIR = 'casos.arquivos_da_nota_fiscal.incluir',
  CASOS_ARQUIVOS_DA_NOTA_FISCAL_EXCLUIR = 'casos.arquivos_da_nota_fiscal.excluir',
  CASOS_NOTA_FISCAL_DUPLICATAS_CONSULTAR = 'casos.nota_fiscal_duplicatas.consultar',
  CASOS_NOTA_FISCAL_DUPLICATAS_INCLUIR = 'casos.nota_fiscal_duplicatas.incluir',
  CASOS_NOTA_FISCAL_DUPLICATAS_EDITAR = 'casos.nota_fiscal_duplicatas.editar',
  CASOS_NOTA_FISCAL_DUPLICATAS_EXCLUIR = 'casos.nota_fiscal_duplicatas.excluir',
  CASOS_NOTA_FISCAL_DUPLICATAS_COPIAR = 'casos.nota_fiscal_duplicatas.copiar',
  CASOS_NOTA_FISCAL_ARQUIVOS_DA_DUPLICATA_CONSULTAR = 'casos.nota_fiscal_arquivos_da_duplicata.consultar',
  CASOS_NOTA_FISCAL_ARQUIVOS_DA_DUPLICATA_INCLUIR = 'casos.nota_fiscal_arquivos_da_duplicata.incluir',
  CASOS_NOTA_FISCAL_ARQUIVOS_DA_DUPLICATA_EXCLUIR = 'casos.nota_fiscal_arquivos_da_duplicata.excluir',
  CASOS_RASTREAMENTO_SUSPEITOS_CONSULTAR = 'casos.rastreamento_suspeitos.consultar',
  CASOS_RASTREAMENTO_SUSPEITOS_INCLUIR = 'casos.rastreamento_suspeitos.incluir',
  CASOS_RASTREAMENTO_SUSPEITOS_EXCLUIR = 'casos.rastreamento_suspeitos.excluir',
  CASOS_RASTREAMENTO_SUSPEITOS_VER_ORIGEM = 'casos.rastreamento_suspeitos.ver_origem',
  CASOS_RASTREAMENTO_SUSPEITOS_GERAR_RELATORIO = 'casos.rastreamento_suspeitos.gerar_relatorio',
  CASOS_RASTREAMENTO_SUSPEITOS_VER_RELATORIO_PARCIAL = 'casos.rastreamento_suspeitos.ver_relatorio_parcial',
  CASOS_RASTREAMENTO_SUSPEITOS_VER_RELATORIO = 'casos.rastreamento_suspeitos.ver_relatorio',
  CASOS_RASTREAMENTO_SUSPEITOS_ATUALIZAR_RELATORIO = 'casos.rastreamento_suspeitos.atualizar_relatorio',
  CASOS_RASTREAMENTO_SUSPEITOS_COPIAR_LINHA = 'casos.rastreamento_suspeitos.copiar_linha',
  CASOS_RASTREAMENTO_CONSULTAS_CONSULTAR = 'casos.rastreamento_consultas.consultar',
  CASOS_RASTREAMENTO_CONSULTAS_INCLUIR = 'casos.rastreamento_consultas.incluir',
  CASOS_RASTREAMENTO_CONSULTAS_EXCLUIR = 'casos.rastreamento_consultas.excluir',
  CASOS_RASTREAMENTO_CONSULTAS_VER_RELATORIO_PARCIAL = 'casos.rastreamento_consultas.ver_relatorio_parcial',
  CASOS_RASTREAMENTO_CONSULTAS_VER_RELATORIO = 'casos.rastreamento_consultas.ver_relatorio',
  CASOS_RASTREAMENTO_CONSULTAS_ADICIONAR_SUSPEITOS = 'casos.rastreamento_consultas.adicionar_suspeitos',
  CASOS_RASTREAMENTO_CONSULTAS_COPIAR_LINHA = 'casos.rastreamento_consultas.copiar_linha',
  CASOS_RASTREAMENTO_ATIVOS_CONSULTAR = 'casos.rastreamento_ativos.consultar',
  CASOS_RASTREAMENTO_ATIVOS_INCLUIR = 'casos.rastreamento_ativos.incluir',
  CASOS_RASTREAMENTO_ATIVOS_CRIAR_GRUPO = 'casos.rastreamento_ativos.criar_grupo',
  CASOS_RASTREAMENTO_ATIVOS_REMOVER_DO_GRUPO = 'casos.rastreamento_ativos.remover_do_grupo',
  CASOS_RASTREAMENTO_ATIVOS_EXCLUIR = 'casos.rastreamento_ativos.excluir',
  CASOS_RASTREAMENTO_ATIVOS_VER_RELATORIO = 'casos.rastreamento_ativos.ver_relatorio',
  CASOS_RASTREAMENTO_ATIVOS_VER_ORIGEM_SUSPEITO = 'casos.rastreamento_ativos.ver_origem_suspeito',
  CASOS_RASTREAMENTO_ATIVOS_VER_GRUPO = 'casos.rastreamento_ativos.ver_grupo',
  CASOS_RASTREAMENTO_ATIVOS_EXCLUIR_GRUPO = 'casos.rastreamento_ativos.excluir_grupo',
  CASOS_RASTREAMENTO_ATIVOS_COPIAR_LINHA = 'casos.rastreamento_ativos.copiar_linha',
  CASOS_RASTREAMENTO_LOCALIZACOES_CONSULTAR = 'casos.rastreamento_localizacoes.consultar',
  CASOS_RASTREAMENTO_LOCALIZACOES_INCLUIR = 'casos.rastreamento_localizacoes.incluir',
  CASOS_RASTREAMENTO_LOCALIZACOES_EDITAR = 'casos.rastreamento_localizacoes.editar',
  CASOS_RASTREAMENTO_LOCALIZACOES_EXCLUIR = 'casos.rastreamento_localizacoes.excluir',
  CASOS_RASTREAMENTO_LOCALIZACOES_COPIAR_LINHA = 'casos.rastreamento_localizacoes.copiar_linha',
  CASOS_RASTREAMENTO_PRACAS_CONSULTAR = 'casos.rastreamento_pracas.consultar',
  CASOS_RASTREAMENTO_PRACAS_MOSTRAR_MAPA = 'casos.rastreamento_pracas.mostrar_mapa',
  CASOS_RASTREAMENTO_PRACAS_VER_SUSPEITO = 'casos.rastreamento_pracas.ver_suspeito',
  CASOS_RASTREAMENTO_PRACAS_VER_IMOVEL = 'casos.rastreamento_pracas.ver_imovel',
  CASOS_RASTREAMENTO_PRACAS_VER_PROCESSO = 'casos.rastreamento_pracas.ver_processo',
  CASOS_RASTREAMENTO_PROCESSOS_CONSULTAR = 'casos.rastreamento_processos.consultar',
  CASOS_RASTREAMENTO_PROCESSOS_VER_PROCESSO = 'casos.rastreamento_processos.ver_processo',
  CASOS_RASTREAMENTO_POSTAGENS_SALVAS_CONSULTAR = 'casos.rastreamento_postagens_salvas.consultar',
  CASOS_RASTREAMENTO_POSTAGENS_SALVAS_EXCLUIR = 'casos.rastreamento_postagens_salvas.excluir',
  CASOS_ACOMPANHAMENTO_TIMELINE_CONSULTAR = 'casos.acompanhamento_timeline.consultar',
  CASOS_ACOMPANHAMENTO_EVENTO_INCLUIR = 'casos.acompanhamento_evento.incluir',
  CASOS_ACOMPANHAMENTO_EVENTO_EDITAR = 'casos.acompanhamento_evento.editar',
  CASOS_ACOMPANHAMENTO_EVENTO_EXCLUIR = 'casos.acompanhamento_evento.excluir',
  CASOS_ACOMPANHAMENTO_ARQUIVOS_EVENTO_CONSULTAR = 'casos.acompanhamento_arquivos_evento.consultar',
  CASOS_ACOMPANHAMENTO_ARQUIVOS_EVENTO_INCLUIR = 'casos.acompanhamento_arquivos_evento.incluir',
  CASOS_ACOMPANHAMENTO_ARQUIVOS_EVENTO_EXCLUIR = 'casos.acompanhamento_arquivos_evento.excluir',
  CASOS_ACOMPANHAMENTO_ATIVIDADES_CONSULTAR = 'casos.acompanhamento_atividades.consultar',
  CASOS_ACOMPANHAMENTO_ATIVIDADES_EDITAR = 'casos.acompanhamento_atividades.editar',
  CASOS_ACOMPANHAMENTO_ATIVIDADES_VER_CONTRATO = 'casos.acompanhamento_atividades.ver_contrato',
  CASOS_PROCESSOS_JUDICIAIS_CONSULTAR = 'casos.processos_judiciais.consultar',
  CASOS_PROCESSOS_JUDICIAIS_INCLUIR = 'casos.processos_judiciais.incluir',
  CASOS_PROCESSOS_JUDICIAIS_VER_PROCESSO = 'casos.processos_judiciais.ver_processo',
  CASOS_PROCESSOS_JUDICIAIS_VER_CONTRATO = 'casos.processos_judiciais.ver_contrato',
  CASOS_ARQUIVOS_CONSULTAR = 'casos.arquivos.consultar',
  CASOS_ARQUIVOS_EXCLUIR = 'casos.arquivos.excluir',
  CASOS_ARQUIVOS_VER_CONTRATO = 'casos.arquivos.ver_contrato',
  CASOS_ARQUIVOS_VER_PROCESSO = 'casos.arquivos.ver_processo',
  CASOS_ARQUIVOS_VER_ATIVO = 'casos.arquivos.ver_ativo',
  CASOS_ARQUIVOS_VER_NOTA_FISCAL = 'casos.arquivos.ver_nota_fiscal',
  CASOS_RELATORIO_PFPJ_EDITAR = 'casos.relatorio_pfpj.editar',
  CASOS_RELATORIO_PFPJ_REMOVER_DO_CASO = 'casos.relatorio_pfpj.remover_do_caso',
  CASOS_RELATORIO_PFPJ_VER_RESUMO = 'casos.relatorio_pfpj.ver_resumo',
  CASOS_RELATORIO_PFPJ_VER_ORIGEM = 'casos.relatorio_pfpj.ver_origem',
  CASOS_RELATORIO_PFPJ_ATUALIZAR = 'casos.relatorio_pfpj.atualizar',
  CASOS_RELATORIO_PFPJ_VER_RELATORIO_PARCIAL = 'casos.relatorio_pfpj.ver_relatorio_parcial',
  CASOS_RELATORIO_PFPJ_TENTAR_NOVAMENTE = 'casos.relatorio_pfpj.tentar_novamente',
  CASOS_RELATORIO_PFPJ_MOSTRAR_MAPA = 'casos.relatorio_pfpj.mostrar_mapa',
  CASOS_RELATORIO_PFPJ_ADICIONAR_AO_CASO = 'casos.relatorio_pfpj.adicionar_ao_caso',
  CASOS_RELATORIO_PFPJ_BUSCAR = 'casos.relatorio_pfpj.buscar',
  CASOS_RELATORIO_PFPJ_ORCAR_PROCESSOS = 'casos.relatorio_pfpj.orcar_processos',
  CASOS_RELATORIO_PFPJ_BUSCAR_NOVOS_PROCESSOS = 'casos.relatorio_pfpj.buscar_novos_processos',
  CASOS_RELATORIO_PFPJ_VER_PROCESSO = 'casos.relatorio_pfpj.ver_processo',
  CASOS_RELATORIO_PFPJ_VER_PERFIL_INSTAGRAM = 'casos.relatorio_pfpj.ver_perfil_instagram',
  CASOS_RELATORIO_PFPJ_ATUALIZAR_PERFIL_INSTAGRAM = 'casos.relatorio_pfpj.atualizar_perfil_instagram',
  CASOS_RELATORIO_PFPJ_REMOVER_DE_POSTAGENS_SALVAS = 'casos.relatorio_pfpj.remover_de_postagens_salvas',
  CASOS_RELATORIO_PERFIL_INSTAGRAM_ATUALIZAR = 'casos.relatorio_perfil_instagram.atualizar',
  CASOS_RELATORIO_PERFIL_INSTAGRAM_EDITAR = 'casos.relatorio_perfil_instagram.editar',
  CASOS_RELATORIO_PERFIL_INSTAGRAM_VER_ORIGEM = 'casos.relatorio_perfil_instagram.ver_origem',
  CASOS_RELATORIO_ATIVO_EDITAR = 'casos.relatorio_ativo.editar',
  CASOS_RELATORIO_ATIVO_EXCLUIR = 'casos.relatorio_ativo.excluir',
  CASOS_RELATORIO_ATIVO_ADICIONAR_A_UM_GRUPO = 'casos.relatorio_ativo.adicionar_a_um_grupo',
  CASOS_RELATORIO_ATIVO_REMOVER_DO_GRUPO = 'casos.relatorio_ativo.remover_do_grupo',
  CASOS_RELATORIO_ATIVO_SUBIR_FOTO = 'casos.relatorio_ativo.subir_foto',
  CASOS_RELATORIO_ATIVO_EXCLUIR_FOTO = 'casos.relatorio_ativo.excluir_foto',
  CASOS_RELATORIO_ATIVO_AVALIACAO_CONSULTAR = 'casos.relatorio_ativo_avaliacao.consultar',
  CASOS_RELATORIO_ATIVO_AVALIACAO_INCLUIR = 'casos.relatorio_ativo_avaliacao.incluir',
  CASOS_RELATORIO_ATIVO_AVALIACAO_EDITAR = 'casos.relatorio_ativo_avaliacao.editar',
  CASOS_RELATORIO_ATIVO_AVALIACAO_EXCLUIR = 'casos.relatorio_ativo_avaliacao.excluir',
  CASOS_RELATORIO_ATIVO_ARQUIVOS_CONSULTAR = 'casos.relatorio_ativo_arquivos.consultar',
  CASOS_RELATORIO_ATIVO_ARQUIVOS_INCLUIR = 'casos.relatorio_ativo_arquivos.incluir',
  CASOS_RELATORIO_ATIVO_ARQUIVOS_EXCLUIR = 'casos.relatorio_ativo_arquivos.excluir',
  CASOS_RELATORIO_ATIVO_VER_CONTRATO = 'casos.relatorio_ativo.ver_contrato',
  CASOS_RELATORIO_ATIVO_VER_GRUPO = 'casos.relatorio_ativo.ver_grupo',
  CASOS_RELATORIO_ATIVO_VER_PROCESSO = 'casos.relatorio_ativo.ver_processo',
  CASOS_RELATORIO_GRUPO_EDITAR = 'casos.relatorio_grupo.editar',
  CASOS_RELATORIO_GRUPO_EXCLUIR = 'casos.relatorio_grupo.excluir',
  CASOS_RELATORIO_GRUPO_VER_PROCESSO = 'casos.relatorio_grupo.ver_processo',
  CASOS_RELATORIO_GRUPO_VER_CONTRATO = 'casos.relatorio_grupo.ver_contrato',
  CASOS_RELATORIO_GRUPO_ATIVOS_CONSULTAR = 'casos.relatorio_grupo_ativos.consultar',
  CASOS_RELATORIO_GRUPO_ATIVOS_INCLUIR = 'casos.relatorio_grupo_ativos.incluir',
  CASOS_RELATORIO_GRUPO_ATIVOS_REMOVER_DO_GRUPO = 'casos.relatorio_grupo_ativos.remover_do_grupo',
  CASOS_RELATORIO_GRUPO_ATIVOS_VER_RELATORIO = 'casos.relatorio_grupo_ativos.ver_relatorio',
  CASOS_RELATORIO_GRUPO_ATIVOS_VER_ORIGEM_SUSPEITO = 'casos.relatorio_grupo_ativos.ver_origem_suspeito',
  CASOS_RELATORIO_GRUPO_ATIVOS_COPIAR_LINHA = 'casos.relatorio_grupo_ativos.copiar_linha',
  CASOS_RELATORIO_GRUPO_AVALIACAO_CONSULTAR = 'casos.relatorio_grupo_avaliacao.consultar',
  CASOS_RELATORIO_GRUPO_AVALIACAO_INCLUIR = 'casos.relatorio_grupo_avaliacao.incluir',
  CASOS_RELATORIO_GRUPO_AVALIACAO_EDITAR = 'casos.relatorio_grupo_avaliacao.editar',
  CASOS_RELATORIO_GRUPO_AVALIACAO_EXCLUIR = 'casos.relatorio_grupo_avaliacao.excluir',
  CASOS_RELATORIO_GRUPO_ARQUIVOS_CONSULTAR = 'casos.relatorio_grupo_arquivos.consultar',
  CASOS_RELATORIO_GRUPO_ARQUIVOS_INCLUIR = 'casos.relatorio_grupo_arquivos.incluir',
  CASOS_RELATORIO_GRUPO_ARQUIVOS_EXCLUIR = 'casos.relatorio_grupo_arquivos.excluir',
  CASOS_PROCESSO_EXTERNO_ADICIONAR_A_RASTREAMENTO = 'casos.processo_externo.adicionar_a_rastreamento',
  CASOS_PROCESSO_EXTERNO_REMOVER_DO_RASTREAMENTO = 'casos.processo_externo.remover_do_rastreamento',
  CASOS_PROCESSO_EXTERNO_ACOMPANHAR_PROCESSO = 'casos.processo_externo.acompanhar_processo',
  CASOS_PROCESSO_EXTERNO_DEIXAR_DE_ACOMPANHAR = 'casos.processo_externo.deixar_de_acompanhar',
  CASOS_PROCESSO_EXTERNO_VER_PROCESSO = 'casos.processo_externo.ver_processo',
  CASOS_PROCESSO_EXCLUIR = 'casos.processo.excluir',
  CASOS_PROCESSO_INFORMACOES_BASICAS_EDITAR = 'casos.processo_informacoes_basicas.editar',
  CASOS_PROCESSO_INFORMACOES_BASICAS_VINCULAR_CONTRATO = 'casos.processo_informacoes_basicas.vincular_contrato',
  CASOS_PROCESSO_INFORMACOES_BASICAS_VINCULAR_RECUPERACAO = 'casos.processo_informacoes_basicas.vincular_recuperacao',
  CASOS_PROCESSO_INFORMACOES_BASICAS_VER_CONTRATO = 'casos.processo_informacoes_basicas.ver_contrato',
  CASOS_PROCESSO_INFORMACOES_BASICAS_ADICIONAR_PROCESSO_VINCULADO = 'casos.processo_informacoes_basicas.adicionar_processo_vinculado',
  CASOS_PROCESSO_INFORMACOES_BASICAS_VER_PROCESSO = 'casos.processo_informacoes_basicas.ver_processo',
  CASOS_PROCESSO_ADVOGADOS_CONSULTAR = 'casos.processo_advogados.consultar',
  CASOS_PROCESSO_ADVOGADOS_INCLUIR = 'casos.processo_advogados.incluir',
  CASOS_PROCESSO_ADVOGADOS_EDITAR = 'casos.processo_advogados.editar',
  CASOS_PROCESSO_ADVOGADOS_EXCLUIR = 'casos.processo_advogados.excluir',
  CASOS_PROCESSO_PARTES_ENVOLVIDAS_CONSULTAR = 'casos.processo_partes_envolvidas.consultar',
  CASOS_PROCESSO_PARTES_ENVOLVIDAS_INCLUIR = 'casos.processo_partes_envolvidas.incluir',
  CASOS_PROCESSO_PARTES_ENVOLVIDAS_EDITAR = 'casos.processo_partes_envolvidas.editar',
  CASOS_PROCESSO_PARTES_ENVOLVIDAS_EXCLUIR = 'casos.processo_partes_envolvidas.excluir',
  CASOS_PROCESSO_PARTES_ENVOLVIDAS_ADICIONAR_A_SUSPEITOS = 'casos.processo_partes_envolvidas.adicionar_a_suspeitos',
  CASOS_PROCESSO_MOVIMENTACOES_CONSULTAR = 'casos.processo_movimentacoes.consultar',
  CASOS_PROCESSO_MOVIMENTACOES_INCLUIR = 'casos.processo_movimentacoes.incluir',
  CASOS_PROCESSO_MOVIMENTACOES_EDITAR = 'casos.processo_movimentacoes.editar',
  CASOS_PROCESSO_MOVIMENTACOES_EXCLUIR = 'casos.processo_movimentacoes.excluir',
  CASOS_PROCESSO_MOVIMENTACOES_ATUALIZAR_AUTOMATICAMENTE = 'casos.processo_movimentacoes.atualizar_automaticamente',
  CASOS_PROCESSO_ARQUIVOS_DO_MOVIMENTO_CONSULTAR = 'casos.processo_arquivos_do_movimento.consultar',
  CASOS_PROCESSO_ARQUIVOS_DO_MOVIMENTO_INCLUIR = 'casos.processo_arquivos_do_movimento.incluir',
  CASOS_PROCESSO_ARQUIVOS_DO_MOVIMENTO_EXCLUIR = 'casos.processo_arquivos_do_movimento.excluir',
  CASOS_PROCESSO_PENHORA_CONSULTAR = 'casos.processo_penhora.consultar',
  CASOS_PROCESSO_PENHORA_INCLUIR = 'casos.processo_penhora.incluir',
  CASOS_PROCESSO_PENHORA_INCLUIR_NOVO_ATIVO = 'casos.processo_penhora.incluir_novo_ativo',
  CASOS_PROCESSO_PENHORA_INCLUIR_NOVO_PROCESSO = 'casos.processo_penhora.incluir_novo_processo',
  CASOS_PROCESSO_PENHORA_EDITAR = 'casos.processo_penhora.editar',
  CASOS_PROCESSO_PENHORA_EXCLUIR = 'casos.processo_penhora.excluir',
  CASOS_PROCESSO_ARQUIVOS_DA_PENHORA_CONSULTAR = 'casos.processo_arquivos_da_penhora.consultar',
  CASOS_PROCESSO_ARQUIVOS_DA_PENHORA_INCLUIR = 'casos.processo_arquivos_da_penhora.incluir',
  CASOS_PROCESSO_ARQUIVOS_DA_PENHORA_EXCLUIR = 'casos.processo_arquivos_da_penhora.excluir',
  LINKS_UTEIS_LINKS_CONSULTAR = 'links_uteis.links.consultar',
  LINKS_UTEIS_LINKS_INCLUIR = 'links_uteis.links.incluir',
  LINKS_UTEIS_LINKS_EDITAR = 'links_uteis.links.editar',
  LINKS_UTEIS_LINKS_EXCLUIR = 'links_uteis.links.excluir',
  PERMISSOES_USUARIOS_INTERNOS_DO_BANCO_CONSULTAR = 'permissoes.usuarios_internos_do_banco.consultar',
  PERMISSOES_USUARIOS_INTERNOS_DO_BANCO_INCLUIR = 'permissoes.usuarios_internos_do_banco.incluir',
  PERMISSOES_USUARIOS_INTERNOS_DO_BANCO_EDITAR = 'permissoes.usuarios_internos_do_banco.editar',
  PERMISSOES_USUARIOS_INTERNOS_DO_BANCO_EXCLUIR = 'permissoes.usuarios_internos_do_banco.excluir',
  PERMISSOES_ESCRITORIOS_DE_ADVOCACIA_CONSULTAR = 'permissoes.escritorios_de_advocacia.consultar',
  PERMISSOES_ESCRITORIOS_DE_ADVOCACIA_INCLUIR = 'permissoes.escritorios_de_advocacia.incluir',
  PERMISSOES_ESCRITORIOS_DE_ADVOCACIA_EDITAR = 'permissoes.escritorios_de_advocacia.editar',
  PERMISSOES_ESCRITORIOS_DE_ADVOCACIA_EXCLUIR = 'permissoes.escritorios_de_advocacia.excluir',
  PERMISSOES_GERENCIAMENTO_DE_PERMISSOES_CONSULTAR = 'permissoes.gerenciamento_de_permissoes.consultar',
  PERMISSOES_GERENCIAMENTO_DE_PERMISSOES_EDITAR = 'permissoes.gerenciamento_de_permissoes.editar',
  PERMISSOES_GERENCIAMENTO_DE_PERMISSOES_EXCLUIR = 'permissoes.gerenciamento_de_permissoes.excluir',
  PERMISSOES_GERENCIAMENTO_DE_PERMISSOES_INCLUIR = 'permissoes.gerenciamento_de_permissoes.incluir'
}
