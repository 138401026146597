import { getFormerValueContent } from 'domain/field';
import React, { ReactNode } from 'react';
import cn from 'classnames';

import { FieldHistory } from 'types';
import { ToolTip } from 'ui';
import { handleNullValue } from 'utils';

import styles from './FormerValueTooltip.module.scss';

type Props = {
  children?: ReactNode;
  fieldName: string;
  fieldValue?: string | number | null;
  history: FieldHistory | null;
  formatter?: (value: string) => string;
  content?: string | null;
};

const FormerValueTooltip = ({
  fieldName,
  fieldValue,
  history,
  formatter,
  content,
  children
}: Props) => {
  const value = formatter
    ? handleNullValue(fieldValue, 'string', formatter)
    : handleNullValue(fieldValue);
  const tooltipContent =
    content ?? getFormerValueContent(fieldName, history, formatter);

  return (
    <ToolTip content={tooltipContent} placement="top">
      <span
        data-testid={`tooltip-${fieldName}`}
        className={cn([{ [styles.boldText]: tooltipContent }])}
      >
        {children ? children : value}
      </span>
    </ToolTip>
  );
};

export default FormerValueTooltip;
