import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { oldCan, Action, hasValidToken } from 'api';
import ErrorsPage from 'pages/errors';
import { SessionDaemon } from 'components';
import Client from 'api/client';

type Props = RouteProps & {
  action?: Action;
  component: NonNullable<RouteProps['component']>;
};

const PrivateRoute = ({ component: Component, action, ...rest }: Props) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (!hasValidToken()) {
          return <Redirect to={Client.logoutUrl()} />;
        }
        if (action && !oldCan(action!)) {
          return (
            <ErrorsPage
              title="Você não tem essa permissão"
              description="ERRO 403"
            />
          );
        }
        return (
          <SessionDaemon>
            <Component {...props} />
          </SessionDaemon>
        );
      }}
    />
  );
};
export default PrivateRoute;
