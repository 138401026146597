import { entityTypeOptions } from 'domain/entity';
import {
  involvedPartyTypeOptions,
  involvedPartyValidationSchema as validationSchema
} from 'domain/lawsuits';
import React from 'react';

import { EntityType, InvolvedParty, InvolvedPartyType } from 'types';
import { Button, FormContainer, InputField, Toggle, ToolTip } from 'ui';
import { Formik, FormikErrors, FormikTouched, getIn } from 'formik';
import { AutoCompleteField } from 'components';

import style from './Form.module.scss';

type Props = {
  caseId: string;
  party?: InvolvedParty;
  partyIndex?: number;
  partyErrors: FormikErrors<InvolvedParty> | undefined;
  onSave: (party: InvolvedParty, partyIndex?: number) => void;
  onDelete: (partyIndex: number) => void;
  onCancel: () => void;
  partyCanBeDeleted: boolean;
};

const initialValues: InvolvedParty = {
  id: null,
  entityType: EntityType.INDIVIDUAL,
  type: InvolvedPartyType.NEUTRAL,
  individual: {
    name: '',
    cpf: ''
  },
  company: {
    businessName: '',
    cnpj: ''
  }
};

const Form = ({
  caseId,
  party,
  partyIndex,
  partyErrors,
  onSave,
  onDelete,
  onCancel,
  partyCanBeDeleted
}: Props) => {
  const formatInitialValues = (party?: InvolvedParty) => {
    if (party)
      return {
        ...party,
        type: party.type ? party.type : InvolvedPartyType.NEUTRAL
      };

    return initialValues;
  };

  const disableSuspectFields = !!party?.isInCase;

  const documentTooltipContent = (documentType: string, disabled: boolean) =>
    disabled
      ? `Não é possível editar o ${documentType} pois já está adicionado a suspeitos`
      : '';

  function handleOnSubmit(values: InvolvedParty, partyIndex?: number) {
    if (values.entityType === EntityType.COMPANY) {
      values.individual = undefined;
    } else {
      values.company = undefined;
    }

    onSave(values, partyIndex);
  }

  return (
    <Formik<InvolvedParty>
      initialErrors={partyErrors}
      initialTouched={partyErrors as FormikTouched<InvolvedParty>}
      initialValues={formatInitialValues(party)}
      validationSchema={validationSchema}
      onSubmit={values => handleOnSubmit(values, partyIndex)}
    >
      {form => {
        return (
          <FormContainer>
            <Toggle
              title="Tipo de pessoa"
              options={entityTypeOptions()}
              onChange={event =>
                form.setFieldValue('entityType', event.target.value)
              }
              checked={form.values.entityType ?? EntityType.INDIVIDUAL}
              disabled={disableSuspectFields}
            />

            {form.values.entityType === EntityType.INDIVIDUAL ? (
              <>
                <ToolTip
                  content={documentTooltipContent('CPF', disableSuspectFields)}
                  placement="top"
                >
                  <span data-testid="cpf-hover-area">
                    <AutoCompleteField
                      id="party-cpf"
                      name="individual.cpf"
                      suggestionsName={form.values.individual?.name}
                      type="cpf"
                      title="CPF"
                      value={form.values.individual?.cpf}
                      caseId={caseId}
                      entityType={EntityType.INDIVIDUAL}
                      dataToItem={data => ({
                        value: data.individual.cpf,
                        complement: data.individual.name
                      })}
                      onSelect={selection => {
                        form.setFieldValue('individual.cpf', selection!.value);
                        form.setFieldValue(
                          'individual.name',
                          selection!.complement
                        );
                      }}
                      onChange={form.handleChange}
                      onBlur={form.handleBlur}
                      error={
                        getIn(form.touched, 'individual.cpf') &&
                        getIn(form.errors, 'individual.cpf')
                      }
                      disabled={disableSuspectFields}
                    />
                  </span>
                </ToolTip>

                <InputField
                  id="party-name"
                  name="individual.name"
                  type="text"
                  title="Nome completo"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.individual?.name}
                  error={
                    getIn(form.touched, 'individual.name') &&
                    getIn(form.errors, 'individual.name')
                  }
                  disabled={disableSuspectFields}
                />
              </>
            ) : (
              <>
                <ToolTip
                  content={documentTooltipContent('CNPJ', disableSuspectFields)}
                  placement="top"
                >
                  <span>
                    <AutoCompleteField
                      id="party-cnpj"
                      name="company.cnpj"
                      suggestionsName={form.values.company?.businessName}
                      type="cnpj"
                      title="CNPJ"
                      value={form.values.company?.cnpj}
                      caseId={caseId}
                      entityType={EntityType.COMPANY}
                      dataToItem={data => ({
                        value: data.company.cnpj,
                        complement: data.company.businessName
                      })}
                      onSelect={selection => {
                        form.setFieldValue('company.cnpj', selection!.value);
                        form.setFieldValue(
                          'company.businessName',
                          selection!.complement
                        );
                      }}
                      onChange={form.handleChange}
                      onBlur={form.handleBlur}
                      error={
                        getIn(form.touched, 'company.cnpj') &&
                        getIn(form.errors, 'company.cnpj')
                      }
                      disabled={disableSuspectFields}
                    />
                  </span>
                </ToolTip>

                <InputField
                  id="party-business-name"
                  name="company.businessName"
                  type="text"
                  title="Razão social"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.company?.businessName}
                  error={
                    getIn(form.touched, 'company.businessName') &&
                    getIn(form.errors, 'company.businessName')
                  }
                  disabled={disableSuspectFields}
                />
              </>
            )}

            <Toggle
              title="Tipo de parte"
              options={involvedPartyTypeOptions()}
              onChange={event => form.setFieldValue('type', event.target.value)}
              checked={form.values.type!}
              name="type"
            />

            <div className={style.actions}>
              <Button
                onClick={form.handleSubmit}
                highlight
                dataTestId="party-submit-button"
                type="submit"
              >
                {party ? 'Salvar' : 'Adicionar'}
              </Button>

              {partyIndex !== undefined ? (
                <Button
                  onClick={() => onDelete(partyIndex)}
                  highlight
                  outline
                  danger
                  disabled={!partyCanBeDeleted}
                  dataTestId="party-remove-button"
                  type="button"
                >
                  Excluir
                </Button>
              ) : (
                <Button
                  onClick={onCancel}
                  highlight
                  outline
                  dataTestId="party-cancel-button"
                  type="button"
                >
                  Cancelar
                </Button>
              )}
            </div>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default Form;
