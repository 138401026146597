import React, { useState, useEffect, ReactElement } from 'react';
import { kebabCase } from 'lodash';
import cn from 'classnames';

import { TabContent as TabContentProps } from 'types/tabContent';

import { Link } from 'ui';
import { LinkLocation } from 'ui/Link/Link';
import './Tabs.scss';

type Props = {
  children: ReactElement[] | ReactElement;
  linkTab?: (tab: TabContentProps) => LinkLocation | string | undefined;
  defaultPath?: string;
};

const Tabs = ({ children, linkTab, defaultPath }: Props) => {
  const [currentTab, setCurrentTab] = useState('');

  useEffect(() => {
    if (!Array.isArray(children)) {
      setCurrentTab(children.props.title);
    } else {
      if (defaultPath) {
        const currentChild = children.find(
          (child: ReactElement) => child.props.path === defaultPath
        );

        setCurrentTab(currentChild?.props.title);
      } else {
        setCurrentTab(children[0].props.title);
      }
    }
  }, [defaultPath, children]);

  const handleTabChange = (props: TabContentProps) => {
    if (!linkTab) {
      setCurrentTab(props.title);
    }
  };

  const tabContents = Array.isArray(children)
    ? children.filter((child: ReactElement) => child.props.title === currentTab)
    : children;

  return (
    <div data-testid="tabs" className="tab-container">
      <ul className="navigation">
        {Array.isArray(children) ? (
          children.map(({ props }, index) => {
            const className = cn('item', {
              '-active': props.title === currentTab
            });

            return (
              <Link
                to={linkTab ? linkTab(props) : ''}
                onClick={() => handleTabChange(props)}
                className={className}
                data-testid={`tab-title-${kebabCase(props.title)}`}
                key={`tab-title-${index}`}
              >
                {props.title}
              </Link>
            );
          })
        ) : (
          <li
            className="item -active"
            data-testid={`tab-title-${children.props.title}`}
            onClick={() => setCurrentTab(children.props.title)}
          >
            {children.props.title}
          </li>
        )}
      </ul>

      <div className="tab-content">{tabContents}</div>
    </div>
  );
};

export default Tabs;
