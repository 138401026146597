export enum DataImportStatus {
  ACTIVE = 'active',
  FINISHED = 'finished'
}

export type DataImportError = {
  messages: Array<string>;
  cell: { path: { cell: number; row: number; worksheet: string } };
};

export type DataImport = {
  id: string;
  status: DataImportStatus;
  caseImportsCount: number;
  processedCaseImportsCount: number;
  failedCaseImportsCount: number;
  finishedAt?: string;
  errors: Array<DataImportError>;
  resultsDismissed: boolean;
};
