import React, { useState } from 'react';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import { Icon } from 'ui';

type Props = {
  taskId: string | null;
  individualName?: string;
  className?: string;
};

const InstagramTaskCopyButton = ({
  taskId,
  individualName,
  className
}: Props) => {
  const [visible, setVisible] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const showTooltip = () => setVisible(true);
  const hideTooltip = () => setVisible(false);

  const handleCopy = (taskId: string, individualName?: string) => {
    navigator.clipboard.writeText(
      individualName ? `${individualName}, ${taskId}` : taskId
    );

    showTooltip();

    setIsCopied(true);
  };

  const handleMouseEnter = () => {
    showTooltip();

    if (isCopied) {
      setIsCopied(false);
    }
  };

  if (!taskId) return <></>;

  return (
    <Tippy
      content={isCopied ? 'TaskId copiado' : 'Clique para copiar TaskId'}
      visible={visible}
    >
      <button
        className={className}
        onClick={() => handleCopy(taskId, individualName)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={hideTooltip}
        data-testid="button-copy-task-id"
      >
        <Icon fill="#3949AB" name="content-copy" small />
      </button>
    </Tippy>
  );
};

export default InstagramTaskCopyButton;
