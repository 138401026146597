import React, { useState } from 'react';
import cn from 'classnames';
import { FileIcon } from 'ui';
import { numericDateFormat } from 'utils';
import { GenericFile } from 'types';

import FileActions from './FileAction/FileActions';

import './FileItem.scss';

type Props = {
  file: GenericFile;
  error?: string | string[];
  uploadProgress?: number;
  onResend?: () => void;
  onDelete?: (id: string) => void;
  onCancel?: () => void;
  onUndoDelete?: (file: GenericFile) => void;
  showMode?: boolean;
  showDelete?: boolean;
};

const FileMimeTypes: { [key: string]: string } = {
  'image/jpeg': '.jpeg',
  'image/png': '.png',
  'image/jpg': '.jpg',
  'application/pdf': '.pdf',
  'application/vnd.ms-excel': '.xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
  'application/vnd.ms-powerpoint': '.ppt',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    '.pptx',
  'application/msword': '.doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    '.docx',
  'message/rfc822': '.eml',
  'application/octet-stream': '.msg'
};

const FileItem = ({
  file,
  error,
  uploadProgress,
  onResend,
  onDelete,
  onCancel,
  onUndoDelete,
  showMode,
  showDelete = true
}: Props) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const isUploading = uploadProgress !== undefined;

  const handleDelete = () => {
    if (!file.id) return;

    !showMode && setIsDeleting(true);
    onDelete && onDelete(file.id);
  };

  const handleUndoDelete = () => {
    setIsDeleting(false);
    onUndoDelete && onUndoDelete(file);
  };

  return (
    <div className="file-item">
      <div
        className="progress-background"
        style={{ width: `${uploadProgress}%` }}
      />

      <FileIcon extension={FileMimeTypes[file.type]} />
      <div className="info">
        <span
          className={cn('name', { '-deleting': isDeleting })}
          title={file.name}
        >
          {file.name}
        </span>

        {isDeleting && (
          <span className="status -error">Arquivo será deletado ao salvar</span>
        )}

        {error && (
          <span className="status -error">
            {Array.isArray(error) ? error.join(', ') : error}
          </span>
        )}

        {isUploading && !error && (
          <span className="status">Carregando... ({uploadProgress}%)</span>
        )}

        {!isDeleting && !isUploading && !error && file.insertedAt && (
          <span className="status">{numericDateFormat(file.insertedAt)}</span>
        )}
      </div>

      <FileActions
        downloadUrl={file.signedUrl}
        error={Boolean(error)}
        isUploading={isUploading}
        isDeleting={isDeleting}
        onResend={onResend}
        onDelete={handleDelete}
        onCancel={onCancel}
        onUndoDelete={handleUndoDelete}
        showDelete={showDelete}
      />
    </div>
  );
};

export default FileItem;
