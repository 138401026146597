export enum ProviderName {
  ASSERTIVA = 'assertiva',
  DIGESTO = 'digesto',
  NEOWAY = 'neoway',
  REFINARIA_INCRA = 'refinaria_incra',
  REFINARIA_SOCIAL = 'refinaria_social',
  INSTAGRAM = 'instagram',
  REFINARIA = 'refinaria',
  GOLIVE = 'golive',
  SERPRO = 'serpro'
}

export enum ProviderType {
  ALL = 'all',
  EXTERNAL_LAWSUIT = 'external_lawsuit',
  EXTERNAL_LAWSUIT_MORE_INFO = 'external_lawsuit_more_info',
  FAMILY = 'family_tree',
  FIPE_PRICE = 'fipe_price_of_vehicle',
  INSTAGRAM_PROFILES = 'instagram_profiles',
  LEGAL_REPORT = 'create_legal_report',
  OWNERS_BY_RURAL_PROPERTY = 'owners_by_rural_property',
  RURAL_PROPERTY = 'rural_properties_by_owner',
  RURAL_PROPERTIES = 'rural_properties',
  VEHICLES = 'vehicles'
}
