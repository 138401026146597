import React from 'react';

import sumBy from 'lodash/sumBy';

import { pluralize } from 'utils';

import { Loader } from 'ui';

import { DebtorGroupedWithContractAndInvoice } from 'types';

import style from './DebtorsList.module.scss';

type Props = {
  isSalesFinance: boolean;
  isLoading: boolean;
  debtorsWith: DebtorGroupedWithContractAndInvoice[];
};

const ListSummary = ({ isSalesFinance, isLoading, debtorsWith }: Props) => {
  const invoicesCount = sumBy(
    debtorsWith
      .flatMap(debtor => debtor.invoicesByCovenant)
      .map(byCovenant => byCovenant.invoices.length)
  );

  const contractsCount = sumBy(
    debtorsWith
      .flatMap(debtor => debtor.contractsByModality)
      .map(byCovenant => byCovenant.contracts.length)
  );

  const debtorText = pluralize(debtorsWith.length, 'devedor', 'devedores');
  const contractText = pluralize(contractsCount, 'contrato', 'contratos');
  const invoiceText = pluralize(invoicesCount, 'nota fiscal', 'notas fiscais');

  if (isLoading) return <Loader />;

  if (isSalesFinance) {
    return (
      <span className={style.status}>
        {`${debtorText}, ${contractText}, ${invoiceText}`}
      </span>
    );
  }

  return (
    <span className={style.status}>{`${debtorText}, ${contractText}`}</span>
  );
};

export default ListSummary;
