import { User } from './user';
import { CreditContract } from './creditContract';
import { Duplicate } from './duplicate';

export type AdversePartyAttributes = {
  name: string;
  type: string;
  role: string;
};

export type AdverseParty = AdversePartyAttributes & {
  id?: string;
};

export enum CaseStatus {
  UNDER_ANALYSIS = 'under_analysis',
  FINISHED = 'finished',
  JUDICIAL = 'judicial',
  NOT_ACQUIRED = 'not_acquired',
  NEGOTIATION = 'negotiation',
  RESEARCH = 'research',
  RECLAIMED = 'reclaimed'
}

export enum CaseType {
  ATIVOS_ESPECIAIS = 'ativos_especiais',
  PEAC = 'peac',
  SALES_FINANCE = 'sales_finance'
}

export type CaseFormAttributes = {
  status: string;
} & Omit<CaseAttributes, 'status'>;

export type CaseAttributes = {
  adverseParties: AdverseParty[];
  creditor: string;
  internalLawyerId: string | null;
  lawFirmId: string | null;
  name: string;
  negotiatorId: string | null;
  negotiator?: User;
  type: CaseType;
  startedAt: string | null;
  status: CaseStatus;
};

export type Case = CaseAttributes & {
  id: string;
  lastChangedAt: string;
  negotiator: User;
  internalLawyer: User | null;
  firstOverdueDate: string | null;
  contracts: CreditContract[];
  duplicates: Duplicate[];
};

export type CaseParams = Partial<Case>;

export type CasesStatsBase = {
  activeCasesCount: number;
  inactiveCasesCount: number;
  totalCasesCount: number;
  totalActiveContractAmount: number;
  totalActiveContracts: number;
  totalActiveReclaimingAmount: number;
  totalInactiveReclaimingAmount: number;
};

type CasesStatsFor<T> = CasesStatsBase & T;

export type CasesStatsForAtivosEspeciais = CasesStatsFor<{
  totalActiveGuaranteeAmount: number;
  totalActiveGuarantees: number;
}>;

export type CasesStatsForPEACS = CasesStatsFor<{
  creditOrigin: Array<{
    name: string;
    data: {
      totalCasesCount: number;
      totalContractAmount: number;
      totalReclaimingAmount: number;
    };
  }>;
}>;

export type CasesStatsForSalesFinance = CasesStatsFor<{
  activeClosedDuplicateAmount: number;
  inactiveClosedDuplicateAmount: number;
  totalActiveInvoiceAmount: number;
  totalActiveInvoices: number;
}>;

export type CasesStats =
  | CasesStatsForAtivosEspeciais
  | CasesStatsForPEACS
  | CasesStatsForSalesFinance;
