import { Option, CourtInstance } from 'types';

const COURT_DICTIONARY = {
  [CourtInstance.HIGHER]: 'Superior',
  [CourtInstance.FIRST]: 'Primeira'
};

export function instanceLabel(instance: CourtInstance): string {
  return COURT_DICTIONARY[instance] || instance;
}

export function instanceOption(instance: CourtInstance): Option {
  return {
    value: instance,
    label: instanceLabel(instance)
  };
}
