import { ERROR_MESSAGE, ReportAssetName } from 'domain/reports';
import { buildPaginationParams } from 'domain/pagination';
import React from 'react';

import {
  Accordion,
  Card,
  DropDown,
  openSuccessToast,
  openErrorToast,
  DynamicTable,
  TablePositionModifier,
  Stack,
  StackMargin
} from 'ui';

import { applyMask, handleNullValue } from 'utils';
import compact from 'lodash/compact';
import { openReportTab } from 'pages/reports/utils';
import {
  createReportFromElectoralDonations,
  addElectoralDonationToCase,
  fetchReportElectoralDonations,
  fetchReportElectoralDonationsSummary,
  fetchReportElectoralDonationsTotalValue
} from 'api';

import { ReportElectoralDonation, ActionName, ActionEntity } from 'types';
import { FormerValueTooltip, ProviderTooltip, SummaryError } from 'components';
import { useQuery } from 'hooks';
import * as Actions from 'utils/actions';

import SummaryTitle from 'pages/reports/SharedComponents/SummaryTitle';
import ReportDynamicTable from '../../ReportDynamicTable/ReportDynamicTable';

import style from './ElectoralDonationsTable.module.scss';

type Props = {
  caseId: string;
  reportId: string;
  addToCase?: boolean;
  research?: boolean;
  isSummary?: boolean;
  providerStatus?: string;
  tableMessage?: string;
};

const ElectoralDonationsTable = ({
  caseId,
  reportId,
  addToCase = true,
  research = true,
  isSummary,
  providerStatus,
  tableMessage
}: Props) => {
  const query = isSummary
    ? fetchReportElectoralDonationsSummary
    : fetchReportElectoralDonations;

  const {
    data: electoralDonations = [],
    isLoading,
    error,
    refetch,
    pagination
  } = useQuery(query, {
    caseId,
    reportId
  });

  const { data: electoralDonationsTotalValue } = useQuery(
    fetchReportElectoralDonationsTotalValue,
    {
      caseId,
      reportId
    }
  );

  if (error) {
    return (
      <SummaryError
        title="Doações eleitorais"
        message={ERROR_MESSAGE.electoralDonations}
      />
    );
  }

  const handleSearch = (
    electoralDonationId: ReportElectoralDonation['id']
  ) => async () => {
    try {
      const response = await createReportFromElectoralDonations({
        caseId,
        reportId,
        contentId: electoralDonationId
      });

      openSuccessToast('Consulta salva com sucesso!');
      openReportTab(response.data);
    } catch (error) {
      openErrorToast('Houve um erro ao criar a consulta.');
    }
  };

  const handleAddToCase = (
    electoralDonationId: ReportElectoralDonation['id']
  ) => async () => {
    try {
      await addElectoralDonationToCase({
        caseId,
        reportId,
        contentId: electoralDonationId
      });
      openSuccessToast('Indivíduo adicionado ao caso com sucesso');
    } catch (error) {
      openErrorToast('Não foi possivel adicionar o indivíduo ao caso');
    }
  };

  const isEmpty = electoralDonations.length < 1;

  const buildActions = ({
    id,
    field: { actions }
  }: ReportElectoralDonation) => {
    const createReportAction = {
      text: 'Buscar',
      callback: handleSearch(id),
      isDisabled: Actions.isDisabled(actions, ActionName.CREATE_REPORT),
      description: Actions.unavailableReasons(
        actions,
        ActionName.CREATE_REPORT,
        ActionEntity.INDIVIDUAL
      )
    };
    const addToCaseAction = {
      text: 'Adicionar ao caso',
      callback: handleAddToCase(id),
      isDisabled: Actions.isDisabled(actions, ActionName.ADD_TO_CASE),
      description: Actions.unavailableReasons(
        actions,
        ActionName.ADD_TO_CASE,
        ActionEntity.INDIVIDUAL
      )
    };

    const allActionsAllowed = compact([
      addToCase && addToCaseAction,
      research && createReportAction
    ]);

    return allActionsAllowed;
  };

  const columns = [
    {
      name: 'Ano da doação',
      columnKey: 'year',
      render: (item: ReportElectoralDonation) => (
        <FormerValueTooltip
          fieldName="year"
          fieldValue={item.year}
          history={item.field.history}
        />
      )
    },
    {
      name: 'CPF do recebedor',
      columnKey: 'receiverCpf',
      render: (item: ReportElectoralDonation) => (
        <FormerValueTooltip
          fieldName="receiverCpf"
          fieldValue={item.receiverCpf}
          history={item.field.history}
          formatter={value => applyMask('cpf', value)}
        />
      )
    },
    {
      name: 'Nome do recebedor',
      columnKey: 'receiverName',
      render: (item: ReportElectoralDonation) => (
        <FormerValueTooltip
          fieldName="receiverName"
          fieldValue={item.receiverName}
          history={item.field.history}
        />
      )
    },
    {
      name: 'Valor da doação',
      columnKey: 'valueCents',
      render: (item: ReportElectoralDonation) => (
        <FormerValueTooltip
          fieldName="valueCents"
          fieldValue={item.valueCents}
          history={item.field.history}
          formatter={value => applyMask('currency', value)}
        />
      )
    }
  ];

  const actionColumn = [
    {
      positionModifier: TablePositionModifier.RIGHT,
      hasActions: true,
      name: '',
      columnKey: 'actions',
      render: (item: ReportElectoralDonation) => (
        <>
          <ProviderTooltip
            providers={item.field.providers}
            searchedByHomonym={item.field.searchedByHomonym}
            dataTestId="provider-tooltip"
          />
          {buildActions(item).length > 0 && (
            <DropDown options={buildActions(item)} />
          )}
        </>
      )
    }
  ];

  const rowKey = (item: ReportElectoralDonation) => item.id;

  const totalValue = handleNullValue(
    electoralDonationsTotalValue?.totalValue,
    'string',
    value => applyMask('currency', value)
  );

  if (isSummary) {
    return (
      <>
        <Stack marginBottom={StackMargin.SMALL}>
          <SummaryTitle
            headerInfo={{
              label: 'Soma dos valores das doações:',
              value: totalValue
            }}
          >{`Doações eleitorais (${electoralDonations.length})`}</SummaryTitle>
        </Stack>
        <DynamicTable
          columns={columns}
          data={electoralDonations}
          rowKey={rowKey}
          getRowClassName={() => style.summaryTableRow}
          noAutoPaginate
          isLoading={isLoading}
        />
      </>
    );
  }

  const refetchWithPagination = (pagination?: {
    page: number;
    pageSize: number;
  }) => {
    refetch({
      caseId,
      reportId,
      pagination
    });
  };

  const paginationProps = buildPaginationParams(
    pagination,
    refetchWithPagination
  );

  return (
    <Accordion
      title="Doações eleitorais"
      badgeContent={pagination?.totalEntries}
      tooltipText={providerStatus}
      headerInfo={{
        label: 'Soma dos valores das doações:',
        value: totalValue
      }}
      isClosed={isEmpty && !tableMessage}
    >
      <Card small>
        <ReportDynamicTable
          columns={[...columns, ...actionColumn]}
          data={electoralDonations}
          rowKey={rowKey}
          pagination={paginationProps}
          isLoading={isLoading}
          emptyText={tableMessage}
          reportAssetName={ReportAssetName.ELECTORAL_DONATIONS}
        />
      </Card>
    </Accordion>
  );
};

export default ElectoralDonationsTable;
