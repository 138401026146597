import { FileUpload } from 'types';

export enum UploadState {
  INPROGRESS = 'inprogress',
  ERROR = 'error',
  SUCCESS = 'success'
}

export enum ReducerActionType {
  ERROR,
  NEW,
  UPDATEPROGRESS,
  SUCCESS,
  DELETE,
  RESET
}

export type ProgressBar = {
  id?: number;
  state?: UploadState;
  progress?: number;
  imageId?: string;
};

export type ReducerImage = Partial<FileUpload> & {
  uploadProgress?: ProgressBar;
};

export type ReducerActions =
  | {
      type: ReducerActionType.ERROR;
      payload: {
        id: number;
      };
    }
  | {
      type: ReducerActionType.NEW;
      payload: {
        id: number;
      };
    }
  | {
      type: ReducerActionType.UPDATEPROGRESS;
      payload: {
        id: number;
        progress: number;
      };
    }
  | {
      type: ReducerActionType.SUCCESS;
      payload: {
        image: FileUpload;
        id: number;
      };
    }
  | {
      type: ReducerActionType.DELETE;
      payload: {
        imageId: string;
      };
    }
  | {
      type: ReducerActionType.RESET;
      payload: {
        images: FileUpload[];
      };
    };
