import { useEffect, useMemo } from 'react';
import { useChannel } from 'components';

type Message = {
  id: string;
};

type Params = {
  caseId: string;
  instagramProfileId?: string;
  onMessage: (message: Message) => Promise<void>;
};

const EVENT_NAME = 'instagram_profile_updated';

function useLiveInstagramProfile({
  caseId,
  instagramProfileId,
  onMessage
}: Params) {
  const topic = useMemo(() => {
    if (instagramProfileId) {
      return `cases:${caseId}:instagram_profiles:${instagramProfileId}`;
    }

    return `cases:${caseId}`;
  }, [caseId, instagramProfileId]);

  const { channel, isConnected } = useChannel(topic);

  useEffect(() => {
    if (!channel || !isConnected) return;

    const queue: string[] = [];
    let isRefetching = false;

    const handleMessage = async (message: Message) => {
      if (!isRefetching && queue.length > 0) {
        isRefetching = true;
        queue.splice(0, queue.length);

        await onMessage(message).finally(() => {
          isRefetching = false;

          handleMessage(message);
        });
      }
    };

    channel.on(EVENT_NAME, (message: Message) => {
      queue.push(EVENT_NAME);

      handleMessage(message);
    });

    return () => {
      if (channel) {
        channel.off(EVENT_NAME);
      }
    };
  }, [channel, isConnected, onMessage]);
}

export { useLiveInstagramProfile };
