import React, { ReactNode, ReactElement } from 'react';
import classNames from 'classnames';

import './FormContainer.scss';

type Props = {
  children: ReactNode;
  className?: string;
  well?: boolean;
  white?: boolean;
  noPadding?: boolean;
  title?: string;
};

const FormContainer = ({
  children,
  className,
  well,
  white,
  noPadding,
  title
}: Props): ReactElement => {
  const formClass = classNames('form-container', className, {
    '-well': well,
    '-white': white,
    '-no-padding': noPadding
  });

  return (
    <div className={formClass} data-testid="form-container">
      {title && <h4>{title}</h4>}
      <div className="content">{children}</div>
    </div>
  );
};

export default FormContainer;
