import { mountReportAddress } from 'domain/reportLocation';
import { ERROR_MESSAGE } from 'domain/reports';
import { providerTableText, providerTooltipText } from 'domain/providers';
import React, { useState } from 'react';

import {
  ErrorBoundary,
  ProviderTooltip,
  ReportRelatedInstagramProfilesTable,
  BookmarkedInstagramPublicationsTable
} from 'components';
import { can, Action } from 'api';
import {
  ReportIndividual,
  Report,
  CardInfoItem,
  Feature,
  ReportExpectedTable,
  InstagramSubjectStatus
} from 'types';
import { CardInfo, Toast, ToastType, Stack, StackMargin } from 'ui';
import {
  applyMask,
  handleNullValue,
  handleEmptyArray,
  reportFieldFirstValue,
  handleMultipleProviders
} from 'utils';
import { useFeatureFlag } from 'hooks';

import BasicInfo from '../../SharedComponents/BasicInfo/BasicInfo';
import ExternalLawsuits from '../ExternalLawsuit';
import VehiclesTable from '../../SharedComponents/SharedTables/VehiclesTable';
import AirplanesTable from '../../SharedComponents/SharedTables/AirplanesTable';
import { RealEstateTable } from '../../SharedComponents/SharedTables';
import RuralPropertyTable from '../../SharedComponents/SharedTables/RuralPropertyTable';
import { default as EditableFieldsForm } from '../Form';
import FamilyMembersTable from '../../SharedComponents/IndividualTables/FamilyMembersTable';
import PartnershipCompaniesTable from '../../SharedComponents/IndividualTables/PartnershipCompaniesTable';
import ElectoralDonationsTable from '../../SharedComponents/IndividualTables/ElectoralDonationsTable';
import InstagramPublicationsTable from '../../SharedComponents/IndividualTables/InstagramPublicationsTable';
import CitiesAndLocations from '../../SharedComponents/CitiesAndLocations/CitiesAndLocations';
import InstagramCardInfo from './InstagramCardInfo';

import style from './Individual.module.scss';

type Props = {
  report: Report;
  onUpdate: (_: Report) => void;
  triggerExternalLawsuitsQueries: () => Promise<void>;
  setReportData: (data: Report) => void;
};

const Individual = ({
  onUpdate,
  report,
  triggerExternalLawsuitsQueries,
  setReportData
}: Props) => {
  const [isExternalLawsuitsEnabled] = useFeatureFlag(Feature.EXTERNAL_LAWSUITS);
  const [isInstagramReportEnabled] = useFeatureFlag(Feature.INSTAGRAM_REPORT);
  const [isBookmarkInstagramPublicationEnabled] = useFeatureFlag(
    Feature.BOOKMARK_INSTAGRAM_PUBLICATION
  );

  const [isMapUpdating, setIsMapUpdating] = useState<boolean>(false);

  function makeCardInfoOtherInformations(individual: ReportIndividual | null) {
    const informationArray: Array<CardInfoItem> = [
      {
        label: 'Telefones',
        value: handleEmptyArray(
          reportFieldFirstValue(individual?.phones)?.map(phone =>
            applyMask('phone', phone)
          )
        ),
        tooltip:
          individual?.phones && individual.phones.length > 0 ? (
            <ProviderTooltip
              providers={handleMultipleProviders(individual.phones)}
            />
          ) : null
      },

      {
        label: 'CEP',
        value: handleNullValue(
          reportFieldFirstValue(individual?.address)?.postalCode
        ),
        tooltip: individual?.address ? (
          <ProviderTooltip
            providers={handleMultipleProviders(individual.address)}
          />
        ) : null
      },
      {
        label: 'Endereço',
        value: mountReportAddress(reportFieldFirstValue(individual?.address)),
        tooltip: individual?.address ? (
          <ProviderTooltip
            providers={handleMultipleProviders(individual.address)}
          />
        ) : null
      },
      {
        label: 'E-mail pessoal',
        value: handleNullValue(reportFieldFirstValue(individual?.email)),
        tooltip: individual?.email ? (
          <ProviderTooltip
            providers={handleMultipleProviders(individual.email)}
          />
        ) : null
      }
    ];

    return informationArray;
  }

  const hasInstagramProfile =
    report.selectedInstagramProfile &&
    report.instagramSubject?.status !== InstagramSubjectStatus.PENDING;

  const negotiatorId = report.caseNegotiatorId;

  const canUser = {
    editReport: can(Action.CASOS_RELATORIO_PFPJ_EDITAR, negotiatorId),
    seeMap: can(Action.CASOS_RELATORIO_PFPJ_MOSTRAR_MAPA, negotiatorId),
    addToCase: can(Action.CASOS_RELATORIO_PFPJ_ADICIONAR_AO_CASO, negotiatorId),
    research: can(Action.CASOS_RELATORIO_PFPJ_BUSCAR, negotiatorId),
    showInstagramReport: can(
      Action.CASOS_RELATORIO_PFPJ_VER_PERFIL_INSTAGRAM,
      negotiatorId
    ),
    updateInstagramReport: can(
      Action.CASOS_RELATORIO_PFPJ_ATUALIZAR_PERFIL_INSTAGRAM,
      report.caseNegotiatorId
    ),
    removeBookmarkedPublication: can(
      Action.CASOS_RELATORIO_PFPJ_REMOVER_DE_POSTAGENS_SALVAS,
      report?.caseNegotiatorId
    )
  };

  return (
    <>
      <CitiesAndLocations
        title="Praças"
        isMapUpdating={isMapUpdating}
        caseId={report.caseId}
        reportId={report.id}
        showInstagramPostOnPin={canUser.showInstagramReport}
        showInstagramLocations={!!hasInstagramProfile}
        canBookmarkPublication={canUser.removeBookmarkedPublication}
        showMap={canUser.seeMap}
      />

      {report.entity && (
        <EditableFieldsForm
          className={style.reportIndividual}
          caseId={report.caseId}
          entity={report.entity}
          isDisabled={!canUser.editReport}
          onUpdate={entity =>
            onUpdate({
              ...report,
              ...(report!.entity ? { entity } : null)
            })
          }
        />
      )}

      <div className={style.reportIndividual} data-testid="report-individual">
        <div className={style.information}>
          <div className={style.col}>
            <BasicInfo
              report={report}
              providerStatus={providerTooltipText(
                report,
                ReportExpectedTable.INDIVIDUAL
              )}
            />
          </div>
          <div className={style.col}>
            <ErrorBoundary
              fallBackComponent={
                <Toast
                  type={ToastType.ERROR}
                  message={ERROR_MESSAGE.otherInformations}
                />
              }
            >
              <CardInfo
                dataTestId="card-info-other-informations"
                title="Outras informações"
                tooltipText={providerTooltipText(
                  report,
                  ReportExpectedTable.INDIVIDUAL
                )}
                items={makeCardInfoOtherInformations(report.individual)}
              />
            </ErrorBoundary>
            {canUser.showInstagramReport &&
              isInstagramReportEnabled &&
              (report.instagramSubject || canUser.updateInstagramReport) && (
                <Stack marginTop={StackMargin.LARGE}>
                  <InstagramCardInfo
                    report={report}
                    setIsMapUpdating={setIsMapUpdating}
                  />
                </Stack>
              )}
          </div>
        </div>

        {canUser.showInstagramReport &&
          isInstagramReportEnabled &&
          hasInstagramProfile && (
            <Stack marginTop={StackMargin.XLARGE}>
              <ErrorBoundary
                fallBackComponent={
                  <Toast
                    type={ToastType.ERROR}
                    message={ERROR_MESSAGE.relatedInstagramProfiles}
                  />
                }
              >
                <ReportRelatedInstagramProfilesTable
                  caseId={report.caseId}
                  key={report.selectedInstagramProfileId}
                  reportId={report.id}
                  selectedInstagramProfile={report.selectedInstagramProfile}
                  canBookmarkPublication={canUser.removeBookmarkedPublication}
                />
              </ErrorBoundary>
            </Stack>
          )}

        {canUser.showInstagramReport &&
          isInstagramReportEnabled &&
          hasInstagramProfile && (
            <Stack marginTop={StackMargin.XLARGE}>
              <ErrorBoundary
                fallBackComponent={
                  <Toast
                    type={ToastType.ERROR}
                    message={ERROR_MESSAGE.instagramPublications}
                  />
                }
              >
                <InstagramPublicationsTable
                  caseId={report.caseId}
                  reportId={report.id}
                  canBookmarkPublication={canUser.removeBookmarkedPublication}
                />
              </ErrorBoundary>
            </Stack>
          )}

        {canUser.showInstagramReport &&
          isBookmarkInstagramPublicationEnabled &&
          hasInstagramProfile && (
            <Stack marginTop={StackMargin.XLARGE}>
              <ErrorBoundary
                fallBackComponent={
                  <Toast
                    type={ToastType.ERROR}
                    message={ERROR_MESSAGE.bookmarkedInstagramPublications}
                  />
                }
              >
                <BookmarkedInstagramPublicationsTable
                  caseId={report.caseId}
                  reportId={report.id}
                  canBookmarkPublication={canUser.removeBookmarkedPublication}
                />
              </ErrorBoundary>
            </Stack>
          )}

        <Stack marginTop={StackMargin.XLARGE}>
          <ErrorBoundary
            fallBackComponent={
              <Toast
                type={ToastType.ERROR}
                message={ERROR_MESSAGE.familyMember}
              />
            }
          >
            <FamilyMembersTable
              caseId={report.caseId}
              reportId={report.id}
              addToCase={canUser.addToCase}
              research={canUser.research}
              providerStatus={providerTooltipText(
                report,
                ReportExpectedTable.FAMILY_MEMBER
              )}
              tableMessage={providerTableText(
                report,
                ReportExpectedTable.FAMILY_MEMBER
              )}
            />
          </ErrorBoundary>
        </Stack>

        <Stack marginTop={StackMargin.XLARGE}>
          <ErrorBoundary
            fallBackComponent={
              <Toast
                type={ToastType.ERROR}
                message={ERROR_MESSAGE.partnershipCompanies}
              />
            }
          >
            <PartnershipCompaniesTable
              caseId={report.caseId}
              reportId={report.id}
              addToCase={canUser.addToCase}
              research={canUser.research}
              providerStatus={providerTooltipText(
                report,
                ReportExpectedTable.PARTNERSHIP_COMPANY
              )}
              tableMessage={providerTableText(
                report,
                ReportExpectedTable.PARTNERSHIP_COMPANY
              )}
            />
          </ErrorBoundary>
        </Stack>

        <Stack marginTop={StackMargin.XLARGE}>
          <ErrorBoundary
            fallBackComponent={
              <Toast
                type={ToastType.ERROR}
                message={ERROR_MESSAGE.realEstate}
              />
            }
          >
            <RealEstateTable
              caseId={report.caseId}
              reportId={report.id}
              addToCase={canUser.addToCase}
              providerStatus={providerTooltipText(
                report,
                ReportExpectedTable.REAL_ESTATE
              )}
              tableMessage={providerTableText(
                report,
                ReportExpectedTable.REAL_ESTATE
              )}
            />
          </ErrorBoundary>
        </Stack>

        <Stack marginTop={StackMargin.XLARGE}>
          <ErrorBoundary
            fallBackComponent={
              <Toast type={ToastType.ERROR} message={ERROR_MESSAGE.vehicles} />
            }
          >
            <VehiclesTable
              caseId={report.caseId}
              reportId={report.id}
              addToCase={canUser.addToCase}
              providerStatus={providerTooltipText(
                report,
                ReportExpectedTable.VEHICLE
              )}
              tableMessage={providerTableText(
                report,
                ReportExpectedTable.VEHICLE
              )}
            />
          </ErrorBoundary>
        </Stack>

        <Stack marginTop={StackMargin.XLARGE}>
          <ErrorBoundary
            fallBackComponent={
              <Toast type={ToastType.ERROR} message={ERROR_MESSAGE.airplanes} />
            }
          >
            <AirplanesTable
              caseId={report.caseId}
              reportId={report.id}
              addToCase={canUser.addToCase}
              providerStatus={providerTooltipText(
                report,
                ReportExpectedTable.AIRPLANE
              )}
              tableMessage={providerTableText(
                report,
                ReportExpectedTable.AIRPLANE
              )}
            />
          </ErrorBoundary>
        </Stack>

        <Stack marginTop={StackMargin.XLARGE}>
          <ErrorBoundary
            fallBackComponent={
              <Toast
                type={ToastType.ERROR}
                message={ERROR_MESSAGE.ruralProperty}
              />
            }
          >
            <RuralPropertyTable
              caseId={report.caseId}
              reportId={report.id}
              addToCase={canUser.addToCase}
              providerStatus={providerTooltipText(
                report,
                ReportExpectedTable.RURAL_PROPERTY
              )}
              tableMessage={providerTableText(
                report,
                ReportExpectedTable.RURAL_PROPERTY
              )}
            />
          </ErrorBoundary>
        </Stack>

        <Stack marginTop={StackMargin.XLARGE}>
          <ErrorBoundary
            fallBackComponent={
              <Toast
                type={ToastType.ERROR}
                message={ERROR_MESSAGE.electoralDonations}
              />
            }
          >
            <ElectoralDonationsTable
              caseId={report.caseId}
              reportId={report.id}
              addToCase={canUser.addToCase}
              research={canUser.research}
              providerStatus={providerTooltipText(
                report,
                ReportExpectedTable.ELECTORAL_DONATION
              )}
              tableMessage={providerTableText(
                report,
                ReportExpectedTable.ELECTORAL_DONATION
              )}
            />
          </ErrorBoundary>
        </Stack>

        {isExternalLawsuitsEnabled && (
          <Stack marginTop={StackMargin.XLARGE}>
            <ErrorBoundary
              fallBackComponent={
                <Toast
                  type={ToastType.ERROR}
                  message={ERROR_MESSAGE.externalLawsuits}
                />
              }
            >
              <ExternalLawsuits
                report={report}
                triggerExternalLawsuitsQueries={triggerExternalLawsuitsQueries}
                setReportData={setReportData}
                providerStatus={providerTooltipText(
                  report,
                  ReportExpectedTable.EXTERNAL_LAWSUIT
                )}
                defaultEmptyMessage={providerTableText(
                  report,
                  ReportExpectedTable.EXTERNAL_LAWSUIT
                )}
              />
            </ErrorBoundary>
          </Stack>
        )}
      </div>
    </>
  );
};

export default Individual;
