import { indicationLabel } from 'domain/externalLawsuits';
import React from 'react';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { useRouteMatch } from 'react-router-dom';

import { Card, Link, Icon, Badge, ToolTip, Stack, StackMargin } from 'ui';

import { applyMask, applyDateMask } from 'utils';
import { ExternalLawsuit } from 'types';
import { LinkLocation } from 'ui/Link/Link';

import ExternalLawsuitCardLoader from './ExternalLawsuitCardLoader';

import styles from './ExternalLawsuitCard.module.scss';

type Props = {
  externalLawsuit?: ExternalLawsuit;
  hideMonitoringIcon?: boolean;
  hideTrackingIcon?: boolean;
  isLoading?: boolean;
  omitCauseAmount?: boolean;
  withoutTitle?: boolean;
  showParties?: boolean;
  title?: React.ReactElement;
  className?: string;
  showFullInfo?: boolean;
};

const filterNullUndefined = (items: (null | string | undefined)[]): string[] =>
  items.filter((item): item is string => typeof item === 'string');

type TitleProps = {
  lawsuit: ExternalLawsuit;
  hideTitleLink?: boolean;
};

const TriggerTitle = ({ lawsuit, hideTitleLink = false }: TitleProps) => {
  const match: {
    params: { caseId: string };
    url: string;
  } = useRouteMatch();

  const location: LinkLocation = {
    pathname: `/cases/${match.params.caseId}/external_lawsuits/${lawsuit.id}`,
    state: { returnTo: match.url }
  };

  return (
    <h3 className={styles.title}>
      <Link
        className={styles.titleLink}
        highlight
        underline
        disabled={hideTitleLink}
        to={location}
        aria-label="numero do processo"
      >
        {lawsuit.number}
        {hideTitleLink || <Icon name="arrow-right" small />}
      </Link>
    </h3>
  );
};

const ExternalLawsuitCard = ({
  externalLawsuit,
  hideMonitoringIcon,
  hideTrackingIcon,
  isLoading,
  omitCauseAmount,
  withoutTitle,
  showParties,
  title,
  className,
  showFullInfo = false
}: Props) => {
  const content = externalLawsuit?.content;
  const basicInformation = content?.basicInformation;
  const isTracked = externalLawsuit?.isTracked ?? false;
  const isMonitored = externalLawsuit?.isMonitored ?? false;

  const tags = uniq(
    basicInformation?.flatMap(basicInformation => {
      return filterNullUndefined([
        basicInformation.lawSegment,
        basicInformation.degree,
        basicInformation.status,
        basicInformation.class,
        basicInformation.judgingBody
      ]);
    })
  );

  const number = externalLawsuit?.number;
  const court = basicInformation?.find(({ court }) => court)?.court;
  const originUnit = basicInformation?.find(({ originUnit }) => originUnit)
    ?.originUnit;
  const judgeName = basicInformation?.find(({ judgeName }) => judgeName)
    ?.judgeName;
  const lawSegment = basicInformation?.find(({ lawSegment }) => lawSegment)
    ?.lawSegment;
  const segment = lawSegment ? `Vara ${lawSegment}` : null;
  const subtitle = filterNullUndefined([
    court,
    segment,
    originUnit,
    judgeName
  ]).join(', ');

  const showTitle = !withoutTitle && !!number;
  const showMonitoringIcon = !hideMonitoringIcon && !!isMonitored;
  const showTrackedIcon = !hideTrackingIcon && !!isTracked;
  const showHeader = showTitle || showMonitoringIcon || showTrackedIcon;
  const showPartiesList = showParties && !!content?.parties.length;

  if (isLoading) {
    return <ExternalLawsuitCardLoader withoutTitle={withoutTitle} />;
  }

  return (
    <Card medium aria-label="lawsuit" className={className}>
      {showHeader && (
        <div className={styles.header}>
          {showTitle && title}

          {showMonitoringIcon && (
            <ToolTip content="Processo em acompanhamento" placement="top">
              <div className={styles.iconWrapper}>
                <Icon
                  name="eye"
                  fill="#62667A"
                  small
                  dataTestId="monitored-lawsuit"
                />
              </div>
            </ToolTip>
          )}
          {showTrackedIcon && (
            <ToolTip
              content="Processo em rastreamento de ativos"
              placement="top"
            >
              <div className={styles.iconWrapper}>
                <Icon
                  name="radar"
                  fill="#62667A"
                  small
                  dataTestId="tracked-lawsuit"
                />
              </div>
            </ToolTip>
          )}
        </div>
      )}

      {subtitle && (
        <div className={styles.info}>
          <h4 className={styles.text}>{subtitle}</h4>
        </div>
      )}

      {showFullInfo &&
        content?.subjects.map((subject, index) => (
          <div
            className={styles.text}
            key={`${subject.split(' ').join('-')}-${index}`}
          >
            {subject}
          </div>
        ))}

      {externalLawsuit?.indications?.map(indication => {
        return (
          <div className={styles.indication} key={indication}>
            Indícios de {indicationLabel(indication)}
          </div>
        );
      })}

      {tags && (
        <div className={styles.tags}>
          {tags.map((tag, i) => (
            <Badge rounded key={i}>
              {tag}
            </Badge>
          ))}
        </div>
      )}

      {uniqBy(basicInformation, 'value').map((basicInformation, index) => (
        <div className={styles.distributionDateWrapper} key={index}>
          <h5 className={styles.distributionDate}>
            Distribuição:
            {externalLawsuit?.distributionDate && (
              <strong className={styles.bold}>
                {applyDateMask(externalLawsuit.distributionDate)}
              </strong>
            )}
          </h5>
          {!omitCauseAmount && (
            <h5 className={styles.value}>
              Valor da Causa:
              <strong className={styles.bold}>
                {basicInformation.value
                  ? applyMask('currency', basicInformation.value.toString())
                  : '-'}
              </strong>
            </h5>
          )}
        </div>
      ))}

      {showPartiesList && (
        <Stack marginTop={StackMargin.MEDIUM}>
          <h4 className={styles.involvedParties}>Partes envolvidas:</h4>
          {content?.parties.map((party, index) => (
            <div key={index} className={styles.party}>
              {party.name}
            </div>
          ))}
        </Stack>
      )}
    </Card>
  );
};

export default Object.assign(ExternalLawsuitCard, { TriggerTitle });
