import React, { ReactNode } from 'react';
import './LayoutAuthentication.scss';

type Props = {
  children: ReactNode;
};

const LayoutAuthentication = ({ children }: Props) => {
  return (
    <div className="layout-authentication">
      <img className="logo" src="/logo.svg" alt="logo" />
      <div className="card">{children}</div>
    </div>
  );
};

export default LayoutAuthentication;
