import React from 'react';
import { DataImportError } from 'types';
import { Pagination } from 'ui';

import { usePagination } from 'hooks/usePagination';
import style from './Errors.module.scss';

type Props = {
  errors: DataImportError[];
};

export function buildCellName(cell: number, row: number): string {
  let dividend = cell + 1;
  let columnInLetters = '';
  let columnInNumber: number;

  while (dividend > 0) {
    columnInNumber = (dividend - 1) % 26;
    columnInLetters =
      String.fromCharCode(65 + columnInNumber) + columnInLetters;
    dividend = ((dividend - columnInNumber) / 26) | 0;
  }

  return `${columnInLetters}${row + 1}`;
}

const Errors = ({ errors }: Props) => {
  const { data, paginationProps } = usePagination({ dataToPaginate: errors });

  function buildIndex({ cell: { path } }: DataImportError): string {
    return `${path.worksheet}${path.cell}${path.row}`;
  }

  return (
    <div data-testid="data-import-errors">
      {data.map(error => (
        <div
          data-testid="data-import-error"
          className={style.error}
          key={buildIndex(error)}
        >
          {`Aba “${error.cell.path.worksheet}”, célula ${buildCellName(
            error.cell.path.cell,
            error.cell.path.row
          )}, ${error.messages.join(', ')}`}
        </div>
      ))}

      <div className={style.pagination}>
        <Pagination {...paginationProps} />
      </div>
    </div>
  );
};

export default Errors;
