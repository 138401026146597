import { Action, can, deleteInvoice } from 'api';
import { DateTime, LocaleOptions } from 'luxon';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Header,
  openErrorToast,
  openSuccessToast,
  Title,
  Stack,
  StackMargin
} from 'ui';
import { useFormDirty, CaseContext } from 'components';
import { Invoice } from 'types';
import style from './Invoice.module.scss';

type Props = {
  caseId: string;
  isLoading: boolean;
  invoice?: Invoice;
};

const InvoiceHeader = ({ caseId, invoice, isLoading }: Props) => {
  const { clearAll, FormChangesMessage } = useFormDirty();
  const { caseData } = useContext(CaseContext);

  const history = useHistory();

  const handleDelete = async () => {
    if (
      !window.confirm(
        'Você tem certeza de que deseja remover essa nota fiscal?'
      )
    )
      return;

    try {
      await deleteInvoice({ caseId, id: invoice!.id });

      clearAll();

      openSuccessToast('Nota fiscal excluído com sucesso!');

      history.push(`/cases/${caseId}/debtors`);
    } catch {
      openErrorToast('Houve um erro ao excluir a nota fiscal!');
    }
  };

  const canDeleteInvoice = can(
    Action.CASOS_NOTA_FISCAL_EXCLUIR,
    caseData?.negotiator?.id
  );

  return (
    <Header
      sticky
      isLoading={isLoading}
      actionsComponent={
        canDeleteInvoice &&
        invoice && (
          <Button small outline danger type="button" onClick={handleDelete}>
            Excluir nota
          </Button>
        )
      }
    >
      <Title className={style.title}>Nota Fiscal</Title>
      {invoice && (
        <div className={style.changed}>
          {`Editado pela última vez em ${DateTime.fromISO(invoice.updatedAt)
            .setLocale('pt-BR')
            .toLocaleString(DateTime.DATE_FULL as LocaleOptions)}`}
        </div>
      )}
      <Stack marginTop={StackMargin.SMALL}>
        <FormChangesMessage />
      </Stack>
    </Header>
  );
};

export default InvoiceHeader;
