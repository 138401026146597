import React from 'react';
import cn from 'classnames';
import { Badge } from 'ui';

import style from './ColoredBadge.module.scss';

export enum BadgeColor {
  RED = 'red',
  PURPLE = 'purple',
  YELLOW = 'yellow',
  PINK = 'pink',
  ORANGE = 'orange',
  AQUAMARINE = 'aquamarine',
  GREEN = 'green',
  DEFAULT = 'grey',
  BLUE = 'blue',
  ROUGE = 'rouge'
}

type Props = {
  type: BadgeColor;
  className?: string;
  subtitle?: string;
  wrapperClassName?: string;
  title: string;
};

const ColoredBadge = ({
  type = BadgeColor.DEFAULT,
  className = '',
  wrapperClassName = '',
  subtitle,
  title
}: Props) => {
  const statusBadgeClass = cn(style.badge, className, style[type]);

  return (
    <div
      aria-label={title}
      className={cn(style.statusBadgeWrapper, wrapperClassName)}
    >
      <Badge className={statusBadgeClass} dataTestId={`badge-${type}`}>
        <span className={style.badgeTitle}>{title}</span>
        <br />
        {subtitle && (
          <span className={style.badgeSubTitle}>{`(${subtitle})`}</span>
        )}
      </Badge>
    </div>
  );
};

export default ColoredBadge;
