import React from 'react';

import './EmptyState.scss';

type Props = {
  text: string;
};

const EmptyState = ({ text }: Props) => {
  return (
    <div className="empty-state">
      <p className="text">{text}</p>
    </div>
  );
};

export default EmptyState;
