import React, { useState } from 'react';

import { Button, Table, Modal } from 'ui';
import Form from './Form/Form';

import style from './NirfCodes.module.scss';

type Props = {
  nirfCodes: string[] | undefined | null;
  disabled: boolean;
  onSave: (codes: string[]) => void;
};

const NirfCodes = ({ nirfCodes, disabled, onSave }: Props) => {
  const sanitizedCodes = nirfCodes || [];
  const [nirfModalOpen, setNirfModalOpen] = useState<boolean>(false);
  const isNirfCodesEmpty = !sanitizedCodes.length;

  const openModal = () => setNirfModalOpen(true);
  const closeModal = () => setNirfModalOpen(false);

  const handleDelete = (code: string) => () => {
    const updatedNirfCodes = sanitizedCodes.filter(
      nirfCode => code !== nirfCode
    );

    return onSave(updatedNirfCodes);
  };

  const handleCreate = (code: string) => {
    onSave([...sanitizedCodes, code]);

    return closeModal();
  };

  return (
    <>
      <Table>
        <Table.Header>
          <Table.Row small>
            <Table.Cell className={style.label}>NIRF (opcional)</Table.Cell>
          </Table.Row>
        </Table.Header>
        <Table.Body
          isEmpty={isNirfCodesEmpty}
          emptyText="Nenhum NIRF informado..."
          columns={1}
        >
          {sanitizedCodes.map((code, index) => (
            <Table.Row small key={`${code}-idx-${index}`}>
              <Table.Cell>
                <div className={style.nirfCodes}>
                  <span className={style.nirfValues} title={code}>
                    {code}
                  </span>
                  {!disabled && (
                    <Button
                      className={style.deleteButton}
                      borderless
                      dataTestId="remove-nirfcode"
                      icon="trash"
                      disabled={disabled}
                      onClick={handleDelete(code)}
                    />
                  )}
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      {!disabled && (
        <Button centered highlight outline small onClick={openModal}>
          Adicionar NIRF
        </Button>
      )}

      <Modal
        isOpen={nirfModalOpen}
        onClose={closeModal}
        dataTestId="nirf-modal"
        title="Adicionar NIRF"
      >
        <Form onSave={handleCreate} />
      </Modal>
    </>
  );
};

export default NirfCodes;
