import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react';

import { Asset } from 'types';

export type AssetOwnerData = {
  groupAssetsList: Asset[];
  setGroupAssetsList: (assets: Asset[]) => void;
};

export const AssetOwnerDefaultValues = {
  groupAssetsList: [],
  setGroupAssetsList: () => {}
};

const AssetOwnerContext = createContext<AssetOwnerData>(
  AssetOwnerDefaultValues
);

type AssetOwnerProviderProps = {
  groupedAssets: Asset[];
  children: ReactNode;
};

const AssetOwnerProvider = ({
  groupedAssets,
  children
}: AssetOwnerProviderProps) => {
  const [groupAssetsList, setGroupAssetsList] = useState<Asset[]>(
    groupedAssets
  );

  useEffect(() => {
    if (groupedAssets) {
      setGroupAssetsList(groupedAssets);
    }
  }, [groupedAssets]);

  return (
    <AssetOwnerContext.Provider
      value={{
        groupAssetsList,
        setGroupAssetsList
      }}
    >
      {children}
    </AssetOwnerContext.Provider>
  );
};

const useAssetOwner = () => useContext(AssetOwnerContext);

export { AssetOwnerProvider, useAssetOwner };
