import {
  SummaryInfoType,
  IndividualSummaryInfoType,
  CompanySummaryInfoType
} from 'types';

export const getLabel = (type: SummaryInfoType) =>
  ({
    [SummaryInfoType.DEBTORS_AND_CONTRACTS]: 'Devedores, contratos',
    [SummaryInfoType.DEBTORS_IN_JUDICIAL_RECOVERY]: 'Devedores em RJ',
    [SummaryInfoType.GUARANTEES]: 'Garantias',
    [SummaryInfoType.EVENTS]: 'Timeline negocial',
    [SummaryInfoType.SUSPECTS]: 'Suspeitos',
    [SummaryInfoType.ASSETS]: 'Ativos',
    [SummaryInfoType.CITIES]: 'Praças',
    [SummaryInfoType.LAWSUITS]: 'Processos jurídicos'
  }[type]);

export const getReportLabel = (
  type: IndividualSummaryInfoType | CompanySummaryInfoType
) =>
  ({
    [IndividualSummaryInfoType.REPORT_CITIES]: 'Praças',
    [IndividualSummaryInfoType.SAVED_POSTS]: 'Postagens salvas',
    [IndividualSummaryInfoType.FAMILY]: 'Família',
    [IndividualSummaryInfoType.PARTNER_COMPANY]: 'Empresas que é sócia',
    [IndividualSummaryInfoType.REAL_ESTATE]: 'Imóveis',
    [IndividualSummaryInfoType.RURAL_PROPERTY]: 'Imóveis Rurais',
    [IndividualSummaryInfoType.VEHICLE]: 'Veículos',
    [IndividualSummaryInfoType.AIRPLANE]: 'Aeronaves',
    [IndividualSummaryInfoType.ELECTORAL_DONATIONS]: 'Doações eleitorais',
    [CompanySummaryInfoType.REPORT_CITIES]: 'Praças',
    [CompanySummaryInfoType.SOCIETAL_STRUCTURE]: 'Estrutura societária',
    [CompanySummaryInfoType.RELATED_COMPANIES]: 'Empresas relacionadas',
    [CompanySummaryInfoType.REAL_ESTATE]: 'Imóveis',
    [CompanySummaryInfoType.RURAL_PROPERTY]: 'Imóveis Rurais',
    [CompanySummaryInfoType.VEHICLE]: 'Veículos',
    [CompanySummaryInfoType.AIRPLANE]: 'Aeronaves',
    [CompanySummaryInfoType.LOCATIONS]: 'Localizações',
    [CompanySummaryInfoType.LAWSUITS]: 'Processos'
  }[type]);
