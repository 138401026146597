import { buildPaginationParams } from 'domain/pagination';
import React, { useContext } from 'react';
import compact from 'lodash/compact';
import { applyMask, handleNullValue } from 'utils';
import {
  Action,
  can,
  deleteAsset,
  fetchAssets,
  fetchAssetsTotalValue,
  removeAssetFromGroup
} from 'api';
import { useToggles, useQuery } from 'hooks';

import {
  Card,
  Button,
  Modal,
  openErrorToast,
  openSuccessToast,
  Accordion,
  Stack,
  StackMargin
} from 'ui';
import {
  AssetTable,
  AssetForm,
  usePaginationContext,
  CaseContext
} from 'components';

import {
  FIRST_PAGE,
  DEFAULT_PAGE_SIZE,
  Asset,
  PaginatedResource,
  AssetOptions
} from 'types';

import CreateAssetGroupModal from './AssetGroup/CreateAssetGroupModal';

import style from './Assets.module.scss';

type Props = {
  caseId: string;
};

const Assets = ({ caseId }: Props) => {
  const {
    getOrderBy,
    getPagination,
    changePagination
  } = usePaginationContext();

  const { caseData } = useContext(CaseContext);
  const negotiatorId = caseData?.negotiator.id;

  const { isOpen, toggle, closeAll } = useToggles<
    'createAsset' | 'createAssetGroup'
  >({
    createAsset: false,
    createAssetGroup: false
  });

  const { data: assets = [], pagination, error, isLoading, refetch } = useQuery(
    fetchAssets,
    {
      caseId,
      ...getPagination(PaginatedResource.ASSETS),
      ...getOrderBy(PaginatedResource.ASSETS)
    }
  );

  const { data: assetValueData } = useQuery(fetchAssetsTotalValue, {
    caseId
  });

  const handleCreate = () => {
    refetch({
      caseId,
      pagination: {
        page: pagination?.number ?? FIRST_PAGE,
        pageSize: pagination?.size ?? DEFAULT_PAGE_SIZE
      }
    });

    closeAll();
  };

  if (error) {
    openErrorToast('Houve um erro ao listar ativos');
  }

  const handleRemoveAsset = async (asset: Asset) => {
    try {
      await removeAssetFromGroup(caseId, asset.id);

      refetch({
        caseId,
        pagination: {
          page: pagination?.number ?? FIRST_PAGE,
          pageSize: pagination?.size ?? DEFAULT_PAGE_SIZE
        }
      });

      openSuccessToast('Ativo removido do grupo com sucesso!');
    } catch (error) {
      openErrorToast('Houve um erro ao remover o ativo do grupo');
    }
  };

  const handleDeleteAsset = async (asset: Asset) => {
    try {
      await deleteAsset(caseId, asset.id);

      refetch({
        caseId,
        pagination: {
          page: pagination?.number ?? FIRST_PAGE,
          pageSize: pagination?.size ?? DEFAULT_PAGE_SIZE
        }
      });

      openSuccessToast('Ativo deletado com sucesso!');
    } catch (error) {
      openErrorToast('Houve um erro ao excluir o ativo');
    }
  };

  const handleDelete = (asset: Asset) => () => {
    if (!window.confirm('Você tem certeza de que deseja remover esse ativo?'))
      return;
    asset.assetGroupId ? handleRemoveAsset(asset) : handleDeleteAsset(asset);
  };

  const handlePaginationChange = (pagination?: {
    page: number;
    pageSize: number;
  }) => {
    pagination && changePagination(PaginatedResource.ASSETS, pagination);
  };

  const paginationProps = buildPaginationParams(
    pagination,
    handlePaginationChange
  );

  const canUser = {
    addAssets: can(Action.CASOS_RASTREAMENTO_ATIVOS_INCLUIR, negotiatorId),
    createGroup: can(
      Action.CASOS_RASTREAMENTO_ATIVOS_CRIAR_GRUPO,
      negotiatorId
    ),
    showReport: can(
      Action.CASOS_RASTREAMENTO_ATIVOS_VER_RELATORIO,
      negotiatorId
    ),
    showOrigin: can(
      Action.CASOS_RASTREAMENTO_ATIVOS_VER_ORIGEM_SUSPEITO,
      negotiatorId
    ),
    copyLine: can(Action.CASOS_RASTREAMENTO_ATIVOS_COPIAR_LINHA, negotiatorId),
    deleteAsset: can(Action.CASOS_RASTREAMENTO_ATIVOS_EXCLUIR, negotiatorId),
    showGroup: can(Action.CASOS_RASTREAMENTO_ATIVOS_VER_GRUPO, negotiatorId),
    deleteGroup: can(
      Action.CASOS_RASTREAMENTO_ATIVOS_EXCLUIR_GRUPO,
      negotiatorId
    ),
    removeFromGroup: can(
      Action.CASOS_RASTREAMENTO_ATIVOS_REMOVER_DO_GRUPO,
      negotiatorId
    )
  };

  const assetOptions = compact([
    canUser.showReport && AssetOptions.SHOW_REPORT,
    canUser.showOrigin && AssetOptions.SHOW_ORIGIN,
    canUser.copyLine && AssetOptions.COPY_LINE,
    canUser.deleteAsset && AssetOptions.DELETE,
    canUser.showGroup && AssetOptions.SHOW_GROUP,
    canUser.deleteGroup && AssetOptions.DELETE_GROUP,
    canUser.removeFromGroup && AssetOptions.DELETE_FROM_GROUP
  ]);

  return (
    <Accordion
      className="assets-default"
      title="Ativos"
      badgeContent={pagination?.totalEntries}
      headerInfo={{
        label: 'Soma dos valores dos ativos: ',
        value: handleNullValue(assetValueData?.totalValue, 'string', value =>
          applyMask('currency', value)
        ),
        dataTestId: 'asset-sum-amout'
      }}
    >
      <Card small>
        <AssetTable
          caseId={caseId}
          assets={assets}
          isLoading={isLoading}
          pagination={paginationProps}
          onDeleteAsset={handleDelete}
          assetOptions={assetOptions}
        />
        <Stack marginTop={StackMargin.MEDIUM} className={style.buttonsWrapper}>
          {canUser.addAssets && (
            <Button
              dataTestId="asset-modal-trigger"
              highlight
              outline
              small
              onClick={toggle('createAsset')}
              disabled={isLoading}
            >
              Adicionar ativo
            </Button>
          )}
          {canUser.createGroup && (
            <Button
              dataTestId="asset-group-modal-trigger"
              highlight
              outline
              small
              onClick={toggle('createAssetGroup')}
              disabled={isLoading}
            >
              Criar grupo de ativos
            </Button>
          )}
        </Stack>
      </Card>

      <Modal
        isOpen={isOpen.createAsset}
        onClose={toggle('createAsset')}
        title="Adicionar ativo"
        large
      >
        <AssetForm onCreateAsset={handleCreate} caseId={caseId} />
      </Modal>

      <Modal
        isOpen={isOpen.createAssetGroup}
        onClose={toggle('createAssetGroup')}
        title="Criar grupo de ativos"
        large
      >
        <CreateAssetGroupModal
          onCreateAssetGroup={handleCreate}
          caseId={caseId}
        />
      </Modal>
    </Accordion>
  );
};

export default Assets;
