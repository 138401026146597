import React from 'react';
import Layout from 'Layouts/Layout';
import { Button, Link } from 'ui';
import { useHistory } from 'react-router-dom';
import { CaseType } from 'types';

import './Errors.scss';

type Props = {
  title?: string;
  description?: string;
  onErrorFalse?: () => void;
};

const Errors = ({
  title = 'Ops',
  description = 'Um erro inesperado ocorreu.',
  onErrorFalse = () => {}
}: Props) => {
  const history = useHistory();
  const handleBack = () => {
    onErrorFalse();
    history.goBack();
  };

  return (
    <Layout>
      <div className="error-page-default" data-testid="error-page">
        <div className="title">
          <h1>{title}</h1>
        </div>
        <p className="description">{description}</p>
        <div className="actions">
          <Button highlight onClick={handleBack} dataTestId="back-button">
            Voltar
          </Button>

          <Link to={`/cases/${CaseType.ATIVOS_ESPECIAIS}`}>
            <Button
              onClick={onErrorFalse}
              highlight
              outline
              dataTestId="back-to-cases"
            >
              Ir para a listagem de ativos especiais
            </Button>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default Errors;
