import { entityName } from 'domain/entity';
import { assetValueCents, assetTypeLabel } from 'domain/assets';
import { handleCopy, NOSELECT } from 'domain/copyTableRow';
import React, { useEffect } from 'react';
import maxBy from 'lodash/maxBy';
import compact from 'lodash/compact';

import { applyMask, applyDateMask, handleNullValue } from 'utils';
import {
  Asset,
  AssetOptions,
  EntityWithRelations,
  PaginatedResource,
  PaginationProps
} from 'types';
import { fetchEntityRelationships } from 'api';
import { useToggles, useQueryCall } from 'hooks';

import {
  DropDown,
  openErrorToast,
  ToolTip,
  DynamicTable,
  TablePositionModifier,
  Icon
} from 'ui';
import { OriginModal, AssetStatusBadges } from 'components';
import cn from 'classnames';
import style from './AssetTable.module.scss';

type Props = {
  caseId: string;
  assets: Asset[];
  isLoading: boolean;
  pagination?: PaginationProps;
  onDeleteAsset: (asset: Asset) => () => void;
  emptyText?: string;
  returnToLink?: string;
  assetOptions?: AssetOptions[];
};

const AssetTable = ({
  caseId,
  assets,
  isLoading,
  pagination,
  onDeleteAsset,
  emptyText = 'Não há ativos neste caso.',
  returnToLink,
  assetOptions = [
    AssetOptions.SHOW_REPORT,
    AssetOptions.SHOW_ORIGIN,
    AssetOptions.COPY_LINE,
    AssetOptions.DELETE,
    AssetOptions.DELETE_FROM_GROUP,
    AssetOptions.DELETE_GROUP,
    AssetOptions.SHOW_GROUP
  ]
}: Props) => {
  const { isOpen, toggle, toggleTrigger } = useToggles<
    'createAsset' | 'origin'
  >({
    createAsset: false,
    origin: false
  });

  const {
    data: entity,
    error: entityError,
    isLoading: entityLoading,
    request
  } = useQueryCall<EntityWithRelations, { caseId: string; entityId: string }>(
    fetchEntityRelationships
  );

  const getRelationships = ({
    caseId,
    entityId
  }: {
    caseId: string;
    entityId: string;
  }) => async () => {
    if (entityId !== entity?.id) await request({ caseId, entityId });

    return toggleTrigger('origin');
  };

  useEffect(() => {
    entityError && openErrorToast('Houve um erro ao listar origem');
  }, [entityError]);

  const {
    assetsToList,
    getRowClassName,
    toggleGroup,
    isGroupOpen
  } = useToggleableAssetGroups(assets);

  const mountDropdownOptions = (asset: Asset) => {
    const groupMenuOptions = compact([
      assetOptions.includes(AssetOptions.SHOW_GROUP) && {
        text: AssetOptions.SHOW_GROUP,
        isLink: true,
        link: {
          pathname: `/cases/${caseId}/assets/${asset.id}`,
          state: { returnTo: returnToLink }
        },
        dataTestId: `show-group-${asset.id}`
      },
      assetOptions.includes(AssetOptions.DELETE_GROUP) && {
        text: AssetOptions.DELETE_GROUP,
        callback: onDeleteAsset(asset),
        dataTestId: `delete-group-${asset.id}`,
        isNegative: true
      }
    ]);

    const assetMenuOptions = compact([
      assetOptions.includes(AssetOptions.SHOW_REPORT) && {
        text: AssetOptions.SHOW_REPORT,
        isLink: true,
        link: {
          pathname: `/cases/${caseId}/assets/${asset.id}`,
          state: { returnTo: returnToLink }
        },
        dataTestId: `show-asset-${asset.id}`
      },
      assetOptions.includes(AssetOptions.SHOW_ORIGIN) && {
        text: AssetOptions.SHOW_ORIGIN,
        callback: getRelationships({ caseId, entityId: asset.entity.id }),
        dataTestId: `origem-${asset.id}`,
        isDisabled: entityLoading
      },
      assetOptions.includes(AssetOptions.COPY_LINE) && {
        text: AssetOptions.COPY_LINE,
        callback: () => handleCopy(asset.id, 'assets'),
        dataTestId: `copy-${asset.id}`
      },
      (asset.assetGroupId
        ? assetOptions.includes(AssetOptions.DELETE_FROM_GROUP)
        : assetOptions.includes(AssetOptions.DELETE)) && {
        text: asset.assetGroupId
          ? AssetOptions.DELETE_FROM_GROUP
          : AssetOptions.DELETE,
        callback: onDeleteAsset(asset),
        dataTestId: `delete-asset-${asset.id}`,
        isNegative: true
      }
    ]);

    return asset.isGroup ? groupMenuOptions : assetMenuOptions;
  };

  const appraisalDateTooltipMessage = (asset: Asset) => {
    if (asset.appraisals.length)
      return `Valor da avaliação feita dia ${applyDateMask(
        maxBy(asset.appraisals, 'date')?.date || ''
      )}`;
    return '';
  };

  const columns = [
    {
      name: 'Tipo',
      columnKey: 'type',
      hasOrderBy: true,
      render: (item: Asset) => {
        if (item.isGroup) {
          return (
            <div onClick={toggleGroup(item.id)}>
              <div
                data-testid={`expand-group-${item.id}`}
                className={cn({
                  [style.groupingAssetArrow]: true,
                  [style.groupingAssetArrowDown]: isGroupOpen[item.id]
                })}
              >
                <Icon name={'arrow-right'} fill="#62667A" small />
              </div>
              {`${assetTypeLabel(item)} (${item.groupedAssets?.length ?? 0})`}
            </div>
          );
        }
        return assetTypeLabel(item);
      }
    },
    {
      name: 'Nome do ativo',
      columnKey: 'name',
      hasOrderBy: true,
      render: (item: Asset) => {
        return (
          <ToolTip content={item.comment} placement="top">
            <span>{item.name}</span>
          </ToolTip>
        );
      }
    },
    {
      name: 'Identificação',
      columnKey: 'identification',
      hasOrderBy: true,
      render: (item: Asset) => handleNullValue(item.identification)
    },
    {
      name: 'Valor',
      columnKey: 'valueCents',
      hasOrderBy: true,
      dataTestId: 'asset-cell-value-cents',
      render: (item: Asset) => {
        return (
          <ToolTip content={appraisalDateTooltipMessage(item)} placement="top">
            <span>
              {handleNullValue(assetValueCents(item), 'string', value =>
                applyMask('currency', value)
              )}
            </span>
          </ToolTip>
        );
      }
    },
    {
      name: 'Nome ou razão social',
      columnKey: 'entityName',
      hasOrderBy: true,
      dataTestId: 'entity-name-cell',
      render: (item: Asset) => entityName(item.entity)
    },
    {
      name: 'Descrição',
      columnKey: 'description',
      hasOrderBy: true,
      className: () => style.descriptionCol,
      dataTestId: 'description-cell',
      render: (item: Asset) =>
        handleNullValue(item.entity.description, 'string')
    },
    {
      name: 'Status do ativo',
      columnKey: 'status',
      className: () => style.badgeColl,
      render: (item: Asset) =>
        Boolean(item.assetGroupId) ? '-' : <AssetStatusBadges asset={item} />
    },
    {
      positionModifier: TablePositionModifier.RIGHT,
      hasActions: true,
      name: '',
      columnKey: 'actions',
      className: () => NOSELECT,
      render: (item: Asset) => {
        const dropdownOptions = mountDropdownOptions(item);

        if (dropdownOptions.length === 0) return <></>;

        return (
          <DropDown
            dataTestId={`dropdown-asset-${item.id}`}
            options={dropdownOptions}
            isDisabled={entityLoading}
            isLoading={entityLoading}
          />
        );
      }
    }
  ];

  const rowKey = (item: Asset) => item.id;

  return (
    <>
      <DynamicTable
        columns={columns}
        data={assetsToList}
        getRowClassName={getRowClassName}
        rowKey={rowKey}
        pagination={pagination}
        noAutoPaginate
        isLoading={isLoading}
        emptyText={emptyText}
        tableName="assets"
        paginatedResource={PaginatedResource.ASSETS}
      />

      {entity && (
        <OriginModal
          onClose={toggle('origin')}
          entity={entity}
          isOpen={isOpen.origin}
        />
      )}
    </>
  );
};

export default AssetTable;

type ToggleableAssetGroups = {
  assetsToList: Asset[];
  getRowClassName: (asset: Asset) => string;
  toggleGroup: (id: string) => () => void;
  isGroupOpen: Record<string, boolean>;
};

function useToggleableAssetGroups(assets: Asset[]): ToggleableAssetGroups {
  const { isOpen, toggle } = useToggles<string>({});

  const getRowClassName = (asset: Asset) => {
    if (asset.assetGroupId) {
      return cn({
        [style.groupedAsset]: true,
        [style.groupedAssetClosed]: !isOpen[asset.assetGroupId]
      });
    }
    return 'defeault-row';
  };

  const assetsToList = assets.flatMap(asset => {
    const shouldIncludGroupedAssets = asset.isGroup && isOpen[asset.id];

    return shouldIncludGroupedAssets && Array.isArray(asset.groupedAssets)
      ? [asset, ...asset.groupedAssets]
      : asset;
  });

  return {
    assetsToList,
    getRowClassName,
    toggleGroup: toggle,
    isGroupOpen: isOpen
  };
}
