import React, { useState } from 'react';

import { Feature, User } from 'types';

import { Button, Loading, Modal, openErrorToast } from 'ui';
import { useFeatureFlag, useQuery } from 'hooks';
import { Action, can, fetchUsers } from 'api';
import sortBy from 'lodash/sortBy';
import Form from './Form';

import UserList from './UserList';

const Users = () => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [userBeingEdited, setUserBeingEdited] = useState<User>();
  const { data: users = [], isLoading, error, setData: setUsers } = useQuery<
    User[],
    { roles?: string[] }
  >(fetchUsers, {});
  const [isEnvironmentBV] = useFeatureFlag(Feature.API_GATEWAY);

  if (error) {
    openErrorToast('Ocorreu um erro na listagem de usuários.');
  }

  const handleCreateUser = (user: User) => {
    setUsers(sortBy([...users, user], user => user.name.toLowerCase()));
    closeModal();
  };

  const closeModal = () => {
    setModalOpen(false);
    setUserBeingEdited(undefined);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const handleEditClick = (user: User) => {
    setUserBeingEdited(user);
    openModal();
  };

  const handleUpdate = (updatedUser: User) => {
    const updatedUsers = users.map(user => {
      if (user.id === updatedUser.id) return updatedUser;
      return user;
    });

    setUsers(sortBy(updatedUsers, user => user.name.toLowerCase()));
    closeModal();
  };

  const handleSave = (user: User) => {
    if (userBeingEdited) {
      handleUpdate(user);
    } else {
      handleCreateUser(user);
    }
  };

  return (
    <div className="users-tab">
      {can(Action.PERMISSOES_USUARIOS_INTERNOS_DO_BANCO_INCLUIR) && (
        <Button
          icon="add"
          onClick={openModal}
          dataTestId="invite-user-button"
          outline
          block
          disabled={isEnvironmentBV}
        >
          Convidar usuário
        </Button>
      )}

      {isLoading ? (
        <Loading />
      ) : (
        <UserList
          usersData={users}
          onEditClick={handleEditClick}
          isInvitationDisabled={isEnvironmentBV}
        />
      )}

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={userBeingEdited ? 'Editar usuário' : 'Convidar usuário'}
      >
        <Form
          handleSave={handleSave}
          formData={userBeingEdited}
          isNameAndEmailDisabled={isEnvironmentBV}
        />
      </Modal>
    </div>
  );
};

export default Users;
