import { assetValueCents } from 'domain/assets';
import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router';
import sum from 'lodash/sum';
import compact from 'lodash/compact';

import { Asset, AssetOptions } from 'types';
import { AssetTable, AssetForm, CaseContext } from 'components';
import { applyMask } from 'utils';
import {
  Button,
  Modal,
  openErrorToast,
  openSuccessToast,
  Stack,
  StackMargin
} from 'ui';
import { useToggles } from 'hooks';
import {
  deleteGuaranteeAsset,
  addGuaranteeAsset,
  removeAssetFromGroup,
  can,
  Action
} from 'api';

import RelateAssetModal from './RelateAssetModal/RelateAssetModal';

import style from './AssetsList.module.scss';

type Props = {
  guaranteeId?: string;
  guaranteeAssets: Asset[];
  caseId: string;
  increaseGuaranteeTotalValue: (amount: number) => void;
};

const AssetsList = ({
  guaranteeAssets,
  caseId,
  guaranteeId,
  increaseGuaranteeTotalValue
}: Props) => {
  const { pathname } = useLocation();
  const { caseData } = useContext(CaseContext);

  const { isOpen, toggle, closeAll } = useToggles<
    'createAsset' | 'relateAsset'
  >({
    createAsset: false,
    relateAsset: false
  });
  const [assets, setAssets] = useState(guaranteeAssets);

  const total = sum(
    assets.map(asset => {
      return assetValueCents(asset) || 0;
    })
  );

  const handleDelete = async (asset: Asset) => {
    if (!window.confirm('Você tem certeza de que deseja remover esse ativo?'))
      return;

    const deletedAsset = assets.find(a => a.id === asset.id);

    try {
      guaranteeId &&
        (await deleteGuaranteeAsset(caseId, guaranteeId, asset.id));

      setAssets(assets.filter(a => a.id !== asset.id));
      increaseGuaranteeTotalValue(-(assetValueCents(deletedAsset!) ?? 0));

      openSuccessToast('Ativo deletado com sucesso!');
    } catch (error) {
      openErrorToast('Houve um erro ao excluir o ativo');
    }
  };

  const handleRemoveFromGroup = async (assetToDelete: Asset) => {
    if (
      !window.confirm(
        'Você tem certeza de que deseja remover esse ativo do grupo?'
      )
    )
      return;

    try {
      await removeAssetFromGroup(caseId, assetToDelete.id);

      const updatedAsset = assets.map(asset => {
        if (asset.id === assetToDelete.assetGroupId) {
          return {
            ...asset,
            groupedAssets: asset.groupedAssets.filter(
              grouped => grouped.id !== assetToDelete.id
            )
          };
        }

        return asset;
      });

      setAssets(updatedAsset);

      openSuccessToast('Ativo removido do grupo com sucesso!');
    } catch (error) {
      openErrorToast('Houve um erro ao remover o ativo do grupo');
    }
  };

  const handleDeleteAsset = (asset: Asset) => () => {
    asset.assetGroupId ? handleRemoveFromGroup(asset) : handleDelete(asset);
  };

  const handleCreate = (asset: Asset) => {
    closeAll();
    setAssets([...assets, asset]);
    increaseGuaranteeTotalValue(assetValueCents(asset) ?? 0);
  };

  const handleAdd = async (assets: Asset[]) => {
    if (!guaranteeId) return;

    try {
      const response = await addGuaranteeAsset(caseId, guaranteeId, {
        assetIds: assets.map(asset => asset.id)
      });

      const assetTotalValue = sum(
        response.data.assets.map(asset => assetValueCents(asset) ?? 0)
      );

      closeAll();
      setAssets(response.data.assets);
      increaseGuaranteeTotalValue(assetTotalValue - total);

      openSuccessToast('Ativo adicionado com sucesso!');
    } catch (error) {
      openErrorToast('Houve um erro ao adicionar o(s) ativo(s)');
    }
  };

  const negotiatorId = caseData?.negotiator?.id;

  const canUser = {
    addAsset: can(Action.CASOS_ATIVOS_DA_GARANTIA_INCLUIR, negotiatorId),
    removeAsset: can(Action.CASOS_ATIVOS_DA_GARANTIA_EXCLUIR, negotiatorId),
    showOrigin: can(
      Action.CASOS_ATIVOS_DA_GARANTIA_VER_ORIGEM_SUSPEITO,
      negotiatorId
    ),
    copyLine: can(Action.CASOS_ATIVOS_DA_GARANTIA_COPIAR_LINHA, negotiatorId),
    showReport: can(Action.CASOS_ATIVOS_DA_GARANTIA_VER_RELATORIO, negotiatorId)
  };

  const assetOptions: AssetOptions[] = compact([
    canUser.showReport && AssetOptions.SHOW_REPORT,
    canUser.showReport && AssetOptions.SHOW_GROUP,
    canUser.showOrigin && AssetOptions.SHOW_ORIGIN,
    canUser.removeAsset && AssetOptions.DELETE,
    canUser.removeAsset && AssetOptions.DELETE_FROM_GROUP,
    canUser.removeAsset && AssetOptions.DELETE_GROUP,
    canUser.copyLine && AssetOptions.COPY_LINE
  ]);

  return (
    <div>
      <h4 className={style.title}>
        Ativos
        <span className={style.totalSum}>
          {`Total de ativos: ${applyMask('currency', total.toString())}`}
        </span>
      </h4>
      <AssetTable
        assets={assets}
        caseId={caseId}
        isLoading={false}
        onDeleteAsset={handleDeleteAsset}
        emptyText="Não há ativos nesta garantia."
        returnToLink={pathname}
        assetOptions={assetOptions}
      />

      {canUser.addAsset && (
        <Stack marginTop={StackMargin.SMALL} className={style.buttonWrapper}>
          <Button
            dataTestId="asset-modal-trigger"
            highlight
            outline
            small
            onClick={toggle('createAsset')}
            disabled={!guaranteeId}
          >
            {!guaranteeId
              ? 'Salve a garantia para poder adicionar ativos'
              : 'Adicionar novo ativo'}
          </Button>
          {Boolean(guaranteeId) && (
            <Button
              highlight
              outline
              small
              onClick={toggle('relateAsset')}
              disabled={!guaranteeId}
            >
              Adicionar ativo existente
            </Button>
          )}
        </Stack>
      )}

      <Modal
        isOpen={isOpen.createAsset}
        onClose={toggle('createAsset')}
        title="Adicionar novo ativo"
        large
      >
        <AssetForm
          onCreateAsset={handleCreate}
          caseId={caseId}
          formDefaultData={{ guaranteeId: guaranteeId }}
        />
      </Modal>

      {isOpen.relateAsset && (
        <Modal
          large
          isOpen={isOpen.relateAsset}
          onClose={toggle('relateAsset')}
          title="Adicionar ativo existente"
        >
          <RelateAssetModal caseId={caseId} onAdd={handleAdd} />
        </Modal>
      )}
    </div>
  );
};

export default AssetsList;
