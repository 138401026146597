import get from 'lodash/get';
import isNil from 'lodash/isNil';
import keys from 'lodash/keys';
import maxBy from 'lodash/maxBy';
import * as Yup from 'yup';
import * as ValidationSchema from 'utils/validationSchema';

import {
  Option,
  Asset,
  EntityType,
  AssetType,
  AssetVehicleAttribute,
  AssetOtherAttribute,
  AssetRuralPropertyAttribute,
  AssetRealEstateAttribute,
  AssetAirplaneAttribute,
  FileUpload,
  Reclaiming,
  AssetAttributes,
  AssetRuralPropertyType,
  AssetRealEstateType,
  AssetOrigin
} from 'types';
import { getWithDefault } from 'utils';

export const ASSET_ORIGIN_DICTIONARY: {
  [key in AssetOrigin]: string;
} = {
  [AssetOrigin.CONSOLIDATION]: 'Consolidação de propriedade',
  [AssetOrigin.COURT_ORDER]: 'Adjudicação',
  [AssetOrigin.PAYMENT_GRANT]: 'Dação em pagamento'
};

export function assetOriginOptions(): Option[] {
  return Object.values(AssetOrigin).map(type => ({
    value: type,
    label: ASSET_ORIGIN_DICTIONARY[type]
  }));
}

export const ASSET_IDENTIFICATION_DICTIONARY: {
  [key in AssetType]: string;
} = {
  [AssetType.AIRPLANE]: 'Prefixo',
  [AssetType.REAL_ESTATE]: 'Matrícula',
  [AssetType.RURAL_PROPERTY]: 'Matrícula',
  [AssetType.VEHICLE]: 'Placa',
  [AssetType.OTHER]: 'Identificação'
};

export function assetRealEstateIdentificationLabel(type: AssetType): string {
  return ASSET_IDENTIFICATION_DICTIONARY[type] || type;
}

export const ASSET_TYPE_DICTIONARY: { [key in AssetType]: string } = {
  [AssetType.AIRPLANE]: 'Aeronave',
  [AssetType.REAL_ESTATE]: 'Imóvel',
  [AssetType.RURAL_PROPERTY]: 'Imóvel Rural',
  [AssetType.VEHICLE]: 'Veículo',
  [AssetType.OTHER]: 'Outros'
};

export function assetTypeOptions(assetTypes?: AssetType[]): Option[] {
  const avaliableTypes = assetTypes ?? Object.values(AssetType);

  return avaliableTypes.map(type => ({
    value: type,
    label: ASSET_TYPE_DICTIONARY[type]
  }));
}

export const ASSET_RURAL_PROPERTY_TYPE_DICTIONARY: {
  [key in AssetRuralPropertyType]: string;
} = {
  [AssetRuralPropertyType.FARM]: 'Fazenda',
  [AssetRuralPropertyType.RANCH]: 'Sítio',
  [AssetRuralPropertyType.RURAL_LOT]: 'Lote rural',
  [AssetRuralPropertyType.SMALL_FARM]: 'Chácara'
};

export function assetRuralPropertyTypeOptions(): Option[] {
  return Object.values(AssetRuralPropertyType).map(type => ({
    value: type,
    label: ASSET_RURAL_PROPERTY_TYPE_DICTIONARY[type]
  }));
}

export const ASSET_REAL_ESTATE_TYPE_DICTIONARY: {
  [key in AssetRealEstateType]: string;
} = {
  [AssetRealEstateType.LAND]: 'Terreno',
  [AssetRealEstateType.ALLOTMENT]: 'Loteamento',
  [AssetRealEstateType.APARTMENT]: 'Apartamento',
  [AssetRealEstateType.APARTMENT_HOTEL]: 'Apartamento hotel',
  [AssetRealEstateType.FLAT]: 'Laje',
  [AssetRealEstateType.HOUSE]: 'Casa',
  [AssetRealEstateType.OFFICE]: 'Escritório',
  [AssetRealEstateType.COMMERCIAL_STORE]: 'Loja',
  [AssetRealEstateType.COMMERCIAL_BUILDING]: 'Prédio comercial',
  [AssetRealEstateType.INDUSTRIAL_HANGAR]: 'Galpão industrial',
  [AssetRealEstateType.SCHOOL]: 'Escola',
  [AssetRealEstateType.DISTRIBUTION_CENTER]: 'Centro de distribuição',
  [AssetRealEstateType.CONVENTION_CENTER]: 'Centro de convenção'
};

export function assetRealEstateTypeOptions(): Option[] {
  return Object.values(AssetRealEstateType).map(type => ({
    value: type,
    label: ASSET_REAL_ESTATE_TYPE_DICTIONARY[type]
  }));
}

export function assetTypeLabel(asset: Asset): string {
  if (asset.isGroup) return 'Grupo';

  return ASSET_TYPE_DICTIONARY[asset.type] ?? asset.type;
}

export const initialValues: AssetAttributes = {
  id: '',
  comment: '',
  appraisalComment: '',
  name: '',
  documentFileUploadIds: [],
  entity: {
    id: '',
    type: EntityType.INDIVIDUAL,
    individual: {
      cpf: '',
      name: '',
      isAbroad: false
    },
    company: {
      cnpj: '',
      businessName: '',
      isAbroad: false
    },
    description: ''
  },
  identification: '',
  isEditable: true,
  reclaimingIds: [],
  type: AssetType.OTHER,
  galleryFileUploadIds: [],
  assetVehicle: null,
  assetOther: null,
  assetAirplane: null,
  assetRealEstate: null,
  assetRuralProperty: null,
  autoStatus: [],
  manualStatus: [],
  appraisals: []
};

export const formatInitialValues = (
  assetData?: Asset | null,
  defaultdata?: Partial<AssetAttributes>
): AssetAttributes => {
  if (!assetData)
    return {
      ...initialValues,
      ...defaultdata
    };

  const getWithInit = (
    keys: keyof Asset | Array<string>,
    keyOnDefault?: keyof AssetAttributes
  ) =>
    getWithDefault(
      keys,
      initialValues,
      assetData,
      keyOnDefault && { keyOnDefault }
    );

  return {
    ...assetData,
    entity: {
      ...assetData.entity,
      individual: getWithInit(['entity', 'individual']),
      company: getWithInit(['entity', 'company']),
      description: getWithInit(['entity', 'description'])
    },

    documentFileUploadIds: getWithInit('documentFileUploads').map(
      (file: FileUpload) => file.id
    ),
    identification: getWithInit('identification'),
    reclaimingIds: getWithInit('reclaimings').map(
      (reclaiming: Reclaiming) => reclaiming.id
    ),
    galleryFileUploadIds: getWithInit('galleryFileUploads').map(
      (file: FileUpload) => file.id
    ),
    assetVehicle: mapValuesToProps<AssetVehicleAttribute>(
      'assetVehicle',
      assetData
    ),
    assetAirplane: mapValuesToProps<AssetAirplaneAttribute>(
      'assetAirplane',
      assetData
    ),
    assetRealEstate: mapValuesToProps<AssetRealEstateAttribute>(
      'assetRealEstate',
      assetData
    ),
    assetRuralProperty: mapValuesToProps<AssetRuralPropertyAttribute>(
      'assetRuralProperty',
      assetData
    ),
    assetOther: mapValuesToProps<AssetOtherAttribute>('assetOther', assetData),
    autoStatus: assetData.autoStatus,
    manualStatus: assetData.manualStatus
  };
};

const mapValuesToProps = <R>(key: keyof Asset, asset?: Asset) => {
  const propertyFromAsset = get(asset, key);

  if (!propertyFromAsset) return null;

  return {
    ...getCityId(key as keyof Asset, asset),
    ...keys(propertyFromAsset).reduce(
      (acc: R, currKey: string) => ({
        ...acc,
        [currKey]: get(propertyFromAsset, [currKey, 'value'], null)
      }),
      {} as R
    )
  };
};

const getCityId = (key: keyof Asset, asset?: Asset) => {
  const propertyAsset = get(asset, [key, 'city']);

  if (!propertyAsset) return null;

  return {
    cityId: get(propertyAsset, ['value', 'id'], '')
  };
};

export const assetValidationSchema = Yup.object().shape({
  type: Yup.string().required('Campo obrigatório'),
  name: Yup.string()
    .min(3, 'Precisa ter ao menos 3 caracteres')
    .max(255, 'Precisa ter menos de 256 caracteres')
    .required('Campo obrigatório'),
  identification: Yup.string().nullable(),
  entity: Yup.object().shape({
    type: Yup.string().required('Campo obrigatório'),
    individual: Yup.object().when('type', {
      is: 'individual',
      then: Yup.object().shape({
        isAbroad: Yup.boolean(),
        cpf: Yup.string().when('isAbroad', {
          is: false,
          then: ValidationSchema.cpf()
            .required('Campo obrigatório')
            .nullable(),
          otherwise: ValidationSchema.cpf()
            .notRequired()
            .nullable()
        }),
        name: Yup.string()
          .min(3, 'Precisa ter ao menos 3 caracteres')
          .max(255, 'Precisa ter menos de 256 caracteres')
          .required('Campo obrigatório')
      }),
      otherwise: Yup.object()
        .shape({
          cpf: Yup.string()
            .notRequired()
            .nullable(),
          name: Yup.string()
            .notRequired()
            .nullable()
        })
        .nullable()
    }),
    company: Yup.object().when('type', {
      is: 'company',
      then: Yup.object().shape({
        cnpj: Yup.string().when('isAbroad', {
          is: false,
          then: ValidationSchema.cnpj()
            .required('Campo obrigatório')
            .nullable(),
          otherwise: ValidationSchema.cnpj()
            .notRequired()
            .nullable()
        }),
        businessName: Yup.string()
          .min(3, 'Precisa ter ao menos 3 caracteres')
          .max(255, 'Precisa ter menos de 256 caracteres')
          .required('Campo obrigatório')
      }),
      otherwise: Yup.object()
        .shape({
          cnpj: Yup.string()
            .notRequired()
            .nullable(),
          businessName: Yup.string()
            .notRequired()
            .nullable()
        })
        .nullable()
    })
  }),
  assetRealEstate: Yup.object()
    .when(['type', 'reclaimingIds', 'isGroup'], {
      is: (type, reclaimingIds, isGroup) =>
        type === AssetType.REAL_ESTATE &&
        Boolean(reclaimingIds.length) &&
        !isGroup,
      then: Yup.object().shape({
        type: Yup.string()
          .required('Campo obrigatório')
          .nullable(),
        occupied: Yup.string()
          .required('Campo obrigatório')
          .nullable(),
        origin: Yup.string()
          .required('Campo obrigatório')
          .nullable()
      })
    })
    .when(['type', 'reclaimingIds', 'isGroup'], {
      is: (type, reclaimingIds, isGroup) =>
        type === AssetType.REAL_ESTATE &&
        Boolean(reclaimingIds.length) &&
        isGroup,
      then: Yup.object().shape({
        origin: Yup.string()
          .required('Campo obrigatório quando status está Recuperado')
          .nullable()
      })
    })
    .nullable(),
  assetRuralProperty: Yup.object()
    .when(['type', 'reclaimingIds', 'isGroup'], {
      is: (type, reclaimingIds, isGroup) =>
        type === AssetType.RURAL_PROPERTY &&
        Boolean(reclaimingIds.length) &&
        !isGroup,
      then: Yup.object().shape({
        type: Yup.string()
          .required('Campo obrigatório')
          .nullable(),
        occupied: Yup.string()
          .required('Campo obrigatório')
          .nullable(),
        origin: Yup.string()
          .required('Campo obrigatório')
          .nullable()
      })
    })
    .when(['type', 'reclaimingIds', 'isGroup'], {
      is: (type, reclaimingIds, isGroup) =>
        type === AssetType.RURAL_PROPERTY &&
        Boolean(reclaimingIds.length) &&
        isGroup,
      then: Yup.object().shape({
        origin: Yup.string()
          .required('Campo obrigatório quando status está Recuperado')
          .nullable()
      })
    })
    .nullable(),
  assetVehicle: Yup.object().when('type', {
    is: AssetType.VEHICLE,
    then: Yup.object()
      .shape({
        yearOfManufacture: Yup.string()
          .matches(/^[0-9]{4}$/, { message: 'Deve ser um ano válido' })
          .nullable(),
        yearOfModel: Yup.string()
          .matches(/^[0-9]{4}$/, { message: 'Deve ser um ano válido' })
          .nullable()
      })
      .nullable(),
    otherwise: Yup.object().nullable()
  }),
  assetAirplane: Yup.object().when('type', {
    is: AssetType.AIRPLANE,
    then: Yup.object()
      .shape({
        yearOfManufacture: Yup.string()
          .matches(/^[0-9]{4}$/, { message: 'Deve ser um ano válido' })
          .nullable()
      })
      .nullable(),
    otherwise: Yup.object().nullable()
  }),
  assetOther: Yup.object().nullable()
});

export const sanitizeInitalValues = (values: AssetAttributes) => {
  if (values.type === AssetType.REAL_ESTATE)
    return {
      ...values,
      assetRuralProperty: null
    };

  if (values.type === AssetType.RURAL_PROPERTY)
    return {
      ...values,
      assetRealEstate: null
    };

  return {
    ...values,
    assetRealEstate: null,
    assetRuralProperty: null
  };
};

export const assetValueCents = (asset: Asset) => {
  if (asset.appraisals.length)
    return maxBy(asset.appraisals, 'date')?.valueCents;
  if (asset.providerValueCents) return asset.providerValueCents;
  return null;
};

export const occupancyOptions = [
  {
    label: 'Ocupado',
    value: 'true'
  },
  {
    label: 'Desocupado',
    value: 'false'
  }
];

const hasMissingInfo = (asset: Asset, attributekeys: string[]) => {
  return attributekeys.map(key => isNil(get(asset, key, null))).includes(true);
};

const assetGroupHasMissingFields = (asset: Asset) => {
  const HasGroupedAssetsWithMissingInfo = asset.groupedAssets.some(
    assetHasMissingFields
  );

  if (HasGroupedAssetsWithMissingInfo) return true;

  if (asset.type === AssetType.REAL_ESTATE) {
    return hasMissingInfo(asset, ['assetRealEstate.origin.value']);
  }

  if (asset.type === AssetType.RURAL_PROPERTY) {
    return hasMissingInfo(asset, ['assetRuralProperty.origin.value']);
  }

  return false;
};

const assetGroupedHasMissingFields = (asset: Asset) => {
  if (asset.type === AssetType.REAL_ESTATE) {
    return hasMissingInfo(asset, [
      'assetRealEstate.occupied.value',
      'assetRealEstate.type.value'
    ]);
  }

  if (asset.type === AssetType.RURAL_PROPERTY) {
    return hasMissingInfo(asset, [
      'assetRuralProperty.occupied.value',
      'assetRuralProperty.type.value'
    ]);
  }

  return false;
};

export const assetHasMissingFields = (asset: Asset) => {
  if (asset.isGroup) {
    return assetGroupHasMissingFields(asset);
  }

  if (asset.assetGroupId) {
    return assetGroupedHasMissingFields(asset);
  }

  if (asset.type === AssetType.REAL_ESTATE) {
    return hasMissingInfo(asset, [
      'assetRealEstate.origin.value',
      'assetRealEstate.occupied.value',
      'assetRealEstate.type.value'
    ]);
  }

  if (asset.type === AssetType.RURAL_PROPERTY) {
    return hasMissingInfo(asset, [
      'assetRuralProperty.origin.value',
      'assetRuralProperty.occupied.value',
      'assetRuralProperty.type.value'
    ]);
  }

  return false;
};
