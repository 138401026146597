import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { LegalReportProvider } from 'components';

import Show from './Show';

type MatchParams = {
  caseId: string;
  reportId: string;
};

const ShowWrapper = ({ match }: RouteComponentProps<MatchParams>) => {
  const caseId = match.params.caseId;
  const reportId = match.params.reportId;

  return (
    <LegalReportProvider>
      <Show caseId={caseId} reportId={reportId} />
    </LegalReportProvider>
  );
};

export default ShowWrapper;
