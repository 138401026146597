import { Case } from './case';
import { FileUpload } from './file';

export type Duplicate = {
  comment: string | null;
  dueDate: string;
  externalNumber: string;
  fineAmount: number;
  finePercentage: number;
  hasReceipt: boolean;
  internalNumber: string;
  fileUploads: FileUpload[];
  invoiceId: string;
  issueDate: string;
  moraAmount: number;
  moraPercentage: number;
  overdueAmount: number;
  protestStatus: DuplicateProtestStatus;
  status: DuplicateStatus;
  taxPercentage: string;
  valueCents: number;
  caseId: Case['id'];
  id: string;
  deliveryNumber: string;
};

export type DuplicateCreateParams = Omit<Duplicate, 'id' | 'fileUploads'> & {
  fileUploadIds: string[];
};

export type DuplicateFormAttributes = {
  comment: string;
  dueDate: string;
  externalNumber: string;
  fineAmount: string;
  finePercentage: string;
  hasReceipt: boolean;
  internalNumber: string;
  issueDate: string;
  moraAmount: string;
  moraPercentage: string;
  overdueAmount: string;
  protestStatus: string;
  status: string;
  taxPercentage: string;
  valueCents: string;
  fileUploadIds: string[];
  deliveryNumber: string;
};

export enum DuplicateStatus {
  PAID_ON_TIME = 'em_dia',
  OVERDUE = 'atraso',
  DEFAULTING = 'inadimplente',
  EXPIRING = 'a_vencer'
}

export enum DuplicateProtestStatus {
  SENT_TO_NOTARY = 'enviado_cartorio',
  PROTESTING = 'em_protesto',
  PROTESTED = 'protestado',
  PAID_OFF = 'liquidado'
}
