import React, { useState, useEffect } from 'react';

import { can, Action, deleteUser, resendInviteUser } from 'api';
import { User, ActionName, ActionEntity } from 'types';

import {
  openErrorToast,
  openSuccessToast,
  ToolTip,
  ListItem,
  Button
} from 'ui';

import * as Actions from 'utils/actions';
import { ColoredBadge } from 'components';
import { BadgeColor } from 'components/ColoredBadge/ColoredBadge';
import styles from './UserList.module.scss';

type Props = {
  usersData?: Array<User>;
  onEditClick: (user: User) => void;
  isInvitationDisabled?: boolean;
  isEditDisabled?: boolean;
  isExternalLawyerList?: boolean;
};

const Users = ({
  usersData,
  onEditClick,
  isInvitationDisabled = false,
  isEditDisabled = false,
  isExternalLawyerList = false
}: Props) => {
  const [users, setUsers] = useState(usersData);

  useEffect(() => {
    setUsers(usersData);
  }, [usersData]);

  function removeUser(userId: string): void {
    const usersUpdated = users?.filter(user => user.id !== userId);
    setUsers(usersUpdated);
  }

  function handleResendInvitation(userId: string) {
    return async function() {
      if (!window.confirm('Você deseja reenviar o convite?')) {
        return;
      }

      try {
        await resendInviteUser(userId);
        openSuccessToast('Convite enviado com sucesso.');
      } catch (error) {
        openErrorToast('Erro ao enviar convite.');
      }
    };
  }

  function handleDeleteUser(userId: string) {
    return async function() {
      if (!window.confirm('Você deseja excluir o usuário?')) {
        return;
      }

      try {
        await deleteUser(userId);

        removeUser(userId);
        openSuccessToast('Usuário excluído com sucesso.');
      } catch (error) {
        openErrorToast('Erro ao excluir usuário.');
      }
    };
  }

  const handleEditClick = (user: User) => () => {
    if (!Actions.isAvailable(user.actions, ActionName.UPDATE)) return;

    onEditClick(user);
  };

  const isEditVisible = isExternalLawyerList
    ? can(Action.PERMISSOES_ESCRITORIOS_DE_ADVOCACIA_EDITAR)
    : can(Action.PERMISSOES_USUARIOS_INTERNOS_DO_BANCO_EDITAR);

  const isResendInvitationVisible = isExternalLawyerList
    ? can(Action.PERMISSOES_ESCRITORIOS_DE_ADVOCACIA_INCLUIR)
    : can(Action.PERMISSOES_USUARIOS_INTERNOS_DO_BANCO_INCLUIR);

  const isDeleteVisible = isExternalLawyerList
    ? can(Action.PERMISSOES_ESCRITORIOS_DE_ADVOCACIA_EXCLUIR)
    : can(Action.PERMISSOES_USUARIOS_INTERNOS_DO_BANCO_EXCLUIR);

  return (
    <ul className={styles.usersListDefault}>
      {users?.map((user: User) => {
        const canEdit = isEditDisabled
          ? false
          : Actions.isAvailable(user.actions, ActionName.UPDATE);

        const canDelete = Actions.isAvailable(user.actions, ActionName.DELETE);

        return (
          <ListItem
            key={`users-${user.id}`}
            title={user.name}
            text={user.email}
            actions={
              <>
                {isEditVisible && (
                  <ToolTip
                    placement="top"
                    content={
                      canEdit
                        ? 'Editar usuário'
                        : Actions.unavailableReasons(
                            user.actions,
                            ActionName.UPDATE,
                            ActionEntity.USER
                          ) || 'Não é possível editar usuário'
                    }
                  >
                    <div className="action" data-testid="action-edit">
                      <Button
                        dataTestId="edit-user"
                        onClick={handleEditClick(user)}
                        icon="edit"
                        borderless
                        className={styles.actionsButton}
                        disabled={!canEdit}
                      />
                    </div>
                  </ToolTip>
                )}

                {isResendInvitationVisible && (
                  <ToolTip
                    placement="top"
                    content={
                      isInvitationDisabled
                        ? 'Não é possível reenviar convite'
                        : 'Reenviar convite'
                    }
                  >
                    <div className="action" data-testid="action-resend">
                      <Button
                        dataTestId="resend-icon"
                        onClick={handleResendInvitation(user.id)}
                        icon="refresh"
                        borderless
                        className={styles.actionsButton}
                        disabled={isInvitationDisabled}
                      />
                    </div>
                  </ToolTip>
                )}

                {isDeleteVisible && (
                  <ToolTip
                    placement="top"
                    content={
                      canDelete
                        ? 'Excluir usuário'
                        : Actions.unavailableReasons(
                            user.actions,
                            ActionName.DELETE,
                            ActionEntity.USER
                          )
                    }
                  >
                    <div className="action" data-testid="action-remove">
                      <Button
                        dataTestId="remove-icon"
                        onClick={handleDeleteUser(user.id)}
                        icon="close"
                        borderless
                        className={styles.actionsButton}
                        disabled={!canDelete}
                      />
                    </div>
                  </ToolTip>
                )}
              </>
            }
          >
            <ColoredBadge
              type={
                user.profile.name === 'YIELD_ADMIN'
                  ? BadgeColor.AQUAMARINE
                  : BadgeColor.DEFAULT
              }
              title={user.profile.name}
            />
          </ListItem>
        );
      })}
    </ul>
  );
};

export default Users;
