import { formatInitialValues, assetHasMissingFields } from 'domain/assets';
import React from 'react';
import { FieldArray, Formik } from 'formik';
import { Button, Stack, StackMargin, Toast, ToastType } from 'ui';
import * as Yup from 'yup';

import { Asset, AssetType, AssetMissingDataAttribute } from 'types';

import AssetRealEstateForm from './AssetRealEstateForm';
import AssetRuralPropertyForm from './AssetRuralPropertyForm';

import style from './AssetsMissingFields.module.scss';

const validationSchema = Yup.object().shape({
  assets: Yup.array().of(
    Yup.object().shape({
      assetRealEstate: Yup.object()
        .when(['type', 'isGroup'], {
          is: (type, isGroup) => type === AssetType.REAL_ESTATE && isGroup,
          then: Yup.object().shape({
            type: Yup.string().nullable(),
            occupied: Yup.string().nullable(),
            origin: Yup.string()
              .required('Campo obrigatório')
              .nullable()
          })
        })
        .when(['type', 'isGroup', 'assetGroupId'], {
          is: (type, isGroup, assetGroupId) =>
            type === AssetType.REAL_ESTATE && !isGroup && assetGroupId !== null,
          then: Yup.object().shape({
            type: Yup.string()
              .required('Campo obrigatório')
              .nullable(),
            occupied: Yup.string()
              .required('Campo obrigatório')
              .nullable(),
            origin: Yup.string().nullable()
          })
        })
        .when(['type', 'isGroup', 'assetGroupId'], {
          is: (type, isGroup, assetGroupId) =>
            type === AssetType.REAL_ESTATE && !isGroup && assetGroupId === null,
          then: Yup.object().shape({
            type: Yup.string()
              .required('Campo obrigatório')
              .nullable(),
            occupied: Yup.string()
              .required('Campo obrigatório')
              .nullable(),
            origin: Yup.string()
              .required('Campo obrigatório')
              .nullable()
          })
        })
        .nullable(),
      assetRuralProperty: Yup.object()
        .when(['type', 'isGroup'], {
          is: (type, isGroup) => type === AssetType.RURAL_PROPERTY && isGroup,
          then: Yup.object().shape({
            type: Yup.string().nullable(),
            occupied: Yup.string().nullable(),
            origin: Yup.string()
              .required('Campo obrigatório')
              .nullable()
          })
        })
        .when(['type', 'isGroup', 'assetGroupId'], {
          is: (type, isGroup, assetGroupId) =>
            type === AssetType.RURAL_PROPERTY &&
            !isGroup &&
            assetGroupId !== null,
          then: Yup.object().shape({
            type: Yup.string()
              .required('Campo obrigatório')
              .nullable(),
            occupied: Yup.string()
              .required('Campo obrigatório')
              .nullable(),
            origin: Yup.string().nullable()
          })
        })
        .when(['type', 'isGroup', 'assetGroupId'], {
          is: (type, isGroup, assetGroupId) =>
            type === AssetType.RURAL_PROPERTY &&
            !isGroup &&
            assetGroupId === null,
          then: Yup.object().shape({
            type: Yup.string()
              .required('Campo obrigatório')
              .nullable(),
            occupied: Yup.string()
              .required('Campo obrigatório')
              .nullable(),
            origin: Yup.string()
              .required('Campo obrigatório')
              .nullable()
          })
        })
        .nullable()
    })
  )
});

export const formatMissingInitialValues = (
  assetData?: Asset
): AssetMissingDataAttribute => {
  const formatedAssetData = formatInitialValues(assetData);

  const assetRealEstate =
    assetData?.type === AssetType.REAL_ESTATE
      ? {
          id: formatedAssetData.assetRealEstate?.id ?? null,
          type: formatedAssetData.assetRealEstate?.type ?? null,
          occupied: formatedAssetData.assetRealEstate?.occupied ?? null,
          origin: formatedAssetData.assetRealEstate?.origin ?? null
        }
      : null;

  const assetRuralProperty =
    assetData?.type === AssetType.RURAL_PROPERTY
      ? {
          id: formatedAssetData.assetRuralProperty?.id ?? null,
          type: formatedAssetData.assetRuralProperty?.type ?? null,
          occupied: formatedAssetData.assetRuralProperty?.occupied ?? null,
          origin: formatedAssetData.assetRuralProperty?.origin ?? null
        }
      : null;

  return {
    ...formatedAssetData,
    assetRealEstate,
    assetRuralProperty
  };
};

type Props = {
  assets: Asset[];
  onAddAssets: (assets: AssetMissingDataAttribute[]) => void;
  onBack: () => void;
};

const AssetsMissingFields = ({ assets, onAddAssets, onBack }: Props) => {
  const assetsToFix = assets.flatMap(asset => {
    if (asset.isGroup) {
      return [asset, ...asset.groupedAssets];
    }

    return asset;
  });

  return (
    <>
      <Toast
        type={ToastType.DEFAULT}
        message={
          <>
            Alguns ativos existentes não possuem prenchidos os campos{' '}
            <b className={style.boldText}>Subtipo</b>,{' '}
            <b className={style.boldText}>Situação</b> e{' '}
            <b className={style.boldText}>Origem</b>. Para adicioná-los em
            recuperação, preencha os campos.
          </>
        }
      />

      <Stack marginTop={StackMargin.LARGE}>
        <Formik
          initialValues={{
            assets: assetsToFix.map(asset => formatMissingInitialValues(asset))
          }}
          validationSchema={validationSchema}
          onSubmit={values => onAddAssets(values.assets)}
          enableReinitialize
        >
          {formik => (
            <div role="listbox">
              <FieldArray
                name="assets"
                render={() =>
                  formik.values.assets.map(
                    (asset: AssetMissingDataAttribute, index: number) => {
                      const assetToFix = assetsToFix[index];
                      const assetGroup = assetsToFix.find(
                        asset => asset.id === assetToFix.assetGroupId
                      );

                      return (
                        <div key={index}>
                          {asset.type === AssetType.REAL_ESTATE &&
                            assetToFix &&
                            assetHasMissingFields(assetToFix) && (
                              <AssetRealEstateForm
                                index={index}
                                asset={assetToFix}
                                assetGroup={assetGroup}
                                formAttributes={asset}
                                errors={formik.errors}
                                touched={formik.touched}
                                handleChange={formik.handleChange}
                                handleBlur={formik.handleBlur}
                                setFieldValue={formik.setFieldValue}
                              />
                            )}
                          {asset.type === AssetType.RURAL_PROPERTY &&
                            assetToFix &&
                            assetHasMissingFields(assetToFix) && (
                              <AssetRuralPropertyForm
                                index={index}
                                asset={assetToFix}
                                assetGroup={assetGroup}
                                formAttributes={asset}
                                errors={formik.errors}
                                touched={formik.touched}
                                handleChange={formik.handleChange}
                                handleBlur={formik.handleBlur}
                                setFieldValue={formik.setFieldValue}
                              />
                            )}
                        </div>
                      );
                    }
                  )
                }
              />

              <Stack
                marginTop={StackMargin.XLARGE}
                className={style.buttonWrapper}
              >
                <Button onClick={onBack} highlight outline small>
                  Voltar
                </Button>

                <Button
                  onClick={formik.handleSubmit}
                  type="submit"
                  highlight
                  small
                  disabled={!formik.isValid}
                >
                  Adicionar
                </Button>
              </Stack>
            </div>
          )}
        </Formik>
      </Stack>
    </>
  );
};

export default AssetsMissingFields;
