import React, { ReactNode } from 'react';
import cn from 'classnames';
import { Loader } from 'ui';

import './Header.scss';

type Props = {
  actionsComponent?: ReactNode;
  children?: ReactNode;
  className?: string;
  containerClassName?: string;
  contentClassName?: string;
  isLoading?: boolean;
  sticky?: boolean;
};

const Header = ({
  actionsComponent,
  children,
  className = '',
  containerClassName = '',
  contentClassName = '',
  isLoading = false,
  sticky = false
}: Props) => {
  const classNames = cn('header-default', className, { '-sticky': sticky });
  const containerClassNames = cn('container', containerClassName);
  const contentClassNames = cn('content', contentClassName);

  return (
    <header className={classNames} data-testid="header">
      <div className={containerClassNames}>
        <div className={contentClassNames}>
          {isLoading ? (
            <span data-testid="header-loader">
              <Loader height={2} width={2} />
              <br />
              <Loader width={5} />
            </span>
          ) : (
            children
          )}
        </div>
        {!isLoading && <div className="actions">{actionsComponent}</div>}
      </div>
    </header>
  );
};

export default Header;
