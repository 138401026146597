import { assetValueCents } from 'domain/assets';
import React from 'react';
import { useLocation } from 'react-router';
import sum from 'lodash/sum';
import compact from 'lodash/compact';

import { Asset, AssetOptions, AssetType } from 'types';
import { AssetTable, AssetForm } from 'components';
import { applyMask } from 'utils';
import {
  Button,
  Card,
  Modal,
  openErrorToast,
  openSuccessToast,
  Stack,
  StackMargin
} from 'ui';
import { useToggles } from 'hooks';
import {
  Action,
  can as apiCan,
  removeAssetFromGroup,
  updateAssetGroup
} from 'api';

import { useAssetOwner } from '../AssetOwnerContext';
import RelateAssetModal from './RelateAssetModal/RelateAssetModal';

import style from './AssetsList.module.scss';

type Props = {
  caseId: string;
  assetGroupId: string;
  assetType: AssetType;
  negotiatorId?: string;
};

const AssetsList = ({
  caseId,
  assetGroupId,
  assetType,
  negotiatorId
}: Props) => {
  const { groupAssetsList, setGroupAssetsList } = useAssetOwner();
  const { pathname } = useLocation();

  const { isOpen, toggle, closeAll } = useToggles<
    'createAsset' | 'relateAsset'
  >({
    createAsset: false,
    relateAsset: false
  });

  const total = sum(
    groupAssetsList.map(asset => {
      return assetValueCents(asset) || 0;
    })
  );

  const handleDelete = (asset: Asset) => async () => {
    if (!window.confirm('Você tem certeza de que deseja remover esse ativo?'))
      return;

    try {
      await removeAssetFromGroup(caseId, asset.id);

      setGroupAssetsList(groupAssetsList.filter(a => a.id !== asset.id));

      openSuccessToast('Ativo removido com sucesso!');
    } catch (error) {
      openErrorToast('Houve um erro ao remover o ativo');
    }
  };

  const handleCreate = (asset: Asset) => {
    closeAll();
    setGroupAssetsList([...groupAssetsList, asset]);
  };

  const handleAdd = async (assets: Asset[]) => {
    try {
      const response = await updateAssetGroup(caseId, assetGroupId, {
        assetIds: assets.map(asset => asset.id)
      });

      closeAll();
      setGroupAssetsList(response.data.groupedAssets);

      openSuccessToast('Ativo adicionado com sucesso!');
    } catch (error) {
      openErrorToast('Houve um erro ao adicionar o(s) ativo(s)');
    }
  };

  const can = (action: Action) => apiCan(action, negotiatorId);

  const canUser = {
    addAsset: can(Action.CASOS_RELATORIO_GRUPO_ATIVOS_INCLUIR),
    removeFromGroup: can(Action.CASOS_RELATORIO_GRUPO_ATIVOS_REMOVER_DO_GRUPO),
    showOrigin: can(Action.CASOS_RELATORIO_GRUPO_ATIVOS_VER_ORIGEM_SUSPEITO),
    copyLine: can(Action.CASOS_RELATORIO_GRUPO_ATIVOS_COPIAR_LINHA),
    showReport: can(Action.CASOS_RELATORIO_GRUPO_ATIVOS_VER_RELATORIO)
  };

  const assetOptions: AssetOptions[] = compact([
    canUser.showReport && AssetOptions.SHOW_REPORT,
    canUser.showOrigin && AssetOptions.SHOW_ORIGIN,
    canUser.copyLine && AssetOptions.COPY_LINE,
    canUser.removeFromGroup && AssetOptions.DELETE_FROM_GROUP
  ]);

  return (
    <Card
      medium
      customHeader={
        <h3 className={style.title}>
          Ativos
          <span className={style.totalSum}>
            {`Total de ativos: ${applyMask('currency', total.toString())}`}
          </span>
        </h3>
      }
    >
      <AssetTable
        assets={groupAssetsList}
        caseId={caseId}
        isLoading={false}
        onDeleteAsset={handleDelete}
        emptyText="Não há ativos neste grupo."
        returnToLink={pathname}
        assetOptions={assetOptions}
      />

      {canUser.addAsset && (
        <Stack marginTop={StackMargin.SMALL} className={style.buttonWrapper}>
          <Button
            dataTestId="asset-modal-trigger"
            highlight
            outline
            small
            onClick={toggle('createAsset')}
          >
            Adicionar novo ativo
          </Button>
          <Button highlight outline small onClick={toggle('relateAsset')}>
            Adicionar ativo existente
          </Button>
        </Stack>
      )}

      <Modal
        isOpen={isOpen.createAsset}
        onClose={toggle('createAsset')}
        title="Adicionar novo ativo"
        large
      >
        <AssetForm
          onCreateAsset={handleCreate}
          caseId={caseId}
          isAssetTypeBlocked
          formDefaultData={{
            assetGroupId: assetGroupId,
            type: assetType
          }}
        />
      </Modal>

      {isOpen.relateAsset && (
        <Modal
          large
          isOpen={isOpen.relateAsset}
          onClose={toggle('relateAsset')}
          title="Adicionar ativo existente"
        >
          <RelateAssetModal
            caseId={caseId}
            onAdd={handleAdd}
            assetTypes={[assetType]}
          />
        </Modal>
      )}
    </Card>
  );
};

export default AssetsList;
