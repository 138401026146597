import React, { useEffect, useRef, useCallback, useState } from 'react';
import { Modal, Button } from 'ui';
import {
  createLegalReportQuotation,
  fetchLegalReportQuotation,
  createLegalReportOrder
} from 'api';
import { applyMask, handleNullValue } from 'utils';
import { QueryStatus, LegalReportOrder } from 'types';

import { useLegalReport } from 'components';

import style from './LegalReportModal.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  caseId: string;
  reportId: string;
  onCreateOrder: (data: LegalReportOrder) => void;
};

const LegalReportModal = ({
  isOpen,
  onClose,
  caseId,
  reportId,
  onCreateOrder
}: Props) => {
  const timeoutRef = useRef<number>();
  const [requestHasError, setRequestError] = useState<boolean>(false);

  const {
    legalReportQuotationId,
    setLegalReportQuotationId,
    legalReportQuotationData,
    setLegalReportQuotationData
  } = useLegalReport();

  const createLegalReport = useCallback(async () => {
    setRequestError(false);

    try {
      const response = await createLegalReportQuotation({ caseId, reportId });

      response && setLegalReportQuotationId(response.data.id);
      runFallbackTimer(response.data.id);
    } catch (e) {
      setRequestError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseId, reportId]);

  useEffect(() => {
    createLegalReport();

    return () => {
      setLegalReportQuotationId(undefined);
      setLegalReportQuotationData(undefined);
      window.clearTimeout(timeoutRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchLegalReport = async (id: string) => {
    setRequestError(false);

    try {
      const response = await fetchLegalReportQuotation({
        caseId,
        reportId,
        quotationId: id
      });

      setLegalReportQuotationData(response.data);
    } catch (e) {
      setRequestError(true);
    }
  };

  const handleRetry = () => {
    setLegalReportQuotationId(undefined);
    setLegalReportQuotationData(undefined);
    createLegalReport();
  };

  const runFallbackTimer = (id: string) => {
    const TIMEOUT_TIMER = 30000;
    const timeout = window.setTimeout(() => {
      fetchLegalReport(id);
    }, TIMEOUT_TIMER);

    timeoutRef.current = timeout;
  };

  const handleCreateOrder = async () => {
    if (!legalReportQuotationId) return;

    setRequestError(false);

    try {
      const response = await createLegalReportOrder({
        caseId,
        reportId,
        quotationId: legalReportQuotationId
      });

      onCreateOrder(response.data);
      onClose();
    } catch (e) {
      setRequestError(true);
    }
  };

  if (legalReportQuotationData?.status === QueryStatus.SUCCEEDED) {
    const content = legalReportQuotationData.content;

    return (
      <Modal
        title="Buscar processos"
        isOpen={isOpen}
        onClose={onClose}
        className={style.modal}
      >
        <p className={style.text}>
          A consulta trará{' '}
          <span className={style.bold}>
            {handleNullValue(content?.totalLawsuits)}
          </span>{' '}
          processos de{' '}
          <span className={style.bold}>
            {handleNullValue(content?.minYear)} a{' '}
            {handleNullValue(content?.maxYear)}
          </span>{' '}
          por{' '}
          <span className={style.bold}>
            {handleNullValue(content?.totalCost, 'string', value =>
              applyMask('currency', value.toString())
            )}
            .
          </span>
        </p>
        <p className={style.text}>
          Confirme o valor antes de realizar a busca.
        </p>

        <div className={style.actions}>
          <Button small highlight outline onClick={onClose}>
            Cancelar
          </Button>
          <Button small highlight onClick={handleCreateOrder}>
            Realizar busca
          </Button>
        </div>
      </Modal>
    );
  }

  if (
    legalReportQuotationData?.status === QueryStatus.FAILED ||
    requestHasError
  )
    return (
      <Modal
        title="Buscar processos"
        isOpen={isOpen}
        onClose={onClose}
        className={style.modal}
      >
        <p className={style.text}>
          Não foi possível consultar o custo da busca de processos.
        </p>
        <p className={style.text}>Tente novamente mais tarde.</p>

        <div className={style.actions}>
          <Button small highlight outline onClick={onClose}>
            Cancelar
          </Button>

          <Button small highlight onClick={handleRetry}>
            Tentar novamente
          </Button>
        </div>
      </Modal>
    );

  return (
    <Modal
      title="Buscar processos"
      isOpen={isOpen}
      onClose={onClose}
      className={style.modal}
    >
      <p className={style.text}>Carregando informações de orçamento...</p>

      <div className={style.actions}>
        <Button small highlight outline onClick={onClose}>
          Cancelar
        </Button>
      </div>
    </Modal>
  );
};

export default LegalReportModal;
