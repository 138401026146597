import React, { useEffect, useState } from 'react';
import { useHistory, RouteComponentProps } from 'react-router-dom';

import { deleteCase, fetchCase, updateCase } from 'api';
import { openErrorToast, openSuccessToast } from 'ui';

import { Case, CaseFormAttributes, OnSubmit } from 'types';

import { CaseContext } from 'components';

import Layout from 'Layouts/Layout';
import CaseTabs from '../CaseTabs';

const Edit = (props: RouteComponentProps<{ caseId: string }>) => {
  const history = useHistory();
  const [currentCase, setCurrentCase] = useState<Case | null>(null);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const caseId = props.match.params.caseId;

  useEffect(() => {
    async function request() {
      try {
        const response = await fetchCase(caseId);

        setCurrentCase({
          ...response.data,
          id: response.data.id,
          creditor: 'bv'
        });
      } catch (error) {
        openErrorToast('Não foi possível encontrar o caso.');
      }
    }

    request();
  }, [caseId]);

  const onSubmit: OnSubmit<CaseFormAttributes> = async (
    values,
    formikHelpers
  ) => {
    setIsSubmiting(true);
    try {
      const response = await updateCase(caseId, values);
      openSuccessToast('Caso atualizado com sucesso!');

      setCurrentCase({
        ...response.data,
        id: response.data.id,
        creditor: 'bv'
      });
    } catch (error) {
      openErrorToast('Não foi possível atualizar o caso');
      formikHelpers.setErrors(error.data);
    } finally {
      setIsSubmiting(false);
    }
  };

  const onDelete = async (caseId: string) => {
    if (window.confirm('Você tem certeza de que deseja remover esse caso?')) {
      try {
        await deleteCase(caseId);
        openSuccessToast('Caso removido com sucesso!');

        history.push(`/cases/${currentCase?.type}`);
      } catch (error) {
        openErrorToast('Não foi possível remover o caso.');
      }
    }
  };

  return (
    <Layout>
      {currentCase ? (
        <CaseContext.Provider value={{ caseData: currentCase }}>
          <CaseTabs
            caseType={currentCase?.type}
            title={`Caso ${currentCase.name}`}
            caseId={caseId}
            onSubmit={onSubmit}
            onDelete={onDelete}
            isSubmiting={isSubmiting}
          />
        </CaseContext.Provider>
      ) : (
        <p>Carregando</p>
      )}
    </Layout>
  );
};

export default Edit;
