import { useState } from 'react';

import useDeepCompareEffect from 'use-deep-compare-effect';

import { ItemsPerPageOptions } from 'types';

function paginateDataToShow<T>(
  data: T[],
  currentPage: number,
  itemsPerPage: number
) {
  const sliceStart = (currentPage - 1) * itemsPerPage;
  const sliceEnd = sliceStart + itemsPerPage;
  return data.slice(sliceStart, sliceEnd);
}

type UsePaginationProps<T> = {
  dataToPaginate?: T[];
  initialItemsPerPage?: ItemsPerPageOptions;
};

export function usePagination<T>({
  dataToPaginate = [],
  initialItemsPerPage = 10
}: UsePaginationProps<T>) {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<ItemsPerPageOptions>(
    initialItemsPerPage
  );
  const [data, setData] = useState<T[]>(
    paginateDataToShow(dataToPaginate, currentPage, itemsPerPage)
  );

  useDeepCompareEffect(() => {
    setData(paginateDataToShow(dataToPaginate, currentPage, itemsPerPage));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataToPaginate]);

  const onCurrentPageChange = (newPage: number) => {
    setCurrentPage(newPage);
    setData(paginateDataToShow(dataToPaginate, newPage, itemsPerPage));
  };

  const onItemsPerPageChange = (newItemsPerPage: ItemsPerPageOptions) => {
    const newCurrentPage = 1;
    setCurrentPage(newCurrentPage);
    setItemsPerPage(newItemsPerPage);
    setData(
      paginateDataToShow(dataToPaginate, newCurrentPage, newItemsPerPage)
    );
  };

  const totalEntries = dataToPaginate.length;
  const totalPages = Math.ceil(dataToPaginate.length / itemsPerPage);
  const hidePagination = dataToPaginate.length <= itemsPerPage;

  return {
    data,
    paginationProps: {
      currentPage,
      hidePagination,
      itemsPerPage,
      onCurrentPageChange,
      onItemsPerPageChange,
      totalEntries,
      totalPages
    }
  };
}
