export function handleNullValue(
  value: string | number | null = null,
  type = 'string',
  formatter?: (value: string) => string
): string {
  if (type === 'number' && !value) {
    return '0';
  }

  if (!value) {
    return '-';
  }

  if (formatter) return formatter(value.toString());

  return value.toString();
}
