import React from 'react';
import styles from './TwoColumnsMasonry.module.scss';

type Props<T> = {
  data: T[];
  render: (data: T[]) => JSX.Element[];
};

const TwoColumnsMasonry = <T extends unknown>({
  data,
  render
}: Props<T>): JSX.Element => {
  const isEven = (number: number): boolean => !(number % 2);

  const firstColumn = data.filter((_, i) => isEven(i));
  const secondColumn = data.filter((_, i) => !isEven(i));

  return (
    <div className={styles.masonry}>
      <div>{render(firstColumn)}</div>
      <div>{render(secondColumn)}</div>
    </div>
  );
};

export default TwoColumnsMasonry;
