import { FileUpload, Debtor } from 'types';
import { Duplicate } from './duplicate';
import { DebtorAmountAggregate } from './debtor';

export enum InvoiceSituation {
  OPEN = 'open',
  OVERDUE = 'overdue',
  RESTRUCTURING = 'restructuring',
  EXECUTION = 'execution',
  PAID_OFF = 'paid_off'
}

export type Covenant = {
  name: string;
  id: string;
};

export type InvoiceFormAttributes = {
  number: string;
  valueCents: string;
  situation: string;
  comment: string | null;
  covenantId: string;
  debtorId: string;
  fileUploadIds: string[];
};

export type Invoice = {
  number: string;
  valueCents: number;
  situation: InvoiceSituation;
  comment: string | null;
  caseId: string;
  id: string;
  debtor: Debtor;
  duplicates: Duplicate[];
  covenant: Covenant;
  fileUploads: FileUpload[];
  updatedAt: string;
};

export type InvoiceByCovenant = {
  covenant: Covenant;
  invoices: InvoiceWithAggregateAmount[];
};

export type InvoiceWithDuplicateFiles = Invoice & {
  duplicateFiles: FileUpload[];
};

export type InvoiceWithAggregateAmount = DebtorAmountAggregate & {
  invoice: Invoice;
};
