import React, { ReactNode } from 'react';
import cn from 'classnames';

import styles from './Title.module.scss';

type Props = {
  children: ReactNode;
  className?: string;
  actions?: ReactNode;
};

const Title = ({ actions, children, className = '' }: Props) => {
  const classNames = cn(styles.titleDefault, className);

  return (
    <div className={classNames} data-testid="title">
      <div className={styles.headerItem}>
        <h1 className={styles.title}>{children}</h1>
      </div>

      <div className={styles.headerInfo}>
        <div className={styles.actions}>{actions && actions}</div>
      </div>
    </div>
  );
};

export default Title;
