import { AreaUnit, Option } from 'types';

export const AREA_UNIT_DICTIONARY: {
  [key in AreaUnit]: string;
} = {
  [AreaUnit.HECTARE]: 'ha',
  [AreaUnit.ACRE]: 'ac',
  [AreaUnit.M2]: 'm²'
};

export function areaUnitOptions(): Option[] {
  return Object.values(AreaUnit).map(unit => ({
    value: unit,
    label: AREA_UNIT_DICTIONARY[unit]
  }));
}
