import React, { ReactElement, useState } from 'react';
import cn from 'classnames';

import { FileItem, openErrorToast, openSuccessToast } from 'ui';
import { FileUpload } from 'types';
import { deleteFile } from 'api';

import './FileList.scss';

type Props = {
  caseId: string;
  title?: string;
  placeholder?: string;
  dataTestId?: string;
  files?: FileUpload[];
  showDelete?: boolean;
  className?: string;
};

const FileList = ({
  title,
  placeholder = 'Não há arquivos',
  files = [],
  caseId,
  showDelete = true,
  className
}: Props): ReactElement => {
  const [deletedIds, setDeletedIds] = useState<string[]>([]);

  const deleteFileRequest = async (fileId: string) => {
    if (!window.confirm('Deseja remover esse arquivo?')) return;

    try {
      await deleteFile(caseId, fileId);

      setDeletedIds([...deletedIds, fileId]);

      openSuccessToast('Arquivo excluído com sucesso.');
    } catch (error) {
      openErrorToast('Erro ao excluir.');
    }
  };

  const onDelete = async (fileId: string) => {
    deleteFileRequest(fileId);
  };

  const filteredFiles = files.filter(file => !deletedIds.includes(file.id));

  const isFilesEmpty = filteredFiles.length === 0;

  return (
    <div className={cn('files-list', className)} data-testid="file-list">
      {title && (
        <span className="label" data-testid="file-list-title">
          {title}
        </span>
      )}
      <div className="files">
        {isFilesEmpty && <div className="empty-list">{placeholder}</div>}

        {filteredFiles.map((file: FileUpload) => {
          return (
            <FileItem
              showDelete={showDelete}
              showMode
              key={`file-${file.id}`}
              file={file}
              onDelete={onDelete}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FileList;
