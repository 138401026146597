import * as Yup from 'yup';

import { applyMask, handleNullValue } from 'utils';
import * as ValidationSchema from 'utils/validationSchema';

import {
  EntityType,
  InternalLawsuit,
  InternalLawsuitAttributes,
  InvolvedParty,
  InvolvedPartyType,
  Lawyer,
  LawyerCauseType,
  Option,
  StatusType
} from 'types';

export function lawsuitNumber(number?: string | null) {
  return `Processo ${number ? `nº ${number}` : 'sem número'}`;
}

const CAUSE_DICTIONARY = {
  [LawyerCauseType.AGAINST]: 'Acusação',
  [LawyerCauseType.IN_FAVOR]: 'Defesa',
  [LawyerCauseType.NEUTRAL]: 'Neutro'
};

export function causeLabel(cause: LawyerCauseType): string {
  return CAUSE_DICTIONARY[cause] || cause;
}

export function causeOptions(): Option[] {
  return Object.values(LawyerCauseType).map(causeType => ({
    value: causeType,
    label: causeLabel(causeType)
  }));
}

export function involvedPartyDocument(entity: InvolvedParty) {
  if (entity.entityType === EntityType.COMPANY) {
    return handleNullValue(entity?.company?.cnpj, 'string', value =>
      applyMask('cnpj', value)
    );
  }

  return handleNullValue(entity?.individual?.cpf, 'string', value =>
    applyMask('cpf', value)
  );
}

export function hasInvolvedPartyDocument(entity: InvolvedParty) {
  return entity.company?.cnpj || entity.individual?.cpf;
}

export function involvedPartyName(entity: InvolvedParty) {
  return entity.company?.businessName && entity.company?.businessName !== ''
    ? entity.company?.businessName
    : entity.individual?.name ?? '';
}

const PARTY_ENTITY_TYPE_DICTIONARY = {
  [EntityType.COMPANY]: 'Pessoa Jurídica',
  [EntityType.INDIVIDUAL]: 'Pessoa Física'
};

export function involvedPartyEntityTypeLabel(
  partyEntityType: EntityType
): string {
  return PARTY_ENTITY_TYPE_DICTIONARY[partyEntityType] || partyEntityType;
}

const PARTY_TYPE_DICTIONARY = {
  [InvolvedPartyType.AUTHOR]: 'Autor',
  [InvolvedPartyType.DEFENDANT]: 'Réu',
  [InvolvedPartyType.NEUTRAL]: 'Neutro'
};

export function involvedPartyTypeLabel(partyType: InvolvedPartyType): string {
  return PARTY_TYPE_DICTIONARY[partyType] || partyType;
}

export function involvedPartyTypeOptions(): Option[] {
  return Object.values(InvolvedPartyType).map(type => ({
    value: type,
    label: involvedPartyTypeLabel(type)
  }));
}

export const STATUS_TYPE_DICTIONARY = {
  [StatusType.FILED]: 'Ajuizado',
  [StatusType.IN_DISTRIBUTION]: 'Em distribuição',
  [StatusType.CLOSED]: 'Encerrado'
};

export function statusTypeOptions(): Option[] {
  return Object.values(StatusType).map(type => ({
    value: type,
    label: STATUS_TYPE_DICTIONARY[type]
  }));
}

export const initialValues: InternalLawsuitAttributes = {
  action: '',
  cause: undefined,
  causeInitialDate: '',
  contractIds: [],
  courtId: '',
  description: '',
  externalNumber: '',
  lawyers: [],
  number: '',
  parties: [],
  reclaimingIds: [],
  status: '',
  type: '',
  fileUploadIds: [],
  executionComments: ''
};

export function internalLawsuitInitialValues(
  data?: InternalLawsuit
): InternalLawsuitAttributes {
  if (!data) return initialValues;

  return {
    ...data,
    causeInitialValue: data.causeInitialValue,
    fileUploadIds: data.fileUploads.map(({ id }) => id),
    courtId: data?.court?.id,
    contractIds: data.contracts.map(contract => contract.id),
    reclaimingIds: data.reclaimings.map(reclaming => reclaming.id)
  };
}

export const involvedPartyValidationSchema = Yup.object().shape({
  entityType: Yup.string().required('Campo obrigatório'),
  type: Yup.string().required('Campo obrigatório'),
  individual: Yup.object().when('entityType', {
    is: EntityType.INDIVIDUAL,
    then: Yup.object().shape({
      cpf: ValidationSchema.cpf().notRequired(),
      name: Yup.string()
        .min(3, 'Precisa ter ao menos 3 caracteres')
        .max(255, 'Precisa ter menos de 256 caracteres')
        .required('Campo obrigatório')
    }),
    otherwise: Yup.object()
      .shape({
        cpf: Yup.string().notRequired(),
        name: Yup.string().notRequired()
      })
      .nullable()
  }),
  company: Yup.object().when('entityType', {
    is: EntityType.COMPANY,
    then: Yup.object().shape({
      cnpj: ValidationSchema.cnpj().notRequired(),
      businessName: Yup.string()
        .min(3, 'Precisa ter ao menos 3 caracteres')
        .max(255, 'Precisa ter menos de 256 caracteres')
        .required('Campo obrigatório')
    }),
    otherwise: Yup.object()
      .shape({
        cnpj: Yup.string().notRequired(),
        businessName: Yup.string().notRequired()
      })
      .nullable()
  })
});

export const lawyerValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Precisa ter ao menos 3 caracteres')
    .max(255, 'Precisa ter menos de 256 caracteres')
    .required('Campo obrigatório'),
  cause: Yup.string().required('Campo obrigatório')
});

export const lawyersValidationSchema = Yup.array()
  .of(lawyerValidationSchema)
  .test(
    'Unique',
    'Remova os advogados duplicados antes de salvar o processo',
    (lawyers: Lawyer[]) =>
      new Set(lawyers.map(lawyer => lawyer.name)).size === lawyers.length
  )
  .notRequired();

export const internalLawsuitValidationSchema = Yup.object().shape({
  status: Yup.string().required('Campo obrigatório'),
  number: Yup.string().when('status', {
    is: StatusType.IN_DISTRIBUTION,
    then: Yup.string()
      .notRequired()
      .nullable(),
    otherwise: Yup.string()
      .required('Campo obrigatório')
      .nullable()
  }),
  externalNumber: Yup.string()
    .notRequired()
    .nullable(),
  causeInitialDate: Yup.date()
    .max(new Date(), 'A data deve ser igual ou anterior a hoje')
    .notRequired()
    .nullable(),
  courtId: Yup.string().notRequired(),
  action: Yup.string()
    .notRequired()
    .nullable(),
  type: Yup.string()
    .notRequired()
    .nullable(),
  parties: Yup.array()
    .min(1, 'Precisa ter ao menos uma parte envolvida')
    .of(involvedPartyValidationSchema),
  lawyers: lawyersValidationSchema,
  contractIds: Yup.array()
    .required('Campo obrigatório')
    .min(1, 'Minimo um contrato deve ser selecionado'),
  description: Yup.string()
    .nullable()
    .notRequired()
    .test(
      'description',
      'Não pode ser maior do que 140 caracteres',
      val => (val?.length ?? 0) <= 140
    )
});
