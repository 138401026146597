import React, { useState } from 'react';

import { exportCases } from 'api';

import { Button, Modal, openErrorToast } from 'ui';

import styles from './ExportButton.module.scss';

type Props = {
  isDisabled?: boolean;
  caseIds?: string[];
};

const ExportButton = ({ isDisabled, caseIds }: Props) => {
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const handleExport = async (caseIds?: string[]) => {
    setIsExporting(true);

    try {
      const { data: xlsxBase64 } = await exportCases(caseIds);

      const xlsxData = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${xlsxBase64}`;

      const exportAnchor = document.createElement('a');
      exportAnchor.href = xlsxData;
      exportAnchor.download = 'export.xlsx';
      exportAnchor.click();
      exportAnchor.remove();
    } catch (e) {
      openErrorToast('Ocorreu um erro ao exportar o arquivo');
    } finally {
      setIsExporting(false);
    }
  };
  return (
    <>
      <Button
        highlight
        outline
        small
        onClick={() => setIsExportModalOpen(true)}
        disabled={isDisabled}
      >
        Exportação
      </Button>

      <Modal
        title="Exportação"
        isOpen={isExportModalOpen}
        onClose={() => setIsExportModalOpen(false)}
      >
        <div className={styles.exportModalActions}>
          <Button
            highlight
            outline
            small
            centered
            onClick={() => handleExport()}
            loading={isExporting}
          >
            Exportar tudo
          </Button>

          <Button
            highlight
            outline
            small
            centered
            onClick={() => handleExport(caseIds)}
            loading={isExporting}
          >
            Exportar selecionados
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ExportButton;
