import React, { useState } from 'react';
import { openErrorToast } from 'ui';
import { FileUpload } from 'types';
import { deleteFile } from 'api';

import Gallery from './Gallery';
import Thumbnails from './Thumbnails';

type Props = {
  images: FileUpload[];
  caseId: string;
  showDelete?: boolean;
};

const ImageGallery = ({
  images: propImages,
  caseId,
  showDelete = true
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState<number>();
  const [images, setImages] = useState(propImages);

  const handleDeleteImage = async (imageId: string) => {
    if (!window.confirm('Deseja remover essa imagem?')) return;

    try {
      await deleteFile(caseId, imageId);

      const removedState = images.filter(image => image.id !== imageId);

      setImages(removedState);
    } catch (error) {
      openErrorToast('Erro ao excluir imagem.');
    }
  };
  const handleOpenGallery = (imageIndex?: number) => {
    setImageIndex(imageIndex);
    setIsOpen(true);
  };

  return (
    <>
      <Thumbnails
        images={images}
        onDelete={handleDeleteImage}
        onOpenGallery={handleOpenGallery}
        showDelete={showDelete}
      />

      {isOpen && (
        <Gallery
          images={images}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onDelete={handleDeleteImage}
          currentActiveIndex={imageIndex}
          showDelete={showDelete}
        />
      )}
    </>
  );
};

export default ImageGallery;
