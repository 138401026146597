import get from 'lodash/get';
import { ApiSuccessfulResponse, Feature } from 'types';
import Client from './client';

const STORAGE_KEY = '_yield_feature_flags';

type Features = {
  [key in Feature]: boolean;
};

export async function fetchFeatureFlags(): Promise<
  ApiSuccessfulResponse<Features>
> {
  const response = await Client.get<Features>('features');

  saveFeatureFlags(response.data);

  return response;
}

export async function featureIsEnabled(featureName: Feature): Promise<boolean> {
  const flags = await readFeatureFlags();

  return get(flags, featureName, false);
}

function saveFeatureFlags(features: Features) {
  window.sessionStorage.setItem(STORAGE_KEY, JSON.stringify(features));
}

async function readFeatureFlags(): Promise<Features> {
  const flags = window.sessionStorage.getItem(STORAGE_KEY);

  if (flags) {
    return JSON.parse(flags);
  }

  const response = await fetchFeatureFlags();
  return response.data;
}
