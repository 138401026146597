import {
  AggregatedMentionedInstagramProfile,
  InstagramProfile,
  InstagramPublication
} from 'types';

import Client from './client';

export type FetchInstagramProfileAggregatedProfilesParams = {
  caseId: string;
  instagramProfileId: string;
  pagination?: {
    page: number;
    pageSize: number;
  };
};

function fetchInstagramProfileAggregatedProfiles({
  caseId,
  instagramProfileId,
  pagination
}: FetchInstagramProfileAggregatedProfilesParams) {
  return Client.get<AggregatedMentionedInstagramProfile[]>(
    `cases/${caseId}/instagram_profiles/${instagramProfileId}/mentions/aggregated_profiles`,
    {
      params: {
        page: pagination?.page.toString(),
        pageSize: pagination?.pageSize.toString()
      }
    }
  );
}

export type FetchAggregatedProfilePublicationsParams = {
  caseId: string;
  instagramProfileId: string;
  aggregatedProfileId: string;
  reportId: string;
  pagination?: {
    page: number;
    pageSize: number;
  };
};

function fetchAggregatedProfilePublications({
  caseId,
  instagramProfileId,
  aggregatedProfileId,
  reportId,
  pagination
}: FetchAggregatedProfilePublicationsParams) {
  return Client.get<InstagramPublication[]>(
    `cases/${caseId}/reports/${reportId}/instagram_profiles/${instagramProfileId}/mentions/${aggregatedProfileId}/publications`,
    {
      params: {
        page: pagination?.page.toString(),
        pageSize: pagination?.pageSize.toString()
      }
    }
  );
}

function updateInstagramProfile({
  caseId,
  instagramProfileId
}: {
  caseId: string;
  instagramProfileId: string;
}) {
  return Client.put<void, InstagramProfile>(
    `cases/${caseId}/instagram_profiles/${instagramProfileId}`
  );
}

function requestInstagramProfileMoreInfo({
  caseId,
  instagramProfileId
}: {
  caseId: string;
  instagramProfileId: string;
}) {
  return Client.post<void, InstagramProfile>(
    `cases/${caseId}/instagram_profiles/${instagramProfileId}/more_info`
  );
}

function fetchInstagramProfile({
  caseId,
  reportId,
  instagramProfileId
}: {
  caseId: string;
  reportId: string;
  instagramProfileId: string;
}) {
  return Client.get<InstagramProfile>(
    `cases/${caseId}/reports/${reportId}/instagram_profiles/${instagramProfileId}`
  );
}

function retryInstagramProfile({
  caseId,
  instagramProfileId
}: {
  caseId: string;
  instagramProfileId: string;
}) {
  return Client.post<void, InstagramProfile>(
    `cases/${caseId}/instagram_profiles/${instagramProfileId}/retry`
  );
}

export type FetchInstagramProfilePublicationsParams = {
  caseId: string;
  reportId: string;
  instagramProfileId: string;
  pagination?: {
    page: number;
    pageSize: number;
  };
};

function fetchInstagramProfilePublications({
  caseId,
  reportId,
  instagramProfileId,
  pagination
}: FetchInstagramProfilePublicationsParams) {
  return Client.get<InstagramPublication[]>(
    `cases/${caseId}/reports/${reportId}/instagram_profiles/${instagramProfileId}/publications`,
    {
      params: {
        page: pagination?.page.toString(),
        pageSize: pagination?.pageSize.toString()
      }
    }
  );
}

export type FetchInstagramProfileBookmarkedPublicationsParams = {
  caseId: string;
  reportId: string;
  instagramProfileId: string;
  pagination?: {
    page: number;
    pageSize: number;
  };
};

function fetchInstagramProfileBookmarkedPublications({
  caseId,
  reportId,
  instagramProfileId,
  pagination
}: FetchInstagramProfileBookmarkedPublicationsParams) {
  return Client.get<InstagramPublication[]>(
    `cases/${caseId}/reports/${reportId}/instagram_profiles/${instagramProfileId}/bookmarks`,
    {
      params: {
        page: pagination?.page.toString(),
        pageSize: pagination?.pageSize.toString()
      }
    }
  );
}

function fetchInstagramAllBookmarkedPublications({
  caseId,
  reportId,
  instagramProfileId
}: FetchInstagramProfileBookmarkedPublicationsParams) {
  return Client.get<InstagramPublication[]>(
    `cases/${caseId}/reports/${reportId}/instagram_profiles/${instagramProfileId}/bookmarks/summary`
  );
}

function createInstagramProfileSearch({
  caseId,
  reportId
}: {
  caseId: string;
  reportId: string;
}) {
  return Client.post<void, void>(
    `cases/${caseId}/reports/${reportId}/create_instagram_query`
  );
}

export {
  createInstagramProfileSearch,
  fetchAggregatedProfilePublications,
  fetchInstagramAllBookmarkedPublications,
  fetchInstagramProfile,
  fetchInstagramProfileAggregatedProfiles,
  fetchInstagramProfileBookmarkedPublications,
  fetchInstagramProfilePublications,
  requestInstagramProfileMoreInfo,
  retryInstagramProfile,
  updateInstagramProfile
};
