import { handleDateFieldChange } from 'domain/forms';
import * as Cases from 'domain/cases';
import React, {
  ReactElement,
  useState,
  useEffect,
  RefObject,
  useContext
} from 'react';
import { DateTime } from 'luxon';
import * as Yup from 'yup';
import { getIn, FieldArray, Formik, FormikProps } from 'formik';

import { Action, fetchUsers, fetchAllLawFirms, can } from 'api';

import {
  FormContainer,
  InputField,
  SelectField,
  openErrorToast,
  DatePickerField
} from 'ui';

import {
  CaseFormAttributes,
  Case,
  LawFirm,
  User,
  OnSubmit,
  ProfileRolesType,
  CaseType
} from 'types';

import { CaseContext } from 'components';
import AdverseParties from './AdverseParties';

function idFor(name: string): string {
  return `case-form-${name}`;
}

const isValidDate = (value: string | null): boolean => {
  if (!value) return true;

  return DateTime.fromISO(value).isValid;
};

const isPastDate = (value: string | null): boolean => {
  if (!value) return true;

  return DateTime.fromISO(value) <= DateTime.local();
};

type SelectOption = { value: string; label: string };

type Props = {
  id: string;
  formRef: RefObject<FormikProps<CaseFormAttributes>>;
  onSubmit: OnSubmit<CaseFormAttributes>;
  caseType: CaseType;
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Precisa ter ao menos 2 caracteres')
    .max(255, 'Precisa ter menos de 256 caracteres')
    .required('Campo obrigatório'),
  startedAt: Yup.string()
    .test('is-valid-date', 'Deve ser uma data válida', isValidDate)
    .test('is-past-date', 'Deve ser a data de hoje ou no passado', isPastDate)
    .nullable()
    .notRequired(),
  status: Yup.string().required('Campo obrigatório'),
  adverseParties: Yup.array().of(
    Yup.object().shape({
      name: Yup.string()
        .min(2, 'Precisa ter ao menos 2 caracteres')
        .max(255, 'Precisa ter menos de 256 caracteres')
        .required('Campo obrigatório'),
      type: Yup.string().required('Campo obrigatório'),
      role: Yup.string().required('Campo obrigatório')
    })
  ),
  internalLawyerId: Yup.string(),
  negotiatorId: Yup.string().required('Campo obrigatório')
});

const toSelectOption = (value: LawFirm | User) => ({
  value: value.id,
  label: value.name
});

const creditors = [{ value: 'bv', label: 'Banco BV' }];

const Form = ({ id, formRef, onSubmit, caseType }: Props): ReactElement => {
  const { caseData: formData } = useContext(CaseContext);

  const [lawFirmsOption, setLawFirmOptions] = useState<SelectOption[]>([]);
  const [internalLawyerOptions, setInternalLawyerOptions] = useState<
    SelectOption[]
  >([]);
  const [negotiatorOptions, setNegotiatorOptions] = useState<SelectOption[]>(
    []
  );
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    async function fetchLawFirmOptions() {
      setLoading(true);

      try {
        const { data } = await fetchAllLawFirms({});
        setLawFirmOptions(data!.map(toSelectOption));
        setLoading(false);
      } catch (err) {
        openErrorToast('Falha ao listar os escritórios de advocacia');
      }
    }

    fetchLawFirmOptions();
  }, [formData]);

  useEffect(() => {
    async function fetchUserOptions() {
      setLoading(true);

      try {
        const { data } = await fetchUsers({
          roles: [ProfileRolesType.INTERNAL_LAWYER, ProfileRolesType.NEGOTIATOR]
        });

        setInternalLawyerOptions(
          data
            .filter(
              user => user.profile.role === ProfileRolesType.INTERNAL_LAWYER
            )
            .map(toSelectOption)
        );

        setNegotiatorOptions(
          data
            .filter(user => user.profile.role === ProfileRolesType.NEGOTIATOR)
            .map(toSelectOption)
        );
        setLoading(false);
      } catch (err) {
        openErrorToast('Falha ao listar usuários');
      }
    }

    fetchUserOptions();
  }, [formData]);

  if (isLoading) {
    return <p className="paragraph">Carregando...</p>;
  }

  const formatInitialValues = (formData?: Case): CaseFormAttributes => {
    const initialValues = Cases.casesInitialValues(caseType);

    if (!formData) return initialValues;

    const { internalLawyer, negotiator } = formData;

    return {
      ...formData,
      internalLawyerId: internalLawyer?.id ?? '',
      negotiatorId: negotiator.id
    };
  };

  const canEditBasicInformation = can(
    Action.CASOS_INFORMACOES_BASICAS_EDITAR,
    formData?.negotiator?.id
  );
  const canShowAdverseParties = can(
    Action.CASOS_INFORMACOES_BASICAS_PARTE_ADVERSA_CONSULTAR,
    formData?.negotiator?.id
  );
  const canAddBasicInformation = can(Action.CASOS_LISTAGEM_DE_CASOS_INCLUIR);

  const canEditOrCreate = formData
    ? canEditBasicInformation
    : canAddBasicInformation;

  return (
    <Formik<CaseFormAttributes>
      innerRef={formRef}
      initialValues={formatInitialValues(formData)}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {formik => (
        <form onSubmit={formik.handleSubmit}>
          <FormContainer className="col-2">
            <InputField
              id={idFor('name')}
              name="name"
              type="text"
              value={formik.values.name}
              title="Nome do caso"
              error={formik.touched.name && getIn(formik.errors, 'name')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Digite aqui…"
              disabled={!canEditOrCreate}
            />
            <DatePickerField
              id={idFor('startedAt')}
              name="startedAt"
              title="Data de início da gestão"
              placeholder="Selecione..."
              value={formik.values.startedAt}
              onChange={handleDateFieldChange(
                formik.setFieldValue,
                formik.setFieldTouched,
                'startedAt'
              )}
              onBlur={formik.handleBlur}
              max={DateTime.local().toISO()}
              error={
                formik.touched.startedAt && getIn(formik.errors, 'startedAt')
              }
              disabled={!canEditOrCreate}
            />
            <SelectField
              id={idFor('status')}
              name="status"
              value={formik.values.status}
              title="Estágio da recuperação"
              error={formik.touched.status && getIn(formik.errors, 'status')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              options={Cases.statusOptions()}
              disabled={!canEditOrCreate}
            />
            <SelectField
              id={idFor('creditor')}
              name="creditor"
              value={formik.values.creditor}
              title="Credor do caso"
              error={
                formik.touched.creditor && getIn(formik.errors, 'creditor')
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              options={creditors}
              disabled
            />
            <SelectField
              id={idFor('internal-lawyer-id')}
              name="internalLawyerId"
              value={formik.values.internalLawyerId! || ''}
              title="Advogado responsável"
              error={
                formik.touched.internalLawyerId &&
                getIn(formik.errors, 'internalLawyerId')
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              options={internalLawyerOptions}
              optional
              disabled={!canEditOrCreate}
            />
            <SelectField
              id={idFor('negotiator-id')}
              name="negotiatorId"
              value={formik.values.negotiatorId! || ''}
              title="Negociador responsável"
              error={
                formik.touched.negotiatorId &&
                getIn(formik.errors, 'negotiatorId')
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              options={negotiatorOptions}
              optional
              disabled={!canEditOrCreate}
            />
            <SelectField
              id={idFor('law-firm-id')}
              name="lawFirmId"
              value={formik.values.lawFirmId || ''}
              title="Escritório de advocacia"
              error={
                formik.touched.lawFirmId && getIn(formik.errors, 'lawFirmId')
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              options={lawFirmsOption}
              optional
              disabled={!canEditOrCreate}
            />
          </FormContainer>

          {canShowAdverseParties && (
            <FieldArray name="adverseParties">
              {formikArrayHelpers => (
                <AdverseParties
                  formikArrayHelpers={formikArrayHelpers}
                  formik={formik}
                />
              )}
            </FieldArray>
          )}
        </form>
      )}
    </Formik>
  );
};

export default Form;
