import React, { useState } from 'react';

import orderBy from 'lodash/orderBy';
import { Action, can as apiCan } from 'api';
import { FormikHelpers } from 'formik';
import { Table, InfoArea, Button, Modal, DropDown } from 'ui';
import { Appraisal, Asset } from 'types';
import { useToggles } from 'hooks';
import { handleNullValue, applyDateMask, applyCurrencyMask } from 'utils';

import style from './Appraisals.module.scss';

import Form from './Form/Form';

type Props = {
  appraisals: Appraisal[];
  setFieldValue: FormikHelpers<Appraisal>['setFieldValue'];
  providerAppraisalValue?: Asset['providerValueCents'];
  isGroup?: boolean;
  negotiatorId?: string;
};

const Appraisals = ({
  appraisals,
  setFieldValue,
  providerAppraisalValue,
  isGroup,
  negotiatorId
}: Props) => {
  const { isOpen, toggle, toggleTrigger, closeAll } = useToggles<'modal'>({
    modal: false
  });

  const [editingAppraisal, setEditingAppraisal] = useState<Appraisal>();

  const handleDeleteClick = (appraisalId: string) => () => {
    if (
      window.confirm('Você tem certeza de que deseja remover esta avaliação?')
    ) {
      const updatedAppraisals = appraisals.filter(
        appraisal => appraisal.id !== appraisalId
      );

      setFieldValue('appraisals', updatedAppraisals);
    }
  };

  const handleEditClick = (selectedAppraisal: Appraisal) => () => {
    setEditingAppraisal(selectedAppraisal);
    toggleTrigger('modal');
  };

  const handleCloseModal = () => {
    closeAll();
    setEditingAppraisal(undefined);
  };

  const apendAppraisal = (appraisalAddData: Appraisal) => {
    return [...appraisals, appraisalAddData];
  };

  const updateAppraisal = (appraisalAddData: Appraisal) => {
    return appraisals.map(appraisal => {
      if (appraisal.id === editingAppraisal?.id) {
        return appraisalAddData;
      }

      return appraisal;
    });
  };

  const handleFormSubmit = (appraisalAddData: Appraisal) => {
    const updatedAppraisals = editingAppraisal
      ? updateAppraisal(appraisalAddData)
      : apendAppraisal(appraisalAddData);

    handleCloseModal();

    setFieldValue('appraisals', updatedAppraisals);
  };

  const orderedByMaxDateAppraisals = orderBy(appraisals, 'date', 'desc');

  const can = (action: Action) => apiCan(action, negotiatorId);

  const canUser = isGroup
    ? {
        addAppraisal: can(Action.CASOS_RELATORIO_GRUPO_AVALIACAO_INCLUIR),
        editAppraisal: can(Action.CASOS_RELATORIO_GRUPO_AVALIACAO_EDITAR),
        deleteAppraisal: can(Action.CASOS_RELATORIO_GRUPO_AVALIACAO_EXCLUIR)
      }
    : {
        addAppraisal: can(Action.CASOS_RELATORIO_ATIVO_AVALIACAO_INCLUIR),
        editAppraisal: can(Action.CASOS_RELATORIO_ATIVO_AVALIACAO_EDITAR),
        deleteAppraisal: can(Action.CASOS_RELATORIO_ATIVO_AVALIACAO_EXCLUIR)
      };

  return (
    <>
      {providerAppraisalValue && (
        <InfoArea
          label="Valor do provedor"
          value={applyCurrencyMask(providerAppraisalValue?.toString())}
        />
      )}

      <label className={style.label}>Avaliações</label>
      <Table>
        <Table.Header>
          <Table.Row small>
            <Table.Cell textAlign="right">Data de avaliação</Table.Cell>
            <Table.Cell textAlign="right">Valor liquidação forçada</Table.Cell>
            <Table.Cell textAlign="right">Valor de mercado</Table.Cell>
            <Table.Cell textAlign="right">Empresa avaliadora</Table.Cell>
            <Table.Cell textAlign="right"></Table.Cell>
          </Table.Row>
        </Table.Header>
        <Table.Body
          isEmpty={appraisals.length === 0}
          emptyText="Não há avaliações neste ativo"
          columns={5}
        >
          {orderedByMaxDateAppraisals.map((appraisal, index) => (
            <Table.Row small key={appraisal.id}>
              <Table.Cell textAlign="right">
                {handleNullValue(appraisal.date, 'string', applyDateMask)}
              </Table.Cell>
              <Table.Cell textAlign="right">
                {handleNullValue(
                  appraisal.valueCents,
                  'string',
                  applyCurrencyMask
                )}
              </Table.Cell>
              <Table.Cell textAlign="right">
                {handleNullValue(
                  appraisal.marketValueCents,
                  'string',
                  applyCurrencyMask
                )}
              </Table.Cell>
              <Table.Cell textAlign="right">
                {handleNullValue(appraisal.companyName, 'string')}
              </Table.Cell>

              <Table.Cell textAlign="right">
                {canUser.editAppraisal || canUser.deleteAppraisal ? (
                  <DropDown
                    options={[
                      {
                        dataTestId: `edit-appraisal-${index}`,
                        text: 'Editar',
                        callback: handleEditClick(appraisal),
                        isHidden: !canUser.editAppraisal
                      },
                      {
                        dataTestId: `delete-appraisal-${index}`,
                        text: 'Excluir',
                        callback: handleDeleteClick(appraisal.id),
                        isNegative: true,
                        isHidden: !canUser.deleteAppraisal
                      }
                    ]}
                  />
                ) : (
                  <></>
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      {canUser.addAppraisal && (
        <Button outline highlight small centered onClick={toggle('modal')}>
          Adicionar avaliação
        </Button>
      )}

      {isOpen.modal && (
        <Modal
          title={editingAppraisal ? 'Editar avaliação' : 'Nova avaliação'}
          isOpen={isOpen.modal}
          onClose={handleCloseModal}
        >
          <Form onSubmit={handleFormSubmit} appraisaldata={editingAppraisal} />
        </Modal>
      )}
    </>
  );
};

export default Appraisals;
