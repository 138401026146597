import {
  involvedPartyDocument,
  involvedPartyName,
  involvedPartyEntityTypeLabel,
  involvedPartyTypeLabel,
  hasInvolvedPartyDocument
} from 'domain/lawsuits';

import React, { useState } from 'react';
import {
  FieldArrayRenderProps,
  FormikErrors,
  FormikProps,
  getIn
} from 'formik';

import {
  ChildLawsuitAttributes,
  InternalLawsuitAttributes,
  InvolvedParty,
  InvolvedPartyAttributes
} from 'types';

import {
  Button,
  Card,
  Checkbox,
  Modal,
  Table,
  StackMargin,
  Stack,
  DropDown,
  openSuccessToast,
  openErrorToast,
  Badge
} from 'ui';
import { addInvolvedPartyToCase } from 'api';

import { compact } from 'lodash';
import Form from './Form/Form';

import style from './InvolvedParties.module.scss';
import MissingInformation from './MissingInformation';

type Props = {
  caseId: string;
  arrayHelpers: FieldArrayRenderProps;
  disabled?: boolean;
  showAdd?: boolean;
  showDelete?: boolean;
  showEdit?: boolean;
  showAddToCase?: boolean;
  form:
    | FormikProps<ChildLawsuitAttributes>
    | FormikProps<InternalLawsuitAttributes>;
};

const InvolvedParties = ({
  caseId,
  arrayHelpers,
  disabled = false,
  form,
  showAdd = true,
  showDelete = true,
  showEdit = true,
  showAddToCase = true
}: Props) => {
  const [partyErrors, setPartyErrors] = useState<FormikErrors<InvolvedParty>>();
  const [selectedParties, setSelectedParties] = useState<Array<string>>([]);
  const isDeleteDisabled =
    selectedParties.length === 0 ||
    selectedParties.length === form.values.parties.length;
  const isCheckboxDisabled = form.values.parties.length === 1;
  const isPartiesEmpty = form.values.parties.length === 0;

  const handleCheckboxChange = (partyName: string) => {
    const itemIndex = selectedParties.findIndex(item => item === partyName);
    const isPartySelected = itemIndex !== -1;
    const parties = [...selectedParties];

    if (isPartySelected) {
      parties.splice(itemIndex, 1);
    } else {
      parties.push(partyName);
    }

    setSelectedParties(parties);
  };

  const handleDeleteSelected = () => {
    const parties = form.values.parties.filter(
      party => !selectedParties.includes(involvedPartyName(party))
    );

    form.setFieldValue('parties', parties);

    setSelectedParties([]);
  };

  const [partyModalOpen, setPartyModalOpen] = useState<boolean>(false);
  const [partyIndex, setPartyIndex] = useState<number>();
  const selectedParty =
    partyIndex === undefined ? undefined : form.values.parties[partyIndex];

  const partyCanBeDeleted = form.values.parties.length > 1;

  function openPartyModal(
    partyErrors?: FormikErrors<InvolvedParty>,
    partyIndex?: number
  ): void {
    partyIndex === undefined
      ? setPartyIndex(undefined)
      : setPartyIndex(partyIndex);

    setPartyErrors(partyErrors);

    setPartyModalOpen(true);
  }

  function closePartyModal(): void {
    setPartyIndex(undefined);
    setPartyModalOpen(false);
  }

  const handleAddToCase = (
    partyIndex: number,
    newSuspect: InvolvedParty
  ) => async () => {
    try {
      await addInvolvedPartyToCase({
        caseId,
        party: newSuspect
      });
      arrayHelpers.replace(partyIndex, { ...newSuspect, isInCase: true });
      openSuccessToast('Suspeito adicionado ao caso com sucesso!');
    } catch (error) {
      openErrorToast('Não foi possível salvar o indivíduo ao caso.');
    }
  };

  function handleSave(
    party: InvolvedPartyAttributes,
    partyIndex?: number
  ): void {
    if (partyIndex === undefined) {
      arrayHelpers.push(party);
    } else {
      arrayHelpers.replace(partyIndex, party);
    }

    closePartyModal();
  }

  function handleDelete(partyIndex: number): void {
    partyCanBeDeleted && arrayHelpers.remove(partyIndex);
    closePartyModal();
  }

  function getPartiesErrors() {
    const errors = getIn(form.errors, 'parties');

    return Array.isArray(errors)
      ? 'Preencha os campos obrigatórios antes de salvar o processo'
      : errors;
  }

  const partiesErrorMessage = getPartiesErrors();

  const addSuspectBehavior = (party: InvolvedParty) => {
    if (!hasInvolvedPartyDocument(party)) {
      return {
        disabled: true,
        message: 'Para adicionar a suspeitos é necessário um CPF/CNPJ'
      };
    }

    if (party.isInCase) {
      return {
        disabled: true,
        message: 'Parte envolvida já está adicionada a suspeitos'
      };
    }

    if (form.dirty && party.isInCase === undefined) {
      return {
        disabled: true,
        message:
          'Para adicionar a suspeitos é necessário primeiro salvar o processo'
      };
    }

    return {
      disabled: false
    };
  };

  const buildActions = (
    item: InvolvedParty,
    index: number,
    partyError: FormikErrors<InvolvedParty>
  ) => {
    const { disabled, message } = addSuspectBehavior(item);
    const addSuspectToCaseAction = {
      text: 'Adicionar a suspeitos',
      callback: handleAddToCase(index, item),
      isDisabled: disabled,
      description: message,
      dataTestId: `dropdown-add-suspect-${index}`
    };

    const editAction = {
      text: 'Editar',
      callback: () => openPartyModal(partyError, index),
      dataTestId: `dropdown-edit-party-${index}`
    };

    const actions = [
      showAddToCase && addSuspectToCaseAction,
      showEdit && editAction
    ];

    return compact(actions);
  };

  return (
    <Stack marginTop={StackMargin.XLARGE}>
      <p className={style.label}>Partes envolvidas</p>
      <Card small dataTestId="involved-parties">
        <Table className={style.table}>
          <Table.Header>
            <Table.Row small>
              <Table.Cell className={style.checkboxCell}></Table.Cell>
              <Table.Cell>Nome completo/Razão Social</Table.Cell>
              <Table.Cell>CPF/CNPJ</Table.Cell>
              <Table.Cell>Tipo de pessoa</Table.Cell>
              <Table.Cell>Tipo de parte</Table.Cell>
              <Table.Cell>Status</Table.Cell>
              <Table.Cell className={style.actionCell}></Table.Cell>
            </Table.Row>
          </Table.Header>
          <Table.Body
            isEmpty={isPartiesEmpty}
            emptyText="Nenhuma parte envolvida neste processo"
            columns={6}
          >
            {form.values.parties.map((party, index) => {
              const partyName = involvedPartyName(party);
              const partyDocument = involvedPartyDocument(party);
              const partyEntityLabel = involvedPartyEntityTypeLabel(
                party.entityType!
              );
              const partyTypeLabel = involvedPartyTypeLabel(party.type!);
              const partyError = getIn(form.errors, `parties[${index}]`);

              return (
                <Table.Row
                  small
                  key={`involved-party-${partyName.replace(/\s/g, '')}`}
                >
                  <Table.Cell className={style.checkboxCell} centeredContent>
                    {showDelete ? (
                      <Checkbox
                        id={`party-checkbox-${partyName.replace(/\s/g, '')}`}
                        name={`party-checkbox-${partyName.replace(/\s/g, '')}`}
                        value={partyName}
                        onChange={e => handleCheckboxChange(e.target.value)}
                        checked={selectedParties.includes(partyName)}
                        disabled={isCheckboxDisabled}
                      />
                    ) : (
                      <></>
                    )}
                  </Table.Cell>
                  <Table.Cell centeredContent>{partyName}</Table.Cell>
                  <Table.Cell centeredContent>{partyDocument}</Table.Cell>
                  <Table.Cell centeredContent>
                    {partyEntityLabel ?? <MissingInformation />}
                  </Table.Cell>
                  <Table.Cell centeredContent>
                    {partyTypeLabel ?? <MissingInformation />}
                  </Table.Cell>
                  <Table.Cell centeredContent>
                    {party.isInCase ? (
                      <Badge
                        rounded
                        dataTestId={`suspect-badge-${index}`}
                        className={style.suspectBadge}
                      >
                        Suspeito
                      </Badge>
                    ) : (
                      ''
                    )}
                  </Table.Cell>
                  <Table.Cell
                    className={style.actionCell}
                    textAlign="right"
                    centeredContent
                  >
                    {buildActions(party, index, partyError).length > 0 ? (
                      <DropDown
                        dataTestId={`dropdown-involved-party-${index}`}
                        options={buildActions(party, index, partyError)}
                      />
                    ) : (
                      <></>
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>

        {partiesErrorMessage && (
          <p className={style.errorMessage}>{partiesErrorMessage}</p>
        )}

        <div className={style.actions}>
          {showAdd && (
            <Button
              highlight
              outline
              small
              disabled={disabled}
              onClick={() => openPartyModal()}
            >
              Adicionar parte envolvida
            </Button>
          )}
          {showDelete && (
            <Button
              highlight
              outline
              danger
              small
              disabled={isDeleteDisabled || disabled}
              onClick={handleDeleteSelected}
            >
              Excluir partes selecionadas
            </Button>
          )}
        </div>
      </Card>

      <Modal
        isOpen={partyModalOpen}
        onClose={closePartyModal}
        dataTestId="party-modal"
        title={
          selectedParty ? 'Editar parte envolvida' : 'Adicionar parte envolvida'
        }
      >
        <Form
          caseId={caseId}
          party={selectedParty}
          partyIndex={partyIndex}
          partyErrors={partyErrors}
          onSave={handleSave}
          onDelete={handleDelete}
          onCancel={closePartyModal}
          partyCanBeDeleted={partyCanBeDeleted}
        />
      </Modal>
    </Stack>
  );
};

export default InvolvedParties;
