import * as Contracts from 'domain/contracts';
import * as TaxIndexers from 'domain/taxIndexers';
import * as Forms from 'domain/forms';
import { creditOriginsOptions } from 'domain/contracts';
import React from 'react';
import { FormikProps, getIn } from 'formik';
import { DateTime } from 'luxon';
import { CreditContractFormAttributes } from 'types';
import { fetchCaseCreditOrigins } from 'api';
import { useQuery } from 'hooks';
import {
  SelectField,
  FormContainer,
  InputField,
  Toggle,
  TextArea,
  DatePickerField,
  InfoArea,
  openErrorToast
} from 'ui';

import style from './ContractInfo.module.scss';

type Props = {
  formik: FormikProps<CreditContractFormAttributes>;
  contractNumberHandler: (e: string) => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  showCreditOrigin?: boolean;
};

const ContractInfo = ({
  formik,
  contractNumberHandler,
  isLoading,
  isDisabled,
  showCreditOrigin
}: Props) => {
  const handleContractNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    formik.handleChange(e);
    contractNumberHandler(e.target.value);
  };

  const diffDateFromToday = (date: string): string => {
    const parsedDate = DateTime.fromISO(date);
    const daysUntilToday =
      parsedDate.diffNow(['days', 'hours']).toObject().days! * -1;

    return daysUntilToday.toString();
  };

  const handleBooleanToggle = (value: string, fieldName: string): void =>
    value === 'true'
      ? formik.setFieldValue(fieldName, true)
      : formik.setFieldValue(fieldName, false);

  const {
    data: creditOrigins = [],
    isLoading: isLoadingCreditOrigins,
    error: creditOriginsError
  } = useQuery(fetchCaseCreditOrigins, undefined);
  const creditOriginOptions = creditOriginsOptions(creditOrigins!);

  if (creditOriginsError) {
    openErrorToast('Falha ao carregar origens de credito');
  }

  return (
    <div>
      {showCreditOrigin && (
        <FormContainer className="col-3">
          <SelectField
            disabled={
              isDisabled || !creditOrigins?.length || creditOriginsError
            }
            error={
              formik.touched.creditOriginId &&
              getIn(formik.errors, 'creditOriginId')
            }
            id="creditOriginId"
            isLoading={isLoadingCreditOrigins}
            name="creditOriginId"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            options={creditOriginOptions}
            title="Origem do crédito"
            value={formik.values.creditOriginId ?? ''}
          />
        </FormContainer>
      )}

      <h2 className={style.title}>Informações do contrato</h2>

      <FormContainer className="col-4">
        <InputField
          disabled={isDisabled || !formik.values.debtorId}
          error={formik.touched.number && getIn(formik.errors, 'number')}
          id="number"
          isLoading={isLoading}
          name="number"
          onBlur={formik.handleBlur}
          onChange={handleContractNumberChange}
          title="Número do contrato"
          type="text"
          value={formik.values.number}
        />

        <SelectField
          disabled={isDisabled || !formik.values.debtorId}
          error={formik.touched.status && getIn(formik.errors, 'status')}
          id="status"
          isLoading={isLoading}
          name="status"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          options={Contracts.statusOptions()}
          title="Situação"
          value={formik.values.status}
        />

        <SelectField
          disabled={isDisabled || !formik.values.debtorId}
          error={formik.touched.modality && getIn(formik.errors, 'modality')}
          id="modality"
          isLoading={isLoading}
          name="modality"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          options={Contracts.modalityOptions()}
          title="Modalidade"
          value={formik.values.modality}
        />

        <Toggle
          checked={formik.values.type}
          disabled={isDisabled || !formik.values.debtorId}
          isLoading={isLoading}
          onChange={event => formik.setFieldValue('type', event.target.value)}
          options={Contracts.typeOptions()}
          title="Bilateral/Compartilhado"
        />

        <SelectField
          disabled={isDisabled || !formik.values.debtorId}
          error={formik.touched.indexer && getIn(formik.errors, 'indexer')}
          id="indexer"
          isLoading={isLoading}
          name="indexer"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          options={TaxIndexers.indexerOptions()}
          title="Indexador"
          value={formik.values.indexer}
        />

        <InputField
          disabled={isDisabled || !formik.values.debtorId}
          error={
            formik.touched.taxPercentage &&
            getIn(formik.errors, 'taxPercentage')
          }
          id="taxPercentage"
          isLoading={isLoading}
          name="taxPercentage"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          title="Taxa (%)"
          type="percent"
          value={formik.values.taxPercentage?.toString() ?? ''}
        />

        <SelectField
          disabled={isDisabled || !formik.values.debtorId}
          error={formik.touched.currency && getIn(formik.errors, 'currency')}
          id="currency"
          isLoading={isLoading}
          name="currency"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          options={Contracts.currencyOptions()}
          title="Moeda"
          value={formik.values.currency}
        />
      </FormContainer>

      <FormContainer className="col-5 -last-small">
        <InputField
          disabled={isDisabled || !formik.values.debtorId}
          error={
            formik.touched.initialAmount &&
            getIn(formik.errors, 'initialAmount')
          }
          id="initialAmount"
          isLoading={isLoading}
          name="initialAmount"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          title="Valor inicial do contrato"
          type="currency"
          value={formik.values.initialAmount?.toString() ?? ''}
        />

        <DatePickerField
          disabled={isDisabled || !formik.values.debtorId}
          error={
            getIn(formik.touched, 'startDate') &&
            getIn(formik.errors, 'startDate')
          }
          id="startDate"
          max={DateTime.local().toISO()}
          name="startDate"
          onBlur={formik.handleBlur}
          onChange={Forms.handleDateFieldChange(
            formik.setFieldValue,
            formik.setFieldTouched,
            'startDate'
          )}
          title="Data inicial do contrato"
          value={formik.values.startDate}
        />

        <DatePickerField
          disabled={isDisabled || !formik.values.debtorId}
          error={
            getIn(formik.touched, 'endDate') && getIn(formik.errors, 'endDate')
          }
          id="endDate"
          name="endDate"
          onBlur={formik.handleBlur}
          onChange={Forms.handleDateFieldChange(
            formik.setFieldValue,
            formik.setFieldTouched,
            'endDate'
          )}
          title="Data final do contrato"
          value={formik.values.endDate}
        />

        <DatePickerField
          disabled={isDisabled || !formik.values.debtorId}
          error={
            getIn(formik.touched, 'firstOverduePaymentDate') &&
            getIn(formik.errors, 'firstOverduePaymentDate')
          }
          id="firstOverduePaymentDate"
          max={DateTime.local().toISO()}
          name="firstOverduePaymentDate"
          onBlur={formik.handleBlur}
          onChange={Forms.handleDateFieldChange(
            formik.setFieldValue,
            formik.setFieldTouched,
            'firstOverduePaymentDate'
          )}
          title="Data do 1º atraso"
          value={formik.values.firstOverduePaymentDate ?? ''}
        />

        <InfoArea
          label="Dias de atraso"
          value={
            formik.values.firstOverduePaymentDate
              ? diffDateFromToday(formik.values.firstOverduePaymentDate)
              : '-'
          }
        />
      </FormContainer>

      <FormContainer className="col-4">
        <InputField
          disabled={isDisabled || !formik.values.debtorId}
          error={
            formik.touched.overdueAmountAtCuttingDate &&
            getIn(formik.errors, 'overdueAmountAtCuttingDate')
          }
          id="overdueAmountAtCuttingDate"
          isLoading={isLoading}
          name="overdueAmountAtCuttingDate"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          title="Saldo devedor na data de corte"
          type="currency"
          value={formik.values.overdueAmountAtCuttingDate?.toString()}
        />

        <DatePickerField
          disabled={isDisabled || !formik.values.debtorId}
          error={
            getIn(formik.touched, 'cuttingDate') &&
            getIn(formik.errors, 'cuttingDate')
          }
          id="cuttingDate"
          max={DateTime.local().toISO()}
          name="cuttingDate"
          onBlur={formik.handleBlur}
          onChange={Forms.handleDateFieldChange(
            formik.setFieldValue,
            formik.setFieldTouched,
            'cuttingDate'
          )}
          title="Data de corte"
          value={formik.values.cuttingDate}
        />

        <InputField
          disabled={isDisabled || !formik.values.debtorId}
          error={
            formik.touched.accountingBalance &&
            getIn(formik.errors, 'accountingBalance')
          }
          id="accountingBalance"
          max={DateTime.local().toISO()}
          isLoading={isLoading}
          name="accountingBalance"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          title="Saldo contábil"
          type="currency"
          value={formik.values.accountingBalance?.toString()}
        />

        <DatePickerField
          disabled={isDisabled || !formik.values.debtorId}
          error={
            getIn(formik.touched, 'accountingDate') &&
            getIn(formik.errors, 'accountingDate')
          }
          id="accountingDate"
          max={DateTime.local().toISO()}
          name="accountingDate"
          onBlur={formik.handleBlur}
          onChange={Forms.handleDateFieldChange(
            formik.setFieldValue,
            formik.setFieldTouched,
            'accountingDate'
          )}
          title="Data contábil"
          value={formik.values.accountingDate}
        />

        <InputField
          disabled={isDisabled || !formik.values.debtorId}
          error={
            formik.touched.pddImpairmentPercentage &&
            getIn(formik.errors, 'pddImpairmentPercentage')
          }
          id="pddImpairmentPercentage"
          isLoading={isLoading}
          name="pddImpairmentPercentage"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          title="PDD/Impairment (%)"
          type="percent"
          value={formik.values.pddImpairmentPercentage?.toString() ?? ''}
        />

        <InputField
          disabled={isDisabled || !formik.values.debtorId}
          error={
            formik.touched.pddImpairmentAmount &&
            getIn(formik.errors, 'pddImpairmentAmount')
          }
          id="pddImpairmentAmount"
          isLoading={isLoading}
          name="pddImpairmentAmount"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          title="PDD/Impairment"
          type="currency"
          value={formik.values.pddImpairmentAmount?.toString() ?? ''}
        />

        <Toggle
          checked={formik.values.taxDeductible.toString()}
          disabled={isDisabled || !formik.values.debtorId}
          isLoading={isLoading}
          onChange={event =>
            handleBooleanToggle(event.target.value, 'taxDeductible')
          }
          options={Forms.booleanOptions()}
          title="Dedutibilidade fiscal"
        />

        <Toggle
          checked={formik.values.inCourt.toString()}
          disabled={isDisabled || !formik.values.debtorId}
          isLoading={isLoading}
          onChange={event => handleBooleanToggle(event.target.value, 'inCourt')}
          options={Forms.booleanOptions()}
          title="Ajuizado"
        />
      </FormContainer>

      <FormContainer>
        <TextArea
          disabled={isDisabled}
          error={formik.touched.comments && getIn(formik.errors, 'comments')}
          id="comments"
          isLoading={isLoading}
          name="comments"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          title="Comentários"
          value={formik.values.comments ?? ''}
        />
      </FormContainer>
    </div>
  );
};

export default ContractInfo;
