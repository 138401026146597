import {
  checkProviderFailed,
  providerName,
  providerStatus,
  providerType
} from 'domain/providers';
import { reportStatus } from 'domain/reports';
import React, { Fragment } from 'react';

import cn from 'classnames';

import { Button, Icon, Modal, Stack, StackMargin, Status } from 'ui';
import {
  EntityType,
  ProviderName,
  ReportExpectedTable,
  Query,
  QueryStatus,
  Report,
  Status as StatusEnum
} from 'types';

import sortBy from 'lodash/sortBy';
import style from './StatusModal.module.scss';

type Props = {
  report?: Report | null;
  statusText?: string;
  isOpen: boolean;
  showAction?: boolean;
  onClose: () => void;
  handleTryAgain: () => void;
  handleGenerateErrorReport: () => void;
};

const messageText = new Map<StatusEnum, string>()
  .set(
    StatusEnum.PARTIAL,
    'Recarregue a página, tente novamente ou comunique sobre as falhas.'
  )
  .set(
    StatusEnum.PENDING,
    'Recarregue a página, tente novamente ou comunique sobre as falhas.'
  )
  .set(StatusEnum.ERROR, 'Tente gerar novamente ou comunique sobre as falhas.');

const buttonText = new Map<StatusEnum, string>()
  .set(StatusEnum.PENDING, 'Tentar novamente')
  .set(StatusEnum.PARTIAL, 'Tentar novamente')
  .set(StatusEnum.ERROR, 'Gerar relatório');

function providerText(
  status: StatusEnum,
  query: Query,
  hasNeowayFailed: boolean,
  hasSerproFailed: boolean
) {
  if (status === StatusEnum.PARTIAL || query.status === QueryStatus.FAILED) {
    return ` (${providerType(query.type)}) ${providerStatus(
      query,
      hasNeowayFailed,
      hasSerproFailed
    )}`;
  }

  if (status === StatusEnum.PENDING) {
    return ` ${providerStatus(query, hasNeowayFailed, hasSerproFailed)}`;
  }

  return '';
}

const StatusModal = ({
  report,
  statusText,
  isOpen,
  showAction = true,
  onClose,
  handleTryAgain,
  handleGenerateErrorReport
}: Props) => {
  const status = reportStatus(report);

  const shouldOpenModal = status !== StatusEnum.SUCCEEDED && isOpen;

  const hasProviederFailed = (
    providerName: ProviderName,
    tableName: ReportExpectedTable
  ) => checkProviderFailed(providerName, tableName, report);

  const handleButtonClick = () => {
    if (status === StatusEnum.ERROR) {
      handleGenerateErrorReport();
    } else {
      handleTryAgain();
    }

    onClose();
  };

  const expectedTable =
    report?.type === EntityType.COMPANY
      ? ReportExpectedTable.COMPANY
      : ReportExpectedTable.INDIVIDUAL;

  return (
    <Modal
      isOpen={shouldOpenModal}
      onClose={onClose}
      contentClassName={style.content}
      medium
    >
      <>
        <Status status={status}>
          <span className={style.status} data-testid="report-status">
            {statusText}
          </span>
        </Status>

        <p className={style.title}>
          A consulta desses provedores falharam, não puderam executar ou estão
          em andamento
        </p>

        <Stack marginTop={StackMargin.LARGE}>
          {sortBy(report?.queries || [], query =>
            providerName(query.provider)
          ).map((query: Query) => (
            <Fragment key={query.id}>
              {(query.status === QueryStatus.FAILED ||
                query.status === QueryStatus.PENDING) && (
                <span
                  className={cn(style.provider, {
                    [style.failed]: query.status === QueryStatus.FAILED
                  })}
                  data-testid={`status-message-${query.id}`}
                >
                  <strong className={style.strong}>
                    {providerName(query.provider)}
                  </strong>
                  {providerText(
                    status,
                    query,
                    hasProviederFailed(ProviderName.NEOWAY, expectedTable),
                    hasProviederFailed(ProviderName.SERPRO, expectedTable)
                  )}
                </span>
              )}
            </Fragment>
          ))}
        </Stack>

        <Stack marginTop={StackMargin.MEDIUM}>
          <div className={style.message}>
            <Icon name="question-mark-rounded" fill="#969aab" small />
            <Stack marginLeft={StackMargin.SMALL}>
              {messageText.get(status) || ''}
            </Stack>
          </div>
        </Stack>

        {showAction ? (
          <Stack marginTop={StackMargin.LARGE}>
            <Button
              outline
              highlight
              small
              centered
              onClick={handleButtonClick}
            >
              {buttonText.get(status) || ''}
            </Button>
          </Stack>
        ) : null}
      </>
    </Modal>
  );
};
export default StatusModal;
