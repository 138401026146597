import { Invoice, InvoiceFormAttributes, Covenant } from 'types';

import Client from './client';

function createInvoice(caseId: string, invoiceData: InvoiceFormAttributes) {
  return Client.post<InvoiceFormAttributes, Invoice>(
    `cases/${caseId}/invoices`,
    invoiceData
  );
}

function updateInvoice(
  caseId: string,
  id: string,
  invoiceData: InvoiceFormAttributes
) {
  return Client.put<InvoiceFormAttributes, Invoice>(
    `cases/${caseId}/invoices/${id}`,
    invoiceData
  );
}

function submitInvoice({
  caseId,
  values,
  invoice
}: {
  caseId: string;
  values: InvoiceFormAttributes;
  invoice?: Invoice;
}) {
  if (invoice) return updateInvoice(caseId, invoice.id, values);

  return createInvoice(caseId, values);
}

function fetchCovenants() {
  return Client.get<Covenant[]>('cases/covenants');
}

function fetchInvoice({ caseId, id }: { caseId: string; id: string }) {
  return Client.get<Invoice>(`cases/${caseId}/invoices/${id}`);
}

function deleteInvoice({ caseId, id }: { caseId: string; id: string }) {
  return Client.delete(`cases/${caseId}/invoices/${id}`);
}

export {
  createInvoice,
  submitInvoice,
  fetchCovenants,
  fetchInvoice,
  deleteInvoice
};
