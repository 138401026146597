import React, { useState } from 'react';
import cn from 'classnames';

import { Icon } from 'ui';

import style from './Bookmark.module.scss';

type Props = {
  bookmarked: boolean;
  className?: string;
  fill: string;
  onClick: () => void;
  small?: boolean;
};

const Bookmark = ({
  bookmarked = false,
  className,
  fill,
  onClick,
  small
}: Props) => {
  const [isHover, setIsHover] = useState<boolean>(false);

  const classNames = cn([style.bookmark, className && className]);

  return (
    <div
      className={classNames}
      data-testid="bookmark-wrapper"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={onClick}
    >
      {bookmarked ? (
        isHover ? (
          <Icon
            small={small}
            name="bookmark-remove"
            fill={fill}
            dataTestId="bookmark-remove"
          />
        ) : (
          <Icon
            small={small}
            name="bookmark"
            fill={fill}
            dataTestId="bookmark"
          />
        )
      ) : isHover ? (
        <Icon
          small={small}
          name="bookmark-add"
          fill={fill}
          dataTestId="bookmark-add"
        />
      ) : (
        <Icon
          small={small}
          name="bookmark-outline"
          fill={fill}
          dataTestId="bookmark-outline"
        />
      )}
    </div>
  );
};

export default Bookmark;
