import React, { ReactNode } from 'react';
import classNames from 'classnames';

import './Button.scss';
import { Icon, Spinner } from 'ui';

type Props = {
  children?: ReactNode;
  block?: boolean;
  borderless?: boolean;
  centered?: boolean;
  className?: string;
  danger?: boolean;
  dataTestId?: string;
  disabled?: boolean;
  form?: string;
  highlight?: boolean;
  icon?: string;
  large?: boolean;
  loading?: boolean;
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement>) => void)
    | (() => void);
  outline?: boolean;
  small?: boolean;
  social?: boolean;
  type?: 'button' | 'submit' | 'reset';
};

const Button = ({
  block,
  borderless,
  centered,
  children,
  className,
  danger,
  dataTestId = 'button',
  disabled,
  form,
  highlight,
  icon,
  large,
  loading,
  onClick,
  outline,
  small,
  social,
  type = 'button'
}: Props) => {
  const btnClass = classNames('button-default', className, {
    '-block': block,
    '-borderless': borderless,
    '-centered': centered,
    '-danger': danger,
    '-disabled': disabled,
    '-highlight': highlight,
    '-icon': icon,
    '-large': large,
    '-loading': loading,
    '-outline': outline,
    '-small': small,
    '-social': social
  });

  return (
    <button
      className={btnClass}
      onClick={onClick}
      disabled={disabled || loading}
      type={type}
      form={form}
      data-testid={dataTestId}
    >
      {loading ? (
        <Spinner color={outline ? 'blue' : 'white'} />
      ) : icon ? (
        <>
          <Icon name={icon} />
          {children && <span className="children">{children}</span>}
        </>
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
