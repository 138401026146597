import cond from 'lodash/cond';
import inRange from 'lodash/inRange';
import { DateTime } from 'luxon';

import { daysSince } from 'utils';

import { BadgeColor } from 'components/ColoredBadge/ColoredBadge';

import {
  Option,
  CreditContractModality,
  CreditContractType,
  CreditContractCurrency,
  CreditContractStatus,
  CreditContractOrigin
} from 'types';

const modalityDictionary: { [key in CreditContractModality]: string } = {
  [CreditContractModality.ACC]: 'ACC',
  [CreditContractModality.ACE]: 'ACE',
  [CreditContractModality.BNDES_AUTOMATICO_FINEM]: 'BNDES Automático/FINEM',
  [CreditContractModality.ACORDO_JUDICIAL]: 'Acordo Judicial',
  [CreditContractModality.BONUS_DE_ADIMPLENCIA]: 'Bonus de adimplencia',
  [CreditContractModality.CAMBIO]: 'Câmbio',
  [CreditContractModality.CAPITAL_DE_GIRO_CCB]: 'Capital de giro CCB',
  [CreditContractModality.CARTA_DE_CREDITO]: 'Carta de crédito',
  [CreditContractModality.CDCA]: 'CDCA',
  [CreditContractModality.CONFISSAO_DE_DIVIDA]: 'Confissão de dívida',
  [CreditContractModality.CONTA_GARANTIDA]: 'Conta garantida',
  [CreditContractModality.COTAS_DE_FIP]: 'Cotas de FIP',
  [CreditContractModality.CPR]: 'CPR',
  [CreditContractModality.DEBENTURES]: 'Debentures',
  [CreditContractModality.DERIVATIVOS]: 'Derivativos',
  [CreditContractModality.DIP_FINANCE]: 'Dip finance',
  [CreditContractModality.FIANCA]: 'Fiança',
  [CreditContractModality.FIANCA_HONRADA]: 'Fiança honrada',
  [CreditContractModality.FINAME]: 'Finame',
  [CreditContractModality.FINIMP]: 'Finimp',
  [CreditContractModality.KG_RENEG]: 'Kg reneg',
  [CreditContractModality.LEASING]: 'Leasing',
  [CreditContractModality.LOAN]: 'Loan',
  [CreditContractModality.LOAN_OFFSHORE]: 'Loan offshore',
  [CreditContractModality.NCE]: 'NCE',
  [CreditContractModality.NOTAS_PROMISSORIAS]: 'Notas promissórias',
  [CreditContractModality.PLANO_DE_RJ]: 'Plano de RJ',
  [CreditContractModality.PRE_PAGAMENTO_DE_EXPORTACAO_PPE]:
    'Pré pagamento exportação PPE',
  [CreditContractModality.REPASSE_BNDES]: 'Repasse BNDES',
  [CreditContractModality.CCB_PEAC_PRE_FIXADA]: 'CCB PEAC pré fixada',
  [CreditContractModality.PEAC]: 'PEAC'
};

const typeDictionary: { [key in CreditContractType]: string } = {
  [CreditContractType.BILATERAL]: 'Bilateral',
  [CreditContractType.UNIONIZED]: 'Compart.'
};

const currencyDictionary: { [key in CreditContractCurrency]: string } = {
  [CreditContractCurrency.BRL]: 'BRL',
  [CreditContractCurrency.USD]: 'USD',
  [CreditContractCurrency.EUR]: 'EUR',
  [CreditContractCurrency.OTHER]: 'Outro'
};

const statusDictionary: { [key in CreditContractStatus]: string } = {
  [CreditContractStatus.CEDIDO]: 'Cedido',
  [CreditContractStatus.EM_DIA]: 'Em dia',
  [CreditContractStatus.INADIMPLENTE]: 'Inadimplente',
  [CreditContractStatus.LIQUIDADO]: 'Liquidado',
  [CreditContractStatus.NOVADO_POR_PRJ]: 'Novado por PRJ',
  [CreditContractStatus.PREJUIZO]: 'Prejuízo'
};

export function modalityLabel(modality: CreditContractModality) {
  return modalityDictionary[modality] || modality;
}

export function modalityOptions(): Option[] {
  return Object.values(CreditContractModality).map(modality => ({
    value: modality,
    label: modalityLabel(modality)
  }));
}

export function typeLabel(type: CreditContractType) {
  return typeDictionary[type] || type;
}

export function typeOptions(): Option[] {
  return Object.values(CreditContractType).map(type => ({
    value: type,
    label: typeLabel(type)
  }));
}

export function currencyLabel(currency: CreditContractCurrency) {
  return currencyDictionary[currency] || currency;
}

export function currencyOptions(): Option[] {
  return Object.values(CreditContractCurrency).map(currency => ({
    value: currency,
    label: currencyLabel(currency)
  }));
}

export function statusLabel(status: CreditContractStatus) {
  return statusDictionary[status] || status;
}

export function statusOptions(): Option[] {
  return Object.values(CreditContractStatus).map(status => ({
    value: status,
    label: statusLabel(status)
  }));
}

export function creditOriginsOptions(
  creditOrigins: CreditContractOrigin[]
): Option[] {
  return creditOrigins.map(origin => ({
    value: origin.id,
    label: origin.name
  }));
}

export const contractBadgeColor = (firstOverduePayment: string | null) => {
  const now = DateTime.local().toISO();

  const daysAfterOverdueDate = daysSince(firstOverduePayment ?? now, now);

  return cond([
    [(days: number) => days >= 90, () => BadgeColor.PURPLE],
    [(days: number) => inRange(days, 30, 90), () => BadgeColor.RED],
    [(days: number) => inRange(days, 15, 30), () => BadgeColor.YELLOW],
    [(days: number) => inRange(days, 0, 15), () => BadgeColor.DEFAULT]
  ])(daysAfterOverdueDate);
};
