import {
  ClientParams,
  Case,
  CaseFormAttributes,
  SearchEntity,
  CaseEntity,
  EntityType,
  CasesStats,
  CreditContractOrigin,
  CaseType,
  CasesStatsForSalesFinance,
  CasesStatsForAtivosEspeciais,
  CasesStatsForPEACS,
  RequestQueryParams
} from 'types';
import Client from './client';

function createCase(caseData: CaseFormAttributes) {
  return Client.post<CaseFormAttributes, Case>('cases', caseData);
}

function updateCase(id: string, caseData: CaseFormAttributes) {
  return Client.put<CaseFormAttributes, Case>(`cases/${id}`, caseData);
}

function fetchCase(id: string) {
  return Client.get<Case>(`cases/${id}`);
}

function fetchCases(params: ClientParams, type: CaseType) {
  return Client.get<Case[]>(`cases/${type}`, { params: params });
}

function fetchCaseCreditOrigins() {
  return Client.get<CreditContractOrigin[]>(`cases/credit_origins`);
}

type FetchCaseEntitiesParams = RequestQueryParams & Pick<CaseEntity, 'caseId'>;

function fetchCaseEntities({
  caseId,
  pagination,
  orderBy
}: FetchCaseEntitiesParams) {
  return Client.get<CaseEntity[]>(`cases/${caseId}/entities`, {
    params: {
      page: pagination?.page?.toString(),
      pageSize: pagination?.pageSize?.toString(),
      field: orderBy?.field,
      value: orderBy?.value
    }
  });
}

function searchCaseEntities(
  caseId: string,
  entityType: EntityType,
  isAbroad: boolean,
  searchParams: { key: string; value: string },
  suggestionName?: string
) {
  return Client.get<SearchEntity>(`cases/${caseId}/entities/search`, {
    params: {
      isAbroad: isAbroad.toString(),
      suggestionName,
      type: entityType,
      [searchParams.key]: searchParams.value
    }
  });
}

function deleteCase(id: string) {
  return Client.delete(`cases/${id}`);
}

function fetchCasesStats({ caseType }: { caseType: CaseType }) {
  return Client.get<CasesStats>(`cases/${caseType}/stats`);
}

function fetchAtivosEspeciaisCasesStats() {
  return Client.get<CasesStatsForAtivosEspeciais>(
    `cases/${CaseType.ATIVOS_ESPECIAIS}/stats`
  );
}

function fetchPeacCasesStats() {
  return Client.get<CasesStatsForPEACS>(`cases/${CaseType.PEAC}/stats`);
}

function fetchSalesFinanceCasesStats() {
  return Client.get<CasesStatsForSalesFinance>(
    `cases/${CaseType.SALES_FINANCE}/stats`
  );
}

function exportCases(caseIds?: string[]) {
  return Client.post<string[], string>('cases/exports', { caseIds });
}

export {
  createCase,
  deleteCase,
  fetchCase,
  fetchCaseCreditOrigins,
  fetchCaseEntities,
  searchCaseEntities,
  fetchCases,
  updateCase,
  fetchCasesStats,
  exportCases,
  fetchAtivosEspeciaisCasesStats,
  fetchPeacCasesStats,
  fetchSalesFinanceCasesStats
};
