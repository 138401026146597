import React, { ReactElement, useState, useContext } from 'react';
import cn from 'classnames';
import { DateTime } from 'luxon';

import {
  Card,
  TimeLine,
  openErrorToast,
  RadioSwitch,
  Button,
  openSuccessToast,
  DropDown,
  Modal
} from 'ui';

import {
  MovementsResponse,
  Movement,
  InternalLawsuit,
  OnSubmit,
  MovementFormAttributes,
  SourceType,
  ChildLawsuit,
  Feature
} from 'types';

import {
  fetchMovements,
  createMovement,
  updateMovement,
  deleteMovement,
  can,
  Action
} from 'api';

import {
  useQuery,
  useLiveLawsuit,
  useFeatureFlag,
  useSearchAnchor
} from 'hooks';

import { MovementTimelineContent, CaseContext } from 'components';
import Form from './Form';

import './Movements.scss';

type Props = {
  caseId: string;
  lawsuit: InternalLawsuit | ChildLawsuit;
  onAutoUpdateToggle: () => void;
};

const Movements = ({
  caseId,
  lawsuit,
  onAutoUpdateToggle
}: Props): ReactElement => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [movement, setMovement] = useState<Movement | undefined>(undefined);
  const [isAutoUpdateEnabled] = useFeatureFlag(Feature.LAWSUIT_AUTO_UPDATE);
  const {
    data: movementsData,
    isLoading,
    error,
    refetch,
    setData: setMovementsData
  } = useQuery<MovementsResponse, { caseId: string; lawsuitId: string }>(
    fetchMovements,
    {
      caseId,
      lawsuitId: lawsuit!.id
    }
  );

  const { caseData } = useContext(CaseContext);
  const negotiatorId = caseData?.negotiator?.id;
  const canUser = {
    accessMovementFiles: can(
      Action.CASOS_PROCESSO_ARQUIVOS_DO_MOVIMENTO_CONSULTAR,
      negotiatorId
    ),
    updateMovements: can(
      Action.CASOS_PROCESSO_MOVIMENTACOES_ATUALIZAR_AUTOMATICAMENTE,
      negotiatorId
    ),
    createMovements: can(
      Action.CASOS_PROCESSO_MOVIMENTACOES_INCLUIR,
      negotiatorId
    ),
    editMovements: can(
      Action.CASOS_PROCESSO_MOVIMENTACOES_EDITAR,
      negotiatorId
    ),
    deleteMovements: can(
      Action.CASOS_PROCESSO_MOVIMENTACOES_EXCLUIR,
      negotiatorId
    )
  };

  useLiveLawsuit(`cases:${caseId}:internal_lawsuits:${lawsuit.id}`, () =>
    refetch({ caseId, lawsuitId: lawsuit.id })
  );

  if (error) {
    openErrorToast('Erro ao listar movimentações.');
  }

  const toggleClassNames = cn('toggle', {
    '-active': lawsuit.isAutoUpdated
  });

  const lastUpdatedAt =
    movementsData?.lastUpdatedAt &&
    DateTime.fromISO(movementsData?.lastUpdatedAt)
      .setLocale('pt-BR')
      .toRelative();
  const handleModalToggle = () => {
    setMovement(undefined);
    setIsModalOpen(!isModalOpen);
  };

  const toggleEditMode = (movement: Movement) => {
    setMovement(movement);
    setIsModalOpen(!isModalOpen);
  };

  const submitMovement = (values: MovementFormAttributes) => {
    if (movement)
      return updateMovement({
        caseId,
        lawsuitId: lawsuit.id,
        movementId: movement.id,
        movementData: values
      });

    return createMovement({
      caseId,
      lawsuitId: lawsuit.id,
      movementData: values
    });
  };

  const handleSubmitMovement: OnSubmit<MovementFormAttributes> = async (
    values: MovementFormAttributes,
    formikHelpers
  ) => {
    try {
      await submitMovement(values);

      refetch({ caseId, lawsuitId: lawsuit.id });
      handleModalToggle();
      openSuccessToast('Movimentação salvo com sucesso!');
    } catch (error) {
      formikHelpers.setErrors(error.errors);
      openErrorToast('Erro ao salvar movimentação.');
    }
  };

  const handleDelete = (id: string) => async () => {
    if (
      !window.confirm(
        'Você tem certeza de que deseja remover essa movimentação?'
      )
    )
      return;

    try {
      await deleteMovement({ caseId, lawsuitId: lawsuit.id, id });

      if (movementsData) {
        const filteredmovements = movementsData.movements.filter(
          movement => movement.id !== id
        );

        setMovementsData({ ...movementsData, movements: filteredmovements });
      }

      openSuccessToast('Movimentação excluida com sucesso!');
    } catch (error) {
      openErrorToast('Erro ao excluir movimentação.');
    }
  };

  useSearchAnchor({ isLoading, field: 'id' });

  const canEditOrDeleteMovements =
    canUser.editMovements || canUser.deleteMovements;

  return (
    <div className="movements-container">
      {isLoading ? (
        <p>Carregando...</p>
      ) : (
        <>
          {canUser.updateMovements && isAutoUpdateEnabled && (
            <div className="actions">
              <div className="auto-update">
                <RadioSwitch
                  dataTestId="auto-update-toggle"
                  active={lawsuit.isAutoUpdated}
                  onChange={onAutoUpdateToggle}
                />
                <span className={toggleClassNames}>
                  Atualização automática
                  {lawsuit.isAutoUpdated ? ' ativada' : ' desativada'}
                </span>
              </div>
              <div className="last-update">
                {lastUpdatedAt && (
                  <p className="label">Última atualização: {lastUpdatedAt}</p>
                )}
              </div>
            </div>
          )}
          <Card>
            {canUser.createMovements && (
              <Button
                onClick={handleModalToggle}
                highlight
                outline
                small
                centered
              >
                Adicionar movimentação
              </Button>
            )}
            {movementsData?.movements.length ? (
              movementsData.movements.map((movement: Movement) => (
                <TimeLine
                  title={movement.title}
                  date={movement.date}
                  key={movement.id}
                  id={movement.id}
                  dataTestId={`movement-item`}
                  actions={
                    movement.source === SourceType.MANUAL &&
                    canEditOrDeleteMovements && (
                      <DropDown
                        options={[
                          {
                            text: 'Editar movimentação',
                            callback: () => toggleEditMode(movement),
                            dataTestId: 'movement-edit-dropdown',
                            isHidden: !canUser.editMovements
                          },
                          {
                            text: 'Excluir movimentação',
                            isNegative: true,
                            callback: handleDelete(movement.id),
                            dataTestId: 'movement-delete-dropdown',
                            isHidden: !canUser.deleteMovements
                          }
                        ]}
                      />
                    )
                  }
                >
                  <MovementTimelineContent
                    movement={movement}
                    caseId={caseId}
                    showMovementFiles={canUser.accessMovementFiles}
                  />
                </TimeLine>
              ))
            ) : (
              <p className="empty">Não há movimentações neste processo</p>
            )}
          </Card>
        </>
      )}
      <Modal
        title={movement ? 'Editar movimentação' : 'Nova movimentação'}
        isOpen={isModalOpen}
        onClose={handleModalToggle}
      >
        <Form
          caseId={caseId}
          movementData={movement}
          onSubmit={handleSubmitMovement}
        />
      </Modal>
    </div>
  );
};

export default Movements;
