import React from 'react';
import * as Yup from 'yup';
import { useFormik, getIn } from 'formik';

import { submitLawFirm } from 'api';

import { LawFirmAttributes, LawFirm, OnSubmit } from 'types';

import {
  FormContainer,
  InputField,
  Button,
  openErrorToast,
  openSuccessToast,
  Stack,
  StackMargin
} from 'ui';

const initialValues: LawFirmAttributes = {
  name: ''
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Precisa ter ao menos 3 caracteres')
    .max(255, 'Precisa ter menos de 256 caracteres')
    .required('Campo obrigatório')
});

type Props = {
  onCreateLawFirm: (lawfirm: LawFirm) => void;
  onUpdateLawFirm: (lawfirm: LawFirm) => void;
  lawFirmData?: LawFirm;
};

const Form = ({ onCreateLawFirm, onUpdateLawFirm, lawFirmData }: Props) => {
  const onSubmit: OnSubmit<LawFirmAttributes> = async (
    values,
    formikHelpers
  ) => {
    try {
      const response = await submitLawFirm({ lawfirm: lawFirmData, values });

      lawFirmData
        ? onUpdateLawFirm(response.data)
        : onCreateLawFirm(response.data);

      openSuccessToast('Escritório salvo com sucesso!');
    } catch (error) {
      formikHelpers.setErrors(error.errors);
      openErrorToast('Erro ao salvar escritório');
    }
  };

  const formik = useFormik({
    initialValues: lawFirmData || initialValues,
    validationSchema,
    onSubmit: onSubmit
  });
  return (
    <form className="lawfirm-form" data-testid="lawfirm-form">
      <FormContainer>
        <InputField
          dataTestId="lawfirm-name"
          id="lawfirm-name"
          name="name"
          type="text"
          value={formik.values.name}
          error={formik.touched.name && getIn(formik.errors, 'name')}
          title="Nome do escritório"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </FormContainer>
      <Stack marginTop={StackMargin.MEDIUM}>
        <Button
          type="submit"
          onClick={formik.handleSubmit}
          highlight
          centered
          dataTestId="save-lawfirm"
        >
          {lawFirmData ? 'Salvar' : 'Criar'}
        </Button>
      </Stack>
    </form>
  );
};

export default Form;
