import { lawsuitNumber } from 'domain/lawsuits';
import React, { useRef, useState, useContext } from 'react';
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch
} from 'react-router-dom';

import {
  ChildLawsuitAttributes,
  Feature,
  LocationState,
  TabContent as TabContentProps
} from 'types';
import { FormikHelpers, FormikProps } from 'formik';

import {
  autoUpdateChildLawsuit,
  deleteChildLawsuit,
  fetchChildLawsuit,
  updateChildLawsuit,
  can,
  Action
} from 'api';
import { CaseContext } from 'components';
import { useFeatureFlag, useQuery } from 'hooks';

import Layout from 'Layouts/Layout';
import {
  Button,
  Header,
  Link,
  openErrorToast,
  openSuccessToast,
  TabContent,
  Tabs,
  Title
} from 'ui';
import Movements from '../../Movements';
import Form from '../Form';

import style from './Edit.module.scss';

type MatchParams = {
  caseId: string;
  internalLawsuitId: string;
  childLawsuitId: string;
  tab: string;
};

const Edit = () => {
  const history = useHistory();
  const params = useParams<MatchParams>();
  const location = useLocation<LocationState>();
  const match = useRouteMatch();
  const [isWaitingResponse, setWaitingResponse] = useState(false);
  const [isMovementsEnable] = useFeatureFlag(Feature.LAWSUIT_MOVEMENTS);
  const formRef = useRef<FormikProps<ChildLawsuitAttributes>>(null);

  const { caseId, internalLawsuitId, childLawsuitId } = params;

  const {
    data: childLawsuit,
    error,
    isLoading,
    setData: setChildLawsuit
  } = useQuery(fetchChildLawsuit, {
    caseId,
    internalLawsuitId,
    childLawsuitId
  });

  const linkTo = (newTab: TabContentProps) => {
    if (!newTab.path) return;

    return generatePath(match.path, {
      caseId,
      internalLawsuitId,
      childLawsuitId,
      tab: newTab.path
    });
  };

  const handleSubmit = () => {
    if (formRef.current) formRef.current.handleSubmit();
  };

  const onSubmit = async (
    values: ChildLawsuitAttributes,
    formikHelpers: FormikHelpers<ChildLawsuitAttributes>
  ) => {
    try {
      childLawsuit &&
        (await updateChildLawsuit(
          caseId,
          internalLawsuitId,
          childLawsuit.id,
          values
        ));
      openSuccessToast('Processo atualizado com sucesso!');
    } catch (error) {
      formikHelpers.setErrors(error.errors);
      openErrorToast('Erro ao atualizar processo!');
    }
  };

  const handleDelete = async () => {
    if (
      window.confirm('Você tem certeza de que deseja remover esse processo?')
    ) {
      try {
        await deleteChildLawsuit(caseId, internalLawsuitId, childLawsuitId);
        openSuccessToast('Processo removido com sucesso!');

        history.push(`/cases/${caseId}/internal_lawsuits/${internalLawsuitId}`);
      } catch (error) {
        openErrorToast('Não foi possível remover o processo.');
      }
    }
  };

  const handleAutoUpdate = async () => {
    if (!childLawsuit) return;

    try {
      const response = await autoUpdateChildLawsuit(
        caseId,
        internalLawsuitId,
        childLawsuit.id
      );

      setChildLawsuit(response.data);
    } catch (error) {
      openErrorToast(
        'Não foi possível ativar a atualização automática de movimentações.'
      );
    }
  };

  if (error) openErrorToast('Erro ao buscar processo');

  const getParentLawsuitNumber = () => {
    return location.state
      ? location.state.parentLawsuitNumber
      : childLawsuit?.parent.number;
  };

  const { caseData } = useContext(CaseContext);
  const negotiatorId = caseData?.negotiator?.id;
  const canUser = {
    editLawsuitBasicInfo: can(
      Action.CASOS_PROCESSO_INFORMACOES_BASICAS_EDITAR,
      negotiatorId
    )
  };

  return (
    <Layout>
      <Header>
        <Link
          iconName="arrow-left"
          to={`/cases/${caseId}/internal_lawsuits/${internalLawsuitId}`}
        >
          Voltar
        </Link>
        <Title>{lawsuitNumber(getParentLawsuitNumber())}</Title>
      </Header>
      <Header
        className={style.header}
        sticky
        actionsComponent={
          <>
            <Button
              highlight
              outline
              small
              danger
              type="button"
              onClick={handleDelete}
            >
              Excluir processo
            </Button>
            <Button highlight small type="submit" onClick={handleSubmit}>
              Salvar
            </Button>
          </>
        }
      >
        <Title className={style.title}>
          {lawsuitNumber(childLawsuit?.number)}
        </Title>
      </Header>
      <Tabs linkTab={linkTo} defaultPath={params.tab}>
        <TabContent
          title="Informações básicas"
          path="basic-information"
          dataTestId="basic-information-tab"
        >
          {isLoading && <p>Carregando...</p>}
          {childLawsuit && (
            <Form
              caseId={caseId}
              formRef={formRef}
              onSubmit={onSubmit}
              data={childLawsuit}
              isWaitingResponse={isWaitingResponse}
              setWaitingResponse={setWaitingResponse}
              isDisabledOnEdit={!canUser.editLawsuitBasicInfo}
            />
          )}
        </TabContent>

        {isMovementsEnable ? (
          <TabContent
            title="Movimentações"
            path="movements"
            dataTestId="movements-tab"
          >
            {isLoading && <p>Carregando...</p>}
            {childLawsuit && (
              <Movements
                caseId={caseId}
                lawsuit={childLawsuit}
                onAutoUpdateToggle={handleAutoUpdate}
              />
            )}
          </TabContent>
        ) : (
          <></>
        )}
      </Tabs>
    </Layout>
  );
};

export default Edit;
