import React from 'react';
import { getIn, FormikHandlers, FormikTouched, FormikErrors } from 'formik';
import { Button, InputField } from 'ui';

type PasswordAttributes = {
  password: string;
  passwordConfirmation: string;
};

type Props = {
  errors: FormikErrors<PasswordAttributes>;
  touched: FormikTouched<PasswordAttributes>;
  values: PasswordAttributes;
  isSubmitting: boolean;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  handleBlur: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnChange: FormikHandlers['handleChange'];
};

const Form = ({
  handleSubmit,
  handleBlur,
  touched,
  errors,
  values,
  handleOnChange,
  isSubmitting
}: Props) => {
  return (
    <form data-testid="form-new-password" onSubmit={handleSubmit}>
      <InputField
        id="password"
        name="password"
        type="password"
        title="Nova senha"
        error={touched.password && getIn(errors, 'password')}
        onChange={handleOnChange}
        onBlur={handleBlur}
        value={values.password}
        dataTestId="new-password-input-password"
        autoComplete="new-password"
      />
      <InputField
        id="password-confirmation"
        name="passwordConfirmation"
        type="password"
        title="Digite a senha novamente"
        error={touched.password && getIn(errors, 'passwordConfirmation')}
        onChange={handleOnChange}
        onBlur={handleBlur}
        value={values.passwordConfirmation}
        dataTestId="new-password-input-password-confirmation"
        autoComplete="new-password"
      />
      <Button highlight type="submit" disabled={isSubmitting}>
        Criar
      </Button>
    </form>
  );
};

export default Form;
