import { isValidFileSize } from 'domain/files';
import React, { useState, useReducer, useEffect } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { FileUpload } from 'types';
import { uploadFile, deleteFile } from 'api';

import { Button, Card, openErrorToast } from 'ui';
import Gallery from '../Gallery';
import ProgressBar from '../ProgressBar';
import Thumbnails from '../Thumbnails';
import {
  ReducerImage,
  ReducerActionType,
  ReducerActions,
  UploadState
} from '../types';

import style from './ImageGalleryField.module.scss';

type Props = {
  images: FileUpload[];
  dataTestId?: string;
  caseId: string;
  onUploadSuccess?: (imageIds: string[]) => void;
  showAdd?: boolean;
  showDelete?: boolean;
};

const IMAGES_TO_SHOW = 6;

const imagesReducer = (
  state: ReducerImage[],
  action: ReducerActions
): ReducerImage[] => {
  switch (action.type) {
    case ReducerActionType.NEW: {
      const updatedState = [
        ...state,
        {
          uploadProgress: {
            id: action.payload.id,
            progress: 0,
            state: UploadState.INPROGRESS
          }
        }
      ];

      return updatedState;
    }

    case ReducerActionType.UPDATEPROGRESS: {
      const updatedState = state.map(image => {
        if (image.uploadProgress?.id === action.payload.id) {
          return {
            ...image,
            uploadProgress: {
              ...image.uploadProgress,
              progress: action.payload.progress
            }
          };
        }

        return image;
      });

      return updatedState;
    }

    case ReducerActionType.SUCCESS: {
      const updatedState = state.map(image => {
        if (image.uploadProgress?.id === action.payload.id) {
          return {
            ...action.payload.image,
            uploadProgress: {
              ...image.uploadProgress,
              state: UploadState.SUCCESS
            }
          };
        }

        return image;
      });

      return updatedState;
    }

    case ReducerActionType.ERROR: {
      const updatedState = state.map(image => {
        if (image.uploadProgress?.id === action.payload.id) {
          return {
            uploadProgress: {
              ...image.uploadProgress,
              state: UploadState.ERROR
            }
          };
        }

        return image;
      });

      return updatedState;
    }

    case ReducerActionType.DELETE: {
      const removedState = state.filter(
        image => image.id !== action.payload.imageId
      );

      return removedState;
    }

    case ReducerActionType.RESET: {
      return action.payload.images;
    }

    default:
      return state;
  }
};

const validMimeType = (file: File) => {
  return ['image/jpeg', 'image/png', 'image/jpg'].includes(file.type);
};

const ImageGalleryField = ({
  images: propImages,
  dataTestId,
  caseId,
  onUploadSuccess,
  showAdd = true,
  showDelete = true
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState<number>();
  const [images, dispatch] = useReducer(imagesReducer, propImages);

  useDeepCompareEffect(() => {
    dispatch({
      type: ReducerActionType.RESET,
      payload: {
        images: propImages
      }
    });
  }, [propImages]);

  useEffect(() => {
    if (!onUploadSuccess) return;

    const noUploadInProgress = images.every(
      image => image.uploadProgress?.state !== UploadState.INPROGRESS
    );

    if (noUploadInProgress && images.length > 0) {
      const imageIds = images.map(image => image.id!).filter(Boolean);

      onUploadSuccess(imageIds);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  const progressbar = (id: number) => (ev: ProgressEvent) => {
    const progress = (ev.loaded / ev.total) * 100;

    dispatch({
      type: ReducerActionType.UPDATEPROGRESS,
      payload: {
        id,
        progress: Math.round(progress)
      }
    });
  };

  const upload = async (file: File) => {
    const id = Date.now();

    if (!isValidFileSize(file.size)) {
      openErrorToast(
        'Novas fotos não adicionadas, um ou mais arquivos excedem o limite permitido (50MB)'
      );
      return;
    }

    if (!validMimeType(file)) {
      openErrorToast(
        'Novas fotos não adicionadas, o formato dos arquivos é incompatível.'
      );
      return;
    }

    dispatch({
      type: ReducerActionType.NEW,
      payload: { id }
    });

    const formData = new FormData();
    formData.append('data[file]', file, file.name);

    try {
      const response = await uploadFile(caseId, formData, progressbar(id));

      dispatch({
        type: ReducerActionType.SUCCESS,
        payload: { id, image: response.data }
      });
    } catch (error) {
      dispatch({
        type: ReducerActionType.ERROR,
        payload: { id }
      });
    }
  };

  const handleOnChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = [...event.target.files!];

    files.forEach(file => {
      upload(file);
    });
  };

  const handleDeleteImage = async (imageId: string) => {
    if (!window.confirm('Deseja remover essa imagem?')) return;

    try {
      await deleteFile(caseId, imageId);

      dispatch({
        type: ReducerActionType.DELETE,
        payload: { imageId }
      });
    } catch (error) {
      openErrorToast('Erro ao excluir imagem.');
    }
  };

  const showOpenGallery = images.length > IMAGES_TO_SHOW;

  const progressBar = images.flatMap(image => image.uploadProgress);

  const handleOpenGallery = (imageIndex?: number) => {
    setImageIndex(imageIndex);
    setIsOpen(true);
  };

  return (
    <Card className={style.card}>
      <h3 className={style.title}>
        {showOpenGallery
          ? `Fotos (${IMAGES_TO_SHOW}/${images.length})`
          : `Fotos (${images.length})`}
      </h3>

      <Thumbnails
        thumbnailsPerRow={3}
        images={images.slice(0, IMAGES_TO_SHOW)}
        onDelete={handleDeleteImage}
        onOpenGallery={handleOpenGallery}
        showDelete={showDelete}
      />

      <ProgressBar progressBar={progressBar} />

      <div className={style.actions}>
        {showOpenGallery && (
          <Button small outline highlight onClick={() => handleOpenGallery()}>
            Ver todas
          </Button>
        )}

        <input
          multiple
          data-testid={dataTestId}
          className={style.input}
          id="image-uploader"
          name="image-uploader"
          type={'file'}
          onChange={handleOnChange}
          accept={'.png, .jpg, .jpeg'}
        />
        {showAdd && (
          <Button highlight outline small>
            <label
              className={style.inputTrigger}
              htmlFor="image-uploader"
              data-testid="file-field-trigger"
            >
              Subir foto
            </label>
          </Button>
        )}
      </div>

      {isOpen && (
        <Gallery
          images={images}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onDelete={handleDeleteImage}
          currentActiveIndex={imageIndex}
          showDelete={showDelete}
        />
      )}
    </Card>
  );
};

export default ImageGalleryField;
