import {
  ExternalLawsuit,
  ExternalLawsuitIndication,
  ExternalLawsuitSearch
} from 'types';
import Client from './client';

type FetchByReportParams = {
  caseId: string;
  reportId: string;
  pagination?: {
    page: number;
    pageSize: number;
  };
  indication?: ExternalLawsuitIndication;
};

function fetchReportExternalLawsuits({
  caseId,
  reportId,
  pagination,
  indication
}: FetchByReportParams) {
  return Client.get<ExternalLawsuit[]>(
    `cases/${caseId}/reports/${reportId}/external_lawsuits`,
    {
      params: {
        page: pagination?.page.toString(),
        pageSize: pagination?.pageSize.toString(),
        indication: indication
      }
    }
  );
}

function fetchReportExternalLawsuitsSummary({
  caseId,
  reportId,
  indication
}: FetchByReportParams) {
  return Client.get<ExternalLawsuit[]>(
    `cases/${caseId}/reports/${reportId}/external_lawsuits/summary`,
    {
      params: {
        indication: indication
      }
    }
  );
}

type FetchTrackedParams = {
  caseId: string;
  pagination?: {
    page: number;
    pageSize: number;
  };
};

function fetchTrackedExternalLawsuits({
  caseId,
  pagination
}: FetchTrackedParams) {
  return Client.get<ExternalLawsuit[]>(
    `cases/${caseId}/external_lawsuits/tracked`,
    {
      params: {
        page: pagination?.page.toString(),
        pageSize: pagination?.pageSize.toString()
      }
    }
  );
}

function fetchTrackedExternalLawsuitsSummary({ caseId }: FetchTrackedParams) {
  return Client.get<ExternalLawsuit[]>(
    `cases/${caseId}/external_lawsuits/tracked_summary`
  );
}

type FetchMonitoredParams = {
  caseId: string;
  pagination?: {
    page: number;
    pageSize: number;
  };
};

function fetchMonitoredExternalLawsuits({
  caseId,
  pagination
}: FetchMonitoredParams) {
  return Client.get<ExternalLawsuit[]>(
    `cases/${caseId}/external_lawsuits/monitored`,
    {
      params: {
        page: pagination?.page.toString(),
        pageSize: pagination?.pageSize.toString()
      }
    }
  );
}

function fetchExternalLawsuit({
  caseId,
  externalLawsuitId
}: {
  caseId: string;
  externalLawsuitId: string;
}) {
  return Client.get<ExternalLawsuit>(
    `cases/${caseId}/external_lawsuits/${externalLawsuitId}`
  );
}

function createExternalLawsuitSearch(caseId: string, searchTerm: string) {
  return Client.post<{ searchTerm: string }, ExternalLawsuitSearch>(
    `cases/${caseId}/external_lawsuits/searches`,
    { searchTerm }
  );
}

function toggleExternalLawsuitTracking({
  caseId,
  externalLawsuitId
}: {
  caseId: string;
  externalLawsuitId: string;
}) {
  return Client.patch<void, ExternalLawsuit>(
    `cases/${caseId}/external_lawsuits/${externalLawsuitId}/tracking`
  );
}

function toggleExternalLawsuitMonitoring({
  caseId,
  externalLawsuitId
}: {
  caseId: string;
  externalLawsuitId: string;
}) {
  return Client.patch<void, ExternalLawsuit>(
    `cases/${caseId}/external_lawsuits/${externalLawsuitId}/monitoring`
  );
}

export {
  fetchExternalLawsuit,
  fetchReportExternalLawsuitsSummary,
  fetchMonitoredExternalLawsuits,
  fetchReportExternalLawsuits,
  fetchTrackedExternalLawsuits,
  fetchTrackedExternalLawsuitsSummary,
  createExternalLawsuitSearch,
  toggleExternalLawsuitMonitoring,
  toggleExternalLawsuitTracking
};
