import { ASSET_IDENTIFICATION_DICTIONARY } from 'domain/assets';
import { FormikProps, getIn } from 'formik';
import React from 'react';
import { AssetAttributes, AssetAirplane, AssetType } from 'types';
import { FormContainer, InputField } from 'ui';

import styles from '../Form.module.scss';

type Props = {
  assetAirplane: AssetAirplane | null;
  isLoading: boolean;
  formik: FormikProps<AssetAttributes>;
  identificationLocked: boolean;
  disabled?: boolean;
};

const AirplaneFields = ({
  assetAirplane,
  isLoading,
  formik,
  identificationLocked,
  disabled
}: Props) => {
  return (
    <>
      <h3 className={styles.title}>Informações da Aeronave</h3>

      <InputField
        type="text"
        id="name"
        name="name"
        title="Nome do ativo"
        isLoading={isLoading}
        value={formik.values.name}
        error={getIn(formik.touched, 'name') && getIn(formik.errors, 'name')}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        disabled={disabled}
      />

      <FormContainer className={'col-2'}>
        <InputField
          type="text"
          id="assetAirplane.manufacturer"
          name="assetAirplane.manufacturer"
          title="Fabricante (opcional)"
          isLoading={isLoading}
          value={formik.values.assetAirplane?.manufacturer}
          error={
            getIn(formik.touched, 'assetAirplane.manufacturer') &&
            getIn(formik.errors, 'assetAirplane.manufacturer')
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          showMode={
            assetAirplane ? !assetAirplane.manufacturer.isEditable : false
          }
          disabled={disabled}
        />

        <InputField
          type="text"
          id="assetAirplane.model"
          name="assetAirplane.model"
          title="Modelo (opcional)"
          isLoading={isLoading}
          value={formik.values.assetAirplane?.model}
          error={
            getIn(formik.touched, 'assetAirplane.model') &&
            getIn(formik.errors, 'assetAirplane.model')
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          showMode={assetAirplane ? !assetAirplane.model.isEditable : false}
          disabled={disabled}
        />

        <InputField
          type="text"
          id="assetAirplane.serialNumber"
          name="assetAirplane.serialNumber"
          title="Número de série (opcional)"
          isLoading={isLoading}
          value={formik.values.assetAirplane?.serialNumber}
          error={
            getIn(formik.touched, 'assetAirplane.serialNumber') &&
            getIn(formik.errors, 'assetAirplane.serialNumber')
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          showMode={
            assetAirplane ? !assetAirplane.serialNumber.isEditable : false
          }
          disabled={disabled}
        />

        <InputField
          type="text"
          id="identification"
          name="identification"
          title={`${
            ASSET_IDENTIFICATION_DICTIONARY[AssetType.AIRPLANE]
          } (opcional)`}
          isLoading={isLoading}
          value={formik.values.identification}
          error={
            getIn(formik.touched, 'identification') &&
            getIn(formik.errors, 'identification')
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          showMode={identificationLocked}
          disabled={disabled}
        />

        <InputField
          type="text"
          id="assetAirplane.engineSpecification"
          name="assetAirplane.engineSpecification"
          title="Especificações dos motores (opcional)"
          isLoading={isLoading}
          value={formik.values.assetAirplane?.engineSpecification}
          error={
            getIn(formik.touched, 'assetAirplane.engineSpecification') &&
            getIn(formik.errors, 'assetAirplane.engineSpecification')
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          showMode={
            assetAirplane
              ? !assetAirplane.engineSpecification.isEditable
              : false
          }
          disabled={disabled}
        />

        <InputField
          type="text"
          id="assetAirplane.seatingCapacity"
          name="assetAirplane.seatingCapacity"
          title="Número de passageiros (opcional)"
          isLoading={isLoading}
          value={formik.values.assetAirplane?.seatingCapacity?.toString()}
          error={
            getIn(formik.touched, 'assetAirplane.seatingCapacity') &&
            getIn(formik.errors, 'assetAirplane.seatingCapacity')
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          showMode={
            assetAirplane ? !assetAirplane.seatingCapacity.isEditable : false
          }
          disabled={disabled}
        />

        <InputField
          type="text"
          id="assetAirplane.yearOfManufacture"
          name="assetAirplane.yearOfManufacture"
          title="Ano de fabricação (opcional)"
          isLoading={isLoading}
          value={formik.values.assetAirplane?.yearOfManufacture?.toString()}
          error={
            getIn(formik.touched, 'assetAirplane.yearOfManufacture') &&
            getIn(formik.errors, 'assetAirplane.yearOfManufacture')
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          showMode={
            assetAirplane ? !assetAirplane.yearOfManufacture.isEditable : false
          }
          disabled={disabled}
        />
      </FormContainer>

      <InputField
        type="text"
        id="assetAirplane.rabSituation"
        name="assetAirplane.rabSituation"
        title="Situação RAB (opcional)"
        isLoading={isLoading}
        value={formik.values.assetAirplane?.rabSituation?.toString()}
        error={
          getIn(formik.touched, 'assetAirplane.rabSituation') &&
          getIn(formik.errors, 'assetAirplane.rabSituation')
        }
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        showMode={
          assetAirplane ? !assetAirplane.rabSituation.isEditable : false
        }
        disabled={disabled}
      />
    </>
  );
};

export default AirplaneFields;
