import { statusLabel } from 'domain/cases';

import React, { useEffect, useState } from 'react';

import { fetchLawFirm } from 'api';
import { handleNullValue, applyDateMask } from 'utils';

import {
  InfoArea,
  StackMargin,
  Stack,
  FormContainer,
  openErrorToast
} from 'ui';

import { Case } from 'types';

import { SummaryError } from 'components';
import SummaryLoader from '../SummaryLoader';

import style from './BasicInformations.module.scss';

type Props = {
  error?: boolean;
  isLoading?: boolean;
  caseData?: Case;
  className?: string;
};

const BasicInformations = ({
  caseData,
  className,
  isLoading = false,
  error = false
}: Props) => {
  const [lawFirm, setLawFirmData] = useState({
    name: '',
    isLoading: false
  });

  useEffect(() => {
    if (!!caseData?.lawFirmId) {
      const getLawFirmById = async () => {
        setLawFirmData({ name: '', isLoading: true });
        try {
          const {
            data: { name }
          } = await fetchLawFirm(caseData!.lawFirmId!);

          setLawFirmData(state => ({ ...state, name }));
        } catch {
          openErrorToast(
            'Não foi possível carregar nome do escritório de advocacia.'
          );
        } finally {
          return setLawFirmData(state => ({ ...state, isLoading: false }));
        }
      };

      getLawFirmById();
    }
  }, [caseData]);

  if (isLoading) {
    return <SummaryLoader title="Informações Básicas" />;
  }

  if (error || !caseData) {
    return (
      <SummaryError
        title="Informações Básicas"
        message="Não foi possível carregar informações do caso, tente novamente."
      />
    );
  }

  const {
    creditor = 'Banco BV',
    status,
    startedAt,
    internalLawyer,
    negotiator
  } = caseData;

  return (
    <Stack className={className} marginTop={StackMargin.XLARGE}>
      <h3 className={style.infoTitle}>Informações básicas</h3>
      <Stack marginTop={StackMargin.LARGE}>
        <FormContainer className="col-3">
          <InfoArea
            className={style.infoValue}
            dataTestId="started-at"
            label="Data de início da gestão"
            value={handleNullValue(startedAt, 'string', applyDateMask)}
          />
          <InfoArea
            className={style.infoValue}
            dataTestId="status"
            label="Estágio de recuperação"
            value={handleNullValue(statusLabel(status), 'string')}
          />
          <InfoArea
            className={style.infoValue}
            dataTestId="creditor"
            label="Credor do caso"
            value={handleNullValue(creditor, 'string')}
          />
        </FormContainer>
        <FormContainer className="col-3">
          <InfoArea
            className={style.infoValue}
            dataTestId="internal-lawyer"
            label="Advogado responsável"
            value={handleNullValue(internalLawyer?.name, 'string')}
          />
          <InfoArea
            className={style.infoValue}
            dataTestId="negotiator"
            label="Negociador responsável"
            value={handleNullValue(negotiator.name, 'string')}
          />
          <InfoArea
            className={style.infoValue}
            isLoading={lawFirm.isLoading}
            dataTestId="law-firm"
            label="Escritório de advocacia"
            value={handleNullValue(lawFirm.name, 'string')}
          />
        </FormContainer>
      </Stack>
    </Stack>
  );
};

export default BasicInformations;
