import React, { useState } from 'react';

import { can, Action, fetchAllLawFirms, deleteLawFirm } from 'api';
import { useQuery } from 'hooks';
import { LawFirm } from 'types';
import {
  Button,
  Modal,
  Accordion,
  Icon,
  Link,
  openErrorToast,
  openSuccessToast,
  ToolTip,
  StackMargin,
  Stack
} from 'ui';
import Form from './Form';

import ExternalLawyer from './ExternalLawyer';

const LawFirms = () => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const { data: lawFirms, error, isLoading, setData: setLawFirms } = useQuery<
    LawFirm[],
    {
      includes: string[];
    }
  >(fetchAllLawFirms, {
    includes: ['users']
  });
  const [lawFirmBeingEdited, setLawFirmBeingEdited] = useState<LawFirm>();

  function closeModal() {
    setModalOpen(false);
    setLawFirmBeingEdited(undefined);
  }

  function openModal() {
    setModalOpen(true);
  }

  const handleEdit = (lawFirmToEdit: LawFirm) => () => {
    setLawFirmBeingEdited(lawFirmToEdit);
    openModal();
  };

  const handleDelete = (lawFirmId: string) => async () => {
    if (!window.confirm('você deseja excluir?')) return;

    try {
      await deleteLawFirm(lawFirmId);
      openSuccessToast('Escritório excluido com sucesso.');

      const lawFirmsUpdated = lawFirms?.filter(
        lawFirm => lawFirm.id !== lawFirmId
      );
      setLawFirms(lawFirmsUpdated);
    } catch (error) {
      openErrorToast('Erro ao excluir o Escritório.');
    }
  };

  function handleUpdateLawFirm(updatedLawFirm: LawFirm) {
    const updatedLawFirms = lawFirms?.map(lawFirm => {
      if (lawFirm.id === updatedLawFirm.id) return updatedLawFirm;
      return lawFirm;
    });

    setLawFirms(updatedLawFirms);
    closeModal();
  }

  function handleCreateLawFirm(lawFirm: LawFirm) {
    setLawFirms([...(lawFirms ?? []), lawFirm]);
    closeModal();
  }

  if (error) {
    openErrorToast('Erro ao carregar os Escritórios.');
  }

  return (
    <div className="law-firms-tab">
      {can(Action.PERMISSOES_ESCRITORIOS_DE_ADVOCACIA_INCLUIR) && (
        <Button
          onClick={openModal}
          outline
          highlight
          small
          dataTestId="add-lawfirm"
        >
          Novo escritório
        </Button>
      )}

      {isLoading && <p>Carregando...</p>}

      {lawFirms?.map(lawFirm => {
        return (
          <Stack key={`law-firm-${lawFirm.id}`} marginTop={StackMargin.XLARGE}>
            <Accordion
              title={lawFirm.name}
              headerActions={
                <>
                  {can(Action.PERMISSOES_ESCRITORIOS_DE_ADVOCACIA_EDITAR) && (
                    <ToolTip content="Editar" placement="top">
                      <div>
                        <Link
                          className="edit"
                          onClick={handleEdit(lawFirm)}
                          dataTestId={`law-firm-edit-${lawFirm.id}`}
                        >
                          <Icon name="edit" fill="#62667a" small />
                        </Link>
                      </div>
                    </ToolTip>
                  )}
                  {can(Action.PERMISSOES_ESCRITORIOS_DE_ADVOCACIA_EXCLUIR) && (
                    <ToolTip content="Remover" placement="top">
                      <div>
                        <Link
                          className="delete"
                          onClick={handleDelete(lawFirm.id)}
                          dataTestId={`law-firm-delete-${lawFirm.id}`}
                        >
                          <Icon name="close" fill="#62667a" small />
                        </Link>
                      </div>
                    </ToolTip>
                  )}
                </>
              }
            >
              <ExternalLawyer users={lawFirm.users} lawFirmId={lawFirm.id} />
            </Accordion>
          </Stack>
        );
      })}

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={lawFirmBeingEdited ? 'Editar escritório' : 'Novo escritório'}
      >
        <Form
          onCreateLawFirm={handleCreateLawFirm}
          onUpdateLawFirm={handleUpdateLawFirm}
          lawFirmData={lawFirmBeingEdited}
        />
      </Modal>
    </div>
  );
};

export default LawFirms;
