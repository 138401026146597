import { Action, can } from 'api';
import { CaseType } from 'types';

type canUser = {
  addCase: boolean;
  removeCase: boolean;
  showSummary: boolean;
  showFiles: boolean;
  showBasicInformation: boolean;
  editBasicInformation: boolean;
  showAdverseParties: boolean;
  addAdverseParties: boolean;
  editAdverseParties: boolean;
  removeAdverseParties: boolean;
  showInternalLawsuits: boolean;
  showEventsTimeline: boolean;
  showEventsActivities: boolean;
  showDebtors: boolean;
  showContracts: boolean;
  showInvoices: boolean;
  showResourcesSuspects: boolean;
  showResourcesReports: boolean;
  showResourcesAssets: boolean;
  showResourcesLocations: boolean;
  showResourcesCities: boolean;
  showResourcesLawsuits: boolean;
  showResourcesInstagramPublications: boolean;
};

type canShowTab = {
  basicInformation: boolean;
  debtors: boolean;
  resources: boolean;
  events: boolean;
  lawsuits: boolean;
  files: boolean;
};

export const buildCanUser = (negotiatorId?: string): canUser => ({
  addCase: can(Action.CASOS_LISTAGEM_DE_CASOS_INCLUIR),
  removeCase: can(Action.CASOS_CASO_EXCLUIR, negotiatorId),
  showSummary: can(Action.CASOS_RESUMO_DO_CASO_CONSULTAR, negotiatorId),
  showFiles: can(Action.CASOS_ARQUIVOS_CONSULTAR, negotiatorId),
  showBasicInformation: can(
    Action.CASOS_INFORMACOES_BASICAS_CONSULTAR,
    negotiatorId
  ),
  editBasicInformation: can(
    Action.CASOS_INFORMACOES_BASICAS_EDITAR,
    negotiatorId
  ),
  showAdverseParties: can(
    Action.CASOS_INFORMACOES_BASICAS_PARTE_ADVERSA_CONSULTAR,
    negotiatorId
  ),
  addAdverseParties: can(
    Action.CASOS_INFORMACOES_BASICAS_PARTE_ADVERSA_INCLUIR,
    negotiatorId
  ),
  editAdverseParties: can(
    Action.CASOS_INFORMACOES_BASICAS_PARTE_ADVERSA_EDITAR,
    negotiatorId
  ),
  removeAdverseParties: can(
    Action.CASOS_INFORMACOES_BASICAS_PARTE_ADVERSA_EXCLUIR,
    negotiatorId
  ),
  showInternalLawsuits: can(
    Action.CASOS_PROCESSOS_JUDICIAIS_CONSULTAR,
    negotiatorId
  ),
  showEventsTimeline: can(
    Action.CASOS_ACOMPANHAMENTO_TIMELINE_CONSULTAR,
    negotiatorId
  ),
  showEventsActivities: can(
    Action.CASOS_ACOMPANHAMENTO_ATIVIDADES_CONSULTAR,
    negotiatorId
  ),
  showDebtors: can(Action.CASOS_DEVEDOR_CONSULTAR, negotiatorId),
  showContracts: can(Action.CASOS_CONTRATO_CONSULTAR, negotiatorId),
  showInvoices: can(Action.CASOS_NOTA_FISCAL_CONSULTAR, negotiatorId),
  showResourcesSuspects: can(
    Action.CASOS_RASTREAMENTO_SUSPEITOS_CONSULTAR,
    negotiatorId
  ),
  showResourcesReports: can(
    Action.CASOS_RASTREAMENTO_CONSULTAS_CONSULTAR,
    negotiatorId
  ),
  showResourcesAssets: can(
    Action.CASOS_RASTREAMENTO_ATIVOS_CONSULTAR,
    negotiatorId
  ),
  showResourcesLocations: can(
    Action.CASOS_RASTREAMENTO_LOCALIZACOES_CONSULTAR,
    negotiatorId
  ),
  showResourcesCities: can(
    Action.CASOS_RASTREAMENTO_PRACAS_CONSULTAR,
    negotiatorId
  ),
  showResourcesLawsuits: can(
    Action.CASOS_RASTREAMENTO_PROCESSOS_CONSULTAR,
    negotiatorId
  ),
  showResourcesInstagramPublications: can(
    Action.CASOS_RASTREAMENTO_POSTAGENS_SALVAS_CONSULTAR,
    negotiatorId
  )
});

export const canShowSaveButton = (
  canUser: canUser,
  caseId?: string
): boolean => {
  const canUpdateAdverseParties =
    canUser.showAdverseParties &&
    (canUser.addAdverseParties ||
      canUser.editAdverseParties ||
      canUser.removeAdverseParties);

  const canSaveFormOnEdit =
    canUser.editBasicInformation || canUpdateAdverseParties;

  const canSaveFormOnAdd = canUser.addCase || canUser.addAdverseParties;

  return caseId ? canSaveFormOnEdit : canSaveFormOnAdd;
};

export const buildCanShowTab = (
  canUser: canUser,
  caseType: CaseType,
  caseId?: string
): canShowTab => ({
  basicInformation: caseId ? canUser.showBasicInformation : canUser.addCase,
  debtors:
    (caseType === CaseType.SALES_FINANCE && canUser.showInvoices) ||
    canUser.showDebtors ||
    canUser.showContracts,
  resources:
    canUser.showResourcesSuspects ||
    canUser.showResourcesReports ||
    canUser.showResourcesAssets ||
    canUser.showResourcesLocations ||
    canUser.showResourcesCities ||
    canUser.showResourcesLawsuits ||
    canUser.showResourcesInstagramPublications,
  events:
    caseType === CaseType.ATIVOS_ESPECIAIS
      ? canUser.showEventsTimeline
      : canUser.showEventsTimeline || canUser.showEventsActivities,
  lawsuits: canUser.showInternalLawsuits,
  files: canUser.showFiles
});
