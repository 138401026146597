import { eventTypeLabel } from 'domain/caseEvents';
import React from 'react';

import { pluralize } from 'utils';
import { CollapsibleParagraph, Stack, StackMargin, Badge } from 'ui';

import { CaseEvent } from 'types';

import style from './TimelineContent.module.scss';

type Props = {
  event: CaseEvent;
  expanded?: boolean;
};

const filesCountText = (filesAmout: number) => {
  if (!filesAmout) return;

  return `(contém ${pluralize(filesAmout, 'arquivo', 'arquivos')})`;
};

const TimelineContent = ({ event, expanded = false }: Props) => {
  return (
    <div className={style.timelineContent}>
      <CollapsibleParagraph text={event.description} />

      {!!event.type && (
        <Stack marginTop={StackMargin.SMALL}>
          <Badge dataTestId="type-badge" className={style.badge} rounded>
            {eventTypeLabel(event.type)}
          </Badge>
        </Stack>
      )}

      {expanded && (
        <Stack marginTop={StackMargin.SMALL}>
          <p className={style.content}>{event.content}</p>
          {event.fileUploads.map((file, index) => (
            <Stack marginTop={StackMargin.SMALL} key={index}>
              <Badge className={style.badge}>{file.name}</Badge>
            </Stack>
          ))}
        </Stack>
      )}

      {!expanded && (
        <Stack marginTop={StackMargin.SMALL}>
          <span>{filesCountText(event.fileUploads.length)}</span>
        </Stack>
      )}
    </div>
  );
};

export default TimelineContent;
