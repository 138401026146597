import React, { useEffect } from 'react';
import * as queryString from 'query-string';
import { useHistory } from 'react-router-dom';

import { oauthLogin } from 'api';
import { useSocket } from 'components';
import { openErrorToast, openInfoToast, Spinner, Title } from 'ui';
import LayoutAuthentication from 'Layouts/LayoutAuthentication';

import style from './Authenticate.module.scss';

const Authenticate = () => {
  const history = useHistory();
  const locationSearch = history.location.search;
  const { socket } = useSocket();

  useEffect(() => {
    const handleOauthLogin = async () => {
      const urlParams = queryString.parse(locationSearch);

      if (urlParams.code) {
        const credendials = { code: urlParams.code!.toString() };

        try {
          const message = await oauthLogin(history, credendials);

          message && openInfoToast(message, 12000);
          socket?.connect();
        } catch (error) {
          if (error.errors?.profile) {
            error.errors.profile.forEach((profileErrorMsg: string) => {
              openErrorToast(profileErrorMsg, 12000);
            });
          } else {
            openErrorToast('Não foi possível efetuar o login.');
          }
          history.push('/login');
        }
      } else {
        history.push('/login');
      }
    };

    handleOauthLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, locationSearch]);

  return (
    <LayoutAuthentication>
      <>
        <Title>Login</Title>
        <div className={style.authenticating} data-testid="auth-loading">
          <Spinner color="blue" />
          Autenticando...
        </div>
      </>
    </LayoutAuthentication>
  );
};

export default Authenticate;
