import React from 'react';
import { fetchAssetsSummary } from 'api';
import { useQuery, useToggles } from 'hooks';
import { RadioSwitch, Stack, StackMargin } from 'ui';
import { SummaryError } from 'components';
import { AssetAutoStatus } from 'types';
import AssetsSummaryTable from '../AssetsSummaryTable/AssetsSummaryTable';
import style from './Assets.module.scss';

type Props = {
  caseId: string;
};

const Assets = ({ caseId }: Props) => {
  const { data: assets = [], error, isLoading } = useQuery(fetchAssetsSummary, {
    caseId
  });

  const { toggle, isOpen } = useToggles<'hideGuaranteeAssets'>({
    hideGuaranteeAssets: false
  });

  const filteredAssets = isOpen.hideGuaranteeAssets
    ? assets.filter(
        asset => !asset.autoStatus.includes(AssetAutoStatus.GUARANTEED)
      )
    : assets;

  const assetFlat = filteredAssets.flatMap(asset => [
    asset,
    ...asset.groupedAssets
  ]);

  if (error) {
    return (
      <SummaryError
        title="Ativos"
        message="Erro ao listar ativos, tente novamente."
      />
    );
  }

  return (
    <Stack marginTop={StackMargin.XLARGE}>
      <Stack className={style.header}>
        <h3 className={style.title}>{`Ativos (${filteredAssets.length})`}</h3>

        <div className={style.hideOnPrint}>
          <RadioSwitch
            label="Ocultar ativos em garantia"
            onChange={toggle('hideGuaranteeAssets')}
            active={isOpen.hideGuaranteeAssets}
            inputId="hide-guarantee-assets"
          />
        </div>
      </Stack>

      <Stack marginTop={StackMargin.MEDIUM} className={style.wrapper}>
        <AssetsSummaryTable isLoading={isLoading} assets={assetFlat} />
      </Stack>
    </Stack>
  );
};

export default Assets;
