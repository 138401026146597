import { useEffect, useCallback } from 'react';
import { getUserId } from 'api';
import { useChannel } from 'components';
import { UnreadNotificationsCount } from 'types';

export function useLiveNotificationsCount({
  caseId,
  setData
}: {
  caseId?: string;
  setData: (message: UnreadNotificationsCount) => void;
}) {
  const userId = getUserId();
  const topic = caseId
    ? `users:${userId}:cases:${caseId}:notifications`
    : `users:${userId}:notifications`;

  const setNewCount = useCallback(
    (message: UnreadNotificationsCount) => {
      setData(message);
    },
    [setData]
  );

  const { channel, isConnected } = useChannel(topic);

  useEffect(() => {
    if (!channel || !isConnected) return;

    channel.on('unread_count_change', setNewCount);

    return () => {
      if (channel) {
        channel.off('unread_count_change');
      }
    };
  }, [setNewCount, isConnected, channel]);
}
