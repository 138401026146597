import { City } from 'types';

export enum CourtInstance {
  FIRST = 'first',
  HIGHER = 'higher'
}

export type Court = {
  name: string;
  judgingBody: string;
  instance: CourtInstance;
  city: City;
  id: string;
};

export type CourtFormAttributes = {
  name?: string;
  judgingBody?: string;
  instance?: string;
  city: Partial<City>;
  id?: string;
};
