import React, { useCallback, ReactNode } from 'react';
import ReactModal from 'react-modal';
import cn from 'classnames';

import { Icon } from 'ui';

import style from './Modal.module.scss';

type Props = {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  dataTestId?: string;
  className?: string;
  contentClassName?: string;
};

const Modal = ({
  children,
  isOpen = false,
  onClose = () => {},
  title,
  subtitle,
  small,
  large,
  medium,
  dataTestId = 'modal',
  className,
  contentClassName
}: Props) => {
  const onRefChange = useCallback(node => {
    if (node !== null) {
      const firstFocusableInput = node.querySelector(
        'input:not([type=checkbox]):not([type=radio]), select, textarea'
      );

      if (firstFocusableInput) {
        firstFocusableInput.focus();
      }
    }
  }, []);

  return (
    <ReactModal
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      className={cn(style.modalBox, className, {
        [style.small]: Boolean(small),
        [style.medium]: Boolean(medium),
        [style.large]: Boolean(large)
      })}
      ariaHideApp={false}
      overlayClassName={style.modalOverlay}
      onRequestClose={onClose}
      contentRef={onRefChange}
    >
      <div data-testid={dataTestId}>
        <button onClick={onClose} className={style.close}>
          <Icon name="close" dataTestId="closeModalIcon" />
        </button>

        {title && <h3 className={style.header}>{title}</h3>}
        {subtitle && <span className={style.subtitle}>{subtitle}</span>}

        <div className={cn(style.content, contentClassName)}>{children}</div>
      </div>
    </ReactModal>
  );
};

export default Modal;
