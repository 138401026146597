import { Option, TaxIndexer } from 'types';

const indexerDictionary: { [key in TaxIndexer]: string } = {
  [TaxIndexer.PREFIXADO]: 'Prefixado',
  [TaxIndexer.CDI]: 'CDI',
  [TaxIndexer.CDI_MAIS]: 'CDI+',
  [TaxIndexer.IGPM]: 'IGPM',
  [TaxIndexer.IGPM_MAIS]: 'IGPM+',
  [TaxIndexer.IPCA]: 'IPCA',
  [TaxIndexer.IPCA_MAIS]: 'IPCA+',
  [TaxIndexer.LIBOR]: 'LIBOR',
  [TaxIndexer.LIBOR_MAIS]: 'LIBOR+',
  [TaxIndexer.SELIC]: 'SELIC',
  [TaxIndexer.SELIC_MAIS]: 'SELIC+',
  [TaxIndexer.TJLP]: 'TJLP',
  [TaxIndexer.TJLP_MAIS]: 'TJLP+',
  [TaxIndexer.TR]: 'TR',
  [TaxIndexer.TR_MAIS]: 'TR+',
  [TaxIndexer.OUTRO]: 'Outro'
};

export function indexerLabel(indexer: TaxIndexer) {
  return indexerDictionary[indexer] || indexer;
}

export function indexerOptions(): Option[] {
  return Object.values(TaxIndexer).map(indexer => ({
    value: indexer,
    label: indexerLabel(indexer)
  }));
}
